import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  MDBModalRef,
  MDBModalService,
  ModalModule,
  WavesModule,
  InputsModule,
} from 'angular-bootstrap-md';
import { ModalComponent } from '../../components/modal/modal.component';
import { ModalService } from '../../services/modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PackageService } from '../../services/package.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('adAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  @ViewChild('frame') public frameModal: any;
  // @ViewChild("cookie") public cookieModal: any;

  public selectedindex: number = 0; //Initialized to 0
  modalRef: MDBModalRef;
  storeanimation: string;
  signalanimation: string;
  packageAnimation: string;
  package2Animation: string;
  bgSignal: string;
  ondemandAnimation: string;
  scrollTop = 0;
  hideNav = false;
  public images: string[] = [
    'assets/img/banner/bannerwebPNG-06.png',
    'assets/img/bg/bg2.png',
  ];
  packagesres: Array<Object>;
  packages: Array<object> = [
    { id: '1', day: 7, speed: '2', call: 100, price: 199 },
    { id: '2', day: 7, speed: '4', call: 100, price: 299 },
    { id: '3', day: 14, speed: '4', call: 100, price: 399 },
    { id: '4', day: 14, speed: '6', call: 100, price: 499 },
    { id: '5', day: 30, speed: '10', call: 100, price: 599 },
  ];

  selectImage(index: number) {
    console.log('Index: ' + index);
    this.selectedindex = index;
    console.log('Selected Index: ' + this.selectedindex);
  }

  constructor(
    private _router: Router,
    private pack: PackageService,
    private modalService: MDBModalService,
    private modal: ModalService,
    private _sanitizer: DomSanitizer,
    private thismodal: ModalModule
  ) {
    this.pack.getPackages('homepage').subscribe((res: any) => {
      this.packagesres = Object.values(
        JSON.parse(res + '')['packagelist']
      ).slice(0, 4);
      // console.log(this.packagesres);
      // console.log(Object.values(JSON.parse(res + '')['packagelist']).slice(-2))
    });
  }
  ngOnInit(): void {
    this.frameModal.show();
    // this.modalRef = this.modalService.show(ModalComponent)
    // this.modal.getModal().subscribe((res: Response) => {
    //   console.log(res['responsecode'])
    //   if (res['responsecode'] == 0 && this.modal.show == true) {
    //     this.modalRef = this.modalService.show(ModalComponent)
    //     this.modal.show = false
    //   }
    // })
  }
  //open modal
  ngAfterViewInit() {
    // this.frameModal.show();
    // this.cookieModal.show();
  }

  onAppear() {
    console.log('yeah');
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.innerWidth > 768) {
      if (this.onCheckScrollPass('store', event)) {
        if (this.storeanimation !== '') {
          this.storeanimation = '';
        }
      } else {
        if (this.storeanimation !== ' circle-animation') {
          this.storeanimation = ' circle-animation';
        }
      }
      if (this.onCheckScrollPass('package', event)) {
        if (this.packageAnimation !== ' animated slideInLeft') {
          this.package2Animation = ' animated slideInLeft phone-2-animation';
          this.packageAnimation = ' animated slideInRight';
        }
      } else {
        if (this.packageAnimation !== '') {
          this.package2Animation = '';
          this.packageAnimation = '';
        }
      }
      if (this.onCheckScrollPass('signal', event)) {
        if (this.bgSignal !== ' bg-signal') {
          this.signalanimation = ' animated slideInLeft';
          this.bgSignal = ' bg-signal';
        }
      } else {
        if (this.bgSignal !== ' bg-soft-green') {
          this.signalanimation = '';
          this.bgSignal = ' bg-soft-green';
        }
      }
      if (this.onCheckScrollPass('ondemand', event)) {
        if (this.ondemandAnimation !== ' half-left-circle') {
          this.ondemandAnimation = ' half-left-circle';
        }
      } else {
        if (this.ondemandAnimation !== '') {
          this.ondemandAnimation = '';
        }
      }
    } else {
      this.ondemandAnimation = '';
      this.signalanimation = ' animated slideInLeft';
      this.bgSignal = ' bg-signal';
      this.package2Animation = ' animated slideInLeft phone-2-animation';
      this.packageAnimation = ' animated slideInRight';
      this.storeanimation = '';
    }
  }

  onCheckScrollPass(id: string, event) {
    var value: boolean =
      document.getElementById(id).getBoundingClientRect().y -
        document.getElementById(id).getBoundingClientRect().height <
      0;
    return value;
  }
  onMenu() {
    this.modal.menu.next(true);
  }
  onPackClick(id: number) {
    this._router.navigate(['/', 'packages', { packid: id }]);
  }
}
