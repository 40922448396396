import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NumberService {
    selectNumber: string;
    price: string;
    productid: string;
    originprice: string;
    taxprice: string;

    firstname: string;
    lastname: string;
    contactnumber: string;
    email: string;
    personaltype: string;
    personalid: string;
    birthday: string;
    housenumber: string;
    housename: string;
    province: string;
    district: string;
    subdistrict: string;
    zipcode: string;
    public resOrder: Subject<Object> = new Subject<Object>();
    private API_URL_PROD = environment.API_URL_PROD;

    constructor(private http: HttpClient) { }

    searchNumber(keyword: string, searchby: string) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/searchnumber'
        let body = JSON.stringify({ keyword, searchby });
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    getNumber() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/searchnumber'
        let body = {}
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    bookNumber(phonenumber: string) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/booknumber'
        let body = JSON.stringify({
            phonenumber
        });
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    orderNumber(data) {
        console.log(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/ordernumber'
        let body = JSON.stringify(data);
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

}
