<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <ng-scrollbar style="background-color: #DCDCDC;">
                <div class="col-md-12 p-4 agreementpage">
                    <div class="force-overflow">
                        <ul class="text-left mb-0">
                            <li>ความเร็วอินเทอร์เน็ตขึ้นอยู่กับพื้นที่การใช้งาน</li>
                            <li>บริษัทสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดเงื่อนไขของบริการและรายการส่งเสริมการขายโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                            </li>
                            <li>บริษัทสงวนสิทธิ์ในการจัดการบริหารเครือข่ายตามความเหมาะสมเพื่อรักษามาตรฐานคุณภาพของบริการ
                                และเพื่อช่วยให้ผู้ใช้บริการโดยรวมสามารถใช้งานได้อย่างมีประสิทธิ์ภาพ เช่น จำกัด หรือ
                                ลดความเร็วหรือดำเนินการใดๆ ในการรับส่งข้อมูลต่างๆ, การใช้งาน Bit Torrent,
                                การแชร์เน็ตผ่าน
                                Hotspot, การดาวน์โหลดและ/หรือ อัพโหลดไฟล์ขนาดใหญ่, หรือ
                                การใช้งานใดที่มีการรับส่งข้อมูลในปริมาณมากอย่างต่อเนื่อง
                                หรือที่มีผลต่อการใช้บริการหรือเกิดความไม่เป็นธรรม
                                ก่อหรืออาจก่อให้เกิดความเสียหายต่อผู้ใช้บริการอื่น และ/หรือต่อเครือข่าย
                                หรือการให้บริการโดยรวมของบริษัท</li>
                            <li>บริการตามรายละเอียดนี้เพื่อการสื่อสารเฉพาะบุคคลเท่านั้น มิใช่เพื่อถือเอาประโยชน์
                                หรือนำไปใช้งานในเชิงพาณิชย์ หรือให้บริการต่อได้
                                หากพบบริษัทขอสงวนสิทธิ์ในการยกเลิกการให้บริการได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
                        </ul>
                    </div>
                </div>
            </ng-scrollbar>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="next()" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ยอมรับเงื่อนไข</button>
        </div>
    </div>
</main>