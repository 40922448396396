<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-8 p-0">
            <div class="col-12 p-0">
                <div class="text-center upload-img mt-3">
                    <img [className]="'d-example'+exampleimageClass" (click)="file_idcard.click()"
                        src="assets/img/banner/CamIDcardfront.png">
                    <div [className]="'preview'+previewimageClass">
                        <img id="peviewidcard" (click)="file_idcard.click()" [src]="peviewidcard">
                    </div>
                    <p class="mt-3">ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหน้า)</p>
                    <input class="d-none" type="file" #file_idcard (click)="file_idcard.value = null"
                        (change)="importFile($event)" accept="image/*" capture>
                </div>
                <div *ngIf="file_idcard.value" class="text-center d-block">
                    <img (click)="rotateIdCard()" class="img-rotating" src="assets/img/icon/rotate-icon.png" width="35">
                </div>
                <div class="text-center upload-img mt-3">
                    <img [className]="'d-example'+examplebackimageClass" (click)="file_backidcard.click()"
                        src="assets/img/banner/CamIDcardBack.png">
                    <div [className]="'preview'+previewbackimageClass">
                        <img id="peviewbackidcard" (click)="file_backidcard.click()" [src]="peviewbackidcard">
                    </div>
                    <p class="mt-3">ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหลัง)</p>
                    <input class="d-none" type="file" #file_backidcard (click)="file_backidcard.value = null"
                        (change)="importBackFile($event)" accept="image/*" capture>
                </div>
                <div *ngIf="file_backidcard.value" class="text-center d-block">
                    <img (click)="rotateBackIdCard()" class="img-rotating" src="assets/img/icon/rotate-icon.png"
                        width="35">
                </div>
            </div>
            <div class="col-12 text-center p-3">
                <button mdbBtn type="button" (click)="nextPage()"
                    [disabled]="(!file_idcard.value && !file_backidcard.value) || disable"
                    class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ถัดไป</button>
            </div>
        </div>
    </div>

    <!-- <div class="col-12 mt-5">
        <div class="text-center upload-img">
            <img (click)="onOpenCamera('backImg')" src="assets/img/banner/CamIDcardBack.png">
            <div class="preview">
                <img id="peviewidcartBackside" (click)="onUploadIdcardBackside" />
            </div>
            <p>ภาพถ่าย บัตรประจำตัวประชาชน (ด้านหลัง)</p>
            <input type="file" name="file_idcard_backside" ref="file_idcard_backside" accept="image/*" capture
                style="display:none;">
            <p class="show-exmple-img text-danger" (click)="show_example_image('back')">ภาพตัวอย่าง</p>
        </div>
        <div class="text-center" style="display: block;position: inherit;">
            <img (click)="rotateIdCardBackside" class="img-rotating" src="/images/icons8-rotate-page-clockwise.png">
        </div>
    </div> -->
</main>

<!-- <div mdbModal #frame="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="close" (click)="onCloseCamera()">
                    <img src="assets/camera/btn.close.png" alt="">
                </div>
                <div class="camera">
                    <video #video class="vid" autoplay></video>
                    <video autoplay id="videoCamera" ref="videoCamera"></video>
                    <img *ngIf="cameraType === 'frontImg'" class="bg" src="assets/camera/step.1.png" alt="">
                    <img *ngIf="cameraType === 'backImg'" class="bg" src="assets/camera/step.2.png" alt="">
                    <div class="screenshot" (click)="onScreenshot()">
                        <img src="assets/camera/btn.shutter.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div *ngIf="showCamera">
    <div class="modal-mask modal-camera">
        <div class="modal-wrapper">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="close" (click)="onCloseCamera()">
                            <img src="assets/camera/btn.close.png" alt="">
                        </div>
                        <div class="camera">
                            <video #video class="vid" autoplay></video>
                            <video autoplay id="videoCamera" ref="videoCamera"></video>
                            <img *ngIf="cameraType === 'frontImg'" class="bg" src="assets/camera/step.1.png" alt="">
                            <img *ngIf="cameraType === 'backImg'" class="bg" src="assets/camera/step.2.png" alt="">
                            <div class="screenshot" (click)="onScreenshot()">
                                <img src="assets/camera/btn.shutter.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="">
    <canvas #canvas class="vid"></canvas>
    <canvas class="d-none" id="canvas"></canvas>
</div> -->