import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';

@Component({
    selector: 'app-portinagreement',
    templateUrl: './portinagreement.component.html',
    styleUrls: ['./portinagreement.component.scss']
})
export class PortinagreementComponent implements OnInit {
    page: string;

    constructor(private register: RegisterService) { }

    ngOnInit(): void {
    }

    next() {
        this.register.pageMove.next('blanksim');
    }

}
