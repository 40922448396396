<div class="nav fixed-top">
    <app-navbar></app-navbar>
</div>
<main (scroll)="onScroll($event)">
    <div>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <div (click)="onMenu()" class="spin-menu icons-wrapper d-none d-sm-none d-md-block" *ngIf="togglemenu">
        <div class="banner-container">
            <div class="banner">
                <div class="banner-full-height outline">
                    <div class="banner-full-height inline">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div (swipeup)="swipeup($event)" (swipedown)="swipedown($event)"
        [className]="'fixed-top-menu box-circle top-0 mt-70px left-50 circle'+menuClass">
        <div class="row h-100 w-100 imagepreview m-0 align-items-start">
            <div class="col-12 h-15 d-flex justify-content-center p-0">
                <div class="d-flex justify-content-center w-100">
                    <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[0]"
                        (click)="rotateImage(0,true)">
                        <img class="w-100 wheel fa-accessible-icon" src="../../assets/img/icon/menu/topup.svg">
                        <!-- <i class="fab wheel fa-accessible-icon fa-4x animated"></i> -->
                    </div>
                </div>
            </div>
            <div class="col-12 h-20 d-flex justify-content-between align-items-start pt-0 pb-0 pl-6 pr-6">
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[7]"
                    (click)="rotateImage(7,true)">
                    <img class="w-100 wheel fa-air-freshener" src="../../assets/img/icon/menu/faq.svg">
                    <!-- <i class="fas wheel fa-air-freshener fa-4x animated"></i> -->
                </div>
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[1]"
                    (click)="rotateImage(1,true)">
                    <img class="w-100 wheel fa-accusoft" src="../../assets/img/icon/menu/package.png">
                    <!-- <i class="fab wheel fa-accusoft fa-4x animated"></i> -->
                </div>
            </div>
            <div class="col-12 h-30 d-flex justify-content-between align-items-center p-0">
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[6]"
                    (click)="rotateImage(6,true)">
                    <img class="w-100 wheel fa-affiliatetheme" src="../../assets/img/icon/menu/onlinesimcard.png">
                    <!-- <i class="fab wheel fa-affiliatetheme fa-4x animated"></i> -->
                </div>
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[2]"
                    (click)="rotateImage(2,true)">
                    <img class="w-100 wheel fa-allergies" src="../../assets/img/icon/menu/register.svg">
                    <!-- <i class="fas wheel fa-allergies fa-4x animated"></i> -->
                </div>
            </div>
            <div class="col-12 h-20 d-flex justify-content-between align-items-end pt-0 pb-0 pl-6 pr-6">
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[5]"
                    (click)="rotateImage(5,true)">
                    <img class="w-100 wheel fa-ambulance" src="../../assets/img/icon/menu/aboutus.svg">
                    <!-- <i class="fas wheel fa-ambulance fa-4x animated"></i> -->
                </div>
                <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[3]"
                    (click)="rotateImage(3,true)">
                    <img class="w-100 wheel fa-apple-alt" src="../../assets/img/icon/menu/store.svg">
                    <!-- <i class="fas wheel fa-apple-alt fa-4x animated"></i> -->
                </div>
            </div>
            <div class="col-12 h-15 d-flex justify-content-center align-items-end p-0">
                <div class="d-flex justify-content-center w-100">
                    <div [className]="'circle-70px cursor-on shadow d-flex justify-content-center align-items-center menu-color'+boxMenu[4]"
                        (click)="rotateImage(4,true)">
                        <img class="w-100 wheel fa-address-book" src="../../assets/img/icon/menu/ondemand.svg">
                        <!-- <i class="far wheel fa-address-book fa-4x animated"></i> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="position-relative d-flex close-menu pr-3 pl-3">
            <div class="w-25">
                <div (click)="onClick()" class="text-white w-fit text-center animated fadeIn">
                    <a><i class="fas fa-times-circle menu-color fa-2x rounded-circle shadow"></i></a>
                </div>
            </div>
        </div>
        <div class="position-relative d-flex text-fixed-wheel pr-3 pl-4">
            <div class="fs-16 color-soft-green w-50 text-center animated fadeIn d-flex justify-content-center">
                <div class="ml-3 shadow w-90px menu-color text-white p-1 rounded-pill">{{header}}</div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</main>
<script src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"></script>
<script>
    new WOW().init();
</script>