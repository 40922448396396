<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-09.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 36.png">
    </div>
    <div class="text-about row w-100 m-0 justify-content-center mt-3">
        <div class="col-lg-8 col-12 rounded-top-3 white pt-5 pb-3">
            <h1 class="fs-50 text-light-green-main">เกี่ยวกับ Feels</h1>
            <h2 class="line-h-100">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                (Feels Telecom Corporation Company Limited)</h2>
            <p class="text-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Feels ประกอบกิจการและให้บริการโทรคมนาคม
                โดยธุรกิจหลักเป็นผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่แบบ MVNO
                (Mobile Virtual Network Operator) เน้นการให้บริการไปยังลูกค้ากลุ่มเป้าหมายในกลุ่ม ลูกค้าองค์กร
                ลูกค้ากลุ่มนักท่องเที่ยว
                รวมถึงลูกค้าองค์กรที่ต้องการเชื่อมต่ออุปกรณ์อีเล็กทรอนิกส์กับสัญญาณโทรศัพท์เคลื่อนที่ (IoT) เป็นต้น
                และประกอบกิจการขายส่งบริการโทรคมนาคม MVNA (Mobile Virtual Network Aggregator) อีกด้วย</p>
            <h2 class="mb-2">ปัจจุบัน</h2>
            <p class="text-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทฯ ให้บริการโทรศัพท์เคลื่อนที่ 3G
                และ 4G บนคลื่นความถี่
                2100 MHzและ 2300 MHz
                บนโครงข่ายของ บริษัท โทรคมนาคมแห่งชาติ จำกัด (มหาชน) โดยให้บริการ Mobile Voice, บริการ Mobile Data
                บริการรับ-ส่งข้อความ SMS
                และบริการดิจิทัลเสริมอื่นๆ (IoT)
                การให้บริการและแพ็กเกจของบริษัทฯจัดทำในรูปแบบการมุ่งเน้นไปยังลูกค้ากลุ่มเป้าหมาย เช่น กลุ่มลูกค้าบริษัท
                และกลุ่มลูกค้าพนักงานบริษัท เป็นต้น เพื่อตอบโจทย์ความต้องการของแต่ละกลุ่มเป้าหมายผ่าน แพ็กเกจ
                และโปรโมชั่นที่บริษัทจัดทำขึ้นให้เหมาะสมสำหรับแต่ละกลุ่มลูกค้าของบริษัทฯ</p>
            <hr style="margin-left: 20%; margin-right: 20%;">
            <a>Feel the limitless mobile freedom</a><br>
            <a style="font-weight: 200;">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</a><br>
            <input type="submit" class="btn bg-soft-green text-white rounded-pill mt-3" value="Feel Now"
                [routerLink]="['/packages']">
        </div>
    </div>
</main>