import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  public paymentchannel: string = "alipay";
  private API_URL = environment.API_URL;
  private API_URL_PROD = environment.API_URL_PROD;
  private KEY = environment.key
  public urlpayment: string = "https://qr.alipay.com/bax01781puutepivcwnv8074";
  selectPackObj: Object;
  selectPackCode: string;
  selectPackPrice: string;
  dataPack: JSON;
  phoneNumber: string;
  selectPayment: string;
  public resPackOndemand: Subject<Object> = new Subject<Object>();
  constructor(private http: HttpClient) { }
  getPackages(packagetype: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = this.API_URL_PROD + '/feelswebapp/getpackage'
    let body = JSON.stringify({ packagetype });
    var result = this.http.post(url, body, { responseType: 'text', headers })
    console.log(result)
    return result

  }
  payment(phonenumber: string, amount: string, paymentchannel: string, packagecode: string): Observable<string> {
    var headers = new HttpHeaders().set('Content-Type', 'application/json');
    let body = JSON.stringify({ phonenumber, amount, paymentchannel, packagecode });
    const url = this.API_URL_PROD + '/feelswebapp/package'
    var result = this.http.post(url, body, { responseType: 'text', headers })
    return result
  }
  process() {
    this.phoneNumber = '66' + this.phoneNumber.slice(1, 10)
    this.payment(this.phoneNumber, this.selectPackPrice, this.selectPayment, this.selectPackCode).subscribe((res: any) => [
      this.resPackOndemand.next(JSON.parse(res + ''))
    ])
  }
  ngOnInit() {

  }
}
