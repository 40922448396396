<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-md-8">
            <div class="col-12">
                <form class="mt-3" [formGroup]="registerForm">
                    <div class="row">
                        <!-- <div class="col-12">
                            <h2 class="text-left text-light-green-main">ข้อมูลส่วนตัว</h2>
                        </div> -->
                        <div class="col-12 text-left">
                            <div class="form-group">
                                <label>รหัสประจำตัวประชาชน<span class="required-text">*</span></label>
                                <input type="tel" name="citizenid" #citizenid formControlName="citizenid"
                                    class="form-control" placeholder="จำเป็นต้องกรอก" (keypress)="numberOnly($event)"
                                    autocomplete="off"
                                    onKeyDown="if(this.value.length==13 && event.keyCode!=8) return false;">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>ชื่อ<span class="required-text">*</span></label>
                                <input type="text" name="firstname" #firstname formControlName="firstname"
                                    class="form-control" placeholder="จำเป็นต้องกรอก" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>นามสกุล<span class="required-text">*</span></label>
                                <input type="text" name="lastname" #lastname formControlName="lastname"
                                    class="form-control" placeholder="จำเป็นต้องกรอก">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>ศาสนา</label>
                                <input type="text" name="religion" formControlName="religion" class="form-control"
                                    placeholder="หากไม่ทราบให้ว่างไว้">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)</label>
                                <!-- <div class="input-group">
                                    <input class="form-control" name="birthday" angular-mydatepicker autocomplete="off"
                                        #dp="angular-mydatepicker" [(ngModel)]="birthdaymodel" [options]="beforeOptions"
                                        [locale]="locale" id="birthday" formControlName="birthday"
                                        (dateChanged)="onDateChanged($event)" (click)="dp.toggleCalendar()"
                                        placeholder="หากไม่ทราบให้ว่างไว้" />
                                </div> -->
                                <div class="input-group">
                                    <input type="text" name="birthday" formControlName="birthday" class="form-control"
                                        matInput mask="00/00/0000" [showMaskTyped]="true"
                                        placeholder="หากไม่ทราบให้ว่างไว้">
                                    <div class="input-group-append">
                                        <span class="input-group-text">หากไม่ทราบให้ว่างไว้</span>
                                    </div>
                                </div>
                                <!-- <input type="text" name="birthday" formControlName="birthday" class="form-control"
                                    matInput mask="00/00/0000 (หากไม่ทราบให้ว่างไว้)" [showMaskTyped]="true"
                                    placeholder="หากไม่ทราบให้ว่างไว้"> -->
                            </div>
                        </div>
                        <div class="col-12 text-left">
                            <div class="form-group">
                                <label>ที่อยู่<span class="required-text">*</span></label>
                                <textarea name="address" #address formControlName="address" class="form-control"
                                    placeholder="จำเป็นต้องกรอก" autosize>Some text</textarea>
                                <!-- <input type="text" name="address" #address formControlName="address"
                                    class="form-control" placeholder="จำเป็นต้องกรอก"> -->
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>วันออกบัตร (ปีคริสต์ศักราช)</label>
                                <div class="input-group">
                                    <input type="text" name="dateissue" formControlName="dateissue" class="form-control"
                                        matInput mask="00/00/0000" [showMaskTyped]="true"
                                        placeholder="หากไม่ทราบให้ว่างไว้">
                                    <div class="input-group-append">
                                        <span class="input-group-text">หากไม่ทราบให้ว่างไว้</span>
                                    </div>
                                </div>
                                <!-- <input class="form-control" name="dateissue" angular-mydatepicker autocomplete="off"
                                    #dp2="angular-mydatepicker" [(ngModel)]="issuemodel" [options]="beforeOptions"
                                    [locale]="locale" id="dateissue" formControlName="dateissue"
                                    (dateChanged)="onDateChanged($event)" (click)="dp2.toggleCalendar()"
                                    placeholder="หากไม่ทราบให้ว่างไว้" /> -->
                                <!-- <input type="text" name="dateissue" formControlName="dateissue" class="form-control"> -->
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>วันบัตรหมดอายุ (ปีคริสต์ศักราช)</label>
                                <div class="input-group">
                                    <input type="text" name="dateexpire" formControlName="dateexpire"
                                        class="form-control" matInput mask="00/00/0000" [showMaskTyped]="true"
                                        placeholder="หากไม่ทราบให้ว่างไว้">
                                    <div class="input-group-append">
                                        <span class="input-group-text">หากไม่ทราบให้ว่างไว้</span>
                                    </div>
                                </div>
                                <!-- <input class="form-control" name="dateexpire" angular-mydatepicker autocomplete="off"
                                    #dp3="angular-mydatepicker" [(ngModel)]="expiremodel" [options]="afterOptions"
                                    [locale]="locale" id="dateexpire" formControlName="dateexpire"
                                    (dateChanged)="onDateChanged($event)" (click)="dp3.toggleCalendar()"
                                    placeholder="หากไม่ทราบให้ว่างไว้" /> -->
                                <!-- <input type="text" name="dateexpire" formControlName="dateexpire" class="form-control"> -->
                            </div>
                        </div>
                        <div class="col-12 text-left">
                            <div class="form-group">
                                <label>รหัสหลังบัตรประชาชน<span class="required-text">*</span></label>
                                <input type="text" name="lasercode" #lasercode formControlName="lasercode"
                                    class="form-control" placeholder="จำเป็นต้องกรอก" matInput mask="AA0-0000000-00"
                                    [clearIfNotMatch]="true" [validation]="true">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>เบอร์ติดต่อกลับ<span class="required-text">*</span></label>
                                <input type="text" name="contactnumber" #contactnumber formControlName="contactnumber"
                                    class="form-control" placeholder="จำเป็นต้องกรอก" prefix="0" mask="000000000"
                                    [clearIfNotMatch]="true" [validation]="true">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>เบอร์ที่ต้องการลงทะเบียน<span class="required-text">*</span></label>
                                <input type="text" name="phonenumber" #phonenumber formControlName="phonenumber"
                                    class="form-control" placeholder="จำเป็นต้องกรอก" prefix="0" mask="000000000"
                                    [clearIfNotMatch]="true" [validation]="true">
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group">
                                <label>ค่ายมือถือเดิม<span class="required-text">*</span></label>
                                <ng-select class="col-md-12 p-0" [items]="oparator" name="oparator"
                                    bindLabel="displayname" bindValue="value" [loading]="false" [virtualScroll]="true"
                                    placeholder="จำเป็นต้องเลือก" formControlName="oparator">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            <span>{{item.displayname}}</span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left" *ngIf="f.oparator.value === 'more'">
                            <div class="form-group">
                                <label>ค่ายมือถืออื่น ๆ</label>
                                <input type="text" name="another" #another formControlName="another"
                                    class="form-control" placeholder="จำเป็นต้องกรอก">
                            </div>
                        </div>
                        <div class="col-12 text-center p-3">
                            <button mdbBtn type="button" (click)="next()"
                                [disabled]="(!citizenid.value && !firstname.value && !lastname.value && !address.value && !lasercode.value && !contactnumber.value && !phonenumber.value)"
                                class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ถัดไป</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>