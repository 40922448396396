import { Component, OnInit } from '@angular/core';
import { OnlinesimService } from '../../../services/onlinesim.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-onlinesimsummary',
    templateUrl: './onlinesimsummary.component.html',
    styleUrls: ['./onlinesimsummary.component.scss']
})
export class OnlinesimsummaryComponent implements OnInit {
    paymentchannel: string;

    orderitemdetail: Array<Object> = [];
    totaloriginprice: string;
    totaltaxprice: string;
    totalprice: string;
    name: string;
    address: string = '';
    contactnumber: string;

    constructor(private onlinesimService: OnlinesimService) {
        console.log(this.onlinesimService.summarydetail);
        console.log(this.onlinesimService.price);
        this.orderitemdetail = this.onlinesimService.summarydetail;
        this.totaloriginprice = this.formatMoney(this.onlinesimService.price, 2);
        if (parseFloat(this.totaloriginprice) === parseInt(this.totaloriginprice)) {
            this.totaloriginprice = this.totaloriginprice.toString();
        }
        this.totaltaxprice = this.formatMoney(this.onlinesimService.price * 0.07, 2);
        if (parseFloat(this.totaltaxprice) === parseInt(this.totaltaxprice)) {
            this.totaltaxprice = this.totaltaxprice.toString();
        }
        this.totalprice = this.formatMoney(+this.totaloriginprice + +this.totaltaxprice, 2);
        if (parseFloat(this.totalprice) === parseInt(this.totalprice)) {
            this.totalprice = this.totalprice.toString();
        }
        this.name = this.onlinesimService.firstname + " " + this.onlinesimService.lastname;
        this.address += this.onlinesimService.housenumber;
        if (this.onlinesimService.housename) {
            this.address += ' ' + this.onlinesimService.housename;
        }
        this.address += ', ' + this.onlinesimService.subdistrict;
        this.address += ', ' + this.onlinesimService.district;
        this.address += ', ' + this.onlinesimService.province;
        this.address += ', ' + this.onlinesimService.zipcode;
        this.contactnumber = this.onlinesimService.contactnumber;
    }

    ngOnInit(): void {
    }

    formatMoney(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    edit() {
        this.onlinesimService.page.next('personalinfo');
    }

    radio(event) {
        const inputValue = event.target.value;
        this.paymentchannel = inputValue;
    }

    submit() {
        if (this.paymentchannel) {
            this.onlinesimService.loading.next(true);
            let jsonparam = {
                'productid': JSON.stringify(this.onlinesimService.summarydetail),
                'paymentchannel': this.paymentchannel,
                'firstname': this.onlinesimService.firstname,
                'lastname': this.onlinesimService.lastname,
                'contactnumber': this.onlinesimService.contactnumber,
                'birthday': this.onlinesimService.birthday,
                'personaltype': this.onlinesimService.personaltype === 'idcard' ? 'C' : 'P',
                'personalid': this.onlinesimService.personalid,
                'housenumber': this.onlinesimService.housenumber,
                'province': this.onlinesimService.province,
                'district': this.onlinesimService.district,
                'subdistrict': this.onlinesimService.subdistrict,
                'zipcode': this.onlinesimService.zipcode
            };
            if (this.onlinesimService.email) {
                jsonparam['email'] = this.onlinesimService.email;
            }
            if (this.onlinesimService.housename) {
                jsonparam['housename'] = this.onlinesimService.housename;
            }
            console.log(jsonparam);
            this.onlinesimService.orderNumber(jsonparam).subscribe(
                (res: any) => {
                    let json_res = JSON.parse(res);
                    this.onlinesimService.loading.next(false);
                    if (json_res.responsecode === 0) {
                        this.onlinesimService.ordernumber = json_res.ordernumber;
                        this.onlinesimService.qrcode = json_res.qrcode;
                        this.onlinesimService.expireorder = json_res.expiredorder;
                        this.onlinesimService.paymentchannel = this.paymentchannel;
                        this.onlinesimService.page.next('result');
                    }
                },
                (err: any) => { }
            );
        } else {
            Swal.fire({
                title: 'คำเตือน',
                html: '<div class="alert-text">กรุณาเลือกช่องทางชำระเงิน</div>',
                icon: 'warning',
                confirmButtonColor: "#28a745",
                confirmButtonText: "ตกลง",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            }).then((result) => {
            })
        }
    }

}
