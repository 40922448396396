import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-personalinfo',
    templateUrl: './personalinfo.component.html',
    styleUrls: ['./personalinfo.component.scss']
})
export class PersonalinfoComponent implements OnInit {
    beforeOptions: IAngularMyDpOptions = {
        dateRange: false,
        firstDayOfWeek: 'su',
        disableSince: { year: 0, month: 0, day: 0 },
        dateFormat: 'dd/mm/yyyy'
        // other options here
    }
    afterOptions: IAngularMyDpOptions = {
        dateRange: false,
        firstDayOfWeek: 'su',
        disableSince: { year: 0, month: 0, day: 0 },
        dateFormat: 'dd/mm/yyyy'
        // other options here
    }
    birthdaymodel: IMyDateModel = null;
    issuemodel: IMyDateModel = null;
    expiremodel: IMyDateModel = null;
    locale: string = 'th';

    registerForm: FormGroup;
    submitted = false;
    disable: boolean = true;

    citizenid: string = '';
    firstname: string = '';
    lastname: string = '';
    religion: string = '';
    birthday: string = '';
    address: string = '';
    dateissue: string = '';
    dateexpire: string = '';
    lasercode: string = '';
    contactnumber: string = '';
    phonenumber: string;

    // public customPatterns = { '0': { pattern: new RegExp('\[A-Z0-9\]') } };

    constructor(private register: RegisterService, private formBuilder: FormBuilder) {
        this.phonenumber = this.register.phonenumber ? '0' + this.register.phonenumber.slice(2) : '';
        if (this.register.citizenid) this.citizenid = this.register.citizenid;
        if (this.register.firstname) this.firstname = this.register.firstname;
        if (this.register.lastname) this.lastname = this.register.lastname;
        if (this.register.religion) this.religion = this.register.religion;
        if (this.register.birthday) this.birthday = this.register.birthday;
        if (this.register.address) this.address = this.register.address;
        if (this.register.dateissue) this.dateissue = this.register.dateissue;
        if (this.register.dateexpire) this.dateexpire = this.register.dateexpire;
        if (this.register.lasercode) this.lasercode = this.register.lasercode;
        this.disableSince();
        this.disableUntil();
        // this.getCopyOfOptionsBefore();
        // this.getCopyOfOptionsAfter();
    }

    ngOnInit(): void {
        let birthday: string;
        let dateissue: string;
        let dateexpire: string;
        if (this.birthday !== '') {
            birthday = this.birthday.slice(6) + this.birthday.slice(4, 6) + this.birthday.slice(0, 4);
        } else {
            birthday = '';
        }
        if (this.dateissue !== '') {
            dateissue = this.dateissue.slice(6) + this.dateissue.slice(4, 6) + this.dateissue.slice(0, 4);
        } else {
            dateissue = '';
        }
        if (this.dateexpire !== '') {
            dateexpire = this.dateexpire.slice(6) + this.dateexpire.slice(4, 6) + this.dateexpire.slice(0, 4);
        } else {
            dateexpire = '';
        }
        this.registerForm = this.formBuilder.group({
            citizenid: [this.citizenid, Validators.required],
            firstname: [this.firstname, Validators.required],
            lastname: [this.lastname, Validators.required],
            birthday: [birthday],
            religion: [this.religion],
            address: [this.address, Validators.required],
            dateissue: [dateissue],
            dateexpire: [dateexpire],
            lasercode: [this.lasercode, Validators.required],
            contactnumber: ['', Validators.required]
        });
        // this.setBirthday();
    }

    get f() {
        return this.registerForm.controls;
    }

    onDateChanged(event: IMyDateModel): void {
        // date selected
        // console.log(event);
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    disableSince() {
        let d: Date = new Date();
        d.setDate(d.getDate() + 1);
        let copy: IAngularMyDpOptions = this.getCopyOfOptionsBefore();
        copy.disableSince = {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate()
        };
        this.beforeOptions = copy;
    }

    getCopyOfOptionsBefore(): IAngularMyDpOptions {
        return JSON.parse(JSON.stringify(this.beforeOptions));
    }

    disableUntil() {
        let d: Date = new Date();
        d.setDate(d.getDate() - 1);
        let copy: IAngularMyDpOptions = this.getCopyOfOptionsAfter();
        copy.disableUntil = {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate()
        };
        this.afterOptions = copy;
    }

    getCopyOfOptionsAfter(): IAngularMyDpOptions {
        return JSON.parse(JSON.stringify(this.afterOptions));
    }

    // setBirthday() {
    //     let date = new Date(this.birthday.slice(0, 4) + '-' + this.birthday.slice(4, 6) + '-' + this.birthday.slice(6));
    //     console.log(date);
    //     let model: IMyDateModel = { isRange: false, singleDate: { jsDate: date }, dateRange: null };
    //     return model
    //     // {
    //     //     date: {
    //     //         year: date.getFullYear(),
    //     //         month: date.getMonth() + 1,
    //     //         day: date.getDate()
    //     //     }
    //     // });
    // }

    calldopa(phonenumber, citizenid, firstname, lastname, birthday, lasercode) {
        this.register.calldopa(phonenumber, citizenid, firstname, lastname, birthday, lasercode).subscribe(
            (res: any) => {
                let json_res = JSON.parse(res);
                this.register.loading.next(false);
                if (json_res.responsecode === 0) {
                    this.register.citizenid = this.registerForm.value.citizenid;
                    this.register.firstname = this.registerForm.value.firstname;
                    this.register.lastname = this.registerForm.value.lastname;
                    this.register.birthday = this.registerForm.value.birthday;
                    this.register.religion = this.registerForm.value.religion;
                    this.register.address = this.registerForm.value.address;
                    this.register.dateissue = this.registerForm.value.dateissue;
                    this.register.dateexpire = this.registerForm.value.dateexpire;
                    this.register.lasercode = this.registerForm.value.lasercode;
                    this.register.contactnumber = '66' + this.registerForm.value.contactnumber;
                    this.register.page.next('personalimage');
                } else {
                    Swal.fire({
                        title: 'ผิดพลาด! ' + json_res.responsecode,
                        html: '<div class="alert-text">' + json_res.messages + ' กรุณาลองใหม่อีกครั้ง</div>',
                        icon: 'warning',
                        confirmButtonColor: "#28a745",
                        confirmButtonText: "ตกลง",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                    })
                }
            },
            (err: any) => {
                this.register.loading.next(false);
                Swal.fire({
                    title: 'ระบบมีปัญหา!',
                    html: '<div class="alert-text">ระบบมีปัญหา ' + err.status + ' ' + err.statusText + ' ' + err.error + ' กรุณาติดต่อเจ้าหน้าที่</div>',
                    icon: 'error',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        );
    }

    nextPage() {
        if (this.registerForm.invalid) {
            Swal.fire({
                title: 'ผิดพลาด!',
                html: '<div class="alert-text">กรุณากรอกข้อมูลให้เรียบร้อย</div>',
                icon: 'warning',
                confirmButtonColor: "#28a745",
                confirmButtonText: "ตกลง",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            }).then((result) => {
            })
            return;
        }
        this.register.loading.next(true);
        if (this.registerForm.value.birthday === null || this.registerForm.value.birthday === '') {
            this.registerForm.value.birthday = '00000000';
            // }
        } else {
            let date = this.registerForm.value.birthday;
            this.registerForm.value.birthday = date.slice(4) + date.slice(2, 4) + date.slice(0, 2);
        }
        // } else {
        //     let date = this.registerForm.value.birthday.singleDate.date;
        //     this.registerForm.value.birthday = date.year.toString() + date.month.toString() + date.day.toString();
        //     // this.registerForm.value.birthday = date.slice(4) + date.slice(2, 4) + date.slice(0, 2);
        // }
        if (this.registerForm.value.dateissue === null || this.registerForm.value.dateissue === '') {
            this.registerForm.value.dateissue = '00000000';
        } else {
            let date = this.registerForm.value.dateissue;
            this.registerForm.value.dateissue = date.slice(4) + date.slice(2, 4) + date.slice(0, 2);
        }
        if (this.registerForm.value.dateexpire === null || this.registerForm.value.dateexpire === '') {
            this.registerForm.value.dateexpire = '00000000';
        } else {
            let date = this.registerForm.value.dateexpire;
            this.registerForm.value.dateexpire = date.slice(4) + date.slice(2, 4) + date.slice(0, 2);
        }
        this.calldopa(this.register.phonenumber, this.registerForm.value.citizenid, this.registerForm.value.firstname, this.registerForm.value.lastname, this.registerForm.value.birthday, this.registerForm.value.lasercode);
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
        // this.register.page.next('personalimage');
    }

}
