<main>
    <!-- <div class="banner">
        <div class="banner-wrapper">
            <h1 class="fs-50">ตั้งค่าเครื่อง</h1>
        </div>
    </div> -->
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/mobile/handon2.svg">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 39 copy 3.png">
    </div>
    <div class="text-about mt-3">
        <div class="row w-100 m-0 white p-lg-5 p-3 pt-4 rounded-top-3 text-left">
            <div class="col-lg-4 col-12 order-0">
                <div class="d-flex mb-3">
                    <h1 class="fs-50 m-0 line-h-100 text-light-green-main">คู่มือการใช้งาน</h1>
                </div>
                <div class="menu"><a (click)="onClick('android')" *ngIf="check(setting,'android')"
                        style="border-bottom: 2px solid #50d587;">การตั้งค่า APN สำหรับอุปกรณ์ Android</a>
                </div>
                <div class="menu"><a (click)="onClick('android')" *ngIf="checkfalse(setting,'android')">การตั้งค่า APN
                        สำหรับอุปกรณ์ Android</a>
                </div>
                <div class="menu"><a (click)="onClick('iOS')" *ngIf="checkfalse(setting,'iOS')">การตั้งค่า APN
                        สำหรับอุปกรณ์ iOS</a>
                </div>
                <div class="menu"><a (click)="onClick('iOS')" *ngIf="check(setting,'iOS')"
                        style="border-bottom: 2px solid #50d587;">การตั้งค่า APN สำหรับอุปกรณ์ iOS</a>
                </div>
                <div class="menu"><a (click)="onClick('moAn')"
                        *ngIf="checkfalse(setting,'moAn')">การเปลี่ยนโหมดเครือข่าย และการค้นหาสัญญาณโทรศัพท์บนอุปกรณ์
                        Android</a>
                </div>
                <div class="menu"><a (click)="onClick('moAn')" *ngIf="check(setting,'moAn')"
                        style="border-bottom: 2px solid #50d587;">การเปลี่ยนโหมดเครือข่าย
                        และการค้นหาสัญญาณโทรศัพท์บนอุปกรณ์ Android</a>
                </div>
                <div class="menu"><a (click)="onClick('moIos')"
                        *ngIf="checkfalse(setting,'moIos')">การเปลี่ยนโหมดเครือข่าย และการค้นหาสัญญาณโทรศัพท์บน iOS</a>
                </div>
                <div class="menu"><a (click)="onClick('moIos')" *ngIf="check(setting,'moIos')"
                        style="border-bottom: 2px solid #50d587;">การเปลี่ยนโหมดเครือข่าย และการค้นหาสัญญาณโทรศัพท์บน
                        iOS</a>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-3 mt-lg-0 order-1 order-lg-2">
                <div style="font-size: 20px; font-weight: 500; margin-bottom:
                 15px; display: inline-block;" *ngFor="let topic of topics; let index=index">
                    <div class="square bg-soft-green" style="height: 15px;" *ngIf="convertSetting(index)"></div>
                    <a *ngIf="convertSetting(index)">{{topic}}</a>
                </div><br>
                <p *ngIf="check(setting,'android')">
                    <a *ngFor="let step of steps; let index=index">
                        <a *ngIf="activeSlideIndex===index"
                            style="color: #50d587; font-weight: 400;">{{step.android}}<br></a>
                        <a *ngIf="activeSlideIndex!==index">{{step.android}}<br></a>
                    </a>
                </p>
                <p *ngIf="check(setting,'iOS')">
                    <a *ngFor="let step of steps; let index=index">
                        <a *ngIf="activeSlideIndex===index"
                            style="color: #50d587; font-weight: 400;">{{step.iOS}}<br></a>
                        <a *ngIf="activeSlideIndex!==index">{{step.iOS}}<br></a>
                    </a>
                </p>
                <p *ngIf="check(setting,'moAn')">
                    <a *ngFor="let step of steps; let index=index">
                        <a *ngIf="activeSlideIndex===index"
                            style="color: #50d587; font-weight: 400;">{{step.momoAn}}<br></a>
                        <a *ngIf="activeSlideIndex!==index">{{step.momoAn}}<br></a>
                    </a>
                </p>
                <p *ngIf="check(setting,'moIos')">
                    <a *ngFor="let step of steps; let index=index">
                        <a *ngIf="activeSlideIndex===index"
                            style="color: #50d587; font-weight: 400;">{{step.moIos}}<br></a>
                        <a *ngIf="activeSlideIndex!==index">{{step.moIos}}<br></a>
                    </a>
                </p>
            </div>
            <div class="col-lg-4 col-12 order-2 order-lg-1">
                <div style="width: fit-content">
                    <mdb-carousel [isControls]="true" [animation]="'slide'" [interval]="'0'"
                        [(activeSlide)]="activeSlideIndex" (activeSlideChange)="activeSlideChange()">
                        <mdb-slide *ngFor="let slide of slidesX; let index=index" style="width: 100%;">
                            <img class="d-block w-100" style=" object-fit: contain;" [src]="slide.img">
                            <div class="carousel-caption">
                                <div class="elegant-color shadow rounded-pill">
                                    <h4>ขั้นตอนที่ {{index +1}}</h4>
                                </div>
                            </div>
                        </mdb-slide>
                    </mdb-carousel>
                </div>
            </div>
        </div>
    </div>
</main>