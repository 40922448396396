import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NgSelectConfig } from '@ng-select/ng-select';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-portinpersonalinfo',
    templateUrl: './portinpersonalinfo.component.html',
    styleUrls: ['./portinpersonalinfo.component.scss']
})
export class PortinpersonalinfoComponent implements OnInit {
    beforeOptions: IAngularMyDpOptions = {
        dateRange: false,
        firstDayOfWeek: 'su',
        disableSince: { year: 0, month: 0, day: 0 },
        dateFormat: 'dd/mm/yyyy'
        // other options here
    }
    afterOptions: IAngularMyDpOptions = {
        dateRange: false,
        firstDayOfWeek: 'su',
        disableSince: { year: 0, month: 0, day: 0 },
        dateFormat: 'dd/mm/yyyy'
        // other options here
    }
    birthdaymodel: IMyDateModel = null;
    issuemodel: IMyDateModel = null;
    expiremodel: IMyDateModel = null;
    locale: string = 'th';

    registerForm: FormGroup;
    submitted = false;
    disable: boolean = true;

    citizenid: string = '';
    firstname: string = '';
    lastname: string = '';
    religion: string = '';
    birthday: string = '';
    address: string = '';
    dateissue: string = '';
    dateexpire: string = '';
    lasercode: string = '';
    contactnumber: string = '';
    phonenumber: string;

    oparator: Object = [
        {
            "displayname": "Aces",
            "value": "true"
        },
        {
            "displayname": "AIS",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "CAT",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "Data CDMA",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "DTAC",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "FINN MOBILE",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "Loxley",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "NU mobile",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "Penguin",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "Real Move",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "TOT",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "True",
            "value": "พาสปอร์ต"
        },
        {
            "displayname": "อื่น ๆ",
            "value": "more"
        }
    ];

    constructor(private register: RegisterService, private formBuilder: FormBuilder, private ngSelectConfig: NgSelectConfig) {
        this.ngSelectConfig.notFoundText = 'ไม่พบข้อมูล';
        if (this.register.citizenid) this.citizenid = this.register.citizenid;
        if (this.register.firstname) this.firstname = this.register.firstname;
        if (this.register.lastname) this.lastname = this.register.lastname;
        if (this.register.religion) this.religion = this.register.religion;
        if (this.register.birthday) this.birthday = this.register.birthday;
        if (this.register.address) this.address = this.register.address;
        if (this.register.dateissue) this.dateissue = this.register.dateissue;
        if (this.register.dateexpire) this.dateexpire = this.register.dateexpire;
        if (this.register.lasercode) this.lasercode = this.register.lasercode;
        this.disableSince();
        this.disableUntil();
    }

    ngOnInit(): void {
        let birthday: string;
        let dateissue: string;
        let dateexpire: string;
        if (this.birthday !== '') {
            birthday = this.birthday.slice(6) + this.birthday.slice(4, 6) + this.birthday.slice(0, 4);
        } else {
            birthday = '';
        }
        if (this.dateissue !== '') {
            dateissue = this.dateissue.slice(6) + this.dateissue.slice(4, 6) + this.dateissue.slice(0, 4);
        } else {
            dateissue = '';
        }
        if (this.dateexpire !== '') {
            dateexpire = this.dateexpire.slice(6) + this.dateexpire.slice(4, 6) + this.dateexpire.slice(0, 4);
        } else {
            dateexpire = '';
        }
        this.registerForm = this.formBuilder.group({
            citizenid: [this.citizenid, Validators.required],
            firstname: [this.firstname, Validators.required],
            lastname: [this.lastname, Validators.required],
            birthday: [birthday],
            religion: [this.religion],
            address: [this.address, Validators.required],
            dateissue: [dateissue],
            dateexpire: [dateexpire],
            lasercode: [this.lasercode, Validators.required],
            contactnumber: ['', Validators.required],
            phonenumber: ['', Validators.required],
            oparator: [null, Validators.required]
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onDateChanged(event: IMyDateModel): void {
        // date selected
        // console.log(event);
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    disableSince() {
        let d: Date = new Date();
        d.setDate(d.getDate() + 1);
        let copy: IAngularMyDpOptions = this.getCopyOfOptionsBefore();
        copy.disableSince = {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate()
        };
        this.beforeOptions = copy;
    }

    getCopyOfOptionsBefore(): IAngularMyDpOptions {
        return JSON.parse(JSON.stringify(this.beforeOptions));
    }

    disableUntil() {
        let d: Date = new Date();
        d.setDate(d.getDate() - 1);
        let copy: IAngularMyDpOptions = this.getCopyOfOptionsAfter();
        copy.disableUntil = {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate()
        };
        this.afterOptions = copy;
    }

    getCopyOfOptionsAfter(): IAngularMyDpOptions {
        return JSON.parse(JSON.stringify(this.afterOptions));
    }

    next() {
        this.register.pageMove.next('customerimg');
    }

}
