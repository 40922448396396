import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-termpage',
    templateUrl: './termpage.component.html',
    styleUrls: ['./termpage.component.scss']
})
export class TermpageComponent implements OnInit {
    disable: boolean = true;

    constructor(private register: RegisterService) { }

    ngOnInit(): void {
    }

    pendingsim() {
        let postparam = {
            'personaltype': 'C',
            'personalid': this.register.citizenid,
            'firstname': this.register.firstname,
            'lastname': this.register.lastname,
            'birthday': this.register.birthday,
            'address': this.register.address,
            'dateissue': this.register.dateissue,
            'dateexpire': this.register.dateexpire,
            'lasercode': this.register.lasercode.split('-').join(''),
            'contactnumber': this.register.contactnumber,
            'activatednumber': this.register.phonenumber,
            'frontimg': this.register.frontidcardbase64,
            'frontangle': this.register.frontidcardangle,
            'backimg': this.register.backidcardbase64,
            'backangle': this.register.backidcardangle,
            'personalimg': this.register.personalbase64one,
            'personalangle': this.register.personalangleone,
            'personalimg2': this.register.personalbase64two,
            'personalangle2': this.register.personalangletwo,
            'simcardimg': this.register.simcardbase64
        }
        if (this.register.religion) {
            postparam['religion'] = this.register.religion;
        }
        this.register.pendingsim(postparam).subscribe(
            (res: any) => {
                let json_res = JSON.parse(res);
                this.register.loading.next(false);
                if (json_res.responsecode === 0) {
                    this.register.page.next('doneterm');
                } else {
                    if (json_res.responsecode === 101) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                        })
                    } else if (json_res.responsecode === 102) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                        })
                    }
                }
            },
            (err: any) => {
                this.register.loading.next(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        );
    }

    activeSim() {
        this.register.loading.next(false);
        this.register.page.next('doneterm');
    }

    nextPage() {
        this.register.loading.next(true);
        this.pendingsim();
    }

}
