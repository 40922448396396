import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

    indexFrom: number = 0;
    indexTo: number = 7;

    public slidesBanner: Array<Object> = [
        { "img": "../../../assets/img/banner/store-2-02.png" },
        { "img": "../../../assets/img/banner/bg4.jpg" }
    ]

    public contents: Array<Object> = [
        {
            "phone": {
                "id": 1,
                "img": "../../../assets/img/products/XIAOMI CM800-A.png",
                "price": "849",
                "modelname": "Xiaomi Deerma CM800"
            }
        },
        {
            "phone": {
                "id": 2,
                "img": "../../../assets/img/products/XIAOMI DX118c-A.jpg",
                "price": "599",
                "modelname": "Xiaomi Deerma DX118c"
            }
        },
        {
            "phone": {
                "id": 3,
                "img": "../../../assets/img/products/XIAOMI DX700-A.jpg",
                "price": "899",
                "modelname": "Xiaomi Deerma DX700"
            },
        },
        {
            "phone": {
                "id": 4,
                "img": "../../../assets/img/products/XIAOMI F301-A.jpeg",
                "price": "599",
                "modelname": "Xiaomi Deerma F301"
            },
        },
        {
            "phone": {
                "id": 5,
                "img": "../../../assets/img/products/XIAOMI F325-A.jpg",
                "price": "549",
                "modelname": "Xiaomi Deerma F325"
            },
        },
        {
            "phone": {
                "id": 6,
                "img": "../../../assets/img/products/XIAOMI MQ8111-A.png",
                "price": "349",
                "modelname": "Xiaomi Deerma MQ811"
            },
        },
        {
            "phone": {
                "id": 7,
                "img": "../../../assets/img/products/XIAOMI NU11-A.png",
                "price": "459",
                "modelname": "Xiaomi Deerma NU11"
            },
        },
        {
            "phone": {
                "id": 8,
                "img": "../../../assets/img/products/XIAOMI TB500-A.jpg",
                "price": "359",
                "modelname": "Xiaomi Deerma TB500"
            },
        },
        {
            "phone": {
                "id": 9,
                "img": "../../../assets/img/products/XIAOMI TB900-A.png",
                "price": "499",
                "modelname": "Xiaomi Deerma TB900"
            },
        }
    ]

    public slides: Array<Object> = [
        {
            "img": "../../../assets/img/products/XIAOMI F325-B.jpg",
            "phone": "../../../assets/img/products/FiGi FJ -1.jpg",
            "iot": "../../../assets/img/products/Main/D-Link - DER920 -A.png",
            "sim": "../../../assets/img/products/XIAOMI F325-B.jpg",
            "tablet": "../../../assets/img/products/XIAOMI F325-A.jpg",
        },
        {
            "img": "../../../assets/img/products/NU11 - 3.jpeg",
            "phone": "../../../assets/img/products/FiGi FJ -2.jpg",
            "iot": "../../../assets/img/products/Main/TK915 -A.PNG",
            "sim": "../../../assets/img/products/NU11 - 3.jpeg",
            "tablet": "../../../assets/img/products/XIAOMI F301-A.jpeg"
        }
    ]
    constructor(private prod: ProductService, private _router: Router) { }

    gotoProductPage(id: number) {
        this.prod.phoneModel = id
        this._router.navigate(["/product/" + id]);
    }

    lower(a: number, b: number) {
        return a < b
    }

    ngOnInit(): void {
    }

}
