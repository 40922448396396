<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-02.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 39 copy.png">
    </div>
    <div class="row m-0 p-0 pr-lg-3 pl-lg-3 mt-2">
        <div class="col-12 text-center col-lg-2 rounded-lg header p-2">
            <div
                class="w-100 h-100 d-flex align-items-center bg-soft-green-gradient text-white rounded-lg justify-content-center">
                <div class="header-text p-2">
                    <i class="fas fa-mobile-alt d-lg-block d-none fa-5x"></i>
                    <div class="mt-0 mt-lg-3 row justify-content-center align-items-center">
                        <i class="fas fa-mobile-alt d-block d-lg-none fa-3x mr-3"></i>
                        <p>โทรศัพท์มือถือ</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-12 d-flex text-center content p-2">
            <div class="w-100 h-100 d-flex white rounded-lg p-2">
                <div class="w-50 d-flex align-items-center">
                    <img class="w-100" src="../../../assets/img/products/FiGi - FJ-C.png">
                </div>
                <div class="w-50 d-flex align-items-center pl-2 pr-2">
                    <div class="text-right">
                        <h1>FiGi Smart Phone</h1>
                        <h1 class="text-light-green-main">
                            <small class="elegant-color-text">ราคา</small> 2,999
                            <small class="elegant-color-text"> บาท</small>
                        </h1>
                        <button mdbBtn type="button" (click)="gotoProductPage(10)"
                            class="bg-soft-green-gradient text-white rounded-pill" mdbWavesEffect>ซื้อเลย</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12 d-flex align-items-center justify-content-center text-center p-2">
            <div class="w-100 h-100 d-flex white rounded-lg p-2 align-items-center">
                <mdb-carousel [isControls]="false" [animation]="'slide'">
                    <mdb-slide *ngFor="let slide of slides; let index=index">
                        <img class="d-block w-100 slide-show" style="object-fit: cover;" [src]="slide.phone">
                    </mdb-slide>
                </mdb-carousel>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pr-lg-3 pl-lg-3">
        <div class="col-12 text-center col-lg-2 rounded-lg header p-2">
            <div
                class="w-100 h-100 d-flex align-items-center bg-soft-green-gradient text-white rounded-lg justify-content-center">
                <div class="header-text p-2">
                    <i class="fas fa-blender fa-5x d-lg-block d-none"></i>
                    <div class="mt-0 mt-lg-3 row justify-content-center align-items-center">
                        <i class="fas fa-blender fa-3x d-block d-lg-none mr-3"></i>
                        <p>ของใช้ในบ้าน</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-12 d-flex align-items-center justify-content-center text-center p-2">
            <div class="w-100 h-100 d-flex white rounded-lg p-2 align-items-center">
                <mdb-carousel [isControls]="false" [animation]="'slide'">
                    <mdb-slide *ngFor="let slide of slides; let index=index">
                        <img class="d-block w-100 slide-show" style="object-fit: cover;" [src]="slide.tablet">
                    </mdb-slide>
                </mdb-carousel>
            </div>
        </div>
        <div class="col-lg-6 col-12 row m-0 text-center content p-2">
            <div class="w-100 h-100 row over-flow-x-scroll m-0 white rounded-lg p-2 align-items-center">
                <div *ngFor="let content of contents; let index=index" class="col-lg-4 col-7 p-3">
                    <div class="phone-component">
                        <div (click)="gotoProductPage(content.phone.id)"
                            class="cursor-on w-100 border-phone rounded-lg p-2">
                            <img class="w-100" [src]="content.phone.img">
                            <div class="model-name">
                                <a id="{{content.phone.modelname}}">{{content.phone.modelname}}</a>
                            </div>
                            <a class="font-w-300">
                                <span
                                    class="text-light-green-main white font-weight-bold pl-2 pr-2 rounded-pill">{{content.phone.price}}</span>
                                บาท
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pr-lg-3 pl-lg-3">
        <div class="col-12 text-center col-lg-2 d-flex rounded-lg header p-2">
            <div
                class="w-100 h-100 d-flex align-items-center bg-soft-green-gradient text-white rounded-lg justify-content-center">
                <div class="header-text p-2">
                    <i class="fas fa-cloud fa-5x d-lg-block d-none"></i>
                    <div class="mt-0 mt-lg-3 row justify-content-center align-items-center">
                        <i class="fas fa-cloud fa-3x d-lg-none mr-2"></i>
                        <p>อุปกรณ์ Iot</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12 text-center content p-2">
            <div class="w-100 h-100 d-flex white rounded-lg p-2">
                <div class="row m-0 w-100">
                    <div class="col-4 d-flex align-items-center">
                        <img class="w-100" src="../../../assets/img/products/Main/TK915 -A.PNG">
                    </div>
                    <div class="col-8 d-flex align-items-center justify-content-end">
                        <div class="text-right">
                            <h1>TK915 3G GPS Tracker</h1>
                            <h1 class="text-light-green-main">
                                <small class="elegant-color-text">ราคา</small> 2,999
                                <small class="elegant-color-text"> บาท</small>
                            </h1>
                            <button mdbBtn type="button" (click)="gotoProductPage(11)"
                                class="bg-soft-green-gradient text-white rounded-pill" mdbWavesEffect>ซื้อเลย</button>
                        </div>
                    </div>
                    <div class="col-8 d-flex align-items-center">
                        <div class="text-left">
                            <h1>D-Link 4G Router (DWR-920) Wireless N300</h1>
                            <h1 class="text-light-green-main">
                                <small class="elegant-color-text">ราคา</small> 2,199
                                <small class="elegant-color-text"> บาท</small>
                            </h1>
                            <button mdbBtn type="button" (click)="gotoProductPage(12)"
                                class="bg-soft-green-gradient text-white rounded-pill" mdbWavesEffect>ซื้อเลย</button>
                        </div>
                    </div>
                    <div class="col-4 d-flex align-items-center">
                        <img class="w-100" src="../../../assets/img/products/Main/D-Link - DER920 -A.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-12 d-flex align-items-center justify-content-center text-center p-2">
            <div class="w-100 h-100 d-flex white rounded-lg p-2 align-items-center">
                <mdb-carousel [isControls]="false" [animation]="'slide'">
                    <mdb-slide *ngFor="let slide of slides; let index=index">
                        <img class="d-block w-100 slide-show" style="object-fit: cover;" [src]="slide.iot">
                    </mdb-slide>
                </mdb-carousel>
            </div>
        </div>
    </div>
</main>