import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    loading: boolean = false;

    constructor(private formBuilder: FormBuilder, private contactService: ContactService) { }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            contactnumber: ['', Validators.required],
            email: ['', Validators.required, Validators.email],
            text: ['', Validators.required],
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        // console.log(this.registerForm)

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        this.sendFeedback(this.registerForm.value);

        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    }

    // sendFeedback(form) {
    //     var submitvalue = JSON.parse(JSON.stringify(form));
    //     var message = "\nจาก : " + submitvalue.name + "\nเบอร์ : " + submitvalue.contactnumber + "\nอีเมล : " + submitvalue.email + "\n ข้อความ : " + submitvalue.text;
    //     var ipaddress = this.ipAddress;
    //     this.contactService.feedback(message, ipaddress).subscribe((res: any) => [
    //         console.log('test'),
    //         console.log(res)
    //     ])
    // }

    sendFeedback(form) {
        var submitvalue = JSON.parse(JSON.stringify(form));
        var name = submitvalue.name;
        var contactnumber = submitvalue.contactnumber;
        var email = submitvalue.email;
        var text = submitvalue.text;
        var message = "\nจาก : " + name + "\nเบอร์ : " + contactnumber + "\nอีเมล : " + email + "\nข้อความ : " + text;
        this.contactService.feedback(message, name, contactnumber, email, text).subscribe((res: any) => {
            let json_res = JSON.parse(res);
            console.log(json_res.responsecode);
            if (json_res.responsecode === 0) {
                Swal.fire({
                    title: 'เราได้รับข้อความของคุณแล้ว',
                    html: '<div class="alert-text">ขอบคุณที่นำแนะเรา</div>',
                    icon: 'success',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    // showCancelButton: true,
                    // cancelButtonColor: "#28a745",
                    // cancelButtonText: 'ยอมรับ'
                }).then((result) => {
                    // if (result.dismiss === Swal.DismissReason.cancel) {
                    //     this.loading = true;
                    //     this.createPolicy(this.registerForm.value);
                    // }
                })
            } else {
                Swal.fire({
                    title: 'ผิดพลาด',
                    html: '<div class="alert-text">ไม่สามารถรับข้อความได้ขณะนี้</div>',
                    icon: 'warning',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    // showCancelButton: true,
                    // cancelButtonColor: "#28a745",
                    // cancelButtonText: 'ยอมรับ'
                }).then((result) => {
                    // if (result.dismiss === Swal.DismissReason.cancel) {
                    //     this.loading = true;
                    //     this.createPolicy(this.registerForm.value);
                    // }
                })
            }
            this.loading = false;
        });
    }

}
