import { Component, OnInit, ViewChild } from '@angular/core';
import { QRCodeComponent } from 'angularx-qrcode';
import { MDBModalRef } from 'angular-bootstrap-md';
import { PackageService } from '../../../services/package.service'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modalpaypack',
  templateUrl: './modalpaypack.component.html',
  styleUrls: ['./modalpaypack.component.scss']
})
export class ModalpaypackComponent implements OnInit {
  public myRadio: string;
  page: string = 'summary'
  selectPack: Object
  onRead: boolean = false;
  packcomfirmForm: FormGroup;
  packages: Array<object> = [
    { "id": "1", "day": 7, "speed": "2", "call": 100, "price": 199 },
    { "id": "2", "day": 7, "speed": "4", "call": 100, "price": 299 },
    { "id": "3", "day": 14, "speed": "4", "call": 100, "price": 399 },
    { "id": "4", "day": 14, "speed": "6", "call": 100, "price": 499 },
    { "id": "5", "day": 30, "speed": "10", "call": 100, "price": 599 }
  ]
  qrSize: number = 200;
  link: string
  public qrcode: string = "assets\img\icon\qrcode.svg";
  errorTextCheck: boolean = false;
  errorQR: boolean = false;
  codeRes: number;
  errorQrText: string;
  spinner: string = ' d-flex'
  qrClass: string = 'd-none'
  btnText: string = 'ตกลง'
  errorText: string;
  successPage: boolean = false

  @ViewChild("save", { static: true }) pRef: QRCodeComponent;
  constructor(private _router: Router, private fb: FormBuilder, public modalRef: MDBModalRef, private pack: PackageService) {
    this.selectPack = this.pack.selectPackObj
    this.buildForm()
    this.pack.resPackOndemand.subscribe((value) => {
      this.codeRes = value['responsecode']
      if (value['responsecode'] === 0) {
        this.qrClass = ''
        this.spinner = ' d-none'
        if (this.myRadio === 'simcard') {
          this.successPage = true
        } else {
          this.qrcode = value['qrcode']
        }
      } else if (this.codeRes === 106) {
        this.spinner = ' d-none'
        this.errorQR = true
        this.errorQrText = value['messages']
        this.btnText = 'เติมเงิน'
      } else {
        this.spinner = ' d-none'
        this.errorQR = true
        this.errorQrText = value['messages']
      }
    })
  }

  ngOnInit(): void {
  }
  buildForm(): void {
    this.packcomfirmForm = this.fb.group({
      'phonenumber': new FormControl(null, [
        Validators.required,
        Validators.minLength(10)
      ]),
    });
  }
  onClose() {
    this.modalRef.hide()
  }
  onSent() {
    if (this.page === 'summary') {
      if (this.packcomfirmForm.get('phonenumber').valid) {
        this.pack.phoneNumber = this.packcomfirmForm.value.phonenumber
        this.errorTextCheck = false
        this.page = 'payment'
      } else {
        this.errorTextCheck = true
        this.errorText = 'กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง'
      }
    } else if (this.page === 'payment') {
      this.btnText = 'ปิด'
      if (!!this.myRadio) {
        this.page = 'success'
        this.errorTextCheck = false
        this.pack.selectPayment = this.myRadio
        this.pack.process()
      } else {
        this.errorTextCheck = true
        this.errorText = 'กรุณาเลือกช่องทางการชำระเงิน'
      }
    } else {
      if (this.codeRes === 106) {
        this.modalRef.hide()
        this._router.navigate(['/', 'topup'])
      } else {
        this.modalRef.hide()
      }
    }

  }
  radio(event) {
    const inputValue = event.target.value;
    this.myRadio = inputValue
  }
  onClick() {
    this.link = this.pRef.qrcElement.nativeElement.firstChild.src
  }

}
