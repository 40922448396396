import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-personalimage',
    templateUrl: './personalimage.component.html',
    styleUrls: ['./personalimage.component.scss']
})
export class PersonalimageComponent implements OnInit {
    exampleimageClass: string = '';
    previewimageClass: string = ' d-none';
    peviewpersonal: any;
    disable: boolean = true;
    retry = 0;

    // personalbase64: string;

    p_img = 10;
    rotation_img = 0;

    constructor(private register: RegisterService) { }

    ngOnInit(): void {
    }

    importFile(event) {
        if (event.target.files.length == 0) {
            console.log("No file selected!");
            return
        }
        let file: File = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.exampleimageClass = ' d-none';
                this.previewimageClass = '';
                this.peviewpersonal = reader.result
            };

            reader.readAsDataURL(file);
            this.disable = false;
            // this.file_phone = this.$refs.file_phone.files[0];
            // this.phoneview = false;
        }
    }

    rotateImg() {
        var img = document.getElementById('peviewpersonal');
        var maring = (img.clientWidth - img.clientHeight) / 2;
        if (maring > 0) {
            this.p_img = maring;
        }
        if (this.rotation_img > 360) {
            this.rotation_img = 0;
        }
        this.rotation_img += 90;
        img.setAttribute("style", "transform: rotate(" + this.rotation_img.toString() + "deg);");
    }

    faceverify(personalbase64) {
        if (this.register.faceidone === 'N' || this.retry > 1) {
            if (this.register.faceidone === 'N') {
                this.register.personalbase64one = personalbase64;
                this.register.personalangleone = this.rotation_img.toString();
                this.register.personalbase64two = personalbase64;
                this.register.personalangletwo = this.rotation_img.toString();
            }
            this.register.loading.next(false);
            this.register.page.next('term');
        } else {
            let postparam = {
                'phonenumber': this.register.phonenumber,
                'faceid': this.register.faceidone,
                'faceimg': personalbase64
            }
            // if (this.retry > 1) {
            //     this.register.page.next('term');
            // } else {
            this.register.faceverify(postparam).subscribe(
                (res: any) => {
                    let json_res = JSON.parse(res);
                    if (json_res.responsecode === 0) {
                        if (json_res.confidence >= 0.5) {
                            // alert('SUCCESS!! :-)\n\n' + JSON.stringify(res, null, 4));
                            this.activeSim(personalbase64);
                        } else {
                            this.register.loading.next(false);
                            this.retry += 1;
                            if (this.retry === 1) {
                                this.register.personalbase64one = postparam['faceimg'];
                                this.register.personalangleone = this.rotation_img.toString();
                            } else if (this.retry === 2) {
                                this.register.personalbase64two = postparam['faceimg'];
                                this.register.personalangletwo = this.rotation_img.toString();
                            }
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">รูปหน้าไม่ใกล้เคียงกัน กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (this.retry > 1) {
                                        this.register.page.next('term');
                                    } else {
                                        this.exampleimageClass = '';
                                        this.previewimageClass = ' d-none';
                                        this.peviewpersonal = null;
                                        this.disable = true;
                                    }
                                }
                            })
                        }
                        // this.activeSim(this.peviewpersonal.split(',').pop());
                    } else {
                        this.register.loading.next(false);
                        if (json_res.responsecode === 201) {
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ไม่สามารถตรวจสอบรูปหน้าบัตรได้ กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.exampleimageClass = '';
                                    this.previewimageClass = ' d-none';
                                    this.peviewpersonal = null;
                                    this.disable = true;
                                }
                            })
                        } else if (json_res.responsecode === 202) {
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ไม่พบไฟล์รูป กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.exampleimageClass = '';
                                    this.previewimageClass = ' d-none';
                                    this.peviewpersonal = null;
                                    this.disable = true;
                                }
                            })
                        } else if (json_res.responsecode === 203) {
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ไฟล์รูปมีปัญหา กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.exampleimageClass = '';
                                    this.previewimageClass = ' d-none';
                                    this.peviewpersonal = null;
                                    this.disable = true;
                                }
                            })
                            // }
                        } else if (json_res.responsecode === 205) {
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">พบปัญหาระหว่างตรวจจับใบหน้า กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.exampleimageClass = '';
                                    this.previewimageClass = ' d-none';
                                    this.peviewpersonal = null;
                                    this.disable = true;
                                }
                            })
                        } else {
                            Swal.fire({
                                title: 'ผิดพลาด!',
                                html: '<div class="alert-text">ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง</div>',
                                icon: 'warning',
                                confirmButtonColor: "#28a745",
                                confirmButtonText: "ตกลง",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.exampleimageClass = '';
                                    this.previewimageClass = ' d-none';
                                    this.peviewpersonal = null;
                                    this.disable = true;
                                }
                            })
                        }
                    }
                    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(res, null, 4));
                    // Swal.fire({
                    //     title: 'ผิดพลาด!',
                    //     html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                    //     icon: 'warning',
                    //     confirmButtonColor: "#28a745",
                    //     confirmButtonText: "ตกลง",
                    //     allowOutsideClick: false,
                    //     allowEscapeKey: false,
                    //     allowEnterKey: false
                    // }).then((result) => {
                    // })
                },
                (err: any) => {
                    this.register.loading.next(false);
                    Swal.fire({
                        title: 'ผิดพลาด!',
                        html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                        icon: 'warning',
                        confirmButtonColor: "#28a745",
                        confirmButtonText: "ตกลง",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                    })
                }
            );
        }
        // }
    }

    activeSim(personalbase64) {
        let postparam = {
            'personaltype': 'C',
            'personalid': this.register.citizenid,
            'firstname': this.register.firstname,
            'lastname': this.register.lastname,
            'birthday': this.register.birthday,
            'address': this.register.address,
            'dateissue': this.register.dateissue,
            'dateexpire': this.register.dateexpire,
            'lasercode': this.register.lasercode.split('-').join(''),
            'contactnumber': this.register.contactnumber,
            'activatednumber': this.register.phonenumber,
            'frontimg': this.register.frontidcardbase64,
            'frontangle': this.register.frontidcardangle,
            'backimg': this.register.backidcardbase64,
            'backangle': this.register.backidcardangle,
            'personalimg': personalbase64,
            'personalangle': this.rotation_img.toString(),
            'simcardimg': this.register.simcardbase64
        };
        if (this.register.religion) {
            postparam['religion'] = this.register.religion;
        }
        this.register.activesim(postparam).subscribe(
            (res: any) => {
                let json_res = JSON.parse(res);
                this.register.loading.next(false);
                if (json_res.responsecode === 0) {
                    this.register.page.next('done');
                } else {
                    if (json_res.responsecode === 101 || json_res.responsecode === 102 || json_res.responsecode === 103) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                        })
                    } else if (json_res.responsecode === 104 || json_res.responsecode === 105) {
                        if (json_res.responsecode === 104) {
                            this.register.errormessage.next('ไม่สามารถเปิดใช้งานซิมได้');
                        } else if (json_res.responsecode === 105) {
                            this.register.errormessage.next('เปิดใช้งานซิมไม่สมบูรณ์');
                        }
                        this.register.page.next('error');
                    }
                }
            },
            (err: any) => {
                this.register.loading.next(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html: '<div class="alert-text">ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        );
    }

    nextPage() {
        this.register.loading.next(true);
        this.faceverify(this.peviewpersonal.split(',').pop());
        // this.activeSim(this.peviewpersonal.split(',').pop());
        // this.register.page.next('done');
    }

}
