import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-devicesettings',
  templateUrl: './devicesettings.component.html',
  styleUrls: ['./devicesettings.component.scss']
})
export class DevicesettingsComponent implements OnInit {

  public setting: string = "android";
  public activeSlideIndex: any = 0;
  public slidesX: Array<Object>

  constructor(public router: Router) {
    this.slidesX = this.slides3
    if (this.router.url === '/apn') {
      this.onClick('android')
    } else if (this.router.url === '/apniphone') {
      this.onClick('iOS')
    } else if (this.router.url === '/carrier') {
      this.onClick('moAn')
    } else if (this.router.url === '/carrieriphone') {
      this.onClick('moIos')
    }
  }

  ngOnInit(): void {
  }
  check(x1: string, x2: string) {
    if (x1 == x2) { return true; }
    else { return false; }
  }
  checkfalse(x1: string, x2: string) {
    if (x1 != x2) { return true; }
    else { return false; }
  }
  onClick(setting: string) {
    this.setting = setting
    this.activeSlideIndex = 0
    if (setting == "android") { this.slidesX = this.slides3 }
    else if (setting == "iOS") { this.slidesX = this.slides4 }
    else if (setting == "moAn") { this.slidesX = this.slides }
    // else if (setting == "moIos") { this.slidesX = this.slides2 }
    else { this.slidesX = this.slides2 }
  }
  activeSlideChange() {
    this.activeSlideIndex = this.activeSlideIndex.relatedTarget
  }
  convertSetting(x: number) {
    if (x == 0) { return "android" == this.setting }
    else if (x == 1) { return "iOS" == this.setting }
    else if (x == 2) { return "moAn" == this.setting }
    else { return "moIos" == this.setting }
  }
  public topics: string[] = ["การตั้งค่า APN สำหรับอุปกรณ์ Android", "การตั้งค่า APN สำหรับอุปกรณ์ iOS", "การเปลี่ยนโหมดเครือข่าย และการค้นหาสัญญาณโทรศัพท์บนอุปกรณ์ Android", "การเปลี่ยนโหมดเครือข่าย และการค้นหาสัญญาณโทรศัพท์บน iOS"]
  public slides: Array<Object> = [
    { "img": "../../../assets/img/phone/AndroidCellularEng1.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng2.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng3.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng4.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng5.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng6.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng7.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng8.jpg" },
    { "img": "../../../assets/img/phone/AndroidCellularEng9.jpg" },

  ];
  public slides2: Array<Object> = [
    { "img": "../../../assets/img/phone/Thaiapn1.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular2.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular3.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular4.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular5.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular6.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular7.jpg" },
    { "img": "../../../assets/img/phone/Thaicellular8.jpg" },

  ];
  public slides3: Array<Object> = [
    { "img": "../../../assets/img/phone/AndroidAPN1.jpg" },
    { "img": "../../../assets/img/phone/AndroidAPN2.jpg" },
    { "img": "../../../assets/img/phone/AndroidAPN3.jpg" },
    { "img": "../../../assets/img/phone/AndroidAPN4.jpg" },
    { "img": "../../../assets/img/phone/AndroidAPN5.jpg" },
    { "img": "../../../assets/img/phone/AndroidAPN6.jpg" },

  ];
  public slides4: Array<Object> = [
    { "img": "../../../assets/img/phone/Thaiapn1.jpg" },
    { "img": "../../../assets/img/phone/Thaiapn2.jpg" },
    { "img": "../../../assets/img/phone/Thaiapn3.jpg" },
    { "img": "../../../assets/img/phone/Thaiapn4.jpg" },
    { "img": "../../../assets/img/phone/Thaiapn5.jpg" },

  ];
  public steps: Array<Object> = [
    {
      "moIos": "ขั้นตอนที่ 1: ไปที่เมนูการตั้งค่า",
      "momoAn": "ขั้นตอนที่ 1: ไปที่เมนูการตั้งค่า",
      "iOS": "ขั้นตอนที่ 1: ไปที่เมนูการตั้งค่า",
      "android": "ขั้นตอนที่ 1: ไปที่เมนูการตั้งค่า"
    },
    {
      "moIos": "ขั้นตอนที่ 2: เลือกที่หัวข้อ “เซลลูลาร์”",
      "momoAn": "ขั้นตอนที่ 2: เลือก “ระบบไร้สายและเครือข่าย”",
      "iOS": "ขั้นตอนที่ 2: เลือกที่หัวข้อ “เซลลูลาร์”",
      "android": "ขั้นตอนที่ 2: เลือก “ระบบไร้สายและเครือข่าย”"
    },
    {
      "moIos": "ขั้นตอนที่ 3: เปิด “ข้อมูลเซลลูลาร์” จากนั้นเลือก “ตัวเลือกข้อมูลเซลลูลาร์”",
      "momoAn": "ขั้นตอนที่ 3: เลือก “เครือข่ายโทรศัพท์มือถือ”",
      "iOS": "ขั้นตอนที่ 3: เลือก “เครือข่ายข้อมูลเซลลูลาร์”",
      "android": "ขั้นตอนที่ 3: เลือก “เครือข่ายโทรศัพท์มือถือ”"
    },
    {
      "moIos": "ขั้นตอนที่ 4: เลือก “เสียงและข้อมูล”",
      "momoAn": "ขั้นตอนที่ 4: เลือก “โหมดเครือข่ายที่ต้องการ”",
      "iOS": "ขั้นตอนที่ 4: ในหัวข้อ ข้อมูลเซลลูลาร์ ตั้งค่า • APN internet • Username feels",
      "android": "ขั้นตอนที่ 4: เลือก “Access Point Names (APNs)”"
    },
    {
      "moIos": "ขั้นตอนที่ 5: เลือก “LTE”",
      "momoAn": "ขั้นตอนที่ 5: เลือก “LTE/WCDMA/GSM อัตโนมัติ”",
      "iOS": "ขั้นตอนที่ 5: เลื่อนลงมาด้านล่าง ในหัวข้อ ฮอตสปอตส่วนบุคคล ตั้งค่า • APN internet • Username feels",
      "android": "ขั้นตอนที่ 5: แตะที่สัญลักษณ์ ... แล้วเลือก “APN ใหม่”"
    },
    {
      "moIos": "ขั้นตอนที่ 6: ย้อนกลับไปยังเมนู “เซลลูลาร์”, เลือก “เลือกเครือข่าย”",
      "momoAn": "ขั้นตอนที่ 6: กลับไปยังหน้าเครือข่ายโทรศัพท์มือถือ, เลือก “ผู้ให้บริการ”",
      "android": "ขั้นตอนที่ 6: ที่หน้า “APN ใหม่” ให้กำหนดการเปลี่ยนชื่อโดยเลือกเป็น “Feels” และตั้งค่า APN เป็น “internet” จากนั้นกดที่เครื่องหมาย ✓ เพื่อทำการบันทึกการตั้งค่า"
    },
    {
      "moIos": "ขั้นตอนที่ 7: เลือกเปิดตัวเลือก “อัตโนมัติ”",
      "momoAn": "ขั้นตอนที่ 7: เลือกเปิดตัวเลือก “อัตโนมัติ”"
    },
    {
      "moIos": "ขั้นตอนที่ 8: เลือก “Feels”",
      "momoAn": "ขั้นตอนที่ 8: เลือก “ตกลง”"
    },
    { "momoAn": "ขั้นตอนที่ 9: เลือก “Feels 4G”" },
  ];



}
