import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    public resFeedback: Subject<Object> = new Subject<Object>();
    private API_URL_PROD = environment.API_URL_PROD;

    constructor(private http: HttpClient) { }

    // feedback(message: string, clientaddress: string): Observable<string> {
    //     var headers = new HttpHeaders().set('Content-Type', 'application/json');
    //     let body = JSON.stringify({
    //         message,
    //         clientaddress
    //     });
    //     const url = this.API_URL_PROD + '/feelswebapp/feedback';
    //     var result = this.http.post(url, body, { responseType: 'text', headers })
    //     return result
    // }

    feedback(message: string, name: string, contactnumber: string, email: string, text: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({
            message,
            name,
            contactnumber,
            email,
            text
        });
        // console.log(body);
        const url = this.API_URL_PROD + '/feelswebapp/feedback';
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    // process(message) {
    //   // let message = "";
    //   this.feedback(message).subscribe((res: any) => [
    //     this.resFeedback.next(JSON.parse(res + ''))
    //   ]);
    // }
}
