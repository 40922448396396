<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <div class="col-12">
                <form class="mt-3 row" [formGroup]="personalForm">
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>ชื่อ<span class="required-text">*</span></label>
                            <input type="text" name="firstname" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="firstname">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>นามสกุล<span class="required-text">*</span></label>
                            <input type="text" name="lastname" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="lastname">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>เบอร์ติดต่อ<span class="required-text">*</span></label>
                            <input type="tel" name="contactnumber" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="contactnumber" (keypress)="numberOnly($event)"
                                onKeyDown="if(this.value.length==10 && event.keyCode!=8) return false;">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>อีเมล</label>
                            <input type="email" name="email" class="form-control" formControlName="email">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>บัตรประชาชน/พาสปอร์ต</label><br>
                            <div class="form-check-inline">
                                <label class="customradio mr-3">
                                    <span class="radiotextsty">บัตรประชาชน</span>
                                    <input type="radio" name="personaltype" formControlName="personaltype"
                                        value="idcard" (change)="empty()">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="customradio">
                                    <span class="radiotextsty">พาสปอร์ต</span>
                                    <input type="radio" name="personaltype" formControlName="personaltype"
                                        value="passport" (change)="empty()">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left" *ngIf="f.personaltype.value === 'idcard'">
                        <div class="form-group">
                            <label>เลขบัตรประชาชน 13 หลัก<span class="required-text">*</span></label>
                            <input type="tel" name="idcard" class="form-control" placeholder="จำเป็นต้องกรอก"
                                (keypress)="numberOnly($event)" autocomplete="off" formControlName="personalid"
                                onKeyDown="if(this.value.length==13 && event.keyCode!=8) return false;">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left" *ngIf="f.personaltype.value !== 'idcard'">
                        <div class="form-group">
                            <label>เลขหนังสือเดินทาง<span class="required-text">*</span></label>
                            <input type="text" name="passport" class="form-control" formControlName="personalid"
                                placeholder="จำเป็นต้องกรอก">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)<span class="required-text">*</span></label>
                            <input type="text" name="birthday" class="form-control" matInput mask="00/00/0000"
                                [showMaskTyped]="true" placeholder="หากไม่ทราบให้ว่างไว้" formControlName="birthday">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="next()" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ถัดไป</button>
        </div>
    </div>
</main>