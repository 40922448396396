import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnlinesimService } from '../../../services/onlinesim.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-onlinesimpersonal',
    templateUrl: './onlinesimpersonal.component.html',
    styleUrls: ['./onlinesimpersonal.component.scss']
})
export class OnlinesimpersonalComponent implements OnInit {
    personalForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private onlinesimService: OnlinesimService) {
        this.personalForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            contactnumber: ['', Validators.required],
            email: [''],
            personaltype: ['idcard'],
            personalid: ['', Validators.required],
            birthday: ['', Validators.required]
        });
    }

    ngOnInit(): void {
    }

    get f() { return this.personalForm.controls; }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    empty() {
        this.personalForm.patchValue({ "personalid": '' });
    }

    next() {
        if (this.personalForm.invalid) {
            Swal.fire({
                title: 'คำเตือน',
                html: '<div class="alert-text">กรุณากรอกข้อมูลที่ต้องการให้ครบ ก่อนไปขั้นตอนถัดไป</div>',
                icon: 'warning',
                confirmButtonColor: "#28a745",
                confirmButtonText: "ตกลง",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            }).then((result) => {
            })
        } else {
            this.onlinesimService.firstname = this.personalForm.value.firstname;
            this.onlinesimService.lastname = this.personalForm.value.lastname;
            this.onlinesimService.contactnumber = this.personalForm.value.contactnumber;
            this.onlinesimService.personaltype = this.personalForm.value.personaltype;
            this.onlinesimService.personalid = this.personalForm.value.personalid;
            this.onlinesimService.birthday = this.personalForm.value.birthday;
            if (this.personalForm.value.email) {
                this.onlinesimService.email = this.personalForm.value.email;
            }
            this.onlinesimService.page.next('personaladdress');
        }
    }

}
