<main>
    <div class="banner d-flex justify-content-center align-items-center">
        <h1 class="text-white fs-50 line-h-100 text-center">นโยบายความเป็นส่วนตัว Feels</h1>
    </div>
    <div class="row justify-content-center m-0">
        <div class="text-about col-12 col-lg-10 p-3 p-lg-5 mt-3 rounded-top-3">
            <div class="mb-3 mt-lg-0 mt-3">
                <h1 class="fs-50 text-center text-light-green-main line-h-100">นโยบายความเป็นส่วนตัว Feels</h1>
            </div>
            <h2 class="text-center m-0">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</h2><br>
            <div style="width: 100%; text-align: start; margin-top: 30px;">
                <a class="head-title">1. นโยบายการคุ้มครองสิทธิและการรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการ</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;เพื่อให้มีหลักเกณฑ์การคุ้มครองสิทธิของผู้ใช้บริการเกี่ยวกับข้อมูลส่วนนบุคคลสิทธิในความเป็นส่วนตัวและเสรีภาพในการสื่อสารถึงกันโดยทางโทรคมนาคมของผู้ใช้บริการให้สอดคล้องกับหลักเกณฑ์ของคณะกรรมการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                    จึงได้จัดให้มีการแจ้งวัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลระยะเวลาในการเก็บข้อมูลส่วนบุคคลประเภทของบุคคลหรือหน่วยงานที่ซึ่งข้อมูลส่วนบุคคลที่อาจจะถูกเปิดเผย
                    และสิทธิของลูกค้าและการรับความยินยอมของลูกค้าตามมาตรการป้องกัน
                    และรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้บริการ
                    ทั้งด้านเทคนิคและการจัดการภายในองค์กรตามความเหมาะสมกับแต่ละบริการโทรคมนาคม
                    รวมถึงการสร้างความเชื่อมั่นในการติดต่อสื่อสารถึงกันทางโทรคมนาคม ตามที่กำหนดไว้ในหลักเกณฑ์ฉบับนี้
                </p>
                <a class="head-title">2. คำจำกัดความ</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;2.1 “บริษัท” หมายถึง บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.2 “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลผู้ใช้เลขหมายโทรคมนาคม
                    ข้อเท็จจริง
                    รายละเอียดเกี่ยวกับผู้ใช้บริการที่สามารถระบุตัวผู้ใช้บริการ หรืออาจจะระบุตัวผู้ใช้บริการนั้นได้
                    ไม่ว่าทางตรงหรือทางอ้อม ข้อมูลการใช้บริการ เลขหมายโทรคมนาคม
                    รวมทั้งพฤติกรรมการใช้บริการโทรคมนาคมของผู้ใช้บริการ แต่ไม่รวมถึงข้อมูลทางเทคนิคที่ใช้เท่าที่จำเป็น
                    เพื่อประโยชน์ในการบริหารโครงข่ายโทรคมนาคม เพื่อประโยชน์ในการติดต่อสื่อสาร
                    และเพื่อประโยชน์ในการดำเนินธุรกิจในภาพรวมของบริษัท
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.3 “การประมวลผล” หมายถึง การดำเนินการใดๆ เกี่ยวกับการใช้ การเปิดเผย
                    หรือการแก้ไขซึ่งข้อมูลส่วนบุคคลของผู้ใช้บริการ ไม่ว่าจะใช้วิธีการใดๆ
                    และให้หมายความรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลที่ใช้ในการประกอบกิจการโทรคมนาคมนั้นๆ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.4 “การเก็บรวบรวม” หมายถึง การทำให้ได้มาซึ่งข้อมูลส่วนบุคคล
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.5 “ข้อมูลผู้ใช้เลขหมายโทรคมนาคม” หมายถึง
                    ข้อมูลเกี่ยวกับหมายเลขโทรคมนาคม ชื่อ สกุล และที่อยู่ของผู้ใช้บริการ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.6 “ผู้ใช้บริการ” หมายถึง ผู้ใช้บริการโทรคมนาคม
                    ทั้งที่เป็นบุคคลธรรมดาและนิติบุคคล ไม่ว่าจะเป็นคู่สัญญากับบริษัท หรือไม่
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.7 “คณะกรรมการ” หมายถึง คณะกรรมการกิจการกระจายสียง
                    กิจการโทรทัศน์และกิจการโทรคมนาคมแห่งชาติ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;2.8 “สำนักงาน” หมายถึง สำนักงานคณะกรรมการกิจการกระจายสียง
                    กิจการโทรทัศน์และกิจการโทรคมนาคมแห่งชาติ
                </p>
                <a class="head-title">3. การเก็บรวบรวม เก็บรักษา และประมวลผลข้อมูลส่วนบุคคล</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;3.1 บริษัทจะเก็บรวบรวม เก็บรักษา และประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการ
                    เมื่อได้รับความยินยอมจากผู้ใช้บริการโดยวิธีการใดวิธีการหนึ่งดังต่อไปนี้
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1.1 แบบคำขอใช้บริการ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1.2 ทางเว็บไซด์ของบริษัท
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1.3 ทางข้อความสั้น (SMS)
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1.4 อื่นๆ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.2 การประมวลผลข้อมูลส่วนบุคคล
                    บริษัทจะกระทำเพื่อประโยชน์ในการดําเนินกิจการโทรคมนาคมเท่านั้น เช่น
                        -
                    เพื่อประโยชน์เกี่ยวกับการศึกษาวิจัยหรือสถิติที่เกี่ยวข้องกับการให้บริการของบริษัทและได้ทำการเก็บข้อมูลส่วนบุคคลนั้นไว้เป็นความลับ
                        - เป็นข้อมูลที่เปิดเผยต่อสาธารณะโดยชอบด้วยกฎหมาย
                        - เพื่อเป็นประโยชน์ต่อลูกค้า
                        - การจัดทำฐานข้อมูลและใช้ข้อมูลลูกค้าเพื่อพัฒนาคุณภาพการให้บริการ
                        - การจัดทำฐานข้อมูลและใช้ข้อมูลลูกค้าเพื่อเสนอสิทธิประโยชน์ตามความสนใจของลูกค้า
                        - การจัดทำฐานข้อมูลและใช้ข้อมูลลูกค้าเพื่อจัดทำกิจกรรมส่งเสริมการขาย หรือกิจกรรมต่างๆ ของบริษัท
                    เป็นต้น
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.3
                    บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้ใช้บริการโดยตรงเท่าที่จําเป็นแก่การดําเนินกิจการโทรคมนาคมของบริษัท
                    และเป็นไปตามวัตถุประสงค์ที่ชอบด้วยกฎหมาย
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.4 บริษัทไม่เก็บรวบรวมข้อมูลลักษณะพิการทางร่างกาย
                    เว้นแต่เป็นการเก็บรวบรวมข้อมูลเพื่อประโยชน์ในการให้บริการที่เหมาะสมตามลักษณะพิการทางร่างกาย
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.5 บริษัทจะไม่เก็บรวบรวมข้อมูลลักษณะทางพันธุกรรม ยกเว้น
                    เป็นข้อมูลที่ปรากฏบนเอกสารที่สามารถเปิดเผยได้ตามกฎหมาย
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.6 บริษัทไม่เก็บรวบรวมข้อมูลที่กระทบต่อความรู้สึก
                    หรืออาจก่อให้เกิดความเสียหายหรือมีผลกระทบต่อสิทธิ เสรีภาพของผู้ใช้บริการอย่างชัดแจ้ง
                    ตามที่คณะกรรมการกำหนด
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.7 บริษัทจัดเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการในช่วงเวลา 3
                    เดือนสุดท้ายของการใช้บริการของผู้ใช้บริการ นับถัดจากวันที่ใช้บริการในปัจจุบัน
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;3.8 ในกรณีที่การให้บริการโทรคมนาคมสิ้นสุดลง
                    บริษัทจะจัดเก็บรักษาข้อมูลส่วนบุคคลตามข้อ 3.7 ไว้เป็นเวลา 3 เดือน
                    นับถัดจากวันสิ้นสุดสัญญาการให้บริการ
                </p>
                <a class="head-title">4. การเปิดเผยข้อมูล</a>
                <p>
                    การเปิดเผยข้อมูลดังต่อไปนี้ บริษัทสามารถดำเนินการได้โดยไม่ต้องได้รับความยินยอมจากผู้ใช้บริการ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;4.1
                    การเปิดเผยข้อมูลส่วนบุคคลต่อหน่วยงานของรัฐหรือเจ้าหน้าที่ของรัฐโดยอาศัยอํานาจตามบทบัญญัติแห่งกฎหมายเฉพาะเพื่อรักษาความมั่นคงของรัฐ
                    หรือเพื่อรักษาความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน
                    และปฏิบัติครบถ้วนตามกระบวนการที่กฎหมายนั้นบัญญัติ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;4.2
                    การใช้หรือเปิดเผยข้อมูลส่วนบุคคลเท่าที่จําเป็นเพื่อป้องกันหรือระงับอันตรายต่อ ชีวิต ร่างกาย
                    หรือสุขภาพอนามัยของผู้ใช้บริการ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;4.3 การส่งข้อมูลส่วนบุคคลให้คณะกรรมการหรือสํานักงาน
                    เมื่อได้รับการร้องขอจากคณะกรรมการและสำนักงาน
                    เพื่อประโยชน์ในการปฏิบัติงานกำกับดูแลการประกอบกิจการโทรคมนาคมตามกฎหมาย
                </p>
                <a class="head-title">5. สิทธิของผู้ใช้บริการ</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;5.1 ผู้ใช้บริการสามารถตรวจดู ขอสำเนา
                    หรือสำเนารับรองความถูกต้องเกี่ยวกับข้อมูลส่วนบุคคลของตน โดยร้องขอต่อบริษัทเป็นหนังสือ
                    โดยเสียค่าธรรมเนียมตามที่บริษัทกำหนด แต่ทั้งนี้ ต้องเป็นไปตามอัตราที่คณะกรรมการกำหนด
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;5.2 ผู้ใช้บริการสามารถแก้ไข
                    หรือเปลี่ยนแปลงข้อมูลส่วนบุคคลของตนให้ถูกต้องสมบูรณ์
                    โดยแจ้งให้บริษัททราบเป็นหนังสือหรือผ่านทางเว็บไซด์ของบริษัท
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;5.3 ผู้ใช้บริการสามารถระงับการใช้ เปิดเผย หรือ
                    เพิกถอนความยินยอมให้ประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการในกิจกรรมต่างๆ
                    ซึ่งไม่กระทบกับการให้บริการแก่ผู้ใช้บริการในเวลาใดๆได้ โดยแจ้งให้บริษัททราบเป็นหนังสือ
                </p>
                <a class="head-title">6. มาตรการในการรักษาข้อมูล</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;6.1 บริษัทจัดให้มีการกำหนดระดับการเข้าถึงข้อมูล และปรับเปลี่ยนรหัสผู้ใช้
                    (Password) ของพนักงานที่เกี่ยวข้องในการเข้าถึงและใช้ข้อมูลเพื่อการรักษาความปลอดภัยของข้อมูลส่วนบุคคล
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;6.2
                    บริษัทจัดให้มีมาตรการเพื่อความปลอดภัยให้เหมาะสมกับความเสี่ยงที่เกิดขึ้นตามการพัฒนาทางเทคโนโลยี
                    โดยมีมาตรการดังนี้
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2.1 การรักษาความปลอดภัยของสถานที่
                    หน่วยงานและบุคลากร โดยมีวิธีการคัดเลือก แยกหน้าที่
                    ฝึกอบรมและมอบหมายงานให้แก่พนักงานในการปฏิบัติหน้าที่ที่เหมาะสม
                    และพนักงานทุกคนผูกพันตามข้อตกลงไม่เปิดเผยข้อมูลและข่าวสาร
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2.2 การรักษาความปลอดภัยของระบบคอมพิวเตอร์
                    และระบบงานสารสนเทศ โดยมีการแยกการทำงานของระบบคอมพิวเตอร์ ควบคุมการเข้าใช้งานระบบคอมพิวเตอร์
                    ตรวจจับผู้บุกรุกและติดตามสืบค้น และควบคุมการเปลี่ยนแปลงต่อระบบคอมพิวเตอร์
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2.3 การรักษาความปลอดภัยข้อมูลสารสนเทศ
                    ด้วยการจัดชั้นความลับของข้อมูล วิธีการเก็บรักษาข้อมูลสำคัญ และวิธีการจัดการข้อมูลที่บันทึกบนกระดาษ
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2.4
                    การรักษาความปลอดภัยการสื่อสารและระบบเครือข่ายการสื่อสาร
                    ด้วยการควบคุมการเข้าถึงระบบเครือข่ายการสื่อสาร
                    มีการสำรองและการกู้คืนข้อมูล ควบคุมการรับ-ส่งข้อมูลในเครือข่าย ตรวจสอบความคลาดเคลื่อนของข้อมูล
                    และการควบคุมการเข้าใช้งานระบบคอมพิวเตอร์จากระยะไกล
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.2.5 แผนการป้องกันธุรกิจหยุดชะงัก
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;6.3 บริษัทไม่กระทำการดักฟัง ตรวจ กักสัญญาณ
                    หรือเปิดเผยสิ่งสื่อสารถึงกันโดยทางโทรคมนาคมที่บุคคลติดต่อถึงกันไม่ว่าจะเป็นในรูปแบบใดๆ
                    เว้นแต่ได้รับอนุญาตตามบทบัญญัติแห่งกฎหมาย
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;6.4 บริษัทจัดทำระบบป้องกันไม่ให้มีการกระทำใดๆ
                    เพื่อให้ความหมายของข้อมูลเปลี่ยนไป
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;6.5 บริษัทจัดให้มีบริการแสดงเลขหมายเรียกเข้า
                    ระบบป้องกันการแสดงหมายเลขโทรออก ระบบปฏิเสธเลขหมายที่ไม่พึงประสงค์ ตามหลักเกณฑ์ที่คณะกรรมการกำหนด
                        <br>&nbsp;&nbsp;&nbsp;&nbsp;6.6
                    บริษัทให้ข้อมูลผู้ใช้เลขหมายโทรคมนาคมแก่บุคคลที่ได้รับอนุญาตให้จัดทํารายนามผู้ใช้บริการตามพระราชบัญญัติการประกอบกิจการโทรคมนาคม
                    พ.ศ. 2544 ซึ่งได้รับความยินยอมจากผู้ใช้บริการแล้ว
                </p>
                <a class="head-title">7. การร้องเรียนของผู้ใช้บริการ</a>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;ผู้ใช้บริการอาจร้องเรียนเกี่ยวกับกรณีถูกละเมิดสิทธิเกี่ยวกับข้อมูลส่วนบุคคล
                    สิทธิในความเป็นส่วนตัว หรือเสรีภาพในการสื่อสารถึงกันโดยทางโทรคมนาคม
                    ตามหลักเกณฑ์การรับเรื่องร้องเรียนที่บริษัทกำหนดไว้
                </p>
            </div>
            <hr style="margin-left: 20%; margin-right: 20%;">
            <a class="cursor-off">Feel the limitless mobile freedom</a><br>
            <a class="cursor-off" style="font-weight: 200;">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</a>
        </div>
    </div>
</main>