import { Component, OnInit } from '@angular/core';
// import { NgScrollbar } from 'ngx-scrollbar';
import { RegisterService } from '../../../services/register.service'

@Component({
    selector: 'app-agreement',
    templateUrl: './agreement.component.html',
    styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
    page: string;

    constructor(private register: RegisterService) { }

    ngOnInit(): void {
    }
    onSent() {
        this.register.page.next('simcard')
    }

}
