<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-10 pt-4 mb-3">
            <div class="row col-12 m-0 justify-content-center">
                <form class="mt-3 col-12 row" [formGroup]="serachForm">
                    <div class="col-12 col-lg-9">
                        <h2>เบอร์โดนใจใช่เลย</h2>
                        <div class="row w-100 align-items-center m-0">
                            <div class="md-form form-sm col-lg-8 col-12">
                                <input mdbInput type="text" maxlength="10" name="keyword" #keyword
                                    formControlName="keyword" placeholder="ใส่เลขที่ชอบ เช่น 55 , 9"
                                    class="form-control form-control-sm elegant-color-text">
                                <label for="form1" class="">ค้นหาเลขที่สนใจ</label>
                            </div>
                            <div class="col-lg-4 col-12 pb-4">
                                <ng-select [items]="filterType" bindLabel="filtername" name="filtername"
                                    formControlName="arrayfilterType" placeholder="ค้นหาด้วย">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.filtername}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center">
                        <button (click)="onSearch()" type="button" mdbBtn
                            class="rounded-pill w-100 mt-2 mt-lg-3 mb-lg-0 mb-3 mr-0 ml-0 bg-soft-green text-white"
                            mdbWavesEffect>ค้นหา</button>
                    </div>
                </form>
            </div>
            <div class="row col-12 m-0 justify-content-center">
                <div class="number-box loading-box" *ngIf="stockloading">
                    <div class="center">
                        <div class="spinner-border"></div>
                    </div>
                </div>
                <div class="number-box w-100" *ngIf="!stockloading">
                    <div class="col-12 row m-0 p-0" *ngIf="!notfoundstock">
                        <div *ngFor="let item of phonenumber.slice(startchar, endchar + 1); let i = index"
                            class="col-md-3 col-sm-4 col-6 pr-2 pl-2">
                            <input type="submit"
                                class="btn btn-outline-green-main pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100 animated fadeIn"
                                value="{{item['phonenumber']}}"
                                [ngClass]="{ 'class-active': selected === item['phonenumber'] }"
                                (click)="onKey($event)">
                        </div>
                    </div>
                    <!-- <div class="col-12 text-center p-3" *ngIf="(phonenumber.length <= 0)">
                        <p>ระบบกำลังปิดปรับปรุง</p>
                    </div> -->
                    <div *ngIf="notfoundstock" class="col-12 text-center p-3">
                        <i class="fas fa-box-open fa-2x mb-2 text-light-green-main"></i>
                        <p class="mt-3">ไม่มีเบอร์นี้ในระบบ</p>
                    </div>
                    <div class="col-12 text-center d-flex justify-content-center mt-3" *ngIf="!notfoundstock">
                        <a class="m-2 text-muted c-point-mute" *ngIf="startchar <= 0">
                            <i class="fas fa-arrow-alt-circle-left"></i>
                            ก่อนหน้า
                        </a>
                        <a class="m-2" *ngIf="startchar > 0" (click)="onPrevious(false)">
                            <i class="fas fa-arrow-alt-circle-left text-light-green-main"></i>
                            ก่อนหน้า
                        </a>
                        <a class="m-2" (click)="onNext(false)">
                            ถัดไป
                            <i class="fas text-light-green-main fa-arrow-alt-circle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>