<main>
    <div class="text-about">
        <div class="text-wrapper">
            <div class="header"><a>Please Scan this QRcode to complete this payment</a></div><br>
            <div class="header-detail"><a>this QRcode will expire in 15 minute</a></div>
            <div class="qr">
                <div style="width: 100%;">
                    <img src="{{data[payment]}}" class="img-pay">
                </div>
                <div class="qr-w">
                    <qrcode [qrdata]="qrcode" [width]=" 500" [errorCorrectionLevel]="'M'">
                    </qrcode>
                </div>
                <div style="width: 100%;">
                    <img src="{{data[payment]}}" class="img-pay">
                </div>
            </div>
        </div>
    </div>
</main>