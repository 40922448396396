<main>
    <form>
        <div class="row justify-content-center ">
            <div class="col-12 col-sm-8 col-lg-6 white mt-3 mb-3 p-3" *ngIf="page==='number'">
                <div class="row animated fadeIn">
                    <div class="col-12">
                        <h1 class="text-center">ย้ายค่ายมาเป็นครอบครัวของเรา</h1>
                        <div class="row justify-content-center p-3 mb-3">
                            <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row m-0 align-items-center">
                            <div class="col-12">
                                <h3>เบอร์ที่ต้องการย้ายค่าย</h3>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="phone" class="form-control form-control-sm">
                                    <label for="phone" class="">เบอร์มือถือที่ต้องการย้าย</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="codemove" class="form-control form-control-sm">
                                    <label for="codemove" class="">รหัสย้ายค่าย</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <mdb-checkbox [default]="true">กดเช็คเพื่อยอมรับเงื่อนไข</mdb-checkbox>
                        <div class="d-flex justify-content-center">
                            <button mdbBtn type="button" (click)="onComfirm()" class="rounded-pill w-50" color="success"
                                mdbWavesEffect>ถัดไป</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-lg-8 white mt-3 mb-3 p-3" *ngIf="page==='identify'">
                <div class="row animated fadeIn">
                    <div class="col-12">
                        <h1 class="text-center">กรอกข้อมูลยืนยันตัวตนการย้ายค่าย</h1>
                        <div class="row justify-content-center p-3 mb-3">
                            <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                        </div>
                    </div>
                    <div class="col12">
                        <div class="row m-0 align-items-center">
                            <div class="col-12">
                                <h3>ข้อมูลส่วนตัว</h3>
                            </div>
                            <div class="col-4 col-lg-2">
                                <ng-select [items]="titleName" bindLabel="title" name="title" [(ngModel)]="selectTitle"
                                    placeholder="คำนำหน้า">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.title}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-8 col-lg-5">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="name" class="form-control form-control-sm">
                                    <label for="name" class="">ชื่อจริง</label>
                                </div>
                            </div>
                            <div class="col-6 col-lg-5">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="lastname" class="form-control form-control-sm">
                                    <label for="lastname" class="">นามสกุล</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="idcardnumber" class="form-control form-control-sm">
                                    <label for="idcardnumber" class="">เลขบัตรประชาชน</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="idcardnumberback"
                                        class="form-control form-control-sm">
                                    <label for="idcardnumberback" class="">เลขหลังบัตรประชาชน</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <h3>ที่อยู่ตามทะเบียนบ้าน</h3>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="address" class="form-control form-control-sm">
                                    <label for="address" class="">ที่อยู่</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="subdistrict" class="form-control form-control-sm">
                                    <label for="subdistrict" class="">ตำบล/แขวง</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="district" class="form-control form-control-sm">
                                    <label for="district" class="">อำเภอ/เขต</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="province" class="form-control form-control-sm">
                                    <label for="province" class="">จังหวัด</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <mdb-checkbox [default]="true">กดเช็คเพื่อยอมรับเงื่อนไข</mdb-checkbox>
                        <div class="d-flex justify-content-center">
                            <button mdbBtn type="button" (click)="onAddress()" class="rounded-pill w-50" color="success"
                                mdbWavesEffect>ถัดไป</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-lg-8 white mt-3 mb-3 p-3" *ngIf="page==='address'">
                <div class="row animated fadeIn">
                    <div class="col-12">
                        <h1 class="text-center">กรอกข้อมูลที่อยู่ในการจัดส่งซิมการ์ด</h1>
                        <div class="row justify-content-center p-3 mb-3">
                            <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                        </div>
                    </div>
                    <div class="col12">
                        <div class="row m-0 align-items-center">
                            <div class="col-12">
                                <h3>ข้อมูลผู้รับ</h3>
                            </div>
                            <div class="col-4 col-lg-2">
                                <ng-select [items]="titleName" bindLabel="title" name="title" [(ngModel)]="selectTitle"
                                    placeholder="คำนำหน้า">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.title}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-8 col-lg-5">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="nameA" class="form-control form-control-sm">
                                    <label for="nameA" class="">ชื่อจริง</label>
                                </div>
                            </div>
                            <div class="col-6 col-lg-5">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="lastnameA" class="form-control form-control-sm">
                                    <label for="lastnameA" class="">นามสกุล</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <h3>ที่อยู่ในการจัดส่ง</h3>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="addressA" class="form-control form-control-sm">
                                    <label for="addressA" class="">ที่อยู่</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="subdistrictA" class="form-control form-control-sm">
                                    <label for="subdistrictA" class="">ตำบล/แขวง</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="districtA" class="form-control form-control-sm">
                                    <label for="districtA" class="">อำเภอ/เขต</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="md-form form-sm">
                                    <input mdbInput type="text" id="provinceA" class="form-control form-control-sm">
                                    <label for="provinceA" class="">จังหวัด</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <button mdbBtn type="button" (click)="onSent()" class="rounded-pill w-50" color="success"
                                mdbWavesEffect>ถัดไป</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</main>