<div class="overlay d-flex justify-content-center align-items-center" *ngIf="loading">
    <i class="fas fa-2x fa-sync fa-spin"></i>
</div>
<div class="modal-header bg-soft-green text-white">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100 cus-text m-0" id="myModalLabel">ทำรายการซื้อซิม</h4>
</div>
<div class="modal-body cus-text">
    <!-- Step 1 -->
    <div class="row justify-content-center" *ngIf="page === 'summary'">
        <div class="col-12">
            <h2 class="text-left text-light-green-main">รายละเอียดเบอร์</h2>
        </div>
        <div *ngIf="!errorBook">
            <div class="col-12">
                <div class="row w-100 m-0 justify-content-between">
                    <div class="col-6 pr-1 pl-1 justify-content-center row align-items-center">
                        <label>เบอร์ของคุณ</label>
                        <h2 class="col-12">{{selectNumber}}</h2>
                    </div>
                    <div class="col-6 pr-1 pl-1 justify-content-center row align-items-center">
                        <label>ราคา</label>
                        <h2 class="text-light-green-main m-0 col-12">{{simprice}}</h2>
                        <small>บาท</small>
                    </div>
                </div>
            </div>
            <div class="col-12 text-left p-3 term-box">
                <div class="scrollbar p-3">
                    <ul>
                        <li>ความเร็วอินเทอร์เน็ตขึ้นอยู่กับพื้นที่การใช้งาน</li>
                        <li>บริษัทสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดเงื่อนไขของบริการและรายการส่งเสริมการขายโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                        </li>
                        <li>บริษัทสงวนสิทธิ์ในการจัดการบริหารเครือข่ายตามความเหมาะสมเพื่อรักษามาตรฐานคุณภาพของบริการ
                            และเพื่อช่วยให้ผู้ใช้บริการโดยรวมสามารถใช้งานได้อย่างมีประสิทธิ์ภาพ เช่น จำกัด หรือ
                            ลดความเร็วหรือดำเนินการใดๆ ในการรับส่งข้อมูลต่างๆ, การใช้งาน Bit Torrent, การแชร์เน็ตผ่าน
                            Hotspot, การดาวน์โหลดและ/หรือ อัพโหลดไฟล์ขนาดใหญ่, หรือ
                            การใช้งานใดที่มีการรับส่งข้อมูลในปริมาณมากอย่างต่อเนื่อง
                            หรือที่มีผลต่อการใช้บริการหรือเกิดความไม่เป็นธรรม
                            ก่อหรืออาจก่อให้เกิดความเสียหายต่อผู้ใช้บริการอื่น และ/หรือต่อเครือข่าย
                            หรือการให้บริการโดยรวมของบริษัท</li>
                        <li>บริการตามรายละเอียดนี้เพื่อการสื่อสารเฉพาะบุคคลเท่านั้น มิใช่เพื่อถือเอาประโยชน์
                            หรือนำไปใช้งานในเชิงพาณิชย์ หรือให้บริการต่อได้
                            หากพบบริษัทขอสงวนสิทธิ์ในการยกเลิกการให้บริการได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
                    </ul>
                </div>
                <!-- <p><small>
                    - ความเร็วอินเทอร์เน็ตขึ้นอยู่กับพื้นที่การใช้งาน
                    -
                    บริษัทสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดเงื่อนไขของบริการและรายการส่งเสริมการขายโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                    - บริษัทสงวนสิทธิ์ในการจัดการบริหารเครือข่ายตามความเหมาะสมเพื่อรักษามาตรฐานคุณภาพของบริการ
                    และเพื่อช่วยให้ผู้ใช้บริการโดยรวมสามารถใช้งานได้อย่างมีประสิทธิ์ภาพ เช่น จำกัด หรือ
                    ลดความเร็วหรือดำเนินการใดๆ ในการรับส่งข้อมูลต่างๆ, การใช้งาน Bit Torrent, การแชร์เน็ตผ่าน
                    Hotspot, การดาวน์โหลดและ/หรือ อัพโหลดไฟล์ขนาดใหญ่, หรือ
                    การใช้งานใดที่มีการรับส่งข้อมูลในปริมาณมากอย่างต่อเนื่อง
                    หรือที่มีผลต่อการใช้บริการหรือเกิดความไม่เป็นธรรม
                    ก่อหรืออาจก่อให้เกิดความเสียหายต่อผู้ใช้บริการอื่น และ/หรือต่อเครือข่าย
                    หรือการให้บริการโดยรวมของบริษัท
                    - บริการตามรายละเอียดนี้เพื่อการสื่อสารเฉพาะบุคคลเท่านั้น มิใช่เพื่อถือเอาประโยชน์
                    หรือนำไปใช้งานในเชิงพาณิชย์ หรือให้บริการต่อได้
                    หากพบบริษัทขอสงวนสิทธิ์ในการยกเลิกการให้บริการได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</small></p> -->
            </div>
        </div>
        <div *ngIf="errorBook" class="col-12">
            <div class="d-flex justify-content-center animated fadeIn">
                <img class="w-50" src="../../../assets/img/item/2842746.svg">
            </div>
            <h2 *ngIf="codeRes === 101">เบอร์นี้มีคนจองแล้ว</h2>
            <p *ngIf="codeRes === 101">เบอร์นี้ถูกจองไว้ชั่วคราว กรุณาลองใหม่อีกครั้ง</p>
        </div>
        <!-- <button mdbBtn type="button" (click)="onCommingSoon()" class="bg-soft-green text-white rounded-pill"
            mdbWavesEffect>สั่งซื้อผ่านไลน์
            Feels</button> -->
    </div>
    <!-- Step 2 -->
    <div *ngIf="page==='personal'">
        <form [formGroup]="submitForm">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-left text-light-green-main">ข้อมูลส่วนตัว</h2>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>ชื่อ<span class="required-text">*</span></label>
                        <input type="text" name="firstname" class="form-control" placeholder="จำเป็นต้องกรอก"
                            formControlName="firstname">
                        <!-- <div class="invalid-feedback" *ngIf="submitted && f.firstName.errors">
                        <p *ngIf="f.firstName.errors.required">กรุณากรอกชื่อ</p>
                    </div> -->
                        <!-- <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="firstname" class="form-control form-control-sm"
                            formControlName="firstname">
                        <label for="firstname" class="">ชื่อจริง</label>
                    </div> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>นามสกุล<span class="required-text">*</span></label>
                        <input type="text" name="lastname" class="form-control" placeholder="จำเป็นต้องกรอก"
                            formControlName="lastname">
                        <!-- <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="lastname" class="form-control form-control-sm"
                            formControlName="lastname">
                        <label for="lastname" class="">นามสกุล</label>
                    </div> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>เบอร์ติดต่อ<span class="required-text">*</span></label>
                        <input type="text" name="contactnumber" class="form-control" placeholder="จำเป็นต้องกรอก"
                            formControlName="contactnumber" (keypress)="keyPressNumber($event)" maxlength="10">
                        <!-- <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="contactnumber" class="form-control form-control-sm"
                            formControlName="contactnumber" (keypress)="keyPressNumber($event)" maxlength="10">
                        <label for="contactnumber" class="">เบอร์ติดต่อ</label>
                    </div> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>อีเมล</label>
                        <input type="email" name="email" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>บัตรประชาชน/พาสปอร์ต</label><br>
                        <div class="form-check-inline">
                            <label class="customradio mr-3">
                                <span class="radiotextsty">บัตรประชาชน</span>
                                <input type="radio" name="idradio" formControlName="idradio" value="idcard">
                                <span class="checkmark"></span>
                            </label>
                            <label class="customradio">
                                <span class="radiotextsty">พาสปอร์ต</span>
                                <input type="radio" name="idradio" formControlName="idradio" value="passport">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left" *ngIf="f.idradio.value === 'idcard'">
                    <div class="form-group">
                        <label>เลขบัตรประชาชน 13 หลัก<span class="required-text">*</span></label>
                        <input type="text" name="idcard" class="form-control" formControlName="idcard"
                            placeholder="จำเป็นต้องกรอก" maxlength="13">
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left" *ngIf="f.idradio.value !== 'idcard'">
                    <div class="form-group">
                        <label>เลขหนังสือเดินทาง<span class="required-text">*</span></label>
                        <input type="text" name="passport" class="form-control" formControlName="passport"
                            placeholder="จำเป็นต้องกรอก">
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>วัน/เดือน/ปีเกิด<span class="required-text">*</span></label>
                        <input type="date" name="dob" class="form-control" formControlName="dob" />
                    </div>
                </div>
            </div>
            <!-- <div class="row" *ngIf="page==='address'">
                <div class="col-12">
                    <h2 class="text-left text-light-green-main">ที่อยู่</h2>
                </div>
                <div class="col-12 col-md-6 animated fadeIn">
                    <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="address" class="form-control form-control-sm">
                        <label for="address" class="">ที่อยู่</label>
                    </div>
                </div>
                <div class="col-6 animated fadeIn">
                    <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="subdistrict" class="form-control form-control-sm">
                        <label for="subdistrict" class="">ตำบล/แขวง</label>
                    </div>
                </div>
                <div class="col-6 animated fadeIn">
                    <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="district" class="form-control form-control-sm">
                        <label for="district" class="">อำเภอ/เขต</label>
                    </div>
                </div>
                <div class="col-6 animated fadeIn">
                    <div class="md-form form-sm mt-2 mb-2">
                        <input mdbInput type="text" id="province" class="form-control form-control-sm">
                        <label for="province" class="">จังหวัด</label>
                    </div>
                </div>
            </div> -->
        </form>
    </div>
    <!-- Step 3 -->
    <div *ngIf="page==='address'">
        <form [formGroup]="submitForm">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-left text-light-green-main">ที่อยู่จัดส่งซิม</h2>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>บ้านเลขที่, ถนน<span class="required-text">*</span></label>
                        <input type="text" name="housenumber" class="form-control" formControlName="housenumber"
                            placeholder="จำเป็นต้องกรอก">
                    </div>
                </div>
                <div class="col-12 col-md-6 animated fadeIn text-left">
                    <div class="form-group">
                        <label>ชื่ออาคาร ชั้น</label>
                        <input type="text" name="housename" class="form-control" formControlName="housename">
                    </div>
                </div>
                <div class="col-12 col-md-6 text-left">
                    <div class="form-group">
                        <label>จังหวัด<span class="required-text">*</span></label>
                        <ng-select class="ng-select-custom" [items]="Province" name="province" bindLabel="provincename"
                            [loading]="false" [virtualScroll]="true" formControlName="province"
                            placeholder="จำเป็นต้องกรอก" (change)="updateDistrict($event)">
                            <ng-template ng-option-tmp let-item="item">
                                {{item.provincename}} <br />
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-left">
                    <div class="form-group">
                        <label>{{districttitle}}<span class="required-text">*</span></label>
                        <ng-select class="ng-select-custom" [items]="Selecteddistrict" name="district"
                            bindLabel="districtname" [loading]="false" [virtualScroll]="true" formControlName="district"
                            placeholder="จำเป็นต้องกรอก" (change)="updateSubDistrict($event)">
                            <ng-template ng-option-tmp let-item="item">
                                {{item.districtname}} <br />
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-left">
                    <div class="form-group">
                        <label>{{subdistricttitle}}<span class="required-text">*</span></label>
                        <ng-select class="ng-select-custom" [items]="Selectedsubdistrict" name="subdistrict"
                            bindLabel="subdistrictname" [loading]="false" [virtualScroll]="true"
                            formControlName="subdistrict" placeholder="จำเป็นต้องกรอก" (change)="updateZipcode($event)">
                            <ng-template ng-option-tmp let-item="item">
                                {{item.subdistrictname}} <br />
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-left">
                    <div class="form-group">
                        <label>รหัสไปรษณีย์<span class="required-text">*</span></label>
                        <input type="text" name="zipcode" class="form-control" formControlName="zipcode"
                            placeholder="จำเป็นต้องกรอก">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- Step 4 -->
    <div class="row" *ngIf="page==='payment'">
        <div class="col-12 animated fadeIn">
            <h2 class="text-center text-light-green-main">ช่องทางการชำระเงิน</h2>
            <div class="row">
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='thaiqr'" src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='thaiqr'" src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="thaiqr" name="payment-method"
                            value="thaiqr" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='linepay'" src="../../../assets/img/icon/Payment/line.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='linepay'" src="../../../assets/img/icon/Payment/line.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="linepay" name="payment-method"
                            value="linepay" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-respo"><br></div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="wechatpay" name="payment-method"
                            value="wechatpay" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="alipay" name="payment-method"
                            value="alipay" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-respo"><br></div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio!=='creditcard'" src="../../../assets/img/icon/Payment/credit-card.png"
                            style="background-color: rgb(49, 
                                    49, 49); display: flex;" class="payment-img">
                        <img *ngIf="myRadio==='creditcard'" src="../../../assets/img/icon/Payment/credit-card.png"
                            style="background-color: rgb(49, 
                                    49, 49); display: flex;" class="payment-img-active">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="creditcard" name="payment-method"
                            value="creditcard" (click)="radio($event)">
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- Step 5 -->
    <div *ngIf="page==='orderdetail'">
        <div class="col-12 animated fadeIn">
            <h2 class="text-center text-light-green-main">สรุปรายการ</h2>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="col-12 box-area p-3">
                        <h3 class="text-left">รายละเอียดสินค้า</h3>
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <h2 class="m-0">เบอร์ของคุณ</h2>
                            </div>
                            <div>
                                <h2 class="m-0">{{selectNumber}}</h2>
                            </div>
                        </div>
                        <hr class="colorgraph">
                        <div class="row w-100">
                            <div class="col-12 text-left">
                                <h3 class="m-0">รายละเอียด</h3>
                            </div>
                        </div>
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <p class="m-0">ราคา</p>
                            </div>
                            <div>
                                <p class="m-0">{{originpricedetail}} บาท</p>
                            </div>
                        </div>
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <p class="m-0">ส่วนลด</p>
                            </div>
                            <div>
                                <p class="m-0">{{discountdetail}} บาท</p>
                            </div>
                        </div>
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <p class="m-0">ค่าจัดส่ง</p>
                            </div>
                            <div>
                                <p class="m-0">{{shippingdetail}} บาท</p>
                            </div>
                        </div>
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <p class="m-0">ภาษี</p>
                            </div>
                            <div>
                                <p class="m-0">{{taxpricedetail}} บาท</p>
                            </div>
                        </div>
                        <hr class="colorgraph">
                        <div class="row w-100 m-0 d-flex">
                            <div class="mr-auto">
                                <h3 class="m-0">ราคารวม</h3>
                            </div>
                            <div>
                                <h3 class="m-0">{{pricedetail}} บาท</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-space">
                    <div class="col-12 box-area p-3">
                        <p (click)="editPage()" id="billing_address_button" class="float-right">
                            แก้ไข
                        </p>
                        <h3 class="text-left">ที่อยู่จัดส่ง</h3>
                        <p class="text-left">
                            <i class="pr-3 fas fas fa-user"></i>
                            {{firstnamedetail}} {{lastnamedetail}}
                        </p>
                        <p class="text-left">
                            <i class="pr-3 fas fa-map-marked-alt"></i>
                            <span>{{addressdetail}}</span>
                        </p>
                        <p class="text-left">
                            <i class="pr-3 fas fa-phone-alt"></i>
                            <!-- <span>{{contactnumberdetail | slice:0:3}}</span> -->
                            <span>{{contactnumberdetail}}</span>
                        </p>
                        <hr class="colorgraph">
                        <div class="row w-100 m-0 d-flex">
                            <h3 class="mr-auto">ช่องทางการชำระเงิน</h3>
                            <h3>
                                <span *ngIf="myRadio==='thaiqr'">ธนาคาร</span>
                                <span *ngIf="myRadio==='linepay'">LINE Pay</span>
                                <span *ngIf="myRadio==='wechatpay'">WeChat Pay</span>
                                <span *ngIf="myRadio==='alipay'">ALIPAY</span>
                                <span *ngIf="myRadio==='creditcard'">Credit Card</span>
                            </h3>
                        </div>
                        <!-- <h3 class="text-left">ช่องทางการชำระเงิน</h3> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit Page 1 -->
    <div *ngIf="page==='editpage'">
        <div class="col-12 animated fadeIn">
            <h2 class="text-left text-light-green-main">
                <i class="fas fa-chevron-left"></i>
                แก้ไขข้อมูล
            </h2>
            <form [formGroup]="submitForm">
                <div class="row">
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>ชื่อ<span class="required-text">*</span></label>
                            <input type="text" name="firstname" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="firstname">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>นามสกุล<span class="required-text">*</span></label>
                            <input type="text" name="lastname" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="lastname">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>เบอร์ติดต่อ<span class="required-text">*</span></label>
                            <input type="text" name="contactnumber" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="contactnumber" (keypress)="keyPressNumber($event)" maxlength="10">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>อีเมล</label>
                            <input type="email" name="email" class="form-control" formControlName="email">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>บัตรประชาชน/พาสปอร์ต</label><br>
                            <div class="form-check-inline">
                                <label class="customradio mr-3">
                                    <span class="radiotextsty">บัตรประชาชน</span>
                                    <input type="radio" name="idradio" formControlName="idradio" value="idcard">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="customradio">
                                    <span class="radiotextsty">พาสปอร์ต</span>
                                    <input type="radio" name="idradio" formControlName="idradio" value="passport">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left" *ngIf="f.idradio.value === 'idcard'">
                        <div class="form-group">
                            <label>เลขบัตรประชาชน 13 หลัก<span class="required-text">*</span></label>
                            <input type="text" name="idcard" class="form-control" formControlName="idcard"
                                placeholder="จำเป็นต้องกรอก" maxlength="13">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left" *ngIf="f.idradio.value !== 'idcard'">
                        <div class="form-group">
                            <label>เลขหนังสือเดินทาง<span class="required-text">*</span></label>
                            <input type="text" name="passport" class="form-control" formControlName="passport"
                                placeholder="จำเป็นต้องกรอก">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 animated fadeIn text-left">
                        <div class="form-group">
                            <label>วัน/เดือน/ปีเกิด<span class="required-text">*</span></label>
                            <input type="date" name="dob" class="form-control" formControlName="dob" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Step 6 -->
    <div *ngIf="page==='success'">
        <div class="row animated fadeIn">
            <div *ngIf="!errorQR" class="col-12">
                <div class="row justify-content-center">
                    <div class="text-center rounded bg-soft-green p-1">
                        <div [className]="qrClass">
                            <qrcode [qrdata]="qrcode" [width]="qrSize" [errorCorrectionLevel]="'M'">
                            </qrcode>
                        </div>
                        <div [className]="'justify-content-center wh-200 align-items-center'+spinner">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <a [href]="link" *ngIf="spinner !== ' d-flex'" download class="text-white"
                            (click)="onClick()">บันทึกรูป</a>
                    </div>
                </div>
            </div>
            <div *ngIf="errorQR" class="col-12">
                <div class="d-flex justify-content-center animated fadeIn">
                    <img class="w-50" src="../../../assets/img/item/2842746.svg">
                </div>
                <h2>Error {{orderres}}</h2>
                <p *ngIf="!!!lowBalance">{{errorQrText}}</p>
                <p *ngIf="!!lowBalance">คุณสามารถเติมเงินได้อีกไม่เกิน {{lowBalance}} บาท กรุณาทำรายการใหม่อีกครั้ง</p>
            </div>
            <div *ngIf="spinner === ' d-flex'" class="col-12 mt-3 mb-3">
                <p>กรุณารอสักครู่ขณะนี้ระบบกำลังทำรายการให้ท่าน</p>
            </div>
            <div *ngIf="!errorQR && !successPage && spinner !== ' d-flex'" class="col-12 mt-3 mb-3">
                <h2>ขอบคุณที่ไว้ใจเรา</h2>
                <p>กรุณาบันทึกภาพ QR CODE และทำการชำระเงินที่แอพ
                    <span *ngIf="myRadio==='thaiqr' || myRadio==='creditcard'">ธนาคาร</span>
                    <span *ngIf="myRadio==='linepay'">LINE Pay</span>
                    <span *ngIf="myRadio==='wechatpay'">WeChat Pay</span>
                    <span *ngIf="myRadio==='alipay'">ALIPAY</span>
                    ของท่าน <b class="text-danger">ภายใน 24 ชั่วโมง</b><br>
                    เมื่อชำระเงินเรียบร้อยแล้วรอรับซิม Feels ที่บ้านได้เลย
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row w-100 justify-content-center">
        <div class="col-12 text-center">
            <p class="cus-text" *ngIf="errorTextCheck">{{errorText}}</p>
        </div>
        <button type="button" mdbBtn class="waves-light cus-text bg-soft-green text-white rounded-pill"
            aria-label="Close" (click)="onSent()" mdbWavesEffect>{{btntext}}</button>
    </div>
</div>
<qrcode #save id="qr" [qrdata]="qrcode" [width]="1000" elementType="img" [errorCorrectionLevel]="'M'" class="d-none">
</qrcode>