import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    page: string = 'intro';
    // page: string = 'agreement';
    // page: string = 'simcard';
    // page: string = 'detail';
    // page: string = 'personalinfo';
    // page: string = 'personalimage';
    // page: string = 'term';
    loading: boolean = false;
    errormessage: string;

    public slides: Array<Object> = [
        { "img": "../../../assets/img/bg/topup.png" },
        { "img": "../../../assets/img/bg/topup2.png" }
    ]

    constructor(private _router: Router, private register: RegisterService) {
        this.register.loading.subscribe((value) => {
            if (value !== undefined) {
                this.loading = value;
            }
        });
        this.register.page.subscribe((value) => {
            if (value !== undefined) {
                this.page = value;
            }
        });
        this.register.errormessage.subscribe((value) => {
            if (value !== undefined) {
                this.errormessage = value;
            }
        })
    }

    ngOnInit(): void {
    }

    onIntro() {
        this.page = 'agreement'
    }

    onDone() {
        this._router.navigate(['/', 'packages'])
    }

    onCommingSoon() {
        window.open('https://register.feels.co.th:8004/');
    }

}
