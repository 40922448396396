<main>
    <!-- <div class="w-100">
        <mdb-carousel [isControls]="true" [animation]="'slide'">
            <mdb-slide *ngFor="let slide of slides; let index=index">
                <img class="d-block w-100" style="height:40vh; object-fit: cover;" [src]="slide.img">
            </mdb-slide>
        </mdb-carousel>
    </div> -->
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/banners-web-online-sim-13-10-20.jpg">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/banners-phone-online-sim-13-10-20.jpg">
    </div>
    <div class="row justify-content-center w-100 m-0">
        <div class="col-12 col-lg-10 white elegant-color-text rounded-top-3 mt-3 mb-3 p-lg-5 pt-5">
            <div class="pb-3">
                <h1 class="text-center text-light-green-main fs-50">เลือกเบอร์ Feels</h1>
            </div>
            <div class="d-flex justify-content-center">
                <div class="row w-100">
                    <div class="col-12 col-lg-9">
                        <h2>เบอร์โดนใจใช่เลย</h2>
                        <div class="row w-100 align-items-center m-0">
                            <div class="md-form form-sm col-lg-8 col-12">
                                <input mdbInput type="text" maxlength="10" id="form1" [(ngModel)]="searchText"
                                    placeholder="ใส่เลขที่ชอบ เช่น 55 , 9"
                                    class="form-control form-control-sm elegant-color-text">
                                <label for="form1" class="">ค้นหาเลขที่สนใจ</label>
                            </div>
                            <div class="col-lg-4 col-12 pb-4">
                                <ng-select [items]="titleName" bindLabel="filtername" name="filtername"
                                    [(ngModel)]="selectTitle" placeholder="ค้นหาด้วย">
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.filtername}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 d-flex justify-content-center align-items-center">
                        <button (click)="onSearch()" type="button" mdbBtn
                            class="rounded-pill w-100 mt-2 mt-lg-3 mb-lg-0 mb-3 mr-0 ml-0 bg-soft-green text-white"
                            mdbWavesEffect>ค้นหา</button>
                    </div>
                    <!-- <div class="col-12 col-lg-5 pb-3">
                        <h2>เบอร์มงคล</h2>
                        <div class="d-flex justify-content-center">
                            <div (click)="selectLucky(0)"
                                [className]="'wh-50px rounded-circle d-flex justify-content-center align-items-center m-2 c-point transition'+luckyNumber[0]">
                                <i class="fas fa-dollar-sign fa-2x"></i>
                            </div>
                            <div (click)="selectLucky(1)"
                                [className]="'wh-50px rounded-circle d-flex justify-content-center align-items-center m-2 c-point transition'+luckyNumber[1]">
                                <i class="fas fa-heart fa-2x"></i>
                            </div>
                            <div (click)="selectLucky(2)"
                                [className]="'wh-50px rounded-circle d-flex justify-content-center align-items-center m-2 c-point transition'+luckyNumber[2]">
                                <i class="fas fa-briefcase fa-2x"></i>
                            </div>
                            <div (click)="selectLucky(3)"
                                [className]="'wh-50px rounded-circle d-flex justify-content-center align-items-center m-2 c-point transition'+luckyNumber[3]">
                                <i class="fas fa-dragon fa-2x"></i>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <!-- <div class="row w-100">
                    <div *ngIf="(!!!this.searchText) || (this.searchText === '')" class="col-12 row m-0 p-0">
                        <div *ngFor="let item of phonenumber.slice(indexFrom, indexTo + 1); let i = index"
                            class="col-md-3 col-sm-4 col-6 pr-2 pl-2">
                            <input type="submit"
                                class="btn btn-outline-green-main pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal waves-effect w-100 animated fadeIn"
                                value="{{item['phonenumber']}}" (click)="onKey($event)">
                        </div>
                    </div>
                    <div *ngIf="!!this.searchText" class="col-12 row m-0 p-0">
                        <div *ngFor="let item of phonenumberSearch.slice(indexFromSearch, indexToSearch + 1); let i = index"
                            class="col-md-3 col-sm-4 col-6 pr-2 pl-2">
                            <input type="submit"
                                class="btn btn-outline-green-main pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal waves-effect w-100 animated fadeIn"
                                value="{{item['phonenumber']}}" (click)="onKey($event)">
                        </div>
                    </div>
                    <div *ngIf="(phonenumber.length <= 0) && !!!this.searchText">
                        <p>ระบบกำลังปิดปรับปรุง</p>
                    </div>
                    <div *ngIf="(phonenumberSearch.length <= 0) && !!this.searchText" class="col-12 text-center p-3">
                        <i class="fas fa-box-open fa-2x mb-2 text-light-green-main"></i>
                        <p>ไม่มีเบอร์นี้ในระบบ</p>
                    </div>
                    <div class="col-12 text-center d-flex justify-content-center" *ngIf="!!this.searchText">
                        <a class="m-2 text-muted c-point-mute" *ngIf="indexFromSearch <= 0"><i
                                class="fas fa-arrow-alt-circle-left"></i>
                            ก่อนหน้า</a>
                        <a class="m-2" *ngIf="indexFromSearch > 0" (click)="onPrevious(true)"><i
                                class="fas fa-arrow-alt-circle-left text-light-green-main"></i>
                            ก่อนหน้า</a>
                        <a class="m-2 text-muted c-point-mute"
                            *ngIf="!(indexToSearch < (phonenumberSearch.length - 1))">ถัดไป <i
                                class="fas fa-arrow-alt-circle-right"></i></a>
                        <a class="m-2" *ngIf="indexToSearch < (phonenumberSearch.length - 1)"
                            (click)="onNext(true)">ถัดไป <i class="fas fa-arrow-alt-circle-right"></i></a>
                    </div>
                    <div class="col-12 text-center d-flex justify-content-center" *ngIf="!!!this.searchText">
                        <a class="m-2 text-muted c-point-mute" *ngIf="indexFrom <= 0"><i
                                class="fas fa-arrow-alt-circle-left"></i>
                            ก่อนหน้า</a>
                        <a class="m-2" *ngIf="indexFrom > 0" (click)="onPrevious(false)"><i
                                class="fas fa-arrow-alt-circle-left text-light-green-main"></i>
                            ก่อนหน้า</a>
                        <a class="m-2 text-muted c-point-mute" *ngIf="!(indexTo < (phonenumber.length - 1))">ถัดไป <i
                                class="fas fa-arrow-alt-circle-right"></i></a>
                        <a class="m-2" *ngIf="indexTo < (phonenumber.length - 1)" (click)="onNext(false)">ถัดไป <i
                                class="fas text-light-green-main fa-arrow-alt-circle-right"></i></a>
                    </div>
                </div> -->
                <div class="row w-100">
                    <div class="number-box loading-box" *ngIf="loadingnumber">
                        <div class="center">
                            <div class="spinner-border"></div>
                        </div>
                    </div>
                    <div class="number-box w-100" *ngIf="!loadingnumber">
                        <div class="col-12 row m-0 p-0" *ngIf="!numberNotFound">
                            <div *ngFor="let item of phonenumber.slice(indexFrom, indexTo + 1); let i = index"
                                class="col-md-3 col-sm-4 col-6 pr-2 pl-2">
                                <input type="submit"
                                    class="btn btn-outline-green-main pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100 animated fadeIn"
                                    value="{{item['phonenumber']}}"
                                    [ngClass]="{ 'class-active': selectNumber === item['phonenumber'] }"
                                    (click)="onKey($event)">
                            </div>
                        </div>
                        <div class="col-12 text-center p-3" *ngIf="(phonenumber.length <= 0)">
                            <p>ระบบกำลังปิดปรับปรุง</p>
                        </div>
                        <div *ngIf="numberNotFound" class="col-12 text-center p-3">
                            <i class="fas fa-box-open fa-2x mb-2 text-light-green-main"></i>
                            <p>ไม่มีเบอร์นี้ในระบบ</p>
                        </div>
                        <div class="col-12 text-center d-flex justify-content-center" *ngIf="!numberNotFound">
                            <a class="m-2 text-muted c-point-mute" *ngIf="indexFrom <= 0"><i
                                    class="fas fa-arrow-alt-circle-left"></i>
                                ก่อนหน้า</a>
                            <a class="m-2" *ngIf="indexFrom > 0" (click)="onPrevious(false)"><i
                                    class="fas fa-arrow-alt-circle-left text-light-green-main"></i>
                                ก่อนหน้า</a>
                            <a class="m-2" (click)="onNext(false)">ถัดไป <i
                                    class="fas text-light-green-main fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10 mb-3 p-3 white elegant-color-text rounded-bottom-3">
            <div class="d-flex justify-content-center">
                <div class="row w-100 justify-content-between">
                    <div class="col-12">
                        <div class="row w-100 justify-content-center m-0">
                            <div class="col-12 col-lg-8">
                                <h2 class="text-center">เลือกเบอร์ Feels</h2>
                                <p>เบอร์ของคุณคือ</p>
                                <div
                                    class="btn-outline-green-main p-2 rounded-pill d-flex justify-content-center align-items-center">
                                    <p class="m-0 fs-20 font-weight-bold">{{selectNumber}}</p>
                                </div>
                                <p *ngIf="numberError" class="text-center mt-3">
                                    กรุณาเลือกเบอร์มือถือที่ต้องการก่อนทำรายการ
                                </p>
                                <button (click)="onSubmit()" type="button" mdbBtn
                                    class="rounded-pill w-100 mt-3 mb-0 mr-0 ml-0 bg-soft-green text-white"
                                    mdbWavesEffect>ซื้อเลย</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>