<!-- Footer -->
<footer class="page-footer font-small footer-bg-soft-green shadow">
    <section class="py-3">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <h3 class="widget-title text-white">ฟีล</h3>
                    <ul class="lqd-custom-menu reset-ul text-white">
                        <li>
                            <a [routerLink]="['/about']">เกี่ยวกับเรา</a>
                        </li>
                        <li>
                            <a [routerLink]="['/frequencies']">เครือข่ายและคลื่นความถี่</a>
                        </li>
                        <li>
                            <a [routerLink]="['/policy']">นโยบายความเป็นส่วนตัว</a>
                        </li>
                        <li>
                            <a [routerLink]="['/sellingagreement']">ข้อเสนอการขายส่งบริการโทรศัพท์เคลื่อนที่แบบโครงข่ายเสมือน</a>
                        </li>
                        <li>
                            <a [routerLink]="['/complaintcenter']">ศูนย์รับเรื่องร้องเรียน</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6">
                    <h3 class="widget-title text-white">บริการแนะนำ</h3>
                    <ul class="lqd-custom-menu reset-ul text-white">
                        <li>
                            <a [routerLink]="['/ondemandpackage']">สร้างแพ็กเกจของคุณเอง</a>
                        </li>
                        <li>
                            <a [routerLink]="['/topup']">เติมเงิน</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6">
                    <h3 class="widget-title text-white">ติดต่อเรา</h3>
                    <p>
                        21/109, ซอยศูนย์วิจัย, ถนนพระราม 9, แขวงบางกะปิ, เขตห้วยขวาง, กรุงเทพมหานคร, 10310<br> <a class="text-white" href="tel:021147069">+66(0)2 114 7069</a>
                    </p>
                    <div id="Certificate-banners"></div>
                </div>
                <div class="col-md-3 col-sm-6">
                    <h3 class="widget-title text-white">ติดตามเราได้ที่</h3>
                    <ul class="social-icon social-icon-md">
                        <li>
                            <a href="https://web.facebook.com/Feels-Telecom-1976979502556378" class="opacity-05 reset-opacity-onhover" target="_blank">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCi089N8K1PUbqzEYzh5mp7A" class="opacity-05 reset-opacity-onhover" target="_blank">
                                <i class="fab fa-youtube-square"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://lin.ee/mG3Fm30" class="opacity-05 reset-opacity-onhover" target="_blank">
                                <i class="fab fa-line"></i>
                            </a>
                        </li>
                    </ul>
                    <h1 class="text-white mt-2 mb-0">Feels Call Center</h1>
                    <a class="text-white" href="tel:021147069"><h1 class="text-white m-0">02 114 7069</h1></a>
                </div>
            </div>
        </div>
    </section>
    <section class="bt-fade-white-015 py-3 mt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <ul class="lqd-custom-menu reset-ul inline-nav font-size-14">
                        <li>
                            <a [routerLink]="['/about']">รู้จักกับฟีล</a>
                        </li>
                        <li>
                            <a [routerLink]="['/proviso']">เงื่อนไขการให้บริการ</a>
                        </li>
                        <li>
                            <a [routerLink]="['/faq']">คำถามที่พบบ่อย</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 text-md-right">
                    <p class="my-0">
                        <span class="font-size-12">© Copyright 2020 Feels. All Rights Reserved.</span>
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!-- Copyright -->
    <!-- <div class="footer-copyright text-center py-3 text-white">
        <div class="row w-100 m-0 p-0 justify-content-around">
            <div class="col-6 col-md-3 row justify-content-center">
                <div id="Certificate-banners"></div>
            </div>
            <div class="col-12 justify-content-center col-md-3 row align-items-center">
                <div>
                    © 2020 Copyright:
                    <a href="https://mdbootstrap.com/"> Feels. All Rights Reserved.</a>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Copyright -->

</footer>