import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
    exampleimageClass: string = '';
    previewimageClass: string = ' d-none';
    examplebackimageClass: string = '';
    previewbackimageClass: string = ' d-none';
    peviewidcard: any;
    peviewbackidcard: any;
    disable: boolean = true;
    p_idcard = 10;
    rotation_idcard = 0;
    p_backidcard = 10;
    rotation_backidcard = 0;
    phonenumber: string;

    constructor(private register: RegisterService, private renderer: Renderer2) {
        this.phonenumber = this.register.phonenumber;
    }

    ngOnInit(): void {
    }

    importFile(event) {
        if (event.target.files.length == 0) {
            console.log("No file selected!");
            return
        }
        let file: File = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.exampleimageClass = ' d-none';
                this.previewimageClass = '';
                this.peviewidcard = reader.result
            };

            reader.readAsDataURL(file);
            this.rotation_idcard = 0;
            this.disable = false;
            // this.file_phone = this.$refs.file_phone.files[0];
            // this.phoneview = false;
        }
    }

    importBackFile(event) {
        if (event.target.files.length == 0) {
            console.log("No file selected!");
            return
        }
        let file: File = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.examplebackimageClass = ' d-none';
                this.previewbackimageClass = '';
                this.peviewbackidcard = reader.result
            };

            reader.readAsDataURL(file);
            this.rotation_backidcard = 0;
            this.disable = false;
            // this.file_phone = this.$refs.file_phone.files[0];
            // this.phoneview = false;
        }
    }

    rotateIdCard() {
        var img = document.getElementById('peviewidcard');
        var maring = (img.clientWidth - img.clientHeight) / 2;
        if (maring > 0) {
            this.p_idcard = maring;
        }
        if (this.rotation_idcard > 360) {
            this.rotation_idcard = 0;
        }
        this.rotation_idcard += 90;
        img.setAttribute("style", "transform: rotate(" + this.rotation_idcard.toString() + "deg); margin-top: " + this.p_idcard + "px;");
    }

    rotateBackIdCard() {
        var img = document.getElementById('peviewbackidcard');
        var maring = (img.clientWidth - img.clientHeight) / 2;
        if (maring > 0) {
            this.p_backidcard = maring;
        }
        if (this.rotation_backidcard > 360) {
            this.rotation_backidcard = 0;
        }
        this.rotation_backidcard += 90;
        img.setAttribute("style", "transform: rotate(" + this.rotation_backidcard.toString() + "deg); margin-top: " + this.p_backidcard + "px;");
    }

    facedetect(phonenumber, frontimg, frontangle) {
        this.register.facedetect(phonenumber, frontimg, frontangle).subscribe(
            (res: any) => {
                let json_res = JSON.parse(res);
                if (json_res.responsecode === 0) {
                    this.register.faceidone = json_res.faceid;
                } else {
                    this.register.faceidone = 'N';
                }
                this.ocrthaiidcard(phonenumber, frontimg, frontangle, this.register.backidcardbase64, this.register.backidcardangle);
            },
            (err: any) => {
                console.log('HTTP Error', err);
                // let json_error = JSON.parse(err);
                this.register.loading.next(false);
                Swal.fire({
                    title: 'ระบบมีปัญหา!',
                    html: '<div class="alert-text">ระบบมีปัญหา ' + err.status + ' ' + err.statusText + ' ' + err.error + ' กรุณาติดต่อเจ้าหน้าที่</div>',
                    icon: 'error',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        );
    }

    ocrthaiidcard(phonenumber, frontimg, frontangle, backimg, backangle) {
        this.register.ocrthatidcard(phonenumber, frontimg, frontangle, backimg, backangle).subscribe(
            (res: any) => {
                // this.register.loading.next(false);
                // console.log(res);
                // alert('SUCCESS!! :-)\n\n' + JSON.stringify(res, null, 4));
                let json_res = JSON.parse(res);
                this.register.loading.next(false);
                if (json_res.responsecode === 0) {
                    this.register.citizenid = json_res.citizenid;
                    this.register.firstname = json_res.fristname;
                    this.register.lastname = json_res.lastname;
                    this.register.birthday = json_res.brithday;
                    this.register.religion = json_res.religion;
                    this.register.address = json_res.address;
                    this.register.dateissue = json_res.dateissue;
                    this.register.dateexpire = json_res.dateexpire;
                    this.register.lasercode = json_res.lasercode;
                    this.register.page.next('personalinfo');
                } else {
                    if (json_res.responsecode === 101) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.exampleimageClass = '';
                                this.previewimageClass = ' d-none';
                                this.peviewidcard = null;
                                this.examplebackimageClass = '';
                                this.previewbackimageClass = ' d-none';
                                this.peviewbackidcard = null;
                                this.disable = true;
                            }
                        })
                    } else if (json_res.responsecode === 102) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปหลังบัตรได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.examplebackimageClass = '';
                                this.previewbackimageClass = ' d-none';
                                this.peviewbackidcard = null;
                                this.disable = true;
                            }
                        })
                    } else if (json_res.responsecode === 103) {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปหน้าบัตรได้ กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.exampleimageClass = '';
                                this.previewimageClass = ' d-none';
                                this.peviewidcard = null;
                                this.disable = true;
                            }
                        })
                    } else {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: '<div class="alert-text">มีปัญหาที่ api กรุณาลองใหม่อีกครั้ง</div>',
                            icon: 'warning',
                            confirmButtonColor: "#28a745",
                            confirmButtonText: "ตกลง",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.exampleimageClass = '';
                                this.previewimageClass = ' d-none';
                                this.peviewidcard = null;
                                this.disable = true;
                            }
                        })
                    }
                }
            },
            (err: any) => {
                console.log('HTTP Error', err);
                // let json_error = JSON.parse(err);
                this.register.loading.next(false);
                Swal.fire({
                    title: 'ระบบมีปัญหา!',
                    html: '<div class="alert-text">ระบบมีปัญหา ' + err.status + ' ' + err.statusText + ' ' + err.error + ' กรุณาติดต่อเจ้าหน้าที่</div>',
                    icon: 'error',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        );
    }

    nextPage() {
        // this.rotation_idcard += 90;
        // if (this.rotation_idcard > 360) {
        //     this.rotation_idcard = 0;
        // }
        this.register.frontidcardbase64 = this.peviewidcard.split(',').pop();
        this.register.frontidcardangle = this.rotation_idcard.toString();
        this.register.backidcardbase64 = this.peviewbackidcard.split(',').pop();
        this.register.backidcardangle = this.rotation_backidcard.toString();
        this.register.loading.next(true);
        // let testvalue = [this.phonenumber, this.register.frontidcardangle, this.register.backidcardangle];
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(testvalue, null, 4));
        this.facedetect(this.phonenumber, this.register.frontidcardbase64, this.register.frontidcardangle);
        // this.ocrthaiidcard(this.phonenumber, this.register.frontidcardbase64, this.register.frontidcardangle, this.register.backidcardbase64, this.register.backidcardangle);
        // this.register.page.next('personalinfo');
    }
}
