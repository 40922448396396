<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-10 pt-4 mb-3 p-0">
            <div class="col-12 row m-0 p-0 justify-content-around">
                <div class="col-lg-5 col-12 p-0">
                    <div class="col-12 p-3 content-area">
                        <h1 class="text-center">
                            สรุปการสั่งซื้อ
                        </h1>
                        <div class="line-items mt-md-0">
                            <div class="headers clearfix">
                                <div class="row">
                                    <div class="col-md-12 text-666 mb-3">
                                        <h3>รายการสินค้า</h3>
                                    </div>
                                    <div class="col-5">รายละเอียดสินค้า</div>
                                    <div class="col-3 text-center">จำนวน</div>
                                    <div class="col-4 text-right">ราคา</div>
                                </div>
                            </div>
                            <div class="items">
                                <div *ngFor="let item of orderitemdetail" class="row item">
                                    <div class="col-5 desc">
                                        <strong>{{item.item}}</strong>
                                        <br>
                                        <span>{{item.detail}}</span>
                                    </div>
                                    <div class="col-3 qty text-center">
                                        {{item.amount}}
                                    </div>
                                    <div class="col-4 amount text-right">
                                        {{formatMoney(item.price, 2)}} บาท
                                    </div>
                                </div>
                            </div>
                            <div class="total text-right">
                                <div class="field">
                                    รวมมูลค่าสินค้า <span>{{totaloriginprice}} บาท</span>
                                </div>
                                <!-- <div class="field">
                                    หักส่วนลด <span>1213213 บาท</span>
                                </div>
                                <div class="field">
                                    ค่าจัดส่ง <span>123123 บาท</span>
                                </div> -->
                                <div class="field">
                                    ภาษีมูลค่าเพิ่ม <span>{{totaltaxprice}} บาท</span>
                                </div>
                                <div class="field grand-total">
                                    จำนวนเงินรวมทั้งสิ้น <span class="text-light-green-main">{{totalprice}}</span> บาท
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-12 p-0 mt-3 mt-md-0">
                    <div class="col-12 p-3 content-area">
                        <p class="float-right edit-btn" (click)="edit()">
                            แก้ไข
                        </p>
                        <h3 class="text-left">ที่อยู่จัดส่ง</h3>
                        <p class="text-left">
                            <i class="pr-3 fas fas fa-user"></i>
                            {{name}}
                        </p>
                        <p class="text-left">
                            <i class="pr-3 fas fa-map-marked-alt"></i>
                            <span>{{address}}</span>
                        </p>
                        <p class="text-left">
                            <i class="pr-3 fas fa-phone-alt"></i>
                            <span>{{contactnumber}}</span>
                        </p>
                        <hr class="colorgraph">
                        <div class="row w-100 m-0 d-flex">
                            <h3 class="mr-auto">เลือกช่องทางการชำระเงิน</h3>
                            <div class="row">
                                <div class="payment-icon col-4">
                                    <label>
                                        <img src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                                            class="payment-img"
                                            [ngClass]="{ 'payment-img-active': paymentchannel === 'thaiqr' }">
                                        <input type="radio" #ctrl="ngModel" [(ngModel)]="paymentchannel" id="thaiqr"
                                            name="payment-method" value="thaiqr" (click)="radio($event)">
                                    </label>
                                </div>
                                <div class="payment-icon col-4">
                                    <label>
                                        <img src="../../../assets/img/icon/Payment/line.png" class="payment-img"
                                            [ngClass]="{ 'payment-img-active': paymentchannel === 'linepay' }">
                                        <input type="radio" #ctrl="ngModel" [(ngModel)]="paymentchannel" id="linepay"
                                            name="payment-method" value="linepay" (click)="radio($event)">
                                    </label>
                                </div>
                                <div class="payment-respo"><br></div>
                                <div class="payment-icon col-4">
                                    <label>
                                        <img src="../../../assets/img/icon/Payment/wechat.png" class="payment-img"
                                            [ngClass]="{ 'payment-img-active': paymentchannel === 'wechatpay' }">
                                        <input type="radio" #ctrl="ngModel" [(ngModel)]="paymentchannel" id="wechatpay"
                                            name="payment-method" value="wechatpay" (click)="radio($event)">
                                    </label>
                                </div>
                                <div class="payment-icon col-4">
                                    <label>
                                        <img src="../../../assets/img/icon/Payment/alipay.png" class="payment-img"
                                            [ngClass]="{ 'payment-img-active': paymentchannel === 'alipay' }">
                                        <input type="radio" #ctrl="ngModel" [(ngModel)]="paymentchannel" id="alipay"
                                            name="payment-method" value="alipay" (click)="radio($event)">
                                    </label>
                                </div>
                                <div class="payment-respo"><br></div>
                                <div class="payment-icon col-4">
                                    <label>
                                        <img src="../../../assets/img/icon/Payment/credit-card.png" class="payment-img"
                                            [ngClass]="{ 'payment-img-active': paymentchannel === 'creditcard' }">
                                        <input type="radio" #ctrl="ngModel" [(ngModel)]="paymentchannel" id="creditcard"
                                            name="payment-method" value="creditcard" (click)="radio($event)">
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 text-center p-3">
                                <button mdbBtn type="button" (click)="submit()"
                                    class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ยืนยัน</button>
                            </div>
                            <!-- <h3>
                                <span>ธนาคาร</span>
                            </h3> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>