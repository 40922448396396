<main>
    <div class="banner d-flex justify-content-center align-items-center">
        <h1 class="text-white fs-50 line-h-100 text-center">เงื่อนไขการให้บริการ</h1>
    </div>
    <div class="row justify-content-center m-0">
        <div class="text-about col-12 col-lg-10 p-3 p-lg-5 mt-3 rounded-top-3">
            <div class="mb-3 mt-lg-0 mt-3">
                <h1 class="fs-50 text-center text-light-green-main line-h-100">เงื่อนไขการให้บริการ</h1>
            </div>
            <h2 class="text-center m-0">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</h2><br>
            <div class="w-100 mt-3 pl-0 pl-md-5 pr-0 pr-md-5" style="text-align: start;">
                <p class="m-0">สัญญาการให้บริการโทรคมนาคมโทรศัพท์เคลื่อนที่ Feels ประเภทเรียกเก็บค่าธรรมเนียมและค่าบริการล่วงหน้า(Pre-Paid) ระหว่างผู้ให้บริการกับผู้ใช้บริการ</p>
                <ol class="pl-0 pl-md-5">
                    <li class="mt-3">ลักษณะและประเภทของบริการ
                        <p class="m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการตกลงให้บริการ และผู้ใช้บริการตกลงรับบริการ โทรศัพท์เคลื่อนที่ ดังนี้
                        </p>
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                บริการประเภทเสียง ผู้ใช้บริการสามารถใช้บริการการโทรเข้า - รับสายจากผู้ให้บริการโทรศัพท์เคลื่อนที่ได้ทุกราย
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                บริการรับส่งข้อมูลความเร็วสูง หรือ โมบายดาต้า ผู้ใช้บริการสามารถเข้าถึงอินเทอร์เน็ตผ่านอุปกรณ์ต่างๆ เช่น โทรศัพท์เคลื่อนที่ได้
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                บริการรับ – ส่งข้อความสั้น (SMS) ผู้ใช้บริการสามารถรับส่งข้อความสั้น SMS ได้
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">มาตรฐานการให้บริการของผู้ให้บริการ
                        <p class="m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการตกลงจะให้บริการตามมาตรฐาน และคุณภาพการให้บริการตามที่ได้โฆษณาไว้ หรือแจ้งให้ผู้ใช้บริการทราบ โดยมาตรฐาน และคุณภาพการให้บริการดังกล่าวต้องไม่ต่ำกว่าหลักเกณฑ์ที่หน่วยงานกำกับดูแลกำหนด
                        </p>
                    </li>
                    <li class="mt-3">ค่าธรรมเนียม ค่าบริการ หรือค่าใช้จ่ายอันเกิดจากการใช้บริการ
                        <p class="m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการจะเรียกเก็บค่าธรรมเนียม ค่าบริการ หรือค่าใช้จ่ายอื่นใดอันเกิดจากการใช้บริการของผู้ใช้บริการ โดยมีรายการดังต่อไปนี้ ทั้งนี้ ผู้ให้บริการขอรับรองว่าจะไม่มีการเรียกเก็บค่าธรรมเนียม ค่าบริการ หรือค่าใช้จ่ายอื่นนอกจากที่กำหนดไว้ในสัญญานี้
                        </p>
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                บริการประเภทเสียง คิดค่าธรรมเนียม 0.75 บาทต่อนาที (ในกรณีที่ผู้ใช้บริการใช้ไม่ครบนาที บริษัทจะคิดค่าธรรมเนียมแบบเต็มนาที)
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                บริการรับส่งข้อมูลความเร็วสูง คิดค่าธรรมเนียม 0.25 บาทต่อเมกกะไบท์ (MB)
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                บริการรับ – ส่งข้อความสั้น (SMS) คิดค่าธรรมเนียม 2 บาทต่อข้อความ
                            </li>
                        </ol>
                        <p class="m-0">
                            ผู้ให้บริการจะจัดให้มีระบบการแจ้งรายละเอียดเกี่ยวกับอัตราค่าธรรมเนียม ค่าบริการ และวิธีการคำนวณค่าธรรมเนียมและค่าบริการที่ชัดเจน รวมถึงค่าบริการที่เรียกเก็บเป็นการล่วงหน้าที่เหลืออยู่ให้ผู้ใช้บริการทราบ
                        </p>
                    </li>
                    <li class="mt-3">สิทธิ หน้าที่ และความรับผิดชอบของผู้ให้บริการและผู้ใช้บริการ
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                ในกรณีที่เกิดเหตุขัดข้องขึ้นกับการให้บริการของผู้ให้บริการจนเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการได้ตามปกติ ผู้ให้บริการจะดำเนินการแก้ไขเพื่อให้ผู้ใช้บริการสามารถใช้บริการโทรคมนาคมได้โดยเร็ว ทั้งนี้ ผู้ให้บริการจะไม่เรียกเก็บค่าบริการในช่วงเวลาที่เกิดเหตุขัดข้องดังกล่าว
                                เว้นแต่พิสูจน์ได้ว่าเหตุขัดข้องดังกล่าวเกิดขึ้นจากความผิดของผู้ใช้บริการ และผู้ให้บริการจะชดเชยเยียวยาให้แก่ผู้ใช้บริการตามที่ได้ตกลงกัน
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ในกรณีที่ผู้ให้บริการจะดำเนินการปรับปรุงโครงข่ายโทรคมนาคมในลักษณะที่อาจมีผลกระทบต่อการใช้บริการของผู้ใช้บริการ ผู้ให้บริการจะแจ้งให้ผู้ใช้บริการรับทราบ ก่อนดำเนินการอย่างน้อย 3 วัน การแจ้งดังกล่าวอาจดำเนินผ่านทางการส่งข้อความสั้น (SMS) หรือทางอีเมล์ ทั้งนี้
                                ในระหว่างการดำเนินการดังกล่าว หากเกิดปัญหาหรือเหตุขัดข้องทำให้ไม่สามารถให้บริการได้ตามปกติเป็นเวลาเกินกว่า 30 นาที ผู้ให้บริการจะประชาสัมพันธ์ให้ผู้ใช้บริการและสาธารณะรับทราบเพื่อหาทางเลือกอื่นในการสื่อสาร
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ให้บริการจะไม่ถือเอาเหตุแห่งการได้ส่งมอบเครื่องอุปกรณ์หรือสิ่งอื่นใดที่เกี่ยวข้องให้แก่ผู้ใช้บริการโดยไม่คิดค่าใช้จ่ายหรือคิดค่าใช้จ่ายในราคาที่ต่ำกว่าราคาตลาดของค่าอุปกรณ์ในขณะที่ส่งมอบเพื่อประโยชน์ในการใช้บริการนั้น มากำหนดเป็นเงื่อนไขอันก่อให้เกิดภาระแก่ผู้ใช้บริการ
                                หรือเรียกเก็บค่าปรับหรือค่าเสียหายจากการที่ผู้ใช้บริการยกเลิกสัญญาก่อนกำหนด
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการจะไม่ถือเอาเหตุแห่งการได้ส่งมอบเครื่องอุปกรณ์หรือสิ่งอื่นใดที่เกี่ยวข้องให้แก่ผู้ใช้บริการโดยไม่คิดค่าใช้จ่ายหรือคิดค่าใช้จ่ายในราคาที่ต่ำกว่าราคาตลาดของค่าอุปกรณ์ในขณะที่ส่งมอบเพื่อประโยชน์ในการใช้บริการนั้น
                                    มากำหนดเป็นเงื่อนไขอันก่อให้เกิดภาระแก่ผู้ใช้บริการ หรือเรียกเก็บค่าปรับหรือค่าเสียหายจากการที่ผู้ใช้บริการยกเลิกสัญญาก่อนกำหนด
                                </p>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ให้บริการและผู้ใช้บริการตกลงจะปฏิบัติตามกฎหมาย ตลอดจนบรรดาข้อกำหนดระเบียบ ประกาศ และคำสั่งต่างๆ ของหน่วยงานกำกับดูแลที่ใช้อยู่ในปัจจุบันและที่จะได้มีการแก้ไขเปลี่ยนแปลงต่อไปในอนาคต และผู้ใช้บริการจะต้องใช้บริการเพื่อการสื่อสารระหว่างการตามปกติเท่านั้น
                                โดยผู้ใช้บริการจะไม่ใช้บริการไปในทางที่ขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน หรือใช้ไปในทางที่ทำให้เกิดความเสียหายหรือเสื่อมเสียชื่อเสียงของบุคคลใดๆ หรือใช้เพื่อวัตถุประสงค์ที่ไม่ชอบด้วยกฎหมาย หรือนำออกให้เช่าหรือให้บริการแก่ผู้อื่นเชิงพาณิชย์จนส่งผลให้เกิดการใช้บริการสูงผิดปกติและส่งผลให้การใช้บริการของผู้ใช้บริการรายอื่นขัดข้องไม่สามารถใช้งานได้ตามปกติ
                                หรือยอมให้บุคคลอื่นใดกระทำการดังกล่าว
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ในกรณีที่ผู้ให้บริการได้กำหนดรหัสประจำตัวของผู้ใช้บริการหรือรหัสส่วนตัวใดๆ เพื่อใช้บริการหรือบริการเสริมใดของผู้ให้บริการ ผู้ใช้บริการตกลงที่จะเก็บรักษารหัสดังกล่าวไว้เป็นความลับ โดยจะไม่เปิดเผยให้บุคคลภายนอกทราบ และผูกพันการกระทำอันเกิดจากการใช้รหัสประจำตัวหรือรหัสส่วนตัวใดๆ
                                ของผู้ใช้บริการ
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการจะโอนสิทธิการใช้บริการตามสัญญาให้แก่บุคคลอื่นมิได้ เว้นแต่จะได้รับความยินยอมจากผู้ให้บริการ กรณีผู้ให้บริการยินยอมให้ผู้ใช้บริการโอนสิทธิการใช้บริการกับบุคคลอื่นแล้ว ถือว่าสิทธิการใช้บริการโทรศัพท์เคลื่อนที่ตามรายการส่งเสริมการขายเป็นอันสิ้นสุดลงทันที
                                ซึ่งในกรณีนี้ผู้รับโอนต้องดำเนินการสมัครหรือยื่นคำขอใช้บริการใหม่กับผู้ให้บริการต่อไป ทั้งนี้ ผู้ให้บริการมีสิทธิเรียกเก็บค่าธรรมเนียมตามอัตราที่หน่วยงานกำกับดูแลเห็นชอบ
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ใช้บริการโอนสิทธิการใช้บริการโดยมอบการครอบครองบัตรประจำตัวผู้ใช้บริการ (SIM Card) ให้แก่บุคคลอื่นหรือผู้รับโอน ผู้ใช้บริการจะต้องดำเนินการให้ผู้รับโอนมาดำเนินการให้ข้อมูลรายละเอียดเกี่ยวกับผู้รับโอน เพื่อแสดงตัวในฐานะเป็นผู้ใช้บริการและผู้ครอบครองบัตรประจำตัวผู้ใช้บริการ
                                    (SIM Card) ตามที่หน่วยงานกำกับดูแลกำหนดให้ผู้ให้บริการจัดเก็บข้อมูลแสดงตน
                                </p>
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ใช้บริการมิได้ดำเนินการตามวรรคสอง ผู้ใช้บริการไม่สามารถยกเหตุการณ์โอนสิทธิมาปฏิเสธความรับผิดชอบความเสียหายที่เกิดขึ้นอันเนื่องจากการใช้บริการโทรศัพท์เคลื่อนที่ของผู้รับโอนได้
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การให้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
                        <p class="m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ใช้บริการตกลงให้ข้อมูลส่วนบุคคลที่จำเป็นดังต่อไปนี้ พร้อมทั้งส่งมอบสำเนาเอกสารหรือหลักฐานซึ่งยืนยันข้อมูลส่วนบุคคลดังกล่าวให้แก่ผู้ให้บริการเพื่อการปฏิบัติตามสัญญา ทั้งนี้ ผู้ให้บริการสามารถขอตรวจสอบต้นฉบับของเอกสารหรือหลักฐานดังกล่าวได้ด้วย
                        </p>
                        <p class="mt-1">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( 1 ) ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล สัญชาติ วันเดือนปีเกิด
                        </p>
                        <p class="mt-1">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( 2 ) ข้อมูลเกี่ยวกับที่อยู่สำหรับติดต่อการจัดส่งใบแจ้งหนี้ค่าใช้บริการ และเอกสารอื่นๆ รวมทั้งเบอร์โทรศัพท์เพื่อใช้ในการติดต่อ
                        </p>
                        <p class="m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อนึ่ง ผู้ให้บริการรับรองว่าจะไม่นำข้อมูลดังกล่าวไปใช้ประโยชน์อื่นใดโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากผู้ใช้บริการ เว้นแต่ข้อมูลนั้นจะถูกนำไปส่งให้แก่หน่วยงานของรัฐตามที่กฎหมายกำหนด ทั้งนี้ ผู้ใช้บริการสามารถเพิกถอนความยินยอมที่จะให้ข้อมูลส่วนบุคคลเป็นลายลักษณ์อักษรต่อผู้ให้บริการเมื่อใดก็ได้
                        </p>
                    </li>
                    <li class="mt-3">การให้บริการเสริม
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ให้บริการจะจัดให้มีระบบการขอใช้บริการเสริมที่มีประสิทธิภาพ และเป็นธรรมต่อผู้ใช้บริการซึ่งสามารถตรวจสอบหรือยืนยันโดยชัดแจ้งในการขอใช้บริการเสริมเช่นว่านั้นได้
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการเสริมมีสิทธิทำรายการบริการเสริมแต่ละประเภทได้ไม่เกินจำนวนครั้งและจำนวนเงินที่ผู้ให้บริการกำหนดไว้ตามรายละเอียดของบริการแต่ละประเภทที่ได้แจ้งแก่ผู้ใช้บริการผ่านสื่อต่างๆ และหากปรากฏว่าผู้ใช้บริการใช้รหัสผ่านหรือรหัสประจำตัวผิดเกินกว่าที่กำหนด
                                ผู้ใช้บริการจะไม่สามารถทำรายการใดๆ ได้อีกจนกว่าผู้ใช้บริการจะติดต่อกับผู้ให้บริการเพื่อแก้ไขให้สามารถใช้บริการได้ดังเดิม
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการยินยอมให้ผู้ให้บริการเป็นผู้เรียกเก็บเงินค่าบริการ และหรือค่าธรรมเนียม/ ค่าใช้จ่ายต่างๆ ทั้งหมดจากผู้ใช้บริการแทนผู้ร่วมให้บริการเสริม
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้ ผู้ให้บริการจะต้องร่วมรับผิดกับผู้ร่วมให้บริการเสริมต่อผู้ใช้บริการในความเสียหายที่เกิดขึ้นจากการให้บริการเสริมด้วย
                                </p>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการยินยอมให้ผู้ให้บริการเป็นผู้เรียกเก็บเงินค่าบริการ และหรือค่าธรรมเนียม/ ค่าใช้จ่ายต่างๆ ทั้งหมดจากผู้ใช้บริการแทนผู้ร่วมให้บริการเสริม
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                กรณีผู้ใช้บริการประสงค์จะบอกเลิกบริการเสริม ผู้ให้บริการต้องดำเนินการดังกล่าวทันที และกรณีมีข้อโต้แย้ง หากผู้ให้บริการไม่สามารถพิสูจน์ได้ว่าผู้ใช้บริการแสดงเจตนาสมัครใช้บริการเสริมโดยชัดแจ้ง ผู้ให้บริการไม่มีสิทธิเรียกเก็บค่าบริการเสริมดังกล่าว
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการสามารถใช้บริการเสริมในต่างประเทศ หากผู้ให้บริการในต่างประเทศนั้นเปิดให้บริการนั้นๆ โดยผู้ใช้บริการต้องเสียค่าใช้บริการเพิ่มเติมตามอัตราของการบริการโทรศัพท์ข้ามแดนอัตโนมัติตามที่ผู้ให้บริการประเทศนั้นๆ ได้กำหนดไว้
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติหรือบริการโทรทางไกลระหว่างประเทศ
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                เมื่อผู้ใช้บริการได้สมัครหรือยื่นคำขอใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติกับผู้ให้บริการแล้ว ผู้ใช้บริการสามารถนำเลขหมายโทรศัพท์เคลื่อนที่ของผู้ใช้บริการ หรือเลขหมายอื่นที่ผู้ให้บริการกำหนดไปใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติในประเทศที่ผู้ให้บริการมีข้อตกลงดังกล่าวกับผู้ให้บริการในต่างประเทศและ/
                                หรือในกรณีที่ผู้ใช้บริการสมัครหรือยื่นคำขอใช้บริการโทรทางไกลระหว่างประเทศไว้กับผู้ให้บริการ ผู้ใช้บริการสามารถใช้โทรศัพท์จากประเทศไทยไปยังเลขหมายโทรศัพท์ปลายทางที่เป็นของต่างประเทศได้ตามวิธีการและขั้นตอนที่ผู้ให้บริการประกาศและแจ้งให้ผู้ใช้บริการทราบ
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการตกลงชำระค่าบริการหรือค่าธรรมเนียมอันเกิดขึ้นจากการใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติ และ/หรือบริการโทรทางไกลระหว่างประเทศตามจำนวนที่เรียกเก็บให้แก่ผู้ให้บริการ
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การขอข้อมูลการใช้บริการ
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการมีสิทธิขอข้อมูลเกี่ยวกับการใช้บริการของตนผ่านช่องทางที่ผู้ให้บริการกำหนดไว้ และผู้ให้บริการตกลงจะจัดหาข้อมูลเกี่ยวกับการใช้บริการตามที่ผู้ใช้บริการร้องขอผ่านช่องทางที่ผู้ให้บริการกำหนด ภายใน 30 วัน นับแต่วันที่ได้รับแจ้ง
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ปรากฏข้อโต้แย้งเกี่ยวกับการเรียกเก็บค่าบริการ ผู้ให้บริการตกลงจะพิสูจน์ข้อเท็จจริงเพื่อยืนยันความถูกต้องของการเรียกเก็บค่าบริการดังกล่าว และต้องแจ้งข้อมูลให้ผู้ใช้บริการทราบภายใน 30 วันนับแต่วันที่ผู้ใช้บริการมีคำขอ
                                </p>
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้ หากผู้ให้บริการไม่ดำเนินการภายใน 60 วัน ผู้ให้บริการสิ้นสิทธิในการเรียกเก็บค่าธรรมเนียมหรือค่าบริการในจำนวนที่ผู้ให้บริการได้โต้แย้งนั้น
                                </p>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ในกรณีที่ข้อเท็จจริงปรากฏว่า ผู้ให้บริการเรียกเก็บค่าบริการเกินกว่าจำนวนที่เกิดขึ้นจากการใช้บริการจริง ผู้ให้บริการตกลงคืนเงินส่วนต่างของค่าบริการที่เรียกเก็บเกินให้แก่ผู้ใช้บริการ ภายใน 30 วันนับแต่วันที่ข้อเท็จจริงยุติ เว้นแต่ผู้ใช้บริการจะได้ตกลงเลือกให้ดำเนินการในการคืนเงินส่วนต่างที่เรียกเก็บเกินเป็นอย่างอื่น
                                ทั้งนี้ การคืนเงินส่วนต่างให้แก่ผู้ใช้บริการ อาจคืนด้วยเงินสด เช็ค หรือนำเข้าบัญชีเงินฝากของผู้ใช้บริการ หรือตามวิธีการที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การระงับการใช้บริการชั่วคราว
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการสามารถใช้สิทธิระงับการใช้บริการของผู้ให้บริการเป็นการชั่วคราวได้ โดยแจ้งเป็นหนังสือ หรือด้วยวิธีการอื่นใดที่ผู้ให้บริการจัดขึ้นเพื่อรับแจ้งให้ผู้ให้บริการทราบล่วงหน้าเป็นเวลาไม่น้อยกว่า 3 วัน ผู้ให้บริการตกลงจะไม่เรียกเก็บค่าธรรมเนียมหรือค่าใช้จ่ายใดๆ
                                จากผู้ใช้บริการ
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการได้แจ้งขอระงับการใช้บริการต่อผู้ให้บริการตามวิธีการที่ได้กำหนดในสัญญาแล้ว ผู้ใช้บริการย่อมหลุดพ้นจากความรับผิดชอบในค่าบริการที่เกิดขึ้นภายหลังการแจ้งขอระงับการใช้บริการชั่วคราวมีผล เว้นแต่ผู้ให้บริการจะพิสูจน์ได้ว่าค่าบริการที่เกิดขึ้นเป็นผลจากการกระทำของผู้ใช้บริการ
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ให้บริการยินยอมให้ผู้ใช้บริการระงับการใช้บริการชั่วคราว โดยมีกำหนดระยะเวลา 30 วัน ในกรณีที่ผู้ใช้บริการระงับบริการเกินกว่าระยะเวลาขั้นสูงที่ผู้ให้บริการกำหนด ผู้ให้บริการอาจยกเลิกการให้บริการได้ โดยแจ้งให้ผู้ใช้บริการทราบล่วงหน้าไม่น้อยกว่า 30 วัน
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                เมื่อครบกำหนดการขอระงับการใช้บริการชั่วคราวแล้ว ผู้ให้บริการมีหน้าที่ต้องเปิดให้ผู้ใช้บริการสามารถเข้าใช้บริการดังกล่าวได้ทันที โดยผู้ให้บริการจะเรียกเก็บค่าธรรมเนียมหรือค่าใช้จ่ายใดๆจากผู้ใช้บริการไม่ได้
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ในกรณีที่มีเหตุจำเป็น ผู้ให้บริการมีสิทธิระงับการให้บริการเป็นการชั่วคราวต่อผู้ใช้บริการก็ได้ โดยแจ้งเป็นหนังสือพร้อมทั้งระบุเหตุการณ์ใช้สิทธิดังกล่าวให้แก่ผู้ใช้บริการทราบล่วงหน้า เป็นเวลาไม่น้อยกว่า 30 วัน เว้นแต่กรณีดังต่อไปนี้ ผู้ให้บริการสามารถระงับการให้บริการได้ทันที
                                <ol>
                                    <li class="cus-font-weight-light mt-1">
                                        เกิดเหตุสุดวิสัยขึ้นแก่ผู้ให้บริการ
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ใช้บริการใช้เอกสารปลอมหรือแสดงข้อความอันเป็นเท็จในการขอใช้บริการ
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการพิสูจน์ได้ว่าบริการที่ให้แก่ผู้ใช้บริการถูกนำไปใช้โดยมิชอบด้วยกฎหมายหรือฝ่าฝืนต่อสัญญา
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการพิสูจน์ได้ว่าผู้ใช้บริการได้นำบริการไปใช้เพื่อแสวงหารายได้โดยมีเจตนาไม่ชำระค่าบริการ
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการมีเหตุที่จำเป็นอย่างเร่งด่วนต้องบำรุงรักษาหรือแก้ไขระบบที่ใช้ในการให้บริการ
                                    </li>
                                </ol>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                กรณีที่ผู้ใช้บริการแจ้งเหตุเครื่องโทรศัพท์เคลื่อนที่และอุปกรณ์ระบุตัวใช้บริการสูญหายถูกโจรกรรมให้ผู้ให้บริการทราบเป็นหนังสือหรือทางโทรศัพท์ หรือโดยเครื่องมือสื่อสารอย่างอื่นหรือโดยวิธีอื่นใด ผู้ให้บริการจะดำเนินการระงับการให้บริการในทันทีที่ได้รับแจ้ง
                                และผู้ให้บริการไม่ต้องรับผิดชำระค่าธรรมเนียมและค่าบริการที่เกิดขึ้นภายหลังการแจ้งดังกล่าว เว้นแต่ผู้ให้บริการจะพิสูจน์ได้ว่าความรับผิดชอบในหนี้นั้นเกิดขึ้นจากการกระทำของผู้ใช้บริการเอง
                                <p class="m-0">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการจะดำเนินการระงับการให้บริการต่อเมื่อได้ตรวจสอบแล้วว่า ผู้แจ้งเป็นผู้ใช้บริการที่แท้จริงเท่านั้น
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การเลิกสัญญา
                        <ol>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการมีสิทธิเลิกสัญญาการใช้บริการโทรศัพท์เคลื่อนที่ได้โดยบอกกล่าวแจ้งความประสงค์เป็นหนังสือด้วยตัวเอง ณ สำนักงานบริการ สำนักงานสาขาของผู้ให้บริการ หรือส่งโทรสารพร้อมสำเนาบัตรประจำตัวประชาชนให้ผู้ให้บริการทราบล่วงหน้าไม่น้อยกว่า 5 วันทำการ ทั้งนี้
                                ผู้ใช้บริการจะต้องชำระค่าบริการครบถ้วนจนถึงวันที่ยกเลิกสัญญามีผลบังคับ
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ใช้บริการอาจใช้สิทธิเลิกสัญญาได้ทันที หากมีเหตุดังต่อไปนี้
                                <ol>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ใช้บริการไม่สามารถรับบริการจากผู้ให้บริการได้ด้วยเหตุที่เกิดขึ้นอย่างต่อเนื่องและอยู่นอกเหนือการควบคุมของผู้ใช้บริการ
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการได้ละเมิดข้อตกลงอันเป็นสาระสำคัญของสัญญา
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการตกเป็นบุคคลล้มละลาย
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการเปลี่ยนแปลงข้อตกลงในสัญญาหรือเงื่อนไขในการให้บริการ ซึ่งมีผลเป็นการลดสิทธิหรือประโยชน์อันพึงได้รับของผู้ใช้บริการลง เว้นแต่เกิดจากเหตุตามที่กฎหมายบัญญัติ
                                    </li>
                                </ol>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                ผู้ให้บริการสามารถยกเลิกการให้บริการตามสัญญา ได้ในกรณีต่อไปนี้
                                <ol>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการพิสูจน์ได้ว่าผู้ใช้บริการมีพฤติกรรมฉ้อฉลในการใช้บริการ หรือนำบริการไปใช้โดยผิดกฎหมาย หรือฝ่าฝืนข้อห้ามในสัญญา
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        ผู้ให้บริการไม่สามารถให้บริการได้โดยเหตุที่อยู่นอกเหนือการควบคุมของผู้ให้บริการ
                                    </li>
                                    <li class="cus-font-weight-light mt-1">
                                        เป็นการยกเลิกโดยบทบัญญัติแห่งกฎหมาย
                                    </li>
                                </ol>
                            </li>
                            <li class="cus-font-weight-light mt-1">
                                เมื่อสัญญาเลิกกัน ผู้ให้บริการจะคืนเงินในส่วนที่มิใช้บริการนั้นแก่ผู้ใช้บริการ เมื่อผู้ให้บริการได้ตรวจสอบหลักฐานแล้วว่าเป็นบุคคลเดียวกันผู้ใช้บริการหรือเป็นผู้รับมอบอำนาจจากผู้ใช้บริการอย่างถูกต้องแล้ว จะดำเนินการคืนเงินภายใน 30 วัน นับแต่วันเลิกสัญญาด้วยเงินสด
                                เช็ค หรือนำเข้าบัญชีเงินฝากของผู้ใช้บริการ หรือตามวิธีการที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3">การแก้ไขเปลี่ยนแปลงสัญญา
                        <p class="mt-1 m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไขเพิ่มเติมสัญญาในบริการนี้ตามที่ได้รับความเห็นชอบจากหน่วยงานกำกับดูแล โดยจะแจ้งให้ผู้ใช้บริการทราบล่วงหน้าผ่านเว็บไซต์ และสำนักงานบริการลูกค้าของผู้ให้บริการ ตัวแทนศูนย์บริการหรือศูนย์บริการรับข้อร้องเรียนของผู้ให้บริการ
                            หรือผ่านเลขหมายของผู้ใช้บริการ ทั้งนี้ ไม่ตัดสิทธิผู้ใช้บริการที่จะบอกเลิกสัญญาให้บริการได้ทันที ในกรณีที่การเปลี่ยนแปลงสัญญาให้บริการมีผลเป็นการลดสิทธิ หรือประโยชน์อันพึงได้รับของผู้ใช้บริการให้น้อยลงเว้นแต่เกิดจากเหตุตามที่กฎหมายกำหนด
                        </p>
                    </li>
                    <li class="mt-3">การร้องเรียนและการแก้ไขปัญหาข้อร้องเรียน
                        <p class="mt-1 m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่มีปัญหาเกี่ยวกับการใช้บริการ ผู้ใช้บริการสามารถร้องเรียนต่อผู้ให้บริการได้ ดังนี้
                        </p>
                        <p class="mt-1 m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ศูนย์รับเรื่องร้องเรียน: ศูนย์ลูกค้าสัมพันธ์ Feels โทร. <a class="text-light-green-main" href="tel:021147069">02-1147069</a> ตลอด 24 ชั่วโมง (โทรฟรี)
                        </p>
                        <p class="mt-1 m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- อีเมล: <a class="text-light-green-main" href="mailto:support@feels.co.th">support@feels.co.th</a>
                        </p>
                        <p class="mt-1 m-0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- เว็บไซต์ <a class="text-light-green-main" href="https://www.feels.co.th">https://www.feels.co.th</a>
                        </p>
                    </li>
                    <p class="mt-3 m-0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ให้บริการตกลงให้บริการ และผู้ใช้บริการตกลงใช้บริการของผู้ให้บริการ โดยคู่สัญญา แต่ละฝ่ายได้อ่านและเข้าใจข้อตกลงและเงื่อนไขการให้บริการนี้ตรงกันเป็นอย่างดีแล้ว อย่างไรก็ตาม ผู้ให้บริการมีสิทธิที่จะปฏิเสธไม่ทำสัญญา
                        หรือไม่เปิดให้ใช้บริการต่างๆ กับบุคคลใดๆ หรือกับผู้ใช้บริการได้ตามเงื่อนไขที่กำหนดไว้ในข้อตกลงฉบับนี้
                    </p>
                </ol>
                <!-- <p class="head-title">1. ลักษณะและประเภทของบริการ</p>
                <p>ผู้ให้บริการตกลงให้บริการ และผู้ใช้บริการตกลงรับบริการ โทรศัพท์เคลื่อนที่ ดังนี้</p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;1.1 “สัญญา” หมายถึง ข้อตกลงร่วมกันระหว่าง ผู้ให้บริการ กับ ผู้ใช้บริการ เกี่ยวกับการให้บริการโทรศัพท์เคลื่อนที่แก่ผู้ใช้บริการ โดยจะมีผลผูกพันและใช้บังคับกับผู้ให้บริการ และผู้ใช้บริการ ตามสิทธิ์และ หน้าที่ ที่กําหนดไว้ในสัญญาการให้บริการโทรคมนาคมฉบับนี้
                    ซึ่งคณะกรรมการเห็นชอบแล้วทันที เมื่อผู้ให้ บริการ ได้ยินยอมให้ผู้ใช้บริการได้ใช้บริการโทรศัพท์เคลื่อนที่ 1.1 “สัญญา” หมายถึง ข้อตกลงร่วมกันระหว่าง ผู้ให้บริการ กับ ผู้ใช้บริการ เกี่ยวกับการให้บริการโทรศัพท์เคลื่อนที่แก่ผู้ใช้บริการ
                    โดยจะมีผลผูกพันและใช้บังคับกับผู้ให้บริการ และผู้ใช้บริการ ตามสิทธิ์และ หน้าที่ ที่กําหนดไว้ในสัญญาการให้บริการโทรคมนาคมฉบับนี้ ซึ่งคณะกรรมการเห็นชอบแล้วทันที เมื่อผู้ให้ บริการ ได้ยินยอมให้ผู้ใช้บริการได้ใช้บริการโทรศัพท์เคลื่อนที่
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.2 “คําขอ” หมายถึง แบบคําขอทุกประเภทที่ผู้ให้บริการกําหนดขึ้น Feels
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.3 “สมัครใช้บริการ” หมายถึง การยื่นคําขอใช้บริการทุกประเภท โดยยื่นต่อผู้ให้บริการด้วยตนเองรวมถึง การพิมพ์หรือ เลือกข้อความและการกระทําใดๆที่ทําให้ปรากฏซึ่ง ข้อความตามวิธีการ ที่ผู้ให้บริการกําหนดไว้ ผ่านเครื่องโทรศัพท์เคลื่อนที่
                    หรือผ่านทางเครือข่ายอินเทอร์เน็ตหรือสื่ออื่นใดตามที่ ผู้ให้บริการกําหนด ทั้งที่มีอยู่ในปัจจุบัน และที่จะกําหนดขึ้นในอนาคต
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.4 “ผู้ให้บริการ” หมายถึง บริษัทฟีลเทเลคอม คอร์ปอเรชั่น จำกัด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.5 “ผู้ใช้บริการ” หมายถึง บุคคล หรือนิติบุคคลผู้ซึ่งได้ตกลงทําสัญญาใช้บริการ โทรศัพท์เคลื่อนที่ของผู้ให้บริการไว้กับผู้ให้บริการ และให้หมายความรวมถึงผู้รับโอนสิทธิ์ ตามสัญญาจากผู้ใช้บริการเดิม โดยได้รับความยินยอมจากผู้ให้บริการเป็นลายลักษณ์อักษรแล้ว
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.6 “ศูนย์บริการลูกค้า” หมายถึง ศูนย์บริการลูกค้าของผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.7 “ตัวแทนศูนย์บริการลูกค้า” หมายถึง ตัวแทนที่ได้รับการแต่งตั้งจากผู้ให้บริการให้เป็นผู้รับ สมัครและจดทะเบียนคําขอใช้บริการ รับเปลี่ยนแปลงทะเบียน รับชําระค่าใช้บริการ และให้บริการอื่นๆ ตามที่ ผู้ให้บริการมอบหมาย
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.8 “บริการโทรศัพท์เคลื่อนที่” หมายถึง บริการเสริม บริการโทรศัพท์เคลื่อนที่ข้ามแดนระหว่าง ประเทศ (International Roaming) บริการโทรศัพท์ทางไกลระหว่างประเทศ (International Direct Dialing)
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.9 “บริการโทรศัพท์เคลื่อนที่แบบชําระค่าธรรมเนียมและค่าบริการล่วงหน้า (Pre-Paid)” หมายถึง บริการโทรศัพท์เคลื่อนที่ซึ่งกําหนดให้ผู้ใช้บริการต้องชําระเงินค่าธรรมเนียม และค่าบริการต่างๆให้แก่ผู้ให้บริการ เป็นการล่วงหน้าก่อน
                    การใช้บริการทั้งนี้ค่าธรรมเนียมและค่าบริการที่ชําระไว้ล่วงหน้าจะถูกตัดทอนไปตามสัดส่วนของ ระยะเวลาใช้บริการและอัตราค่าธรรมเนียม และค่าบริการที่กําหนดไว้ในการใช้บริการแต่ละครั้ง
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.10 “บัตรประจําตัวผู้ใช้บริการ (Subscriber Identity Module Card “SIM Card”)” หมายถึง อุปกรณ์บรรจุหน่วยประมวลผลและความจําขนาดเล็ก ซึ่งผู้ให้บริการบันทึกหมายเลขโทรศัพท์ประจําตัวของ ผู้ใช้บริการ เพื่อให้ผู้ใช้บริการสามารถใช้บริการต่อสัญญาณกับโครงข่ายได้และใช้เก็บหมายเลขโทรศัพท์เคลื่อนที่
                    และข้อมูลต่างๆ ของผู้ใช้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.11 “บริการ” หมายถึง บริการปกติและบริการเสริม
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.12 “บริการปกติ” หมายถึง การให้บริการโทรคมนาคมที่ผู้ส่งหรือผู้รับสามารถสื่อสารกันได้เป็นคําพูด สัญญาณ เครื่องหมาย ตัวหนังสือ ภาพ เสียง หรืออื่นใด ที่สามารถสื่อความหมายได้และหมายความรวมถึง บริการโทรศัพท์ระหว่างประเทศบริการเพื่อการรับส่งข้อมูลบริการเชื่อมต่อและหรือบริการโทรคมนาคมอื่นที่ให้บริการ
                    ผ่านบริการโทรคมนาคมของผู้ให้บริการ เช่น การเชื่อมต่อเพื่อรับบริการ อินเทอร์เน็ต เป็นต้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.13 “บริการเสริม” หมายถึง บริการใดๆ ของผู้ให้บริการที่ให้บริการเพิ่มเติมนอกเหนือจากการ โทรออกและรับสายเข้าตามปกติทั้งประเภทที่ต้องหรือไม่ต้องสมัครขอใช้บริการก่อน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.14 “ผู้ร่วมให้บริการเสริม”หมายถึงผู้ที่มีข้อตกลงกับผู้ให้บริการเพื่อเสนอขายสินค้าหรือ ให้บริการแก่ผู้ใช้บริการที่ใช้บริการเสริม
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.15 “บริการโทรศัพท์ระหว่างประเทศ” หมายถึง การเรียกออกต่างประเทศโดยผู้ใช้บริการเอง (International Direct Dialing: IDD) โดยเรียกผ่านเลขหมาย สําหรับบริการเรียกออกต่างประเทศ หรือสัญลักษณ์ที่ใช้แทนรหัสเรียกออกต่างประเทศของผู้ให้บริการเอง
                    หรือของผู้ให้บริการโทรศัพท์ระหว่างประเทศ รายอื่นซึ่งมีข้อตกลงร่วมกับผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.16 “บริการข้ามแดนอัตโนมัติ” หมายถึง การให้บริการโทรศัพท์เคลื่อนที่ข้ามแดนระหว่าง ประเทศ (International Roaming) โดยใช้โครงข่ายของผู้ให้บริการโทรศัพท์เคลื่อนที่ในต่างประเทศ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.17 “ข้อมูลส่วนบุคคล” หมายถึง ชื่อ สกุล ที่อยู่ของผู้ใช้บริการและให้หมายความรวมถึง รายละเอียดเกี่ยวกับผู้ใช้บริการ ที่สามารถระบุตัวผู้ใช้บริการ หรืออาจระบุตัวผู้ใช้บริการนั้นได้ ไม่ว่าทางตรง หรือทางอ้อม
                    ข้อมูลการใช้บริการ เลขหมายโทรคมนาคม และพฤติกรรมการใช้บริการโทรคมนาคมของ ผู้ใช้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.18 “วงเงินค่าใช้บริการ” หมายถึง จํานวนเงินค่าใช้บริการสูงสุดที่ผู้ใช้บริการได้ทําการเติมเงิน หรือชําระค่าบริการล่วงหน้าตามประเภท จํานวน หรือวิธีการที่ผู้ให้บริการกําหนด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.19 “เติมเงิน” หรือ “การเติมเงิน” หมายถึง การชําระค่าบริการล่วงหน้าผ่านช่องทางต่างๆ ที่ผู้ให้บริการกําหนดและเผยแพร่ให้ผู้ใช้บริการ ทราบล่วงหน้าเป็นการทั่วไปผ่านเว็บไซต์ และศูนย์บริการลูกค้าของผู้ให้บริการ
                    เช่น บัตรเติมเงินของผู้ให้บริการเอง การชําระเงินออนไลน์ การชําระเงินผ่านช่องทาง อิเล็กทรอนิกส์การชําระเงินผ่านเคาน์เตอร์เซอร์วิส หรือ ร้านบริการรับชําระค่าสินค้าและบริการการชําระเงิน ณ ศูนย์บริการลูกค้าของผู้ให้บริการ เป็นต้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.20 “รายการส่งเสริมการขาย” หมายถึง รายการส่งเสริมการให้บริการของผู้ให้บริการ เช่น โปรโมชั่นแพ็กเกจอัตราค่าบริการส่วนลดค่าบริการ ซึ่งผู้ให้บริการกําหนดขึ้น สําหรับการให้บริการแต่ละประเภทแก่ผู้ใช้บริการ โดยผู้ใช้บริการมีสิทธิ์
                    เลือกใช้รายการส่งเสริมการขายดังกล่าวกับ การใช้บริการของตนได้ภายใต้เงื่อนไขที่ผู้ให้บริการกําหนด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.21 “สิทธิ์พิเศษอื่นๆ” หมายถึง สิทธิ์ประโยชน์อื่นๆ นอกเหนือจากรายการส่งเสริมการขาย ที่ผู้ให้บริการกําหนดขึ้นสําหรับการให้บริการแก่ผู้ใช้บริการโดยผู้ใช้บริการมีสิทธิ์เลือกรับสิทธิ์พิเศษอื่นๆ ดังกล่าว เพิ่มเติมจากการใช้บริการได้
                    ภายใต้เงื่อนไขที่ผู้ให้บริการกําหนด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.22 “คณะกรรมการ” หมายถึง คณะกรรมการกิจการกระจายเสียง กิจการโทรทัศน์และกิจการ โทรคมนาคมแห่งชาติ(กสทช.)
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.23 “ค่าบริการ” หมายถึง ค่าธรรมเนียมหรือค่าใช้จ่ายอื่นใด ที่ผู้ให้บริการเรียกเก็บ จากผู้ใช้บริการอันเนื่องจากการได้ใช้ประโยชน์ หรือจะได้ใช้ประโยชน์ในบริการโทรศัพท์เคลื่อนที่ ของผู้ให้บริการ รวมถึงค่าบริการโทรทางไกลระหว่างประเทศ
                    ที่เรียกเก็บตามจํานวน หรือปริมาณการใช้ในแต่ละรอบการใช้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.24 “รอบค่าใช้บริการ” หมายถึง รอบระยะเวลาของการให้บริการเพื่อใช้ในการเรียกเก็บค่าใช้ บริการตามที่ผู้ให้บริการกําหนด ซึ่งไม่เกินกว่า 31 วันต่อรอบ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1.25 “การระงับใช้บริการชั่วคราว” หมายถึง การระงับการใช้บริการโทรคมนาคมทั้งการโทรออก และการรับสายเรียกเข้าเป็นการชั่วคราว”
                </p>
                <a class="head-title">2. การทำสัญญาและการใช้บริการ</a>
                <p>
                    2. การทําสัญญาและการใช้บริการ     &nbsp;&nbsp;&nbsp;&nbsp;2.1 เมื่อผู้ใช้บริการ ได้สมัครใช้บริการโดยวิธีการทํารายการลงทะเบียน บัตรประจําตัวผู้ใช้บริการ (SIMCard) เพื่อเปิดใช้บริการโทรศัพท์เคลื่อนที่กับผู้ให้บริการ หรือด้วยวิธีการทางอิเล็กทรอนิกส์ผ่านโทรศัพท์
                    เคลื่อนที่รวมถึงการพิมพ์หรือเลือกข้อความและการกระทําใดๆ ที่ทําให้ปรากฏซึ่งข้อความตามวิธีการที่ผู้ให้บริการ กําหนดไว้ผ่านเครื่องโทรศัพท์เคลื่อนที่ หรือผ่านทางเครือข่ายอินเทอร์เน็ตหรือสื่ออิเล็กทรอนิกส์อื่นใดตามที่ผู้ให้ บริการกําหนด
                    หรือ เมื่อผู้ให้บริการได้เปิด หรือ ยินยอมให้ผู้ใช้บริการใช้บริการโทรศัพท์เคลื่อนที่แล้ว ถือว่าผู้ใช้ บริการได้ตกลง ทําสัญญาการให้บริการโทรคมนาคม กับผู้ให้บริการ ตามเงื่อนไขและข้อตกลงที่กําหนดไว้ ใน เอกสารนี้
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.2 ผู้ใช้บริการต้องให้ข้อมูลชื่อ-สกุล ที่อยู่พร้อมสําเนาบัตรประจําตัวประชาชน หรือบัตรอื่นใด ที่หน่วยงานราชการออกให้ เพื่อแสดงตัวในฐานะเป็นผู้ใช้บริการ และผู้ครอบครองบัตรประจําตัวผู้ใช้บริการ (SIM Card)
                    ทั้งนี้ เป็นไปตามที่คณะกรรมการกําหนดให้ ผู้ให้บริการจัดเก็บข้อมูลแสดงตน กรณีผู้ใช้บริการ ไม่ยินยอมให้ข้อมูลแสดงตนผู้ให้บริการมีสิทธิ์ระงับการใช้บริการ ชั่วคราวได้ตามกฎหมาย และ/หรือประกาศ หลักเกณฑ์ที่คณะกรรมการได้กําหนดไว้ทั้งในปัจจุบันและที่จะมีเกิดขึ้นในภายหน้า
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.3 ผู้ใช้บริการจะได้รับสิทธิ์ประโยชน์ในการใช้บริการ ตามเงื่อนไขและรายละเอียดที่กําหนดไว้ใน รายการส่งเสริมการขาย ที่ผู้ให้บริการประกาศไว้ให้แก่ผู้ที่สมัครใช้บริการ หรือ ที่ใช้บริการอยู่ในแต่ละช่วงเวลานั้น
                    ทั้งนี้ผู้ใช้บริการมีสิทธิ์ขอเปลี่ยนแปลงรายการ ส่งเสริมการขายและการใช้บริการได้ตามรายการส่งเสริมการขาย ที่ผู้ให้บริการเปิดให้สมัครใช้ได้อยู่ณ เวลานั้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.4 ผู้ใช้บริการจะต้องใช้บริการตามข้อกําหนด และเงื่อนไขที่ผู้ให้บริการกําหนด รวมทั้งการ ใช้บริการต้องไม่มีลักษณะอย่างหนึ่งอย่างใด ดังต่อไปนี้
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) ใช้บริการ หรืออนุญาตให้บุคคลอื่นใช้บริการในลักษณะหยาบคาย ลามก อนาจาร ไม่ชอบด้วยกฎหมาย หรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) ใช้เพื่อวัตถุประสงค์ในการ ข่มขู่ รบกวน คุกคาม ล่วงเกิน หรือเป็นการกระทําละเมิด ต่อสิทธิ์ในความเป็นส่วนตัว หรือทําให้บุคคลอื่น เสื่อมเสียชื่อเสียง
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) กระทําการใดอันเป็นการละเมิดสิทธิ์ในทรัพย์สินทางปัญญา
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4) ใช้เพื่อการสร้างความเข้าใจผิด และ/หรือหลอกลวงประชาชน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5) ใช้ในลักษณะที่ต้องห้ามหรือขัดต่อกฎหมาย ระเบียบ จรรยาบรรณ หรือประกาศใดๆ ที่ใช้บังคับ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6) ดําเนินการใดๆ ในลักษณะของการแทรกแซง การให้บริการ ของผู้ให้บริการ หรือ ผู้ให้ บริการรายอื่น ซึ่งอาจก่อให้เกิดความเสียหาย ต่อผู้ให้บริการหรือโครงข่ายโทรคมนาคมอื่นๆ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.5 หากผู้ใช้บริการ ได้ใช้บริการอันเป็นการขัดต่อสัญญาให้บริการนี้ หรือ ขัดต่อกฎหมายใดๆ อันเป็นการละเมิดต่อบุคคลภายนอก และมีความเสียหายเกิดขึ้นผู้ใช้บริการต้องรับผิด ในความเสียหายที่เกิดขึ้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.6 ผู้ใช้บริการจะต้องเติมเงินผ่านช่องทาง วิธีการ และด้วยจํานวนเงินขั้นต่ําตามที่ผู้ให้บริการกําหนด ทั้งนี้หากผู้ใช้บริการตกลงเข้าร่วมรายการการส่งเสริมการขาย หรือขอรับสิทธิ์พิเศษอื่นๆที่ผู้ให้บริการเสนอผู้ใช้บริการตกลงปฏิบัติตามข้อกําหนด
                    หรือเงื่อนไขต่างๆ ที่เกี่ยวข้อง
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.7 ผู้ให้บริการมีสิทธิ์นําข้อมูลส่วนบุคคลของผู้ใช้บริการ ไปใช้ในการให้บริการได้ โดยต้องได้รับความยินยอมจากผู้ใช้บริการ และเพื่อประโยชน์ในการดําเนินกิจการโทรคมนาคม และเป็นไปตามหลักเกณฑ์ ที่กําหนดตามที่คณะกรรมการประกาศกําหนดในเรื่องมาตรการคุ้มครองสิทธิ์ของผู้ใช้บริการโทรคมนาคม
                    เกี่ยวกับข้อมูลส่วนบุคคลฯ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2.8 กรณีผู้ใช้บริการโอนสิทธิ์ การใช้บริการ โดยมอบการครอบครองบัตรประจําตัวผู้ใช้บริการ (SIMCard) ให้แก่บุคคลอื่น ผู้รับโอน หรือผู้ใช้บริการจะต้องดําเนินการ ให้ผู้รับโอนมาดําเนินการให้ข้อมูล รายละเอียดเกี่ยวกับผู้รับโอน
                    เพื่อแสดงตัว ในฐานะเป็นผู้ใช้บริการและผู้ครอบครองบัตรประจําตัวผู้ใช้บริการ (SIMCard) ทั้งนี้ เป็นไปตามที่คณะกรรมการกําหนดให้ ผู้ให้บริการจัดเก็บข้อมูลแสดงตน     กรณีผู้ใช้บริการมิได้ดําเนินการตามวรรคแรก ผู้ใช้บริการไม่สามารถยกเหตุการโอนสิทธิ์มาปฏิเสธ
                    ความรับผิดชอบความเสียหายที่เกิดขึ้น อันเนื่องจากการใช้บริการโทรศัพท์เคลื่อนที่ของผู้รับโอนได้     กรณีผู้ให้บริการ ยินยอมให้ผู้ใช้บริการ โอนสิทธิ์การใช้บริการให้กับบุคคลอื่นแล้วถือว่าสิทธิ์ การใช้บริการโทรศัพท์เคลื่อนที่ตามรายการส่งเสริมการขาย
                    สิทธิ์การใช้บริการโทรศัพท์ข้ามแดนอัตโนมัติ และ/หรือการใช้บริการโทรทางไกลระหว่างประเทศเดิมของผู้ใช้บริการเป็นอันสิ้นสุดลงทันทีในกรณีนี้ผู้รับโอน ต้องดําเนินการสมัครหรือยื่นคําขอใช้บริการใหม่กับผู้ให้บริการต่อไป
                </p>
                <a class="head-title">3. การให้บริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;3.1 ผู้ให้บริการ จะให้บริการโทรศัพท์เคลื่อนที่ตามมาตรฐานและคุณภาพการให้บริการ ตามที่ได้โฆษณาไว้ หรือแจ้งให้ผู้ใช้บริการทราบ โดยมาตรฐานและคุณภาพการให้บริการดังกล่าว จะไม่ต่ํากว่าหลักเกณฑ์ ที่คณะกรรมการประกาศกําหนด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;3.2 ในกรณีที่เกิดเหตุขัดข้องขึ้นกับการให้บริการโทรศัพท์เคลื่อนที่ ของผู้ให้บริการอันเนื่องมาจาก มาตรฐานหรือคุณภาพการให้บริการ จนเป็นเหตุให้ผู้ใช้บริการ ไม่สามารถใช้บริการได้ตามปกติ ผู้ให้บริการมีหน้าที่ต้องดําเนินการแก้ไข
                    เพื่อให้ผู้ใช้บริการสามารถใช้บริการโทรศัพท์เคลื่อนที่ได้โดยเร็ว ผู้ให้บริการ จะไม่เรียกเก็บเงินค่าบริการในช่วงเวลาดังกล่าวจากผู้ใช้บริการ อย่างไรก็ตามค่าบริการที่เกิดขึ้นในช่วงเวลาที่สามารถใช้งานได้ตามปกตินั้น ผู้ใช้บริการยังคงมีหน้าที่ต้องชําระให้แก่ผู้ให้บริการ
                </p>
                <a class="head-title">4. วิธีการใช้บริการเสริม</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1 การใช้บริการเสริม ประเภทที่กําหนดให้ ต้องใช้รหัสผ่านหรือรหัสประจําตัวของผู้ใช้บริการ ผู้ใช้บริการต้องเก็บรักษารหัสดังกล่าวไว้เป็นความลับแต่เพียงผู้เดียวเท่านั้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4.2 ผู้ใช้บริการเสริม มีสิทธิ์ทํารายการบริการเสริมแต่ละประเภทได้ไม่เกินจํานวนครั้งและจํานวนเงินที่ผู้ให้บริการกําหนดไว้ ตามรายละเอียดของบริการแต่ละประเภท ที่ได้แจ้งแก่ผู้ใช้บริการผ่านสื่อต่างๆ และหากปรากฏว่าผู้ใช้บริการ
                    กดรหัสผ่านหรือรหัสประจําตัว ผิดเกินกว่าที่กําหนดผู้ใช้บริการ จะไม่สามารถทํารายการใดๆได้อีกจนกว่าผู้ใช้บริการจะติดต่อกับผู้ให้บริการ เพื่อแก้ไขให้สามารถใช้บริการได้ดังเดิม
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4.3 ผู้ใช้บริการ ยินยอมให้ผู้ให้บริการ เป็นผู้เรียกเก็บเงินค่าบริการ หรือค่าใช้จ่ายต่างๆทั้งหมดจากผู้ใช้บริการแทนผู้ร่วมให้บริการเสริม ทั้งนี้ผู้ให้บริการจะต้องร่วมรับผิดกับผู้ร่วมให้บริการเสริม ต่อผู้ใช้บริการในความเสียหายที่เกิดขึ้นจากการให้บริการเสริมด้วย
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4.4 กรณีผู้ใช้บริการ ประสงค์จะบอกเลิกบริการเสริม ผู้ให้บริการ ต้องดําเนินการดังกล่าวทันที และกรณีมีข้อโต้แย้งหากผู้ให้บริการไม่สามารถพิสูจน์ได้ว่า ผู้ใช้บริการแสดงเจตนาสมัครใช้บริการเสริมโดยชัดแจ้ง ผู้ให้บริการไม่มีสิทธิ์เรียกเก็บค่าบริการเสริมดังกล่าว
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4.5 ผู้ใช้บริการ สามารถใช้บริการเสริมในต่างประเทศ หากผู้ให้บริการในต่างประเทศนั้น เปิดให้บริการนั้นๆโดยผู้ใช้บริการต้องเสียค่าบริการเพิ่มเติม ตามอัตราของการบริการข้ามแดนอัตโนมัติตามที่ผู้ให้บริการประเทศนั้นๆ
                    หรือผู้ให้บริการได้กําหนดไว้
                </p>
                <a class="head-title">5. วิธีการใช้บริการข้ามแดนอัตโนมัติและ/หรือบริการโทรทางไกลระหว่างประเทศ
                    กรณีผู้ใช้บริการ สมัคร หรือยื่นคําขอใช้บริการข้ามแดนอัตโนมัติ และ/หรือ บริการโทรทางไกล ระหว่างประเทศ
                    ผู้ใช้บริการต้องปฏิบัติตามเงื่อนไขและข้อกําหนดของการใช้บริการเพิ่มเติม ดังต่อไปนี้</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;5.1 เมื่อผู้ใช้บริการได้สมัคร หรือยื่นคําขอใช้บริการข้ามแดนอัตโนมัติกับผู้ให้บริการแล้ว ผู้ใช้บริการ สามารถนําเลขหมายโทรศัพท์เคลื่อนที่ของผู้ใช้บริการ หรือเลขหมายอื่น ที่ผู้ให้บริการกําหนดไปใช้ บริการโทรศัพท์ข้ามแดนระหว่างประเทศ
                    ในประเทศที่ผู้ให้บริการ มีข้อตกลงดังกล่าว กับผู้ให้บริการูในต่าง ประเทศ และ/หรือ ในกรณีที่ผู้ใช้บริการ สมัครหรือยื่นคําขอใช้บริการโทรทางไกล ระหว่างประเทศไว้กับผู้ให้บริการผู้ใช้บริการสามารถใช้โทรศัพท์จากประเทศไทยไปยังเลขหมายปลายทางที่เป็นเบอร์โทรศัพท์
                    ของต่างประเทศได้ตามวิธีการ และขั้นตอนที่ผู้ให้บริการประกาศและแจ้งให้ผู้ใช้บริการทราบ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;5.2 ในระหว่างที่ใช้บริการข้ามแดนอัตโนมัติอยู่ในต่างประเทศ ผู้ใช้บริการจะต้องไม่ทําการจําหน่าย จ่าย โอน หรือเปลี่ยนแปลงสิทธิ์การเป็นผู้ใช้บริการ โทรศัพท์เคลื่อนที่ของเลขหมาย ที่นําไปใช้งานในต่างประเทศ
                </p>
                <a class="head-title">6. การแก้ไขเปลี่ยนแปลง</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;6.1 บรรดาข้อตกลง ข้อกําหนด และเงื่อนไขการใช้บริการ และเอกสารแนบท้ายสัญญา การให้บริการอื่นใดที่มีอยู่ก่อนแล้วระหว่างผู้ให้บริการ กับผู้ใช้บริการในส่วนที่กําหนดไว้แล้วในสัญญาการให้บริการนี้ซึ่งขัดหรือแย้งกับสัญญาให้บริการนี้ให้ใช้สัญญาให้บริการนี้แทน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;6.2 ผู้ให้บริการขอสงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไขเพิ่มเติมสัญญาให้บริการนี้ตามที่ได้รับความเห็นชอบจากคณะกรรมการ หรือตามที่คณะกรรมการประกาศกําหนด โดยจะแจ้งให้ผู้ใช้บริการทราบล่วงหน้า ผ่านเว็บไซต์และศูนย์บริการลูกค้าของผู้ให้บริการ
                    ตัวแทนศูนย์บริการลูกค้าหรือศูนย์บริการ รับข้อร้องเรียนของผู้ให้บริการ หรือผ่านเลขหมายของผู้ให้บริการ ทั้งนี้ไม่ตัดสิทธิ์ผู้ใช้บริการ ที่จะบอกเลิกสัญญาให้บริการได้ทันที ในกรณีที่การเปลี่ยนแปลงสัญญาให้บริการมีผลเป็นการลดสิทธิ์หรือประโยชน์
                    อันพึงได้รับของผู้ใช้บริการให้น้อยลง เว้นแต่เกิดจากเหตุตามที่กฎหมายกําหนด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;6.3 กรณีที่สัญญาข้อหนึ่งข้อใดขัดหรือแย้งกับกฎหมาย ระเบียบ หรือประกาศ ซึ่งออกโดย คณะกรรมการ หรือหน่วยงานของรัฐ ที่มีอํานาจตามกฎหมายคู่สัญญาทั้งสองฝ่าย ตกลงกันให้ถือว่าสาระสําคัญของสัญญาในข้ออื่นๆ ที่ไม่ขัดหรือแย้งกับกฎหมายระเบียบ
                    หรือประกาศดังกล่าวยังคงมีผลใช้บังคับต่อไป
                </p>
                <a class="head-title">7. การตรวจสอบข้อมูลการใช้บริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;7.1 ในกรณีที่ผู้ใช้บริการเห็นว่าผู้ให้บริการเรียกเก็บค่าบริการสูงกว่าอัตราที่กําหนดไว้หรือสูงกว่า ที่เรียกเก็บจากผู้ใช้บริการรายอื่นที่ใช้บริการโทรคมนาคม ในลักษณะหรือประเภทเดียวกัน หรือเห็นว่า ผู้ให้บริการเรียกเก็บค่าบริการไม่ถูกต้อง
                    ผู้ใช้บริการมีสิทธิ์ยื่นคําขอตรวจสอบข้อมูลการใช้บริการได้ ตามหลักเกณฑ์ที่กําหนดตามประกาศ กทช. เรื่องมาตรการคุ้มครองสิทธิ์ของผู้ใช้บริการโทรคมนาคมเกี่ยวกับข้อมูลส่วนบุคคลฯ ทั้งนี้ผู้ใช้บริการต้องยื่นคําขอเป็นหนังสือ ณ ศูนย์บริการลูกค้าของผู้ให้บริการ
                    เว็บไซด์ของผู้ให้บริการ หรือช่องทางอื่น ตามที่ผู้ให้บริการกําหนดเพิ่มเติม และผู้ให้บริการจะดําเนินการตรวจสอบข้อเท็จจริง และแจ้งผลการตรวจสอบข้อมูลให้ผู้ใช้บริการทราบโดยเร็ว แต่ทั้งนี้ต้องไม่เกินกว่า 30 วัน นับแต่วันที่ผู้ใช้บริการมีคําขอ
                    หากผู้ให้บริการไม่ดําเนินการภายในระยะเวลาที่กําหนด ให้ถือว่าผู้ให้บริการนั้นสิ้นสิทธิ์ ในการเรียกเก็บค่าธรรมเนียมหรือค่าบริการในจํานวนที่ผู้ใช้บริการได้โต้แย้งนั้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;7.2 กรณีข้อเท็จจริงปรากฏว่าผู้ให้บริการเรียกเก็บค่าบริการเกินกว่าจํานวนที่เกิดขึ้นจากการ ใช้บริการจริง ผู้ให้บริการจะคืนเงินส่วนต่างของค่าบริการให้แก่ผู้ใช้บริการ ภายใน 30 วัน นับแต่วันที่ข้อเท็จจริงยุติ
                    และผู้ให้บริการต้องชําระดอกเบี้ย ในส่วนต่างในอัตราเท่ากับที่ได้กําหนดว่าจะเรียกเก็บจากผู้ใช้บริการ ในกรณีที่ผู้ใช้บริการผิดนัด โดยผู้ให้บริการจะจ่ายคืนเงินให้เป็นเงินสด หรือเช็ค หรือนําเข้าบัญชีเงินฝากของผู้ใช้บริการ ตามที่ผู้ใช้บริการแจ้งไว้
                    หรือโอนเงินค่าบริการที่ ผู้ใช้บริการชําระล่วงหน้าไว้ ไปยังเลขหมายอื่น ที่อยู่ภายในเครือข่ายเดียวกันตามที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;7.3 ผู้ให้บริการจะดําเนินการตรวจสอบข้อมูลการใช้บริการ แจ้งผล และคืนเงิน(ถ้ามี) ให้แก่ ผู้ใช้บริการที่มีหลักฐานแสดงตนว่าเป็นผู้ใช้บริการที่แท้จริงเท่านั้น
                </p>
                <a class="head-title">8. อัตราและการเรียกเก็บค่าบริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;8.1 ผู้ให้บริการจะเรียกเก็บค่าบริการต่อผู้ให้บริการตามอัตราที่ผู้ให้บริการกําหนด ทั้งนี้อัตรา ค่าบริการประเภทเสียง (voice) บริการที่ไม่ใช่ประเภทเสียง (non-voice) ให้เป็นไปตามเงื่อนไขใบอนุญาต ประกอบกิจการโทรคมนาคมแบบที่สามของผู้ให้บริการ
                    จนกว่าคณะกรรมการจะออกประกาศกําหนดอัตรา ขั้นสูงต่อไป ซึ่งผู้ให้บริการได้เผยแพร่ และแจ้งให้ผู้ใช้บริการทราบเป็นการทั่วไป ณ ที่ทําการ และเว็บไซต์ ของผู้ให้บริการผู้ให้บริการรับรองว่าจะไม่เรียกเก็บค่าธรรมเนียม หรือค่าบริการนอกเหนือจากที่กําหนดดังกล่าว
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;8.2 ภายใต้อัตราค่าบริการตามข้อ 8.1 ผู้ให้บริการอาจลดหรือยกเว้นการเรียกเก็บค่าบริการ ตามสัญญาให้บริการนี้ทั้งหมด หรือแต่บางส่วนได้ โดยขึ้นอยู่กับเงื่อนไขของรายการส่งเสริมการขาย หรือสิทธิ์พิเศษอื่นๆที่ผู้ใช้บริการเข้าร่วมหรือได้รับ
                    ทั้งนี้ตามที่ผู้ใช้บริการได้แจ้งความประสงค์ให้ผู้ให้บริการทราบ ภายใต้เงื่อนไขการเข้าร่วมรายการส่งเสริมการขาย หรือการขอรับสิทธิ์พิเศษอื่นๆ ทั้งนี้ เงื่อนไขในการเข้าร่วมรายการส่งเสริมการขายแต่ละรายการ หรือการขอรับสิทธิ์พิเศษอื่นๆ รวมถึงระยะเวลาของรายการส่งเสริม
                    การขายแต่ละรายการ หรือการรับสิทธิ์อื่นๆ จะเป็นไปตามที่ผู้ให้บริการ และผู้ใช้บริการได้ตกลงกัน
                </p>
                <a class="head-title">9. วิธีการชําระค่าใช้บริการและกําหนดเวลาชําระค่าใช้บริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;9.1 ผู้ใช้บริการ ต้องชําระค่าบริการและค่าธรรมเนียมให้แก่ผู้ให้บริการ เป็นการล่วงหน้าก่อนการใช้บริการโทรศัพท์ โดยวิธีการเติมเงิน(TopUp)ตามช่องทางการชําระเงินต่างๆที่ผู้ให้บริการกําหนดไว้ดังนี้ (1) การใช้บัตรเติมเงิน
                    (2) ศูนย์บริการลูกค้าของผู้ให้บริการ (3) ผ่านเครือข่ายการให้บริการอินเทอร์เน็ต กรณีผู้ให้บริการแก้ไข ลด หรือเพิ่มเติมช่องทางและวิธีการเติมเงิน (Top Up) ผู้ให้บริการจะประกาศ และแจ้งให้ผู้ใช้บริการทราบต่อไป
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;9.2 ผู้ใช้บริการสามารถทําการเติมเงิน(TopUp) เพื่อการใช้บริการโทรศัพท์เคลื่อนที่ได้ตลอดเวลาที่ใช้บริการ กรณีที่ยังคงมียอดเงินคงเหลืออยู่ในระบบการใช้บริการและผู้ใช้บริการได้มีการเติมเงิน (Top Up) ภายในกําหนดเวลา
                    ระบบจะนํายอดเงินเก่าที่เหลืออยู่รวมกับยอดเงินใหม่
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;9.3 ผู้ใช้บริการ สามารถตรวจสอบวงเงินคงเหลืออยู่ ในระบบการใช้บริการโทรศัพท์ได้ตลอด เวลา และผู้ให้บริการจะแจ้งวงเงินคงเหลือ ให้ผู้ใช้บริการทราบ เป็นลายลักษณ์อักษรผ่านระบบการให้บริ การส่งข้อความสั้น (SMS)
                </p>
                <a class="head-title">10. ระยะเวลาการใช้บริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;10.1 การเติมเงินแต่ละครั้งตามมูลค่าที่ผู้ให้บริการกําหนดไว้ผู้ใช้บริการจะได้รับระยะเวลาการใช้งาน เป็นจํานวนไม่น้อยกว่า 30 วัน ทั้งนี้มีระยะเวลาสะสมสูงสุด 365 วัน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;10.2 หากผู้ใช้บริการไม่มีระยะเวลาสะสมคงเหลือ ผู้ให้บริการขอสงวนสิทธิ์์ดําเนินการ ดังนี้ (1) ช่วงที่1 ระงับการโทรออก แต่สามารถรับสายและเติมเงินได้ภายใน 30 วัน (2) ช่วงที่2 ระงับการโทรออกและการรับสาย แต่สามารถเติมเงินได้ภายใน
                    30 วัน (3) ช่วงที่3 ไม่สามารถใช้บริการได้     ทั้งนี้ผู้ใช้บริการสามารถโอนเงินค่าบริการที่ชําระล่วงหน้าไว้ ไปยังเลขหมายอื่นที่อยู่ภายใต้ บริการ Feels ได้
                </p>
                <a class="head-title">11. การแจ้งขอระงับใช้บริการชั่วคราวของผู้ใช้บริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;11.1 ในกรณีที่ผู้ใช้บริการ มีเหตุจําเป็นไม่สามารถใช้บริการโทรศัพท์เคลื่อนที่ได้เป็นการชั่วคราว ผู้ใช้บริการสามารถขอระงับการใช้บริการเป็นการชั่วคราวได้ โดยต้องแจ้งความประสงค์เป็นคราวๆด้วยตนเอง หรือส่งโทรสารพร้อมสําเนาบัตรประจําตัวประชาชน
                    มายังศูนย์บริการลูกค้า หรือตัวแทนศูนย์บริการลูกค้า เป็นการล่วงหน้าไม่น้อยกว่า3วัน ทั้งนี้ผู้ใช้บริการสามารถขอระงับการใช้บริการได้คราวละไม่เกิน15วันโดยการขอระงับใช้ ชัวคราวในแต่ละครั้งต้องห่างกันอย่างน้อย 180 วัน     กรณีมีเหตุสุดวิสัยเกิดขึ้นกับผู้ใช้บริการ
                    จนเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการได้ ผู้ให้บริการตกลงให้ผู้ใช้บริการ ระงับการใช้บริการชั่วคราวได้ ตามระยะเวลาที่ผู้ใช้บริการร้องขอ แต่ทั้งนี้ไม่เกินกว่าระยะเวลาที่เหตุสุดวิสัยนั้นคงอยู่     ในการขอระงับการใช้บริการชั่วคราว
                    ผู้ใช้บริการไม่ต้องเสียค่าธรรมเนียมหรือค่าใช้จ่ายใดๆในการขอระงับบริการ เว้นแต่กรณีที่ผู้ใช้บริการ ระงับบริการเกินกว่าระยะเวลาขั้นสูงที่ผู้ใช้บริการกําหนดไว้ ผู้ให้บริการมีสิทธิ์ยกเลิกการให้บริการ ได้โดยแจ้งให้ผู้ใช้บริการทราบล่วงหน้าเป็นหนังสือ
                    ไม่น้อยกว่า 30 วัน
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;11.2 ผู้ให้บริการจะเปิดให้ผู้ใช้บริการได้ใช้บริการทันที ในวันถัดจากวันที่ครบกําหนดการขอระงับใช้บริการชั่วคราว โดยไม่เรียกเก็บค่าใช้จ่ายใดๆในการขอเปิดใช้บริการ
                </p>
                <a class="head-title">12. การแจ้งขอระงับการให้บริการชั่วคราวของผู้ให้บริการ</a>
                <p>เมื่อมีเหตุจําเป็น ผู้ให้บริการมีสิทธิ์ระงับการให้บริการโทรคมนาคมต่อผู้ใช้บริการเป็นการชั่วคราวได้ โดยแจ้งเป็นหนังสือพร้อมระบุเหตุให้ผู้ใช้บริการทราบล่วงหน้าไม่น้อยกว่า30วัน เว้นแต่กรณีดังต่อไปนี้ ผู้ให้บริการสามารถระงับการให้บริการได้ทันที
                    &nbsp;&nbsp;&nbsp;&nbsp;1) เกิดเหตุสุดวิสัยขึ้นแก่ผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2) ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;3) ผู้ใช้บริการใช้เอกสารปลอม หรือแสดงข้อความอันเป็นเท็จในการขอใช้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4) ผู้ให้บริการพิสูจน์ได้ว่า บริการโทรคมนาคมที่ให้แก่ผู้ใช้บริการถูกนํา ไปใช้โดยมิชอบ ด้วย กฎหมาย หรือฝ่าฝืนต่อสัญญาให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;5) ผู้ให้บริการมีเหตุอันเชื่อได้ว่า ผู้ใช้บริการมีพฤติกรรมฉ้อฉลในการใช้บริการ หรือนําบริการไปใช้ เพื่อแสวงหารายได้โดยมีเจตนาจะไม่ชําระค่าใช้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;6) ผู้ให้บริการ มีเหตุที่จําเป็นต้องบํารุงรักษา หรือ แก้ไขระบบโทรคมนาคมที่ใช้ในการให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;7) ผู้ใช้บริการไม่ปฏิบัติตามกฎระเบียบ หรือคําสั่งที่ออกโดย กสทช.
                </p>
                <a class="head-title">13. กรณีเครื่องโทรศัพท์เคลื่อนที่และ/หรือ SIM Card สูญหายหรือถูกโจรกรรม</a>
                <p>หากเครื่องโทรศัพท์เคลื่อนที่และ/หรือSIMCardสูญหาย หรือ ถูกโจรกรรมให้ผู้ใช้บริการ แจ้ง เหตุสูญหาย หรือถูกโจรกรรมให้ผู้ให้บริการทราบ ณ ศูนย์บริการลูกค้าของผู้ให้บริการเป็นหนังสือหรือทาง โทรศัพท์ หรือโดยวิธีการอื่นใด ตามผู้ให้บริการกําหนด
                    และสามารถตรวจสอบได้ว่าเป็นผู้ใช้บริการจริง ผู้ให้บริการ จะดําเนินการระงับการให้บริการโดยทันที ที่ได้รับแจ้งหากมีค่าใช้บริการเกิดขึ้นหลังจากการ แจ้งสูญหาย หรือถูกโจรกรรมผู้ใช้บริการไม่ต้องรับผิด เว้นแต่ค่าใช้บริการจะเกิดจากการ กระทําของผู้ใช้
                    บริการเอง
                </p>
                <a class="head-title">14. เหตุแห่งการปฏิเสธการให้บริการ</a>
                <p>ผู้ให้บริการมีสิทธิ์ปฏิเสธการให้บริการแก่ผู้ยื่นคําขอใช้บริการได้ดังนี้ &nbsp;&nbsp;&nbsp;&nbsp;1) เมื่อผู้ใช้บริการ ปฏิเสธไม่ให้ข้อมูลรายละเอียดการแสดงตัวในฐานะเป็นผู้ใช้บริการ และผู้ครอบครองบัตรประจําตัวผู้ใช้บริการ (SIM Card)
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2) เมื่อปรากฏว่าผู้ใช้บริการ ถูกระงับการให้บริการ หรือเคยถูกผู้ให้บริการ ระงับการให้บริการ เนื่องมาจากเหตุข้อหนึ่งข้อใดตามข้อ 15
                </p>
                <a class="head-title">15. เหตุแห่งการเลิกสัญญาของผู้ให้บริการ</a>
                <p>ในกรณีที่มีเหตุผลดังต่อไปนี้ีผู้ให้บริการอาจใช้สิทธิ์เลิกสัญญาให้บริการได้ทันที
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1) เมื่อผู้ใช้บริการไม่มีระยะเวลาสะสมคงเหลือตามข้อ 10.2(3)
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2) ผู้ใช้บริการถึงแก่ความตาย หรือสิ้นสุดสภาพนิติบุคคล
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;3) ผู้ให้บริการมีเหตุผลอันเชื่อได้ว่า ผู้ใช้บริการมีพฤติกรรมฉ้อฉลในการใช้บริการ หรือนําบริการไปใช้โดยผิดกฎหมาย หรือฝ่าฝืนข้อห้ามในสัญญาให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4) ผู้ให้บริการไม่สามารถให้บริการได้ โดยเหตุที่อยู่นอกเหนือการควบคุมของผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;5) ผู้ใช้บริการโอนสิทธิ์การใช้บริการตามสัญญานี้ โดยไม่ได้รับความยินยอมจากผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;6) เป็นการยกเลิกโดยบทบัญญัติแห่งกฎหมาย
                </p>

                <a class="head-title">16. เหตุแห่งการเลิกสัญญาของผู้ใช้บริการ</a>
                <p>ผู้ใช้บริการ บอกเลิกสัญญาให้บริการในเวลาใดก็ได้โดยบอกกล่าวเป็นหนังสือให้แก่ผู้ให้บริการ ทราบล่วงหน้าไม่น้อยกว่า 5 วันทําการ หรือด้วยวิธีอื่นใดตามที่ผู้ให้บริการกําหนด เว้นแต่ในกรณีดังต่อไปนี้ ผู้ใช้บริการอาจใช้สิทธิ์เลิกสัญญาให้บริการได้ทันที
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;1) ผู้ใช้บริการไม่สามารถรับบริการจากผู้ให้บริการได้ด้วยเหตุผลที่เกิดขึ้นอย่างต่อเนื่อง และอยู่ เหนือการควบคุมของผู้ให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2) ผู้ให้บริการได้ละเมิดข้อตกลงอันเป็นสาระสําคัญของสัญญาให้บริการ
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;3) ผู้ให้บริการตกเป็นบุคคลล้มละลาย
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;4) ผู้ให้บริการเปลี่ยนแปลงข้อตกลง หรือเงื่อนไขในสัญญาให้บริการซึ่งมีผลเป็นการลดสิทธิ์ หรือประโยชน์อันพึงได้รับของผู้ใช้บริการลง เว้นแต่เกิดจากเหตุตามที่กฎหมายกําหนด
                </p>
                <a class="head-title">17. การคืนเงินค่าบริการ</a>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;1) เมื่อสัญญาเลิกกันไม่ว่าด้วยเหตุใดๆหากปรากฏว่า ผู้ให้บริการมีเงินค้างชําระแก่ผู้ใช้บริการหลังจากที่ได้หักชําระหนี้ค่าบริการ และ/หรือค่าธรรมเนียม ที่ผู้ใช้บริการค้างชําระโดยจะไม่รวม ถึงค่าโบนัส ค่าโทรพิเศษ หรือบริการอื่น
                    ที่ผู้ให้บริการเสนอว่าจะเติมเคยเติม หรือแจ้งว่าจะเติมในอนาคต ผู้ ให้บริการ จะคืนเงินส่วนที่ผู้ให้บริการได้รับไว้ให้ แก่ผู้ใช้บริการที่แท้จริง หรือบุคคลทีได้รับมอบอํานาจ จากผู้ใช้บริการภายใน30วัน นับแต่วันเลิกสัญญาด้วยเงินสดเช็คหรือนําเข้าบัญชีเงินฝาก
                    ของผู้ใช้บริการหรือตามวิธีการที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้ ทั้งนี้สามารถโอนเงินค่าบริการที่ชําระล่วงหน้าไว้ไปยังเลขหมายระบบ PrePaid อื่นที่อยู่ภายในเครือข่ายเดียวกันตามที่ผู้ใช้บริการได้แจ้งความประสงค์ไว้เท่านั้น
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;2) กรณีผู้ให้บริการไม่สามารถคืนเงินค้างชําระให้แก่ผู้ใช้บริการได้ภายในระยะเวลาที่กําหนด ผู้ให้บริการต้องชําระค่าเสียประโยชน์ ในอัตราเท่ากับอัตราดอกเบี้ย ที่ผู้ให้บริการคิดจากผู้ใช้บริการกรณี ผู้ใช้บริการผิดนัด
                    ไม่ชําระค่าธรรมเนียม หรือค่าบริการแก่ผู้ให้บริการ แต่ทั้งนี้ไม่ตัดสิทธิ์ผู้ใช้บริการที่จะ เรียกค่าเสียหาย อย่างอื่นผู้ใช้บริการได้ศึกษารายละเอียดของสัญญาการให้บริการโทรคมนาคมประเภท บริการโทรศัพท์เคลื่อนที่แบบชําระค่าธรรมเนียม และค่าบริการล่วงหน้า
                    (Pre-Paid) ของผู้ให้บริการ และ เข้าใจข้อกําหนดและเงื่อนไขของสัญญานี้แล้ว จึงตกลงใช้บริการ โดยยินยอมปฏิบัติตามสัญญาการให้บริการโทรคมนาคม นี้ทุกประการ ทั้งนี้หากผู้ใช้บริการได้รับความเดือดร้อนจากการใช้บริการ ของผู้ให้บริการตามสัญญาสามารถร้องเรียนโดยตรงต่อผู้ให้บริการ
                    ดังต่อไปนี้ ยื่นเรื่องร้องเรียนด้วยตนเองที่ศูนย์บริการลูกค้า บริษัท ฟีลเทเลคอม คอร์ปอเรชั่น จำกัด
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;· ทางไปรษณีย์ถึง บริษัท ฟีลเทเลคอม คอร์ปอเรชั่น จำกัด) เลขที่ 21/109 แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;· ทาง call center ผ่านหมายเลขโทรศัพท์ 02-1429965
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;· หรือ ร้องเรียนผ่านหน่วยงานที่ทําหน้าที่คุ้มครองผู้บริโภค ตามที่คณะกรรมการกําหนด โดยระบุรายละเอียดที่อยู่เบอร์โทรศัพท์ของคณะกรรมการให้ชัดเจน เพื่อความสะดวกในการใช้สิทธิ์ร้องเรียนของผู้ใช้บริการ
                </p> -->
            </div>
            <hr style="margin-left: 20%; margin-right: 20%;">
            <a class="cursor-off">Feel the limitless mobile freedom</a><br>
            <a class="cursor-off" style="font-weight: 200;">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</a>
        </div>
    </div>
</main>