import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service'

@Component({
  selector: 'app-detailmove',
  templateUrl: './detailmove.component.html',
  styleUrls: ['./detailmove.component.scss']
})
export class DetailmoveComponent implements OnInit {
  titleName: Array<Object> = [{ 'title': 'นาย' }, { 'title': 'นางสาว' }, { 'title': 'นาง' }]
  selectTitle: Object
  page: string = 'number'

  constructor(private register: RegisterService) { }

  ngOnInit(): void {
  }
  onSent() {
    this.register.pageMove.next('payment')
  }
  onAddress() {
    this.page = "address"
  }
  onComfirm() {
    this.page = 'identify'
  }

}
