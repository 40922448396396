<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-8 p-0">
            <div class="col-12 p-0">
                <div class="text-center upload-img">
                    <img [className]="'d-example'+exampleimageClass" (click)="file_personal.click()"
                        src="assets/img/banner/Photo.png">
                    <div [className]="'preview'+previewimageClass">
                        <img id="peviewpersonal" (click)="file_personal.click()" [src]="peviewpersonal">
                    </div>
                    <p class="mt-3">ถ่ายภาพหน้าตรง</p>
                    <input class="d-none" type="file" #file_personal (click)="file_personal.value = null"
                        (change)="importFile($event)" accept="image/*" capture>
                </div>
                <div *ngIf="file_personal.value" class="text-center d-block">
                    <img (click)="rotateImg()" class="img-rotating" src="assets/img/icon/rotate-icon.png" width="35">
                </div>
            </div>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="nextPage()" [disabled]="disable"
                class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ถัดไป</button>
        </div>
    </div>
</main>