<main>
    <!-- <div style="width: 100%; margin-bottom: 20px;">
        <mdb-carousel [isControls]="true" [animation]="'slide'">
            <mdb-slide *ngFor="let slide of slides; let index=index">
                <img class="d-block w-100" style="height:40vh; object-fit: cover;" [src]="slide.img">
            </mdb-slide>
        </mdb-carousel>
    </div> -->
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/banner-main-topup.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/banner-main-topup.png">
    </div>
    <div class="row w-100 justify-content-center m-0 mt-3">
        <div class="col-md-8 col-12 white d-flex align-content-between p-3 p-lg-5 rounded-top-3 flex-wrap">
            <div class="row w-100 mt-3 mt-lg-0">
                <p class="fs-50 col-12 text-light-green-main text-center pb-3">เติมเงินกับ Feels</p><br>
                <a class="col-12">เลือกจำนวนเงินที่คุณต้องการเติม</a>
            </div>
            <div class="row w-100 m-0">
                <div *ngFor="let item of money; let i = index" class="col-lg-4 col-6 pr-2 pl-2">
                    <button type="button" (click)="onKey($event,1)" value="{{item}}"
                        class="btn btn-outline-green-main rounded-lg ml-0 mr-0 mt-2 font-weight-normal fs-20 w-100">{{item}}฿</button>
                    <!-- <input type="submit"
                        class="btn btn-outline-green-main rounded-lg ml-0 mr-0 mt-2 font-weight-normal fs-20 waves-effect w-100"
                        value="{{item}}" (click)="onKey($event,1)"> -->
                </div>
            </div>
            <div class="row w-100 m-0 mt-3 justify-content-center">
                <div class="col-10 col-lg-6">
                    <div class="md-form form-sm">
                        <input mdbInput type="text" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" value="{{totalPrice}}"
                            (change)="onKey($event,2)"
                            oninput="if(this.value <= 5000){ this.value = Math.abs(this.value) } else { this.value = 5000 }"
                            id="price" class="form-control form-control-sm">
                        <label for="price" class="fs-20 text-light-green-main" name="price" type="tel">ต้องการเติมเงิน
                            (บาท)</label>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-2 w-100 justify-content-center">
                <div class="text-center w-50">
                    <p *ngIf="priceError">กรุณาใส่จำนวนเงินให้ถูกต้อง</p>
                    <p *ngIf="pricelessError">กรุณาใส่จำนวนเงิน 10 บาทขึ้นไป</p>
                    <button type="button" (click)="onSent()"
                        class="btn bg-soft-green w-100 text-white rounded-pill">จ่ายเงิน</button>
                </div>
            </div>
            <div class="row w-100 justify-content-center mt-5 mr-0 ml-0 p-2 text-body">
                <div class="col-12 col-lg-4">
                    <h1 class="text-light-green-main">อัตราค่าบริการ</h1>
                </div>
                <div class="col-12 col-lg-8 row">
                    <div class="col-4">
                        <h3>โทร<br>
                            <small>(บาท/นาที)</small>
                        </h3>
                    </div>
                    <div class="col-4">
                        <h3>อินเตอร์เน็ต<br><small>
                                (บาท/MB)</small></h3>
                    </div>
                    <div class="col-4">
                        <h3>SMS<br><small>
                                (บาท/ข้อความ)</small></h3>
                    </div>
                </div>
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-12 col-lg-4">
                    <h2>ราคา (บาท)</h2>
                </div>
                <div class="col-12 col-lg-8 row">
                    <div class="col-4">
                        <h3>0.75<br><small>
                                รวมภาษีมูลค่าเพิ่ม0.8</small></h3>
                    </div>
                    <div class="col-4">
                        <h3>0.25<br><small>
                                รวมภาษีมูลค่าเพิ่ม0.27</small></h3>
                    </div>
                    <div class="col-4">
                        <h3>2<br><small>
                                รวมภาษีมูลค่าเพิ่ม2.14</small></h3>
                    </div>
                </div>
                <div class="col-12 text-center">
                  <h2><small class="text-danger">**จำนวนเงินในซิมสำหรับใช้งานเท่านั้น ไม่สามารถนำไปซื้อแพ็กเกจได้</small></h2>
                </div>                
            </div>
        </div>
    </div>
</main>