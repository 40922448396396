import { Component, OnInit, ViewChild } from '@angular/core';
import { QRCodeComponent } from 'angularx-qrcode';
import { MDBModalRef } from 'angular-bootstrap-md';
import { TopupService } from '../../../services/topup.service'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-modalpaytopup',
    templateUrl: './modalpaytopup.component.html',
    styleUrls: ['./modalpaytopup.component.scss']
})
export class ModalpaytopupComponent implements OnInit {
    @ViewChild("save", { static: true }) pRef: QRCodeComponent;
    public myRadio: string;
    page: string = 'summary';
    qrSize: number = 200;
    topupcomfirmForm: FormGroup;
    spinner: string = ' d-flex';
    qrClass: string = 'd-none';
    errorQR: boolean = false;
    codeRes: number;
    errorQrText: string;
    btnText: string = 'ตกลง';
    price: number;
    errorText: string;
    lowBalance: string;
    successPage: boolean = false;
    errorTextCheck: boolean = false;
    link: string;
    public qrcode: string = "assets\img\icon\qrcode.svg";

    constructor(private fb: FormBuilder, public modalRef: MDBModalRef, private topupService: TopupService) {
        this.price = this.topupService.selectPrice;
        this.buildForm();
        this.topupService.resTopup.subscribe((value) => {
            // console.log(value);
            this.codeRes = value['responsecode']
            if (value['responsecode'] === 0) {
                this.qrClass = '';
                this.spinner = ' d-none';
                if (this.myRadio === 'simcard') {
                    this.successPage = true;
                } else {
                    this.qrcode = value['qrcode'];
                }
            } else {
                this.spinner = ' d-none';
                this.errorQR = true;
                this.errorQrText = value['messages'];
                if (value['responsecode'] === 102) {
                    this.lowBalance = value['maximum'].split(".")[0];
                }
            }
        })
    }

    ngOnInit(): void {
    }

    buildForm(): void {
        this.topupcomfirmForm = this.fb.group({
            'phonenumber': new FormControl(null, [
                Validators.required,
                Validators.minLength(10)
            ]),
        });
    }

    onSent() {
        if (this.page === 'summary') {
            if (this.topupcomfirmForm.get('phonenumber').valid) {
                this.topupService.phonenumber = this.topupcomfirmForm.value.phonenumber;
                this.errorTextCheck = false;
                this.page = 'payment';
            } else {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง';
            }
        } else if (this.page === 'payment') {
            this.btnText = 'ปิด';
            if (!!this.myRadio) {
                this.page = 'success';
                this.errorTextCheck = false;
                this.topupService.selectPayment = this.myRadio;
                this.topupService.process();
            } else {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาเลือกช่องทางการชำระเงิน';
            }
        } else {
            this.modalRef.hide();
        }

    }
    radio(event) {
        const inputValue = event.target.value;
        this.myRadio = inputValue;
        // console.log(inputValue);
    }
    onClick() {
        this.link = this.pRef.qrcElement.nativeElement.firstChild.src;
    }

}
