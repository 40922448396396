<div class="modal-header bg-soft-green text-white">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100 cus-text m-0" id="myModalLabel">ทำรายการเติมเงิน</h4>
</div>
<div class="modal-body cus-text">
    <div class="row" *ngIf="page==='payment'">
        <div class="col-12 animated fadeIn">
            <h2 class="text-center text-light-green-main">เลือกช่องทางการชำระเงิน</h2>
            <div class="row">
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='thaiqr'" src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='thaiqr'" src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="thaiqr" name="payment-method"
                            value="thaiqr" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='linepay'" src="../../../assets/img/icon/Payment/line.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='linepay'" src="../../../assets/img/icon/Payment/line.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="linepay" name="payment-method"
                            value="linepay" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-respo"><br></div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="wechatpay" name="payment-method"
                            value="wechatpay" (click)="radio($event)">
                    </label>
                </div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio==='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                            class="payment-img-active">
                        <img *ngIf="myRadio!=='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                            class="payment-img">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="alipay" name="payment-method"
                            value="alipay" (click)="radio($event)" checked>
                    </label>
                </div>
                <div class="payment-respo"><br></div>
                <div class="payment-icon col-4">
                    <label>
                        <img *ngIf="myRadio!=='creditcard'" src="../../../assets/img/icon/Payment/credit-card.png"
                            style="display: flex;" class="payment-img">
                        <img *ngIf="myRadio==='creditcard'" src="../../../assets/img/icon/Payment/credit-card.png"
                            style="display: flex;" class="payment-img-active">
                        <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="creditcard" name="payment-method"
                            value="creditcard" (click)="radio($event)">
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="page==='summary'">
        <form [formGroup]="topupcomfirmForm">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-left text-light-green-main">เติมเงิน</h2>
                </div>
                <div class="col-12">
                    <p>เติมเงินเป็นจำนวน</p>
                    <h2>{{price}} บาท</h2>
                </div>
                <div class="col-12">
                    <div class="md-form form-group">
                        <input mdbInput type="text" maxlength="10" class="form-control text-dark" id="inputPhoneMD"
                            placeholder="เบอร์โทรศัพท์" formControlName="phonenumber">
                        <label class="text-light-green-main fs-20" for="inputPhoneMD">เบอร์ที่ต้องการเติมเงิน</label>
                    </div>
                </div>
                <!-- <div class="col-12 text-left p-3">
                    <p><small>- ราคายังไม่รวมภาษีมูลค่าเพิ่ม
                            - ความเร็วอินเทอร์เน็ตขึ้นอยู่กับพื้นที่การใช้งาน
                            -
                            บริษัทสงวนสิทธิ์ในการเปลี่ยนแปลงรายละเอียดเงื่อนไขของบริการและรายการส่งเสริมการขายโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                            - บริษัทสงวนสิทธิ์ในการจัดการบริหารเครือข่ายตามความเหมาะสมเพื่อรักษามาตรฐานคุณภาพของบริการ
                            และเพื่อช่วยให้ผู้ใช้บริการโดยรวมสามารถใช้งานได้อย่างมีประสิทธิ์ภาพ เช่น จำกัด หรือ
                            ลดความเร็วหรือดำเนินการใดๆ ในการรับส่งข้อมูลต่างๆ, การใช้งาน Bit Torrent, การแชร์เน็ตผ่าน
                            Hotspot, การดาวน์โหลดและ/หรือ อัพโหลดไฟล์ขนาดใหญ่, หรือ
                            การใช้งานใดที่มีการรับส่งข้อมูลในปริมาณมากอย่างต่อเนื่อง
                            หรือที่มีผลต่อการใช้บริการหรือเกิดความไม่เป็นธรรม
                            ก่อหรืออาจก่อให้เกิดความเสียหายต่อผู้ใช้บริการอื่น และ/หรือต่อเครือข่าย
                            หรือการให้บริการโดยรวมของบริษัท
                            - บริการตามรายละเอียดนี้เพื่อการสื่อสารเฉพาะบุคคลเท่านั้น มิใช่เพื่อถือเอาประโยชน์
                            หรือนำไปใช้งานในเชิงพาณิชย์ หรือให้บริการต่อได้
                            หากพบบริษัทขอสงวนสิทธิ์ในการยกเลิกการให้บริการได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</small></p>
                </div> -->
            </div>
        </form>
    </div>
    <div *ngIf="page==='success'">
        <div class="row animated fadeIn">
            <div *ngIf="!errorQR" class="col-12">
                <div class="row justify-content-center">
                    <div class="text-center rounded bg-soft-green p-1">
                        <div [className]="qrClass">
                            <qrcode [qrdata]="qrcode" [width]="qrSize" [errorCorrectionLevel]="'M'">
                            </qrcode>
                        </div>
                        <div [className]="'justify-content-center wh-200 align-items-center'+spinner">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <a [href]="link" *ngIf="spinner !== ' d-flex'" download class="text-white"
                            (click)="onClick()">บันทึกรูป</a>
                    </div>
                </div>
            </div>
            <div *ngIf="errorQR" class="col-12">
                <div class="d-flex justify-content-center animated fadeIn">
                    <img class="w-50" src="../../../assets/img/item/2842746.svg">
                </div>
                <h2>Error {{codeRes}}</h2>
                <p *ngIf="!!!lowBalance">{{errorQrText}}</p>
                <p *ngIf="!!lowBalance">คุณสามารถเติมเงินได้อีกไม่เกิน {{lowBalance}} บาท กรุณาทำรายการใหม่อีกครั้ง</p>
            </div>
            <div *ngIf="spinner === ' d-flex'" class="col-12 mt-3 mb-3">
                <p>กรุณารอสักครู่ขณะนี้ระบบกำลังทำรายการให้ท่าน</p>
            </div>
            <div *ngIf="!errorQR && !successPage && spinner !== ' d-flex'" class="col-12 mt-3 mb-3">
                <p><small>กรุณาแสกน QR CODE เพื่อชำระเงิน เมื่อชำระเงินเสร็จเรียบร้อยแล้วกรุณารอ SMS
                        ยืนยันการทำรายการ</small></p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row w-100 justify-content-center">
        <div class="col-12 text-center">
            <p class="cus-text" *ngIf="errorTextCheck">{{errorText}}</p>
        </div>
        <button type="button" mdbBtn class="waves-light cus-text bg-soft-green text-white rounded-pill"
            aria-label="Close" (click)="onSent()" mdbWavesEffect>{{btnText}}</button>
    </div>
</div>
<qrcode #save id="qr" [qrdata]="qrcode" [width]="1000" elementType="img" [errorCorrectionLevel]="'M'" class="d-none">
</qrcode>