import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { PackageService } from 'src/app/services/package.service';
import { ModaltouristpayComponent } from './modaltouristpay/modaltouristpay.component';

@Component({
  selector: 'app-tourist',
  templateUrl: './tourist.component.html',
  styleUrls: ['./tourist.component.scss']
})
export class TouristComponent implements OnInit {

  public selectedindex: number = 0; //Initialized to 0
  modalRef: MDBModalRef;
  packagesres: Array<object>
  public slides: Array<Object> = [
      { "img": "../../../assets/img/banner/store-2-04.png" },
      { "img": "../../../assets/img/banner/store-2-04.png" }
  ];
  btnroute: boolean = false;

  constructor(private route: ActivatedRoute, private pack: PackageService, private modalService: MDBModalService) {
      this.pack.getPackages('tourist').subscribe((res: any) => {
          this.btnroute = true;
          this.packagesres = Object.values(JSON.parse(res + '')['packagelist'])
          if (this.route.snapshot.paramMap.get("packid") !== null) {
              this.onSelect(this.packagesres.filter(obj => obj['packagecode'] + '' === this.route.snapshot.paramMap.get("packid"))[0])
          }
      })
  }

  ngOnInit(): void {
  }

  selectImage(index: number) {
      this.selectedindex = index;
  }

  onSelect(selectPack: Object) {
      this.pack.selectPackObj = selectPack
      this.pack.selectPackPrice = selectPack['total_net']
      this.pack.selectPackCode = selectPack['packagecode']
      this.modalRef = this.modalService.show(ModaltouristpayComponent,
          { class: 'modal-dialog-scrollable modal-dialog-centered' }
      )
  }


}
