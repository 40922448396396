<main>
    <!-- <div class="w-100 d-block d-lg-block">
        <img class="d-block w-100 obj-cover" src="assets/img/banner/bannerwebPNG-06.png">
    </div> -->
    <div class="w-100 d-block d-lg-none">
        <img class="d-block w-100 m-slide" src="assets/img/bg/homepagepng.png" alt="home-banner">
    </div>
    <div class="w-100 d-none d-lg-block pan-y">
        <mdb-carousel [isControls]="false" [interval]="'0'">
            <mdb-slide>
                <img class="d-block w-100 obj-cover" src="assets/img/banner/bannerwebPNG-06.png" alt="First slide">
                <div class="carousel-caption">
                    <button mdbBtn type="button" (click)="onMenu()" class="bg-soft-green text-white rounded-pill"
                        mdbWavesEffect>บริการของฟีล</button>
                </div>
            </mdb-slide>
            <!-- <mdb-slide>
                <img class="d-block w-100 obj-cover" src="assets/img/banner/bannerwebPNG-06.png" alt="Second slide">
                <div class="carousel-caption">
                    <button mdbBtn type="button" (click)="onMenu()" class="bg-soft-green text-white rounded-pill"
                        mdbWavesEffect>บริการของฟีล</button>
                </div>
            </mdb-slide> -->
        </mdb-carousel>
    </div>
    <div id="apk-app" class="row w-100 justify-content-center m-0 pt-0">
          <div id="newsspec-19854-app" class="feels-app col-12 m-0">
            <div class="feels-app-text">
              <div class="feels-app-text__download">ดาวน์โหลดแอปฯ Feels</div>
              <div class="feels-app-text__tagline mt-2">ให้ประสบการณ์การใช้งานของคุณเป็นเรื่องง่าย เติมเงิน สมัครแพ็กเกจเสริม ซื้อซิม ลงทะเบียนซิม จบครบในแอปเดียว</div><br>
            </div>
            <div class="feels-app__section">
              <div class="feels-app-subsection">
                <img class="feels-app__bbc-logo" src="assets/img/icon/feels-app/favicon.svg" width="106" height="106" alt="feels-app-icon">
              </div>
              <div class="feels-app-subsection">
                <a class="feels-app-subsection--link feels-app-subsection--apk" href="https://cdn.feels.co.th/feel.apk" target="_parent" aria-label="Download apk">
                  <img class="feels-app__apk" src="assets/img/icon/feels-app/apk-download.svg" width="161" height="auto" alt="apk-button">
                </a>
                <!-- <a class="feels-app-subsection--link feels-app-subsection--playstore" href="https://play.google.com/store/apps/details?id=bbc.mobile.news.ww&amp;hl=en" target="_parent">
                  <img class="feels-app__play-store" src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg" width="161" height="auto" border="0">
                </a>
                <a class="feels-app-subsection--link feels-app-subsection--appstore" href="https://itunes.apple.com/us/app/bbc-news/id364147881?mt=8" target="_parent">
                  <img class="feels-app__app-store" src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg" width="161" height="auto" border="0">
                </a> -->
              </div>
            </div>
            <div class="feels-app__section">
            </div>
          </div>
        </div>    
    <div class="row w-100 m-0">
        <div class="row w-100 m-0 elegant-color-dark">
            <div class="col-12 vh-20 text-center text-white p-3">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <div>
                        <h1 class="m-0 text-light-green-main">แพ็กเกจสุดคุ้ม</h1>
                        <h2 class="m-0">เลือกซื้อแพ็กเกจสุดคุ้มตามที่ใจคุณต้องการ</h2>
                    </div>
                </div>
            </div>
            <div class="row m-0 w-100">
                <div class="col-lg-3 col-md-6 col-12 p-0 rounded-lg" *ngFor="let item of packagesres; let i = index">
                    <div [className]="'bg bg-vh-100 bg-pack-'+i">
                        <div class="w-100 h-100 black-opacity-50 text-white top-100 p-2 pt-5 pb-5 p-lg-5">
                            <div class="row m-0 h-100 align-content-between flex-wrap">
                                <div class="col-12">
                                    <div class="row m-0">
                                        <h1 class="col-4 col-md-12 fs-30">{{item.duration}} วัน</h1>
                                        <h1 class="col-4 col-md-12 fs-30" *ngIf="item.dataspeedtype !== 'FullSpeed'">
                                            {{item.dataspeedamount.split('.')[0]}}
                                            {{item.dataspeedunit}}
                                        </h1>
                                        <h1 class="col-4 col-md-12 fs-30" *ngIf="item.dataspeedtype === 'FullSpeed'">
                                            Full Speed
                                            <div class="d-inline-block" *ngIf="item.datatype !== 'Unlimited'">
                                                {{item.datavolumn.split('.')[0]}}
                                                {{item.dataunit}}
                                            </div>
                                        </h1>
                                        <h1 class="col-4 col-md-12 fs-30">{{item.price.split('.')[0]}} บาท</h1>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <p>
                                        เล่นเน็ตความเร็ว
                                        <span class="d-inline-block" *ngIf="item.dataspeedtype !== 'FullSpeed'">
                                            {{item.dataspeedamount.split('.')[0]}} {{item.dataspeedunit}}&nbsp;
                                        </span>
                                        <span class="d-inline-block" *ngIf="item.dataspeedtype === 'FullSpeed'">
                                            Full Speed&nbsp;
                                        </span>
                                        <span class="d-inline-block" *ngIf="item.datatype === 'Unlimited'">
                                            ไม่จำกัด
                                        </span>
                                        <span class="d-inline-block" *ngIf="item.datatype !== 'Unlimited'">
                                            {{item.datavolumn.split('.')[0]}} {{item.dataunit}}
                                        </span>
                                        นาน
                                        {{item.duration}} วัน
                                    </p>
                                    <p>
                                        สนใจสมัครกด
                                        <span *ngIf="item.ussdcode">{{item.ussdcode}} แล้วโทรออก</span>
                                        <span *ngIf="!item.ussdcode">เลย</span>
                                    </p>
                                    <button (click)="onPackClick(item.packagecode)" mdbBtn type="button"
                                        class="btn-outline-white rounded-pill" mdbWavesEffect>สมัครเลย</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="store" class="row w-100 justify-content-center m-0 bg-circle-store">
            <div class="col-12 row align-items-center justify-content-center p-0 vh-store-100">
                <div class="d-flex justify-content-center wh-50vhw p-md-5 p-2 align-items-center">
                    <div
                        [className]="'rounded-circle p-5 p-lg-0 circle-store justify-content-center transition w-100 h-100 row m-0 align-content-center flex-wrap'+storeanimation">
                        <h1 class="m-0 fs-100px col-12 text-center elegant-color-text line-h-100">Feels Store</h1>
                        <div class="col-12 elegant-color-text text-center pt-4 pt-lg-5">
                            <p>พบกับสินค้ามากมายหลากหลายเพียงนิ้วคลิกที่ Feels Store</p>
                        </div>
                        <div class="col12 mt-3">
                            <!-- <button mdbBtn type="button" [routerLink]="['/store']"
                                class="white elegant-color-text rounded-pill" mdbWavesEffect>เลือกซื้อเลย</button> -->
                            <a href="https://shop.feels.co.th"
                                class="btn white elegant-color-text rounded-pill">เลือกซื้อเลย</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="package" class="row w-100 justify-content-center align-items-center m-0 bg-soft-green">
            <div class="d-none d-lg-block col-lg-6 align-items-center vh-store-100">
                <div class="h-100 w-100">
                    <img [className]="'transition w-100 h-100 of-fill'+packageAnimation"
                        src="assets/img/item/iphone-1.svg" alt="phone1">
                    <img [className]="'transition position-relative phone-2 w-100 h-100 of-fill'+package2Animation"
                        src="assets/img/item/iphone-2.svg" alt="phone2">
                </div>
            </div>
            <div class="col-12 col-lg-6 row align-items-center justify-content-center p-0 vh-store-100">
                <div class="d-flex justify-content-center w-100 h-100 p-3 p-lg-5 align-items-center">
                    <div class="justify-content-center w-100 h-100 row m-0 align-content-center flex-wrap">
                        <h1 class="m-0 fs-50px col-12 elegant-color-text line-h-120">ฟีลมอบสิ่งนี้ “ให้คุณ”</h1>
                        <div class="col-12 col-6 text-white pt-5">
                            <div class="row">
                                <div class="col-8">
                                    <p>
                                        เครือข่ายเทคโนโลยีที่คุณปรับแต่งได้ไม่จำกัด หรือสร้างเงื่อนไขแพ็กเกจใด ๆ
                                        ที่ผูกมัดเพื่อให้คุณใช้งานเครือข่ายของฟีล
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <h2 class="elegant-color-text">
                                        อิสระอยู่ในมือคุณแล้ว !
                                    </h2>
                                    <p>
                                        ไม่เพียงแต่ซื้อดาต้าเพื่อไปใช้งาน คุณยังสามารถปรับแต่งให้กับเพื่อน ๆ
                                        ในเครือข่ายของฟีลได้ ตามที่ใจคุณต้องการ
                                    </p>
                                </div>
                                <div class="col-12">
                                    <h2 class="elegant-color-text">
                                        ผลิตภัณฑ์ที่ยอดเยี่ยม !
                                    </h2>
                                    <p>
                                        นี่ไม่ใช่เทคโนโลยีที่ใหม่แต่ฟีลต้องการให้เราเป็นเสมือนครอบครัวที่ร่วมแบ่งปันความสุขให้กันและกัน
                                    </p>
                                </div>
                                <div class="col12 mt-3">
                                    <button mdbBtn type="button" [routerLink]="['/ondemandpackage']"
                                        class="rounded-pill" color="elegant" mdbWavesEffect>ข้อเสนอและแพ็กเกจ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="signal" [className]="'row w-100 transition justify-content-center align-items-center m-0'+bgSignal">
            <div class="col-12 col-lg-6 row align-items-center justify-content-center p-0 vh-store-100">
                <div class="d-flex justify-content-center w-100 h-100 p-5 align-items-center">
                    <div class="justify-content-center w-100 h-100 row m-0 align-content-center flex-wrap">
                        <h1 class="m-0 fs-50px col-12 text-light-green-main line-h-120">ไร้ขีดจำกัด เร็วแรงทุกพื้นที่
                            กับเสาสัญญาณกว่า
                            75,000 ต้น ครอบคลุมทั่วประเทศ</h1>
                        <div class="col-12 col-6 text-white pt-lg-5 pt-3">
                            <div class="row">
                                <div class="col-8">
                                    <p>
                                        เรามั่นใจในคุณภาพของบริการผ่านโครงข่าย บริษัท โทรคมนาคมแห่งชาติ จำกัด (มหาชน)
                                        ที่มีพื้นที่ให้บริการกว้าง เร็ว แรงทั่วประเทศ
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="row mt-lg-3 mt-2">
                                <div class="col-12">
                                    <h2 class="text-light-green-main">
                                        หากคุณสนใจรายละเอียด
                                    </h2>
                                    <p>
                                        คุณสามารถอ่านต่อได้ที่ <a class="text-light-green-main"
                                            href="http://telecom.tot.co.th/">เว็บไซต์ บริษัท ทีโอที จำกัด
                                            (มหาชน)</a>
                                        เพื่อศึกษาเพิ่มเติมได้
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block col-lg-6 align-items-center vh-store-100">
                <div class="h-100 w-100 p-3">
                    <img [className]="'transition w-100 h-100 of-contain'+signalanimation"
                        src="assets/img/bg/Artboard15copy3.png" alt="signal">
                </div>
            </div>
        </div>
        <div id="ondemand" class="row w-100 justify-content-center m-0 pt-0 bg-signal">
            <div class="col-12 row align-items-center justify-content-center p-0 on-demand-height">
                <div class="d-flex justify-content-center on-demand-height w-100 align-items-center">
                    <div
                        [className]="'bg-soft-green justify-content-center transition w-100 h-100 row m-0 align-content-center flex-wrap'+ondemandAnimation">
                        <h1 class="m-0 fs-50px col-12 text-center elegant-color-text line-h-120">
                            ใช้อินเทอร์เน็ตได้ตามใจสั่ง !</h1>
                        <div class="col-12 text-white text-center pt-lg-5 pt-3">
                            <p>เพียงแค่ เลือก ซื้อ และใช้งาน เต็มสปีดที่คุณมีได้เลย</p>
                        </div>
                        <div class="col12 mt-3">
                            <button [routerLink]="['/ondemandpackage']" mdbBtn type="button"
                                class="white elegant-color-text rounded-pill"
                                mdbWavesEffect>เลือกแพ็กเกจฟีลตอนนี้เลย</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</main>

<!-- <div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row d-flex justify-content-center align-items-center">
                    <h2>
                        <span class="badge">v52gs1</span>
                    </h2>
                    <p class="pt-3 mx-4">We have a gift for you! Use this code to get a
                        <strong>10% discount</strong>.</p>
                    <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
                        <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
                    </a>
                    <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
                        data-dismiss="modal" (click)="frame.hide()">Ok, thanks</a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div mdbModal #frame="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
            </div> -->
            <div class="modal-body cus-modal-body">
                <div class="circle-close" (click)="frame.hide()">&times;</div>
                <img class="modal-img" src="assets/img/banner/modal-2024-12-07.jpg">
            </div>
            <!-- <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                    (click)="frame.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect>Save!</button>
            </div> -->
        </div>
    </div>
</div>

<!-- <div mdbModal #cookie="mdbModal" class="modal fade" id="cookieModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content bg-dark">
            <div class="modal-body">
                <div class="notice d-flex justify-content-between align-items-center">
                    <div class="cookie-text">
                        เว็บไซต์นี้ใช้คุกกี้เพื่อเพิ่มประสบการณ์และความพึงพอใจในการใช้ งาน ให้สามารถเข้าถึงง่าย
                        สะดวกในการใช้งาน และมี
                        ประสิทธิภาพยิ่งขึ้น การกด "ยอมรับ" ถือว่าคุณได้อนุญาตให้เรา ใช้คุกกี้ ตามนโยบายคุกกี้ของบริษัท
                    </div>
                    <div class="buttons d-flex flex-column flex-lg-row">
                        <a href="#a" class="cc-btn cc-dismiss" data-dismiss="modal">ยอมรับ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->