<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <ng-scrollbar style="background-color: #DCDCDC;">
                <div class="col-md-12 p-4 agreementpage">
                    <div class="force-overflow">
                        <p class="text-left mb-3">
                            ผู้ใช้บริการสามารถลงทะเบียนซิมการ์ดแบบเติมเงินเครือข่าย Feels ได้ทุกประเภท
                            โดยผู้ใช้บริการสามารถเลือกซื้อบริการเสริมได้จากช่องทางต่างๆ ที่บริษัทกำหนด อาทิเช่น
                            บนเว็บไซต์
                            ตัวแทนจำหน่ายฟีล สื่อสังคม เป็นต้น โดยผู้ใช้บริการที่ประสงค์ใช้บริการ Feels
                            สามารถลงทะเบียนผ่านช่องทางเว็บไซต์ ตัวแทนจำหน่าย หรือจากจุดให้บริการของฟีลได้
                        </p>
                        <p class="text-left mb-3">
                            ผู้ใช้บริการสามารถเข้าสู่หน้าลงทะเบียนซิมการ์ดได้ผ่านช่องทางเว็บไซต์
                            หรือผู้ใช้บริการสามารถแสกน
                            QR Code บนซองซิมการ์ดเพื่อเข้าไปยังช่องทางลงทะเบียนซิมฟีล
                            โดยบริษัทจะนับว่าผู้ใช้บริการได้อ่านและยอมรับเงื่อนไขการใช้บริการของบริษัทแล้ว
                            ผู้ใช้บริการสามารถอ่านเงื่อนไขการใช้งานได้ <a [routerLink]="['/proviso']" target="_blank">
                                <span class="link-txt text-light-green-main">
                                    ที่นี่
                                </span>
                            </a>
                        </p>
                        <p class="text-left mb-3">
                            ในการใช้งานระบบเติมเงิน หรือระบบชำระเงินของบริษัท
                            ผู้ใช้บริการยินยอมให้บริษัทหักบัญชีจากบัตรเครดิต บัตรเดบิต บัญชี Alipay บัญชี Rabbit Line
                            Pay
                            บัญชี WeChat Pay หรือวิธีการอื่นใดที่บริษัทจะมีในอนาคตที่ผู้ใช้บริการได้เลือกไว้
                        </p>
                        <p class="text-left mb-3">
                            ผู้ใช้บริการจะต้องกดรับรหัสย้ายค่ายจาก <span class="text-light-green-main">
                                *151*ตามด้วยเลขบัตรประชาชน 13 หลัก#
                            </span>
                        </p>
                        <p class="text-left mb-3">
                            <span class="text-danger">สำหรับลูกค้าชาวต่างชาติ กรุณาติดต่อเจ้าหน้าที่</span>
                        </p>
                        <!-- <p class="text-left mb-3">
                            บริษัทมีหน้าที่จัดเก็บเลขประจำตัวประชาชน ชื่อ สกุล ที่อยู่ รูปถ่าย ของผู้ใช้บริการ
                            โดยผู้ใช้บริการต้องใช้บัตรประจำตัวประชาชนฉบับจริง หรือบัตรอื่นใดฉบับจริงตามที่ สำนักงาน
                            กสทช.
                            กำหนด เพื่อลงทะเบียนแสดงตนในฐานะผู้ใช้บริการ ตามขั้นตอน วิธีการที่สำนักงาน กสทช. กำหนด
                            โดยผู้ใช้บริการมีสิทธิปฏิเสธการจัดเก็บข้อมูลดังกล่าวและยกเลิกการลงทะเบียนซิมการ์ดได้
                            ผู้ใช้บริการสามารถอ่านรายละเอียดนโยบายความเป็นส่วนตัวเพิ่มเติมได้ <a
                                [routerLink]="['/policy']" target="_blank">
                                <span class="link-txt text-light-green-main">
                                    ที่นี่
                                </span>
                            </a>
                        </p>
                        <p class="text-left mb-3">
                            ผู้ใช้บริการยินยอมให้บริษัทจัดเก็บ รวบรวม ใช้ และประมวลผลข้อมูลของผู้ใช้บริการ
                            เพื่อใช้ประโยชน์เกี่ยวกับหรือเกี่ยวเนื่องกับการให้บริการอันเป็นประโยชน์แก่ผู้ใช้บริการ
                        </p>
                        <p class="text-left mb-3">
                            บริษัทขอสงวนสิทธิในการระงับการใช้งานหรือปฏิเสธการลงทะเบียน ให้บริการ
                            หรือการสั่งซื้อซิมการ์ดในกรณีที่ผู้ใช้บริการให้ข้อมูลไม่เพียงพอ
                            มิได้ดำเนินตามขั้นตอนตามที่บริษัทและวิธีการที่สำนักงาน กสทช. กำหนด
                            หรือบริษัทตรวจสอบพบว่าข้อมูลการลงทะเบียนของผู้ใช้บริการทั้งหมดหรือบางส่วนเป็นเท็จ
                            และบริษัทจะดำเนินการในทางกฎหมายต่อไป
                        </p>
                        <ol class="text-left mb-3">
                            <li>ใช้บริการ หรืออนุญาตให้บุคคลอื่นใช้บริการในลักษณะหยาบคาย ลามกอนาจาร ไม่ชอบด้วยกฎหมาย
                                หรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน</li>
                            <li>ใช้เพื่อวัตถุประสงค์ในการข่มขู่ รบกวน คุกคาม ล่วงเกิน
                                หรือเป็นการกระทำละเมิดต่อสิทธิในความเป็นส่วนตัวหรือทำให้บุคคลอื่นเสื่อมเสียชื่อเสียง
                            </li>
                            <li>กระทำการใดอันเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญา</li>
                            <li>ใช้เพื่อการสร้างความเข้าใจผิด และ/หรือหลอกลวงประชาชน</li>
                            <li>ดำเนินการใด ๆ ในลักษณะของการแทรกแซงการให้บริการของผู้ให้บริการ
                                หรือผู้ให้บริการรายอื่นซึ่งอาจก่อให้เกิดความเสียหายต่อผู้ให้บริการหรือโครงข่ายโทรคมนาคมอื่น
                                ๆ</li>
                            <li>ใช้ในลักษณะที่ต้องห้ามหรือขัดต่อกฎหมาย ระเบียบ จรรยาบรรณ หรือประกาศ ใดๆที่ใช้บังคับ</li>
                        </ol>
                        <p class="text-left mb-3">
                            กรณีผู้ให้บริการตรวจสอบได้ว่าผู้ใช้บริการมีเจตนาในการทุจริตในทุกรูปแบบ
                            หรือมิได้ทำตามเงื่อนไขการใช้บริการที่บริษัทกำหนด
                            หรือกระทำการใดหรือแสดงข้อความหรือเอกสารใดอันเป็นเท็จเพื่อให้ได้มาซึ่งผลประโยชน์ส่วนตัวของผู้ใช้บริการ
                            บริษัทสามารถระงับบริการหมายเลข และ/หรือใช้สิทธิเรียกร้อง และ/หรือดำเนินคดีตามกฎหมายได้
                        </p>
                        <p class="text-left mb-3">
                            ในกรณีที่ผู้ใช้บริการมิได้ใช้งานซิมการ์ด หรือลงทะเบียนซิมการ์ดภายในระยะเวลา 60
                            วันนับจากวันที่ผู้ใช้บริการได้รับซิมการ์ด
                            บริษัทขอสงวนสิทธิระงับการเปิดใช้ซิมการ์ดนั้นเพื่อความปลอดภัยของผู้ใช้บริการ
                        </p> -->
                    </div>
                </div>
            </ng-scrollbar>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="next()" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ยอมรับเงื่อนไข</button>
        </div>
    </div>
</main>