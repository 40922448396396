<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <div class="col-12">
                <form class="mt-3 row" [formGroup]="addressForm">
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>บ้านเลขที่, ถนน<span class="required-text">*</span></label>
                            <input type="text" name="housenumber" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="housenumber">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>ชื่ออาคาร ชั้น</label>
                            <input type="text" name="housename" class="form-control" formControlName="housename">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>จังหวัด<span class="required-text">*</span></label>
                            <ng-select class="ng-select-custom" [items]="Province" name="province"
                                bindLabel="provincename" [loading]="false" [virtualScroll]="true"
                                placeholder="จำเป็นต้องกรอก" formControlName="province"
                                (change)="updateDistrict($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.provincename}} <br />
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>{{districttitle}}<span class="required-text">*</span></label>
                            <ng-select class="ng-select-custom" [items]="Selecteddistrict" name="district"
                                bindLabel="districtname" [loading]="false" [virtualScroll]="true"
                                placeholder="จำเป็นต้องกรอก" formControlName="district"
                                (change)="updateSubDistrict($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.districtname}} <br />
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>{{subdistricttitle}}<span class="required-text">*</span></label>
                            <ng-select class="ng-select-custom" [items]="Selectedsubdistrict" name="subdistrict"
                                bindLabel="subdistrictname" [loading]="false" [virtualScroll]="true"
                                placeholder="จำเป็นต้องกรอก" formControlName="subdistrict"
                                (change)="updateZipcode($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.subdistrictname}} <br />
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 text-left">
                        <div class="form-group">
                            <label>รหัสไปรษณีย์<span class="required-text">*</span></label>
                            <input type="text" name="zipcode" class="form-control" placeholder="จำเป็นต้องกรอก"
                                formControlName="zipcode">
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="next()" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ถัดไป</button>
        </div>
    </div>
</main>