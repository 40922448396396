<main>
    <form>
        <div class="row justify-content-center ">
            <div class="col-12 col-sm-8 col-lg-4 white mt-3 mb-3 p-3">
                <h1 class="text-center">เงื่อนไขการใช้งานเว็บย้ายค่าย</h1>
                <div class="row justify-content-center p-3 mb-3">
                    <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                </div>
                <p>
                    ผู้ใช้บริการสามารถลงทะเบียนซิมการ์ดแบบเติมเงินเครือข่าย Feels ได้ทุกประเภท
                    โดยผู้ใช้บริการสามารถเลือกซื้อบริการเสริมได้จากช่องทางต่างๆ ที่บริษัทกำหนด อาทิเช่น บนเว็บไซต์
                    ตัวแทนจำหน่ายฟีล สื่อสังคม เป็นต้น โดยผู้ใช้บริการที่ประสงค์ใช้บริการ Feels
                    สามารถลงทะเบียนผ่านช่องทางเว็บไซต์ ตัวแทนจำหน่าย หรือจากจุดให้บริการของฟีลได้
                    ผู้ใช้บริการสามารถเข้าสู่หน้าลงทะเบียนซิมการ์ดได้ผ่านช่องทางเว็บไซต์ หรือผู้ใช้บริการสามารถแสกน QR
                    Code บนซองซิมการ์ดเพื่อเข้าไปยังช่องทางลงทะเบียนซิมฟีล
                    โดยบริษัทจะนับว่าผู้ใช้บริการได้อ่านและยอมรับเงื่อนไขการใช้บริการของบริษัทแล้ว
                    ผู้ใช้บริการสามารถอ่านเงื่อนไขการใช้งานได้ที่ https://www.feels.co.th/proviso.html
                    ในการใช้งานระบบเติมเงิน หรือระบบชำระเงินของบริษัท ผู้ใช้บริการยินยอมให้บริษัทหักบัญชีจากบัตรเครดิต
                    บัตรเดบิต บัญชี Alipay บัญชี Rabbit Line Pay บัญชี WeChat Pay
                    หรือวิธีการอื่นใดที่บริษัทจะมีในอนาคตที่ผู้ใช้บริการได้เลือกไว้
                    ผู้ใช้บริการจะต้องถ่ายรูปบาร์โค้ดบนซองซิมการ์ดเพื่อยืนยัน
                </p>
                <mdb-checkbox [default]="true">กดเช็คเพื่อยอมรับเงื่อนไข</mdb-checkbox>
                <div class="d-flex justify-content-center">
                    <button mdbBtn type="button" (click)="onSent()" class="rounded-pill w-50" color="success"
                        mdbWavesEffect>ถัดไป</button>
                </div>
            </div>
        </div>
    </form>
</main>