import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellingagreement',
  templateUrl: './sellingagreement.component.html',
  styleUrls: ['./sellingagreement.component.scss']
})
export class SellingagreementComponent implements OnInit {
  // pdfSrc = "./assets/pdf/2021_01_27_ข้อเสนอการขายส่งบริการโทรศัพท์เคลื่อนที่.pdf";

  constructor() {
    // this.show(this.pdfSrc);
  }

  ngOnInit(): void {
  }

  // public show(blob) {
  //   var fileURL: any = URL.createObjectURL(blob);
  //   var a = document.createElement("a");
  //   a.href = fileURL;
  //   a.target = '_blank';
  //   // Don't set download attribute
  //   // a.download = "Example.pdf";
  //   a.click();
  // }

}
