import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackageService } from 'src/app/services/package.service';

@Component({
  selector: 'app-qrpayment',
  templateUrl: './qrpayment.component.html',
  styleUrls: ['./qrpayment.component.scss']
})
export class QrpaymentComponent implements OnInit {
  public qrcode: string
  public payment: string
  public data: JSON = <JSON><unknown>{
    'thaiqr': '../../../assets/img/icon/Payment/mobilebanking-alt.png',
    'alipay': '../../../assets/img/icon/Payment/alipay.png',
    'wechatpay': '../../../assets/img/icon/Payment/wechat.png',
    'linepay': '../../../assets/img/icon/Payment/line.png',
    'creditcard': '../../../assets/img/icon/Payment/credit-card.png'
  }

  constructor(private _Activatedroute: ActivatedRoute, private pack: PackageService) {
    this.qrcode = this.pack.urlpayment;
    this.payment = this.pack.paymentchannel;
  }

  ngOnInit(): void {
  }

}
