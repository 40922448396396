import { Component, OnInit } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalpaypackComponent } from './modalpaypack/modalpaypack.component';
import { PackageService } from '../../services/package.service'
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

    public selectedindex: number = 0; //Initialized to 0
    modalRef: MDBModalRef;
    packagesres: Array<object>
    public slides: Array<Object> = [
        { "img": "../../../assets/img/banner/store-2-04.png" },
        { "img": "../../../assets/img/banner/store-2-04.png" }
    ];
    btnroute: boolean = false;

    constructor(private route: ActivatedRoute, private pack: PackageService, private modalService: MDBModalService) {
        this.pack.getPackages('static').subscribe((res: any) => {
            this.btnroute = true;
            this.packagesres = Object.values(JSON.parse(res + '')['packagelist'])
            if (this.route.snapshot.paramMap.get("packid") !== null) {
                this.onSelect(this.packagesres.filter(obj => obj['packagecode'] + '' === this.route.snapshot.paramMap.get("packid"))[0])
            }
        })
    }

    ngOnInit(): void {
    }

    selectImage(index: number) {
        this.selectedindex = index;
    }

    onSelect(selectPack: Object) {
        this.pack.selectPackObj = selectPack
        this.pack.selectPackPrice = selectPack['total_net']
        this.pack.selectPackCode = selectPack['packagecode']
        this.modalRef = this.modalService.show(ModalpaypackComponent,
            { class: 'modal-dialog-scrollable modal-dialog-centered' }
        )
    }

    // bannerPackage() {
    //     let packagecode = "F12E10EL000S344";
    //     this.onSelect(this.packagesres.filter(obj => obj['packagecode'] + '' === packagecode));
    // }

}
