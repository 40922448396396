import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { ModalService } from '../../services/modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public img: SafeResourceUrl;

  constructor(public modalRef: MDBModalRef, private _sanitizer: DomSanitizer, private modal: ModalService) { }

  ngOnInit(): void {
    // this.modal.getModal().subscribe((res: Response) => {
    //   if (res['responsecode'] == 0) {
    //     this.img = this._sanitizer.bypassSecurityTrustResourceUrl("data:Image/*;base64,"+res['modalimage']);
    //   }
    // })
  }

}
