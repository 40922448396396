<main>
    <!-- <div class="w-100" *ngIf="page==='intro'">
        <mdb-carousel [isControls]="true" [animation]="'slide'">
            <mdb-slide *ngFor="let slide of slides; let index=index">
                <img class="d-block w-100" style="height:40vh; object-fit: cover;" [src]="slide.img">
            </mdb-slide>
        </mdb-carousel>
    </div> -->
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-16.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 30 copy 2.png">
    </div>
    <div class="row justify-content-center w-100 m-0" *ngIf="page==='intro'">
        <div class="col-12 col-sm-10 col-md-8 white rounded-top-3 mt-3 mb-3 p-5">
            <div class="mb-3">
                <h1 class="fs-50 line-h-100 text-center text-light-green-main">ย้ายค่ายมาใช้ Feels</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-01.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">1. กรอกเบอร์ที่ต้องการย้ายค่าย</h2>
                        <p>กรอกเบอร์ที่ต้องการย้ายค่ายและรหัสย้ายค่าย</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-02.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">2. กรอกข้อมูลส่วนตัว</h2>
                        <p>กรอกข้อมูลส่วนตัวของท่าน เช่น ที่อยู่ เลขบัตรประชาชน รูปหน้าตรง</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-03.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">3. ที่อยู่จัดส่งซิมใหม่</h2>
                        <p>กรอกข้อมูลที่อยู่ที่ท่านต้องการให้จัดส่งซิมการ์ดไป</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-04.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">4. ย้ายค่ายเสร็จแล้ว</h2>
                        <p>ย้ายค่ายสำเร็จแล้ว เริ่มใช้งานซิม Feels ได้ทันที</p>
                    </div>
                </div>
                <div class="col-12 text-center p-3">
                    <button mdbBtn type="button" class="rounded-pill bg-soft-green text-white"
                        mdbWavesEffect>เร็วๆนี้</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center w-100 m-0" *ngIf="page==='done'">
        <div class="col-12 col-sm-10 col-md-8 white mt-3 mb-3 p-0 animated fadeIn">
            <div class="rounded-bottom-3 bg-soft-green">
                <div class="p-3">
                    <h1 class="text-center text-white m-0">
                        แสกนคิวอาร์โค้ดด้านล่างนี้เพื่อชำระเงินตามช่องทางที่ท่านได้เลือกไว้
                    </h1>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 p-3">
                        <div class="row justify-content-center" *ngIf="errorQr">
                            <img class="col-4" src="../../../assets/img/icon/Asset2.svg">
                        </div>
                        <div class="row justify-content-center" *ngIf="!errorQr">
                            <div class="text-center rounded white p-1">
                                <qrcode [qrdata]="qrcode" [width]="qrSize" [errorCorrectionLevel]="'M'">
                                </qrcode>
                                <a [href]="link" download class="text-light-green-main"
                                    (click)="onClick()">บันทึกรูป</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center p-3">
                    <button mdbBtn type="button" (click)="onDone()" class="rounded-pill bg-soft-green text-white"
                        mdbWavesEffect>แพ็กเกจ</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row w-100 m-0" *ngIf="page==='agreement'">
        <div class="col-12">
            <app-agreementmove></app-agreementmove>
        </div>
    </div>
    <div class="row w-100 m-0" *ngIf="page==='detail'">
        <div class="col-12">
            <app-detailmove></app-detailmove>
        </div>
    </div>
    <div class="row w-100 m-0" *ngIf="page==='payment'">
        <div class="col-12">
            <app-paymentsimcard></app-paymentsimcard>
        </div>
    </div>
    <qrcode #save id="qr" [qrdata]="qrcode" [width]="1000" elementType="img" [errorCorrectionLevel]="'M'"
        class="d-none">
    </qrcode>
</main>