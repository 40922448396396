import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnlinesimService } from '../../../services/onlinesim.service';

@Component({
    selector: 'app-selectnumber',
    templateUrl: './selectnumber.component.html',
    styleUrls: ['./selectnumber.component.scss']
})
export class SelectnumberComponent implements OnInit {
    serachForm: FormGroup;
    // arrayfilterType: Object;
    filterType: Array<Object> = [
        {
            'id': 'sum',
            'filtername': 'ผลรวม'
        },
        {
            'id': 'numberlike',
            'filtername': 'ค้นหาเลขที่สนใจ'
        }
    ];
    stockloading: boolean = true;
    notfoundstock: boolean = false;
    startchar: number = 0;
    endchar: number = 11;
    indexFromSearch: number = 0;
    indexToSearch: number = 11;
    selected: string;
    phonenumber: Array<Object> = []

    // price: number = 0;
    // numberproductid: string;
    // numberoriginprice: string;
    // numbertaxprice: string;

    constructor(private formBuilder: FormBuilder, private onlinesimService: OnlinesimService) {
        // this.arrayfilterType = this.filterType[1]
        this.serachForm = this.formBuilder.group({
            keyword: [''],
            arrayfilterType: [this.filterType[1]]
        });
        this.onlinesimService.getPhonenumber().subscribe(
            (res: any) => {
                this.stockloading = false;
                this.phonenumber = Object.values(JSON.parse(res + '')['numberlist']);
            }
        )
    }

    get f() {
        return this.serachForm.controls;
    }

    ngOnInit(): void {
    }

    onSearch() {
        this.stockloading = true;
        let keyword = this.serachForm.value.keyword;
        let filtertype = this.serachForm.value.arrayfilterType.id;
        if (!!keyword) {
            this.onlinesimService.filterNumber(keyword, filtertype).subscribe(
                (res: any) => {
                    this.stockloading = false;
                    let json_res = JSON.parse(res);
                    if (json_res.responsecode === 0) {
                        this.notfoundstock = false
                        this.phonenumber = Object.values(JSON.parse(res + '')['numberlist']);
                    } else {
                        if (json_res.responsecode === 101) {
                            this.notfoundstock = true
                        }
                    }
                }
            );
        } else {
            this.notfoundstock = false
            this.onlinesimService.getPhonenumber().subscribe(
                (res: any) => {
                    this.stockloading = false;
                    this.onlinesimService.phonenumber.next('ยังไม่เลือกเบอร์');
                    this.phonenumber = Object.values(JSON.parse(res + '')['numberlist']);
                }
            );
        }
    }

    onKey(event) {
        this.onlinesimService.price = 0;
        // console.log(event.getAttribute('data-price'));
        for (const prop in this.phonenumber) {
            if (this.phonenumber[prop]['phonenumber'] === event.target.value) {
                // this.onlinesimService.summarydetail = this.onlinesimService.summarydetail.filter(items => items.item !== 'ซิมการ์ด');
                //         console.log(this.phonenumber[prop]['numberid']);
                //         console.log(this.phonenumber[prop]['price']);
                // this.onlinesimService.price += +this.phonenumber[prop]['originprice'];
                this.onlinesimService.numberproductid = this.phonenumber[prop]['productid'];
                this.onlinesimService.numberoriginprice = this.phonenumber[prop]['originprice'];
                this.onlinesimService.numbertaxprice = this.phonenumber[prop]['taxprice'];
                // let detail = {
                //     'item': 'ซิมการ์ด',
                //     'amount': '1',
                //     'price': this.phonenumber[prop]['originprice']
                // }
                // this.onlinesimService.summarydetail.push(detail);
            }
        }
        this.selected = event.target.value;
        this.onlinesimService.phonenumber.next(this.selected);
    }

    onNext(search: boolean) {
        if (!search) {
            if (!(this.endchar < (this.phonenumber.length - 1))) {
                this.onSearch()
                this.startchar = 0
                this.endchar = 11
            } else {
                this.startchar += 12
                this.endchar += 12
            }
        } else {
            this.indexFromSearch += 12
            this.indexToSearch += 12
        }
    }

    onPrevious(search: boolean) {
        if (!search) {
            this.startchar -= 12
            this.endchar -= 12
        } else {
            this.indexFromSearch -= 12
            this.indexToSearch -= 12
        }
    }

}
