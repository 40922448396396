<main>
    <form>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-12 col-md-8 white p-3 mt-3 mb-3">
                <div class="row">
                    <div class="col-12 animated fadeIn">
                        <h2 class="text-center text-success">ช่องทางการชำระเงิน</h2>
                        <div class="row">
                            <div class="payment-icon col-6 col-md-4">
                                <label>
                                    <img *ngIf="myRadio==='thaiqr'"
                                        src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                                        class="payment-img-active">
                                    <img *ngIf="myRadio!=='thaiqr'"
                                        src="../../../assets/img/icon/Payment/mobilebanking-alt.png"
                                        class="payment-img">
                                    <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="thaiqr"
                                        name="payment-method" value="thaiqr" (click)="radio($event)">
                                </label>
                            </div>
                            <div class="payment-icon col-6 col-md-4">
                                <label>
                                    <img *ngIf="myRadio==='linepay'" src="../../../assets/img/icon/Payment/line.png"
                                        class="payment-img-active">
                                    <img *ngIf="myRadio!=='linepay'" src="../../../assets/img/icon/Payment/line.png"
                                        class="payment-img">
                                    <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="linepay"
                                        name="payment-method" value="linepay" (click)="radio($event)">
                                </label>
                            </div>
                            <div class="payment-respo"><br></div>
                            <div class="payment-icon col-6 col-md-4">
                                <label>
                                    <img *ngIf="myRadio==='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                                        class="payment-img-active">
                                    <img *ngIf="myRadio!=='wechatpay'" src="../../../assets/img/icon/Payment/wechat.png"
                                        class="payment-img">
                                    <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="wechatpay"
                                        name="payment-method" value="wechatpay" (click)="radio($event)">
                                </label>
                            </div>
                            <div class="payment-icon col-6 col-md-4">
                                <label>
                                    <img *ngIf="myRadio==='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                                        class="payment-img-active">
                                    <img *ngIf="myRadio!=='alipay'" src="../../../assets/img/icon/Payment/alipay.png"
                                        class="payment-img">
                                    <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="alipay"
                                        name="payment-method" value="alipay" (click)="radio($event)" checked>
                                </label>
                            </div>
                            <div class="payment-respo"><br></div>
                            <div class="payment-icon col-6 col-md-4">
                                <label>
                                    <img *ngIf="myRadio!=='creditcard'"
                                        src="../../../assets/img/icon/Payment/credit-card.png" style="background-color: rgb(49, 
                                                49, 49); display: flex;" class="payment-img">
                                    <img *ngIf="myRadio==='creditcard'"
                                        src="../../../assets/img/icon/Payment/credit-card.png" style="background-color: rgb(49, 
                                                49, 49); display: flex;" class="payment-img-active">
                                    <input type="radio" #ctrl="ngModel" [(ngModel)]="myRadio" id="creditcard"
                                        name="payment-method" value="creditcard" (click)="radio($event)">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center p-3 animated fadeIn">
                        <button mdbBtn type="button" (click)="onSent()" class="rounded-pill bg-soft-green text-white"
                            mdbWavesEffect>ชำระเงิน</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</main>