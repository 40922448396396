<div class="modal-body">
    <p><img [src]="img"></p>
</div>
<!-- <div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}"> -->
<!-- <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content"> -->
<!-- <div class="modal-body">
    <div class="row d-flex justify-content-center align-items-center">
        <h2>
            <span class="badge">v52gs1</span>
        </h2>
        <p class="pt-3 mx-4">We have a gift for you! Use this code to get a
            <strong>10% discount</strong>.</p>
        <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
        </a>
        <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="frame.hide()">Ok, thanks</a>
    </div>
</div> -->
<!-- </div>
</div> -->
<!-- </div> -->