import { Component, OnInit } from '@angular/core';
import { OnlinesimService } from '../../services/onlinesim.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-onlinesim',
    templateUrl: './onlinesim.component.html',
    styleUrls: ['./onlinesim.component.scss']
})
export class OnlinesimComponent implements OnInit {
    page: string = 'selectnumber';
    // page: string = 'result';
    loading: boolean = false;
    errormessage: string;

    selectednumber: string = 'ยังไม่เลือกเบอร์';
    selectedpackage: string = '-';
    numberError: boolean;

    constructor(private onlinesimService: OnlinesimService) {
        this.onlinesimService.summarydetail = [];
        this.onlinesimService.loading.subscribe((value) => {
            if (value !== undefined) {
                this.loading = value;
            }
        });
        this.onlinesimService.page.subscribe((value) => {
            if (value !== undefined) {
                this.page = value;
            }
        });
        this.onlinesimService.phonenumber.subscribe((value) => {
            if (value !== undefined) {
                this.selectednumber = value;
            }
        });
        this.onlinesimService.package.subscribe((value) => {
            if (value !== undefined) {
                this.selectedpackage = value;
            }
        });
    }

    ngOnInit(): void {
    }

    onSubmit() {
        if (this.page === 'selectnumber') {
            if (this.selectednumber != 'ยังไม่เลือกเบอร์') {
                this.onlinesimService.price += +this.onlinesimService.numberoriginprice;
                console.log(this.onlinesimService.price);
                let detail = {
                    'item': 'ซิมการ์ด',
                    'detail': this.selectednumber,
                    'amount': '1',
                    'price': this.onlinesimService.numberoriginprice,
                    'productid': this.onlinesimService.numberproductid,
                    'quantity': '1'
                }
                this.onlinesimService.summarydetail.push(detail);
                this.onlinesimService.page.next('selectpackage');
            } else {
                Swal.fire({
                    title: 'คำเตือน',
                    html: '<div class="alert-text">กรุณาเลือกเบอร์ที่ต้องการ ก่อนไปขั้นตอนถัดไป</div>',
                    icon: 'warning',
                    confirmButtonColor: "#28a745",
                    confirmButtonText: "ตกลง",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                })
            }
        } else if (this.page === 'selectpackage') {
            let phonenumber = '66' + this.selectednumber.slice(1);
            this.onlinesimService.bookNumber(phonenumber).subscribe(
                (res: any) => {
                    let json_res = JSON.parse(res);
                    console.log(json_res);
                    if (json_res.responsecode === 0) {
                        if (this.onlinesimService.selectedPackageCode) {
                            this.onlinesimService.price += +this.onlinesimService.selectedPackageOriginPrice;
                            console.log(this.onlinesimService.price);
                            let detail = {
                                'item': 'แพ็กเกจ',
                                'detail': this.selectedpackage,
                                'amount': '1',
                                'price': this.onlinesimService.selectedPackageOriginPrice,
                                'productid': this.onlinesimService.selectedPackageProductid,
                                'quantity': '1'
                            }
                            this.onlinesimService.summarydetail.push(detail);
                        }
                        this.onlinesimService.page.next('agreement');
                    }
                },
                (err: any) => {
                    Swal.fire({
                        title: 'ผิดพลาด',
                        html: '<div class="alert-text">ระบบมีปัญหา กรุณาลองใหม่อีกครั้ง</div>',
                        icon: 'warning',
                        confirmButtonColor: "#28a745",
                        confirmButtonText: "ตกลง",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                    })
                }
            );
        }
    }

}
