import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    private API_URL_PROD = environment.API_URL_PROD;
    public page: Subject<string> = new Subject<string>();
    public pageMove: Subject<string> = new Subject<string>();
    public loading: Subject<boolean> = new Subject<boolean>();
    public errormessage: Subject<string> = new Subject<string>();
    public simcardbase64: string;
    public phonenumber: string;
    public iccid: string;
    public frontidcardbase64: string;
    public frontidcardangle: string;
    public backidcardbase64: string;
    public backidcardangle: string;
    public faceidone: string;
    public faceidtwo: string;
    public personalbase64one: string;
    public personalangleone: string;
    public personalbase64two: string;
    public personalangletwo: string;
    public citizenid: string;
    public firstname: string;
    public lastname: string;
    public birthday: string;
    public religion: string;
    public address: string;
    public dateissue: string;
    public dateexpire: string;
    public lasercode: string;
    public contactnumber: string;

    constructor(private http: HttpClient) {
    }

    checkphonumber(text: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ text });
        const url = this.API_URL_PROD + '/feelswebapp/checksimcard';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    facedetect(phonenumber: string, frontimg: string, frontangle: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ phonenumber, frontimg, frontangle });
        // console.log(body);
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(body, null, 4));
        const url = this.API_URL_PROD + '/feelswebapp/facedetect';
        // const url = this.API_URL_PROD + '/feelswebapp/v2/ocrthaiidcard';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    ocrthatidcard(phonenumber: string, frontimg: string, frontangle: string, backimg: string, backangle: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ phonenumber, frontimg, frontangle, backimg, backangle });
        // console.log(body);
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(body, null, 4));
        const url = this.API_URL_PROD + '/feelswebapp/ocrthaiidcard';
        // const url = this.API_URL_PROD + '/feelswebapp/v2/ocrthaiidcard';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    calldopa(phonenumber: string, citizenid: string, firstname: string, lastname: string, birthday: string, lasercode: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ phonenumber, citizenid, firstname, lastname, birthday, lasercode });
        // console.log(body);
        const url = this.API_URL_PROD + '/feelswebapp/calldopa';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    faceverify(data) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify(data);
        // console.log(body);
        // return body
        // const url = this.API_URL_PROD + '/';
        const url = this.API_URL_PROD + '/feelswebapp/faceverify';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    pendingsim(data) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify(data);
        // console.log(body);
        // return body
        // const url = this.API_URL_PROD + '/';
        const url = this.API_URL_PROD + '/feelswebapp/pendingsim';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    activesim(data) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify(data);
        // console.log(body);
        // return body
        // const url = this.API_URL_PROD + '/';
        const url = this.API_URL_PROD + '/feelswebapp/activesim';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }

    checkblanksim(text: string) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ text });
        const url = this.API_URL_PROD + '/feelswebapp/checkblanksim';
        var result = this.http.post(url, body, { responseType: 'text', headers });
        return result
    }
}
