<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-10 pt-4 mb-3">
            <div class="number-box loading-box" *ngIf="packageloading">
                <div class="text-center">
                    <div class="spinner-border"></div>
                </div>
            </div>
            <div class="pt-3" *ngIf="!packageloading">
                <div class="promotion-container row m-0 p-3 p-md-4 rounded-lg cursor-on shadow" (click)="onSelectNull()"
                    [ngClass]="{ 'promotion-container-active': selectedpackage === 'null' }">
                    <div class="col-12 d-block text-center d-lg-block fs-20 my-auto">
                        สมัครแพ็กเกจภายหลัง
                    </div>
                </div>
            </div>
            <div *ngFor="let item of packagelist; let i = index" class="pt-3">
                <div class="promotion-container row m-0 p-3 p-md-4 rounded-lg cursor-on shadow" (click)="onSelect(item)"
                    [ngClass]="{ 'promotion-container-active': selectedpackage === item.packagecode }">
                    <div class="col-3 package-title d-none text-center d-lg-block my-auto">
                        <h1 class="text-light-green-main fs-30 mb-1">
                            <span class="elegant-color-text">แพ็กเกจ</span><br>
                            {{item.productname}}
                        </h1>
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <div
                            *ngIf="item.packagetype === 'VOICE' && item.packagespeedamount === '0' && item.packagedataamount === '0'">
                            <h3 class="elegant-color-text mb-1 fs-30">-</h3>
                        </div>
                        <div *ngIf="item.packagetype !== 'VOICE'">
                            <h3 class="text-light-green-main mb-1 fs-30" *ngIf="item.packagespeedtype === 'FullSpeed'">
                                Full Speed
                            </h3>
                            <h3 class="text-light-green-main mb-1 fs-30" *ngIf="item.packagespeedtype !== 'FullSpeed'">
                                {{item.packagespeedamount}}
                                <span class="elegant-color-text"> {{item.packagespeedunit}}</span>
                            </h3>
                            <small class="respo-text-phone mt-2">ใช้เน็ตได้
                                <span class="text-light-green-main fs-20"
                                    *ngIf="item.packagedatatype === 'Unlimited'">ไม่จำกัด</span>
                                <span class="text-light-green-main fs-20" *ngIf="item.packagedatatype !== 'Unlimited'">
                                    {{item.packagedataamount}}
                                    <span class="elegant-color-text"> {{item.packagedataunit}}</span>
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <h3 class="elegant-color-text mb-1 fs-30"
                            *ngIf="item.voiceamount === '0' && item.packageonnetfreecall === '0' && item.packageoffnetfreecall === '0'">
                            -</h3>
                        <h3 class="text-light-green-main mb-1 fs-30" *ngIf="item.voiceamount !== '0'">
                            {{item.voiceamount}}
                            <span class="elegant-color-text"> นาที</span>
                        </h3>
                        <h3 class="text-light-green-main mb-1 fs-30"
                            *ngIf="item.voiceamount === '0' && (item.packageonnetfreecall !== '0' || item.packageoffnetfreecall !== '0')">
                            โทรฟรีไม่จำกัด
                        </h3>
                        <small class="respo-text-phone mt-2"
                            *ngIf="item.voiceamount === '0' && item.packageonnetfreecall !== '0'">
                            โทรฟรีในเครือข่าย ครั้งละ 60 นาที
                        </small>
                        <small class="respo-text-phone mt-2"
                            *ngIf="item.voiceamount === '0' && item.packageoffnetfreecall !== '0'">
                            โทรฟรีทุกเครือข่าย ครั้งละ 60 นาที
                        </small>
                    </div>
                    <div class="col-3 package-price d-none text-center d-lg-block my-auto">
                        <h3 class="text-light-green-main mb-1 fs-30">
                            {{item.totalprice}}
                            <span class="elegant-color-text fs-16">บาท / {{item.packageduration}} วัน</span>
                        </h3>
                    </div>
                    <div class="col-12 d-lg-none d-block text-left my-auto">
                        <h1 class="text-light-green-main fs-30 mb-1">
                            <span class="elegant-color-text">แพ็กเกจ</span><br>
                            {{item.productname}}
                        </h1>
                    </div>
                    <div class="col-12 d-lg-none d-block text-left my-auto">
                        <div>
                            <div *ngIf="item.packagetype !== 'VOICE'">อินเตอร์เน็ต
                                <b class="text-light-green-main" *ngIf="item.packagedatatype === 'Unlimited'">ไม่จำกัด
                                </b>
                                <b class="text-light-green-main"
                                    *ngIf="item.packagedatatype !== 'Unlimited'">{{item.packagedataamount}}
                                    {{item.packagedataunit}} </b>
                                <b class="elegant-color-text" *ngIf="item.packagespeedtype === 'FullSpeed'">
                                    Full Speed
                                </b>
                                <b class="elegant-color-text" *ngIf="item.packagespeedtype !== 'FullSpeed'">
                                    {{item.packagespeedamount}}
                                    <span class="elegant-color-text"> {{item.packagespeedunit}}</span>
                                </b>
                            </div>
                            <div
                                *ngIf="item.voiceamount !== '0' || (item.packageonnetfreecall !== '0' || item.packageoffnetfreecall !== '0')">
                                <span *ngIf="item.packagetype !== 'VOICE'">/ โทร</span>
                                <span *ngIf="item.packagetype === 'VOICE'">โทร</span>
                                {{" "}}
                                <b class="text-light-green-main"
                                    *ngIf="item.voiceamount === '0' && item.packageonnetfreecall === '0' && item.packageoffnetfreecall === '0'">-</b>
                                <b class="text-light-green-main" *ngIf="item.voiceamount !== '0'">{{item.voiceamount}}
                                    นาที</b>
                                <b class="text-light-green-main"
                                    *ngIf="item.voiceamount === '0' && item.packageonnetfreecall !== '0'">โทรฟรีในเครือข่าย
                                    ครั้งละ 60 นาที</b>
                                <b class="text-light-green-main"
                                    *ngIf="item.voiceamount === '0' && item.packageoffnetfreecall !== '0'">โทรฟรีทุกเครือข่าย
                                    ครั้งละ 60 นาที</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-lg-none d-block text-left my-auto">
                        <div class="text-right text-light-green-main fs-20 mt-3">{{item.totalprice}}
                            <span class="elegant-color-text fs-16"> บาท / {{item.packageduration}} วัน</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>