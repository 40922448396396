import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { QRCodeModule } from 'angularx-qrcode';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Hammer from 'hammerjs';
import { FacebookModule } from 'ngx-facebook';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PackagesComponent } from './components/packages/packages.component';
import { OndemandpackageComponent } from './components/ondemandpackage/ondemandpackage.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FooterComponent } from './components/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { FrequenciesComponent } from './components/frequencies/frequencies.component';
import { TopupComponent } from './components/topup/topup.component';
import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { DevicesettingsComponent } from './components/devicesettings/devicesettings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QrpaymentComponent } from './components/qrpayment/qrpayment.component';
import { StoreComponent } from './components/store/store.component';
import { ModalComponent } from './components/modal/modal.component';
import { ProductComponent } from './components/product/product.component';
import { NumberComponent } from './components/number/number.component';
import { RegisterComponent } from './components/register/register.component';
import { MovetofeelsComponent } from './components/movetofeels/movetofeels.component';
import { SubmitComponent } from './components/number/submit/submit.component';
import { AgreementComponent } from './components/register/agreement/agreement.component';
import { SimcardComponent } from './components/register/simcard/simcard.component';
import { DetailComponent } from './components/register/detail/detail.component';
import { AgreementmoveComponent } from './components/movetofeels/agreementmove/agreementmove.component';
import { DetailmoveComponent } from './components/movetofeels/detailmove/detailmove.component';
import { PaymentsimcardComponent } from './components/movetofeels/paymentsimcard/paymentsimcard.component';
import { ModalpaypackComponent } from './components/packages/modalpaypack/modalpaypack.component';
import { ModalpaytopupComponent } from './components/topup/modalpaytopup/modalpaytopup.component';
import { ModalpayondemandComponent } from './components/ondemandpackage/modalpayondemand/modalpayondemand.component';
import { RedirectGuard } from './guards/redirect.guard';
import { SubpathGuard } from './guards/subpath.guard';
import { ProvisoComponent } from './components/proviso/proviso.component';
import { CameraCaptureComponent } from './components/register/camera-capture/camera-capture.component';
import { PersonalinfoComponent } from './components/register/personalinfo/personalinfo.component';
import { PersonalimageComponent } from './components/register/personalimage/personalimage.component';
import { TermpageComponent } from './components/register/termpage/termpage.component';
import { OnlinesimComponent } from './components/onlinesim/onlinesim.component';
import { SelectnumberComponent } from './components/onlinesim/selectnumber/selectnumber.component';
import { SelectpackageComponent } from './components/onlinesim/selectpackage/selectpackage.component';
import { OnlinesimagreementComponent } from './components/onlinesim/onlinesimagreement/onlinesimagreement.component';
import { OnlinesimpersonalComponent } from './components/onlinesim/onlinesimpersonal/onlinesimpersonal.component';
import { OnlinesimaddressComponent } from './components/onlinesim/onlinesimaddress/onlinesimaddress.component';
import { OnlinesimsummaryComponent } from './components/onlinesim/onlinesimsummary/onlinesimsummary.component';
import { OnlinesimpaymentComponent } from './components/onlinesim/onlinesimpayment/onlinesimpayment.component';
import { PortinComponent } from './components/portin/portin.component';
import { PortinagreementComponent } from './components/portin/portinagreement/portinagreement.component';
import { PortinblanksimComponent } from './components/portin/portinblanksim/portinblanksim.component';
import { PortinpersonalimgComponent } from './components/portin/portinpersonalimg/portinpersonalimg.component';
import { PortinpersonalinfoComponent } from './components/portin/portinpersonalinfo/portinpersonalinfo.component';
import { PortincusimgComponent } from './components/portin/portincusimg/portincusimg.component';
import { SellingagreementComponent } from './components/sellingagreement/sellingagreement.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './services/pwa.service';
import { PromptComponent } from './components/prompt/prompt.component';
import { ComplaintCenterComponent } from './components/complaint-center/complaint-center.component';
import { TouristComponent } from './components/tourist/tourist.component';
import { ModaltouristpayComponent } from './components/tourist/modaltouristpay/modaltouristpay.component';

// const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

// Routes
export const router: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    // {
    //     path: 'activation',
    //     canActivate: [RedirectGuard],
    //     component: RegisterComponent,
    //     data: {
    //         externalUrl: 'https://register.feels.co.th:8004/'
    //     }
    // },
    {
        path: 'activation',
        redirectTo: '/register',
        pathMatch: 'full'
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'movetofeels',
        redirectTo: '/portin',
        pathMatch: 'full'
    },
    // {
    //     path: 'portin',
    //     component: PortinComponent
    // },
    {
        path: 'portin',
        component: MovetofeelsComponent
    },
    // {
    //     path: 'store',
    //     component: StoreComponent
    // },
    {
        path: 'packages',
        component: PackagesComponent
    },
    {
        path: 'qrpackages',
        redirectTo: '/packages',
        pathMatch: 'full'
    },
    {
        path: 'onlinepackages',
        redirectTo: '/packages',
        pathMatch: 'full'
    },
    {
        path: 'promotion',
        redirectTo: '/packages',
        pathMatch: 'full'
    },
    {
        path: 'th/ondemandpackage',
        redirectTo: '/packages',
        pathMatch: 'full'
    },
    {
        path: 'en/ondemandpackage',
        redirectTo: '/packages',
        pathMatch: 'full'
    },
    {
        path: 'ondemandpackage',
        component: OndemandpackageComponent
    },
    {
        path: 'ondemandpackages',
        redirectTo: '/ondemandpackage',
        pathMatch: 'full'
    },
    {
        path: 'topup',
        component: TopupComponent
    },
    {
        path: 'tourist',
        component: TouristComponent
    },
    {
        path: 'recharge',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'onlinetopup/th',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'qrpayments',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'qrpayments/th',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'th/onlinetopup',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'en/onlinetopup',
        redirectTo: '/topup',
        pathMatch: 'full'
    },
    {
        path: 'about',
        component: AboutComponent
    },
    {
        path: 'frequencies',
        component: FrequenciesComponent
    },
    {
        path: 'policy',
        component: PrivacypolicyComponent
    },
    {
        path: 'policy.html',
        redirectTo: '/policy',
        pathMatch: 'full'
    },
    {
        path: 'proviso',
        component: ProvisoComponent
    },
    {
        path: 'proviso.html',
        redirectTo: '/proviso',
        pathMatch: 'full'
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    {
        path: 'devicesettings',
        component: DevicesettingsComponent
    },
    {
        path: 'settings',
        redirectTo: '/devicesettings',
        pathMatch: 'full'
    },
    {
        path: 'apn',
        component: DevicesettingsComponent
        // redirectTo: '/devicesettings',
        // pathMatch: 'full',
    },
    {
        path: 'apniphone',
        component: DevicesettingsComponent
        // redirectTo: '/devicesettings',
        // pathMatch: 'full',
    },
    {
        path: 'carrier',
        component: DevicesettingsComponent
        // redirectTo: '/devicesettings',
        // pathMatch: 'full',
    },
    {
        path: 'carrieriphone',
        component: DevicesettingsComponent
        // redirectTo: '/devicesettings',
        // pathMatch: 'full',
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'contactus',
        redirectTo: '/contact',
        pathMatch: 'full'
    },
    {
        path: 'onlinesim',
        component: OnlinesimComponent
    },
    {
        path: 'complaintcenter',
        component: ComplaintCenterComponent
    },
    {
        path: 'selectnumber',
        redirectTo: '/onlinesim',
        pathMatch: 'full'
    },
    {
        path: 'onlinesimcard',
        redirectTo: '/onlinesim',
        pathMatch: 'full'
    },
    // {
    //     path: 'onlinesimdev',
    //     component: NumberComponent
    // },
    // {
    //     path: 'product/:id',
    //     component: ProductComponent
    // },
    {
        path: 'sellingagreement',
        component: SellingagreementComponent
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    }
    // developing path
    // {
    //   path: 'product',
    //   component: ProductComponent
    // },
    // {
    //   path: 'qrpayment',
    //   component: QrpaymentComponent
    // },
    // {
    //   path: 'number',
    //   component: NumberComponent
    // },
    // {
    //   path: 'selectnumber/',
    //   component: NumberComponent
    // },
    // {
    //   path: 'onlinesimcard/',
    //   component: NumberComponent
    // },
];

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: 'feels.co.th'
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '#000000',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#50d587',
            text: '#000000',
            border: 'transparent'
        }
    },
    type: 'info',
    content: {
        message:
            'เว็บไซต์นี้ใช้คุกกี้เพื่อเพิ่มประสบการณ์และความพึงพอใจในการใช้งาน ให้สามารถเข้าถึงง่าย สะดวกในการใช้งาน และมีประสิทธิภาพยิ่งขึ้น การกด "ยอมรับ" ถือว่าคุณได้อนุญาตให้เราใช้คุกกี้ตามนโยบายคุกกี้ของบริษัท',
        dismiss: 'ยอมรับ',
        deny: 'Refuse cookies',
        link: 'เรียนรู้เพิ่มเติม',
        href: '/policy',
        // target: '_blank|_self',
        policy: 'Cookie Policy'
    }
};

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_ALL } // override default settings
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavbarComponent,
        PackagesComponent,
        OndemandpackageComponent,
        FooterComponent,
        AboutComponent,
        PrivacypolicyComponent,
        FrequenciesComponent,
        TopupComponent,
        ContactComponent,
        FaqComponent,
        DevicesettingsComponent,
        QrpaymentComponent,
        StoreComponent,
        ModalComponent,
        ProductComponent,
        NumberComponent,
        RegisterComponent,
        MovetofeelsComponent,
        SubmitComponent,
        AgreementComponent,
        SimcardComponent,
        DetailComponent,
        AgreementmoveComponent,
        DetailmoveComponent,
        PaymentsimcardComponent,
        ModalpaypackComponent,
        ModalpaytopupComponent,
        ModalpayondemandComponent,
        ProvisoComponent,
        CameraCaptureComponent,
        PersonalinfoComponent,
        PersonalimageComponent,
        TermpageComponent,
        OnlinesimComponent,
        SelectnumberComponent,
        SelectpackageComponent,
        OnlinesimagreementComponent,
        OnlinesimpersonalComponent,
        OnlinesimaddressComponent,
        OnlinesimsummaryComponent,
        OnlinesimpaymentComponent,
        PortinComponent,
        PortinagreementComponent,
        PortinblanksimComponent,
        PortinpersonalimgComponent,
        PortinpersonalinfoComponent,
        PortincusimgComponent,
        SellingagreementComponent,
        PromptComponent,
        ComplaintCenterComponent,
        ModaltouristpayComponent,
        TouristComponent,
        // PaymentComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        HammerModule,
        AppRoutingModule,
        MatNativeDateModule,
        RouterModule.forRoot(router),
        BrowserAnimationsModule,
        MDBBootstrapModule.forRoot(),
        GoogleMapsModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        QRCodeModule,
        AngularMyDatePickerModule,
        TextareaAutosizeModule,
        // PdfViewerModule,
        NgxMaskModule.forRoot(),
        FacebookModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgSelectModule,
        NgScrollbarModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 60,
            space: -10,
            maxPercent: 100,
            outerStrokeGradient: true,
            outerStrokeWidth: 10,
            outerStrokeColor: '#4882c2',
            outerStrokeGradientStopColor: '#60F913',
            innerStrokeColor: '#e7e8ea',
            innerStrokeWidth: 10,
            title: 'UI',
            animateTitle: true,
            animationDuration: 1000,
            showUnits: false,
            showBackground: false,
            clockwise: false,
            responsive: true,
            startFromZero: true,
            showSubtitle: false
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializer,
        //     deps: [PwaService],
        //     multi: true
        // },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
