<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-8 p-0">
            <div class="col-12 p-0">
                <div class="text-center upload-img">
                    <img [className]="'d-example'+exampleimageClass" (click)="file_simcard.click()"
                        src="assets/img/banner/SIMcardfront.png">
                    <div [className]="'preview'+previewimageClass">
                        <img id="peviewidcart" (click)="file_simcard.click()" [src]="peviewphone">
                    </div>
                    <p class="mt-3">ถ่ายภาพเบอร์ที่ต้องการลงทะเบียน</p>
                    <input class="d-none" type="file" #file_simcard (click)="file_simcard.value = null"
                        (change)="importFile($event)" accept="image/*" capture>
                </div>
            </div>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="nextPage()" [disabled]="disable"
                class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ถัดไป</button>
        </div>
    </div>
</main>