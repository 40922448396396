import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  displayNavbar: string;

  constructor(private modal: ModalService, private facebookService: FacebookService) { }

  ngOnInit(): void {
    this.displayNavbar = '1';
    this.initFacebookService();
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }

  toggleNavbar() {

    if (this.displayNavbar == '0') {
      this.displayNavbar = '1';
      //  alert(this.displayNavbar);
    } if (this.displayNavbar == '1') {
      //    alert("1 - Changing to 0");
      this.displayNavbar = '0';
    } else {
      this.displayNavbar = '1';
    }
  }
  onMenu() {
    this.modal.menu.next(true)
  }


}
