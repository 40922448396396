import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-portincusimg',
    templateUrl: './portincusimg.component.html',
    styleUrls: ['./portincusimg.component.scss']
})
export class PortincusimgComponent implements OnInit {
    exampleimageClass: string = '';
    previewimageClass: string = ' d-none';
    peviewpersonal: any;
    disable: boolean = true;
    retry = 0;

    // personalbase64: string;

    p_img = 10;
    rotation_img = 0;

    constructor(private register: RegisterService) { }

    ngOnInit(): void {
    }

    importFile(event) {
        if (event.target.files.length == 0) {
            console.log("No file selected!");
            return
        }
        let file: File = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.exampleimageClass = ' d-none';
                this.previewimageClass = '';
                this.peviewpersonal = reader.result
            };

            reader.readAsDataURL(file);
            this.disable = false;
            // this.file_phone = this.$refs.file_phone.files[0];
            // this.phoneview = false;
        }
    }

    rotateImg() {
        var img = document.getElementById('peviewpersonal');
        var maring = (img.clientWidth - img.clientHeight) / 2;
        if (maring > 0) {
            this.p_img = maring;
        }
        if (this.rotation_img > 360) {
            this.rotation_img = 0;
        }
        this.rotation_img += 90;
        img.setAttribute("style", "transform: rotate(" + this.rotation_img.toString() + "deg);");
    }

    next() {
        this.register.pageMove.next('done');
        // this.register.loading.next(true);
    }

}
