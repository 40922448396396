import { Component, OnInit, Renderer2 } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-simcard',
  templateUrl: './simcard.component.html',
  styleUrls: ['./simcard.component.scss'],
})
export class SimcardComponent implements OnInit {
  peviewphone: any;
  exampleimageClass: string = '';
  previewimageClass: string = ' d-none';
  disable: boolean = true;

  constructor(private renderer: Renderer2, private register: RegisterService) {}

  ngOnInit(): void {}

  importFile(event) {
    if (event.target.files.length == 0) {
      // console.log("No file selected!");
      return;
    }
    let file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.exampleimageClass = ' d-none';
        this.previewimageClass = '';
        this.peviewphone = reader.result;
      };

      reader.readAsDataURL(file);
      this.disable = false;
    }
  }

  checksimcard(base64) {
    this.register.checkphonumber(base64).subscribe(
      (res: any) => {
        let json_res = JSON.parse(res);
        this.register.loading.next(false);
        // console.log(json_res.responsecode);
        if (json_res.responsecode === 0) {
          this.register.phonenumber = json_res.phonenumber;
          this.register.iccid = json_res.iccid;
          this.register.page.next('detail');
        } else {
          if (json_res.responsecode === 101) {
            Swal.fire({
              title: 'ผิดพลาด!',
              html: '<div class="alert-text">ไม่สามารถอ่านข้อมูลจากรูปได้ กรุณาลองใหม่อีกครั้ง</div>',
              icon: 'warning',
              confirmButtonColor: '#28a745',
              confirmButtonText: 'ตกลง',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.exampleimageClass = '';
                this.previewimageClass = ' d-none';
                this.peviewphone = null;
                this.disable = true;
              }
            });
          } else if (json_res.responsecode === 102) {
            Swal.fire({
              title: 'ผิดพลาด!',
              html: '<div class="alert-text">ไม่พบหมายเลขที่ต้องการลงทะเบียน กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่</div>',
              icon: 'warning',
              confirmButtonColor: '#28a745',
              confirmButtonText: 'ตกลง',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((result) => {});
          } else if (json_res.responsecode === 104) {
            Swal.fire({
              title: 'ผิดพลาด!',
              html: '<div class="alert-text">หมายเลขที่ต้องการลงทะเบียนอยู่ระหว่างรอการตรวจสอบ กรุณารอเจ้าหน้าที่ติดต่อไป</div>',
              icon: 'warning',
              confirmButtonColor: '#28a745',
              confirmButtonText: 'ตกลง',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((result) => {});
          } else if (json_res.responsecode === 105) {
            Swal.fire({
              title: 'ผิดพลาด!',
              html: '<div class="alert-text">หมายเลขที่ต้องการลงทะเบียนหมดเขตการลงทะเบียนตามเงื่อนไขแล้ว กรุณาติดต่อเจ้าหน้าที่</div>',
              icon: 'warning',
              confirmButtonColor: '#28a745',
              confirmButtonText: 'ตกลง',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((result) => {});
          } else {
            Swal.fire({
              title: 'ลงทะเบียนสำเร็จแล้ว',
              html: '<div class="alert-text">หมายเลขที่ต้องการลงทะเบียนถูกลงทะเบียนไปแล้ว กรุณาติดต่อเจ้าหน้าที่</div>',
              icon: 'info',
              confirmButtonColor: '#28a745',
              confirmButtonText: 'ตกลง',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((result) => {});
          }
        }
      },
      (err: any) => {
        console.log('HTTP Error', err);
        // let json_error = JSON.parse(err);
        this.register.loading.next(false);
        Swal.fire({
          title: 'ระบบมีปัญหา!',
          html:
            '<div class="alert-text">ระบบมีปัญหา ' +
            err.status +
            ' ' +
            err.statusText +
            ' ' +
            err.error +
            ' กรุณาติดต่อเจ้าหน้าที่</div>',
          icon: 'error',
          confirmButtonColor: '#28a745',
          confirmButtonText: 'ตกลง',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then((result) => {});
      }
    );
  }

  nextPage() {
    this.register.simcardbase64 = this.peviewphone.split(',').pop();
    this.register.loading.next(true);
    this.checksimcard(this.register.simcardbase64);
  }
}
