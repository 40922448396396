import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OnlinesimService {
    private API_URL_PROD = environment.API_URL_PROD;
    public page: Subject<string> = new Subject<string>();
    public loading: Subject<boolean> = new Subject<boolean>();
    public errormessage: Subject<string> = new Subject<string>();
    public phonenumber: Subject<string> = new Subject<string>();
    public package: Subject<string> = new Subject<string>();
    public personal: Subject<string> = new Subject<string>();

    public price = 0;
    public summarydetail: Array<Object> = [];

    public numberproductid: string;
    public numberoriginprice: string;
    public numbertaxprice: string;

    public selectednumber: string;
    public selectedPackageObject: Object;
    public selectedPackageOriginPrice: string;
    public selectedPackagePrice: string;
    public selectedPackageCode: string;
    public selectedPackageProductid: string;

    public firstname: string;
    public lastname: string;
    public contactnumber: string;
    public email: string;
    public personaltype: string;
    public personalid: string;
    public birthday: string;

    public housenumber: string;
    public housename: string;
    public province: string;
    public district: string;
    public subdistrict: string;
    public zipcode: string;

    public paymentchannel: string;
    public ordernumber: string;
    public qrcode: string;
    public expireorder: string;

    constructor(private http: HttpClient) { }

    getPhonenumber() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/searchnumber'
        let body = {}
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    filterNumber(keyword: string, searchby: string) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/searchnumber'
        let body = JSON.stringify({ keyword, searchby });
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    getPackage() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/onlinesimpackages'
        let body = JSON.stringify({});
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    bookNumber(phonenumber: string) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/booknumber'
        let body = JSON.stringify({
            phonenumber
        });
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    orderNumber(data) {
        console.log(data);
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = this.API_URL_PROD + '/feelswebapp/ordernumbernew'
        let body = JSON.stringify(data);
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }
}
