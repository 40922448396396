import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalpayondemandComponent } from './modalpayondemand/modalpayondemand.component';

import { PackageService } from '../../services/package.service'

declare var $: any;

@Component({
    selector: 'app-ondemandpackage',
    templateUrl: './ondemandpackage.component.html',
    styleUrls: ['./ondemandpackage.component.scss']
})
export class OndemandpackageComponent implements OnInit {
    // @ViewChild("dataspeed") dataspeed: ElementRef;
    // @ViewChild("datavolumn") datavolumn: ElementRef;
    // @ViewChild("metervolumn") metervolumn: ElementRef;
    public data: JSON = <JSON><unknown>{ "data": "0GB", "speed": "0Mbps", "call": "0min", "duration": "7Days" }
    public dataDisplay: JSON = <JSON><unknown>{ "data": "0 GB", "speed": "0 Mbps", "call": "0 นาที", "duration": "7 วัน" }
    public dataW: JSON = <JSON><unknown>{ "data": 25, "speed": 25, "call": 25, "duration": 33 }
    public phonecallbar: number = 1
    public durationbar: number = 1
    public speedbar: number = 1
    public databar: number = 1
    priceError: boolean = false
    modalRef: MDBModalRef;
    howtoslide: string = '';
    public packagePrice: number = 0
    public selectedindex: number = 0; //Initialized to 0
    public packages: JSON;
    public packagesres: any
    public speed2: string[] = []
    public data2: Number[] = []
    public boxX: JSON;
    public duration2: string[] = []
    public call: string[] = []
    public unlimited: string[] = []
    public myRadio: string = ''
    public packagecode: string;
    public images: string[] = [
        "assets/img/bg/bg1.png",
        "assets/img/bg/bg2.png"
    ];

    constructor(private modalService: MDBModalService, private pack: PackageService, private _router: Router) {
        this.pack.getPackages('custom').subscribe((res: any) => {
            this.packagesres = JSON.parse(res + '')['packagelist']
            var data2: Number[] = []
            for (const [id, detail] of Object.entries(this.packagesres)) {
                var speed: string = Number(detail['dataspeedamount']) + "Mbps"
                var data: number = Number(detail['datavolumn'])
                var duration: string = String(detail['duration']) + "Days"
                var call: string = Number(detail['voiceamount']) + "min"
                var unlimited: string = String(detail['dataspeedtype'])
                this.speed2.push(speed)
                data2.push(data)
                this.call.push(call)
                this.duration2.push(duration)
                this.unlimited.push(unlimited)
            }
            this.data2 = data2
            if (this.unlimited.find(x => x == "FullSpeed") !== undefined) {
                this.speed2.push("FullSpeed")
            }
            this.speed2 = Array.from(new Set(this.speed2))
            this.data2 = Array.from(new Set(this.data2)).sort((a: number, b: number) => a - b)
            this.duration2 = Array.from(new Set(this.duration2))
            this.call = Array.from(new Set(this.call))
            this.boxX = <JSON><unknown>{
                "speed": this.speed2.sort(),
                "data": this.data2,
                "duration": this.duration2,
                "call": this.call
            }
            this.phonecallbar = Number(this.boxX['call'].length)
            this.durationbar = Number(this.boxX['duration'].length)
            this.speedbar = Number(this.boxX['speed'].length)
            this.databar = Number(this.boxX['data'].length)
            $("#slider-circle-1").roundSlider({
              radius: 90,
              circleShape: "pie",
              sliderType: "min-range",
              showTooltip: false,
              value: 0,
              width: 12,
              step: 100 / (this.databar - 1),
              handleSize: "+8",
              startAngle: 315,
              beforeCreate: "traceEvent",
              create: "traceEvent",
              start: "traceEvent",
              stop: "traceEvent",
              change: (event, ui) => {
                  const value = Number((event.value / (100 / (this.databar - 1))).toFixed(0)) + 1
                  this.changeMeter(event.value, 1)
                  this.onSlider(value, 'data', 1,event.value)
              },
              drag: (event) => {
                  this.changeMeter(event.value, 1)
              }
          });
          $("#slider-circle-2").roundSlider({
              radius: 90,
              circleShape: "pie",
              sliderType: "min-range",
              showTooltip: false,
              value: 0,
              disabled:true,
              width: 12,
              step: 100 / (this.speedbar - 1),
              handleSize: "+8",
              startAngle: 315,
              beforeCreate: "traceEvent",
              create: "traceEvent",
              start: "traceEvent",
              stop: "traceEvent",
              change: (event, ui) => {
                  const value = Number((event.value / (100 / (this.speedbar - 1))).toFixed(0)) + 1
                  this.changeMeter(event.value, 2)
                  this.onSlider(value, 'speed', 2,event.value)
              },
              drag: (event) => {
                  this.changeMeter(event.value, 2)
              }
          });
          $("#slider-circle-3").roundSlider({
              radius: 90,
              circleShape: "pie",
              sliderType: "min-range",
              showTooltip: false,
              value: 0,
              width: 12,
              step: 100 / (this.phonecallbar - 1),
              handleSize: "+8",
              startAngle: 315,
              beforeCreate: "traceEvent",
              create: "traceEvent",
              start: "traceEvent",
              stop: "traceEvent",
              change: (event, ui) => {
                  const value = Number((event.value / (100 / (this.phonecallbar - 1))).toFixed(0)) + 1
                  this.changeMeter(event.value, 3)
                  this.onSlider(value, 'call', 3,event.value)
              },
              drag: (event) => {
                  this.changeMeter(event.value, 3)
              }
          });
          $("#slider-circle-4").roundSlider({
              radius: 90,
              circleShape: "half-top",
              sliderType: "min-range",
              showTooltip: false,
              value: 0,
              width: 12,
              step: 100 / (this.durationbar - 1),
              handleSize: "+8",
              // startAngle: 315,
              beforeCreate: "traceEvent",
              create: "traceEvent",
              start: "traceEvent",
              stop: "traceEvent",
              change: (event, ui) => {
                  const value = Number((event.value / (100 / (this.durationbar - 1))).toFixed(0)) + 1
                  this.changeMeter(event.value, 4)
                  this.onSlider(value, 'duration', 4,event.value)
              },
              drag: (event) => {
                  this.changeMeter(event.value, 4)
              }
          });
            //console.log(Object.values(this.packages).find(({ packageid }) => packageid === '1440'))
        });
    }

    selectImage(index: number) {
        this.selectedindex = index;
    }

    // onKey(event, meter: number) {
    //     console.log(event.target.value, event.target.attributes.id.value, meter)
    //     this.howtoslide = ' animated fadeOut';
    //     const inputValue = event.target.value;
    //     const inputId = event.target.attributes.id.value;
    //     this.data[inputId] = this.boxX[inputId][inputValue - 1]
    //     if (inputId === "data") {
    //         // console.log('test')
    //         // console.log(inputValue)
    //         this.data[inputId] = this.boxX[inputId][inputValue - 1] + "GB";
    //         if (inputValue === '1') {
    //             this.data['speed'] = '0Mbps';
    //             this.dataspeed.nativeElement.value = '1';
    //             this.dataW['speed'] = 1 * (100 / this.boxX['speed'].length);
    //         } else {
    //             if (this.dataspeed.nativeElement.value === '1') {
    //                 this.data['speed'] = '1Mbps';
    //                 this.dataspeed.nativeElement.value = '2';
    //                 this.dataW['speed'] = 2 * (100 / this.boxX['speed'].length);
    //             }
    //         }
    //     }
    //     if (inputId === "speed") {
    //         if (inputValue === '1') {
    //             this.data['data'] = '0GB';
    //             this.datavolumn.nativeElement.value = '1';
    //             this.dataW['data'] = 1 * (100 / this.boxX['speed'].length);
    //         }
    //     }
    //     this.dataW[inputId] = inputValue * (100 / this.boxX[inputId].length)
    //     var unlimited: string = "Speed"
    //     var dataSpeedUnit: string = "Mbps"
    //     if (this.data['speed'] == "0Mbps" || this.data['data'] == "0GB") {
    //         if (this.data['call'] == "0min") { this.packagePrice = 0 }
    //         else if (this.data['speed'] == "FullSpeed") {
    //             var obj: any = Object.values(this.packagesres)
    //                 .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
    //                     Number(dataspeedamount) + dataSpeedUnit === "0Mbps" && Number(datavolumn) + "GB" === "0GB"
    //                     && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
    //             this.packagePrice = Number(obj['total_net'])
    //             this.packagecode = obj['packagecode']
    //         }
    //         else {
    //             var obj: any = Object.values(this.packagesres)
    //                 .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
    //                     Number(dataspeedamount) + dataSpeedUnit === "0Mbps" && Number(datavolumn) + "GB" === "0GB"
    //                     && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
    //             this.packagePrice = Number(obj['total_net'])
    //             this.packagecode = obj['packagecode']
    //         }
    //     }
    //     else {
    //         if (this.data['speed'] == "FullSpeed") {
    //             var obj: any = Object.values(this.packagesres)
    //                 .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
    //                     Number(datavolumn) + "GB" === this.data['data']
    //                     && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call']
    //                     && dataspeedtype === "FullSpeed")
    //             this.packagePrice = Number(obj['total_net'])
    //             this.packagecode = obj['packagecode']
    //         } else {
    //             var obj: any = Object.values(this.packagesres)
    //                 .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
    //                     Number(dataspeedamount) + dataSpeedUnit === this.data['speed'] && Number(datavolumn) + "GB" === this.data['data']
    //                     && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
    //             this.packagePrice = Number(obj['total_net'])
    //             this.packagecode = obj['packagecode']
    //         }
    //     }
    //     this.dataDisplay['data'] = this.data['data'].replace('GB', ' GB');
    //     if (this.data['speed'] === 'FullSpeed') {
    //         this.dataDisplay['speed'] = this.data['speed'].replace('FullSpeed', 'เร็วเต็มสปีด');
    //     } else {
    //         this.dataDisplay['speed'] = this.data['speed'].replace('Mbps', ' Mbps');
    //     }
    //     this.dataDisplay['call'] = this.data['call'].replace('min', ' นาที');
    //     this.dataDisplay['duration'] = this.data['duration'].replace('Days', ' วัน');
    //     var lenMeter: any;
    //     if (meter === 1) {
    //         lenMeter = this.databar - 1
    //     } else if (meter === 2) {
    //         lenMeter = this.speedbar - 1
    //     } else if (meter === 3) {
    //         lenMeter = this.phonecallbar - 1
    //     } else {
    //         lenMeter = this.durationbar - 1
    //     }
    //     document.getElementById('meter' + meter).style.transform = 'rotate(' + ((((inputValue - 1) / lenMeter) * 315) - 85) + 'deg)'
    //     // document.getElementById('bottle' + meter).style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * (100 / lenMeter))) + '%, 100% ' + (100 - ((inputValue - 1) * (100 / lenMeter))) + '%, 100% 100%, 0% 100%)'
    // }

    onSelect() {
        if (this.packagePrice > 0) {
            console.log(this.dataDisplay)
            this.priceError = false
            this.pack.selectPackCode = this.packagecode
            this.pack.selectPackPrice = this.packagePrice + ''
            this.pack.dataPack = this.dataDisplay
            this.modalRef = this.modalService.show(ModalpayondemandComponent,
                { class: 'modal-dialog-scrollable modal-dialog-centered' }
            )
        } else {
            this.priceError = true
        }
    }

    ngOnInit(): void {
    }
    onTest(event) {
        const inputValue = event.target.value;
        const inputId = event.target.attributes.id.value;
        if (inputId === 'metervolumn1') {
            document.getElementById('meter1').style.transform = 'rotate(' + ((((inputValue - 1) / 4) * 315) - 85) + 'deg)'
            document.getElementById('bottle4').style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * 25)) + '%, 100% ' + (100 - ((inputValue - 1) * 25)) + '%, 100% 100%, 0% 100%)'
        } else if (inputId === 'metervolumn2') {
            document.getElementById('meter2').style.transform = 'rotate(' + ((((inputValue - 1) / 4) * 315) - 85) + 'deg)'
            document.getElementById('bottle2').style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * 25)) + '%, 100% ' + (100 - ((inputValue - 1) * 25)) + '%, 100% 100%, 0% 100%)'
        } else if (inputId === 'metervolumn3') {
            document.getElementById('meter3').style.transform = 'rotate(' + ((((inputValue - 1) / 4) * 315) - 85) + 'deg)'
            document.getElementById('bottle3').style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * 25)) + '%, 100% ' + (100 - ((inputValue - 1) * 25)) + '%, 100% 100%, 0% 100%)'
        } else {
            document.getElementById('meter4').style.transform = 'rotate(' + ((((inputValue - 1) / 4) * 315) - 85) + 'deg)'
            document.getElementById('bottle1').style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * 25)) + '%, 100% ' + (100 - ((inputValue - 1) * 25)) + '%, 100% 100%, 0% 100%)'
        }

    }
    onSlider(value: number, id: string, meter: number, eventValue: number) {
        this.howtoslide = ' animated fadeOut';
        const inputValue = value;
        const inputId = id;
        const inputMeter = eventValue;
        this.data[inputId] = this.boxX[inputId][inputValue - 1]
        const circle2 = Number(($("#slider-circle-2").roundSlider('getValue') / (100 / (this.durationbar - 1))).toFixed(0)) + 1
        if (inputId === "data") {
            // console.log('test')
            // console.log(inputValue)
            this.data[inputId] = this.boxX[inputId][inputValue - 1] + "GB";
            if (inputValue === 1) {
                this.data['speed'] = '0Mbps';
                // this.dataspeed.nativeElement.value = '1';
                $("#slider-circle-2").roundSlider('setValue', 0);
                this.changeMeter(0,2);
                this.dataW['speed'] = 1 * (100 / this.boxX['speed'].length);
            } else {
                if (circle2 === 1) {
                    this.data['speed'] = '1Mbps';
                    $("#slider-circle-2").roundSlider('setValue', 1 * (100 / this.speedbar));
                    this.changeMeter(1 * (100 / (this.speedbar - 1)), 2)
                    // this.dataspeed.nativeElement.value = '2';
                    this.dataW['speed'] = 2 * (100 / this.boxX['speed'].length);
                }
            }
        }
        if (inputId === "speed") {
            if (inputValue === 1) {
                this.data['data'] = '0GB';
                $("#slider-circle-1").roundSlider('setValue', 0);
                this.changeMeter(0,1);
                // this.datavolumn.nativeElement.value = '1';
                this.dataW['data'] = 1 * (100 / this.boxX['speed'].length);
            }
        }
        this.dataW[inputId] = inputValue * (100 / this.boxX[inputId].length)
        var unlimited: string = "Speed"
        var dataSpeedUnit: string = "Mbps"
        if (this.data['speed'] == "0Mbps" || this.data['data'] == "0GB") {
            if (this.data['call'] == "0min") { this.packagePrice = 0 }
            else if (this.data['speed'] == "FullSpeed") {
                var obj: any = Object.values(this.packagesres)
                    .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
                        Number(dataspeedamount) + dataSpeedUnit === "0Mbps" && Number(datavolumn) + "GB" === "0GB"
                        && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
                this.packagePrice = Number(obj['total_net'])
                this.packagecode = obj['packagecode']
            }
            else {
                var obj: any = Object.values(this.packagesres)
                    .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
                        Number(dataspeedamount) + dataSpeedUnit === "0Mbps" && Number(datavolumn) + "GB" === "0GB"
                        && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
                this.packagePrice = Number(obj['total_net'])
                this.packagecode = obj['packagecode']
            }
        }
        else {
            if (this.data['speed'] == "FullSpeed") {
                var obj: any = Object.values(this.packagesres)
                    .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
                        Number(datavolumn) + "GB" === this.data['data']
                        && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call']
                        && dataspeedtype === "FullSpeed")
                this.packagePrice = Number(obj['total_net'])
                this.packagecode = obj['packagecode']
            } else {
                var obj: any = Object.values(this.packagesres)
                    .find(({ dataspeedamount, datavolumn, duration, voiceamount, dataspeedtype }) =>
                        Number(dataspeedamount) + dataSpeedUnit === this.data['speed'] && Number(datavolumn) + "GB" === this.data['data']
                        && Number(duration) + "Days" === this.data['duration'] && Number(voiceamount) + "min" === this.data['call'])
                this.packagePrice = Number(obj['total_net'])
                this.packagecode = obj['packagecode']
            }
        }
        this.dataDisplay['data'] = this.data['data'].replace('GB', ' GB');
        if (this.data['speed'] === 'FullSpeed') {
            this.dataDisplay['speed'] = this.data['speed'].replace('FullSpeed', 'เร็วเต็มสปีด');
        } else {
            this.dataDisplay['speed'] = this.data['speed'].replace('Mbps', ' Mbps');
        }
        this.dataDisplay['call'] = this.data['call'].replace('min', ' นาที');
        this.dataDisplay['duration'] = this.data['duration'].replace('Days', ' วัน');
        var lenMeter: any;
        if (meter === 1) {
            lenMeter = this.databar - 1
        } else if (meter === 2) {
            lenMeter = this.speedbar - 1
        } else if (meter === 3) {
            lenMeter = this.phonecallbar - 1
        } else {
            lenMeter = this.durationbar - 1
        }
        // document.getElementById('meter' + meter).style.transform = 'rotate(' + ((((inputValue - 1) / lenMeter) * 270) - 65) + 'deg)'
        if (this.data['data'] === '0GB') {
            $("#slider-circle-2").roundSlider("disable");
            $("#slider-circle-1").roundSlider('setValue', 0);
            $("#slider-circle-2").roundSlider('setValue', 0);
            this.changeMeter(0,2);
        } else {
            $("#slider-circle-2").roundSlider("enable");
        }
        // document.getElementById('bottle' + meter).style.clipPath = 'polygon(0 ' + (100 - ((inputValue - 1) * (100 / lenMeter))) + '%, 100% ' + (100 - ((inputValue - 1) * (100 / lenMeter))) + '%, 100% 100%, 0% 100%)'
    }
    changeMeter(value: number, meter: number) {
        const inputValue = value;
        var start:number = 65;
        var total:number = 270;
        if(meter === 4){
          start = 18;
          total = 178;
        }
        document.getElementById('meter' + meter).style.transform = 'rotate(' + (((inputValue / 100) * total) - start) + 'deg)'
    }

}
