<main>
    <div class="banner">
    </div>
    <div class="text-about rounded-top-3">
        <div class="detail-sec p-3 p-lg-4">
            <div class="slide-show">
                <mdb-carousel [isControls]="true" [animation]="'slide'">
                    <mdb-slide *ngFor="let slide of product['pic']; let index=index">
                        <img class="d-block w-100 slide-pic" [src]="slide">
                    </mdb-slide>
                </mdb-carousel>
            </div>
            <div class="product-detail mt-3 mt-lg-2">
                <p class="header">{{productModel}}</p>
                <div class="menu button-content pt-3 pb-3">
                    <div class="rounded-pill grey lighten-4" style="display: flex;">
                        <div [className]="'sub-menu rounded-pill transition-2 pt-2 pb-2'+activeTab[0]"
                            (click)="onTab(0)">
                            <p class="m-0 cursor-on">ข้อมูลทั่วไป</p>
                        </div>
                        <div [className]="'sub-menu rounded-pill transition-2 pt-2 pb-2'+activeTab[1]"
                            (click)="onTab(1)">
                            <p class="m-0 cursor-on">ข้อมูลตัวเครื่อง</p>
                        </div>
                    </div>
                </div>
                <div class="content" *ngIf="activeTab[0] !== ''">
                    <p *ngIf="product['intro'] != '-'">
                        {{product['intro']}}
                    </p>
                    <h1 *ngIf="product['attribute'].length > 0" class="text-light-green-main">คุณสมบัติ</h1>
                    <p *ngFor="let text of product['attribute']; let index=index">
                        &nbsp;&nbsp;• {{text}}<br>
                    </p>
                </div>
                <div class="content" *ngIf="activeTab[1] !== ''">
                    <h1 class="text-light-green-main">ข้อมูลตัวเครื่อง</h1>
                    <div *ngFor="let text of product['spec']; let index=index">
                        <p class="d-block" *ngIf="(text !== '-') && (text !== '') && (titleSpec[index] !== 'ราคา')">
                            &nbsp;&nbsp;<span class="font-weight-normal">{{titleSpec[index]}}</span> {{text}}
                        </p>
                    </div>
                </div>
                <div class="mb-4">
                    <h1 class="text-light-green-main">
                        <small class="elegant-color-text">ราคา</small> {{product['price']}}
                        <small class="elegant-color-text">บาท</small>
                    </h1>
                </div>
                <div class="w-100 bg-soft-green cursor-on rounded-lg p-4 text-center row m-0 justify-content-center align-items-center"
                    (click)="onFeelsSupport()">
                    <div class="d-none d-lg-block">
                        <div class="d-flex align-items-center col-12 justify-content-center">
                            <h1 class="text-white m-0">สนใจติดต่อไลน์ของเรา</h1>
                            <i class="fab fa-line text-white fa-4x mr-2 ml-2"></i>
                            <i class="far fa-hand-point-left text-white fa-2x ml-1 mr-2"></i>
                            <h2 class="text-white m-0">
                                <u>คลิก</u>
                            </h2>
                        </div>
                    </div>
                    <div class="d-block d-lg-none">
                        <div class="d-flex align-items-center col-12 justify-content-center">
                            <h2 class="text-white m-0">สนใจติดต่อไลน์ของเรา</h2>
                            <i class="fab fa-line text-white fa-3x mr-2 ml-2"></i>
                            <i class="far fa-hand-point-left text-white fa-1x ml-1 mr-2"></i>
                            <h3 class="text-white m-0">
                                <u>คลิก</u>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>