import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service'

@Component({
  selector: 'app-agreementmove',
  templateUrl: './agreementmove.component.html',
  styleUrls: ['./agreementmove.component.scss']
})
export class AgreementmoveComponent implements OnInit {

  constructor(private register: RegisterService) { }

  ngOnInit(): void {
  }
  onSent() {
    this.register.pageMove.next('detail')
  }

}
