<main>
    <div class="banner d-flex justify-content-center align-items-center">
        <h1 class="text-white fs-50 line-h-100 text-center">เครือข่ายและคลื่นความถี่</h1>
    </div>
    <div class="text-about row m-0 mt-3 justify-content-center">
        <div class="col-lg-8 col-12 rounded-top-3 white pt-5 pb-3">
            <h1 class="fs-50 text-light-green-main">เครือข่ายและคลื่นความถี่</h1>
            <p>บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด เป็นผู้ใช้บริการโทรศัพท์เคลื่อนที่แบบ MVNO (Mobile Virtual Network
                Operator) บนโครงข่ายที่กว้างด้วยเสาสัญญาณกว่า 75,000 ต้น ครอบคลุมทั่วประเทศของผู้ให้บริการ MNO (Mobile
                Network Operator) บริษัท โทรคมนาคมแห่งชาติ จำกัด (มหาชน)ให้บริการ 3G บนคลื่นความถี่ LTE – FDD 2100MHz
                และให้บริการ
                4G
                บนคลื่นความถี่ LTE – TDD 2300MHz รองรับความต้องการของ ผู้ใช้บริการทั่วประเทศ
            </p>
            <p>
                การให้บริการ 4G บนคลื่นความถี่ 2300MHz ด้วยเทคโนโลยี 4G LTE-TDD บนแบนด์วิดท์ที่กว้างถึง 60 MHz (2310 –
                2370
                MHz) ซึ่งเทคโนโลยีดังกล่าวสามารถรองรับความต้องการการใช้งานด้านดาต้า ที่ เร็ว แรง
                ส่งเสริมประสบการณ์ไปอีกขั้นของการใช้งานในด้านต่างๆ ไม่ว่าจะเป็นโมบายดาต้า หรือการใช้งาน อุปกรณ์ IoT
                ต่างๆ
                ให้ใช้งานได้อย่างต่อเนื่อง
            </p>
            <p>
                เทคโนโลยี 4G LTE (4th Generation Long Term Evolution) มีหลักการทำงาน 2 แบบนั้นก็คือ LTE-FDD (Frequency
                Division Duplex) และ LTE-TDD (Time Division Duplex) ซึ่ง Feels ให้บริการทั้ง 2 รูปแบบ
                บนคลื่นความถี่ที่แตกต่างกัน รองรับการใช้งานที่หลากหลาย การทำงานของระบบ LTE-FDD จะแตกต่างจากการทำงาน แบบ
                LTE-TDD โดยหลักการของ LTE-FDD จะแบ่งการรับส่งคลื่น (Uplink -Downlink) ออกเป็นช่วงความถี่ทำให้
                การรับส่งข้อมูลสามารถทำในเวลาเดียวกันได้ ส่วนการทำงานของ LTE-TDD
                จะแบ่งช่วงเวลาการรับส่งข้อมูลทำให้การทำงาน
                มีความยืดหยุ่นกว่าในยามที่มีผู้ใช้บริการจำนวนมาก
            </p>
            <hr style="margin-left: 20%; margin-right: 20%;">
            <a class="cursor-off">Feel the limitless mobile freedom</a><br>
            <a style="font-weight: 200;" class="cursor-off">บริษัท ฟีล เทเลคอม คอร์ปอเรชั่น จำกัด</a><br>
            <input type="submit" class="btn bg-soft-green text-white rounded-pill mt-3" value="Feel Now"
                [routerLink]="['/packages']">
        </div>
    </div>
</main>