<div class="camera-capture">
    <div class="g-image">
        <img src="./assets/who_is.png" />
    </div>

    <video *ngIf="enabled" #video id="video" width="640" height="480" autoplay></video>
    <canvas #canvas id="canvas" width="640" height="480"></canvas>

    <div class="g-button">
        <button class="black d-none d-lg-block" (click)="executeButtonAction()">{{ getButtonName() }}</button>
        <button class="black d-lg-none" (click)="mobileInput.click()">{{ getMobileName() }}</button>
    </div>

    <div class="mobile-capture">
        <input #mobileInput type="file" accept="image/*" id="capture" capture="camera"
            (change)="onFileSelected($event)" />
    </div>
</div>