import { Component, OnInit, ViewChild } from '@angular/core';
import { OnlinesimService } from '../../../services/onlinesim.service';
import { QRCodeComponent } from 'angularx-qrcode';

@Component({
    selector: 'app-onlinesimpayment',
    templateUrl: './onlinesimpayment.component.html',
    styleUrls: ['./onlinesimpayment.component.scss']
})
export class OnlinesimpaymentComponent implements OnInit {
    @ViewChild("save", { static: true }) pRef: QRCodeComponent;
    public qrcode: string = "assets\img\icon\qrcode.svg";
    qrsize: number = 200;
    link: string;

    paymentchannel: string;
    ordernumber: string;
    expireorder: string;

    constructor(private onlinesimService: OnlinesimService) {
        this.qrcode = this.onlinesimService.qrcode;
        this.ordernumber = this.onlinesimService.ordernumber;
        this.expireorder = this.onlinesimService.expireorder;
        this.paymentchannel = this.onlinesimService.paymentchannel;
    }

    ngOnInit(): void {
    }

    download() {
        this.link = this.pRef.qrcElement.nativeElement.firstChild.src;
    }

}
