<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-07.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 36 copy 2.png">
    </div>
    <div class="row m-0 justify-content-center">
        <div class="col-12 col-lg-10 p-0 text-center mt-3">
            <div class="row p-3 p-lg-5 w-100 m-0 white rounded-top-3">
                <div class="col-12 pb-3">
                    <h1 class="fs-50 text-light-green-main">คำถามที่ถามบ่อย</h1>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;" src="../../../assets/img/logo/Feels_Logo_grey.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">Feels คือใคร?</h2>
                            <a>- Feels คือ ใคร?</a><br>
                            <a>- สิ่งที่เรามอบให้คุณ</a><br>
                            <a>- โครงข่ายของเรา</a>
                        </div>
                        <button type="button" (click)="scroll(whoisfeel)"
                            class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></button>
                    </div>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;"
                                src="../../../assets/img/logo/contract, agreement, project, management.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">แพ็กเกจของเรา</h2>
                            <a>- FUP คืออะไร ?</a><br>
                            <a>- แพ็กเกจของเรา</a><br>
                            <a>- แพ็กเกจดาต้า/ เน็ตซิม</a>
                            <a>- ไม่เจอแพ็กเกจที่คุณต้องการ?</a>
                        </div>
                        <button type="button" (click)="scroll(ourplan)"
                            class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></button>
                    </div>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;" src="../../../assets/img/logo/topup.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">ช่องทางการเติมเงิน และการคิดค่าบริการ</h2>
                            <a>- ช่องทางการเติมเงิน</a><br>
                            <a>- การเติมเงินด้วยบัตรเงินสดฟีลพลัส</a><br>
                            <a>- แพ็กเกจหมด คิดค่าบริการอย่างไร</a><br>
                            <a>- วันการใช้งานหมด ทำอย่างไร?</a>
                        </div>
                        <button type="button" (click)="scroll(rateandrecharge)"
                            class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></button>
                    </div>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;" src="../../../assets/img/logo/simcard.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">เริ่มต้นการใช้งานซิม Feels</h2>
                            <a>- การเปิดใช้งานซิม</a><br>
                            <a>- ย้ายค่ายเบอร์เดิม</a>
                        </div>
                        <button type="button" (click)="scroll(firststep)"
                            class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></button>
                    </div>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;" src="../../../assets/img/logo/problem.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">การแก้ไขปัญหาและความช่วยเหลือ</h2>
                            <a>- การแก้ไขปัญหาเมื่อได้รับข้อความ “ปัญหาการเชื่อมต่อหรือรหัส MMI ไม่ถูกต้อง”
                                จากกดสมัครแพ็กเกจ</a><br>
                            <a>- การแก้ไขปัญหาการค้นหาสัญญาณไม่เจอ</a><br>
                            <a>- การแก้ไขปัญหาไม่สามารถใช้อินเทอร์เน็ตได้</a><br>
                            <a>- ตรวจสอบด้วยตัวเองด้วยบริการ USSD</a><br>
                            <a>- ช่องทางขอความช่วยเหลือ Feels</a>
                        </div>
                        <button type="button" (click)="scroll(problemshoot)"
                            class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></button>
                    </div>
                </div>
                <div class="col-12 col-lg-4 p-1">
                    <div class="btn-outline-green-main h-100 rounded-lg p-3">
                        <div class="w-100">
                            <img class="w-25" style="margin: auto;" src="../../../assets/img/logo/callcenter.png">
                        </div>
                        <div class="elegant-color-text">
                            <h2 class="text-light-green-main">ติดต่อ Feels Call Center</h2>
                            <p class="font-weight-cus-bold m-0">Feels Call Center โทร <a class="text-dark text-hover-green" href="tel:021147069">02 114 7069</a></p>
                        </div>
                        <a href="https://lin.ee/mG3Fm30" target="_blank" class="btn bg-soft-green text-white rounded-pill"><small>เพิ่มเติม</small></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10 p-0">
            <div class="text-faq-wrapper p-3 p-lg-5">
                <div class="faq-content">
                    <div class="line-h-100"
                        style="font-size: 35px; font-weight: 500; margin-bottom: 15px; display: inline-block;"
                        #whoisfeel>
                        <div class="square bg-soft-green"></div>Feels คือ ใคร?
                    </div><br>
                    <h2 class="mb-1">Who are we?</h2>
                    - เราคือค่ายโทรศัพท์มือถือ Gen ใหม่
                    พร้อมให้บริการลูกค้าทุกท่านที่ต้องการยกระดับบริการโทรศัพท์เคลื่อนที่ของคุณ<br>
                    <h2 class="mb-1">Our Service</h2>
                    - หมดปัญหากับการจ่ายแพ็กเกจที่เกินความต้องการของคุณด้วยระบบการสร้างแพ็กเกจใน style ของคุณเอง<br>
                    - บริการจำหน่ายดาต้าที่คุณเองสามารถแบ่งปัน mobile data
                    ของคุณไปยังสามชิกในครอบครัวหรือเพื่อนของคุณได้ด้วยบริการซื้อ mobile data เฉพาะที่ Feels
                    เร็วๆนี้<br>
                    <h2 class="mb-1">Our Network</h2>
                    - มั่นใจด้วยโครงข่าย TOT ที่มีเสาสัญญาณถึง 15,700 ต้น ครอบคลุมทุกพื้นที่ เร็ว แรง ทั่วไทย
                </div>
                <div class="faq-content" #ourplan>
                    <div class="line-h-100"
                        style="font-size: 35px; font-weight: 500; margin-bottom: 15px; display: inline-block;" #ourplan>
                        <div class="square bg-soft-green"></div>แพ็กเกจของเรา
                    </div>
                    <br>
                    <h2 class="mb-1">FUP คืออะไร ?</h2>
                    - FUP (Fair Usage Policy)
                    เป็นนโยบายการใช้ดาต้าสากลเมื่อลูกค้าใช้งานดาต้าเกินปริมาณที่กำหนดหรือที่ซื้อไว้
                    ความเร็วในการใช้งานดาต้าจะลดลงตามข้อตกลงของการใช้งานแต่ยังสามารถเล่นดาต้าได้อย่างไม่จำกัดตามแพ็กเกจของคุณ<br>
                    <h2 class="mb-1">แพ็กเกจของเรา</h2>
                    - คุณสามารถเลือกแพ็กเกจ Feels โดนใจ <span [routerLink]="['/packages']"
                        class="text-light-green-main">ได้ที่นี่.</span><br>
                    <h2 class="mb-1">แพ็กเกจดาต้า/ เน็ตซิม</h2>
                    - เน้นเน็ตไม่ใช้โทร เราขอแนะนำเน็ตซิม Feels ที่ให้วันการใช้งานดาต้าของคุณนานเป็น 2
                    เท่าจากแพ็กเกจปกติ
                    หรือต้องการแบ่งปันดาต้าของคุณไปยังเบอร์ Feels อื่นๆ ตามความต้องการ<br>
                    <h2 class="mb-1">ไม่เจอแพ็กเกจที่คุณต้องการ?</h2>
                    ด้วยบริการ Customize Package คุณสามารถเลือกขอบเขตการใช้งานของคุณได้อย่างไม่จำกัด <span
                        [routerLink]="['/ondemandpackage']" class="text-light-green-main">ได้ที่นี่</span>
                </div>
                <div class="faq-content" #rateandrecharge>
                    <div class="line-h-100"
                        style="font-size: 35px; font-weight: 500; margin-bottom: 15px; display: inline-block;">
                        <div class="square bg-soft-green"></div>ช่องทางการเติมเงิน และการคิดค่าบริการ
                    </div><br>
                    <h2 class="mb-1">ช่องทางการเติมเงิน</h2>
                    - เติมเงินผ่านระบบ QR Code ที่รองรับการชำระเงินด้วยแอปพลิเคชันออนไลน์แบงกี้ง, Rabbit Line Pay, Ali
                    Pay,
                    และ WeChat Pay<br>
                    - เติมเงินผ่านตู้เติมเงิน ตู้เติมดี<br>
                    - ติดต่อเจ้าหน้าที่หรือตัวแทนจำหน่ายฟีลพลัสของคุณเพื่อเติมเงิน<br>
                    <h2 class="mb-1">การเติมเงินด้วยบัตรเงินสดฟีลพลัส</h2>
                    เติมเงินง่ายๆ ผ่านบัตรเงินสดฟีลพลัสได้ตามขั้นตอนดังนี้:<br>
                    1. ขูดรหัสเติมเงินเบาๆ เพื่อแสดงรหัสบัตรเติมเงิน<br>
                    2. กดรหัส *4111*รหัสบัตรเติมเงิน# แล้วกดโทรออก<br>
                    3. ยอดเงินจะเพิ่มขึ้นทันที!<br>
                    <h2 class="mb-1">แพ็กเกจหมด คิดค่าบริการอย่างไร</h2>
                    - ถ้าเบอร์ของคุณแพ็กเกจหมดอายุ หรือไม่มีการสมัครแพ็กเกจไว้ ระบบจะคิดค่าบริการตามแพ็กเกจหลัก
                    กดดูข้อมูลแพ็กเกจหลัก <span [routerLink]="['/topup']"
                        class="text-light-green-main cursor-on">ได้ที่นี่.</span><br>
                    <h2 class="mb-1">วันการใช้งานหมด ทำอย่างไร?</h2>
                    - คุณสามารถเติมเงินเข้าเบอร์ของคุณผ่านช่องทางใดก็ได้ ยอดเงินเท่าไรก็ได้ ระบบจะเติมวันการใช้งานของคุณ
                    30
                    วัน นับจากวันที่เติม สะสมสูงสุดไม่เกิน 365 วัน
                </div>
                <div class="faq-content" #firststep>
                    <div class="line-h-100"
                        style="font-size: 35px; font-weight: 500; margin-bottom: 15px; display: inline-block;">
                        <div class="square bg-soft-green"></div>เริ่มต้นการใช้งานซิม Feels
                    </div><br>
                    <h2 class="mb-1">การเปิดใช้งานซิม</h2>
                    - ติดต่อเจ้าหน้าที่ Feels ที่หมายเลข 02-1147069 หรือติดต่อตัวแทนจำหน่ายของท่าน<br>
                    - เตรียมข้อมูลบัตรประชาชนให้พร้อม<br>
                    - ลงทะเบียนซิมของคุณได้ที่ <span [routerLink]="['/register']"
                        class="text-light-green-main cursor-on">ลงทะเบียนออนไลน์</span><br>
                    - เมื่อลงทะเบียนสำเร็จ กดโทรออกเพื่อเริ่มการใช้งาน<br>
                    <h2 class="mb-1">ย้ายค่ายเบอร์เดิม</h2>
                    - เตรียมตัวก่อนย้ายค่าย
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;ลูกค้ารายเดือนต้องเคลียร์ค่าใช้จ่ายจากผู้ให้บริการเดิม
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;ลูกค้ารายเดือนต้องย้ายมาใช้บริการเติมเงินของค่ายเดิม
                    <br>&nbsp;&nbsp;&nbsp;&nbsp;เช็คว่าลูกค้าไม่มีสัญญาผูกมัดกับผู้ให้บริการรายเดิม
                    <br>- กด *151* ตามด้วยเลขบัตรประชาชน 13 หลัก # แล้วโทรออกเพื่อรับรหัสย้ายค่าย
                    (รหัสย้ายค่ายมีอายุภายในวันที่กดเท่านั้น ถ้าหมดอายุกรุณากดขอรับรหัสใหม่)
                    <br>- รอรับ SMS ยืนยันการย้ายค่าย
                    <br>- เมื่อทำการสั่งซื้อและได้รับซิมการ์ดแล้ว เปลี่ยนซิมเพื่อเริ่มการใช้งาน<br>
                </div>
                <div class="faq-content" #problemshoot>
                    <div class="line-h-100"
                        style="font-size: 35px; font-weight: 500; margin-bottom: 15px; display: inline-block;" #ourplan>
                        <div class="square bg-soft-green"></div>การแก้ไขปัญหาและความช่วยเหลือ
                    </div>
                    <br>
                    <h2 class="mb-1">การแก้ไขปัญหาเมื่อได้รับข้อความ “ปัญหาการเชื่อมต่อหรือรหัส MMI ไม่ถูกต้อง”
                        จากกดสมัครแพ็กเกจ</h2>
                    - กรุณารอระบบดำเนินการภายใน 3 นาทีเพื่อรับ SMS หรือติดต่อ Call Center โทร 02-1147069<br>
                    - คู่มือการสมัครแพ็กเกจด้วยรหัส USSD<br>
                    <h2 class="mb-1">การแก้ไขปัญหาการค้นหาสัญญาณไม่เจอ</h2>
                    <span [routerLink]="['/carrieriphone']" class="text-light-green-main cursor-on">-
                        คู่มือการตั้งค่าโหมดเครือข่ายสำหรับระบบปฏิบัติการ iOS</span><br>
                    <span [routerLink]="['/carrier']" class="text-light-green-main cursor-on">-
                        คู่มือการตั้งค่าโหมดเครือข่ายสำหรับระบบปฏิบัติการ Android</span><br>
                    <h2 class="mb-1">การแก้ไขปัญหาไม่สามารถใช้อินเทอร์เน็ตได้</h2>
                    <span [routerLink]="['/apniphone']" class="text-light-green-main cursor-on">- คู่มือการตั้งค่า APN
                        สำหรับระบบปฏิบัติการ iOS</span><br>
                    <span [routerLink]="['/apn']" class="text-light-green-main cursor-on">- คู่มือการตั้งค่า APN
                        สำหรับระบบปฏิบัติการ Android</span><br>
                    <h2 class="mb-1">ตรวจสอบด้วยตัวเองด้วยบริการ USSD</h2>
                    - เช็คยอดเงินและวันคงเหลือ กด *4111# แล้วกดโทรออก<br>
                    - เช็คแพ็กเกจหลัก กด *4112# แล้วกดโทรออก<br>
                    - เช็คแพ็กเกจเสริม กด *4113# แล้วกดโทรออก<br>
                    - เช็คเบอร์ของคุณ กด *4114# แล้วกดโทรออก<br>
                    - ปิดบริการดาต้า กด *4115*0# แล้วกดโทรออก<br>
                    - เปิดบริการดาต้า กด *4115*1# แล้วกดโทรออก<br>
                    <h2 class="mb-1">ช่องทางขอความช่วยเหลือ Feels</h2>
                    <a>- ท่านสามารถติดต่อ Feels Call Center หมายเลข 02-1147069 ได้ตลอด 24 ชั่วโมง</a>
                </div>
            </div>
        </div>
    </div>
</main>