import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { QRCodeComponent } from 'angularx-qrcode';
import { Router } from '@angular/router';
import { RegisterService } from '../../services/register.service'

@Component({
  selector: 'app-movetofeels',
  templateUrl: './movetofeels.component.html',
  styleUrls: ['./movetofeels.component.scss']
})
export class MovetofeelsComponent implements OnInit {
  page: string = 'intro'
  qrSize: number = 200;
  link: string
  public qrcode: string = "assets\img\icon\qrcode.svg";
  errorQr: boolean = false;
  public slides: Array<Object> = [
    { "img": "../../../assets/img/bg/topup.png" },
    { "img": "../../../assets/img/bg/topup2.png" }
  ]

  @ViewChild("save", { static: true }) pRef: QRCodeComponent;
  constructor(private register: RegisterService, private _router: Router) {
    this.register.pageMove.subscribe((value) => {
      if (value !== undefined) {
        this.page = value
      }
    })
  }

  ngOnInit(): void {
  }
  onIntro() {
    this.page = 'agreement'
  }
  onDone() {
    this._router.navigate(['/', 'packages'])
  }
  onClick() {
    this.link = this.pRef.qrcElement.nativeElement.firstChild.src
  }

}
