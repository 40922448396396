import { Component, Renderer2, HostListener } from '@angular/core';
import { ModalService } from './services/modal.service';
import { Router } from '@angular/router';
import * as Hammer from 'hammerjs';
import {
    NgcCookieConsentService,
    NgcInitializeEvent,
    NgcNoCookieLawEvent,
    NgcStatusChangeEvent
} from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
    title = 'angular-feels-webapp';
    onFocus = 6;
    onFocus2 = 6;
    scrollTop = 0;
    hideNav = false;
    screenWidth: number;
    screenHeight: number;
    circleArray: Array<Array<number>> = [
        [ 7, 0, 1 ],
        [ 0, 1, 2 ],
        [ 1, 2, 3 ],
        [ 2, 3, 4 ],
        [ 3, 4, 5 ],
        [ 4, 5, 6 ],
        [ 5, 6, 7 ],
        [ 6, 7, 0 ]
    ];
    menuClass = ' w-100 h-100 left-200';
    menuText: Array<string> = [
        'เติมเงิน',
        'แพ็กเกจ',
        'ลงทะเบียนซิม',
        'ร้านค้า',
        'แพ็กเกจตามใจชอบ',
        'เกี่ยวกับเรา',
        'ซื้อเบอร์ใหม่',
        'ช่วยเหลือ'
    ];
    linkMenu: Array<string> = [
        'topup',
        'packages',
        'register',
        'store',
        'ondemandpackage',
        'about',
        'onlinesim',
        'faq'
    ];
    header: string;
    angle = 0;
    boxWheel: Array<string> = [
        '.fa-apple-alt',
        '.fa-address-book',
        '.fa-ambulance',
        '.fa-allergies',
        '.fa-affiliatetheme',
        '.fa-accusoft',
        '.fa-air-freshener',
        '.fa-accessible-icon'
    ];
    boxMenu: Array<string> = [
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' on-focus-menu text-white',
        ' text-light'
    ];
    boxMenuColor: Array<string> = [
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-light',
        ' text-white',
        ' text-light'
    ];
    togglemenu = true;

    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription = new Subscription();
    private popupCloseSubscription: Subscription = new Subscription();
    private initializeSubscription: Subscription = new Subscription();
    private statusChangeSubscription: Subscription = new Subscription();
    private revokeChoiceSubscription: Subscription = new Subscription();
    private noCookieLawSubscription: Subscription = new Subscription();

    constructor(
        private _router: Router,
        private modal: ModalService,
        private renderer: Renderer2,
        private ccService: NgcCookieConsentService,
        private cookieService: CookieService
    ) {
        this.header = this.menuText[this.onFocus];
        this.modal.menu.subscribe((res) => {
            if (res) {
                this.onClick();
            }
        });
        this.modal.menub.subscribe((res) => {
            if (res) {
                this.onClick();
            }
        });
    }
    onMenu() {
        // this.togglemenu = !this.togglemenu;
        this.modal.menu.next(true);
    }

    rotateImage(direction, isTouch: boolean) {
        let direction2 = 'left';
        if (isTouch) {
            if (this.circleArray[this.onFocus][0] === direction) {
                this.onFocus = this.circleArray[this.onFocus][2];
                direction2 = 'left';
            } else if (this.circleArray[this.onFocus][2] === direction) {
                this.onFocus = this.circleArray[this.onFocus][0];
                direction2 = 'right';
            } else {
                direction2 = 'center';
            }
        } else {
            if (direction == 0) {
                this.onFocus = this.circleArray[this.onFocus][2];
                direction2 = 'left';
            } else {
                this.onFocus = this.circleArray[this.onFocus][0];
                direction2 = 'right';
            }
        }
        if (direction2 !== 'center') {
            this.angle += direction2 == 'left' ? -45 : 45;
            this.header = this.menuText[this.onFocus];
        }
        for (let i = 0; i < 8; i++) {
            if (i === this.onFocus) {
                this.boxMenu[i] = ' on-focus-menu text-white';
            } else {
                this.boxMenu[i] = ' text-light';
            }
            if (this.angle <= 0) {
                this.renderer.setStyle(
                    document.querySelector(this.boxWheel[i]),
                    'transform',
                    `rotate(${Math.abs(this.angle)}deg)`
                );
            } else {
                this.renderer.setStyle(
                    document.querySelector(this.boxWheel[i]),
                    'transform',
                    `rotate(${this.angle * -1}deg)`
                );
            }
        }
        this.renderer.setStyle(document.querySelector('.imagepreview'), 'transform', `rotate(${this.angle}deg)`);
        if (direction2 === 'center') {
            if (direction === 3) {
                window.location.href = 'https://shop.feels.co.th';
            } else {
                this._router.navigate([ '/', this.linkMenu[this.onFocus] ]);
                this.modal.menub.next(true);
            }
        }
    }
    onClick() {
        if (this.menuClass !== ' w-100 h-100') {
            this.togglemenu = !this.togglemenu;
            this.menuClass = ' w-100 h-100';
        } else {
            this.togglemenu = !this.togglemenu;
            this.menuClass = ' w-100 h-100 left-200';
        }
    }
    @HostListener('window:scroll', [ '$event' ])
    onScroll(event) {
        this.hideNav = this.scrollTop < event.target.scrollingElement.scrollTop;
        this.scrollTop = event.target.scrollingElement.scrollTop;
    }
    onActivate(event) {
        window.scroll(0, 0);
        // or document.body.scrollTop = 0;
        // or document.querySelector('body').scrollTo(0,0)
    }
    @HostListener('swipeup')
    public swipeup(event): void {
        if (event) {
            this.rotateImage(1, false);
        }
    }

    @HostListener('swipedown')
    public swipedown(event): void {
        if (event) {
            this.rotateImage(0, false);
        }
    }

    ngOnInit() {
        // Check Cookie
        let isCookieAllowed = this.cookieService.get('IS_COOKIE_ALLOWED');
        if (isCookieAllowed == 'true') {
            this.ccService.destroy();
        }
        // subscribe to cookieconsent observables to react to main events
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
            // you can use this.ccService.getConfig() to do stuff...
            if (event.status === 'allow') {
                this.cookieService.set('IS_COOKIE_ALLOWED', 'true');
                this.ccService.destroy();
            } else {
                this.cookieService.delete('IS_COOKIE_ALLOWED');
            }
        });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
            // you can use this.ccService.getConfig() to do stuff...
        });
    }
    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
}
