<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <div style="background-color: #DCDCDC;">
                <div class="col-md-12 p-4 termpage">
                    <div class="force-overflow">
                        <ul>
                            <li>
                                การลงทะเบียนด้วยระบบอัตลักษณ์ยังไม่สมบูรณ์ หากท่านประสงค์จะลงทะเบียนซิมการ์ดต่อไป
                                ท่านจะต้องยืนยันและรับรองความถูกต้องในตัวบุคคลโดยต้องตรวจสอบจากบัตรจริงเท่านั้นและยืนยันรับรอง
                            </li>
                            <li>
                                ข้อมูลนี้จะถูกเก็บข้อมูลไว้เป็นหลักฐานในการขอตรวจสอบตามกฎหมายต่อไป
                            </li>
                            <li>
                                ในกรณีที่บริษัทตรวจพบว่าข้อมูลเป็นเท็จ บริษัทขอสงวนสิทธิในการระงับการใช้งานได้ทันที
                            </li>
                            <li>
                                หากท่านไม่ติดต่อเจ้าหน้าที่ภายใน 15 วันหลังจากการแจ้งเตือน
                                บริษัทจะขอสงวนสิทธิยกเลิกบริการซิมการ์ดได้ทันที และส่งเรื่องดำเนินการตามกฎหมายต่อไป
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center p-3">
            <button mdbBtn type="button" (click)="nextPage()" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ยอมรับเงื่อนไข</button>
        </div>
    </div>
</main>