import { Component, OnInit, HostListener } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { SubmitComponent } from './submit/submit.component';
import { NumberService } from 'src/app/services/number.service';

@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {
    public myRadio: string = 'alipay'
    selectTitle: Object;
    selectNumber: string = 'ยังไม่เลือกเบอร์';
    titleName: Array<Object> = [{ 'id': 'sum', 'filtername': 'ผลรวม' }, { 'id': 'numberlike', 'filtername': 'ค้นหาเลขที่สนใจ' }]
    luckyNumber: Array<string> = [' bg-soft-green text-white', ' btn-outline-green-main', ' btn-outline-green-main', ' btn-outline-green-main']
    indexFrom: number = 0;
    indexTo: number = 11;
    selectNumberType: number = 0
    indexFromSearch: number = 0;
    indexToSearch: number = 11;
    modalRef: MDBModalRef;
    numberError: boolean;
    numberNotFound: boolean = false;
    searchText: string;
    loadingnumber: boolean = true;
    price: string;
    productid: string;
    originprice: string;
    taxprice: string;

    public slides: Array<Object> = [
        { "img": "../../../assets/img/bg/topup.png" },
        { "img": "../../../assets/img/bg/topup2.png" }
    ]
    phonenumberSearch: Array<Object> = [];
    phonenumber2: Array<Object>
    phonenumber: Array<Object> = []
    // phonenumber: Array<Object> = [
    //   { "phonenumber": "0863584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0863584257", "price": "599", "type": 0 },
    //   { "phonenumber": "0863587766", "price": "599", "type": 0 },
    //   { "phonenumber": "0863589999", "price": "599", "type": 0 },
    //   { "phonenumber": "0863581234", "price": "599", "type": 0 },
    //   { "phonenumber": "0963584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0663584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0863584455", "price": "599", "type": 0 },
    //   { "phonenumber": "0863589900", "price": "599", "type": 0 },
    //   { "phonenumber": "0863580000", "price": "599", "type": 0 },
    //   { "phonenumber": "0863489900", "price": "599", "type": 0 },
    //   { "phonenumber": "0864450000", "price": "599", "type": 0 },
    //   { "phonenumber": "0811584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0811584257", "price": "599", "type": 0 },
    //   { "phonenumber": "0811587766", "price": "599", "type": 0 },
    //   { "phonenumber": "0811589999", "price": "599", "type": 0 },
    //   { "phonenumber": "0811581234", "price": "599", "type": 0 },
    //   { "phonenumber": "0911584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0611584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0811584455", "price": "599", "type": 0 },
    //   { "phonenumber": "0811589900", "price": "599", "type": 0 },
    //   { "phonenumber": "0811580000", "price": "599", "type": 0 },
    //   { "phonenumber": "0811489900", "price": "599", "type": 0 },
    //   { "phonenumber": "0865550000", "price": "599", "type": 0 },
    //   { "phonenumber": "0863584258", "price": "599", "type": 0 },
    //   { "phonenumber": "0863584257", "price": "599", "type": 3 },
    //   { "phonenumber": "0863587766", "price": "599", "type": 3 },
    //   { "phonenumber": "0863589999", "price": "599", "type": 3 },
    //   { "phonenumber": "0863581234", "price": "599", "type": 3 },
    //   { "phonenumber": "0963584258", "price": "599", "type": 3 },
    //   { "phonenumber": "0663584258", "price": "599", "type": 2 },
    //   { "phonenumber": "0863584455", "price": "599", "type": 2 },
    //   { "phonenumber": "0863589900", "price": "599", "type": 2 },
    //   { "phonenumber": "0863580000", "price": "599", "type": 2 },
    //   { "phonenumber": "0863489900", "price": "599", "type": 2 },
    //   { "phonenumber": "0864450000", "price": "599", "type": 2 },
    //   { "phonenumber": "0811584258", "price": "599", "type": 3 },
    //   { "phonenumber": "0811584257", "price": "599", "type": 3 },
    //   { "phonenumber": "0811587766", "price": "599", "type": 3 },
    //   { "phonenumber": "0811589999", "price": "599", "type": 3 },
    //   { "phonenumber": "0811581234", "price": "599", "type": 3 },
    //   { "phonenumber": "0911584258", "price": "599", "type": 3 },
    //   { "phonenumber": "0611584258", "price": "599", "type": 3 },
    //   { "phonenumber": "0811584455", "price": "599", "type": 3 },
    //   { "phonenumber": "0811589900", "price": "599", "type": 1 },
    //   { "phonenumber": "0811580000", "price": "599", "type": 1 },
    //   { "phonenumber": "0811489900", "price": "599", "type": 1 },
    //   { "phonenumber": "0865550000", "price": "599", "type": 1 },
    // ]

    constructor(private modalService: MDBModalService, private numberService: NumberService) {
        this.selectTitle = this.titleName[1]
        this.numberService.getNumber().subscribe((res: any) => {
            this.loadingnumber = false;
            this.phonenumber = Object.values(JSON.parse(res + '')['numberlist']);
            // console.log(this.phonenumber);
        })
    }

    ngOnInit(): void {
    }
    radio(event) {
        const inputValue = event.target.value;
        this.myRadio = inputValue
        console.log(inputValue)
    }
    onKey(event) {
        // console.log(event.getAttribute('data-price'));
        for (const prop in this.phonenumber) {
            if (this.phonenumber[prop]['phonenumber'] === event.target.value) {
                console.log(this.phonenumber[prop]['numberid']);
                console.log(this.phonenumber[prop]['price']);
                this.price = this.phonenumber[prop]['price'];
                this.productid = this.phonenumber[prop]['productid'];
                this.originprice = this.phonenumber[prop]['originprice'];
                this.taxprice = this.phonenumber[prop]['taxprice'];
            }
        }
        const inputValue = event.target.value;
        this.selectNumber = inputValue
    }
    onNext(search: boolean) {
        if (!search) {
            if (!(this.indexTo < (this.phonenumber.length - 1))) {
                this.onSearch()
                this.indexFrom = 0
                this.indexTo = 11
            } else {
                this.indexFrom += 12
                this.indexTo += 12
            }
        } else {
            this.indexFromSearch += 12
            this.indexToSearch += 12
        }
        console.log(this.indexFrom, this.indexTo, this.phonenumber.length)
    }
    onPrevious(search: boolean) {
        if (!search) {
            this.indexFrom -= 12
            this.indexTo -= 12
        } else {
            this.indexFromSearch -= 12
            this.indexToSearch -= 12
        }
    }
    onSubmit() {
        if (this.selectNumber != 'ยังไม่เลือกเบอร์') {
            this.numberService.selectNumber = this.selectNumber;
            this.numberService.price = this.price;
            this.numberService.productid = this.productid;
            this.numberService.originprice = this.originprice;
            this.numberService.taxprice = this.taxprice;
            this.modalRef = this.modalService.show(SubmitComponent,
                { class: 'modal-dialog-centered modal-lg modal-dialog-scrollable' }
            )
        } else {
            this.numberError = true
        }
    }
    // @HostListener('input') oninput() { this.searchItems(); }
    // searchItems() {
    //   this.phonenumberSearch = []
    //   if (this.selectTitle['filtername'] === 'ผลรวม') {
    //     for (var i of this.phonenumber) {
    //       let result = i['phonenumber'].split('').reduce((a, b) => Number(a) + Number(b), 0)
    //       if (result === Number(this.searchText)) {
    //         this.phonenumberSearch.push(i)
    //       }
    //     }
    //   } else {
    //     for (var i of this.phonenumber) {
    //       if (this.searh(i['phonenumber'])) {
    //         this.phonenumberSearch.push(i)
    //       }
    //     }
    //   }
    // }

    searh(text: string) {
        return text.indexOf(this.searchText) >= 0;
    }
    selectLucky(index: number) {
        this.selectNumberType = index
        for (let i = 0; i < 4; i++) {
            if (i === index) {
                this.luckyNumber[i] = ' bg-soft-green text-white'
            } else {
                this.luckyNumber[i] = ' btn-outline-green-main'
            }
        }
        this.phonenumber = this.phonenumber2.filter(obj => obj['type'] === this.selectNumberType)
    }
    onSearch() {
        this.loadingnumber = true;
        if (!!this.searchText) {
            this.numberService.searchNumber(this.searchText, this.selectTitle['id']).subscribe((res: any) => {
                this.loadingnumber = false;
                let resp = JSON.parse(res + '')['responsecode']
                if (resp === 101) {
                    this.numberNotFound = true
                } else {
                    this.numberNotFound = false
                    this.phonenumber = Object.values(JSON.parse(res + '')['numberlist'])
                }
            })
        } else {
            this.numberNotFound = false
            this.numberService.getNumber().subscribe((res: any) => {
                this.loadingnumber = false;
                this.phonenumber = Object.values(JSON.parse(res + '')['numberlist'])
            })
        }
    }

}
