<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-8 p-0">
            <div class="col-12 p-0">
                <div class="text-center upload-img mt-3">
                    <img [className]="'d-example'+exampleimageClass" (click)="file_idcard.click()"
                        src="assets/img/banner/CamIDcardfront.png">
                    <div [className]="'preview'+previewimageClass">
                        <img id="peviewidcard" (click)="file_idcard.click()" [src]="peviewidcard">
                    </div>
                    <p class="mt-3">ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหน้า)</p>
                    <input class="d-none" type="file" #file_idcard (click)="file_idcard.value = null"
                        (change)="importFile($event)" accept="image/*" capture>
                </div>
                <div *ngIf="file_idcard.value" class="text-center d-block">
                    <img (click)="rotateIdCard()" class="img-rotating" src="assets/img/icon/rotate-icon.png" width="35">
                </div>
                <div class="text-center upload-img mt-3">
                    <img [className]="'d-example'+examplebackimageClass" (click)="file_backidcard.click()"
                        src="assets/img/banner/CamIDcardBack.png">
                    <div [className]="'preview'+previewbackimageClass">
                        <img id="peviewbackidcard" (click)="file_backidcard.click()" [src]="peviewbackidcard">
                    </div>
                    <p class="mt-3">ภาพถ่ายบัตรประจำตัวประชาชน (ด้านหลัง)</p>
                    <input class="d-none" type="file" #file_backidcard (click)="file_backidcard.value = null"
                        (change)="importBackFile($event)" accept="image/*" capture>
                </div>
                <div *ngIf="file_backidcard.value" class="text-center d-block">
                    <img (click)="rotateBackIdCard()" class="img-rotating" src="assets/img/icon/rotate-icon.png"
                        width="35">
                </div>
            </div>
            <div class="col-12 text-center p-3">
                <button mdbBtn type="button" (click)="next()"
                    [disabled]="(!file_idcard.value && !file_backidcard.value) || disable"
                    class="rounded-pill bg-soft-green text-white" mdbWavesEffect>ถัดไป</button>
            </div>
        </div>
    </div>
</main>