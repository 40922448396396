import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public show: boolean = true;
  public menu: Subject<boolean> = new Subject<boolean>();
  public menub: Subject<boolean> = new Subject<boolean>();
  private API_URL = environment.API_URL;
  private KEY = environment.key

  constructor(private http: HttpClient) { }

  getModal() {
    const headers = new HttpHeaders().set('x-api-key', this.KEY).set('Content-Type', 'application/json');
    const url = this.API_URL + '/api/getmodal'
    return this.http.get(url, { headers })

  }
}
