<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
    <a class="navbar-brand text-white" [routerLink]="['/']" data-toggle="collapse" data-target=".navbar-collapse.show">
        <img src="assets\img\logo\logo-1.svg">
    </a>
    <div>
        <!-- <a class="navbar-brand text-dark pr-3 pl-3 dropdown d-none" [routerLink]="['/']">
            <img class="w-28px" src="assets\img\icon\Thai.png">
            <a><small> ไทย</small></a>
            <div class="dropdown-content lng-drop">
                <a href="#"><img class="w-28px" src="assets\img\icon\Thai.jpg"> ไทย</a>
                <a href="#"><img class="w-28px" src="assets\img\icon\English.jpg"> อังกฤษ</a>
            </div>
        </a> -->
        <div class="d-flex align-items-center text-light-green-main">
            <img (click)="onMenu()" height="40px" class="spin-menu d-block d-sm-block d-md-none"
                src="assets/img/icon/menu/38857 [Converted]-03.png">
            <!-- <i (click)="onMenu()" class="fas fa-spinner fa-2x cursor-on"></i> -->
            <button class="navbar-toggler" type="button" id="menunav" data-toggle="collapse"
                data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </div>
    <div class="collapse navbar-collapse mr-4" id="navbarsExampleDefault">
        <ul class="navbar-nav mr-auto">
        </ul>
        <ul class="navbar-nav">
            <li>
                <a class="nav-link" [routerLink]="['/']" data-toggle="collapse"
                    data-target=".navbar-collapse.show">หน้าแรก</a>
            </li>
            <li>
                <div class="dropdown w-100">
                    <a class="nav-link">สั่งซื้อออนไลน์</a>
                    <div class="dropdown-content rounded-bottom">
                        <a [routerLink]="['/register']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">ลงทะเบียนออนไลน์</a>
                        <a [routerLink]="['/movetofeels']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">ย้ายค่าย</a>
                        <a href="https://shop.feels.co.th">ร้านค้าออนไลน์</a>
                        <!-- <a [routerLink]="['/store']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">ร้านค้าออนไลน์</a> -->
                    </div>
                </div>
            </li>
            <li>
                <div class="dropdown w-100">
                    <a class="nav-link">แพ็กเกจ</a>
                    <div class="dropdown-content rounded-bottom">
                        <a [routerLink]="['/packages']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">เลือกแพ็กเกจ</a>
                        <a [routerLink]="['/ondemandpackage']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">สร้างแพ็กเกจของคุณเอง</a>
                    </div>
                </div>
            </li>
            <li>
                <a class="nav-link" [routerLink]="['/onlinesim']" data-toggle="collapse"
                    data-target=".navbar-collapse.show">ซื้อเบอร์ใหม่</a>
            </li>
            <li>
                <a class="nav-link" [routerLink]="['/topup']" data-toggle="collapse"
                    data-target=".navbar-collapse.show">เติมเงิน</a>
            </li>
            <li>
                <div class="dropdown w-100">
                    <a class="nav-link">เกี่ยวกับเรา</a>
                    <div class="dropdown-content rounded-bottom">
                        <a [routerLink]="['/about']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">ประวัติ</a>
                        <a [routerLink]="['/frequencies']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">เครือข่ายและคลื่นความถี่</a>
                        <a [routerLink]="['/policy']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">นโยบายความเป็นส่วนตัว</a>
                        <a [routerLink]="['/proviso']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">เงื่อนไขการให้บริการ</a>
                    </div>
                </div>
            </li>
            <li style="float:right;">
                <div class="dropdown w-100">
                    <a class="nav-link">ช่วยเหลือ</a>
                    <div class="dropdown-content rounded-bottom">
                        <a [routerLink]="['/faq']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">คำถามที่พบบ่อย</a>
                        <a [routerLink]="['devicesettings']" data-toggle="collapse"
                            data-target=".navbar-collapse.show">การตั้งค่าเครื่อง</a>
                    </div>
                </div>
            </li>
            <li>
                <a class="nav-link" [routerLink]="['/contact']" data-toggle="collapse"
                    data-target=".navbar-collapse.show">ติดต่อเรา</a>
            </li>
        </ul>
    </div>

    <!-- <div class="fb-customerchat" page_id="358289884653354"></div> -->
    <div class="fb-customerchat" attribution=setup_tool page_id="1976979502556378"
        logged_in_greeting="สวัสดีมีอะไรให้ช่วยไหมเอ่ย ?" logged_out_greeting="สวัสดีมีอะไรให้ช่วยไหมเอ่ย ?">
    </div>

</nav>