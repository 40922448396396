<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/main-banner-ordersim-240103.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/main-banner-ordersim-240103.jpg">
    </div>
    <div class="row w-100 m-0">
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="page-body white rounded-top-3 col-12 col-lg-8 mt-3 mb-3 p-3">
                    <div class="row justify-content-center p-3 mb-3">
                        <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                    </div>
                    <div class="mb-3">
                        <h1 class="fs-50 line-h-100 text-center text-light-green-main">ซื้อซิมออนไลน์</h1>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <div class="progressbar-wrapper">
                                <ul class="progressbar-min" *ngIf="page==='selectnumber'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">เลือกเบอร์</div>
                                            ที่ต้องการ
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='selectpackage'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">เลือกแพ็กเกจ</div>
                                            ที่ต้องการ
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='agreement'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">เงื่อนไข</div>
                                            การให้บริการ
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='personalinfo'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ข้อมูล</div>
                                            ส่วนตัว
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='personaladdress'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ที่อยู่</div>
                                            จัดส่ง
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='summary'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">สรุปรายการ</div>
                                            สั่งซื้อ
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-max" *ngIf="page==='result'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ผลลัพธ์</div>
                                            การสั่งซื้อ
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <app-selectnumber class="col-12" *ngIf="page==='selectnumber'"></app-selectnumber>
                        <app-selectpackage class="col-12" *ngIf="page==='selectpackage'"></app-selectpackage>
                        <app-onlinesimagreement class="col-12" *ngIf="page==='agreement'"></app-onlinesimagreement>
                        <app-onlinesimpersonal class="col-12" *ngIf="page==='personalinfo'"></app-onlinesimpersonal>
                        <app-onlinesimaddress class="col-12" *ngIf="page==='personaladdress'"></app-onlinesimaddress>
                        <app-onlinesimsummary class="col-12" *ngIf="page==='summary'"></app-onlinesimsummary>
                        <app-onlinesimpayment class="col-12" *ngIf="page==='result'"></app-onlinesimpayment>
                    </div>
                </div>
                <div class="col-12 col-lg-8 mb-3 p-3 white elegant-color-text rounded-bottom-3"
                    *ngIf="page==='selectnumber' || page==='selectpackage'">
                    <div class="d-flex justify-content-center">
                        <div class="row w-100 justify-content-between">
                            <div class="col-12">
                                <div class="row w-100 justify-content-center m-0">
                                    <div class="col-12 col-lg-8" *ngIf="page==='selectnumber'">
                                        <h2 class="text-center">รายละเอียด</h2>
                                        <p>เบอร์ของคุณคือ</p>
                                        <div
                                            class="btn-outline-green-main p-2 rounded-pill d-flex justify-content-center align-items-center">
                                            <p class="m-0 fs-20 font-weight-bold">{{selectednumber}}</p>
                                        </div>
                                        <button (click)="onSubmit()" type="button" mdbBtn
                                            class="rounded-pill w-100 mt-3 mb-0 mr-0 ml-0 bg-soft-green text-white"
                                            mdbWavesEffect>ถัดไป</button>
                                    </div>
                                    <div class="col-12 col-lg-8" *ngIf="page==='selectpackage'">
                                        <h2 class="text-center">รายละเอียด</h2>
                                        <p class="mt-3 mb-3">เบอร์ของคุณคือ</p>
                                        <div
                                            class="btn-outline-green-main p-2 rounded-pill d-flex justify-content-center align-items-center">
                                            <p class="m-0 fs-20 font-weight-bold">{{selectednumber}}</p>
                                        </div>
                                        <p class="mt-3 mb-3">แพ็กเกจของคุณคือ</p>
                                        <div
                                            class="btn-outline-green-main p-2 rounded-pill d-flex justify-content-center align-items-center">
                                            <p class="m-0 fs-20 font-weight-bold">{{selectedpackage}}</p>
                                        </div>
                                        <button (click)="onSubmit()" type="button" mdbBtn
                                            class="rounded-pill w-100 mt-3 mb-0 mr-0 ml-0 bg-soft-green text-white"
                                            mdbWavesEffect>ถัดไป</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<div class="container-fluid" *ngIf="loading">
    <div class="row justify-content-center align-items-center overlay" id="overlay">
        <div class="col col-sm-6 col-lg-12 h-100 p-0">
            <!-- <div class="loading" id="loading3"></div> -->
            <div class="bounce col-12">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <h1 class="col mt-5 loading-txt">กรุณารอสักครู่</h1>
        </div>
    </div>
</div>