import { Injectable } from '@angular/core';
import { PackageService } from 'src/app/services/package.service';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TopupService {
    selectPrice: number;
    phonenumber: string;
    selectPayment: string;
    topupMethod: string;
    public resTopup: Subject<Object> = new Subject<Object>();
    private API_URL_PROD = environment.API_URL_PROD;
    public packagecode: string = "1140"

    constructor(private http: HttpClient, private pack: PackageService) { }

    topup(phonenumber: string, amount: string, paymentchannel: string, method: string): Observable<string> {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body = JSON.stringify({ phonenumber, amount, paymentchannel, method });
        const url = this.API_URL_PROD + '/feelswebapp/topup'
        var result = this.http.post(url, body, { responseType: 'text', headers })
        return result
    }

    process() {
        this.phonenumber = '66' + this.phonenumber.slice(1, 10)
        this.topup(this.phonenumber, this.selectPrice + "", this.selectPayment, this.topupMethod).subscribe((res: any) => [
            this.resTopup.next(JSON.parse(res + ''))
        ])
    }

}
