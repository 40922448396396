import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { QRCodeComponent } from 'angularx-qrcode';
import { NumberService } from 'src/app/services/number.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
    selector: 'app-submit',
    templateUrl: './submit.component.html',
    styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit {
    submitForm: FormGroup;
    public myRadio: string;
    page: string = 'summary';
    qrSize: number = 200;
    link: string;
    selectNumber: string;
    btntext: string = 'ถัดไป';
    simprice: string;
    personaltype: string = 'idcard';
    loading: boolean = false;
    errorBook: boolean;

    errorTextCheck: boolean;
    errorText: string;
    codeRes: number;
    orderres: number;
    qrClass: string = 'd-none';
    spinner: string = ' d-flex';
    successPage: boolean = false;
    errorQR: boolean = false;
    errorQrText: string;
    lowBalance: string;
    public qrcode: string = "assets\img\icon\qrcode.svg";

    thisindex = 0;
    disableDistrict: boolean = true;
    disableSubdistrict: boolean = true;
    districttitle: string = 'อำเภอ / เขต';
    subdistricttitle: string = 'ตำบล / แขวง';

    Selecteddistrict: Object;
    Selectedsubdistrict: Object;

    pricedetail: string;
    originpricedetail: string;
    discountdetail: string;
    shippingdetail: string;
    taxpricedetail: string;
    firstnamedetail: string;
    lastnamedetail: string;
    contactnumberdetail: string;
    addressdetail: string = '';
    housenumberdetail: string;
    housenamedetail: string;
    provincedetail: string;
    districtdetail: string;
    subdistrictdetail: string;
    zipcodedetail: string;

    Province: Object = [
        {
            "provincecode": "10",
            "provincename": "กรุงเทพมหานคร"
        },
        {
            "provincecode": "11",
            "provincename": "สมุทรปราการ"
        },
        {
            "provincecode": "12",
            "provincename": "นนทบุรี"
        },
        {
            "provincecode": "13",
            "provincename": "ปทุมธานี"
        },
        {
            "provincecode": "14",
            "provincename": "พระนครศรีอยุธยา"
        },
        {
            "provincecode": "15",
            "provincename": "อ่างทอง"
        },
        {
            "provincecode": "16",
            "provincename": "ลพบุรี"
        },
        {
            "provincecode": "17",
            "provincename": "สิงห์บุรี"
        },
        {
            "provincecode": "18",
            "provincename": "ชัยนาท"
        },
        {
            "provincecode": "19",
            "provincename": "สระบุรี"
        },
        {
            "provincecode": "20",
            "provincename": "ชลบุรี"
        },
        {
            "provincecode": "21",
            "provincename": "ระยอง"
        },
        {
            "provincecode": "22",
            "provincename": "จันทบุรี"
        },
        {
            "provincecode": "23",
            "provincename": "ตราด"
        },
        {
            "provincecode": "24",
            "provincename": "ฉะเชิงเทรา"
        },
        {
            "provincecode": "25",
            "provincename": "ปราจีนบุรี"
        },
        {
            "provincecode": "26",
            "provincename": "นครนายก"
        },
        {
            "provincecode": "27",
            "provincename": "สระแก้ว"
        },
        {
            "provincecode": "30",
            "provincename": "นครราชสีมา"
        },
        {
            "provincecode": "31",
            "provincename": "บุรีรัมย์"
        },
        {
            "provincecode": "32",
            "provincename": "สุรินทร์"
        },
        {
            "provincecode": "33",
            "provincename": "ศรีสะเกษ"
        },
        {
            "provincecode": "34",
            "provincename": "อุบลราชธานี"
        },
        {
            "provincecode": "35",
            "provincename": "ยโสธร"
        },
        {
            "provincecode": "36",
            "provincename": "ชัยภูมิ"
        },
        {
            "provincecode": "37",
            "provincename": "อำนาจเจริญ"
        },
        {
            "provincecode": "39",
            "provincename": "หนองบัวลำภู"
        },
        {
            "provincecode": "40",
            "provincename": "ขอนแก่น"
        },
        {
            "provincecode": "41",
            "provincename": "อุดรธานี"
        },
        {
            "provincecode": "42",
            "provincename": "เลย"
        },
        {
            "provincecode": "43",
            "provincename": "หนองคาย"
        },
        {
            "provincecode": "44",
            "provincename": "มหาสารคาม"
        },
        {
            "provincecode": "45",
            "provincename": "ร้อยเอ็ด"
        },
        {
            "provincecode": "46",
            "provincename": "กาฬสินธุ์"
        },
        {
            "provincecode": "47",
            "provincename": "สกลนคร"
        },
        {
            "provincecode": "48",
            "provincename": "นครพนม"
        },
        {
            "provincecode": "49",
            "provincename": "มุกดาหาร"
        },
        {
            "provincecode": "50",
            "provincename": "เชียงใหม่"
        },
        {
            "provincecode": "51",
            "provincename": "ลำพูน"
        },
        {
            "provincecode": "52",
            "provincename": "ลำปาง"
        },
        {
            "provincecode": "53",
            "provincename": "อุตรดิตถ์"
        },
        {
            "provincecode": "54",
            "provincename": "แพร่"
        },
        {
            "provincecode": "55",
            "provincename": "น่าน"
        },
        {
            "provincecode": "56",
            "provincename": "พะเยา "
        },
        {
            "provincecode": "57",
            "provincename": "เชียงราย"
        },
        {
            "provincecode": "58",
            "provincename": "แม่ฮ่องสอน"
        },
        {
            "provincecode": "60",
            "provincename": "นครสวรรค์"
        },
        {
            "provincecode": "61",
            "provincename": "อุทัยธานี"
        },
        {
            "provincecode": "62",
            "provincename": "กำแพงเพชร"
        },
        {
            "provincecode": "63",
            "provincename": "ตาก"
        },
        {
            "provincecode": "64",
            "provincename": "สุโขทัย"
        },
        {
            "provincecode": "65",
            "provincename": "พิษณุโลก"
        },
        {
            "provincecode": "66",
            "provincename": "พิจิตร"
        },
        {
            "provincecode": "67",
            "provincename": "เพชรบูรณ์"
        },
        {
            "provincecode": "70",
            "provincename": "ราชบุรี"
        },
        {
            "provincecode": "71",
            "provincename": "กาญจนบุรี"
        },
        {
            "provincecode": "72",
            "provincename": "สุพรรณบุรี"
        },
        {
            "provincecode": "73",
            "provincename": "นครปฐม"
        },
        {
            "provincecode": "74",
            "provincename": "สมุทรสาคร"
        },
        {
            "provincecode": "75",
            "provincename": "สมุทรสงคราม"
        },
        {
            "provincecode": "76",
            "provincename": "เพชรบุรี"
        },
        {
            "provincecode": "77",
            "provincename": "ประจวบคีรีขันธ์"
        },
        {
            "provincecode": "80",
            "provincename": "นครศรีธรรมราช"
        },
        {
            "provincecode": "81",
            "provincename": "กระบี่"
        },
        {
            "provincecode": "82",
            "provincename": "พังงา"
        },
        {
            "provincecode": "83",
            "provincename": "ภูเก็ต"
        },
        {
            "provincecode": "84",
            "provincename": "สุราษฎร์ธานี"
        },
        {
            "provincecode": "85",
            "provincename": "ระนอง"
        },
        {
            "provincecode": "86",
            "provincename": "ชุมพร"
        },
        {
            "provincecode": "90",
            "provincename": "สงขลา"
        },
        {
            "provincecode": "91",
            "provincename": "สตูล"
        },
        {
            "provincecode": "92",
            "provincename": "ตรัง"
        },
        {
            "provincecode": "93",
            "provincename": "พัทลุง"
        },
        {
            "provincecode": "94",
            "provincename": "ปัตตานี"
        },
        {
            "provincecode": "95",
            "provincename": "ยะลา"
        },
        {
            "provincecode": "96",
            "provincename": "นราธิวาส"
        },
        {
            "provincecode": "97",
            "provincename": "บึงกาฬ"
        }
    ];

    District: Object = [
        {
            "provincecode": "10",
            "districtcode": "1001",
            "districtname": "พระนคร"
        },
        {
            "provincecode": "10",
            "districtcode": "1002",
            "districtname": "ดุสิต"
        },
        {
            "provincecode": "10",
            "districtcode": "1003",
            "districtname": "หนองจอก"
        },
        {
            "provincecode": "10",
            "districtcode": "1004",
            "districtname": "บางรัก"
        },
        {
            "provincecode": "10",
            "districtcode": "1005",
            "districtname": "บางเขน"
        },
        {
            "provincecode": "10",
            "districtcode": "1006",
            "districtname": "บางกะปิ"
        },
        {
            "provincecode": "10",
            "districtcode": "1007",
            "districtname": "ปทุมวัน"
        },
        {
            "provincecode": "10",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย"
        },
        {
            "provincecode": "10",
            "districtcode": "1009",
            "districtname": "พระโขนง"
        },
        {
            "provincecode": "10",
            "districtcode": "1010",
            "districtname": "มีนบุรี"
        },
        {
            "provincecode": "10",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง"
        },
        {
            "provincecode": "10",
            "districtcode": "1012",
            "districtname": "ยานนาวา"
        },
        {
            "provincecode": "10",
            "districtcode": "1013",
            "districtname": "สัมพันธวงศ์"
        },
        {
            "provincecode": "10",
            "districtcode": "1014",
            "districtname": "พญาไท"
        },
        {
            "provincecode": "10",
            "districtcode": "1015",
            "districtname": "ธนบุรี"
        },
        {
            "provincecode": "10",
            "districtcode": "1016",
            "districtname": "บางกอกใหญ่"
        },
        {
            "provincecode": "10",
            "districtcode": "1017",
            "districtname": "ห้วยขวาง"
        },
        {
            "provincecode": "10",
            "districtcode": "1018",
            "districtname": "คลองสาน"
        },
        {
            "provincecode": "10",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน"
        },
        {
            "provincecode": "10",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย"
        },
        {
            "provincecode": "10",
            "districtcode": "1021",
            "districtname": "บางขุนเทียน"
        },
        {
            "provincecode": "10",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ"
        },
        {
            "provincecode": "10",
            "districtcode": "1023",
            "districtname": "หนองแขม"
        },
        {
            "provincecode": "10",
            "districtcode": "1024",
            "districtname": "ราษฎร์บูรณะ"
        },
        {
            "provincecode": "10",
            "districtcode": "1025",
            "districtname": "บางพลัด"
        },
        {
            "provincecode": "10",
            "districtcode": "1026",
            "districtname": "ดินแดง"
        },
        {
            "provincecode": "10",
            "districtcode": "1027",
            "districtname": "บึงกุ่ม"
        },
        {
            "provincecode": "10",
            "districtcode": "1028",
            "districtname": "สาทร"
        },
        {
            "provincecode": "10",
            "districtcode": "1029",
            "districtname": "บางซื่อ"
        },
        {
            "provincecode": "10",
            "districtcode": "1030",
            "districtname": "จตุจักร"
        },
        {
            "provincecode": "10",
            "districtcode": "1031",
            "districtname": "บางคอแหลม"
        },
        {
            "provincecode": "10",
            "districtcode": "1032",
            "districtname": "ประเวศ"
        },
        {
            "provincecode": "10",
            "districtcode": "1033",
            "districtname": "คลองเตย"
        },
        {
            "provincecode": "10",
            "districtcode": "1034",
            "districtname": "สวนหลวง"
        },
        {
            "provincecode": "10",
            "districtcode": "1035",
            "districtname": "จอมทอง"
        },
        {
            "provincecode": "10",
            "districtcode": "1036",
            "districtname": "ดอนเมือง"
        },
        {
            "provincecode": "10",
            "districtcode": "1037",
            "districtname": "ราชเทวี"
        },
        {
            "provincecode": "10",
            "districtcode": "1038",
            "districtname": "ลาดพร้าว"
        },
        {
            "provincecode": "10",
            "districtcode": "1039",
            "districtname": "วัฒนา"
        },
        {
            "provincecode": "10",
            "districtcode": "1040",
            "districtname": "บางแค"
        },
        {
            "provincecode": "10",
            "districtcode": "1041",
            "districtname": "หลักสี่"
        },
        {
            "provincecode": "10",
            "districtcode": "1042",
            "districtname": "สายไหม"
        },
        {
            "provincecode": "10",
            "districtcode": "1043",
            "districtname": "คันนายาว"
        },
        {
            "provincecode": "10",
            "districtcode": "1044",
            "districtname": "สะพานสูง"
        },
        {
            "provincecode": "10",
            "districtcode": "1045",
            "districtname": "วังทองหลาง"
        },
        {
            "provincecode": "10",
            "districtcode": "1046",
            "districtname": "คลองสามวา"
        },
        {
            "provincecode": "10",
            "districtcode": "1047",
            "districtname": "บางนา"
        },
        {
            "provincecode": "10",
            "districtcode": "1048",
            "districtname": "ทวีวัฒนา"
        },
        {
            "provincecode": "10",
            "districtcode": "1049",
            "districtname": "ทุ่งครุ"
        },
        {
            "provincecode": "10",
            "districtcode": "1050",
            "districtname": "บางบอน"
        },
        {
            "provincecode": "10",
            "districtcode": "1081",
            "districtname": "บ้านทะวาย"
        },
        {
            "provincecode": "11",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ"
        },
        {
            "provincecode": "11",
            "districtcode": "1102",
            "districtname": "บางบ่อ"
        },
        {
            "provincecode": "11",
            "districtcode": "1103",
            "districtname": "บางพลี"
        },
        {
            "provincecode": "11",
            "districtcode": "1104",
            "districtname": "พระประแดง"
        },
        {
            "provincecode": "11",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์"
        },
        {
            "provincecode": "11",
            "districtcode": "1106",
            "districtname": "บางเสาธง"
        },
        {
            "provincecode": "12",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี"
        },
        {
            "provincecode": "12",
            "districtcode": "1202",
            "districtname": "บางกรวย"
        },
        {
            "provincecode": "12",
            "districtcode": "1203",
            "districtname": "บางใหญ่"
        },
        {
            "provincecode": "12",
            "districtcode": "1204",
            "districtname": "บางบัวทอง"
        },
        {
            "provincecode": "12",
            "districtcode": "1205",
            "districtname": "ไทรน้อย"
        },
        {
            "provincecode": "12",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด"
        },
        {
            "provincecode": "12",
            "districtcode": "1251",
            "districtname": "เทศบาลนครนนทบุรี (สาขาแขวงท่าทราย)"
        },
        {
            "provincecode": "12",
            "districtcode": "1297",
            "districtname": "เทศบาลเมืองปากเกร็ด"
        },
        {
            "provincecode": "13",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี"
        },
        {
            "provincecode": "13",
            "districtcode": "1302",
            "districtname": "คลองหลวง"
        },
        {
            "provincecode": "13",
            "districtcode": "1303",
            "districtname": "ธัญบุรี"
        },
        {
            "provincecode": "13",
            "districtcode": "1304",
            "districtname": "หนองเสือ"
        },
        {
            "provincecode": "13",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว"
        },
        {
            "provincecode": "13",
            "districtcode": "1306",
            "districtname": "ลำลูกกา"
        },
        {
            "provincecode": "13",
            "districtcode": "1307",
            "districtname": "สามโคก"
        },
        {
            "provincecode": "13",
            "districtcode": "1351",
            "districtname": "ลำลูกกา (สาขาตำบลคูคต)"
        },
        {
            "provincecode": "14",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา"
        },
        {
            "provincecode": "14",
            "districtcode": "1402",
            "districtname": "ท่าเรือ"
        },
        {
            "provincecode": "14",
            "districtcode": "1403",
            "districtname": "นครหลวง"
        },
        {
            "provincecode": "14",
            "districtcode": "1404",
            "districtname": "บางไทร"
        },
        {
            "provincecode": "14",
            "districtcode": "1405",
            "districtname": "บางบาล"
        },
        {
            "provincecode": "14",
            "districtcode": "1406",
            "districtname": "บางปะอิน"
        },
        {
            "provincecode": "14",
            "districtcode": "1407",
            "districtname": "บางปะหัน"
        },
        {
            "provincecode": "14",
            "districtcode": "1408",
            "districtname": "ผักไห่"
        },
        {
            "provincecode": "14",
            "districtcode": "1409",
            "districtname": "ภาชี"
        },
        {
            "provincecode": "14",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง"
        },
        {
            "provincecode": "14",
            "districtcode": "1411",
            "districtname": "วังน้อย"
        },
        {
            "provincecode": "14",
            "districtcode": "1412",
            "districtname": "เสนา"
        },
        {
            "provincecode": "14",
            "districtcode": "1413",
            "districtname": "บางซ้าย"
        },
        {
            "provincecode": "14",
            "districtcode": "1414",
            "districtname": "อุทัย"
        },
        {
            "provincecode": "14",
            "districtcode": "1415",
            "districtname": "มหาราช"
        },
        {
            "provincecode": "14",
            "districtcode": "1416",
            "districtname": "บ้านแพรก"
        },
        {
            "provincecode": "15",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง"
        },
        {
            "provincecode": "15",
            "districtcode": "1502",
            "districtname": "ไชโย"
        },
        {
            "provincecode": "15",
            "districtcode": "1503",
            "districtname": "ป่าโมก"
        },
        {
            "provincecode": "15",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง"
        },
        {
            "provincecode": "15",
            "districtcode": "1505",
            "districtname": "แสวงหา"
        },
        {
            "provincecode": "15",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ"
        },
        {
            "provincecode": "15",
            "districtcode": "1507",
            "districtname": "สามโก้"
        },
        {
            "provincecode": "16",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี"
        },
        {
            "provincecode": "16",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม"
        },
        {
            "provincecode": "16",
            "districtcode": "1603",
            "districtname": "โคกสำโรง"
        },
        {
            "provincecode": "16",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล"
        },
        {
            "provincecode": "16",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง"
        },
        {
            "provincecode": "16",
            "districtcode": "1606",
            "districtname": "บ้านหมี่"
        },
        {
            "provincecode": "16",
            "districtcode": "1607",
            "districtname": "ท่าหลวง"
        },
        {
            "provincecode": "16",
            "districtcode": "1608",
            "districtname": "สระโบสถ์"
        },
        {
            "provincecode": "16",
            "districtcode": "1609",
            "districtname": "โคกเจริญ"
        },
        {
            "provincecode": "16",
            "districtcode": "1610",
            "districtname": "ลำสนธิ"
        },
        {
            "provincecode": "16",
            "districtcode": "1611",
            "districtname": "หนองม่วง"
        },
        {
            "provincecode": "16",
            "districtcode": "1681",
            "districtname": "อ.บ้านเช่า จ.ลพบุรี"
        },
        {
            "provincecode": "17",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี"
        },
        {
            "provincecode": "17",
            "districtcode": "1702",
            "districtname": "บางระจัน"
        },
        {
            "provincecode": "17",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน"
        },
        {
            "provincecode": "17",
            "districtcode": "1704",
            "districtname": "พรหมบุรี"
        },
        {
            "provincecode": "17",
            "districtcode": "1705",
            "districtname": "ท่าช้าง"
        },
        {
            "provincecode": "17",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี"
        },
        {
            "provincecode": "18",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท"
        },
        {
            "provincecode": "18",
            "districtcode": "1802",
            "districtname": "มโนรมย์"
        },
        {
            "provincecode": "18",
            "districtcode": "1803",
            "districtname": "วัดสิงห์"
        },
        {
            "provincecode": "18",
            "districtcode": "1804",
            "districtname": "สรรพยา"
        },
        {
            "provincecode": "18",
            "districtcode": "1805",
            "districtname": "สรรคบุรี"
        },
        {
            "provincecode": "18",
            "districtcode": "1806",
            "districtname": "หันคา"
        },
        {
            "provincecode": "18",
            "districtcode": "1807",
            "districtname": "หนองมะโมง"
        },
        {
            "provincecode": "18",
            "districtcode": "1808",
            "districtname": "เนินขาม"
        },
        {
            "provincecode": "19",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี"
        },
        {
            "provincecode": "19",
            "districtcode": "1902",
            "districtname": "แก่งคอย"
        },
        {
            "provincecode": "19",
            "districtcode": "1903",
            "districtname": "หนองแค"
        },
        {
            "provincecode": "19",
            "districtcode": "1904",
            "districtname": "วิหารแดง"
        },
        {
            "provincecode": "19",
            "districtcode": "1905",
            "districtname": "หนองแซง"
        },
        {
            "provincecode": "19",
            "districtcode": "1906",
            "districtname": "บ้านหมอ"
        },
        {
            "provincecode": "19",
            "districtcode": "1907",
            "districtname": "ดอนพุด"
        },
        {
            "provincecode": "19",
            "districtcode": "1908",
            "districtname": "หนองโดน"
        },
        {
            "provincecode": "19",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท"
        },
        {
            "provincecode": "19",
            "districtcode": "1910",
            "districtname": "เสาไห้"
        },
        {
            "provincecode": "19",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก"
        },
        {
            "provincecode": "19",
            "districtcode": "1912",
            "districtname": "วังม่วง"
        },
        {
            "provincecode": "19",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ"
        },
        {
            "provincecode": "20",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี"
        },
        {
            "provincecode": "20",
            "districtcode": "2002",
            "districtname": "บ้านบึง"
        },
        {
            "provincecode": "20",
            "districtcode": "2003",
            "districtname": "หนองใหญ่"
        },
        {
            "provincecode": "20",
            "districtcode": "2004",
            "districtname": "บางละมุง"
        },
        {
            "provincecode": "20",
            "districtcode": "2005",
            "districtname": "พานทอง"
        },
        {
            "provincecode": "20",
            "districtcode": "2006",
            "districtname": "พนัสนิคม"
        },
        {
            "provincecode": "20",
            "districtcode": "2007",
            "districtname": "ศรีราชา"
        },
        {
            "provincecode": "20",
            "districtcode": "2008",
            "districtname": "เกาะสีชัง"
        },
        {
            "provincecode": "20",
            "districtcode": "2009",
            "districtname": "สัตหีบ"
        },
        {
            "provincecode": "20",
            "districtcode": "2010",
            "districtname": "บ่อทอง"
        },
        {
            "provincecode": "20",
            "districtcode": "2011",
            "districtname": "เกาะจันทร์"
        },
        {
            "provincecode": "20",
            "districtcode": "2051",
            "districtname": "สัตหีบ (สาขาตำบลบางเสร่)"
        },
        {
            "provincecode": "20",
            "districtcode": "2072",
            "districtname": "ท้องถิ่นเทศบาลเมืองหนองปรือ"
        },
        {
            "provincecode": "20",
            "districtcode": "2093",
            "districtname": "เทศบาลตำบลแหลมฉบัง"
        },
        {
            "provincecode": "20",
            "districtcode": "2099",
            "districtname": "เทศบาลเมืองชลบุรี"
        },
        {
            "provincecode": "21",
            "districtcode": "2101",
            "districtname": "เมืองระยอง"
        },
        {
            "provincecode": "21",
            "districtcode": "2102",
            "districtname": "บ้านฉาง"
        },
        {
            "provincecode": "21",
            "districtcode": "2103",
            "districtname": "แกลง"
        },
        {
            "provincecode": "21",
            "districtcode": "2104",
            "districtname": "วังจันทร์"
        },
        {
            "provincecode": "21",
            "districtcode": "2105",
            "districtname": "บ้านค่าย"
        },
        {
            "provincecode": "21",
            "districtcode": "2106",
            "districtname": "ปลวกแดง"
        },
        {
            "provincecode": "21",
            "districtcode": "2107",
            "districtname": "เขาชะเมา"
        },
        {
            "provincecode": "21",
            "districtcode": "2108",
            "districtname": "นิคมพัฒนา"
        },
        {
            "provincecode": "21",
            "districtcode": "2151",
            "districtname": "สาขาตำบลมาบข่า"
        },
        {
            "provincecode": "22",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี"
        },
        {
            "provincecode": "22",
            "districtcode": "2202",
            "districtname": "ขลุง"
        },
        {
            "provincecode": "22",
            "districtcode": "2203",
            "districtname": "ท่าใหม่"
        },
        {
            "provincecode": "22",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน"
        },
        {
            "provincecode": "22",
            "districtcode": "2205",
            "districtname": "มะขาม"
        },
        {
            "provincecode": "22",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์"
        },
        {
            "provincecode": "22",
            "districtcode": "2207",
            "districtname": "สอยดาว"
        },
        {
            "provincecode": "22",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว"
        },
        {
            "provincecode": "22",
            "districtcode": "2209",
            "districtname": "นายายอาม"
        },
        {
            "provincecode": "22",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ"
        },
        {
            "provincecode": "22",
            "districtcode": "2281",
            "districtname": "กิ่ง อ.กำพุธ จ.จันทบุรี"
        },
        {
            "provincecode": "23",
            "districtcode": "2301",
            "districtname": "เมืองตราด"
        },
        {
            "provincecode": "23",
            "districtcode": "2302",
            "districtname": "คลองใหญ่"
        },
        {
            "provincecode": "23",
            "districtcode": "2303",
            "districtname": "เขาสมิง"
        },
        {
            "provincecode": "23",
            "districtcode": "2304",
            "districtname": "บ่อไร่"
        },
        {
            "provincecode": "23",
            "districtcode": "2305",
            "districtname": "แหลมงอบ"
        },
        {
            "provincecode": "23",
            "districtcode": "2306",
            "districtname": "เกาะกูด"
        },
        {
            "provincecode": "23",
            "districtcode": "2307",
            "districtname": "เกาะช้าง"
        },
        {
            "provincecode": "24",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา"
        },
        {
            "provincecode": "24",
            "districtcode": "2402",
            "districtname": "บางคล้า"
        },
        {
            "provincecode": "24",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว"
        },
        {
            "provincecode": "24",
            "districtcode": "2404",
            "districtname": "บางปะกง"
        },
        {
            "provincecode": "24",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์"
        },
        {
            "provincecode": "24",
            "districtcode": "2406",
            "districtname": "พนมสารคาม"
        },
        {
            "provincecode": "24",
            "districtcode": "2407",
            "districtname": "ราชสาส์น"
        },
        {
            "provincecode": "24",
            "districtcode": "2408",
            "districtname": "สนามชัยเขต"
        },
        {
            "provincecode": "24",
            "districtcode": "2409",
            "districtname": "แปลงยาว"
        },
        {
            "provincecode": "24",
            "districtcode": "2410",
            "districtname": "ท่าตะเกียบ"
        },
        {
            "provincecode": "24",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน"
        },
        {
            "provincecode": "25",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี"
        },
        {
            "provincecode": "25",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี"
        },
        {
            "provincecode": "25",
            "districtcode": "2503",
            "districtname": "นาดี"
        },
        {
            "provincecode": "25",
            "districtcode": "2504",
            "districtname": "สระแก้ว"
        },
        {
            "provincecode": "25",
            "districtcode": "2505",
            "districtname": "วังน้ำเย็น"
        },
        {
            "provincecode": "25",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง"
        },
        {
            "provincecode": "25",
            "districtcode": "2507",
            "districtname": "ประจันตคาม"
        },
        {
            "provincecode": "25",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ"
        },
        {
            "provincecode": "25",
            "districtcode": "2509",
            "districtname": "ศรีมโหสถ"
        },
        {
            "provincecode": "25",
            "districtcode": "2510",
            "districtname": "อรัญประเทศ"
        },
        {
            "provincecode": "25",
            "districtcode": "2511",
            "districtname": "ตาพระยา"
        },
        {
            "provincecode": "25",
            "districtcode": "2512",
            "districtname": "วัฒนานคร"
        },
        {
            "provincecode": "25",
            "districtcode": "2513",
            "districtname": "คลองหาด"
        },
        {
            "provincecode": "26",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก"
        },
        {
            "provincecode": "26",
            "districtcode": "2602",
            "districtname": "ปากพลี"
        },
        {
            "provincecode": "26",
            "districtcode": "2603",
            "districtname": "บ้านนา"
        },
        {
            "provincecode": "26",
            "districtcode": "2604",
            "districtname": "องครักษ์"
        },
        {
            "provincecode": "27",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว"
        },
        {
            "provincecode": "27",
            "districtcode": "2702",
            "districtname": "คลองหาด"
        },
        {
            "provincecode": "27",
            "districtcode": "2703",
            "districtname": "ตาพระยา"
        },
        {
            "provincecode": "27",
            "districtcode": "2704",
            "districtname": "วังน้ำเย็น"
        },
        {
            "provincecode": "27",
            "districtcode": "2705",
            "districtname": "วัฒนานคร"
        },
        {
            "provincecode": "27",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ"
        },
        {
            "provincecode": "27",
            "districtcode": "2707",
            "districtname": "เขาฉกรรจ์"
        },
        {
            "provincecode": "27",
            "districtcode": "2708",
            "districtname": "โคกสูง"
        },
        {
            "provincecode": "27",
            "districtcode": "2709",
            "districtname": "วังสมบูรณ์"
        },
        {
            "provincecode": "30",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา"
        },
        {
            "provincecode": "30",
            "districtcode": "3002",
            "districtname": "ครบุรี"
        },
        {
            "provincecode": "30",
            "districtcode": "3003",
            "districtname": "เสิงสาง"
        },
        {
            "provincecode": "30",
            "districtcode": "3004",
            "districtname": "คง"
        },
        {
            "provincecode": "30",
            "districtcode": "3005",
            "districtname": "บ้านเหลื่อม"
        },
        {
            "provincecode": "30",
            "districtcode": "3006",
            "districtname": "จักราช"
        },
        {
            "provincecode": "30",
            "districtcode": "3007",
            "districtname": "โชคชัย"
        },
        {
            "provincecode": "30",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด"
        },
        {
            "provincecode": "30",
            "districtcode": "3009",
            "districtname": "โนนไทย"
        },
        {
            "provincecode": "30",
            "districtcode": "3010",
            "districtname": "โนนสูง"
        },
        {
            "provincecode": "30",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง"
        },
        {
            "provincecode": "30",
            "districtcode": "3012",
            "districtname": "บัวใหญ่"
        },
        {
            "provincecode": "30",
            "districtcode": "3013",
            "districtname": "ประทาย"
        },
        {
            "provincecode": "30",
            "districtcode": "3014",
            "districtname": "ปักธงชัย"
        },
        {
            "provincecode": "30",
            "districtcode": "3015",
            "districtname": "พิมาย"
        },
        {
            "provincecode": "30",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง"
        },
        {
            "provincecode": "30",
            "districtcode": "3017",
            "districtname": "ชุมพวง"
        },
        {
            "provincecode": "30",
            "districtcode": "3018",
            "districtname": "สูงเนิน"
        },
        {
            "provincecode": "30",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ"
        },
        {
            "provincecode": "30",
            "districtcode": "3020",
            "districtname": "สีคิ้ว"
        },
        {
            "provincecode": "30",
            "districtcode": "3021",
            "districtname": "ปากช่อง"
        },
        {
            "provincecode": "30",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก"
        },
        {
            "provincecode": "30",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง"
        },
        {
            "provincecode": "30",
            "districtcode": "3024",
            "districtname": "โนนแดง"
        },
        {
            "provincecode": "30",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว"
        },
        {
            "provincecode": "30",
            "districtcode": "3026",
            "districtname": "เทพารักษ์"
        },
        {
            "provincecode": "30",
            "districtcode": "3027",
            "districtname": "เมืองยาง"
        },
        {
            "provincecode": "30",
            "districtcode": "3028",
            "districtname": "พระทองคำ"
        },
        {
            "provincecode": "30",
            "districtcode": "3029",
            "districtname": "ลำทะเมนชัย"
        },
        {
            "provincecode": "30",
            "districtcode": "3030",
            "districtname": "บัวลาย"
        },
        {
            "provincecode": "30",
            "districtcode": "3031",
            "districtname": "สีดา"
        },
        {
            "provincecode": "30",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ"
        },
        {
            "provincecode": "30",
            "districtcode": "3049",
            "districtname": "ท้องถิ่นเทศบาลตำบลโพธิ์กลาง"
        },
        {
            "provincecode": "30",
            "districtcode": "3051",
            "districtname": "สาขาตำบลมะค่า-พลสงคราม"
        },
        {
            "provincecode": "30",
            "districtcode": "3081",
            "districtname": "โนนลาว"
        },
        {
            "provincecode": "31",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์"
        },
        {
            "provincecode": "31",
            "districtcode": "3102",
            "districtname": "คูเมือง"
        },
        {
            "provincecode": "31",
            "districtcode": "3103",
            "districtname": "กระสัง"
        },
        {
            "provincecode": "31",
            "districtcode": "3104",
            "districtname": "นางรอง"
        },
        {
            "provincecode": "31",
            "districtcode": "3105",
            "districtname": "หนองกี่"
        },
        {
            "provincecode": "31",
            "districtcode": "3106",
            "districtname": "ละหานทราย"
        },
        {
            "provincecode": "31",
            "districtcode": "3107",
            "districtname": "ประโคนชัย"
        },
        {
            "provincecode": "31",
            "districtcode": "3108",
            "districtname": "บ้านกรวด"
        },
        {
            "provincecode": "31",
            "districtcode": "3109",
            "districtname": "พุทไธสง"
        },
        {
            "provincecode": "31",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ"
        },
        {
            "provincecode": "31",
            "districtcode": "3111",
            "districtname": "สตึก"
        },
        {
            "provincecode": "31",
            "districtcode": "3112",
            "districtname": "ปะคำ"
        },
        {
            "provincecode": "31",
            "districtcode": "3113",
            "districtname": "นาโพธิ์"
        },
        {
            "provincecode": "31",
            "districtcode": "3114",
            "districtname": "หนองหงส์"
        },
        {
            "provincecode": "31",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย"
        },
        {
            "provincecode": "31",
            "districtcode": "3116",
            "districtname": "ห้วยราช"
        },
        {
            "provincecode": "31",
            "districtcode": "3117",
            "districtname": "โนนสุวรรณ"
        },
        {
            "provincecode": "31",
            "districtcode": "3118",
            "districtname": "ชำนิ"
        },
        {
            "provincecode": "31",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์"
        },
        {
            "provincecode": "31",
            "districtcode": "3120",
            "districtname": "โนนดินแดง"
        },
        {
            "provincecode": "31",
            "districtcode": "3121",
            "districtname": "บ้านด่าน"
        },
        {
            "provincecode": "31",
            "districtcode": "3122",
            "districtname": "แคนดง"
        },
        {
            "provincecode": "31",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ"
        },
        {
            "provincecode": "32",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์"
        },
        {
            "provincecode": "32",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี"
        },
        {
            "provincecode": "32",
            "districtcode": "3203",
            "districtname": "ท่าตูม"
        },
        {
            "provincecode": "32",
            "districtcode": "3204",
            "districtname": "จอมพระ"
        },
        {
            "provincecode": "32",
            "districtcode": "3205",
            "districtname": "ปราสาท"
        },
        {
            "provincecode": "32",
            "districtcode": "3206",
            "districtname": "กาบเชิง"
        },
        {
            "provincecode": "32",
            "districtcode": "3207",
            "districtname": "รัตนบุรี"
        },
        {
            "provincecode": "32",
            "districtcode": "3208",
            "districtname": "สนม"
        },
        {
            "provincecode": "32",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ"
        },
        {
            "provincecode": "32",
            "districtcode": "3210",
            "districtname": "สังขะ"
        },
        {
            "provincecode": "32",
            "districtcode": "3211",
            "districtname": "ลำดวน"
        },
        {
            "provincecode": "32",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ"
        },
        {
            "provincecode": "32",
            "districtcode": "3213",
            "districtname": "บัวเชด"
        },
        {
            "provincecode": "32",
            "districtcode": "3214",
            "districtname": "พนมดงรัก"
        },
        {
            "provincecode": "32",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์"
        },
        {
            "provincecode": "32",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์"
        },
        {
            "provincecode": "32",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์"
        },
        {
            "provincecode": "33",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ"
        },
        {
            "provincecode": "33",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย"
        },
        {
            "provincecode": "33",
            "districtcode": "3303",
            "districtname": "กันทรารมย์"
        },
        {
            "provincecode": "33",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์"
        },
        {
            "provincecode": "33",
            "districtcode": "3305",
            "districtname": "ขุขันธ์"
        },
        {
            "provincecode": "33",
            "districtcode": "3306",
            "districtname": "ไพรบึง"
        },
        {
            "provincecode": "33",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่"
        },
        {
            "provincecode": "33",
            "districtcode": "3308",
            "districtname": "ขุนหาญ"
        },
        {
            "provincecode": "33",
            "districtcode": "3309",
            "districtname": "ราษีไศล"
        },
        {
            "provincecode": "33",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย"
        },
        {
            "provincecode": "33",
            "districtcode": "3311",
            "districtname": "บึงบูรพ์"
        },
        {
            "provincecode": "33",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน"
        },
        {
            "provincecode": "33",
            "districtcode": "3313",
            "districtname": "โนนคูณ"
        },
        {
            "provincecode": "33",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ"
        },
        {
            "provincecode": "33",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง"
        },
        {
            "provincecode": "33",
            "districtcode": "3316",
            "districtname": "วังหิน"
        },
        {
            "provincecode": "33",
            "districtcode": "3317",
            "districtname": "ภูสิงห์"
        },
        {
            "provincecode": "33",
            "districtcode": "3318",
            "districtname": "เมืองจันทร์"
        },
        {
            "provincecode": "33",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์"
        },
        {
            "provincecode": "33",
            "districtcode": "3320",
            "districtname": "พยุห์"
        },
        {
            "provincecode": "33",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ"
        },
        {
            "provincecode": "33",
            "districtcode": "3322",
            "districtname": "ศิลาลาด"
        },
        {
            "provincecode": "34",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี"
        },
        {
            "provincecode": "34",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่"
        },
        {
            "provincecode": "34",
            "districtcode": "3403",
            "districtname": "โขงเจียม"
        },
        {
            "provincecode": "34",
            "districtcode": "3404",
            "districtname": "เขื่องใน"
        },
        {
            "provincecode": "34",
            "districtcode": "3405",
            "districtname": "เขมราฐ"
        },
        {
            "provincecode": "34",
            "districtcode": "3406",
            "districtname": "ชานุมาน"
        },
        {
            "provincecode": "34",
            "districtcode": "3407",
            "districtname": "เดชอุดม"
        },
        {
            "provincecode": "34",
            "districtcode": "3408",
            "districtname": "นาจะหลวย"
        },
        {
            "provincecode": "34",
            "districtcode": "3409",
            "districtname": "น้ำยืน"
        },
        {
            "provincecode": "34",
            "districtcode": "3410",
            "districtname": "บุณฑริก"
        },
        {
            "provincecode": "34",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล"
        },
        {
            "provincecode": "34",
            "districtcode": "3412",
            "districtname": "กุดข้าวปุ้น"
        },
        {
            "provincecode": "34",
            "districtcode": "3413",
            "districtname": "พนา"
        },
        {
            "provincecode": "34",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ"
        },
        {
            "provincecode": "34",
            "districtcode": "3415",
            "districtname": "วารินชำราบ"
        },
        {
            "provincecode": "34",
            "districtcode": "3416",
            "districtname": "อำนาจเจริญ"
        },
        {
            "provincecode": "34",
            "districtcode": "3417",
            "districtname": "���สนางคนิคม"
        },
        {
            "provincecode": "34",
            "districtcode": "3418",
            "districtname": "หัวตะพาน"
        },
        {
            "provincecode": "34",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร"
        },
        {
            "provincecode": "34",
            "districtcode": "3420",
            "districtname": "ตาลสุม"
        },
        {
            "provincecode": "34",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร"
        },
        {
            "provincecode": "34",
            "districtcode": "3422",
            "districtname": "สำโรง"
        },
        {
            "provincecode": "34",
            "districtcode": "3423",
            "districtname": "กิ่งอำเภอลืออำนาจ"
        },
        {
            "provincecode": "34",
            "districtcode": "3424",
            "districtname": "ดอนมดแดง"
        },
        {
            "provincecode": "34",
            "districtcode": "3425",
            "districtname": "สิรินธร"
        },
        {
            "provincecode": "34",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม"
        },
        {
            "provincecode": "34",
            "districtcode": "3427",
            "districtname": "ปทุมราชวงศา"
        },
        {
            "provincecode": "34",
            "districtcode": "3428",
            "districtname": "กิ่งอำเภอศรีหลักชัย"
        },
        {
            "provincecode": "34",
            "districtcode": "3429",
            "districtname": "นาเยีย"
        },
        {
            "provincecode": "34",
            "districtcode": "3430",
            "districtname": "นาตาล"
        },
        {
            "provincecode": "34",
            "districtcode": "3431",
            "districtname": "เหล่าเสือโก้ก"
        },
        {
            "provincecode": "34",
            "districtcode": "3432",
            "districtname": "สว่างวีระวงศ์"
        },
        {
            "provincecode": "34",
            "districtcode": "3433",
            "districtname": "น้ำขุ่น"
        },
        {
            "provincecode": "34",
            "districtcode": "3481",
            "districtname": "อ.สุวรรณวารี จ.อุบลราชธานี"
        },
        {
            "provincecode": "35",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร"
        },
        {
            "provincecode": "35",
            "districtcode": "3502",
            "districtname": "ทรายมูล"
        },
        {
            "provincecode": "35",
            "districtcode": "3503",
            "districtname": "กุดชุม"
        },
        {
            "provincecode": "35",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว"
        },
        {
            "provincecode": "35",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว"
        },
        {
            "provincecode": "35",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย"
        },
        {
            "provincecode": "35",
            "districtcode": "3507",
            "districtname": "ค้อวัง"
        },
        {
            "provincecode": "35",
            "districtcode": "3508",
            "districtname": "เลิงนกทา"
        },
        {
            "provincecode": "35",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ"
        },
        {
            "provincecode": "36",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ"
        },
        {
            "provincecode": "36",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า"
        },
        {
            "provincecode": "36",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์"
        },
        {
            "provincecode": "36",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์"
        },
        {
            "provincecode": "36",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง"
        },
        {
            "provincecode": "36",
            "districtcode": "3606",
            "districtname": "จัตุรัส"
        },
        {
            "provincecode": "36",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์"
        },
        {
            "provincecode": "36",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว"
        },
        {
            "provincecode": "36",
            "districtcode": "3609",
            "districtname": "เทพสถิต"
        },
        {
            "provincecode": "36",
            "districtcode": "3610",
            "districtname": "ภูเขียว"
        },
        {
            "provincecode": "36",
            "districtcode": "3611",
            "districtname": "บ้านแท่น"
        },
        {
            "provincecode": "36",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ"
        },
        {
            "provincecode": "36",
            "districtcode": "3613",
            "districtname": "คอนสาร"
        },
        {
            "provincecode": "36",
            "districtcode": "3614",
            "districtname": "ภักดีชุมพล"
        },
        {
            "provincecode": "36",
            "districtcode": "3615",
            "districtname": "เนินสง่า"
        },
        {
            "provincecode": "36",
            "districtcode": "3616",
            "districtname": "ซับใหญ่"
        },
        {
            "provincecode": "36",
            "districtcode": "3651",
            "districtname": "เมืองชัยภูมิ (สาขาตำบลโนนสำราญ)"
        },
        {
            "provincecode": "36",
            "districtcode": "3652",
            "districtname": "สาขาตำบลบ้านหว่าเฒ่า"
        },
        {
            "provincecode": "36",
            "districtcode": "3653",
            "districtname": "หนองบัวแดง (สาขาตำบลวังชมภู)"
        },
        {
            "provincecode": "36",
            "districtcode": "3654",
            "districtname": "กิ่งอำเภอซับใหญ่ (สาขาตำบลซับใหญ่)"
        },
        {
            "provincecode": "36",
            "districtcode": "3655",
            "districtname": "สาขาตำบลโคกเพชร"
        },
        {
            "provincecode": "36",
            "districtcode": "3656",
            "districtname": "เทพสถิต (สาขาตำบลนายางกลัก)"
        },
        {
            "provincecode": "36",
            "districtcode": "3657",
            "districtname": "บ้านแท่น (สาขาตำบลบ้านเต่า)"
        },
        {
            "provincecode": "36",
            "districtcode": "3658",
            "districtname": "แก้งคร้อ (สาขาตำบลท่ามะไฟหวาน)"
        },
        {
            "provincecode": "36",
            "districtcode": "3659",
            "districtname": "คอนสาร (สาขาตำบลโนนคูณ)"
        },
        {
            "provincecode": "37",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ"
        },
        {
            "provincecode": "37",
            "districtcode": "3702",
            "districtname": "ชานุมาน"
        },
        {
            "provincecode": "37",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา"
        },
        {
            "provincecode": "37",
            "districtcode": "3704",
            "districtname": "พนา"
        },
        {
            "provincecode": "37",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม"
        },
        {
            "provincecode": "37",
            "districtcode": "3706",
            "districtname": "หัวตะพาน"
        },
        {
            "provincecode": "37",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ"
        },
        {
            "provincecode": "39",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู"
        },
        {
            "provincecode": "39",
            "districtcode": "3902",
            "districtname": "นากลาง"
        },
        {
            "provincecode": "39",
            "districtcode": "3903",
            "districtname": "โนนสัง"
        },
        {
            "provincecode": "39",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง"
        },
        {
            "provincecode": "39",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา"
        },
        {
            "provincecode": "39",
            "districtcode": "3906",
            "districtname": "นาวัง"
        },
        {
            "provincecode": "40",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น"
        },
        {
            "provincecode": "40",
            "districtcode": "4002",
            "districtname": "บ้านฝาง"
        },
        {
            "provincecode": "40",
            "districtcode": "4003",
            "districtname": "พระยืน"
        },
        {
            "provincecode": "40",
            "districtcode": "4004",
            "districtname": "หนองเรือ"
        },
        {
            "provincecode": "40",
            "districtcode": "4005",
            "districtname": "ชุมแพ"
        },
        {
            "provincecode": "40",
            "districtcode": "4006",
            "districtname": "สีชมพู"
        },
        {
            "provincecode": "40",
            "districtcode": "4007",
            "districtname": "น้ำพอง"
        },
        {
            "provincecode": "40",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์"
        },
        {
            "provincecode": "40",
            "districtcode": "4009",
            "districtname": "กระนวน"
        },
        {
            "provincecode": "40",
            "districtcode": "4010",
            "districtname": "บ้านไผ่"
        },
        {
            "provincecode": "40",
            "districtcode": "4011",
            "districtname": "เปือยน้อย"
        },
        {
            "provincecode": "40",
            "districtcode": "4012",
            "districtname": "พล"
        },
        {
            "provincecode": "40",
            "districtcode": "4013",
            "districtname": "แวงใหญ่"
        },
        {
            "provincecode": "40",
            "districtcode": "4014",
            "districtname": "แวงน้อย"
        },
        {
            "provincecode": "40",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง"
        },
        {
            "provincecode": "40",
            "districtcode": "4016",
            "districtname": "ภูเวียง"
        },
        {
            "provincecode": "40",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี"
        },
        {
            "provincecode": "40",
            "districtcode": "4018",
            "districtname": "ชนบท"
        },
        {
            "provincecode": "40",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง"
        },
        {
            "provincecode": "40",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน"
        },
        {
            "provincecode": "40",
            "districtcode": "4021",
            "districtname": "ซำสูง"
        },
        {
            "provincecode": "40",
            "districtcode": "4022",
            "districtname": "โคกโพธิ์ไชย"
        },
        {
            "provincecode": "40",
            "districtcode": "4023",
            "districtname": "หนองนาคำ"
        },
        {
            "provincecode": "40",
            "districtcode": "4024",
            "districtname": "บ้านแฮด"
        },
        {
            "provincecode": "40",
            "districtcode": "4025",
            "districtname": "โนนศิลา"
        },
        {
            "provincecode": "40",
            "districtcode": "4029",
            "districtname": "เวียงเก่า"
        },
        {
            "provincecode": "40",
            "districtcode": "4068",
            "districtname": "ท้องถิ่นเทศบาลตำบลบ้านเป็ด"
        },
        {
            "provincecode": "40",
            "districtcode": "4098",
            "districtname": "เทศบาลตำบลเมืองพล"
        },
        {
            "provincecode": "41",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี"
        },
        {
            "provincecode": "41",
            "districtcode": "4102",
            "districtname": "กุดจับ"
        },
        {
            "provincecode": "41",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ"
        },
        {
            "provincecode": "41",
            "districtcode": "4104",
            "districtname": "กุมภวาปี"
        },
        {
            "provincecode": "41",
            "districtcode": "4105",
            "districtname": "โนนสะอาด"
        },
        {
            "provincecode": "41",
            "districtcode": "4106",
            "districtname": "หนองหาน"
        },
        {
            "provincecode": "41",
            "districtcode": "4107",
            "districtname": "ทุ่งฝน"
        },
        {
            "provincecode": "41",
            "districtcode": "4108",
            "districtname": "ไชยวาน"
        },
        {
            "provincecode": "41",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ"
        },
        {
            "provincecode": "41",
            "districtcode": "4110",
            "districtname": "วังสามหมอ"
        },
        {
            "provincecode": "41",
            "districtcode": "4111",
            "districtname": "บ้านดุง"
        },
        {
            "provincecode": "41",
            "districtcode": "4112",
            "districtname": "หนองบัวลำภู"
        },
        {
            "provincecode": "41",
            "districtcode": "4113",
            "districtname": "ศรีบุญเรือง"
        },
        {
            "provincecode": "41",
            "districtcode": "4114",
            "districtname": "นากลาง"
        },
        {
            "provincecode": "41",
            "districtcode": "4115",
            "districtname": "สุวรรณคูหา"
        },
        {
            "provincecode": "41",
            "districtcode": "4116",
            "districtname": "โนนสัง"
        },
        {
            "provincecode": "41",
            "districtcode": "4117",
            "districtname": "บ้านผือ"
        },
        {
            "provincecode": "41",
            "districtcode": "4118",
            "districtname": "น้ำโสม"
        },
        {
            "provincecode": "41",
            "districtcode": "4119",
            "districtname": "เพ็ญ"
        },
        {
            "provincecode": "41",
            "districtcode": "4120",
            "districtname": "สร้างคอม"
        },
        {
            "provincecode": "41",
            "districtcode": "4121",
            "districtname": "หนองแสง"
        },
        {
            "provincecode": "41",
            "districtcode": "4122",
            "districtname": "นายูง"
        },
        {
            "provincecode": "41",
            "districtcode": "4123",
            "districtname": "พิบูลย์รักษ์"
        },
        {
            "provincecode": "41",
            "districtcode": "4124",
            "districtname": "กู่แก้ว"
        },
        {
            "provincecode": "41",
            "districtcode": "4125",
            "districtname": "ประจักษ์ศิลปาคม"
        },
        {
            "provincecode": "42",
            "districtcode": "4201",
            "districtname": "เมืองเลย"
        },
        {
            "provincecode": "42",
            "districtcode": "4202",
            "districtname": "นาด้วง"
        },
        {
            "provincecode": "42",
            "districtcode": "4203",
            "districtname": "เชียงคาน"
        },
        {
            "provincecode": "42",
            "districtcode": "4204",
            "districtname": "ปากชม"
        },
        {
            "provincecode": "42",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย"
        },
        {
            "provincecode": "42",
            "districtcode": "4206",
            "districtname": "นาแห้ว"
        },
        {
            "provincecode": "42",
            "districtcode": "4207",
            "districtname": "ภูเรือ"
        },
        {
            "provincecode": "42",
            "districtcode": "4208",
            "districtname": "ท่าลี่"
        },
        {
            "provincecode": "42",
            "districtcode": "4209",
            "districtname": "วังสะพุง"
        },
        {
            "provincecode": "42",
            "districtcode": "4210",
            "districtname": "ภูกระดึง"
        },
        {
            "provincecode": "42",
            "districtcode": "4211",
            "districtname": "ภูหลวง"
        },
        {
            "provincecode": "42",
            "districtcode": "4212",
            "districtname": "ผาขาว"
        },
        {
            "provincecode": "42",
            "districtcode": "4213",
            "districtname": "เอราวัณ"
        },
        {
            "provincecode": "42",
            "districtcode": "4214",
            "districtname": "หนองหิน"
        },
        {
            "provincecode": "43",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย"
        },
        {
            "provincecode": "43",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ"
        },
        {
            "provincecode": "43",
            "districtcode": "4305",
            "districtname": "โพนพิสัย"
        },
        {
            "provincecode": "43",
            "districtcode": "4307",
            "districtname": "ศรีเชียงใหม่"
        },
        {
            "provincecode": "43",
            "districtcode": "4308",
            "districtname": "สังคม"
        },
        {
            "provincecode": "43",
            "districtcode": "4314",
            "districtname": "สระใคร"
        },
        {
            "provincecode": "43",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่"
        },
        {
            "provincecode": "43",
            "districtcode": "4316",
            "districtname": "รัตนวาปี"
        },
        {
            "provincecode": "43",
            "districtcode": "4317",
            "districtname": "โพธิ์ตาก"
        },
        {
            "provincecode": "44",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม"
        },
        {
            "provincecode": "44",
            "districtcode": "4402",
            "districtname": "แกดำ"
        },
        {
            "provincecode": "44",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย"
        },
        {
            "provincecode": "44",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย"
        },
        {
            "provincecode": "44",
            "districtcode": "4405",
            "districtname": "เชียงยืน"
        },
        {
            "provincecode": "44",
            "districtcode": "4406",
            "districtname": "บรบือ"
        },
        {
            "provincecode": "44",
            "districtcode": "4407",
            "districtname": "นาเชือก"
        },
        {
            "provincecode": "44",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย"
        },
        {
            "provincecode": "44",
            "districtcode": "4409",
            "districtname": "วาปีปทุม"
        },
        {
            "provincecode": "44",
            "districtcode": "4410",
            "districtname": "นาดูน"
        },
        {
            "provincecode": "44",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช"
        },
        {
            "provincecode": "44",
            "districtcode": "4412",
            "districtname": "กุดรัง"
        },
        {
            "provincecode": "44",
            "districtcode": "4413",
            "districtname": "ชื่นชม"
        },
        {
            "provincecode": "44",
            "districtcode": "4481",
            "districtname": "หลุบ"
        },
        {
            "provincecode": "45",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด"
        },
        {
            "provincecode": "45",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย"
        },
        {
            "provincecode": "45",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์"
        },
        {
            "provincecode": "45",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน"
        },
        {
            "provincecode": "45",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี"
        },
        {
            "provincecode": "45",
            "districtcode": "4506",
            "districtname": "พนมไพร"
        },
        {
            "provincecode": "45",
            "districtcode": "4507",
            "districtname": "โพนทอง"
        },
        {
            "provincecode": "45",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย"
        },
        {
            "provincecode": "45",
            "districtcode": "4509",
            "districtname": "หนองพอก"
        },
        {
            "provincecode": "45",
            "districtcode": "4510",
            "districtname": "เสลภูมิ"
        },
        {
            "provincecode": "45",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ"
        },
        {
            "provincecode": "45",
            "districtcode": "4512",
            "districtname": "เมืองสรวง"
        },
        {
            "provincecode": "45",
            "districtcode": "4513",
            "districtname": "โพนทราย"
        },
        {
            "provincecode": "45",
            "districtcode": "4514",
            "districtname": "อาจสามารถ"
        },
        {
            "provincecode": "45",
            "districtcode": "4515",
            "districtname": "เมยวดี"
        },
        {
            "provincecode": "45",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ"
        },
        {
            "provincecode": "45",
            "districtcode": "4517",
            "districtname": "จังหาร"
        },
        {
            "provincecode": "45",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ"
        },
        {
            "provincecode": "45",
            "districtcode": "4519",
            "districtname": "หนองฮี"
        },
        {
            "provincecode": "45",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง"
        },
        {
            "provincecode": "46",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์"
        },
        {
            "provincecode": "46",
            "districtcode": "4602",
            "districtname": "นามน"
        },
        {
            "provincecode": "46",
            "districtcode": "4603",
            "districtname": "กมลาไสย"
        },
        {
            "provincecode": "46",
            "districtcode": "4604",
            "districtname": "ร่องคำ"
        },
        {
            "provincecode": "46",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์"
        },
        {
            "provincecode": "46",
            "districtcode": "4606",
            "districtname": "เขาวง"
        },
        {
            "provincecode": "46",
            "districtcode": "4607",
            "districtname": "ยางตลาด"
        },
        {
            "provincecode": "46",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก"
        },
        {
            "provincecode": "46",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์"
        },
        {
            "provincecode": "46",
            "districtcode": "4610",
            "districtname": "คำม่วง"
        },
        {
            "provincecode": "46",
            "districtcode": "4611",
            "districtname": "ท่าคันโท"
        },
        {
            "provincecode": "46",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี"
        },
        {
            "provincecode": "46",
            "districtcode": "4613",
            "districtname": "สมเด็จ"
        },
        {
            "provincecode": "46",
            "districtcode": "4614",
            "districtname": "ห้วยผึ้ง"
        },
        {
            "provincecode": "46",
            "districtcode": "4615",
            "districtname": "สามชัย"
        },
        {
            "provincecode": "46",
            "districtcode": "4616",
            "districtname": "นาคู"
        },
        {
            "provincecode": "46",
            "districtcode": "4617",
            "districtname": "ดอนจาน"
        },
        {
            "provincecode": "46",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย"
        },
        {
            "provincecode": "47",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร"
        },
        {
            "provincecode": "47",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์"
        },
        {
            "provincecode": "47",
            "districtcode": "4703",
            "districtname": "กุดบาก"
        },
        {
            "provincecode": "47",
            "districtcode": "4704",
            "districtname": "พรรณานิคม"
        },
        {
            "provincecode": "47",
            "districtcode": "4705",
            "districtname": "พังโคน"
        },
        {
            "provincecode": "47",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ"
        },
        {
            "provincecode": "47",
            "districtcode": "4707",
            "districtname": "นิคมน้ำอูน"
        },
        {
            "provincecode": "47",
            "districtcode": "4708",
            "districtname": "วานรนิวาส"
        },
        {
            "provincecode": "47",
            "districtcode": "4709",
            "districtname": "คำตากล้า"
        },
        {
            "provincecode": "47",
            "districtcode": "4710",
            "districtname": "บ้านม่วง"
        },
        {
            "provincecode": "47",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย"
        },
        {
            "provincecode": "47",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน"
        },
        {
            "provincecode": "47",
            "districtcode": "4713",
            "districtname": "ส่องดาว"
        },
        {
            "provincecode": "47",
            "districtcode": "4714",
            "districtname": "เต่างอย"
        },
        {
            "provincecode": "47",
            "districtcode": "4715",
            "districtname": "โคกศรีสุพรรณ"
        },
        {
            "provincecode": "47",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์"
        },
        {
            "provincecode": "47",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว"
        },
        {
            "provincecode": "47",
            "districtcode": "4718",
            "districtname": "ภูพาน"
        },
        {
            "provincecode": "47",
            "districtcode": "4751",
            "districtname": "วานรนิวาส (สาขาตำบลกุดเรือคำ)"
        },
        {
            "provincecode": "47",
            "districtcode": "4781",
            "districtname": "อ.บ้านหัน จ.สกลนคร"
        },
        {
            "provincecode": "48",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม"
        },
        {
            "provincecode": "48",
            "districtcode": "4802",
            "districtname": "ปลาปาก"
        },
        {
            "provincecode": "48",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน"
        },
        {
            "provincecode": "48",
            "districtcode": "4804",
            "districtname": "บ้านแพง"
        },
        {
            "provincecode": "48",
            "districtcode": "4805",
            "districtname": "ธาตุพนม"
        },
        {
            "provincecode": "48",
            "districtcode": "4806",
            "districtname": "เรณูนคร"
        },
        {
            "provincecode": "48",
            "districtcode": "4807",
            "districtname": "นาแก"
        },
        {
            "provincecode": "48",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม"
        },
        {
            "provincecode": "48",
            "districtcode": "4809",
            "districtname": "นาหว้า"
        },
        {
            "provincecode": "48",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์"
        },
        {
            "provincecode": "48",
            "districtcode": "4811",
            "districtname": "นาทม"
        },
        {
            "provincecode": "48",
            "districtcode": "4812",
            "districtname": "วังยาง"
        },
        {
            "provincecode": "49",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร"
        },
        {
            "provincecode": "49",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย"
        },
        {
            "provincecode": "49",
            "districtcode": "4903",
            "districtname": "ดอนตาล"
        },
        {
            "provincecode": "49",
            "districtcode": "4904",
            "districtname": "ดงหลวง"
        },
        {
            "provincecode": "49",
            "districtcode": "4905",
            "districtname": "คำชะอี"
        },
        {
            "provincecode": "49",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่"
        },
        {
            "provincecode": "49",
            "districtcode": "4907",
            "districtname": "หนองสูง"
        },
        {
            "provincecode": "50",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่"
        },
        {
            "provincecode": "50",
            "districtcode": "5002",
            "districtname": "จอมทอง"
        },
        {
            "provincecode": "50",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม"
        },
        {
            "provincecode": "50",
            "districtcode": "5004",
            "districtname": "เชียงดาว"
        },
        {
            "provincecode": "50",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด"
        },
        {
            "provincecode": "50",
            "districtcode": "5006",
            "districtname": "แม่แตง"
        },
        {
            "provincecode": "50",
            "districtcode": "5007",
            "districtname": "แม่ริม"
        },
        {
            "provincecode": "50",
            "districtcode": "5008",
            "districtname": "สะเมิง"
        },
        {
            "provincecode": "50",
            "districtcode": "5009",
            "districtname": "ฝาง"
        },
        {
            "provincecode": "50",
            "districtcode": "5010",
            "districtname": "แม่อาย"
        },
        {
            "provincecode": "50",
            "districtcode": "5011",
            "districtname": "พร้าว"
        },
        {
            "provincecode": "50",
            "districtcode": "5012",
            "districtname": "สันป่าตอง"
        },
        {
            "provincecode": "50",
            "districtcode": "5013",
            "districtname": "สันกำแพง"
        },
        {
            "provincecode": "50",
            "districtcode": "5014",
            "districtname": "สันทราย"
        },
        {
            "provincecode": "50",
            "districtcode": "5015",
            "districtname": "หางดง"
        },
        {
            "provincecode": "50",
            "districtcode": "5016",
            "districtname": "ฮอด"
        },
        {
            "provincecode": "50",
            "districtcode": "5017",
            "districtname": "ดอยเต่า"
        },
        {
            "provincecode": "50",
            "districtcode": "5018",
            "districtname": "อมก๋อย"
        },
        {
            "provincecode": "50",
            "districtcode": "5019",
            "districtname": "สารภี"
        },
        {
            "provincecode": "50",
            "districtcode": "5020",
            "districtname": "เวียงแหง"
        },
        {
            "provincecode": "50",
            "districtcode": "5021",
            "districtname": "ไชยปราการ"
        },
        {
            "provincecode": "50",
            "districtcode": "5022",
            "districtname": "แม่วาง"
        },
        {
            "provincecode": "50",
            "districtcode": "5023",
            "districtname": "แม่ออน"
        },
        {
            "provincecode": "50",
            "districtcode": "5024",
            "districtname": "ดอยหล่อ"
        },
        {
            "provincecode": "50",
            "districtcode": "5051",
            "districtname": "เทศบาลนครเชียงใหม่ (สาขาแขวงกาลวิละ"
        },
        {
            "provincecode": "50",
            "districtcode": "5052",
            "districtname": "เทศบาลนครเชียงใหม่ (สาขาแขวงศรีวิชั"
        },
        {
            "provincecode": "50",
            "districtcode": "5053",
            "districtname": "เทศบาลนครเชียงใหม่ (สาขาเม็งราย"
        },
        {
            "provincecode": "51",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน"
        },
        {
            "provincecode": "51",
            "districtcode": "5102",
            "districtname": "แม่ทา"
        },
        {
            "provincecode": "51",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง"
        },
        {
            "provincecode": "51",
            "districtcode": "5104",
            "districtname": "ลี้"
        },
        {
            "provincecode": "51",
            "districtcode": "5105",
            "districtname": "ทุ่งหัวช้าง"
        },
        {
            "provincecode": "51",
            "districtcode": "5106",
            "districtname": "ป่าซาง"
        },
        {
            "provincecode": "51",
            "districtcode": "5107",
            "districtname": "บ้านธิ"
        },
        {
            "provincecode": "51",
            "districtcode": "5108",
            "districtname": "เวียงหนองล่อง"
        },
        {
            "provincecode": "52",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง"
        },
        {
            "provincecode": "52",
            "districtcode": "5202",
            "districtname": "แม่เมาะ"
        },
        {
            "provincecode": "52",
            "districtcode": "5203",
            "districtname": "เกาะคา"
        },
        {
            "provincecode": "52",
            "districtcode": "5204",
            "districtname": "เสริมงาม"
        },
        {
            "provincecode": "52",
            "districtcode": "5205",
            "districtname": "งาว"
        },
        {
            "provincecode": "52",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม"
        },
        {
            "provincecode": "52",
            "districtcode": "5207",
            "districtname": "วังเหนือ"
        },
        {
            "provincecode": "52",
            "districtcode": "5208",
            "districtname": "เถิน"
        },
        {
            "provincecode": "52",
            "districtcode": "5209",
            "districtname": "แม่พริก"
        },
        {
            "provincecode": "52",
            "districtcode": "5210",
            "districtname": "แม่ทะ"
        },
        {
            "provincecode": "52",
            "districtcode": "5211",
            "districtname": "สบปราบ"
        },
        {
            "provincecode": "52",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร"
        },
        {
            "provincecode": "52",
            "districtcode": "5213",
            "districtname": "เมืองปาน"
        },
        {
            "provincecode": "53",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์"
        },
        {
            "provincecode": "53",
            "districtcode": "5302",
            "districtname": "ตรอน"
        },
        {
            "provincecode": "53",
            "districtcode": "5303",
            "districtname": "ท่าปลา"
        },
        {
            "provincecode": "53",
            "districtcode": "5304",
            "districtname": "น้ำปาด"
        },
        {
            "provincecode": "53",
            "districtcode": "5305",
            "districtname": "ฟากท่า"
        },
        {
            "provincecode": "53",
            "districtcode": "5306",
            "districtname": "บ้านโคก"
        },
        {
            "provincecode": "53",
            "districtcode": "5307",
            "districtname": "พิชัย"
        },
        {
            "provincecode": "53",
            "districtcode": "5308",
            "districtname": "ลับแล"
        },
        {
            "provincecode": "53",
            "districtcode": "5309",
            "districtname": "ทองแสนขัน"
        },
        {
            "provincecode": "54",
            "districtcode": "5401",
            "districtname": "เมืองแพร่"
        },
        {
            "provincecode": "54",
            "districtcode": "5402",
            "districtname": "ร้องกวาง"
        },
        {
            "provincecode": "54",
            "districtcode": "5403",
            "districtname": "ลอง"
        },
        {
            "provincecode": "54",
            "districtcode": "5404",
            "districtname": "สูงเม่น"
        },
        {
            "provincecode": "54",
            "districtcode": "5405",
            "districtname": "เด่นชัย"
        },
        {
            "provincecode": "54",
            "districtcode": "5406",
            "districtname": "สอง"
        },
        {
            "provincecode": "54",
            "districtcode": "5407",
            "districtname": "วังชิ้น"
        },
        {
            "provincecode": "54",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่"
        },
        {
            "provincecode": "55",
            "districtcode": "5501",
            "districtname": "เมืองน่าน"
        },
        {
            "provincecode": "55",
            "districtcode": "5502",
            "districtname": "แม่จริม"
        },
        {
            "provincecode": "55",
            "districtcode": "5503",
            "districtname": "บ้านหลวง"
        },
        {
            "provincecode": "55",
            "districtcode": "5504",
            "districtname": "นาน้อย"
        },
        {
            "provincecode": "55",
            "districtcode": "5505",
            "districtname": "ปัว"
        },
        {
            "provincecode": "55",
            "districtcode": "5506",
            "districtname": "ท่าวังผา"
        },
        {
            "provincecode": "55",
            "districtcode": "5507",
            "districtname": "เวียงสา"
        },
        {
            "provincecode": "55",
            "districtcode": "5508",
            "districtname": "ทุ่งช้าง"
        },
        {
            "provincecode": "55",
            "districtcode": "5509",
            "districtname": "เชียงกลาง"
        },
        {
            "provincecode": "55",
            "districtcode": "5510",
            "districtname": "นาหมื่น"
        },
        {
            "provincecode": "55",
            "districtcode": "5511",
            "districtname": "สันติสุข"
        },
        {
            "provincecode": "55",
            "districtcode": "5512",
            "districtname": "บ่อเกลือ"
        },
        {
            "provincecode": "55",
            "districtcode": "5513",
            "districtname": "สองแคว"
        },
        {
            "provincecode": "55",
            "districtcode": "5514",
            "districtname": "ภูเพียง"
        },
        {
            "provincecode": "55",
            "districtcode": "5515",
            "districtname": "เฉลิมพระเกียรติ"
        },
        {
            "provincecode": "56",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา"
        },
        {
            "provincecode": "56",
            "districtcode": "5602",
            "districtname": "จุน"
        },
        {
            "provincecode": "56",
            "districtcode": "5603",
            "districtname": "เชียงคำ"
        },
        {
            "provincecode": "56",
            "districtcode": "5604",
            "districtname": "เชียงม่วน"
        },
        {
            "provincecode": "56",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้"
        },
        {
            "provincecode": "56",
            "districtcode": "5606",
            "districtname": "ปง"
        },
        {
            "provincecode": "56",
            "districtcode": "5607",
            "districtname": "แม่ใจ"
        },
        {
            "provincecode": "56",
            "districtcode": "5608",
            "districtname": "ภูซาง"
        },
        {
            "provincecode": "56",
            "districtcode": "5609",
            "districtname": "ภูกามยาว"
        },
        {
            "provincecode": "57",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย"
        },
        {
            "provincecode": "57",
            "districtcode": "5702",
            "districtname": "เวียงชัย"
        },
        {
            "provincecode": "57",
            "districtcode": "5703",
            "districtname": "เชียงของ"
        },
        {
            "provincecode": "57",
            "districtcode": "5704",
            "districtname": "เทิง"
        },
        {
            "provincecode": "57",
            "districtcode": "5705",
            "districtname": "พาน"
        },
        {
            "provincecode": "57",
            "districtcode": "5706",
            "districtname": "ป่าแดด"
        },
        {
            "provincecode": "57",
            "districtcode": "5707",
            "districtname": "แม่จัน"
        },
        {
            "provincecode": "57",
            "districtcode": "5708",
            "districtname": "เชียงแสน"
        },
        {
            "provincecode": "57",
            "districtcode": "5709",
            "districtname": "แม่สาย"
        },
        {
            "provincecode": "57",
            "districtcode": "5710",
            "districtname": "แม่สรวย"
        },
        {
            "provincecode": "57",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า"
        },
        {
            "provincecode": "57",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย"
        },
        {
            "provincecode": "57",
            "districtcode": "5713",
            "districtname": "เวียงแก่น"
        },
        {
            "provincecode": "57",
            "districtcode": "5714",
            "districtname": "ขุนตาล"
        },
        {
            "provincecode": "57",
            "districtcode": "5715",
            "districtname": "แม่ฟ้าหลวง"
        },
        {
            "provincecode": "57",
            "districtcode": "5716",
            "districtname": "แม่ลาว"
        },
        {
            "provincecode": "57",
            "districtcode": "5717",
            "districtname": "เวียงเชียงรุ้ง"
        },
        {
            "provincecode": "57",
            "districtcode": "5718",
            "districtname": "ดอยหลวง"
        },
        {
            "provincecode": "58",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน"
        },
        {
            "provincecode": "58",
            "districtcode": "5802",
            "districtname": "ขุนยวม"
        },
        {
            "provincecode": "58",
            "districtcode": "5803",
            "districtname": "ปาย"
        },
        {
            "provincecode": "58",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง"
        },
        {
            "provincecode": "58",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย"
        },
        {
            "provincecode": "58",
            "districtcode": "5806",
            "districtname": "สบเมย"
        },
        {
            "provincecode": "58",
            "districtcode": "5807",
            "districtname": "ปางมะผ้า"
        },
        {
            "provincecode": "58",
            "districtcode": "5881",
            "districtname": "อ.ม่วยต่อ จ.แม่ฮ่องสอน"
        },
        {
            "provincecode": "60",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์"
        },
        {
            "provincecode": "60",
            "districtcode": "6002",
            "districtname": "โกรกพระ"
        },
        {
            "provincecode": "60",
            "districtcode": "6003",
            "districtname": "ชุมแสง"
        },
        {
            "provincecode": "60",
            "districtcode": "6004",
            "districtname": "หนองบัว"
        },
        {
            "provincecode": "60",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย"
        },
        {
            "provincecode": "60",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว"
        },
        {
            "provincecode": "60",
            "districtcode": "6007",
            "districtname": "ตาคลี"
        },
        {
            "provincecode": "60",
            "districtcode": "6008",
            "districtname": "ท่าตะโก"
        },
        {
            "provincecode": "60",
            "districtcode": "6009",
            "districtname": "ไพศาลี"
        },
        {
            "provincecode": "60",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี"
        },
        {
            "provincecode": "60",
            "districtcode": "6011",
            "districtname": "ลาดยาว"
        },
        {
            "provincecode": "60",
            "districtcode": "6012",
            "districtname": "ตากฟ้า"
        },
        {
            "provincecode": "60",
            "districtcode": "6013",
            "districtname": "แม่วงก์"
        },
        {
            "provincecode": "60",
            "districtcode": "6014",
            "districtname": "แม่เปิน"
        },
        {
            "provincecode": "60",
            "districtcode": "6015",
            "districtname": "ชุมตาบง"
        },
        {
            "provincecode": "60",
            "districtcode": "6051",
            "districtname": "สาขาตำบลห้วยน้ำหอม"
        },
        {
            "provincecode": "60",
            "districtcode": "6052",
            "districtname": "กิ่งอำเภอชุมตาบง (สาขาตำบลชุมตาบง)"
        },
        {
            "provincecode": "60",
            "districtcode": "6053",
            "districtname": "แม่วงก์ (สาขาตำบลแม่เล่ย์)"
        },
        {
            "provincecode": "61",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี"
        },
        {
            "provincecode": "61",
            "districtcode": "6102",
            "districtname": "ทัพทัน"
        },
        {
            "provincecode": "61",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์"
        },
        {
            "provincecode": "61",
            "districtcode": "6104",
            "districtname": "หนองฉาง"
        },
        {
            "provincecode": "61",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง"
        },
        {
            "provincecode": "61",
            "districtcode": "6106",
            "districtname": "บ้านไร่"
        },
        {
            "provincecode": "61",
            "districtcode": "6107",
            "districtname": "ลานสัก"
        },
        {
            "provincecode": "61",
            "districtcode": "6108",
            "districtname": "ห้วยคต"
        },
        {
            "provincecode": "62",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร"
        },
        {
            "provincecode": "62",
            "districtcode": "6202",
            "districtname": "ไทรงาม"
        },
        {
            "provincecode": "62",
            "districtcode": "6203",
            "districtname": "คลองลาน"
        },
        {
            "provincecode": "62",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี"
        },
        {
            "provincecode": "62",
            "districtcode": "6205",
            "districtname": "คลองขลุง"
        },
        {
            "provincecode": "62",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย"
        },
        {
            "provincecode": "62",
            "districtcode": "6207",
            "districtname": "ลานกระบือ"
        },
        {
            "provincecode": "62",
            "districtcode": "6208",
            "districtname": "ทรายทองวัฒนา"
        },
        {
            "provincecode": "62",
            "districtcode": "6209",
            "districtname": "ปางศิลาทอง"
        },
        {
            "provincecode": "62",
            "districtcode": "6210",
            "districtname": "บึงสามัคคี"
        },
        {
            "provincecode": "62",
            "districtcode": "6211",
            "districtname": "โกสัมพีนคร"
        },
        {
            "provincecode": "63",
            "districtcode": "6301",
            "districtname": "เมืองตาก"
        },
        {
            "provincecode": "63",
            "districtcode": "6302",
            "districtname": "บ้านตาก"
        },
        {
            "provincecode": "63",
            "districtcode": "6303",
            "districtname": "สามเงา"
        },
        {
            "provincecode": "63",
            "districtcode": "6304",
            "districtname": "แม่ระมาด"
        },
        {
            "provincecode": "63",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง"
        },
        {
            "provincecode": "63",
            "districtcode": "6306",
            "districtname": "แม่สอด"
        },
        {
            "provincecode": "63",
            "districtcode": "6307",
            "districtname": "พบพระ"
        },
        {
            "provincecode": "63",
            "districtcode": "6308",
            "districtname": "อุ้มผาง"
        },
        {
            "provincecode": "63",
            "districtcode": "6309",
            "districtname": "วังเจ้า"
        },
        {
            "provincecode": "63",
            "districtcode": "6381",
            "districtname": "กิ่ง อ.ท่าปุย จ.ตาก"
        },
        {
            "provincecode": "64",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย"
        },
        {
            "provincecode": "64",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย"
        },
        {
            "provincecode": "64",
            "districtcode": "6403",
            "districtname": "คีรีมาศ"
        },
        {
            "provincecode": "64",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ"
        },
        {
            "provincecode": "64",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย"
        },
        {
            "provincecode": "64",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง"
        },
        {
            "provincecode": "64",
            "districtcode": "6407",
            "districtname": "สวรรคโลก"
        },
        {
            "provincecode": "64",
            "districtcode": "6408",
            "districtname": "ศรีนคร"
        },
        {
            "provincecode": "64",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม"
        },
        {
            "provincecode": "65",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก"
        },
        {
            "provincecode": "65",
            "districtcode": "6502",
            "districtname": "นครไทย"
        },
        {
            "provincecode": "65",
            "districtcode": "6503",
            "districtname": "ชาติตระการ"
        },
        {
            "provincecode": "65",
            "districtcode": "6504",
            "districtname": "บางระกำ"
        },
        {
            "provincecode": "65",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม"
        },
        {
            "provincecode": "65",
            "districtcode": "6506",
            "districtname": "พรหมพิราม"
        },
        {
            "provincecode": "65",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์"
        },
        {
            "provincecode": "65",
            "districtcode": "6508",
            "districtname": "วังทอง"
        },
        {
            "provincecode": "65",
            "districtcode": "6509",
            "districtname": "เนินมะปราง"
        },
        {
            "provincecode": "66",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร"
        },
        {
            "provincecode": "66",
            "districtcode": "6602",
            "districtname": "วังทรายพูน"
        },
        {
            "provincecode": "66",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง"
        },
        {
            "provincecode": "66",
            "districtcode": "6604",
            "districtname": "ตะพานหิน"
        },
        {
            "provincecode": "66",
            "districtcode": "6605",
            "districtname": "บางมูลนาก"
        },
        {
            "provincecode": "66",
            "districtcode": "6606",
            "districtname": "โพทะเล"
        },
        {
            "provincecode": "66",
            "districtcode": "6607",
            "districtname": "สามง่าม"
        },
        {
            "provincecode": "66",
            "districtcode": "6608",
            "districtname": "ทับคล้อ"
        },
        {
            "provincecode": "66",
            "districtcode": "6609",
            "districtname": "สากเหล็ก"
        },
        {
            "provincecode": "66",
            "districtcode": "6610",
            "districtname": "บึงนาราง"
        },
        {
            "provincecode": "66",
            "districtcode": "6611",
            "districtname": "ดงเจริญ"
        },
        {
            "provincecode": "66",
            "districtcode": "6612",
            "districtname": "วชิรบารมี"
        },
        {
            "provincecode": "67",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์"
        },
        {
            "provincecode": "67",
            "districtcode": "6702",
            "districtname": "ชนแดน"
        },
        {
            "provincecode": "67",
            "districtcode": "6703",
            "districtname": "หล่มสัก"
        },
        {
            "provincecode": "67",
            "districtcode": "6704",
            "districtname": "หล่มเก่า"
        },
        {
            "provincecode": "67",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี"
        },
        {
            "provincecode": "67",
            "districtcode": "6706",
            "districtname": "ศรีเทพ"
        },
        {
            "provincecode": "67",
            "districtcode": "6707",
            "districtname": "หนองไผ่"
        },
        {
            "provincecode": "67",
            "districtcode": "6708",
            "districtname": "บึงสามพัน"
        },
        {
            "provincecode": "67",
            "districtcode": "6709",
            "districtname": "น้ำหนาว"
        },
        {
            "provincecode": "67",
            "districtcode": "6710",
            "districtname": "วังโป่ง"
        },
        {
            "provincecode": "67",
            "districtcode": "6711",
            "districtname": "เขาค้อ"
        },
        {
            "provincecode": "70",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี"
        },
        {
            "provincecode": "70",
            "districtcode": "7002",
            "districtname": "จอมบึง"
        },
        {
            "provincecode": "70",
            "districtcode": "7003",
            "districtname": "สวนผึ้ง"
        },
        {
            "provincecode": "70",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก"
        },
        {
            "provincecode": "70",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง"
        },
        {
            "provincecode": "70",
            "districtcode": "7006",
            "districtname": "บางแพ"
        },
        {
            "provincecode": "70",
            "districtcode": "7007",
            "districtname": "โพธาราม"
        },
        {
            "provincecode": "70",
            "districtcode": "7008",
            "districtname": "ปากท่อ"
        },
        {
            "provincecode": "70",
            "districtcode": "7009",
            "districtname": "วัดเพลง"
        },
        {
            "provincecode": "70",
            "districtcode": "7010",
            "districtname": "บ้านคา"
        },
        {
            "provincecode": "70",
            "districtcode": "7074",
            "districtname": "ท้องถิ่นเทศบาลตำบลบ้านฆ้อง"
        },
        {
            "provincecode": "71",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี"
        },
        {
            "provincecode": "71",
            "districtcode": "7102",
            "districtname": "ไทรโยค"
        },
        {
            "provincecode": "71",
            "districtcode": "7103",
            "districtname": "บ่อพลอย"
        },
        {
            "provincecode": "71",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์"
        },
        {
            "provincecode": "71",
            "districtcode": "7105",
            "districtname": "ท่ามะกา"
        },
        {
            "provincecode": "71",
            "districtcode": "7106",
            "districtname": "ท่าม่วง"
        },
        {
            "provincecode": "71",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ"
        },
        {
            "provincecode": "71",
            "districtcode": "7108",
            "districtname": "สังขละบุรี"
        },
        {
            "provincecode": "71",
            "districtcode": "7109",
            "districtname": "พนมทวน"
        },
        {
            "provincecode": "71",
            "districtcode": "7110",
            "districtname": "เลาขวัญ"
        },
        {
            "provincecode": "71",
            "districtcode": "7111",
            "districtname": "ด่านมะขามเตี้ย"
        },
        {
            "provincecode": "71",
            "districtcode": "7112",
            "districtname": "หนองปรือ"
        },
        {
            "provincecode": "71",
            "districtcode": "7113",
            "districtname": "ห้วยกระเจา"
        },
        {
            "provincecode": "71",
            "districtcode": "7151",
            "districtname": "สาขาตำบลท่ากระดาน"
        },
        {
            "provincecode": "71",
            "districtcode": "7181",
            "districtname": "บ้านทวน จ.กาญจนบุรี"
        },
        {
            "provincecode": "72",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี"
        },
        {
            "provincecode": "72",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช"
        },
        {
            "provincecode": "72",
            "districtcode": "7203",
            "districtname": "ด่านช้าง"
        },
        {
            "provincecode": "72",
            "districtcode": "7204",
            "districtname": "บางปลาม้า"
        },
        {
            "provincecode": "72",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์"
        },
        {
            "provincecode": "72",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์"
        },
        {
            "provincecode": "72",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง"
        },
        {
            "provincecode": "72",
            "districtcode": "7208",
            "districtname": "สามชุก"
        },
        {
            "provincecode": "72",
            "districtcode": "7209",
            "districtname": "อู่ทอง"
        },
        {
            "provincecode": "72",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ"
        },
        {
            "provincecode": "73",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม"
        },
        {
            "provincecode": "73",
            "districtcode": "7302",
            "districtname": "กำแพงแสน"
        },
        {
            "provincecode": "73",
            "districtcode": "7303",
            "districtname": "นครชัยศรี"
        },
        {
            "provincecode": "73",
            "districtcode": "7304",
            "districtname": "ดอนตูม"
        },
        {
            "provincecode": "73",
            "districtcode": "7305",
            "districtname": "บางเลน"
        },
        {
            "provincecode": "73",
            "districtcode": "7306",
            "districtname": "สามพราน"
        },
        {
            "provincecode": "73",
            "districtcode": "7307",
            "districtname": "พุทธมณฑล"
        },
        {
            "provincecode": "74",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร"
        },
        {
            "provincecode": "74",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน"
        },
        {
            "provincecode": "74",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว"
        },
        {
            "provincecode": "75",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม"
        },
        {
            "provincecode": "75",
            "districtcode": "7502",
            "districtname": "บางคนที"
        },
        {
            "provincecode": "75",
            "districtcode": "7503",
            "districtname": "อัมพวา"
        },
        {
            "provincecode": "76",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี"
        },
        {
            "provincecode": "76",
            "districtcode": "7602",
            "districtname": "เขาย้อย"
        },
        {
            "provincecode": "76",
            "districtcode": "7603",
            "districtname": "หนองหญ้าปล้อง"
        },
        {
            "provincecode": "76",
            "districtcode": "7604",
            "districtname": "ชะอำ"
        },
        {
            "provincecode": "76",
            "districtcode": "7605",
            "districtname": "ท่ายาง"
        },
        {
            "provincecode": "76",
            "districtcode": "7606",
            "districtname": "บ้านลาด"
        },
        {
            "provincecode": "76",
            "districtcode": "7607",
            "districtname": "บ้านแหลม"
        },
        {
            "provincecode": "76",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน"
        },
        {
            "provincecode": "77",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์"
        },
        {
            "provincecode": "77",
            "districtcode": "7702",
            "districtname": "กุยบุรี"
        },
        {
            "provincecode": "77",
            "districtcode": "7703",
            "districtname": "ทับสะแก"
        },
        {
            "provincecode": "77",
            "districtcode": "7704",
            "districtname": "บางสะพาน"
        },
        {
            "provincecode": "77",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย"
        },
        {
            "provincecode": "77",
            "districtcode": "7706",
            "districtname": "ปราณบุรี"
        },
        {
            "provincecode": "77",
            "districtcode": "7707",
            "districtname": "หัวหิน"
        },
        {
            "provincecode": "77",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด"
        },
        {
            "provincecode": "80",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช"
        },
        {
            "provincecode": "80",
            "districtcode": "8002",
            "districtname": "พรหมคีรี"
        },
        {
            "provincecode": "80",
            "districtcode": "8003",
            "districtname": "ลานสกา"
        },
        {
            "provincecode": "80",
            "districtcode": "8004",
            "districtname": "ฉวาง"
        },
        {
            "provincecode": "80",
            "districtcode": "8005",
            "districtname": "พิปูน"
        },
        {
            "provincecode": "80",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่"
        },
        {
            "provincecode": "80",
            "districtcode": "8007",
            "districtname": "ชะอวด"
        },
        {
            "provincecode": "80",
            "districtcode": "8008",
            "districtname": "ท่าศาลา"
        },
        {
            "provincecode": "80",
            "districtcode": "8009",
            "districtname": "ทุ่งสง"
        },
        {
            "provincecode": "80",
            "districtcode": "8010",
            "districtname": "นาบอน"
        },
        {
            "provincecode": "80",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่"
        },
        {
            "provincecode": "80",
            "districtcode": "8012",
            "districtname": "ปากพนัง"
        },
        {
            "provincecode": "80",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์"
        },
        {
            "provincecode": "80",
            "districtcode": "8014",
            "districtname": "สิชล"
        },
        {
            "provincecode": "80",
            "districtcode": "8015",
            "districtname": "ขนอม"
        },
        {
            "provincecode": "80",
            "districtcode": "8016",
            "districtname": "หัวไทร"
        },
        {
            "provincecode": "80",
            "districtcode": "8017",
            "districtname": "บางขัน"
        },
        {
            "provincecode": "80",
            "districtcode": "8018",
            "districtname": "ถ้ำพรรณรา"
        },
        {
            "provincecode": "80",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์"
        },
        {
            "provincecode": "80",
            "districtcode": "8020",
            "districtname": "พระพรหม"
        },
        {
            "provincecode": "80",
            "districtcode": "8021",
            "districtname": "นบพิตำ"
        },
        {
            "provincecode": "80",
            "districtcode": "8022",
            "districtname": "ช้างกลาง"
        },
        {
            "provincecode": "80",
            "districtcode": "8023",
            "districtname": "เฉลิมพระเกียรติ"
        },
        {
            "provincecode": "80",
            "districtcode": "8051",
            "districtname": "เชียรใหญ่ (สาขาตำบลเสือหึง)"
        },
        {
            "provincecode": "80",
            "districtcode": "8052",
            "districtname": "สาขาตำบลสวนหลวง"
        },
        {
            "provincecode": "80",
            "districtcode": "8053",
            "districtname": "ร่อนพิบูลย์ (สาขาตำบลหินตก)"
        },
        {
            "provincecode": "80",
            "districtcode": "8054",
            "districtname": "หัวไทร (สาขาตำบลควนชะลิก)"
        },
        {
            "provincecode": "80",
            "districtcode": "8055",
            "districtname": "ทุ่งสง (สาขาตำบลกะปาง)"
        },
        {
            "provincecode": "81",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่"
        },
        {
            "provincecode": "81",
            "districtcode": "8102",
            "districtname": "เขาพนม"
        },
        {
            "provincecode": "81",
            "districtcode": "8103",
            "districtname": "เกาะลันตา"
        },
        {
            "provincecode": "81",
            "districtcode": "8104",
            "districtname": "คลองท่อม"
        },
        {
            "provincecode": "81",
            "districtcode": "8105",
            "districtname": "อ่าวลึก"
        },
        {
            "provincecode": "81",
            "districtcode": "8106",
            "districtname": "ปลายพระยา"
        },
        {
            "provincecode": "81",
            "districtcode": "8107",
            "districtname": "ลำทับ"
        },
        {
            "provincecode": "81",
            "districtcode": "8108",
            "districtname": "เหนือคลอง"
        },
        {
            "provincecode": "82",
            "districtcode": "8201",
            "districtname": "เมืองพังงา"
        },
        {
            "provincecode": "82",
            "districtcode": "8202",
            "districtname": "เกาะยาว"
        },
        {
            "provincecode": "82",
            "districtcode": "8203",
            "districtname": "กะปง"
        },
        {
            "provincecode": "82",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง"
        },
        {
            "provincecode": "82",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า"
        },
        {
            "provincecode": "82",
            "districtcode": "8206",
            "districtname": "คุระบุรี"
        },
        {
            "provincecode": "82",
            "districtcode": "8207",
            "districtname": "ทับปุด"
        },
        {
            "provincecode": "82",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง"
        },
        {
            "provincecode": "83",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต"
        },
        {
            "provincecode": "83",
            "districtcode": "8302",
            "districtname": "กะทู้"
        },
        {
            "provincecode": "83",
            "districtcode": "8303",
            "districtname": "ถลาง"
        },
        {
            "provincecode": "83",
            "districtcode": "8381",
            "districtname": "ทุ่งคา"
        },
        {
            "provincecode": "84",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี"
        },
        {
            "provincecode": "84",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์"
        },
        {
            "provincecode": "84",
            "districtcode": "8403",
            "districtname": "ดอนสัก"
        },
        {
            "provincecode": "84",
            "districtcode": "8404",
            "districtname": "เกาะสมุย"
        },
        {
            "provincecode": "84",
            "districtcode": "8405",
            "districtname": "เกาะพะงัน"
        },
        {
            "provincecode": "84",
            "districtcode": "8406",
            "districtname": "ไชยา"
        },
        {
            "provincecode": "84",
            "districtcode": "8407",
            "districtname": "ท่าชนะ"
        },
        {
            "provincecode": "84",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม"
        },
        {
            "provincecode": "84",
            "districtcode": "8409",
            "districtname": "บ้านตาขุน"
        },
        {
            "provincecode": "84",
            "districtcode": "8410",
            "districtname": "พนม"
        },
        {
            "provincecode": "84",
            "districtcode": "8411",
            "districtname": "ท่าฉาง"
        },
        {
            "provincecode": "84",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร"
        },
        {
            "provincecode": "84",
            "districtcode": "8413",
            "districtname": "บ้านนาเดิม"
        },
        {
            "provincecode": "84",
            "districtcode": "8414",
            "districtname": "เคียนซา"
        },
        {
            "provincecode": "84",
            "districtcode": "8415",
            "districtname": "เวียงสระ"
        },
        {
            "provincecode": "84",
            "districtcode": "8416",
            "districtname": "พระแสง"
        },
        {
            "provincecode": "84",
            "districtcode": "8417",
            "districtname": "พุนพิน"
        },
        {
            "provincecode": "84",
            "districtcode": "8418",
            "districtname": "ชัยบุรี"
        },
        {
            "provincecode": "84",
            "districtcode": "8419",
            "districtname": "วิภาวดี"
        },
        {
            "provincecode": "84",
            "districtcode": "8451",
            "districtname": "เกาะพงัน (สาขาตำบลเกาะเต่า)"
        },
        {
            "provincecode": "84",
            "districtcode": "8481",
            "districtname": "อ.บ้านดอน จ.สุราษฎร์ธานี"
        },
        {
            "provincecode": "85",
            "districtcode": "8501",
            "districtname": "เมืองระนอง"
        },
        {
            "provincecode": "85",
            "districtcode": "8502",
            "districtname": "ละอุ่น"
        },
        {
            "provincecode": "85",
            "districtcode": "8503",
            "districtname": "กะเปอร์"
        },
        {
            "provincecode": "85",
            "districtcode": "8504",
            "districtname": "กระบุรี"
        },
        {
            "provincecode": "85",
            "districtcode": "8505",
            "districtname": "สุขสำราญ"
        },
        {
            "provincecode": "86",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร"
        },
        {
            "provincecode": "86",
            "districtcode": "8602",
            "districtname": "ท่าแซะ"
        },
        {
            "provincecode": "86",
            "districtcode": "8603",
            "districtname": "ปะทิว"
        },
        {
            "provincecode": "86",
            "districtcode": "8604",
            "districtname": "หลังสวน"
        },
        {
            "provincecode": "86",
            "districtcode": "8605",
            "districtname": "ละแม"
        },
        {
            "provincecode": "86",
            "districtcode": "8606",
            "districtname": "พะโต๊ะ"
        },
        {
            "provincecode": "86",
            "districtcode": "8607",
            "districtname": "สวี"
        },
        {
            "provincecode": "86",
            "districtcode": "8608",
            "districtname": "ทุ่งตะโก"
        },
        {
            "provincecode": "90",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา"
        },
        {
            "provincecode": "90",
            "districtcode": "9002",
            "districtname": "สทิงพระ"
        },
        {
            "provincecode": "90",
            "districtcode": "9003",
            "districtname": "จะนะ"
        },
        {
            "provincecode": "90",
            "districtcode": "9004",
            "districtname": "นาทวี"
        },
        {
            "provincecode": "90",
            "districtcode": "9005",
            "districtname": "เทพา"
        },
        {
            "provincecode": "90",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย"
        },
        {
            "provincecode": "90",
            "districtcode": "9007",
            "districtname": "ระโนด"
        },
        {
            "provincecode": "90",
            "districtcode": "9008",
            "districtname": "กระแสสินธุ์"
        },
        {
            "provincecode": "90",
            "districtcode": "9009",
            "districtname": "รัตภูมิ"
        },
        {
            "provincecode": "90",
            "districtcode": "9010",
            "districtname": "สะเดา"
        },
        {
            "provincecode": "90",
            "districtcode": "9011",
            "districtname": "หาดใหญ่"
        },
        {
            "provincecode": "90",
            "districtcode": "9012",
            "districtname": "นาหม่อม"
        },
        {
            "provincecode": "90",
            "districtcode": "9013",
            "districtname": "ควนเนียง"
        },
        {
            "provincecode": "90",
            "districtcode": "9014",
            "districtname": "บางกล่ำ"
        },
        {
            "provincecode": "90",
            "districtcode": "9015",
            "districtname": "สิงหนคร"
        },
        {
            "provincecode": "90",
            "districtcode": "9016",
            "districtname": "คลองหอยโข่ง"
        },
        {
            "provincecode": "90",
            "districtcode": "9077",
            "districtname": "ท้องถิ่นเทศบาลตำบลสำนักขาม"
        },
        {
            "provincecode": "90",
            "districtcode": "9096",
            "districtname": "เทศบาลตำบลบ้านพรุ"
        },
        {
            "provincecode": "91",
            "districtcode": "9101",
            "districtname": "เมืองสตูล"
        },
        {
            "provincecode": "91",
            "districtcode": "9102",
            "districtname": "ควนโดน"
        },
        {
            "provincecode": "91",
            "districtcode": "9103",
            "districtname": "ควนกาหลง"
        },
        {
            "provincecode": "91",
            "districtcode": "9104",
            "districtname": "ท่าแพ"
        },
        {
            "provincecode": "91",
            "districtcode": "9105",
            "districtname": "ละงู"
        },
        {
            "provincecode": "91",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า"
        },
        {
            "provincecode": "91",
            "districtcode": "9107",
            "districtname": "มะนัง"
        },
        {
            "provincecode": "92",
            "districtcode": "9201",
            "districtname": "เมืองตรัง"
        },
        {
            "provincecode": "92",
            "districtcode": "9202",
            "districtname": "กันตัง"
        },
        {
            "provincecode": "92",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว"
        },
        {
            "provincecode": "92",
            "districtcode": "9204",
            "districtname": "ปะเหลียน"
        },
        {
            "provincecode": "92",
            "districtcode": "9205",
            "districtname": "สิเกา"
        },
        {
            "provincecode": "92",
            "districtcode": "9206",
            "districtname": "ห้วยยอด"
        },
        {
            "provincecode": "92",
            "districtcode": "9207",
            "districtname": "วังวิเศษ"
        },
        {
            "provincecode": "92",
            "districtcode": "9208",
            "districtname": "นาโยง"
        },
        {
            "provincecode": "92",
            "districtcode": "9209",
            "districtname": "รัษฎา"
        },
        {
            "provincecode": "92",
            "districtcode": "9210",
            "districtname": "หาดสำราญ"
        },
        {
            "provincecode": "92",
            "districtcode": "9251",
            "districtname": "อำเภอเมืองตรัง(สาขาคลองเต็ง)"
        },
        {
            "provincecode": "93",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง"
        },
        {
            "provincecode": "93",
            "districtcode": "9302",
            "districtname": "กงหรา"
        },
        {
            "provincecode": "93",
            "districtcode": "9303",
            "districtname": "เขาชัยสน"
        },
        {
            "provincecode": "93",
            "districtcode": "9304",
            "districtname": "ตะโหมด"
        },
        {
            "provincecode": "93",
            "districtcode": "9305",
            "districtname": "ควนขนุน"
        },
        {
            "provincecode": "93",
            "districtcode": "9306",
            "districtname": "ปากพะยูน"
        },
        {
            "provincecode": "93",
            "districtcode": "9307",
            "districtname": "ศรีบรรพต"
        },
        {
            "provincecode": "93",
            "districtcode": "9308",
            "districtname": "ป่าบอน"
        },
        {
            "provincecode": "93",
            "districtcode": "9309",
            "districtname": "บางแก้ว"
        },
        {
            "provincecode": "93",
            "districtcode": "9310",
            "districtname": "ป่าพะยอม"
        },
        {
            "provincecode": "93",
            "districtcode": "9311",
            "districtname": "ศรีนครินทร์"
        },
        {
            "provincecode": "94",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี"
        },
        {
            "provincecode": "94",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์"
        },
        {
            "provincecode": "94",
            "districtcode": "9403",
            "districtname": "หนองจิก"
        },
        {
            "provincecode": "94",
            "districtcode": "9404",
            "districtname": "ปะนาเระ"
        },
        {
            "provincecode": "94",
            "districtcode": "9405",
            "districtname": "มายอ"
        },
        {
            "provincecode": "94",
            "districtcode": "9406",
            "districtname": "ทุ่งยางแดง"
        },
        {
            "provincecode": "94",
            "districtcode": "9407",
            "districtname": "สายบุรี"
        },
        {
            "provincecode": "94",
            "districtcode": "9408",
            "districtname": "ไม้แก่น"
        },
        {
            "provincecode": "94",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง"
        },
        {
            "provincecode": "94",
            "districtcode": "9410",
            "districtname": "ยะรัง"
        },
        {
            "provincecode": "94",
            "districtcode": "9411",
            "districtname": "กะพ้อ"
        },
        {
            "provincecode": "94",
            "districtcode": "9412",
            "districtname": "แม่ลาน"
        },
        {
            "provincecode": "95",
            "districtcode": "9501",
            "districtname": "เมืองยะลา"
        },
        {
            "provincecode": "95",
            "districtcode": "9502",
            "districtname": "เบตง"
        },
        {
            "provincecode": "95",
            "districtcode": "9503",
            "districtname": "บันนังสตา"
        },
        {
            "provincecode": "95",
            "districtcode": "9504",
            "districtname": "ธารโต"
        },
        {
            "provincecode": "95",
            "districtcode": "9505",
            "districtname": "ยะหา"
        },
        {
            "provincecode": "95",
            "districtcode": "9506",
            "districtname": "รามัน"
        },
        {
            "provincecode": "95",
            "districtcode": "9507",
            "districtname": "กาบัง"
        },
        {
            "provincecode": "95",
            "districtcode": "9508",
            "districtname": "กรงปินัง"
        },
        {
            "provincecode": "96",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส"
        },
        {
            "provincecode": "96",
            "districtcode": "9602",
            "districtname": "ตากใบ"
        },
        {
            "provincecode": "96",
            "districtcode": "9603",
            "districtname": "บาเจาะ"
        },
        {
            "provincecode": "96",
            "districtcode": "9604",
            "districtname": "ยี่งอ"
        },
        {
            "provincecode": "96",
            "districtcode": "9605",
            "districtname": "ระแงะ"
        },
        {
            "provincecode": "96",
            "districtcode": "9606",
            "districtname": "รือเสาะ"
        },
        {
            "provincecode": "96",
            "districtcode": "9607",
            "districtname": "ศรีสาคร"
        },
        {
            "provincecode": "96",
            "districtcode": "9608",
            "districtname": "แว้ง"
        },
        {
            "provincecode": "96",
            "districtcode": "9609",
            "districtname": "สุคิริน"
        },
        {
            "provincecode": "96",
            "districtcode": "9610",
            "districtname": "สุไหงโก-ลก"
        },
        {
            "provincecode": "96",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี"
        },
        {
            "provincecode": "96",
            "districtcode": "9612",
            "districtname": "จะแนะ"
        },
        {
            "provincecode": "96",
            "districtcode": "9613",
            "districtname": "เจาะไอร้อง"
        },
        {
            "provincecode": "96",
            "districtcode": "9681",
            "districtname": "อ.บางนรา จ.นราธิวาส"
        },
        {
            "provincecode": "97",
            "districtcode": "3807",
            "districtname": "ปากคาด"
        },
        {
            "provincecode": "97",
            "districtcode": "3806",
            "districtname": "บึงโขงหลง"
        },
        {
            "provincecode": "97",
            "districtcode": "3805",
            "districtname": "ศรีวิไล"
        },
        {
            "provincecode": "97",
            "districtcode": "3804",
            "districtname": "พรเจริญ"
        },
        {
            "provincecode": "97",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย"
        },
        {
            "provincecode": "97",
            "districtcode": "3802",
            "districtname": "เซกา"
        },
        {
            "provincecode": "97",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ"
        },
        {
            "provincecode": "97",
            "districtcode": "3808",
            "districtname": "บุ่งคล้า"
        }
    ];

    Subdistrict: Object = [
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100101",
            "subdistrictname": "พระบรมมหาราชวัง",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100102",
            "subdistrictname": "วังบูรพาภิรมย์",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100103",
            "subdistrictname": "วัดราชบพิธ",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100104",
            "subdistrictname": "สำราญราษฎร์",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100105",
            "subdistrictname": "ศาลเจ้าพ่อเสือ",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100106",
            "subdistrictname": "เสาชิงช้า",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100107",
            "subdistrictname": "บวรนิเวศ",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100108",
            "subdistrictname": "ตลาดยอด",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100109",
            "subdistrictname": "ชนะสงคราม",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100110",
            "subdistrictname": "บ้านพานถม",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100111",
            "subdistrictname": "บางขุนพรหม",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1001",
            "districtname": "พระนคร",
            "subdistrictcode": "100112",
            "subdistrictname": "วัดสามพระยา",
            "zipcode": "10200"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1002",
            "districtname": "ดุสิต",
            "subdistrictcode": "100201",
            "subdistrictname": "ดุสิต",
            "zipcode": "10300"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1002",
            "districtname": "ดุสิต",
            "subdistrictcode": "100202",
            "subdistrictname": "วชิรพยาบาล",
            "zipcode": "10300"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1002",
            "districtname": "ดุสิต",
            "subdistrictcode": "100203",
            "subdistrictname": "สวนจิตรลดา",
            "zipcode": "10300"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1002",
            "districtname": "ดุสิต",
            "subdistrictcode": "100204",
            "subdistrictname": "สี่แยกมหานาค",
            "zipcode": "10300"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1002",
            "districtname": "ดุสิต",
            "subdistrictcode": "100206",
            "subdistrictname": "ถนนนครไชยศรี",
            "zipcode": "10300"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100301",
            "subdistrictname": "กระทุ่มราย",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100302",
            "subdistrictname": "หนองจอก",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100303",
            "subdistrictname": "คลองสิบ",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100304",
            "subdistrictname": "คลองสิบสอง",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100305",
            "subdistrictname": "โคกแฝด",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100306",
            "subdistrictname": "คู้ฝั่งเหนือ",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100307",
            "subdistrictname": "ลำผักชี",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1003",
            "districtname": "หนองจอก",
            "subdistrictcode": "100308",
            "subdistrictname": "ลำต้อยติ่ง",
            "zipcode": "10530"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1004",
            "districtname": "บางรัก",
            "subdistrictcode": "100401",
            "subdistrictname": "มหาพฤฒาราม",
            "zipcode": "10500"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1004",
            "districtname": "บางรัก",
            "subdistrictcode": "100402",
            "subdistrictname": "สีลม",
            "zipcode": "10500"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1004",
            "districtname": "บางรัก",
            "subdistrictcode": "100403",
            "subdistrictname": "สุริยวงศ์",
            "zipcode": "10500"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1004",
            "districtname": "บางรัก",
            "subdistrictcode": "100404",
            "subdistrictname": "บางรัก",
            "zipcode": "10500"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1004",
            "districtname": "บางรัก",
            "subdistrictcode": "100405",
            "subdistrictname": "สี่พระยา",
            "zipcode": "10500"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1005",
            "districtname": "บางเขน",
            "subdistrictcode": "100502",
            "subdistrictname": "อนุสาวรีย์",
            "zipcode": "10220"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1005",
            "districtname": "บางเขน",
            "subdistrictcode": "100508",
            "subdistrictname": "ท่าแร้ง",
            "zipcode": "10220"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1006",
            "districtname": "บางกะปิ",
            "subdistrictcode": "100601",
            "subdistrictname": "คลองจั่น",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1006",
            "districtname": "บางกะปิ",
            "subdistrictcode": "100608",
            "subdistrictname": "หัวหมาก",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1007",
            "districtname": "ปทุมวัน",
            "subdistrictcode": "100701",
            "subdistrictname": "รองเมือง",
            "zipcode": "10330"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1007",
            "districtname": "ปทุมวัน",
            "subdistrictcode": "100702",
            "subdistrictname": "วังใหม่",
            "zipcode": "10330"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1007",
            "districtname": "ปทุมวัน",
            "subdistrictcode": "100703",
            "subdistrictname": "ปทุมวัน",
            "zipcode": "10330"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1007",
            "districtname": "ปทุมวัน",
            "subdistrictcode": "100704",
            "subdistrictname": "ลุมพินี",
            "zipcode": "10330"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย",
            "subdistrictcode": "100801",
            "subdistrictname": "ป้อมปราบ",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย",
            "subdistrictcode": "100802",
            "subdistrictname": "วัดเทพศิรินทร์",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย",
            "subdistrictcode": "100803",
            "subdistrictname": "คลองมหานาค",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย",
            "subdistrictcode": "100804",
            "subdistrictname": "บ้านบาตร",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1008",
            "districtname": "ป้อมปราบศัตรูพ่าย",
            "subdistrictcode": "100805",
            "subdistrictname": "วัดโสมนัส",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1009",
            "districtname": "พระโขนง",
            "subdistrictcode": "100905",
            "subdistrictname": "บางจาก",
            "zipcode": "10260"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1010",
            "districtname": "มีนบุรี",
            "subdistrictcode": "101001",
            "subdistrictname": "มีนบุรี",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1010",
            "districtname": "มีนบุรี",
            "subdistrictcode": "101002",
            "subdistrictname": "แสนแสบ",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101101",
            "subdistrictname": "ลาดกระบัง",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101102",
            "subdistrictname": "คลองสองต้นนุ่น",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101103",
            "subdistrictname": "คลองสามประเวศ",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101104",
            "subdistrictname": "ลำปลาทิว",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101105",
            "subdistrictname": "ทับยาว",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1011",
            "districtname": "ลาดกระบัง",
            "subdistrictcode": "101106",
            "subdistrictname": "ขุมทอง",
            "zipcode": "10520"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1012",
            "districtname": "ยานนาวา",
            "subdistrictcode": "101203",
            "subdistrictname": "ช่องนนทรี",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1012",
            "districtname": "ยานนาวา",
            "subdistrictcode": "101204",
            "subdistrictname": "บางโพงพาง",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1013",
            "districtname": "สัมพันธวงศ์",
            "subdistrictcode": "101301",
            "subdistrictname": "จักรวรรดิ",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1013",
            "districtname": "สัมพันธวงศ์",
            "subdistrictcode": "101302",
            "subdistrictname": "สัมพันธวงศ์",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1013",
            "districtname": "สัมพันธวงศ์",
            "subdistrictcode": "101303",
            "subdistrictname": "ตลาดน้อย",
            "zipcode": "10100"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1014",
            "districtname": "พญาไท",
            "subdistrictcode": "101401",
            "subdistrictname": "สามเสนใน",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101501",
            "subdistrictname": "วัดกัลยาณ์",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101502",
            "subdistrictname": "หิรัญรูจี",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101503",
            "subdistrictname": "บางยี่เรือ",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101504",
            "subdistrictname": "บุคคโล",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101505",
            "subdistrictname": "ตลาดพลู",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101506",
            "subdistrictname": "ดาวคะนอง",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1015",
            "districtname": "ธนบุรี",
            "subdistrictcode": "101507",
            "subdistrictname": "สำเหร่",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1016",
            "districtname": "บางกอกใหญ่",
            "subdistrictcode": "101601",
            "subdistrictname": "วัดอรุณ",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1016",
            "districtname": "บางกอกใหญ่",
            "subdistrictcode": "101602",
            "subdistrictname": "วัดท่าพระ",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1017",
            "districtname": "ห้วยขวาง",
            "subdistrictcode": "101701",
            "subdistrictname": "ห้วยขวาง",
            "zipcode": "10310"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1017",
            "districtname": "ห้วยขวาง",
            "subdistrictcode": "101702",
            "subdistrictname": "บางกะปิ",
            "zipcode": "10310"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1017",
            "districtname": "ห้วยขวาง",
            "subdistrictcode": "101704",
            "subdistrictname": "สามเสนนอก",
            "zipcode": "10310"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1018",
            "districtname": "คลองสาน",
            "subdistrictcode": "101801",
            "subdistrictname": "สมเด็จเจ้าพระยา",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1018",
            "districtname": "คลองสาน",
            "subdistrictcode": "101802",
            "subdistrictname": "คลองสาน",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1018",
            "districtname": "คลองสาน",
            "subdistrictcode": "101803",
            "subdistrictname": "บางลำภูล่าง",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1018",
            "districtname": "คลองสาน",
            "subdistrictcode": "101804",
            "subdistrictname": "คลองต้นไทร",
            "zipcode": "10600"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101901",
            "subdistrictname": "คลองชักพระ",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101902",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101903",
            "subdistrictname": "ฉิมพลี",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101904",
            "subdistrictname": "บางพรม",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101905",
            "subdistrictname": "บางระมาด",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1019",
            "districtname": "ตลิ่งชัน",
            "subdistrictcode": "101907",
            "subdistrictname": "บางเชือกหนัง",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย",
            "subdistrictcode": "102004",
            "subdistrictname": "ศิริราช",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย",
            "subdistrictcode": "102005",
            "subdistrictname": "บ้านช่างหล่อ",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย",
            "subdistrictcode": "102006",
            "subdistrictname": "บางขุนนนท์",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย",
            "subdistrictcode": "102007",
            "subdistrictname": "บางขุนศรี",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1020",
            "districtname": "บางกอกน้อย",
            "subdistrictcode": "102009",
            "subdistrictname": "อรุณอมรินทร์",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1021",
            "districtname": "บางขุนเทียน",
            "subdistrictcode": "102105",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1021",
            "districtname": "บางขุนเทียน",
            "subdistrictcode": "102107",
            "subdistrictname": "แสมดำ",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102201",
            "subdistrictname": "บางหว้า",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102202",
            "subdistrictname": "บางด้วน",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเท���มหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102203",
            "subdistrictname": "บางแค",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102204",
            "subdistrictname": "บางแคเหนือ",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102205",
            "subdistrictname": "บางไผ่",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102206",
            "subdistrictname": "บางจาก",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102207",
            "subdistrictname": "บางแวก",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102208",
            "subdistrictname": "คลองขวาง",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102209",
            "subdistrictname": "ปากคลองภาษีเจริญ",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1022",
            "districtname": "ภาษีเจริญ",
            "subdistrictcode": "102210",
            "subdistrictname": "คูหาสวรรค์",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1023",
            "districtname": "หนองแขม",
            "subdistrictcode": "102302",
            "subdistrictname": "หนองแขม",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1023",
            "districtname": "หนองแขม",
            "subdistrictcode": "102303",
            "subdistrictname": "หนองค้างพลู",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1024",
            "districtname": "ราษฎร์บูรณะ",
            "subdistrictcode": "102401",
            "subdistrictname": "ราษฎร์บูรณะ",
            "zipcode": "10140"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1024",
            "districtname": "ราษฎร์บูรณะ",
            "subdistrictcode": "102402",
            "subdistrictname": "บางปะกอก",
            "zipcode": "10140"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1025",
            "districtname": "บางพลัด",
            "subdistrictcode": "102501",
            "subdistrictname": "บางพลัด",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1025",
            "districtname": "บางพลัด",
            "subdistrictcode": "102502",
            "subdistrictname": "บางอ้อ",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1025",
            "districtname": "บางพลัด",
            "subdistrictcode": "102503",
            "subdistrictname": "บางบำหรุ",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1025",
            "districtname": "บางพลัด",
            "subdistrictcode": "102504",
            "subdistrictname": "บางยี่ขัน",
            "zipcode": "10700"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1026",
            "districtname": "ดินแดง",
            "subdistrictcode": "102601",
            "subdistrictname": "ดินแดง",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1027",
            "districtname": "บึงกุ่ม",
            "subdistrictcode": "102701",
            "subdistrictname": "คลองกุ่ม",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1027",
            "districtname": "บึงกุ่ม",
            "subdistrictcode": "102702",
            "subdistrictname": "สะพานสูง",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1027",
            "districtname": "บึงกุ่ม",
            "subdistrictcode": "102703",
            "subdistrictname": "คันนายาว",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1028",
            "districtname": "สาทร",
            "subdistrictcode": "102801",
            "subdistrictname": "ทุ่งวัดดอน",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1028",
            "districtname": "สาทร",
            "subdistrictcode": "102802",
            "subdistrictname": "ยานนาวา",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1028",
            "districtname": "สาทร",
            "subdistrictcode": "102803",
            "subdistrictname": "ทุ่งมหาเมฆ",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1029",
            "districtname": "บางซื่อ",
            "subdistrictcode": "102901",
            "subdistrictname": "บางซื่อ",
            "zipcode": "10800"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1030",
            "districtname": "จตุจักร",
            "subdistrictcode": "103001",
            "subdistrictname": "ลาดยาว",
            "zipcode": "10900"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1030",
            "districtname": "จตุจักร",
            "subdistrictcode": "103002",
            "subdistrictname": "เสนานิคม",
            "zipcode": "10900"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1030",
            "districtname": "จตุจักร",
            "subdistrictcode": "103003",
            "subdistrictname": "จันทรเกษม",
            "zipcode": "10900"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1030",
            "districtname": "จตุจักร",
            "subdistrictcode": "103004",
            "subdistrictname": "จอมพล",
            "zipcode": "10900"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1030",
            "districtname": "จตุจักร",
            "subdistrictcode": "103005",
            "subdistrictname": "จตุจักร",
            "zipcode": "10900"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1031",
            "districtname": "บางคอแหลม",
            "subdistrictcode": "103101",
            "subdistrictname": "บางคอแหลม",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1031",
            "districtname": "บางคอแหลม",
            "subdistrictcode": "103102",
            "subdistrictname": "วัดพระยาไกร",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1031",
            "districtname": "บางคอแหลม",
            "subdistrictcode": "103103",
            "subdistrictname": "บางโคล่",
            "zipcode": "10120"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1032",
            "districtname": "ประเวศ",
            "subdistrictcode": "103201",
            "subdistrictname": "ประเวศ",
            "zipcode": "10250"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1032",
            "districtname": "ประเวศ",
            "subdistrictcode": "103202",
            "subdistrictname": "หนองบอน",
            "zipcode": "10250"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1032",
            "districtname": "ประเวศ",
            "subdistrictcode": "103203",
            "subdistrictname": "ดอกไม้",
            "zipcode": "10250"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1032",
            "districtname": "ประเวศ",
            "subdistrictcode": "103204",
            "subdistrictname": "สวนหลวง",
            "zipcode": "10250"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103301",
            "subdistrictname": "คลองเตย",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103302",
            "subdistrictname": "คลองตัน",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103303",
            "subdistrictname": "พระโขนง",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103304",
            "subdistrictname": "คลองเตยเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103305",
            "subdistrictname": "คลองตันเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1033",
            "districtname": "คลองเตย",
            "subdistrictcode": "103306",
            "subdistrictname": "พระโขนงเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1034",
            "districtname": "สวนหลวง",
            "subdistrictcode": "103401",
            "subdistrictname": "สวนหลวง",
            "zipcode": "10250"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1035",
            "districtname": "จอมทอง",
            "subdistrictcode": "103501",
            "subdistrictname": "บางขุนเทียน",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1035",
            "districtname": "จอมทอง",
            "subdistrictcode": "103502",
            "subdistrictname": "บางค้อ",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1035",
            "districtname": "จอมทอง",
            "subdistrictcode": "103503",
            "subdistrictname": "บางมด",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1035",
            "districtname": "จอมทอง",
            "subdistrictcode": "103504",
            "subdistrictname": "จอมทอง",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1036",
            "districtname": "ดอนเมือง",
            "subdistrictcode": "103602",
            "subdistrictname": "สีกัน",
            "zipcode": "10210"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1037",
            "districtname": "ราชเทวี",
            "subdistrictcode": "103701",
            "subdistrictname": "ทุ่งพญาไท",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1037",
            "districtname": "ราชเทวี",
            "subdistrictcode": "103702",
            "subdistrictname": "ถนนพญาไท",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1037",
            "districtname": "ราชเทวี",
            "subdistrictcode": "103703",
            "subdistrictname": "ถนนเพชรบุรี",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1037",
            "districtname": "ราชเทวี",
            "subdistrictcode": "103704",
            "subdistrictname": "มักกะสัน",
            "zipcode": "10400"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1038",
            "districtname": "ลาดพร้าว",
            "subdistrictcode": "103801",
            "subdistrictname": "ลาดพร้าว",
            "zipcode": "10230"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1038",
            "districtname": "ลาดพร้าว",
            "subdistrictcode": "103802",
            "subdistrictname": "จรเข้บัว",
            "zipcode": "10230"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1039",
            "districtname": "วัฒนา",
            "subdistrictcode": "103901",
            "subdistrictname": "คลองเตยเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1039",
            "districtname": "วัฒนา",
            "subdistrictcode": "103902",
            "subdistrictname": "คลองตันเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1039",
            "districtname": "วัฒนา",
            "subdistrictcode": "103903",
            "subdistrictname": "พระโขนงเหนือ",
            "zipcode": "10110"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1040",
            "districtname": "บางแค",
            "subdistrictcode": "104001",
            "subdistrictname": "บางแค",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1040",
            "districtname": "บางแค",
            "subdistrictcode": "104002",
            "subdistrictname": "บางแคเหนือ",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1040",
            "districtname": "บางแค",
            "subdistrictcode": "104003",
            "subdistrictname": "บางไผ่",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1040",
            "districtname": "บางแค",
            "subdistrictcode": "104004",
            "subdistrictname": "หลักสอง",
            "zipcode": "10160"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1041",
            "districtname": "หลักสี่",
            "subdistrictcode": "104101",
            "subdistrictname": "ทุ่งสองห้อง",
            "zipcode": "10210"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1041",
            "districtname": "หลักสี่",
            "subdistrictcode": "104102",
            "subdistrictname": "ตลาดบางเขน",
            "zipcode": "10210"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1042",
            "districtname": "สายไหม",
            "subdistrictcode": "104201",
            "subdistrictname": "สายไหม",
            "zipcode": "10220"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1042",
            "districtname": "สายไหม",
            "subdistrictcode": "104202",
            "subdistrictname": "ออเงิน",
            "zipcode": "10220"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1042",
            "districtname": "สายไหม",
            "subdistrictcode": "104203",
            "subdistrictname": "คลองถนน",
            "zipcode": "10220"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1043",
            "districtname": "คันนายาว",
            "subdistrictcode": "104301",
            "subdistrictname": "คันนายาว",
            "zipcode": "10230"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1044",
            "districtname": "สะพานสูง",
            "subdistrictcode": "104401",
            "subdistrictname": "สะพานสูง",
            "zipcode": "10240"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1045",
            "districtname": "วังทองหลาง",
            "subdistrictcode": "104501",
            "subdistrictname": "วังทองหลาง",
            "zipcode": "10310"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1046",
            "districtname": "คลองสามวา",
            "subdistrictcode": "104601",
            "subdistrictname": "สามวาตะวันตก",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1046",
            "districtname": "คลองสามวา",
            "subdistrictcode": "104602",
            "subdistrictname": "สามวาตะวันออก",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1046",
            "districtname": "คลองสามวา",
            "subdistrictcode": "104603",
            "subdistrictname": "บางชัน",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1046",
            "districtname": "คลองสามวา",
            "subdistrictcode": "104604",
            "subdistrictname": "ทรายกองดิน",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1046",
            "districtname": "คลองสามวา",
            "subdistrictcode": "104605",
            "subdistrictname": "ทรายกองดินใต้",
            "zipcode": "10510"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1047",
            "districtname": "บางนา",
            "subdistrictcode": "104701",
            "subdistrictname": "บางนา",
            "zipcode": "10260"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1048",
            "districtname": "ทวีวัฒนา",
            "subdistrictcode": "104801",
            "subdistrictname": "ทวีวัฒนา",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1048",
            "districtname": "ทวีวัฒนา",
            "subdistrictcode": "104802",
            "subdistrictname": "ศาลาธรรมสพน์",
            "zipcode": "10170"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1049",
            "districtname": "ทุ่งครุ",
            "subdistrictcode": "104901",
            "subdistrictname": "บางมด",
            "zipcode": "10140"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1049",
            "districtname": "ทุ่งครุ",
            "subdistrictcode": "104902",
            "subdistrictname": "ทุ่งครุ",
            "zipcode": "10140"
        },
        {
            "isActive": true,
            "provincecode": "กรุงเทพมหานคร",
            "districtcode": "1050",
            "districtname": "บางบอน",
            "subdistrictcode": "105001",
            "subdistrictname": "บางบอน",
            "zipcode": "10150"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110101",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110102",
            "subdistrictname": "สำโรงเหนือ",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110103",
            "subdistrictname": "บางเมือง",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110104",
            "subdistrictname": "ท้ายบ้าน",
            "zipcode": "10280"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110108",
            "subdistrictname": "บางปูใหม่",
            "zipcode": "10280"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110110",
            "subdistrictname": "แพรกษา",
            "zipcode": "10280"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110111",
            "subdistrictname": "บางโปรง",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110112",
            "subdistrictname": "บางปู",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110113",
            "subdistrictname": "บางด้วน",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110114",
            "subdistrictname": "บางเมืองใหม่",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110115",
            "subdistrictname": "เทพารักษ์",
            "zipcode": "10270"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110116",
            "subdistrictname": "ท้ายบ้านใหม่",
            "zipcode": "10280"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1101",
            "districtname": "เมืองสมุทรปราการ",
            "subdistrictcode": "110117",
            "subdistrictname": "แพรกษาใหม่",
            "zipcode": "10280"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110201",
            "subdistrictname": "บางบ่อ",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110202",
            "subdistrictname": "บ้านระกาศ",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110203",
            "subdistrictname": "บางพลีน้อย",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110204",
            "subdistrictname": "บางเพรียง",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110205",
            "subdistrictname": "คลองด่าน",
            "zipcode": "10550"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110206",
            "subdistrictname": "คลองสวน",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110207",
            "subdistrictname": "เปร็ง",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1102",
            "districtname": "บางบ่อ",
            "subdistrictcode": "110208",
            "subdistrictname": "คลองนิยมยาตรา",
            "zipcode": "10560"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110301",
            "subdistrictname": "บางพลีใหญ่",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110302",
            "subdistrictname": "บางแก้ว",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110303",
            "subdistrictname": "บางปลา",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110304",
            "subdistrictname": "บางโฉลง",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110308",
            "subdistrictname": "ราชาเทวะ",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1103",
            "districtname": "บางพลี",
            "subdistrictcode": "110309",
            "subdistrictname": "หนองปรือ",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110401",
            "subdistrictname": "ตลาด",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110402",
            "subdistrictname": "บางพึ่ง",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110403",
            "subdistrictname": "บางจาก",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110404",
            "subdistrictname": "บางครุ",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110405",
            "subdistrictname": "บางหญ้าแพรก",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110406",
            "subdistrictname": "บางหัวเสือ",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110407",
            "subdistrictname": "สำโรงใต้",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110408",
            "subdistrictname": "บางยอ",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110409",
            "subdistrictname": "บางกะเจ้า",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110410",
            "subdistrictname": "บางน้ำผึ้ง",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110411",
            "subdistrictname": "บางกระสอบ",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110412",
            "subdistrictname": "บางกอบัว",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110413",
            "subdistrictname": "ทรงคนอง",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110414",
            "subdistrictname": "สำโรง",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1104",
            "districtname": "พระประแดง",
            "subdistrictcode": "110415",
            "subdistrictname": "สำโรงกลาง",
            "zipcode": "10130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์",
            "subdistrictcode": "110501",
            "subdistrictname": "นาเกลือ",
            "zipcode": "10290"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์",
            "subdistrictcode": "110502",
            "subdistrictname": "บ้านคลองสวน",
            "zipcode": "10290"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์",
            "subdistrictcode": "110503",
            "subdistrictname": "แหลมฟ้าผ่า",
            "zipcode": "10290"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์",
            "subdistrictcode": "110504",
            "subdistrictname": "ปากคลองบางปลากด",
            "zipcode": "10290"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1105",
            "districtname": "พระสมุทรเจดีย์",
            "subdistrictcode": "110505",
            "subdistrictname": "ในคลองบางปลากด",
            "zipcode": "10290"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1106",
            "districtname": "บางเสาธง",
            "subdistrictcode": "110601",
            "subdistrictname": "บางเสาธง",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1106",
            "districtname": "บางเสาธง",
            "subdistrictcode": "110602",
            "subdistrictname": "ศีรษะจรเข้น้อย",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรปราการ",
            "districtcode": "1106",
            "districtname": "บางเสาธง",
            "subdistrictcode": "110603",
            "subdistrictname": "ศีรษะจรเข้ใหญ่",
            "zipcode": "10540"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120101",
            "subdistrictname": "สวนใหญ่",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120102",
            "subdistrictname": "ตลาดขวัญ",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120103",
            "subdistrictname": "บางเขน",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120104",
            "subdistrictname": "บางกระสอ",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120105",
            "subdistrictname": "ท่าทราย",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120106",
            "subdistrictname": "บางไผ่",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120107",
            "subdistrictname": "บางศรีเมือง",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120108",
            "subdistrictname": "บางกร่าง",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120109",
            "subdistrictname": "ไทรม้า",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1201",
            "districtname": "เมืองนนทบุรี",
            "subdistrictcode": "120110",
            "subdistrictname": "บางรักน้อย",
            "zipcode": "11000"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120201",
            "subdistrictname": "วัดชลอ",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120202",
            "subdistrictname": "บางกรวย",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120203",
            "subdistrictname": "บางสีทอง",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120204",
            "subdistrictname": "บางขนุน",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120205",
            "subdistrictname": "บางขุนกอง",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120206",
            "subdistrictname": "บางคูเวียง",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120207",
            "subdistrictname": "มหาสวัสดิ์",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120208",
            "subdistrictname": "ปลายบาง",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1202",
            "districtname": "บางกรวย",
            "subdistrictcode": "120209",
            "subdistrictname": "ศาลากลาง",
            "zipcode": "11130"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120301",
            "subdistrictname": "บางม่วง",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120302",
            "subdistrictname": "บางแม่นาง",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120303",
            "subdistrictname": "บางเลน",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120304",
            "subdistrictname": "เสาธงหิน",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120305",
            "subdistrictname": "บางใหญ่",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1203",
            "districtname": "บางใหญ่",
            "subdistrictcode": "120306",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "11140"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120401",
            "subdistrictname": "โสนลอย",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120402",
            "subdistrictname": "บางบัวทอง",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120403",
            "subdistrictname": "บางรักใหญ่",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120404",
            "subdistrictname": "บางคูรัด",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120405",
            "subdistrictname": "ละหาร",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120406",
            "subdistrictname": "ลำโพ",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120407",
            "subdistrictname": "พิมลราช",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1204",
            "districtname": "บางบัวทอง",
            "subdistrictcode": "120408",
            "subdistrictname": "บางรักพัฒนา",
            "zipcode": "11110"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120501",
            "subdistrictname": "ไทรน้อย",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120502",
            "subdistrictname": "ราษฎร์นิยม",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120503",
            "subdistrictname": "หนองเพรางาย",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120504",
            "subdistrictname": "ไทรใหญ่",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120505",
            "subdistrictname": "ขุนศรี",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120506",
            "subdistrictname": "คลองขวาง",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1205",
            "districtname": "ไทรน้อย",
            "subdistrictcode": "120507",
            "subdistrictname": "ทวีวัฒนา",
            "zipcode": "11150"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120601",
            "subdistrictname": "ปากเกร็ด",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120602",
            "subdistrictname": "บางตลาด",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120603",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120604",
            "subdistrictname": "บางพูด",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120605",
            "subdistrictname": "บางตะไนย์",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120606",
            "subdistrictname": "คลองพระอุดม",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120607",
            "subdistrictname": "ท่าอิฐ",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120608",
            "subdistrictname": "เกาะเกร็ด",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120609",
            "subdistrictname": "อ้อมเกร็ด",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120610",
            "subdistrictname": "คลองข่อย",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120611",
            "subdistrictname": "บางพลับ",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "นนทบุรี",
            "districtcode": "1206",
            "districtname": "ปากเกร็ด",
            "subdistrictcode": "120612",
            "subdistrictname": "คลองเกลือ",
            "zipcode": "11120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130101",
            "subdistrictname": "บางปรอก",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130102",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130103",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130104",
            "subdistrictname": "บ้านฉาง",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130105",
            "subdistrictname": "บ้านกระแชง",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130106",
            "subdistrictname": "บางขะแยง",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130107",
            "subdistrictname": "บางคูวัด",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130108",
            "subdistrictname": "บางหลวง",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130109",
            "subdistrictname": "บางเดื่อ",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130110",
            "subdistrictname": "บางพูด",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130111",
            "subdistrictname": "บางพูน",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130112",
            "subdistrictname": "บางกะดี",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130113",
            "subdistrictname": "สวนพริกไทย",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1301",
            "districtname": "เมืองปทุมธานี",
            "subdistrictcode": "130114",
            "subdistrictname": "หลักหก",
            "zipcode": "12000"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130201",
            "subdistrictname": "คลองหนึ่ง",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130202",
            "subdistrictname": "คลองสอง",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130203",
            "subdistrictname": "คลองสาม",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130204",
            "subdistrictname": "คลองสี่",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130205",
            "subdistrictname": "คลองห้า",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130206",
            "subdistrictname": "คลองหก",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1302",
            "districtname": "คลองหลวง",
            "subdistrictcode": "130207",
            "subdistrictname": "คลองเจ็ด",
            "zipcode": "12120"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130301",
            "subdistrictname": "ประชาธิปัตย์",
            "zipcode": "12130"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130302",
            "subdistrictname": "บึงยี่โถ",
            "zipcode": "12130"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130303",
            "subdistrictname": "รังสิต",
            "zipcode": "12110"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130304",
            "subdistrictname": "ลำผักกูด",
            "zipcode": "12110"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130305",
            "subdistrictname": "บึงสนั่น",
            "zipcode": "12110"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1303",
            "districtname": "ธัญบุรี",
            "subdistrictcode": "130306",
            "subdistrictname": "บึงน้ำรักษ์",
            "zipcode": "12110"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130401",
            "subdistrictname": "บึงบา",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130402",
            "subdistrictname": "บึงบอน",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130403",
            "subdistrictname": "บึงกาสาม",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130404",
            "subdistrictname": "บึงชำอ้อ",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130405",
            "subdistrictname": "หนองสามวัง",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130406",
            "subdistrictname": "ศาลาครุ",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1304",
            "districtname": "หนองเสือ",
            "subdistrictcode": "130407",
            "subdistrictname": "นพรัตน์",
            "zipcode": "12170"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130501",
            "subdistrictname": "ระแหง",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130502",
            "subdistrictname": "ลาดหลุมแก้ว",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130503",
            "subdistrictname": "คูบางหลวง",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130504",
            "subdistrictname": "คูขวาง",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130505",
            "subdistrictname": "คลองพระอุดม",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130506",
            "subdistrictname": "บ่อเงิน",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1305",
            "districtname": "ลาดหลุมแก้ว",
            "subdistrictcode": "130507",
            "subdistrictname": "หน้าไม้",
            "zipcode": "12140"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130601",
            "subdistrictname": "คูคต",
            "zipcode": "12130"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130602",
            "subdistrictname": "ลาดสวาย",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130603",
            "subdistrictname": "บึงคำพร้อย",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130604",
            "subdistrictname": "ลำลูกกา",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130605",
            "subdistrictname": "บึงทองหลาง",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130606",
            "subdistrictname": "ลำไทร",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130607",
            "subdistrictname": "บึงคอไห",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1306",
            "districtname": "ลำลูกกา",
            "subdistrictcode": "130608",
            "subdistrictname": "พืชอุดม",
            "zipcode": "12150"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130701",
            "subdistrictname": "บางเตย",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130702",
            "subdistrictname": "คลองควาย",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130703",
            "subdistrictname": "สามโคก",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130704",
            "subdistrictname": "กระแชง",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130705",
            "subdistrictname": "บางโพธิ์เหนือ",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130706",
            "subdistrictname": "เชียงรากใหญ่",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130707",
            "subdistrictname": "บ้านปทุม",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130708",
            "subdistrictname": "บ้านงิ้ว",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130709",
            "subdistrictname": "เชียงรากน้อย",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130710",
            "subdistrictname": "บางกระบือ",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "ปทุมธานี",
            "districtcode": "1307",
            "districtname": "สามโคก",
            "subdistrictcode": "130711",
            "subdistrictname": "ท้ายเกาะ",
            "zipcode": "12160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140101",
            "subdistrictname": "ประตูชัย",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140102",
            "subdistrictname": "กะมัง",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140103",
            "subdistrictname": "หอรัตนไชย",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140104",
            "subdistrictname": "หัวรอ",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140105",
            "subdistrictname": "ท่าวาสุกรี",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140106",
            "subdistrictname": "ไผ่ลิง",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140107",
            "subdistrictname": "ปากกราน",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140108",
            "subdistrictname": "ภูเขาทอง",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140109",
            "subdistrictname": "สำเภาล่ม",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140110",
            "subdistrictname": "สวนพริก",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140111",
            "subdistrictname": "คลองตะเคียน",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140112",
            "subdistrictname": "วัดตูม",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140113",
            "subdistrictname": "หันตรา",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140114",
            "subdistrictname": "ลุมพลี",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140115",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140116",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140117",
            "subdistrictname": "คลองสวนพลู",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140118",
            "subdistrictname": "คลองสระบัว",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140119",
            "subdistrictname": "เกาะเรียน",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140120",
            "subdistrictname": "บ้านป้อม",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1401",
            "districtname": "พระนครศรีอยุธยา",
            "subdistrictcode": "140121",
            "subdistrictname": "บ้านรุน",
            "zipcode": "13000"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140201",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140202",
            "subdistrictname": "จำปา",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140203",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140204",
            "subdistrictname": "บ้านร่อม",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140205",
            "subdistrictname": "ศาลาลอย",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140206",
            "subdistrictname": "วังแดง",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140207",
            "subdistrictname": "โพธิ์เอน",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140208",
            "subdistrictname": "ปากท่า",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140209",
            "subdistrictname": "หนองขนาก",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1402",
            "districtname": "ท่าเรือ",
            "subdistrictcode": "140210",
            "subdistrictname": "ท่าเจ้าสนุก",
            "zipcode": "13130"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140301",
            "subdistrictname": "นครหลวง",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140302",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140303",
            "subdistrictname": "บ่อโพง",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140304",
            "subdistrictname": "บ้านชุ้ง",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140305",
            "subdistrictname": "ปากจั่น",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140306",
            "subdistrictname": "บางระกำ",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140307",
            "subdistrictname": "บางพระครู",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140308",
            "subdistrictname": "แม่ลา",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140309",
            "subdistrictname": "หนองปลิง",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140310",
            "subdistrictname": "คลองสะแก",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140311",
            "subdistrictname": "สามไถ",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1403",
            "districtname": "นครหลวง",
            "subdistrictcode": "140312",
            "subdistrictname": "พระนอน",
            "zipcode": "13260"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140401",
            "subdistrictname": "บางไทร",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140402",
            "subdistrictname": "บางพลี",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140403",
            "subdistrictname": "สนามชัย",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140404",
            "subdistrictname": "บ้านแป้ง",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140405",
            "subdistrictname": "หน้าไม้",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140406",
            "subdistrictname": "บางยี่โท",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140407",
            "subdistrictname": "แคออก",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140408",
            "subdistrictname": "แคตก",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140409",
            "subdistrictname": "ช่างเหล็ก",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140410",
            "subdistrictname": "กระแชง",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140411",
            "subdistrictname": "บ้านกลึง",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140412",
            "subdistrictname": "ช้างน้อย",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140413",
            "subdistrictname": "ห่อหมก",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140414",
            "subdistrictname": "ไผ่พระ",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140415",
            "subdistrictname": "กกแก้วบูรพา",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140416",
            "subdistrictname": "ไม้ตรา",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140417",
            "subdistrictname": "บ้านม้า",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140418",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140419",
            "subdistrictname": "ราชคราม",
            "zipcode": "13290"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140420",
            "subdistrictname": "ช้างใหญ่",
            "zipcode": "13290"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140421",
            "subdistrictname": "โพแตง",
            "zipcode": "13290"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140422",
            "subdistrictname": "เชียงรากน้อย",
            "zipcode": "13290"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1404",
            "districtname": "บางไทร",
            "subdistrictcode": "140423",
            "subdistrictname": "โคกช้าง",
            "zipcode": "13190"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140501",
            "subdistrictname": "บางบาล",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140502",
            "subdistrictname": "วัดยม",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140503",
            "subdistrictname": "ไทรน้อย",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140504",
            "subdistrictname": "สะพานไทย",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140505",
            "subdistrictname": "มหาพราหมณ์",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140506",
            "subdistrictname": "กบเจา",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140507",
            "subdistrictname": "บ้านคลัง",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140508",
            "subdistrictname": "พระขาว",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140509",
            "subdistrictname": "น้ำเต้า",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140510",
            "subdistrictname": "ทางช้าง",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140511",
            "subdistrictname": "วัดตะกู",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140512",
            "subdistrictname": "บางหลวง",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140513",
            "subdistrictname": "บางหลวงโดด",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140514",
            "subdistrictname": "บางหัก",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140515",
            "subdistrictname": "บางชะนี",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1405",
            "districtname": "บางบาล",
            "subdistrictcode": "140516",
            "subdistrictname": "บ้านกุ่ม",
            "zipcode": "13250"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140601",
            "subdistrictname": "บ้านเลน",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140602",
            "subdistrictname": "เชียงรากน้อย",
            "zipcode": "13180"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140603",
            "subdistrictname": "บ้านโพ",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140604",
            "subdistrictname": "บ้านกรด",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140605",
            "subdistrictname": "บางกระสั้น",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140606",
            "subdistrictname": "คลองจิก",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140607",
            "subdistrictname": "บ้านหว้า",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140608",
            "subdistrictname": "วัดยม",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140609",
            "subdistrictname": "บางประแดง",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140610",
            "subdistrictname": "สามเรือน",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140611",
            "subdistrictname": "เกาะเกิด",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140612",
            "subdistrictname": "บ้านพลับ",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140613",
            "subdistrictname": "บ้านแป้ง",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140614",
            "subdistrictname": "คุ้งลาน",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140615",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140616",
            "subdistrictname": "บ้านสร้าง",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140617",
            "subdistrictname": "ตลาดเกรียบ",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1406",
            "districtname": "บางปะอิน",
            "subdistrictcode": "140618",
            "subdistrictname": "ขนอนหลวง",
            "zipcode": "13160"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140701",
            "subdistrictname": "บางปะหัน",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140702",
            "subdistrictname": "ขยาย",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140703",
            "subdistrictname": "บางเดื่อ",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140704",
            "subdistrictname": "เสาธง",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140705",
            "subdistrictname": "ทางกลาง",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140706",
            "subdistrictname": "บางเพลิง",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140707",
            "subdistrictname": "หันสัง",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140708",
            "subdistrictname": "บางนางร้า",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140709",
            "subdistrictname": "ตานิม",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140710",
            "subdistrictname": "ทับน้ำ",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140711",
            "subdistrictname": "บ้านม้า",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140712",
            "subdistrictname": "ขวัญเมือง",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140713",
            "subdistrictname": "บ้านลี่",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140714",
            "subdistrictname": "โพธิ์สามต้น",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140715",
            "subdistrictname": "พุทเลา",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140716",
            "subdistrictname": "ตาลเอน",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1407",
            "districtname": "บางปะหัน",
            "subdistrictcode": "140717",
            "subdistrictname": "บ้านขล้อ",
            "zipcode": "13220"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140801",
            "subdistrictname": "ผักไห่",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140802",
            "subdistrictname": "อมฤต",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140803",
            "subdistrictname": "บ้านแค",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140804",
            "subdistrictname": "ลาดน้ำเค็ม",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140805",
            "subdistrictname": "ตาลาน",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140806",
            "subdistrictname": "ท่าดินแดง",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140807",
            "subdistrictname": "ดอนลาน",
            "zipcode": "13280"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140808",
            "subdistrictname": "นาคู",
            "zipcode": "13280"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140809",
            "subdistrictname": "กุฎี",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140810",
            "subdistrictname": "ลำตะเคียน",
            "zipcode": "13280"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140811",
            "subdistrictname": "โคกช้าง",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140812",
            "subdistrictname": "จักราช",
            "zipcode": "13280"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140813",
            "subdistrictname": "หนองน้ำใหญ่",
            "zipcode": "13280"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140814",
            "subdistrictname": "ลาดชิด",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140815",
            "subdistrictname": "หน้าโคก",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1408",
            "districtname": "ผักไห่",
            "subdistrictcode": "140816",
            "subdistrictname": "บ้านใหญ่",
            "zipcode": "13120"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140901",
            "subdistrictname": "ภาชี",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140902",
            "subdistrictname": "โคกม่วง",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140903",
            "subdistrictname": "ระโสม",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140904",
            "subdistrictname": "หนองน้ำใส",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140905",
            "subdistrictname": "ดอนหญ้านาง",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140906",
            "subdistrictname": "ไผ่ล้อม",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140907",
            "subdistrictname": "กระจิว",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1409",
            "districtname": "ภาชี",
            "subdistrictcode": "140908",
            "subdistrictname": "พระแก้ว",
            "zipcode": "13140"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141001",
            "subdistrictname": "ลาดบัวหลวง",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141002",
            "subdistrictname": "หลักชัย",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141003",
            "subdistrictname": "สามเมือง",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141004",
            "subdistrictname": "พระยาบันลือ",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141005",
            "subdistrictname": "สิงหนาท",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141006",
            "subdistrictname": "คู้สลอด",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1410",
            "districtname": "ลาดบัวหลวง",
            "subdistrictcode": "141007",
            "subdistrictname": "คลองพระยาบันลือ",
            "zipcode": "13230"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141101",
            "subdistrictname": "ลำตาเสา",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141102",
            "subdistrictname": "บ่อตาโล่",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141103",
            "subdistrictname": "วังน้อย",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141104",
            "subdistrictname": "ลำไทร",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141105",
            "subdistrictname": "สนับทึบ",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141106",
            "subdistrictname": "พยอม",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141107",
            "subdistrictname": "หันตะเภา",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141108",
            "subdistrictname": "วังจุฬา",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141109",
            "subdistrictname": "ข้าวงาม",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1411",
            "districtname": "วังน้อย",
            "subdistrictcode": "141110",
            "subdistrictname": "ชะแมบ",
            "zipcode": "13170"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141201",
            "subdistrictname": "เสนา",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141202",
            "subdistrictname": "บ้านแพน",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141203",
            "subdistrictname": "เจ้าเจ็ด",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141204",
            "subdistrictname": "สามกอ",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141205",
            "subdistrictname": "บางนมโค",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141206",
            "subdistrictname": "หัวเวียง",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141207",
            "subdistrictname": "มารวิชัย",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141208",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141209",
            "subdistrictname": "รางจรเข้",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141210",
            "subdistrictname": "บ้านกระทุ่ม",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141211",
            "subdistrictname": "บ้านแถว",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141212",
            "subdistrictname": "ชายนา",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141213",
            "subdistrictname": "สามตุ่ม",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141214",
            "subdistrictname": "ลาดงา",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141215",
            "subdistrictname": "ดอนทอง",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141216",
            "subdistrictname": "บ้านหลวง",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1412",
            "districtname": "เสนา",
            "subdistrictcode": "141217",
            "subdistrictname": "เจ้าเสด็จ",
            "zipcode": "13110"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141301",
            "subdistrictname": "บางซ้าย",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141302",
            "subdistrictname": "แก้วฟ้า",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141303",
            "subdistrictname": "เต่าเล่า",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141304",
            "subdistrictname": "ปลายกลัด",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141305",
            "subdistrictname": "เทพมงคล",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1413",
            "districtname": "บางซ้าย",
            "subdistrictcode": "141306",
            "subdistrictname": "วังพัฒนา",
            "zipcode": "13270"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141401",
            "subdistrictname": "คานหาม",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141402",
            "subdistrictname": "บ้านช้าง",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141403",
            "subdistrictname": "สามบัณฑิต",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141404",
            "subdistrictname": "บ้านหีบ",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141405",
            "subdistrictname": "หนองไม้ซุง",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141406",
            "subdistrictname": "อุทัย",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141407",
            "subdistrictname": "เสนา",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141408",
            "subdistrictname": "หนองน้ำส้ม",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141409",
            "subdistrictname": "โพสาวหาญ",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141410",
            "subdistrictname": "ธนู",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1414",
            "districtname": "อุทัย",
            "subdistrictcode": "141411",
            "subdistrictname": "ข้าวเม่า",
            "zipcode": "13210"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141501",
            "subdistrictname": "หัวไผ่",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141502",
            "subdistrictname": "กะทุ่ม",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141503",
            "subdistrictname": "มหาราช",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141504",
            "subdistrictname": "น้ำเต้า",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141505",
            "subdistrictname": "บางนา",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141506",
            "subdistrictname": "โรงช้าง",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141507",
            "subdistrictname": "เจ้าปลุก",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141508",
            "subdistrictname": "พิตเพียน",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141509",
            "subdistrictname": "บ้านนา",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141510",
            "subdistrictname": "บ้านขวาง",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141511",
            "subdistrictname": "ท่าตอ",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1415",
            "districtname": "มหาราช",
            "subdistrictcode": "141512",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "13150"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1416",
            "districtname": "บ้านแพรก",
            "subdistrictcode": "141601",
            "subdistrictname": "บ้านแพรก",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1416",
            "districtname": "บ้านแพรก",
            "subdistrictcode": "141602",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1416",
            "districtname": "บ้านแพรก",
            "subdistrictcode": "141603",
            "subdistrictname": "สำพะเนียง",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1416",
            "districtname": "บ้านแพรก",
            "subdistrictcode": "141604",
            "subdistrictname": "คลองน้อย",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "พระนครศรีอยุธยา",
            "districtcode": "1416",
            "districtname": "บ้านแพรก",
            "subdistrictcode": "141605",
            "subdistrictname": "สองห้อง",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150101",
            "subdistrictname": "ตลาดหลวง",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150102",
            "subdistrictname": "บางแก้ว",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150103",
            "subdistrictname": "ศาลาแดง",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150104",
            "subdistrictname": "ป่างิ้ว",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150105",
            "subdistrictname": "บ้านแห",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150106",
            "subdistrictname": "ตลาดกรวด",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150107",
            "subdistrictname": "มหาดไทย",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150108",
            "subdistrictname": "บ้านอิฐ",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150109",
            "subdistrictname": "หัวไผ่",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150110",
            "subdistrictname": "จำปาหล่อ",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150111",
            "subdistrictname": "โพสะ",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150112",
            "subdistrictname": "บ้านรี",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150113",
            "subdistrictname": "คลองวัว",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1501",
            "districtname": "เมืองอ่างทอง",
            "subdistrictcode": "150114",
            "subdistrictname": "ย่านซื่อ",
            "zipcode": "14000"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150201",
            "subdistrictname": "จรเข้ร้อง",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150202",
            "subdistrictname": "ไชยภูมิ",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150203",
            "subdistrictname": "ชัยฤทธิ์",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150204",
            "subdistrictname": "เทวราช",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150205",
            "subdistrictname": "ราชสถิตย์",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150206",
            "subdistrictname": "ไชโย",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150207",
            "subdistrictname": "หลักฟ้า",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150208",
            "subdistrictname": "ชะไว",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1502",
            "districtname": "ไชโย",
            "subdistrictcode": "150209",
            "subdistrictname": "ตรีณรงค์",
            "zipcode": "14140"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150301",
            "subdistrictname": "บางปลากด",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150302",
            "subdistrictname": "ป่าโมก",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150303",
            "subdistrictname": "สายทอง",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150304",
            "subdistrictname": "โรงช้าง",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150305",
            "subdistrictname": "บางเสด็จ",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150306",
            "subdistrictname": "นรสิงห์",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150307",
            "subdistrictname": "เอกราช",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1503",
            "districtname": "ป่าโมก",
            "subdistrictcode": "150308",
            "subdistrictname": "โผงเผง",
            "zipcode": "14130"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150401",
            "subdistrictname": "อ่างแก้ว",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150402",
            "subdistrictname": "อินทประมูล",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150403",
            "subdistrictname": "บางพลับ",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150404",
            "subdistrictname": "หนองแม่ไก่",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150405",
            "subdistrictname": "รำมะสัก",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150406",
            "subdistrictname": "บางระกำ",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150407",
            "subdistrictname": "โพธิ์รังนก",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150408",
            "subdistrictname": "องครักษ์",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150409",
            "subdistrictname": "โคกพุทรา",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150410",
            "subdistrictname": "ยางช้าย",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150411",
            "subdistrictname": "บ่อแร่",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150412",
            "subdistrictname": "ทางพระ",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150413",
            "subdistrictname": "สามง่าม",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150414",
            "subdistrictname": "บางเจ้าฉ่า",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1504",
            "districtname": "โพธิ์ทอง",
            "subdistrictcode": "150415",
            "subdistrictname": "คำหยาด",
            "zipcode": "14120"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150501",
            "subdistrictname": "แสวงหา",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150502",
            "subdistrictname": "ศรีพราน",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150503",
            "subdistrictname": "บ้านพราน",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150504",
            "subdistrictname": "วังน้ำเย็น",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150505",
            "subdistrictname": "สีบัวทอง",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150506",
            "subdistrictname": "ห้วยไผ่",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1505",
            "districtname": "แสวงหา",
            "subdistrictcode": "150507",
            "subdistrictname": "จำลอง",
            "zipcode": "14150"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150601",
            "subdistrictname": "ไผ่จำศิล",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150602",
            "subdistrictname": "ศาลเจ้าโรงทอง",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150603",
            "subdistrictname": "ไผ่ดำพัฒนา",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150604",
            "subdistrictname": "สาวร้องไห้",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150605",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150606",
            "subdistrictname": "ยี่ล้น",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150607",
            "subdistrictname": "บางจัก",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150608",
            "subdistrictname": "ห้วยคันแหลน",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150609",
            "subdistrictname": "คลองขนาก",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150610",
            "subdistrictname": "ไผ่วง",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150611",
            "subdistrictname": "สี่ร้อย",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150612",
            "subdistrictname": "ม่วงเตี้ย",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150613",
            "subdistrictname": "หัวตะพาน",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150614",
            "subdistrictname": "หลักแก้ว",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1506",
            "districtname": "วิเศษชัยชาญ",
            "subdistrictcode": "150615",
            "subdistrictname": "ตลาดใหม่",
            "zipcode": "14110"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1507",
            "districtname": "สามโก้",
            "subdistrictcode": "150701",
            "subdistrictname": "สามโก้",
            "zipcode": "14160"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1507",
            "districtname": "สามโก้",
            "subdistrictcode": "150702",
            "subdistrictname": "ราษฎรพัฒนา",
            "zipcode": "14160"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1507",
            "districtname": "สามโก้",
            "subdistrictcode": "150703",
            "subdistrictname": "อบทม",
            "zipcode": "14160"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1507",
            "districtname": "สามโก้",
            "subdistrictcode": "150704",
            "subdistrictname": "โพธิ์ม่วงพันธ์",
            "zipcode": "14160"
        },
        {
            "isActive": true,
            "provincecode": "อ่างทอง",
            "districtcode": "1507",
            "districtname": "สามโก้",
            "subdistrictcode": "150705",
            "subdistrictname": "มงคลธรรมนิมิต",
            "zipcode": "14160"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160101",
            "subdistrictname": "ทะเลชุบศร",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160102",
            "subdistrictname": "ท่าหิน",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160103",
            "subdistrictname": "กกโก",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160104",
            "subdistrictname": "โก่งธนู",
            "zipcode": "13240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160105",
            "subdistrictname": "เขาพระงาม",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160106",
            "subdistrictname": "เขาสามยอด",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160107",
            "subdistrictname": "โคกกะเทียม",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160108",
            "subdistrictname": "โคกลำพาน",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160109",
            "subdistrictname": "โคกตูม",
            "zipcode": "15210"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160110",
            "subdistrictname": "งิ้วราย",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160111",
            "subdistrictname": "ดอนโพธิ์",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160112",
            "subdistrictname": "ตะลุง",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160114",
            "subdistrictname": "ท่าแค",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160115",
            "subdistrictname": "ท่าศาลา",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160116",
            "subdistrictname": "นิคมสร้างตนเอง",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160117",
            "subdistrictname": "บางขันหมาก",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160118",
            "subdistrictname": "บ้านข่อย",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160119",
            "subdistrictname": "ท้ายตลาด",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160120",
            "subdistrictname": "ป่าตาล",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160121",
            "subdistrictname": "พรหมมาสตร์",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160122",
            "subdistrictname": "โพธิ์เก้าต้น",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160123",
            "subdistrictname": "โพธิ์ตรุ",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160124",
            "subdistrictname": "สี่คลอง",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1601",
            "districtname": "เมืองลพบุรี",
            "subdistrictcode": "160125",
            "subdistrictname": "ถนนใหญ่",
            "zipcode": "15000"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160201",
            "subdistrictname": "พัฒนานิคม",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160202",
            "subdistrictname": "ช่องสาริกา",
            "zipcode": "15220"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160203",
            "subdistrictname": "มะนาวหวาน",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160204",
            "subdistrictname": "ดีลัง",
            "zipcode": "15220"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160205",
            "subdistrictname": "โคกสลุง",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160206",
            "subdistrictname": "ชอนน้อย",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160207",
            "subdistrictname": "หนองบัว",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160208",
            "subdistrictname": "ห้วยขุนราม",
            "zipcode": "18220"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1602",
            "districtname": "พัฒนานิคม",
            "subdistrictcode": "160209",
            "subdistrictname": "น้ำสุด",
            "zipcode": "15140"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160301",
            "subdistrictname": "โคกสำโรง",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160302",
            "subdistrictname": "เกาะแก้ว",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160303",
            "subdistrictname": "ถลุงเหล็ก",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160304",
            "subdistrictname": "หลุมข้าว",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160305",
            "subdistrictname": "ห้วยโป่ง",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160306",
            "subdistrictname": "คลองเกตุ",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160307",
            "subdistrictname": "สะแกราบ",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160308",
            "subdistrictname": "เพนียด",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160309",
            "subdistrictname": "วังเพลิง",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160310",
            "subdistrictname": "ดงมะรุม",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160318",
            "subdistrictname": "วังขอนขว้าง",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160320",
            "subdistrictname": "วังจั่น",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1603",
            "districtname": "โคกสำโรง",
            "subdistrictcode": "160322",
            "subdistrictname": "หนองแขม",
            "zipcode": "15120"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160401",
            "subdistrictname": "ลำนารายณ์",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160402",
            "subdistrictname": "ชัยนารายณ์",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160403",
            "subdistrictname": "ศิลาทิพย์",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160404",
            "subdistrictname": "ห้วยหิน",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160405",
            "subdistrictname": "ม่วงค่อม",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160406",
            "subdistrictname": "บัวชุม",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160407",
            "subdistrictname": "ท่าดินดำ",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160408",
            "subdistrictname": "มะกอกหวาน",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบ���รี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160409",
            "subdistrictname": "ซับตะเคียน",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160410",
            "subdistrictname": "นาโสม",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160411",
            "subdistrictname": "หนองยายโต๊ะ",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160412",
            "subdistrictname": "เกาะรัง",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160414",
            "subdistrictname": "ท่ามะนาว",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160417",
            "subdistrictname": "นิคมลำนารายณ์",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160418",
            "subdistrictname": "ชัยบาดาล",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160419",
            "subdistrictname": "บ้านใหม่สามัคคี",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1604",
            "districtname": "ชัยบาดาล",
            "subdistrictcode": "160422",
            "subdistrictname": "เขาแหลม",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160501",
            "subdistrictname": "ท่าวุ้ง",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160502",
            "subdistrictname": "บางคู้",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160503",
            "subdistrictname": "โพตลาดแก้ว",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160504",
            "subdistrictname": "บางลี่",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160505",
            "subdistrictname": "บางงา",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160506",
            "subdistrictname": "โคกสลุด",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160507",
            "subdistrictname": "เขาสมอคอน",
            "zipcode": "15180"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160508",
            "subdistrictname": "หัวสำโรง",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160509",
            "subdistrictname": "ลาดสาลี่",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160510",
            "subdistrictname": "บ้านเบิก",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1605",
            "districtname": "ท่าวุ้ง",
            "subdistrictcode": "160511",
            "subdistrictname": "มุจลินท์",
            "zipcode": "15150"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160601",
            "subdistrictname": "ไผ่ใหญ่",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160602",
            "subdistrictname": "บ้านทราย",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160603",
            "subdistrictname": "บ้านกล้วย",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160604",
            "subdistrictname": "ดงพลับ",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160605",
            "subdistrictname": "บ้านชี",
            "zipcode": "15180"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160606",
            "subdistrictname": "พุคา",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160607",
            "subdistrictname": "หินปัก",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160608",
            "subdistrictname": "บางพึ่ง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160609",
            "subdistrictname": "หนองทรายขาว",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160610",
            "subdistrictname": "บางกะพี้",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160611",
            "subdistrictname": "หนองเต่า",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160612",
            "subdistrictname": "โพนทอง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160613",
            "subdistrictname": "บางขาม",
            "zipcode": "15180"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160614",
            "subdistrictname": "ดอนดึง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160615",
            "subdistrictname": "ชอนม่วง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160616",
            "subdistrictname": "หนองกระเบียน",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160617",
            "subdistrictname": "สายห้วยแก้ว",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160618",
            "subdistrictname": "มหาสอน",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160619",
            "subdistrictname": "บ้านหมี่",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160620",
            "subdistrictname": "เชียงงา",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160621",
            "subdistrictname": "หนองเมือง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1606",
            "districtname": "บ้านหมี่",
            "subdistrictcode": "160622",
            "subdistrictname": "สนามแจง",
            "zipcode": "15110"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160701",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160702",
            "subdistrictname": "แก่งผักกูด",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160703",
            "subdistrictname": "ซับจำปา",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160704",
            "subdistrictname": "หนองผักแว่น",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160705",
            "subdistrictname": "ทะเลวังวัด",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1607",
            "districtname": "ท่าหลวง",
            "subdistrictcode": "160706",
            "subdistrictname": "หัวลำ",
            "zipcode": "15230"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1608",
            "districtname": "สระโบสถ์",
            "subdistrictcode": "160801",
            "subdistrictname": "สระโบสถ์",
            "zipcode": "15240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1608",
            "districtname": "สระโบสถ์",
            "subdistrictcode": "160802",
            "subdistrictname": "มหาโพธิ",
            "zipcode": "15240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1608",
            "districtname": "สระโบสถ์",
            "subdistrictcode": "160803",
            "subdistrictname": "ทุ่งท่าช้าง",
            "zipcode": "15240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1608",
            "districtname": "สระโบสถ์",
            "subdistrictcode": "160804",
            "subdistrictname": "ห้วยใหญ่",
            "zipcode": "15240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1608",
            "districtname": "สระโบสถ์",
            "subdistrictcode": "160805",
            "subdistrictname": "นิยมชัย",
            "zipcode": "15240"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1609",
            "districtname": "โคกเจริญ",
            "subdistrictcode": "160901",
            "subdistrictname": "โคกเจริญ",
            "zipcode": "15250"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1609",
            "districtname": "โคกเจริญ",
            "subdistrictcode": "160902",
            "subdistrictname": "ยางราก",
            "zipcode": "15250"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1609",
            "districtname": "โคกเจริญ",
            "subdistrictcode": "160903",
            "subdistrictname": "หนองมะค่า",
            "zipcode": "15250"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1609",
            "districtname": "โคกเจริญ",
            "subdistrictcode": "160904",
            "subdistrictname": "วังทอง",
            "zipcode": "15250"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1609",
            "districtname": "โคกเจริญ",
            "subdistrictcode": "160905",
            "subdistrictname": "โคกแสมสาร",
            "zipcode": "15250"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161001",
            "subdistrictname": "ลำสนธิ",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161002",
            "subdistrictname": "ซับสมบูรณ์",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161003",
            "subdistrictname": "หนองรี",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161004",
            "subdistrictname": "กุดตาเพชร",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161005",
            "subdistrictname": "เขารวก",
            "zipcode": "15190"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1610",
            "districtname": "ลำสนธิ",
            "subdistrictcode": "161006",
            "subdistrictname": "เขาน้อย",
            "zipcode": "15130"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161101",
            "subdistrictname": "หนองม่วง",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161102",
            "subdistrictname": "บ่อทอง",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161103",
            "subdistrictname": "ดงดินแดง",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161104",
            "subdistrictname": "ชอนสมบูรณ์",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161105",
            "subdistrictname": "ยางโทน",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "ลพบุรี",
            "districtcode": "1611",
            "districtname": "หนองม่วง",
            "subdistrictcode": "161106",
            "subdistrictname": "ชอนสารเดช",
            "zipcode": "15170"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170101",
            "subdistrictname": "บางพุทรา",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170102",
            "subdistrictname": "บางมัญ",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170103",
            "subdistrictname": "โพกรวม",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170104",
            "subdistrictname": "ม่วงหมู่",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170105",
            "subdistrictname": "หัวไผ่",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170106",
            "subdistrictname": "ต้นโพธิ์",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170107",
            "subdistrictname": "จักรสีห์",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1701",
            "districtname": "เมืองสิงห์บุรี",
            "subdistrictcode": "170108",
            "subdistrictname": "บางกระบือ",
            "zipcode": "16000"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170201",
            "subdistrictname": "สิงห์",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170202",
            "subdistrictname": "ไม้ดัด",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170203",
            "subdistrictname": "เชิงกลัด",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170204",
            "subdistrictname": "โพชนไก่",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170205",
            "subdistrictname": "แม่ลา",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170206",
            "subdistrictname": "บ้านจ่า",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170207",
            "subdistrictname": "พักทัน",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1702",
            "districtname": "บางระจัน",
            "subdistrictcode": "170208",
            "subdistrictname": "สระแจง",
            "zipcode": "16130"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170301",
            "subdistrictname": "โพทะเล",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170302",
            "subdistrictname": "บางระจัน",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170303",
            "subdistrictname": "โพสังโฆ",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170304",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170305",
            "subdistrictname": "คอทราย",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1703",
            "districtname": "ค่ายบางระจัน",
            "subdistrictcode": "170306",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "16150"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170401",
            "subdistrictname": "พระงาม",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170402",
            "subdistrictname": "พรหมบุรี",
            "zipcode": "16160"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170403",
            "subdistrictname": "บางน้ำเชี่ยว",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170404",
            "subdistrictname": "บ้านหม้อ",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170405",
            "subdistrictname": "บ้านแป้ง",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170406",
            "subdistrictname": "หัวป่า",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1704",
            "districtname": "พรหมบุรี",
            "subdistrictcode": "170407",
            "subdistrictname": "โรงช้าง",
            "zipcode": "16120"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1705",
            "districtname": "ท่าช้าง",
            "subdistrictcode": "170501",
            "subdistrictname": "ถอนสมอ",
            "zipcode": "16140"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1705",
            "districtname": "ท่าช้าง",
            "subdistrictcode": "170502",
            "subdistrictname": "โพประจักษ์",
            "zipcode": "16140"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1705",
            "districtname": "ท่าช้าง",
            "subdistrictcode": "170503",
            "subdistrictname": "วิหารขาว",
            "zipcode": "16140"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1705",
            "districtname": "ท่าช้าง",
            "subdistrictcode": "170504",
            "subdistrictname": "พิกุลทอง",
            "zipcode": "16140"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170601",
            "subdistrictname": "อินทร์บุรี",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170602",
            "subdistrictname": "ประศุก",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170603",
            "subdistrictname": "ทับยา",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170604",
            "subdistrictname": "งิ้วราย",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170605",
            "subdistrictname": "ชีน้ำร้าย",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170606",
            "subdistrictname": "ท่างาม",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170607",
            "subdistrictname": "น้ำตาล",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170608",
            "subdistrictname": "ทองเอน",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170609",
            "subdistrictname": "ห้วยชัน",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "สิงห์บุรี",
            "districtcode": "1706",
            "districtname": "อินทร์บุรี",
            "subdistrictcode": "170610",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "16110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180101",
            "subdistrictname": "ในเมือง",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180102",
            "subdistrictname": "บ้านกล้วย",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180103",
            "subdistrictname": "ท่าชัย",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180104",
            "subdistrictname": "ชัยนาท",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180105",
            "subdistrictname": "เขาท่าพระ",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180106",
            "subdistrictname": "หาดท่าเสา",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180107",
            "subdistrictname": "ธรรมามูล",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180108",
            "subdistrictname": "เสือโฮก",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1801",
            "districtname": "เมืองชัยนาท",
            "subdistrictcode": "180109",
            "subdistrictname": "นางลือ",
            "zipcode": "17000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180201",
            "subdistrictname": "คุ้งสำเภา",
            "zipcode": "17110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180202",
            "subdistrictname": "วัดโคก",
            "zipcode": "17110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180203",
            "subdistrictname": "ศิลาดาน",
            "zipcode": "17110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180204",
            "subdistrictname": "ท่าฉนวน",
            "zipcode": "17110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180205",
            "subdistrictname": "หางน้ำสาคร",
            "zipcode": "17170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180206",
            "subdistrictname": "ไร่พัฒนา",
            "zipcode": "17170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1802",
            "districtname": "มโนรมย์",
            "subdistrictcode": "180207",
            "subdistrictname": "อู่ตะเภา",
            "zipcode": "17170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180301",
            "subdistrictname": "วัดสิงห์",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180302",
            "subdistrictname": "มะขามเฒ่า",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180303",
            "subdistrictname": "หนองน้อย",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180304",
            "subdistrictname": "หนองบัว",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180306",
            "subdistrictname": "หนองขุ่น",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180307",
            "subdistrictname": "บ่อแร่",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1803",
            "districtname": "วัดสิงห์",
            "subdistrictcode": "180311",
            "subdistrictname": "วังหมัน",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180401",
            "subdistrictname": "สรรพยา",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180402",
            "subdistrictname": "ตลุก",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180403",
            "subdistrictname": "เขาแก้ว",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180404",
            "subdistrictname": "โพนางดำตก",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180405",
            "subdistrictname": "โพนางดำออก",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180406",
            "subdistrictname": "บางหลวง",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1804",
            "districtname": "สรรพยา",
            "subdistrictcode": "180407",
            "subdistrictname": "หาดอาษา",
            "zipcode": "17150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180501",
            "subdistrictname": "แพรกศรีราชา",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180502",
            "subdistrictname": "เที่ยงแท้",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180503",
            "subdistrictname": "ห้วยกรด",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180504",
            "subdistrictname": "โพงาม",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180505",
            "subdistrictname": "บางขุด",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180506",
            "subdistrictname": "ดงคอน",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180507",
            "subdistrictname": "ดอนกำ",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1805",
            "districtname": "สรรคบุรี",
            "subdistrictcode": "180508",
            "subdistrictname": "ห้วยกรดพัฒนา",
            "zipcode": "17140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180601",
            "subdistrictname": "หันคา",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180602",
            "subdistrictname": "บ้านเชี่ยน",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180605",
            "subdistrictname": "ไพรนกยูง",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180606",
            "subdistrictname": "หนองแซง",
            "zipcode": "17160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180607",
            "subdistrictname": "ห้วยงู",
            "zipcode": "17160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180608",
            "subdistrictname": "วังไก่เถื่อน",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180609",
            "subdistrictname": "เด่นใหญ่",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1806",
            "districtname": "หันคา",
            "subdistrictcode": "180611",
            "subdistrictname": "สามง่ามท่าโบสถ์",
            "zipcode": "17160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1807",
            "districtname": "หนองมะโมง",
            "subdistrictcode": "180701",
            "subdistrictname": "หนองมะโมง",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1807",
            "districtname": "หนองมะโมง",
            "subdistrictcode": "180702",
            "subdistrictname": "วังตะเคียน",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1807",
            "districtname": "หนองมะโมง",
            "subdistrictcode": "180703",
            "subdistrictname": "สะพานหิน",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1807",
            "districtname": "หนองมะโมง",
            "subdistrictcode": "180704",
            "subdistrictname": "กุดจอก",
            "zipcode": "17120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1808",
            "districtname": "เนินขาม",
            "subdistrictcode": "180801",
            "subdistrictname": "เนินขาม",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1808",
            "districtname": "เนินขาม",
            "subdistrictcode": "180802",
            "subdistrictname": "กะบกเตี้ย",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยนาท",
            "districtcode": "1808",
            "districtname": "เนินขาม",
            "subdistrictcode": "180803",
            "subdistrictname": "สุขเดือนห้า",
            "zipcode": "17130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190101",
            "subdistrictname": "ปากเพรียว",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190105",
            "subdistrictname": "ดาวเรือง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190106",
            "subdistrictname": "นาโฉง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190107",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190108",
            "subdistrictname": "หนองโน",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190109",
            "subdistrictname": "หนองยาว",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190110",
            "subdistrictname": "ปากข้าวสาร",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190111",
            "subdistrictname": "หนองปลาไหล",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190112",
            "subdistrictname": "กุดนกเปล้า",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190113",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1901",
            "districtname": "เมืองสระบุรี",
            "subdistrictcode": "190114",
            "subdistrictname": "ตะกุด",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190201",
            "subdistrictname": "แก่งคอย",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190202",
            "subdistrictname": "ทับกวาง",
            "zipcode": "18260"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190203",
            "subdistrictname": "ตาลเดี่ยว",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190204",
            "subdistrictname": "ห้วยแห้ง",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190205",
            "subdistrictname": "ท่าคล้อ",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190206",
            "subdistrictname": "หินซ้อน",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190207",
            "subdistrictname": "บ้านธาตุ",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190208",
            "subdistrictname": "บ้านป่า",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190209",
            "subdistrictname": "ท่าตูม",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190210",
            "subdistrictname": "ชะอม",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190211",
            "subdistrictname": "สองคอน",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190212",
            "subdistrictname": "เตาปูน",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190213",
            "subdistrictname": "ชำผักแพว",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1902",
            "districtname": "แก่งคอย",
            "subdistrictcode": "190215",
            "subdistrictname": "ท่ามะปราง",
            "zipcode": "18110"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190301",
            "subdistrictname": "หนองแค",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190302",
            "subdistrictname": "กุ่มหัก",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190303",
            "subdistrictname": "คชสิทธิ์",
            "zipcode": "18250"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190304",
            "subdistrictname": "โคกตูม",
            "zipcode": "18250"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190305",
            "subdistrictname": "โคกแย้",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190306",
            "subdistrictname": "บัวลอย",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190307",
            "subdistrictname": "ไผ่ต่ำ",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190308",
            "subdistrictname": "โพนทอง",
            "zipcode": "18250"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190309",
            "subdistrictname": "ห้วยขมิ้น",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190310",
            "subdistrictname": "ห้วยทราย",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "ส��ะบุรี",
            "districtcode": "1903",
            "districtname": "หนองแ���",
            "subdistrictcode": "190311",
            "subdistrictname": "หนองไข่น้ำ",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190312",
            "subdistrictname": "หนองแขม",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190313",
            "subdistrictname": "หนองจิก",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190314",
            "subdistrictname": "หนองจรเข้",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190315",
            "subdistrictname": "หนองนาก",
            "zipcode": "18230"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190316",
            "subdistrictname": "หนองปลาหมอ",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190317",
            "subdistrictname": "หนองปลิง",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1903",
            "districtname": "หนองแค",
            "subdistrictcode": "190318",
            "subdistrictname": "หนองโรง",
            "zipcode": "18140"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190401",
            "subdistrictname": "หนองหมู",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190402",
            "subdistrictname": "บ้านลำ",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190403",
            "subdistrictname": "คลองเรือ",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190404",
            "subdistrictname": "วิหารแดง",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190405",
            "subdistrictname": "หนองสรวง",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1904",
            "districtname": "วิหารแดง",
            "subdistrictcode": "190406",
            "subdistrictname": "เจริญธรรม",
            "zipcode": "18150"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190501",
            "subdistrictname": "หนองแซง",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190502",
            "subdistrictname": "หนองควายโซ",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190503",
            "subdistrictname": "หนองหัวโพ",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190504",
            "subdistrictname": "หนองสีดา",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190505",
            "subdistrictname": "หนองกบ",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190506",
            "subdistrictname": "ไก่เส่า",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190507",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190508",
            "subdistrictname": "ม่วงหวาน",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1905",
            "districtname": "หนองแซง",
            "subdistrictcode": "190509",
            "subdistrictname": "เขาดิน",
            "zipcode": "18170"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190601",
            "subdistrictname": "บ้านหมอ",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190602",
            "subdistrictname": "บางโขมด",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190603",
            "subdistrictname": "สร่างโศก",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190604",
            "subdistrictname": "ตลาดน้อย",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190605",
            "subdistrictname": "หรเทพ",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190606",
            "subdistrictname": "โคกใหญ่",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190607",
            "subdistrictname": "ไผ่ขวาง",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190608",
            "subdistrictname": "บ้านครัว",
            "zipcode": "18270"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1906",
            "districtname": "บ้านหมอ",
            "subdistrictcode": "190609",
            "subdistrictname": "หนองบัว",
            "zipcode": "18130"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1907",
            "districtname": "ดอนพุด",
            "subdistrictcode": "190701",
            "subdistrictname": "ดอนพุด",
            "zipcode": "18210"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1907",
            "districtname": "ดอนพุด",
            "subdistrictcode": "190702",
            "subdistrictname": "ไผ่หลิ่ว",
            "zipcode": "18210"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1907",
            "districtname": "ดอนพุด",
            "subdistrictcode": "190703",
            "subdistrictname": "บ้านหลวง",
            "zipcode": "18210"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1907",
            "districtname": "ดอนพุด",
            "subdistrictcode": "190704",
            "subdistrictname": "ดงตะงาว",
            "zipcode": "18210"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1908",
            "districtname": "หนองโดน",
            "subdistrictcode": "190801",
            "subdistrictname": "หนองโดน",
            "zipcode": "18190"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1908",
            "districtname": "หนองโดน",
            "subdistrictcode": "190802",
            "subdistrictname": "บ้านกลับ",
            "zipcode": "18190"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1908",
            "districtname": "หนองโดน",
            "subdistrictcode": "190803",
            "subdistrictname": "ดอนทอง",
            "zipcode": "18190"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1908",
            "districtname": "หนองโดน",
            "subdistrictcode": "190804",
            "subdistrictname": "บ้านโปร่ง",
            "zipcode": "18190"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190901",
            "subdistrictname": "พระพุทธบาท",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190902",
            "subdistrictname": "ขุนโขลน",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190903",
            "subdistrictname": "ธารเกษม",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190904",
            "subdistrictname": "นายาว",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190905",
            "subdistrictname": "พุคำจาน",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190906",
            "subdistrictname": "เขาวง",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190907",
            "subdistrictname": "ห้วยป่าหวาย",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190908",
            "subdistrictname": "พุกร่าง",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1909",
            "districtname": "พระพุทธบาท",
            "subdistrictcode": "190909",
            "subdistrictname": "หนองแก",
            "zipcode": "18120"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191001",
            "subdistrictname": "เสาไห้",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191002",
            "subdistrictname": "บ้านยาง",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191003",
            "subdistrictname": "หัวปลวก",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191004",
            "subdistrictname": "งิ้วงาม",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191005",
            "subdistrictname": "ศาลารีไทย",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191006",
            "subdistrictname": "ต้นตาล",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191007",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191008",
            "subdistrictname": "พระยาทด",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191009",
            "subdistrictname": "ม่วงงาม",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191010",
            "subdistrictname": "เริงราง",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191011",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1910",
            "districtname": "เสาไห้",
            "subdistrictcode": "191012",
            "subdistrictname": "สวนดอกไม้",
            "zipcode": "18160"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191101",
            "subdistrictname": "มวกเหล็ก",
            "zipcode": "18180"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191102",
            "subdistrictname": "มิตรภาพ",
            "zipcode": "18180"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191104",
            "subdistrictname": "หนองย่างเสือ",
            "zipcode": "18180"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191105",
            "subdistrictname": "ลำสมพุง",
            "zipcode": "18180"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191107",
            "subdistrictname": "ลำพญากลาง",
            "zipcode": "18180"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1911",
            "districtname": "มวกเหล็ก",
            "subdistrictcode": "191109",
            "subdistrictname": "ซับสนุ่น",
            "zipcode": "18220"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1912",
            "districtname": "วังม่วง",
            "subdistrictcode": "191201",
            "subdistrictname": "แสลงพัน",
            "zipcode": "18220"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1912",
            "districtname": "วังม่วง",
            "subdistrictcode": "191202",
            "subdistrictname": "คำพราน",
            "zipcode": "18220"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1912",
            "districtname": "วังม่วง",
            "subdistrictcode": "191203",
            "subdistrictname": "วังม่วง",
            "zipcode": "18220"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191301",
            "subdistrictname": "เขาดินพัฒนา",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191302",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191303",
            "subdistrictname": "ผึ้งรวง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191304",
            "subdistrictname": "พุแค",
            "zipcode": "18240"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191305",
            "subdistrictname": "ห้วยบง",
            "zipcode": "18000"
        },
        {
            "isActive": true,
            "provincecode": "สระบุรี",
            "districtcode": "1913",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "191306",
            "subdistrictname": "หน้าพระลาน",
            "zipcode": "18240"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200101",
            "subdistrictname": "บางปลาสร้อย",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200102",
            "subdistrictname": "มะขามหย่ง",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200103",
            "subdistrictname": "บ้านโขด",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200104",
            "subdistrictname": "แสนสุข",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200105",
            "subdistrictname": "บ้านสวน",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200106",
            "subdistrictname": "หนองรี",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200107",
            "subdistrictname": "นาป่า",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200108",
            "subdistrictname": "หนองข้างคอก",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200109",
            "subdistrictname": "ดอนหัวฬ่อ",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200110",
            "subdistrictname": "หนองไม้แดง",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200111",
            "subdistrictname": "บางทราย",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200112",
            "subdistrictname": "คลองตำหรุ",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200113",
            "subdistrictname": "เหมือง",
            "zipcode": "20130"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200114",
            "subdistrictname": "บ้านปึก",
            "zipcode": "20130"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200115",
            "subdistrictname": "ห้วยกะปิ",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200116",
            "subdistrictname": "เสม็ด",
            "zipcode": "20130"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200117",
            "subdistrictname": "อ่างศิลา",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2001",
            "districtname": "เมืองชลบุรี",
            "subdistrictcode": "200118",
            "subdistrictname": "สำนักบก",
            "zipcode": "20000"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200201",
            "subdistrictname": "บ้านบึง",
            "zipcode": "20170"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200202",
            "subdistrictname": "คลองกิ่ว",
            "zipcode": "20220"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200203",
            "subdistrictname": "มาบไผ่",
            "zipcode": "20170"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200204",
            "subdistrictname": "หนองซ้ำซาก",
            "zipcode": "20170"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200205",
            "subdistrictname": "หนองบอนแดง",
            "zipcode": "20170"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200206",
            "subdistrictname": "หนองชาก",
            "zipcode": "20170"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200207",
            "subdistrictname": "หนองอิรุณ",
            "zipcode": "20220"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2002",
            "districtname": "บ้านบึง",
            "subdistrictcode": "200208",
            "subdistrictname": "หนองไผ่แก้ว",
            "zipcode": "20220"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2003",
            "districtname": "หนองใหญ่",
            "subdistrictcode": "200301",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "20190"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2003",
            "districtname": "หนองใหญ่",
            "subdistrictcode": "200302",
            "subdistrictname": "คลองพลู",
            "zipcode": "20190"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2003",
            "districtname": "หนองใหญ่",
            "subdistrictcode": "200303",
            "subdistrictname": "หนองเสือช้าง",
            "zipcode": "20190"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2003",
            "districtname": "หนองใหญ่",
            "subdistrictcode": "200304",
            "subdistrictname": "ห้างสูง",
            "zipcode": "20190"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2003",
            "districtname": "หนองใหญ่",
            "subdistrictcode": "200305",
            "subdistrictname": "เขาซก",
            "zipcode": "20190"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200401",
            "subdistrictname": "บางละมุง",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200402",
            "subdistrictname": "หนองปรือ",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200403",
            "subdistrictname": "หนองปลาไหล",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200404",
            "subdistrictname": "โป่ง",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200405",
            "subdistrictname": "เขาไม้แก้ว",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200406",
            "subdistrictname": "ห้วยใหญ่",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200407",
            "subdistrictname": "ตะเคียนเตี้ย",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2004",
            "districtname": "บางละมุง",
            "subdistrictcode": "200408",
            "subdistrictname": "นาเกลือ",
            "zipcode": "20150"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200501",
            "subdistrictname": "พานทอง",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200502",
            "subdistrictname": "หนองตำลึง",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200503",
            "subdistrictname": "มาบโป่ง",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200504",
            "subdistrictname": "หนองกะขะ",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200505",
            "subdistrictname": "หนองหงษ์",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200506",
            "subdistrictname": "โคกขี้หนอน",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200507",
            "subdistrictname": "บ้านเก่า",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200508",
            "subdistrictname": "หน้าประดู่",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200509",
            "subdistrictname": "บางนาง",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200510",
            "subdistrictname": "เกาะลอย",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2005",
            "districtname": "พานทอง",
            "subdistrictcode": "200511",
            "subdistrictname": "บางหัก",
            "zipcode": "20160"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200601",
            "subdistrictname": "พนัสนิคม",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200602",
            "subdistrictname": "หน้าพระธาตุ",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200603",
            "subdistrictname": "วัดหลวง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200604",
            "subdistrictname": "บ้านเซิด",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200605",
            "subdistrictname": "นาเริก",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200606",
            "subdistrictname": "หมอนนาง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200607",
            "subdistrictname": "สระสี่เหลี่ยม",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200608",
            "subdistrictname": "วัดโบสถ์",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200609",
            "subdistrictname": "กุฎโง้ง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200610",
            "subdistrictname": "หัวถนน",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200611",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200613",
            "subdistrictname": "หนองปรือ",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200614",
            "subdistrictname": "หนองขยาด",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200615",
            "subdistrictname": "ทุ่งขวาง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200616",
            "subdistrictname": "หนองเหียง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200617",
            "subdistrictname": "นาวังหิน",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200618",
            "subdistrictname": "บ้านช้าง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200620",
            "subdistrictname": "โคกเพลาะ",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200621",
            "subdistrictname": "ไร่หลักทอง",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2006",
            "districtname": "พนัสนิคม",
            "subdistrictcode": "200622",
            "subdistrictname": "นามะตูม",
            "zipcode": "20140"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200701",
            "subdistrictname": "ศรีราชา",
            "zipcode": "20110"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200702",
            "subdistrictname": "สุรศักดิ์",
            "zipcode": "20110"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200703",
            "subdistrictname": "ทุ่งสุขลา",
            "zipcode": "20230"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200704",
            "subdistrictname": "บึง",
            "zipcode": "20230"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200705",
            "subdistrictname": "หนองขาม",
            "zipcode": "20110"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200706",
            "subdistrictname": "เขาคันทรง",
            "zipcode": "20110"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200707",
            "subdistrictname": "บางพระ",
            "zipcode": "20110"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2007",
            "districtname": "ศรีราชา",
            "subdistrictcode": "200708",
            "subdistrictname": "บ่อวิน",
            "zipcode": "20230"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2008",
            "districtname": "เกาะสีชัง",
            "subdistrictcode": "200801",
            "subdistrictname": "ท่าเทววงษ์",
            "zipcode": "20120"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2009",
            "districtname": "สัตหีบ",
            "subdistrictcode": "200901",
            "subdistrictname": "สัตหีบ",
            "zipcode": "20180"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2009",
            "districtname": "สัตหีบ",
            "subdistrictcode": "200902",
            "subdistrictname": "นาจอมเทียน",
            "zipcode": "20250"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2009",
            "districtname": "สัตหีบ",
            "subdistrictcode": "200903",
            "subdistrictname": "พลูตาหลวง",
            "zipcode": "20180"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2009",
            "districtname": "สัตหีบ",
            "subdistrictcode": "200904",
            "subdistrictname": "บางเสร่",
            "zipcode": "20250"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2009",
            "districtname": "สัตหีบ",
            "subdistrictcode": "200905",
            "subdistrictname": "แสมสาร",
            "zipcode": "20180"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201001",
            "subdistrictname": "บ่อทอง",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201002",
            "subdistrictname": "วัดสุวรรณ",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201003",
            "subdistrictname": "บ่อกวางทอง",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201004",
            "subdistrictname": "ธาตุทอง",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201005",
            "subdistrictname": "เกษตรสุวรรณ",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2010",
            "districtname": "บ่อทอง",
            "subdistrictcode": "201006",
            "subdistrictname": "พลวงทอง",
            "zipcode": "20270"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2011",
            "districtname": "เกาะจันทร์",
            "subdistrictcode": "201101",
            "subdistrictname": "เกาะจันทร์",
            "zipcode": "20240"
        },
        {
            "isActive": true,
            "provincecode": "ชลบุรี",
            "districtcode": "2011",
            "districtname": "เกาะจันทร์",
            "subdistrictcode": "201102",
            "subdistrictname": "ท่าบุญมี",
            "zipcode": "20240"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210101",
            "subdistrictname": "ท่าประดู่",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210102",
            "subdistrictname": "เชิงเนิน",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210103",
            "subdistrictname": "ตะพง",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210104",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210105",
            "subdistrictname": "เพ",
            "zipcode": "21160"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210106",
            "subdistrictname": "แกลง",
            "zipcode": "21160"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210107",
            "subdistrictname": "บ้านแลง",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210108",
            "subdistrictname": "นาตาขวัญ",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210109",
            "subdistrictname": "เนินพระ",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210110",
            "subdistrictname": "กะเฉด",
            "zipcode": "21100"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210111",
            "subdistrictname": "ทับมา",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210112",
            "subdistrictname": "น้ำคอก",
            "zipcode": "21000"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210113",
            "subdistrictname": "ห้วยโป่ง",
            "zipcode": "21150"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210114",
            "subdistrictname": "มาบตาพุด",
            "zipcode": "21150"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2101",
            "districtname": "เมืองระยอง",
            "subdistrictcode": "210115",
            "subdistrictname": "สำนักทอง",
            "zipcode": "21100"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2102",
            "districtname": "บ้านฉาง",
            "subdistrictcode": "210201",
            "subdistrictname": "สำนักท้อน",
            "zipcode": "21130"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2102",
            "districtname": "บ้านฉาง",
            "subdistrictcode": "210202",
            "subdistrictname": "พลา",
            "zipcode": "21130"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2102",
            "districtname": "บ้านฉาง",
            "subdistrictcode": "210203",
            "subdistrictname": "บ้านฉาง",
            "zipcode": "21130"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210301",
            "subdistrictname": "ทางเกวียน",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210302",
            "subdistrictname": "วังหว้า",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210303",
            "subdistrictname": "ชากโดน",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210304",
            "subdistrictname": "เนินฆ้อ",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210305",
            "subdistrictname": "กร่ำ",
            "zipcode": "21190"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210306",
            "subdistrictname": "ชากพง",
            "zipcode": "21190"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210307",
            "subdistrictname": "กระแสบน",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210308",
            "subdistrictname": "บ้านนา",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210309",
            "subdistrictname": "ทุ่งควายกิน",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210310",
            "subdistrictname": "กองดิน",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210311",
            "subdistrictname": "คลองปูน",
            "zipcode": "21170"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210312",
            "subdistrictname": "พังราด",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210313",
            "subdistrictname": "ปากน้ำกระแส",
            "zipcode": "21170"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210317",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2103",
            "districtname": "แกลง",
            "subdistrictcode": "210318",
            "subdistrictname": "สองสลึง",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2104",
            "districtname": "วังจันทร์",
            "subdistrictcode": "210401",
            "subdistrictname": "วังจันทร์",
            "zipcode": "21210"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2104",
            "districtname": "วังจันทร์",
            "subdistrictcode": "210402",
            "subdistrictname": "ชุมแสง",
            "zipcode": "21210"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2104",
            "districtname": "วังจันทร์",
            "subdistrictcode": "210403",
            "subdistrictname": "ป่ายุบใน",
            "zipcode": "21210"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2104",
            "districtname": "วังจันทร์",
            "subdistrictcode": "210404",
            "subdistrictname": "พลงตาเอี่ยม",
            "zipcode": "21210"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210501",
            "subdistrictname": "บ้านค่าย",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210502",
            "subdistrictname": "หนองละลอก",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210503",
            "subdistrictname": "หนองตะพาน",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210504",
            "subdistrictname": "ตาขัน",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210505",
            "subdistrictname": "บางบุตร",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210506",
            "subdistrictname": "หนองบัว",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2105",
            "districtname": "บ้านค่าย",
            "subdistrictcode": "210507",
            "subdistrictname": "ชากบก",
            "zipcode": "21120"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210601",
            "subdistrictname": "ปลวกแดง",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210602",
            "subdistrictname": "ตาสิทธิ์",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210603",
            "subdistrictname": "ละหาร",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210604",
            "subdistrictname": "แม่น้ำคู้",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210605",
            "subdistrictname": "มาบยางพร",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2106",
            "districtname": "ปลวกแดง",
            "subdistrictcode": "210606",
            "subdistrictname": "หนองไร่",
            "zipcode": "21140"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2107",
            "districtname": "เขาชะเมา",
            "subdistrictcode": "210701",
            "subdistrictname": "น้ำเป็น",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2107",
            "districtname": "เขาชะเมา",
            "subdistrictcode": "210702",
            "subdistrictname": "ห้วยทับมอญ",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2107",
            "districtname": "เขาชะเมา",
            "subdistrictcode": "210703",
            "subdistrictname": "ชำฆ้อ",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2107",
            "districtname": "เขาชะเมา",
            "subdistrictcode": "210704",
            "subdistrictname": "เขาน้อย",
            "zipcode": "21110"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2108",
            "districtname": "นิคมพัฒนา",
            "subdistrictcode": "210801",
            "subdistrictname": "นิคมพัฒนา",
            "zipcode": "21180"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2108",
            "districtname": "นิคมพัฒนา",
            "subdistrictcode": "210802",
            "subdistrictname": "มาบข่า",
            "zipcode": "21180"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2108",
            "districtname": "นิคมพัฒนา",
            "subdistrictcode": "210803",
            "subdistrictname": "พนานิคม",
            "zipcode": "21180"
        },
        {
            "isActive": true,
            "provincecode": "ระยอง",
            "districtcode": "2108",
            "districtname": "นิคมพัฒนา",
            "subdistrictcode": "210804",
            "subdistrictname": "มะขามคู่",
            "zipcode": "21180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220101",
            "subdistrictname": "ตลาด",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220102",
            "subdistrictname": "วัดใหม่",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220103",
            "subdistrictname": "คลองนารายณ์",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220104",
            "subdistrictname": "เกาะขวาง",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220105",
            "subdistrictname": "คมบาง",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220106",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220107",
            "subdistrictname": "จันทนิมิต",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220108",
            "subdistrictname": "บางกะจะ",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220109",
            "subdistrictname": "แสลง",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220110",
            "subdistrictname": "หนองบัว",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2201",
            "districtname": "เมืองจันทบุรี",
            "subdistrictcode": "220111",
            "subdistrictname": "พลับพลา",
            "zipcode": "22000"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220201",
            "subdistrictname": "ขลุง",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220202",
            "subdistrictname": "บ่อ",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220203",
            "subdistrictname": "เกวียนหัก",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220204",
            "subdistrictname": "ตะปอน",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220205",
            "subdistrictname": "บางชัน",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220206",
            "subdistrictname": "วันยาว",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220207",
            "subdistrictname": "ซึ้ง",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220208",
            "subdistrictname": "มาบไพ",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220209",
            "subdistrictname": "วังสรรพรส",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220210",
            "subdistrictname": "ตรอกนอง",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220211",
            "subdistrictname": "ตกพรม",
            "zipcode": "22110"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2202",
            "districtname": "ขลุง",
            "subdistrictcode": "220212",
            "subdistrictname": "บ่อเวฬุ",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220301",
            "subdistrictname": "ท่าใหม่",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220302",
            "subdistrictname": "ยายร้า",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220303",
            "subdistrictname": "สีพยา",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220304",
            "subdistrictname": "บ่อพุ",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220305",
            "subdistrictname": "พลอยแหวน",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220306",
            "subdistrictname": "เขาวัว",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220307",
            "subdistrictname": "เขาบายศรี",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220308",
            "subdistrictname": "สองพี่น้อง",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220309",
            "subdistrictname": "ทุ่งเบญจา",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220311",
            "subdistrictname": "รำพัน",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220312",
            "subdistrictname": "โขมง",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220313",
            "subdistrictname": "ตะกาดเง้า",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220314",
            "subdistrictname": "คลองขุด",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2203",
            "districtname": "ท่าใหม่",
            "subdistrictcode": "220324",
            "subdistrictname": "เขาแก้ว",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน",
            "subdistrictcode": "220401",
            "subdistrictname": "ทับไทร",
            "zipcode": "22140"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน",
            "subdistrictcode": "220402",
            "subdistrictname": "โป่งน้ำร้อน",
            "zipcode": "22140"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน",
            "subdistrictcode": "220404",
            "subdistrictname": "หนองตาคง",
            "zipcode": "22140"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน",
            "subdistrictcode": "220409",
            "subdistrictname": "เทพนิมิต",
            "zipcode": "22140"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2204",
            "districtname": "โป่งน้ำร้อน",
            "subdistrictcode": "220410",
            "subdistrictname": "คลองใหญ่",
            "zipcode": "22140"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220501",
            "subdistrictname": "มะขาม",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220502",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220503",
            "subdistrictname": "ปัถวี",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220504",
            "subdistrictname": "วังแซ้ม",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220506",
            "subdistrictname": "ฉมัน",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2205",
            "districtname": "มะขาม",
            "subdistrictcode": "220508",
            "subdistrictname": "อ่างคีรี",
            "zipcode": "22150"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220601",
            "subdistrictname": "ปากน้ำแหลมสิงห์",
            "zipcode": "22130"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220602",
            "subdistrictname": "เกาะเปริด",
            "zipcode": "22130"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220603",
            "subdistrictname": "หนองชิ่ม",
            "zipcode": "22130"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220604",
            "subdistrictname": "พลิ้ว",
            "zipcode": "22190"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220605",
            "subdistrictname": "คลองน้ำเค็ม",
            "zipcode": "22190"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220606",
            "subdistrictname": "บางสระเก้า",
            "zipcode": "22190"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2206",
            "districtname": "แหลมสิงห์",
            "subdistrictcode": "220607",
            "subdistrictname": "บางกะไชย",
            "zipcode": "22120"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2207",
            "districtname": "สอยดาว",
            "subdistrictcode": "220701",
            "subdistrictname": "ปะตง",
            "zipcode": "22180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2207",
            "districtname": "สอยดาว",
            "subdistrictcode": "220702",
            "subdistrictname": "ทุ่งขนาน",
            "zipcode": "22180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2207",
            "districtname": "สอยดาว",
            "subdistrictcode": "220703",
            "subdistrictname": "ทับช้าง",
            "zipcode": "22180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2207",
            "districtname": "สอยดาว",
            "subdistrictcode": "220704",
            "subdistrictname": "ทรายขาว",
            "zipcode": "22180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2207",
            "districtname": "สอยดาว",
            "subdistrictcode": "220705",
            "subdistrictname": "สะตอน",
            "zipcode": "22180"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว",
            "subdistrictcode": "220801",
            "subdistrictname": "แก่งหางแมว",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว",
            "subdistrictcode": "220802",
            "subdistrictname": "ขุนซ่อง",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว",
            "subdistrictcode": "220803",
            "subdistrictname": "สามพี่น้อง",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว",
            "subdistrictcode": "220804",
            "subdistrictname": "พวา",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2208",
            "districtname": "แก่งหางแมว",
            "subdistrictcode": "220805",
            "subdistrictname": "เขาวงกต",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220901",
            "subdistrictname": "นายายอาม",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220902",
            "subdistrictname": "วังโตนด",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220903",
            "subdistrictname": "กระแจะ",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220904",
            "subdistrictname": "สนามไชย",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220905",
            "subdistrictname": "ช้างข้าม",
            "zipcode": "22160"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2209",
            "districtname": "นายายอาม",
            "subdistrictcode": "220906",
            "subdistrictname": "วังใหม่",
            "zipcode": "22170"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ",
            "subdistrictcode": "221001",
            "subdistrictname": "ชากไทย",
            "zipcode": "22210"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ",
            "subdistrictcode": "221002",
            "subdistrictname": "พลวง",
            "zipcode": "22210"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ",
            "subdistrictcode": "221003",
            "subdistrictname": "ตะเคียนทอง",
            "zipcode": "22210"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ",
            "subdistrictcode": "221004",
            "subdistrictname": "คลองพลู",
            "zipcode": "22210"
        },
        {
            "isActive": true,
            "provincecode": "จันทบุรี",
            "districtcode": "2210",
            "districtname": "เขาคิชฌกูฏ",
            "subdistrictcode": "221005",
            "subdistrictname": "จันทเขลม",
            "zipcode": "22210"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230101",
            "subdistrictname": "บางพระ",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230102",
            "subdistrictname": "หนองเสม็ด",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230103",
            "subdistrictname": "หนองโสน",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230104",
            "subdistrictname": "หนองคันทรง",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230105",
            "subdistrictname": "ห้วงน้ำขาว",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230106",
            "subdistrictname": "อ่าวใหญ่",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230107",
            "subdistrictname": "วังกระแจะ",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230108",
            "subdistrictname": "ห้วยแร้ง",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230109",
            "subdistrictname": "เนินทราย",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230110",
            "subdistrictname": "ท่าพริก",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230111",
            "subdistrictname": "ท่ากุ่ม",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230112",
            "subdistrictname": "ตะกาง",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230113",
            "subdistrictname": "ชำราก",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2301",
            "districtname": "เมืองตราด",
            "subdistrictcode": "230114",
            "subdistrictname": "แหลมกลัด",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2302",
            "districtname": "คลองใหญ่",
            "subdistrictcode": "230201",
            "subdistrictname": "คลองใหญ่",
            "zipcode": "23110"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2302",
            "districtname": "คลองใหญ่",
            "subdistrictcode": "230202",
            "subdistrictname": "ไม้รูด",
            "zipcode": "23110"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2302",
            "districtname": "คลองใหญ่",
            "subdistrictcode": "230203",
            "subdistrictname": "หาดเล็ก",
            "zipcode": "23110"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230301",
            "subdistrictname": "เขาสมิง",
            "zipcode": "23130"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230302",
            "subdistrictname": "แสนตุ้ง",
            "zipcode": "23150"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230303",
            "subdistrictname": "วังตะเคียน",
            "zipcode": "23130"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230304",
            "subdistrictname": "ท่าโสม",
            "zipcode": "23150"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230305",
            "subdistrictname": "สะตอ",
            "zipcode": "23150"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230306",
            "subdistrictname": "ประณีต",
            "zipcode": "23150"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230307",
            "subdistrictname": "เทพนิมิต",
            "zipcode": "23150"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2303",
            "districtname": "เขาสมิง",
            "subdistrictcode": "230308",
            "subdistrictname": "ทุ่งนนทรี",
            "zipcode": "23130"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2304",
            "districtname": "บ่อไร่",
            "subdistrictcode": "230401",
            "subdistrictname": "บ่อพลอย",
            "zipcode": "23140"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2304",
            "districtname": "บ่อไร่",
            "subdistrictcode": "230402",
            "subdistrictname": "ช้างทูน",
            "zipcode": "23140"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2304",
            "districtname": "บ่อไร่",
            "subdistrictcode": "230403",
            "subdistrictname": "ด่านชุมพล",
            "zipcode": "23140"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2304",
            "districtname": "บ่อไร่",
            "subdistrictcode": "230404",
            "subdistrictname": "หนองบอน",
            "zipcode": "23140"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2304",
            "districtname": "บ่อไร่",
            "subdistrictcode": "230405",
            "subdistrictname": "นนทรีย์",
            "zipcode": "23140"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2305",
            "districtname": "แหลมงอบ",
            "subdistrictcode": "230501",
            "subdistrictname": "แหลมงอบ",
            "zipcode": "23120"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2305",
            "districtname": "แหลมงอบ",
            "subdistrictcode": "230502",
            "subdistrictname": "น้ำเชี่ยว",
            "zipcode": "23120"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2305",
            "districtname": "แหลมงอบ",
            "subdistrictcode": "230503",
            "subdistrictname": "บางปิด",
            "zipcode": "23120"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2305",
            "districtname": "แหลมงอบ",
            "subdistrictcode": "230507",
            "subdistrictname": "คลองใหญ่",
            "zipcode": "23120"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2306",
            "districtname": "เกาะกูด",
            "subdistrictcode": "230601",
            "subdistrictname": "เกาะหมาก",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2306",
            "districtname": "เกาะกูด",
            "subdistrictcode": "230602",
            "subdistrictname": "เกาะกูด",
            "zipcode": "23000"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2307",
            "districtname": "เกาะช้าง",
            "subdistrictcode": "230701",
            "subdistrictname": "เกาะช้าง",
            "zipcode": "23170"
        },
        {
            "isActive": true,
            "provincecode": "ตราด",
            "districtcode": "2307",
            "districtname": "เกาะช้าง",
            "subdistrictcode": "230702",
            "subdistrictname": "เกาะช้างใต้",
            "zipcode": "23170"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240101",
            "subdistrictname": "หน้าเมือง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240102",
            "subdistrictname": "ท่าไข่",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240103",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240104",
            "subdistrictname": "คลองนา",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240105",
            "subdistrictname": "บางตีนเป็ด",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240106",
            "subdistrictname": "บางไผ่",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240107",
            "subdistrictname": "คลองจุกกระเฌอ",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240108",
            "subdistrictname": "บางแก้ว",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240109",
            "subdistrictname": "บางขวัญ",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240110",
            "subdistrictname": "คลองนครเนื่องเขต",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240111",
            "subdistrictname": "วังตะเคียน",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240112",
            "subdistrictname": "โสธร",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240113",
            "subdistrictname": "บางพระ",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240114",
            "subdistrictname": "บางกะไห",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240115",
            "subdistrictname": "หนามแดง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240116",
            "subdistrictname": "คลองเปรง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240117",
            "subdistrictname": "คลองอุดมชลจร",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240118",
            "subdistrictname": "คลองหลวงแพ่ง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2401",
            "districtname": "เมืองฉะเชิงเทรา",
            "subdistrictcode": "240119",
            "subdistrictname": "บางเตย",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240201",
            "subdistrictname": "บางคล้า",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240204",
            "subdistrictname": "บางสวน",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240208",
            "subdistrictname": "บางกระเจ็ด",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240209",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240210",
            "subdistrictname": "ท่าทองหลาง",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240211",
            "subdistrictname": "สาวชะโงก",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240212",
            "subdistrictname": "เสม็ดเหนือ",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240213",
            "subdistrictname": "เสม็ดใต้",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2402",
            "districtname": "บางคล้า",
            "subdistrictcode": "240214",
            "subdistrictname": "หัวไทร",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240301",
            "subdistrictname": "บางน้ำเปรี้ยว",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240302",
            "subdistrictname": "บางขนาก",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240303",
            "subdistrictname": "สิงโตทอง",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240304",
            "subdistrictname": "หมอนทอง",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240305",
            "subdistrictname": "บึงน้ำรักษ์",
            "zipcode": "24170"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240306",
            "subdistrictname": "ดอนเกาะกา",
            "zipcode": "24170"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240307",
            "subdistrictname": "โยธะกา",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240308",
            "subdistrictname": "ดอนฉิมพลี",
            "zipcode": "24170"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240309",
            "subdistrictname": "ศาลาแดง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2403",
            "districtname": "บางน้ำเปรี้ยว",
            "subdistrictcode": "240310",
            "subdistrictname": "โพรงอากาศ",
            "zipcode": "24150"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240401",
            "subdistrictname": "บางปะกง",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240402",
            "subdistrictname": "ท่าสะอ้าน",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240403",
            "subdistrictname": "บางวัว",
            "zipcode": "24180"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240404",
            "subdistrictname": "บางสมัคร",
            "zipcode": "24180"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240405",
            "subdistrictname": "บางผึ้ง",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240406",
            "subdistrictname": "บางเกลือ",
            "zipcode": "24180"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240407",
            "subdistrictname": "สองคลอง",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240408",
            "subdistrictname": "หนองจอก",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240409",
            "subdistrictname": "พิมพา",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240410",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240411",
            "subdistrictname": "หอมศีล",
            "zipcode": "24180"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2404",
            "districtname": "บางปะกง",
            "subdistrictcode": "240412",
            "subdistrictname": "เขาดิน",
            "zipcode": "24130"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240501",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240502",
            "subdistrictname": "เกาะไร่",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240503",
            "subdistrictname": "คลองขุด",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240504",
            "subdistrictname": "คลองบ้านโพธิ์",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240505",
            "subdistrictname": "คลองประเวศ",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240506",
            "subdistrictname": "ดอนทราย",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240507",
            "subdistrictname": "เทพราช",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240508",
            "subdistrictname": "ท่าพลับ",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240509",
            "subdistrictname": "หนองตีนนก",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240510",
            "subdistrictname": "หนองบัว",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240511",
            "subdistrictname": "บางซ่อน",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240512",
            "subdistrictname": "บางกรูด",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240513",
            "subdistrictname": "แหลมประดู่",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240514",
            "subdistrictname": "ลาดขวาง",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240515",
            "subdistrictname": "สนามจันทร์",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240516",
            "subdistrictname": "แสนภูดาษ",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2405",
            "districtname": "บ้านโพธิ์",
            "subdistrictcode": "240517",
            "subdistrictname": "สิบเอ็ดศอก",
            "zipcode": "24140"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240601",
            "subdistrictname": "เกาะขนุน",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240602",
            "subdistrictname": "บ้านซ่อง",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240603",
            "subdistrictname": "พนมสารคาม",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240604",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240605",
            "subdistrictname": "หนองยาว",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240606",
            "subdistrictname": "ท่าถ่าน",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240607",
            "subdistrictname": "หนองแหน",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2406",
            "districtname": "พนมสารคาม",
            "subdistrictcode": "240608",
            "subdistrictname": "เขาหินซ้อน",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2407",
            "districtname": "ราชสาส์น",
            "subdistrictcode": "240701",
            "subdistrictname": "บางคา",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2407",
            "districtname": "ราชสาส์น",
            "subdistrictcode": "240702",
            "subdistrictname": "เมืองใหม่",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2407",
            "districtname": "ราชสาส์น",
            "subdistrictcode": "240703",
            "subdistrictname": "ดงน้อย",
            "zipcode": "24120"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2408",
            "districtname": "สนามชัยเขต",
            "subdistrictcode": "240801",
            "subdistrictname": "คู้ยายหมี",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2408",
            "districtname": "สนามชัยเขต",
            "subdistrictcode": "240802",
            "subdistrictname": "ท่ากระดาน",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2408",
            "districtname": "สนามชัยเขต",
            "subdistrictcode": "240803",
            "subdistrictname": "ทุ่งพระยา",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2408",
            "districtname": "สนามชัยเขต",
            "subdistrictcode": "240805",
            "subdistrictname": "ลาดกระทิง",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2409",
            "districtname": "แปลงยาว",
            "subdistrictcode": "240901",
            "subdistrictname": "แปลงยาว",
            "zipcode": "24190"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2409",
            "districtname": "แปลงยาว",
            "subdistrictcode": "240902",
            "subdistrictname": "วังเย็น",
            "zipcode": "24190"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2409",
            "districtname": "แปลงยาว",
            "subdistrictcode": "240903",
            "subdistrictname": "หัวสำโรง",
            "zipcode": "24190"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2409",
            "districtname": "แปลงยาว",
            "subdistrictcode": "240904",
            "subdistrictname": "หนองไม้แก่น",
            "zipcode": "24190"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2410",
            "districtname": "ท่าตะเกียบ",
            "subdistrictcode": "241001",
            "subdistrictname": "ท่าตะเกียบ",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2410",
            "districtname": "ท่าตะเกียบ",
            "subdistrictcode": "241002",
            "subdistrictname": "คลองตะเกรา",
            "zipcode": "24160"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน",
            "subdistrictcode": "241101",
            "subdistrictname": "ก้อนแก้ว",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน",
            "subdistrictcode": "241102",
            "subdistrictname": "คลองเขื่อน",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน",
            "subdistrictcode": "241103",
            "subdistrictname": "บางเล่า",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน",
            "subdistrictcode": "241104",
            "subdistrictname": "บางโรง",
            "zipcode": "24000"
        },
        {
            "isActive": true,
            "provincecode": "ฉะเชิงเทรา",
            "districtcode": "2411",
            "districtname": "คลองเขื่อน",
            "subdistrictcode": "241105",
            "subdistrictname": "บางตลาด",
            "zipcode": "24110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250101",
            "subdistrictname": "หน้าเมือง",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250102",
            "subdistrictname": "รอบเมือง",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250103",
            "subdistrictname": "วัดโบสถ์",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250104",
            "subdistrictname": "บางเดชะ",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250105",
            "subdistrictname": "ท่างาม",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250106",
            "subdistrictname": "บางบริบูรณ์",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250107",
            "subdistrictname": "ดงพระราม",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250108",
            "subdistrictname": "บ้านพระ",
            "zipcode": "25230"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250109",
            "subdistrictname": "โคกไม้ลาย",
            "zipcode": "25230"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250110",
            "subdistrictname": "ไม้เค็ด",
            "zipcode": "25230"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250111",
            "subdistrictname": "ดงขี้เหล็ก",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250112",
            "subdistrictname": "เนินหอม",
            "zipcode": "25230"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2501",
            "districtname": "เมืองปราจีนบุรี",
            "subdistrictcode": "250113",
            "subdistrictname": "โนนห้อม",
            "zipcode": "25000"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250201",
            "subdistrictname": "กบินทร์",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250202",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "25240"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250203",
            "subdistrictname": "วังดาล",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250204",
            "subdistrictname": "นนทรี",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250205",
            "subdistrictname": "ย่านรี",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250206",
            "subdistrictname": "วังตะเคียน",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250207",
            "subdistrictname": "หาดนางแก้ว",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250208",
            "subdistrictname": "ลาดตะเคียน",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250209",
            "subdistrictname": "บ้านนา",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250210",
            "subdistrictname": "บ่อทอง",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250211",
            "subdistrictname": "หนองกี่",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250212",
            "subdistrictname": "นาแขม",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250213",
            "subdistrictname": "เขาไม้แก้ว",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2502",
            "districtname": "กบินทร์บุรี",
            "subdistrictcode": "250214",
            "subdistrictname": "วังท่าช้าง",
            "zipcode": "25110"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250301",
            "subdistrictname": "นาดี",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250302",
            "subdistrictname": "สำพันตา",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250303",
            "subdistrictname": "สะพานหิน",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250304",
            "subdistrictname": "ทุ่งโพธิ์",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250305",
            "subdistrictname": "แก่งดินสอ",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2503",
            "districtname": "นาดี",
            "subdistrictcode": "250306",
            "subdistrictname": "บุพราหมณ์",
            "zipcode": "25220"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250601",
            "subdistrictname": "บ้านสร้าง",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250602",
            "subdistrictname": "บางกระเบา",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250603",
            "subdistrictname": "บางเตย",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250604",
            "subdistrictname": "บางยาง",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250605",
            "subdistrictname": "บางแตน",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250606",
            "subdistrictname": "บางพลวง",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250607",
            "subdistrictname": "บางปลาร้า",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250608",
            "subdistrictname": "บางขาม",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2506",
            "districtname": "บ้านสร้าง",
            "subdistrictcode": "250609",
            "subdistrictname": "กระทุ่มแพ้ว",
            "zipcode": "25150"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250701",
            "subdistrictname": "ประจันตคาม",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250702",
            "subdistrictname": "เกาะลอย",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250703",
            "subdistrictname": "บ้านหอย",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250704",
            "subdistrictname": "หนองแสง",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250705",
            "subdistrictname": "ดงบัง",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250706",
            "subdistrictname": "คำโตนด",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250707",
            "subdistrictname": "บุฝ้าย",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250708",
            "subdistrictname": "หนองแก้ว",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2507",
            "districtname": "ประจันตคาม",
            "subdistrictcode": "250709",
            "subdistrictname": "โพธิ์งาม",
            "zipcode": "25130"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250801",
            "subdistrictname": "ศรีมหาโพธิ",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250802",
            "subdistrictname": "สัมพันธ์",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250803",
            "subdistrictname": "บ้านทาม",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250804",
            "subdistrictname": "ท่าตูม",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250805",
            "subdistrictname": "บางกุ้ง",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250806",
            "subdistrictname": "ดงกระทงยาม",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250807",
            "subdistrictname": "หนองโพรง",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250808",
            "subdistrictname": "หัวหว้า",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250809",
            "subdistrictname": "หาดยาง",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2508",
            "districtname": "ศรีมหาโพธิ",
            "subdistrictcode": "250810",
            "subdistrictname": "กรอกสมบูรณ์",
            "zipcode": "25140"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2509",
            "districtname": "ศรีมโหสถ",
            "subdistrictcode": "250901",
            "subdistrictname": "โคกปีบ",
            "zipcode": "25190"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2509",
            "districtname": "ศรีมโหสถ",
            "subdistrictcode": "250902",
            "subdistrictname": "โคกไทย",
            "zipcode": "25190"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2509",
            "districtname": "ศรีมโหสถ",
            "subdistrictcode": "250903",
            "subdistrictname": "คู้ลำพัน",
            "zipcode": "25190"
        },
        {
            "isActive": true,
            "provincecode": "ปราจีนบุรี",
            "districtcode": "2509",
            "districtname": "ศรีมโหสถ",
            "subdistrictcode": "250904",
            "subdistrictname": "ไผ่ชะเลือด",
            "zipcode": "25190"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260101",
            "subdistrictname": "นครนายก",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260102",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260103",
            "subdistrictname": "บ้านใหญ่",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260104",
            "subdistrictname": "วังกระโจม",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260105",
            "subdistrictname": "ท่าทราย",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260106",
            "subdistrictname": "ดอนยอ",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260107",
            "subdistrictname": "ศรีจุฬา",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260108",
            "subdistrictname": "ดงละคร",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260109",
            "subdistrictname": "ศรีนาวา",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260110",
            "subdistrictname": "สาริกา",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260111",
            "subdistrictname": "หินตั้ง",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260112",
            "subdistrictname": "เขาพระ",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2601",
            "districtname": "เมืองนครนายก",
            "subdistrictcode": "260113",
            "subdistrictname": "พรหมณี",
            "zipcode": "26000"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260201",
            "subdistrictname": "เกาะหวาย",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260202",
            "subdistrictname": "เกาะโพธิ์",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260203",
            "subdistrictname": "ปากพลี",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260204",
            "subdistrictname": "โคกกรวด",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260205",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260206",
            "subdistrictname": "หนองแสง",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2602",
            "districtname": "ปากพลี",
            "subdistrictcode": "260207",
            "subdistrictname": "นาหินลาด",
            "zipcode": "26130"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260301",
            "subdistrictname": "บ้านนา",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260302",
            "subdistrictname": "บ้านพร้าว",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260303",
            "subdistrictname": "บ้านพริก",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260304",
            "subdistrictname": "อาษา",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260305",
            "subdistrictname": "ทองหลาง",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260306",
            "subdistrictname": "บางอ้อ",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260307",
            "subdistrictname": "พิกุลออก",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260308",
            "subdistrictname": "ป่าขะ",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260309",
            "subdistrictname": "เขาเพิ่ม",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2603",
            "districtname": "บ้านนา",
            "subdistrictcode": "260310",
            "subdistrictname": "ศรีกะอาง",
            "zipcode": "26110"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260401",
            "subdistrictname": "พระอาจารย์",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260402",
            "subdistrictname": "บึงศาล",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260403",
            "subdistrictname": "ศีรษะกระบือ",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260404",
            "subdistrictname": "โพธิ์แทน",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260405",
            "subdistrictname": "บางสมบูรณ์",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260406",
            "subdistrictname": "ทรายมูล",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260407",
            "subdistrictname": "บางปลากด",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260408",
            "subdistrictname": "บางลูกเสือ",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260409",
            "subdistrictname": "องครักษ์",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260410",
            "subdistrictname": "ชุมพล",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "นครนายก",
            "districtcode": "2604",
            "districtname": "องครักษ์",
            "subdistrictcode": "260411",
            "subdistrictname": "คลองใหญ่",
            "zipcode": "26120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270101",
            "subdistrictname": "สระแก้ว",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270102",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270103",
            "subdistrictname": "ศาลาลำดวน",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270104",
            "subdistrictname": "โคกปี่ฆ้อง",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270105",
            "subdistrictname": "ท่าแยก",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270106",
            "subdistrictname": "ท่าเกษม",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270108",
            "subdistrictname": "สระขวัญ",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2701",
            "districtname": "เมืองสระแก้ว",
            "subdistrictcode": "270111",
            "subdistrictname": "หนองบอน",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270201",
            "subdistrictname": "คลองหาด",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270202",
            "subdistrictname": "ไทยอุดม",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270203",
            "subdistrictname": "ซับมะกรูด",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270204",
            "subdistrictname": "ไทรเดี่ยว",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270205",
            "subdistrictname": "คลองไก่เถื่อน",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270206",
            "subdistrictname": "เบญจขร",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2702",
            "districtname": "คลองหาด",
            "subdistrictcode": "270207",
            "subdistrictname": "ไทรทอง",
            "zipcode": "27260"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2703",
            "districtname": "ตาพระยา",
            "subdistrictcode": "270301",
            "subdistrictname": "ตาพระยา",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2703",
            "districtname": "ตาพระยา",
            "subdistrictcode": "270302",
            "subdistrictname": "ทัพเสด็จ",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2703",
            "districtname": "ตาพระยา",
            "subdistrictcode": "270306",
            "subdistrictname": "ทัพราช",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2703",
            "districtname": "ตาพระยา",
            "subdistrictcode": "270307",
            "subdistrictname": "ทัพไทย",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2703",
            "districtname": "ตาพระยา",
            "subdistrictcode": "270309",
            "subdistrictname": "โคคลาน",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2704",
            "districtname": "วังน้ำเย็น",
            "subdistrictcode": "270401",
            "subdistrictname": "วังน้ำเย็น",
            "zipcode": "27210"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2704",
            "districtname": "วังน้ำเย็น",
            "subdistrictcode": "270403",
            "subdistrictname": "ตาหลังใน",
            "zipcode": "27210"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2704",
            "districtname": "วังน้ำเย็น",
            "subdistrictcode": "270405",
            "subdistrictname": "คลองหินปูน",
            "zipcode": "27210"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2704",
            "districtname": "วังน้ำเย็น",
            "subdistrictcode": "270406",
            "subdistrictname": "ทุ่งมหาเจริญ",
            "zipcode": "27210"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270501",
            "subdistrictname": "วัฒนานคร",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270502",
            "subdistrictname": "ท่าเกวียน",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270503",
            "subdistrictname": "ผักขะ",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270504",
            "subdistrictname": "โนนหมากเค็ง",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270505",
            "subdistrictname": "หนองน้ำใส",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270506",
            "subdistrictname": "ช่องกุ่ม",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270507",
            "subdistrictname": "หนองแวง",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270508",
            "subdistrictname": "แซร์ออ",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270509",
            "subdistrictname": "หนองหมากฝ้าย",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270510",
            "subdistrictname": "หนองตะเคียนบอน",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2705",
            "districtname": "วัฒนานคร",
            "subdistrictcode": "270511",
            "subdistrictname": "ห้วยโจด",
            "zipcode": "27160"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270601",
            "subdistrictname": "อรัญประเทศ",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270602",
            "subdistrictname": "เมืองไผ่",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270603",
            "subdistrictname": "หันทราย",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270604",
            "subdistrictname": "คลองน้ำใส",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270605",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270606",
            "subdistrictname": "ป่าไร่",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270607",
            "subdistrictname": "ทับพริก",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270608",
            "subdistrictname": "บ้านใหม่หนองไทร",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270609",
            "subdistrictname": "ผ่านศึก",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270610",
            "subdistrictname": "หนองสังข์",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270611",
            "subdistrictname": "คลองทับจันทร์",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270612",
            "subdistrictname": "ฟากห้วย",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2706",
            "districtname": "อรัญประเทศ",
            "subdistrictcode": "270613",
            "subdistrictname": "บ้านด่าน",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2707",
            "districtname": "เขาฉกรรจ์",
            "subdistrictcode": "270701",
            "subdistrictname": "เขาฉกรรจ์",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2707",
            "districtname": "เขาฉกรรจ์",
            "subdistrictcode": "270702",
            "subdistrictname": "หนองหว้า",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2707",
            "districtname": "เขาฉกรรจ์",
            "subdistrictcode": "270703",
            "subdistrictname": "พระเพลิง",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2707",
            "districtname": "เขาฉกรรจ์",
            "subdistrictcode": "270704",
            "subdistrictname": "เขาสามสิบ",
            "zipcode": "27000"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2708",
            "districtname": "โคกสูง",
            "subdistrictcode": "270801",
            "subdistrictname": "โคกสูง",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2708",
            "districtname": "โคกสูง",
            "subdistrictcode": "270802",
            "subdistrictname": "หนองม่วง",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2708",
            "districtname": "โคกสูง",
            "subdistrictcode": "270803",
            "subdistrictname": "หนองแวง",
            "zipcode": "27180"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2708",
            "districtname": "โคกสูง",
            "subdistrictcode": "270804",
            "subdistrictname": "โนนหมากมุ่น",
            "zipcode": "27120"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2709",
            "districtname": "วังสมบูรณ์",
            "subdistrictcode": "270901",
            "subdistrictname": "วังสมบูรณ์",
            "zipcode": "27250"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2709",
            "districtname": "วังสมบูรณ์",
            "subdistrictcode": "270902",
            "subdistrictname": "วังใหม่",
            "zipcode": "27250"
        },
        {
            "isActive": true,
            "provincecode": "สระแก้ว",
            "districtcode": "2709",
            "districtname": "วังสมบูรณ์",
            "subdistrictcode": "270903",
            "subdistrictname": "วังทอง",
            "zipcode": "27250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300101",
            "subdistrictname": "ในเมือง",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300102",
            "subdistrictname": "โพธิ์กลาง",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300103",
            "subdistrictname": "หนองจะบก",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300104",
            "subdistrictname": "โคกสูง",
            "zipcode": "30310"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300105",
            "subdistrictname": "มะเริง",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300106",
            "subdistrictname": "หนองระเวียง",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300107",
            "subdistrictname": "ปรุใหญ่",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300108",
            "subdistrictname": "หมื่นไวย",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300109",
            "subdistrictname": "พลกรัง",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300110",
            "subdistrictname": "หนองไผ่ล้อม",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300111",
            "subdistrictname": "หัวทะเล",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300112",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300113",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300114",
            "subdistrictname": "พุดซา",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300115",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "30310"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300116",
            "subdistrictname": "จอหอ",
            "zipcode": "30310"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300117",
            "subdistrictname": "โคกกรวด",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300118",
            "subdistrictname": "ไชยมงคล",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300119",
            "subdistrictname": "หนองบัวศาลา",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300120",
            "subdistrictname": "สุรนารี",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300121",
            "subdistrictname": "สีมุม",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300122",
            "subdistrictname": "ตลาด",
            "zipcode": "30310"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300123",
            "subdistrictname": "พะเนา",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300124",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3001",
            "districtname": "เมืองนครราชสีมา",
            "subdistrictcode": "300125",
            "subdistrictname": "หนองไข่น้ำ",
            "zipcode": "30310"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300201",
            "subdistrictname": "แชะ",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300202",
            "subdistrictname": "เฉลียง",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300203",
            "subdistrictname": "ครบุรี",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300204",
            "subdistrictname": "โคกกระชาย",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300205",
            "subdistrictname": "จระเข้หิน",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300206",
            "subdistrictname": "มาบตะโกเอน",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300207",
            "subdistrictname": "อรพิมพ์",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300208",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300209",
            "subdistrictname": "ลำเพียก",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300210",
            "subdistrictname": "ครบุรีใต้",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300211",
            "subdistrictname": "ตะแบกบาน",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3002",
            "districtname": "ครบุรี",
            "subdistrictcode": "300212",
            "subdistrictname": "สระว่านพระยา",
            "zipcode": "30250"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300301",
            "subdistrictname": "เสิงสาง",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300302",
            "subdistrictname": "สระตะเคียน",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300303",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300304",
            "subdistrictname": "กุดโบสถ์",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300305",
            "subdistrictname": "สุขไพบูลย์",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3003",
            "districtname": "เสิงสาง",
            "subdistrictcode": "300306",
            "subdistrictname": "บ้านราษฎร์",
            "zipcode": "30330"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300401",
            "subdistrictname": "เมืองคง",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300402",
            "subdistrictname": "คูขาด",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300403",
            "subdistrictname": "เทพาลัย",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300404",
            "subdistrictname": "ตาจั่น",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300405",
            "subdistrictname": "บ้านปรางค์",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300406",
            "subdistrictname": "หนองมะนาว",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300407",
            "subdistrictname": "หนองบัว",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300408",
            "subdistrictname": "โนนเต็ง",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300409",
            "subdistrictname": "ดอนใหญ่",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3004",
            "districtname": "คง",
            "subdistrictcode": "300410",
            "subdistrictname": "ขามสมบูรณ์",
            "zipcode": "30260"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3005",
            "districtname": "บ้านเหลื่อม",
            "subdistrictcode": "300501",
            "subdistrictname": "บ้านเหลื่อม",
            "zipcode": "30350"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3005",
            "districtname": "บ้านเหลื่อม",
            "subdistrictcode": "300502",
            "subdistrictname": "วังโพธิ์",
            "zipcode": "30350"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3005",
            "districtname": "บ้านเหลื่อม",
            "subdistrictcode": "300503",
            "subdistrictname": "โคกกระเบื้อง",
            "zipcode": "30350"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3005",
            "districtname": "บ้านเหลื่อม",
            "subdistrictcode": "300504",
            "subdistrictname": "ช่อระกา",
            "zipcode": "30350"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300601",
            "subdistrictname": "จักราช",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300602",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300603",
            "subdistrictname": "ทองหลาง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300604",
            "subdistrictname": "สีสุก",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300605",
            "subdistrictname": "หนองขาม",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300606",
            "subdistrictname": "หนองงูเหลือม",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300607",
            "subdistrictname": "หนองพลวง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300608",
            "subdistrictname": "หนองยาง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300609",
            "subdistrictname": "พระพุทธ",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300610",
            "subdistrictname": "ศรีละกอ",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300611",
            "subdistrictname": "คลองเมือง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300612",
            "subdistrictname": "ช้างทอง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3006",
            "districtname": "จักราช",
            "subdistrictcode": "300613",
            "subdistrictname": "หินโคน",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300701",
            "subdistrictname": "กระโทก",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300702",
            "subdistrictname": "พลับพลา",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300703",
            "subdistrictname": "ท่าอ่าง",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300704",
            "subdistrictname": "ทุ่งอรุณ",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300705",
            "subdistrictname": "ท่าลาดขาว",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300706",
            "subdistrictname": "ท่าจะหลุง",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300707",
            "subdistrictname": "ท่าเยี่ยม",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300708",
            "subdistrictname": "โชคชัย",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300709",
            "subdistrictname": "ละลมใหม่พัฒนา",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3007",
            "districtname": "โชคชัย",
            "subdistrictcode": "300710",
            "subdistrictname": "ด่านเกวียน",
            "zipcode": "30190"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300801",
            "subdistrictname": "กุดพิมาน",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300802",
            "subdistrictname": "ด่านขุนทด",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300803",
            "subdistrictname": "ด่านนอก",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300804",
            "subdistrictname": "ด่านใน",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300805",
            "subdistrictname": "ตะเคียน",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300806",
            "subdistrictname": "บ้านเก่า",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300807",
            "subdistrictname": "บ้านแปรง",
            "zipcode": "36220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300808",
            "subdistrictname": "พันชนะ",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300809",
            "subdistrictname": "สระจรเข้",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300810",
            "subdistrictname": "หนองกราด",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300811",
            "subdistrictname": "หนองบัวตะเกียด",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300812",
            "subdistrictname": "หนองบัวละคร",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300813",
            "subdistrictname": "หินดาด",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300815",
            "subdistrictname": "ห้วยบง",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300817",
            "subdistrictname": "โนนเมืองพัฒนา",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3008",
            "districtname": "ด่านขุนทด",
            "subdistrictcode": "300818",
            "subdistrictname": "หนองไทร",
            "zipcode": "36220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300901",
            "subdistrictname": "โนนไทย",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300902",
            "subdistrictname": "ด่านจาก",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300903",
            "subdistrictname": "กำปัง",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300904",
            "subdistrictname": "สำโรง",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300905",
            "subdistrictname": "ค้างพลู",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300906",
            "subdistrictname": "บ้านวัง",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300907",
            "subdistrictname": "บัลลังก์",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300908",
            "subdistrictname": "สายออ",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300909",
            "subdistrictname": "ถนนโพธิ์",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300910",
            "subdistrictname": "พังเทียม",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300911",
            "subdistrictname": "สระพระ",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300912",
            "subdistrictname": "ทัพรั้ง",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300913",
            "subdistrictname": "หนองหอย",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300914",
            "subdistrictname": "มะค่า",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3009",
            "districtname": "โนนไทย",
            "subdistrictcode": "300915",
            "subdistrictname": "มาบกราด",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301001",
            "subdistrictname": "โนนสูง",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301002",
            "subdistrictname": "ใหม่",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301003",
            "subdistrictname": "โตนด",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301004",
            "subdistrictname": "บิง",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301005",
            "subdistrictname": "ดอนชมพู",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301006",
            "subdistrictname": "ธารปราสาท",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301007",
            "subdistrictname": "หลุมข้าว",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301008",
            "subdistrictname": "มะค่า",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301009",
            "subdistrictname": "พลสงคราม",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301010",
            "subdistrictname": "จันอัด",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301011",
            "subdistrictname": "ขามเฒ่า",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301012",
            "subdistrictname": "ด่านคล้า",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301013",
            "subdistrictname": "ลำคอหงษ์",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301014",
            "subdistrictname": "เมืองปราสาท",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301015",
            "subdistrictname": "ดอนหวาย",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3010",
            "districtname": "โนนสูง",
            "subdistrictcode": "301016",
            "subdistrictname": "ลำมูล",
            "zipcode": "30160"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301101",
            "subdistrictname": "ขามสะแกแสง",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301102",
            "subdistrictname": "โนนเมือง",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301103",
            "subdistrictname": "เมืองนาท",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301104",
            "subdistrictname": "ชีวึก",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301105",
            "subdistrictname": "พะงาด",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301106",
            "subdistrictname": "หนองหัวฟาน",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3011",
            "districtname": "ขามสะแกแสง",
            "subdistrictcode": "301107",
            "subdistrictname": "เมืองเกษตร",
            "zipcode": "30290"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301201",
            "subdistrictname": "บัวใหญ่",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301203",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301204",
            "subdistrictname": "เสมาใหญ่",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301206",
            "subdistrictname": "ดอนตะหนิน",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301207",
            "subdistrictname": "หนองบัวสะอาด",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301208",
            "subdistrictname": "โนนทองหลาง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301209",
            "subdistrictname": "หนองหว้า",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301210",
            "subdistrictname": "บัวลาย",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301211",
            "subdistrictname": "สีดา",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301212",
            "subdistrictname": "โพนทอง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301214",
            "subdistrictname": "กุดจอก",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301215",
            "subdistrictname": "ด่านช้าง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301216",
            "subdistrictname": "โนนจาน",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301218",
            "subdistrictname": "สามเมือง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301220",
            "subdistrictname": "ขุนทอง",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301221",
            "subdistrictname": "หนองตาดใหญ่",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301222",
            "subdistrictname": "เมืองพะไล",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301223",
            "subdistrictname": "โนนประดู่",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3012",
            "districtname": "บัวใหญ่",
            "subdistrictcode": "301224",
            "subdistrictname": "หนองแจ้งใหญ่",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301301",
            "subdistrictname": "ประทาย",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301303",
            "subdistrictname": "กระทุ่มราย",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301304",
            "subdistrictname": "วังไม้แดง",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301306",
            "subdistrictname": "ตลาดไทร",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301307",
            "subdistrictname": "หนองพลวง",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301308",
            "subdistrictname": "หนองค่าย",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301309",
            "subdistrictname": "หันห้วยทราย",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301310",
            "subdistrictname": "ดอนมัน",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301313",
            "subdistrictname": "นางรำ",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301314",
            "subdistrictname": "โนนเพ็ด",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301315",
            "subdistrictname": "ทุ่งสว่าง",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301317",
            "subdistrictname": "โคกกลาง",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3013",
            "districtname": "ประทาย",
            "subdistrictcode": "301318",
            "subdistrictname": "เมืองโดน",
            "zipcode": "30180"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301401",
            "subdistrictname": "เมืองปัก",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301402",
            "subdistrictname": "ตะคุ",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301403",
            "subdistrictname": "โคกไทย",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301404",
            "subdistrictname": "สำโรง",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301405",
            "subdistrictname": "ตะขบ",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301406",
            "subdistrictname": "นกออก",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301407",
            "subdistrictname": "ดอน",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301409",
            "subdistrictname": "ตูม",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301410",
            "subdistrictname": "งิ้ว",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301411",
            "subdistrictname": "สะแกราช",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301412",
            "subdistrictname": "ลำนางแก้ว",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301416",
            "subdistrictname": "ภูหลวง",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301417",
            "subdistrictname": "ธงชัยเหนือ",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301418",
            "subdistrictname": "สุขเกษม",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301419",
            "subdistrictname": "เกษมทรัพย์",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3014",
            "districtname": "ปักธงชัย",
            "subdistrictcode": "301420",
            "subdistrictname": "บ่อปลาทอง",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301501",
            "subdistrictname": "ในเมือง",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301502",
            "subdistrictname": "สัมฤทธิ์",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301503",
            "subdistrictname": "โบสถ์",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301504",
            "subdistrictname": "กระเบื้องใหญ่",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301505",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301506",
            "subdistrictname": "รังกาใหญ่",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301507",
            "subdistrictname": "ชีวาน",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301508",
            "subdistrictname": "นิคมสร้างตนเอง",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301509",
            "subdistrictname": "กระชอน",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301510",
            "subdistrictname": "ดงใหญ่",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301511",
            "subdistrictname": "ธารละหลอด",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3015",
            "districtname": "พิมาย",
            "subdistrictcode": "301512",
            "subdistrictname": "หนองระเวียง",
            "zipcode": "30110"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301601",
            "subdistrictname": "ห้วยแถลง",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301602",
            "subdistrictname": "ทับสวาย",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301603",
            "subdistrictname": "เมืองพลับพลา",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301604",
            "subdistrictname": "หลุ่งตะเคียน",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301605",
            "subdistrictname": "หินดาด",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301606",
            "subdistrictname": "งิ้ว",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301607",
            "subdistrictname": "กงรถ",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301608",
            "subdistrictname": "หลุ่งประดู่",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301609",
            "subdistrictname": "ตะโก",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3016",
            "districtname": "ห้วยแถลง",
            "subdistrictcode": "301610",
            "subdistrictname": "ห้วยแคน",
            "zipcode": "30240"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301701",
            "subdistrictname": "ชุมพวง",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301702",
            "subdistrictname": "ประสุข",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301703",
            "subdistrictname": "ท่าลาด",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301704",
            "subdistrictname": "สาหร่าย",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301705",
            "subdistrictname": "ตลาดไทร",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301706",
            "subdistrictname": "ช่องแมว",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301707",
            "subdistrictname": "ขุย",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301710",
            "subdistrictname": "โนนรัง",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301711",
            "subdistrictname": "บ้านยาง",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301714",
            "subdistrictname": "หนองหลัก",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301715",
            "subdistrictname": "ไพล",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301716",
            "subdistrictname": "โนนตูม",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3017",
            "districtname": "ชุมพวง",
            "subdistrictcode": "301717",
            "subdistrictname": "โนนยอ",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301801",
            "subdistrictname": "สูงเนิน",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301802",
            "subdistrictname": "เสมา",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301803",
            "subdistrictname": "โคราช",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301804",
            "subdistrictname": "บุ่งขี้เหล็ก",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301805",
            "subdistrictname": "โนนค่า",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301806",
            "subdistrictname": "โค้งยาง",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301807",
            "subdistrictname": "มะเกลือเก่า",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301808",
            "subdistrictname": "มะเกลือใหม่",
            "zipcode": "30170"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301809",
            "subdistrictname": "นากลาง",
            "zipcode": "30380"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301810",
            "subdistrictname": "หนองตะไก้",
            "zipcode": "30380"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3018",
            "districtname": "สูงเนิน",
            "subdistrictcode": "301811",
            "subdistrictname": "กุดจิก",
            "zipcode": "30380"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ",
            "subdistrictcode": "301901",
            "subdistrictname": "ขามทะเลสอ",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ",
            "subdistrictcode": "301902",
            "subdistrictname": "โป่งแดง",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ",
            "subdistrictcode": "301903",
            "subdistrictname": "พันดุง",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ",
            "subdistrictcode": "301904",
            "subdistrictname": "หนองสรวง",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3019",
            "districtname": "ขามทะเลสอ",
            "subdistrictcode": "301905",
            "subdistrictname": "บึงอ้อ",
            "zipcode": "30280"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302001",
            "subdistrictname": "สีคิ้ว",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302002",
            "subdistrictname": "บ้านหัน",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302003",
            "subdistrictname": "กฤษณา",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302004",
            "subdistrictname": "ลาดบัวขาว",
            "zipcode": "30340"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302005",
            "subdistrictname": "หนองหญ้าขาว",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302006",
            "subdistrictname": "กุดน้อย",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302007",
            "subdistrictname": "หนองน้ำใส",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302008",
            "subdistrictname": "วังโรงใหญ่",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302009",
            "subdistrictname": "มิตรภาพ",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302010",
            "subdistrictname": "คลองไผ่",
            "zipcode": "30340"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302011",
            "subdistrictname": "ดอนเมือง",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3020",
            "districtname": "สีคิ้ว",
            "subdistrictcode": "302012",
            "subdistrictname": "หนองบัวน้อย",
            "zipcode": "30140"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302101",
            "subdistrictname": "ปากช่อง",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302102",
            "subdistrictname": "กลางดง",
            "zipcode": "30320"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302103",
            "subdistrictname": "จันทึก",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302104",
            "subdistrictname": "วังกะทะ",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302105",
            "subdistrictname": "หมูสี",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302106",
            "subdistrictname": "หนองสาหร่าย",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302107",
            "subdistrictname": "ขนงพระ",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302108",
            "subdistrictname": "โป่งตาลอง",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302109",
            "subdistrictname": "คลองม่วง",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302110",
            "subdistrictname": "หนองน้ำแดง",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302111",
            "subdistrictname": "วังไทร",
            "zipcode": "30130"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3021",
            "districtname": "ปากช่อง",
            "subdistrictcode": "302112",
            "subdistrictname": "พญาเย็น",
            "zipcode": "30320"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302201",
            "subdistrictname": "หนองบุนนาก",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302202",
            "subdistrictname": "สารภี",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302203",
            "subdistrictname": "ไทยเจริญ",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302204",
            "subdistrictname": "หนองหัวแรต",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302205",
            "subdistrictname": "แหลมทอง",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302206",
            "subdistrictname": "หนองตะไก้",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302207",
            "subdistrictname": "ลุงเขว้า",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302208",
            "subdistrictname": "หนองไม้ไผ่",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3022",
            "districtname": "หนองบุญมาก",
            "subdistrictcode": "302209",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "30410"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง",
            "subdistrictcode": "302301",
            "subdistrictname": "แก้งสนามนาง",
            "zipcode": "30440"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง",
            "subdistrictcode": "302302",
            "subdistrictname": "โนนสำราญ",
            "zipcode": "30440"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง",
            "subdistrictcode": "302303",
            "subdistrictname": "บึงพะไล",
            "zipcode": "30440"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง",
            "subdistrictcode": "302304",
            "subdistrictname": "สีสุก",
            "zipcode": "30440"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3023",
            "districtname": "แก้งสนามนาง",
            "subdistrictcode": "302305",
            "subdistrictname": "บึงสำโรง",
            "zipcode": "30440"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3024",
            "districtname": "โนนแดง",
            "subdistrictcode": "302401",
            "subdistrictname": "โนนแดง",
            "zipcode": "30360"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3024",
            "districtname": "โนนแดง",
            "subdistrictcode": "302402",
            "subdistrictname": "โนนตาเถร",
            "zipcode": "30360"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3024",
            "districtname": "โนนแดง",
            "subdistrictcode": "302403",
            "subdistrictname": "สำพะเนียง",
            "zipcode": "30360"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3024",
            "districtname": "โนนแดง",
            "subdistrictcode": "302404",
            "subdistrictname": "วังหิน",
            "zipcode": "30360"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3024",
            "districtname": "โนนแดง",
            "subdistrictcode": "302405",
            "subdistrictname": "ดอนยาวใหญ่",
            "zipcode": "30360"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว",
            "subdistrictcode": "302501",
            "subdistrictname": "วังน้ำเขียว",
            "zipcode": "30370"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว",
            "subdistrictcode": "302502",
            "subdistrictname": "วังหมี",
            "zipcode": "30370"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว",
            "subdistrictcode": "302503",
            "subdistrictname": "ระเริง",
            "zipcode": "30150"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว",
            "subdistrictcode": "302504",
            "subdistrictname": "อุดมทรัพย์",
            "zipcode": "30370"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3025",
            "districtname": "วังน้ำเขียว",
            "subdistrictcode": "302505",
            "subdistrictname": "ไทยสามัคคี",
            "zipcode": "30370"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3026",
            "districtname": "เทพารักษ์",
            "subdistrictcode": "302601",
            "subdistrictname": "สำนักตะคร้อ",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3026",
            "districtname": "เทพารักษ์",
            "subdistrictcode": "302602",
            "subdistrictname": "หนองแวง",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3026",
            "districtname": "เทพารักษ์",
            "subdistrictcode": "302603",
            "subdistrictname": "บึงปรือ",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3026",
            "districtname": "เทพารักษ์",
            "subdistrictcode": "302604",
            "subdistrictname": "วังยายทอง",
            "zipcode": "30210"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3027",
            "districtname": "เมืองยาง",
            "subdistrictcode": "302701",
            "subdistrictname": "เมืองยาง",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3027",
            "districtname": "เมืองยาง",
            "subdistrictcode": "302702",
            "subdistrictname": "กระเบื้องนอก",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3027",
            "districtname": "เมืองยาง",
            "subdistrictcode": "302703",
            "subdistrictname": "ละหานปลาค้าว",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3027",
            "districtname": "เมืองยาง",
            "subdistrictcode": "302704",
            "subdistrictname": "โนนอุดม",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3028",
            "districtname": "พระทองคำ",
            "subdistrictcode": "302801",
            "subdistrictname": "สระพระ",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3028",
            "districtname": "พระทองคำ",
            "subdistrictcode": "302802",
            "subdistrictname": "มาบกราด",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3028",
            "districtname": "พระทองคำ",
            "subdistrictcode": "302803",
            "subdistrictname": "พังเทียม",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3028",
            "districtname": "พระทองคำ",
            "subdistrictcode": "302804",
            "subdistrictname": "ทัพรั้ง",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3028",
            "districtname": "พระทองคำ",
            "subdistrictcode": "302805",
            "subdistrictname": "หนองหอย",
            "zipcode": "30220"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3029",
            "districtname": "ลำทะเมนชัย",
            "subdistrictcode": "302901",
            "subdistrictname": "ขุย",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3029",
            "districtname": "ลำทะเมนชัย",
            "subdistrictcode": "302902",
            "subdistrictname": "บ้านยาง",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3029",
            "districtname": "ลำทะเมนชัย",
            "subdistrictcode": "302903",
            "subdistrictname": "ช่องแมว",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3029",
            "districtname": "ลำทะเมนชัย",
            "subdistrictcode": "302904",
            "subdistrictname": "ไพล",
            "zipcode": "30270"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3030",
            "districtname": "บัวลาย",
            "subdistrictcode": "303001",
            "subdistrictname": "เมืองพะไล",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3030",
            "districtname": "บัวลาย",
            "subdistrictcode": "303002",
            "subdistrictname": "โนนจาน",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3030",
            "districtname": "บัวลาย",
            "subdistrictcode": "303003",
            "subdistrictname": "บัวลาย",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3030",
            "districtname": "บัวลาย",
            "subdistrictcode": "303004",
            "subdistrictname": "หนองหว้า",
            "zipcode": "30120"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3031",
            "districtname": "สีดา",
            "subdistrictcode": "303101",
            "subdistrictname": "สีดา",
            "zipcode": "30430"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3031",
            "districtname": "สีดา",
            "subdistrictcode": "303102",
            "subdistrictname": "โพนทอง",
            "zipcode": "30430"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3031",
            "districtname": "สีดา",
            "subdistrictcode": "303103",
            "subdistrictname": "โนนประดู่",
            "zipcode": "30430"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3031",
            "districtname": "สีดา",
            "subdistrictcode": "303104",
            "subdistrictname": "สามเมือง",
            "zipcode": "30430"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3031",
            "districtname": "สีดา",
            "subdistrictcode": "303105",
            "subdistrictname": "หนองตาดใหญ่",
            "zipcode": "30430"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "303201",
            "subdistrictname": "ช้างทอง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "303202",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "303203",
            "subdistrictname": "พระพุทธ",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "303204",
            "subdistrictname": "หนองงูเหลือม",
            "zipcode": "30000"
        },
        {
            "isActive": true,
            "provincecode": "นครราชสีมา",
            "districtcode": "3032",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "303205",
            "subdistrictname": "หนองยาง",
            "zipcode": "30230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310101",
            "subdistrictname": "ในเมือง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310102",
            "subdistrictname": "อิสาณ",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310103",
            "subdistrictname": "เสม็ด",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310104",
            "subdistrictname": "บ้านบัว",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310105",
            "subdistrictname": "สะแกโพรง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310106",
            "subdistrictname": "สวายจีก",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310108",
            "subdistrictname": "บ้านยาง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310112",
            "subdistrictname": "พระครู",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310113",
            "subdistrictname": "ถลุงเหล็ก",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310114",
            "subdistrictname": "หนองตาด",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310117",
            "subdistrictname": "ลุมปุ๊ก",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310118",
            "subdistrictname": "สองห้อง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310119",
            "subdistrictname": "บัวทอง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310120",
            "subdistrictname": "ชุมเห็ด",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310122",
            "subdistrictname": "หลักเขต",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310125",
            "subdistrictname": "สะแกซำ",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310126",
            "subdistrictname": "กลันทา",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310127",
            "subdistrictname": "กระสัง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3101",
            "districtname": "เมืองบุรีรัมย์",
            "subdistrictcode": "310128",
            "subdistrictname": "เมืองฝาง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310201",
            "subdistrictname": "คูเมือง",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310202",
            "subdistrictname": "ปะเคียบ",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310203",
            "subdistrictname": "บ้านแพ",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310204",
            "subdistrictname": "พรสำราญ",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310205",
            "subdistrictname": "หินเหล็กไฟ",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310206",
            "subdistrictname": "ตูมใหญ่",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3102",
            "districtname": "คูเมือง",
            "subdistrictcode": "310207",
            "subdistrictname": "หนองขมาร",
            "zipcode": "31190"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310301",
            "subdistrictname": "กระสัง",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310302",
            "subdistrictname": "ลำดวน",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310303",
            "subdistrictname": "สองชั้น",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310304",
            "subdistrictname": "สูงเนิน",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310305",
            "subdistrictname": "หนองเต็ง",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310306",
            "subdistrictname": "เมืองไผ่",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310307",
            "subdistrictname": "ชุมแสง",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310308",
            "subdistrictname": "บ้านปรือ",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310309",
            "subdistrictname": "ห้วยสำราญ",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310310",
            "subdistrictname": "กันทรารมย์",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3103",
            "districtname": "กระสัง",
            "subdistrictcode": "310311",
            "subdistrictname": "ศรีภูมิ",
            "zipcode": "31160"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310401",
            "subdistrictname": "นางรอง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310403",
            "subdistrictname": "สะเดา",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310405",
            "subdistrictname": "ชุมแสง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310406",
            "subdistrictname": "หนองโบสถ์",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310408",
            "subdistrictname": "หนองกง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310413",
            "subdistrictname": "ถนนหัก",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310414",
            "subdistrictname": "หนองไทร",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310415",
            "subdistrictname": "ก้านเหลือง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310416",
            "subdistrictname": "บ้านสิงห์",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310417",
            "subdistrictname": "ลำไทรโยง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310418",
            "subdistrictname": "ทรัพย์พระยา",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310424",
            "subdistrictname": "หนองยายพิมพ์",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310425",
            "subdistrictname": "หัวถนน",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310426",
            "subdistrictname": "ทุ่งแสงทอง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3104",
            "districtname": "นางรอง",
            "subdistrictcode": "310427",
            "subdistrictname": "หนองโสน",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310501",
            "subdistrictname": "หนองกี่",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310502",
            "subdistrictname": "เย้ยปราสาท",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310503",
            "subdistrictname": "เมืองไผ่",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310504",
            "subdistrictname": "ดอนอะราง",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310505",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310506",
            "subdistrictname": "ทุ่งกระตาดพัฒนา",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310507",
            "subdistrictname": "ทุ่งกระเต็น",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310508",
            "subdistrictname": "ท่าโพธิ์ชัย",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310509",
            "subdistrictname": "โคกสูง",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3105",
            "districtname": "หนองกี่",
            "subdistrictcode": "310510",
            "subdistrictname": "บุกระสัง",
            "zipcode": "31210"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310601",
            "subdistrictname": "ละหานทราย",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310603",
            "subdistrictname": "ตาจง",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310604",
            "subdistrictname": "สำโรงใหม่",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310607",
            "subdistrictname": "หนองแวง",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310610",
            "subdistrictname": "หนองตระครอง",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3106",
            "districtname": "ละหานทราย",
            "subdistrictcode": "310611",
            "subdistrictname": "โคกว่าน",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310701",
            "subdistrictname": "ประโคนชัย",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310702",
            "subdistrictname": "แสลงโทน",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310703",
            "subdistrictname": "บ้านไทร",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310705",
            "subdistrictname": "ละเวี้ย",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310706",
            "subdistrictname": "จรเข้มาก",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310707",
            "subdistrictname": "ปังกู",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310708",
            "subdistrictname": "โคกย่าง",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310710",
            "subdistrictname": "โคกม้า",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310713",
            "subdistrictname": "ไพศาล",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310714",
            "subdistrictname": "ตะโกตาพิ",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310715",
            "subdistrictname": "เขาคอก",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310716",
            "subdistrictname": "หนองบอน",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุร���รัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310718",
            "subdistrictname": "โคกมะขาม",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310719",
            "subdistrictname": "โคกตูม",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310720",
            "subdistrictname": "ประทัดบุ",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3107",
            "districtname": "ประโคนชัย",
            "subdistrictcode": "310721",
            "subdistrictname": "สี่เหลี่ยม",
            "zipcode": "31140"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310801",
            "subdistrictname": "บ้านกรวด",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310802",
            "subdistrictname": "โนนเจริญ",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310803",
            "subdistrictname": "หนองไม้งาม",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310804",
            "subdistrictname": "ปราสาท",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310805",
            "subdistrictname": "สายตะกู",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310806",
            "subdistrictname": "หินลาด",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310807",
            "subdistrictname": "บึงเจริญ",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310808",
            "subdistrictname": "จันทบเพชร",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3108",
            "districtname": "บ้านกรวด",
            "subdistrictcode": "310809",
            "subdistrictname": "เขาดินเหนือ",
            "zipcode": "31180"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310901",
            "subdistrictname": "พุทไธสง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310902",
            "subdistrictname": "มะเฟือง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310903",
            "subdistrictname": "บ้านจาน",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310906",
            "subdistrictname": "บ้านเป้า",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310907",
            "subdistrictname": "บ้านแวง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310909",
            "subdistrictname": "บ้านยาง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3109",
            "districtname": "พุทไธสง",
            "subdistrictcode": "310910",
            "subdistrictname": "หายโศก",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311001",
            "subdistrictname": "ลำปลายมาศ",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311002",
            "subdistrictname": "หนองคู",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311003",
            "subdistrictname": "แสลงพัน",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311004",
            "subdistrictname": "ทะเมนชัย",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311005",
            "subdistrictname": "ตลาดโพธิ์",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311006",
            "subdistrictname": "หนองกะทิง",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311007",
            "subdistrictname": "โคกกลาง",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311008",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311009",
            "subdistrictname": "เมืองแฝก",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311010",
            "subdistrictname": "บ้านยาง",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311011",
            "subdistrictname": "ผไทรินทร์",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311012",
            "subdistrictname": "โคกล่าม",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311013",
            "subdistrictname": "หินโคน",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311014",
            "subdistrictname": "หนองบัวโคก",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311015",
            "subdistrictname": "บุโพธิ์",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3110",
            "districtname": "ลำปลายมาศ",
            "subdistrictcode": "311016",
            "subdistrictname": "หนองโดน",
            "zipcode": "31130"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311101",
            "subdistrictname": "สตึก",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311102",
            "subdistrictname": "นิคม",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311103",
            "subdistrictname": "ทุ่งวัง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311104",
            "subdistrictname": "เมืองแก",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311105",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311106",
            "subdistrictname": "ร่อนทอง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311109",
            "subdistrictname": "ดอนมนต์",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311110",
            "subdistrictname": "ชุมแสง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311111",
            "subdistrictname": "ท่าม่วง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311112",
            "subdistrictname": "สะแก",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311114",
            "subdistrictname": "สนามชัย",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3111",
            "districtname": "สตึก",
            "subdistrictcode": "311115",
            "subdistrictname": "กระสัง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3112",
            "districtname": "ปะคำ",
            "subdistrictcode": "311201",
            "subdistrictname": "ปะคำ",
            "zipcode": "31220"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3112",
            "districtname": "ปะคำ",
            "subdistrictcode": "311202",
            "subdistrictname": "ไทยเจริญ",
            "zipcode": "31220"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3112",
            "districtname": "ปะคำ",
            "subdistrictcode": "311203",
            "subdistrictname": "หนองบัว",
            "zipcode": "31220"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3112",
            "districtname": "ปะคำ",
            "subdistrictcode": "311204",
            "subdistrictname": "โคกมะม่วง",
            "zipcode": "31220"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3112",
            "districtname": "ปะคำ",
            "subdistrictcode": "311205",
            "subdistrictname": "หูทำนบ",
            "zipcode": "31220"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3113",
            "districtname": "นาโพธิ์",
            "subdistrictcode": "311301",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "31230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3113",
            "districtname": "นาโพธิ์",
            "subdistrictcode": "311302",
            "subdistrictname": "บ้านคู",
            "zipcode": "31230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3113",
            "districtname": "นาโพธิ์",
            "subdistrictcode": "311303",
            "subdistrictname": "บ้านดู่",
            "zipcode": "31230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3113",
            "districtname": "นาโพธิ์",
            "subdistrictcode": "311304",
            "subdistrictname": "ดอนกอก",
            "zipcode": "31230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3113",
            "districtname": "นาโพธิ์",
            "subdistrictcode": "311305",
            "subdistrictname": "ศรีสว่าง",
            "zipcode": "31230"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311401",
            "subdistrictname": "สระแก้ว",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311402",
            "subdistrictname": "ห้วยหิน",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311403",
            "subdistrictname": "ไทยสามัคคี",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311404",
            "subdistrictname": "หนองชัยศรี",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311405",
            "subdistrictname": "เสาเดียว",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311406",
            "subdistrictname": "เมืองฝ้าย",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3114",
            "districtname": "หนองหงส์",
            "subdistrictcode": "311407",
            "subdistrictname": "สระทอง",
            "zipcode": "31240"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย",
            "subdistrictcode": "311501",
            "subdistrictname": "จันดุม",
            "zipcode": "31250"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย",
            "subdistrictcode": "311502",
            "subdistrictname": "โคกขมิ้น",
            "zipcode": "31250"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย",
            "subdistrictcode": "311503",
            "subdistrictname": "ป่าชัน",
            "zipcode": "31250"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย",
            "subdistrictcode": "311504",
            "subdistrictname": "สะเดา",
            "zipcode": "31250"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3115",
            "districtname": "พลับพลาชัย",
            "subdistrictcode": "311505",
            "subdistrictname": "สำโรง",
            "zipcode": "31250"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311601",
            "subdistrictname": "ห้วยราช",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311602",
            "subdistrictname": "สามแวง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311603",
            "subdistrictname": "ตาเสา",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311604",
            "subdistrictname": "บ้านตะโก",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311605",
            "subdistrictname": "สนวน",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311606",
            "subdistrictname": "โคกเหล็ก",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311607",
            "subdistrictname": "เมืองโพธิ์",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3116",
            "districtname": "ห้วยราช",
            "subdistrictcode": "311608",
            "subdistrictname": "ห้วยราชา",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3117",
            "districtname": "โนนสุวรรณ",
            "subdistrictcode": "311701",
            "subdistrictname": "โนนสุวรรณ",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3117",
            "districtname": "โนนสุวรรณ",
            "subdistrictcode": "311702",
            "subdistrictname": "ทุ่งจังหัน",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3117",
            "districtname": "โนนสุวรรณ",
            "subdistrictcode": "311703",
            "subdistrictname": "โกรกแก้ว",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3117",
            "districtname": "โนนสุวรรณ",
            "subdistrictcode": "311704",
            "subdistrictname": "ดงอีจาน",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311801",
            "subdistrictname": "ชำนิ",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311802",
            "subdistrictname": "หนองปล่อง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311803",
            "subdistrictname": "เมืองยาง",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311804",
            "subdistrictname": "ช่อผกา",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311805",
            "subdistrictname": "ละลวด",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3118",
            "districtname": "ชำนิ",
            "subdistrictcode": "311806",
            "subdistrictname": "โคกสนวน",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์",
            "subdistrictcode": "311901",
            "subdistrictname": "หนองแวง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์",
            "subdistrictcode": "311902",
            "subdistrictname": "ทองหลาง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์",
            "subdistrictcode": "311903",
            "subdistrictname": "แดงใหญ่",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์",
            "subdistrictcode": "311904",
            "subdistrictname": "กู่สวนแตง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3119",
            "districtname": "บ้านใหม่ไชยพจน์",
            "subdistrictcode": "311905",
            "subdistrictname": "หนองเยือง",
            "zipcode": "31120"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3120",
            "districtname": "โนนดินแดง",
            "subdistrictcode": "312001",
            "subdistrictname": "โนนดินแดง",
            "zipcode": "31260"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3120",
            "districtname": "โนนดินแดง",
            "subdistrictcode": "312002",
            "subdistrictname": "ส้มป่อย",
            "zipcode": "31260"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3120",
            "districtname": "โนนดินแดง",
            "subdistrictcode": "312003",
            "subdistrictname": "ลำนางรอง",
            "zipcode": "31260"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3121",
            "districtname": "บ้านด่าน",
            "subdistrictcode": "312101",
            "subdistrictname": "บ้านด่าน",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3121",
            "districtname": "บ้านด่าน",
            "subdistrictcode": "312102",
            "subdistrictname": "ปราสาท",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3121",
            "districtname": "บ้านด่าน",
            "subdistrictcode": "312103",
            "subdistrictname": "วังเหนือ",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3121",
            "districtname": "บ้านด่าน",
            "subdistrictcode": "312104",
            "subdistrictname": "โนนขวาง",
            "zipcode": "31000"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3122",
            "districtname": "แคนดง",
            "subdistrictcode": "312201",
            "subdistrictname": "แคนดง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3122",
            "districtname": "แคนดง",
            "subdistrictcode": "312202",
            "subdistrictname": "ดงพลอง",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3122",
            "districtname": "แคนดง",
            "subdistrictcode": "312203",
            "subdistrictname": "สระบัว",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3122",
            "districtname": "แคนดง",
            "subdistrictcode": "312204",
            "subdistrictname": "หัวฝาย",
            "zipcode": "31150"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "312301",
            "subdistrictname": "เจริญสุข",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "312302",
            "subdistrictname": "ตาเป๊ก",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "312303",
            "subdistrictname": "อีสานเขต",
            "zipcode": "31110"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "312304",
            "subdistrictname": "ถาวร",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "บุรีรัมย์",
            "districtcode": "3123",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "312305",
            "subdistrictname": "ยายแย้มวัฒนา",
            "zipcode": "31170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320101",
            "subdistrictname": "ในเมือง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320102",
            "subdistrictname": "ตั้งใจ",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320103",
            "subdistrictname": "เพี้ยราม",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320104",
            "subdistrictname": "นาดี",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320105",
            "subdistrictname": "ท่าสว่าง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320106",
            "subdistrictname": "สลักได",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320107",
            "subdistrictname": "ตาอ็อง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320109",
            "subdistrictname": "สำโรง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320110",
            "subdistrictname": "แกใหญ่",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320111",
            "subdistrictname": "นอกเมือง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320112",
            "subdistrictname": "คอโค",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320113",
            "subdistrictname": "สวาย",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320114",
            "subdistrictname": "เฉนียง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320116",
            "subdistrictname": "เทนมีย์",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320118",
            "subdistrictname": "นาบัว",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320119",
            "subdistrictname": "เมืองที",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320120",
            "subdistrictname": "ราม",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320121",
            "subdistrictname": "บุฤาษี",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320122",
            "subdistrictname": "ตระแสง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320125",
            "subdistrictname": "แสลงพันธ์",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3201",
            "districtname": "เมืองสุรินทร์",
            "subdistrictcode": "320126",
            "subdistrictname": "กาเกาะ",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320201",
            "subdistrictname": "ชุมพลบุรี",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320202",
            "subdistrictname": "นาหนองไผ่",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320203",
            "subdistrictname": "ไพรขลา",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320204",
            "subdistrictname": "ศรีณรงค์",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320205",
            "subdistrictname": "ยะวึก",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320206",
            "subdistrictname": "เมืองบัว",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320207",
            "subdistrictname": "สระขุด",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320208",
            "subdistrictname": "กระเบื้อง",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3202",
            "districtname": "ชุมพลบุรี",
            "subdistrictcode": "320209",
            "subdistrictname": "หนองเรือ",
            "zipcode": "32190"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320301",
            "subdistrictname": "ท่าตูม",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320302",
            "subdistrictname": "กระโพ",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320303",
            "subdistrictname": "พรมเทพ",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320304",
            "subdistrictname": "โพนครก",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320305",
            "subdistrictname": "เมืองแก",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320306",
            "subdistrictname": "บะ",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320307",
            "subdistrictname": "หนองบัว",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320308",
            "subdistrictname": "บัวโคก",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320309",
            "subdistrictname": "หนองเมธี",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3203",
            "districtname": "ท่าตูม",
            "subdistrictcode": "320310",
            "subdistrictname": "ทุ่งกุลา",
            "zipcode": "32120"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320401",
            "subdistrictname": "จอมพระ",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320402",
            "subdistrictname": "เมืองลีง",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320403",
            "subdistrictname": "กระหาด",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320404",
            "subdistrictname": "บุแกรง",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320405",
            "subdistrictname": "หนองสนิท",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320406",
            "subdistrictname": "บ้านผือ",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320407",
            "subdistrictname": "ลุ่มระวี",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320408",
            "subdistrictname": "ชุมแสง",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3204",
            "districtname": "จอมพระ",
            "subdistrictcode": "320409",
            "subdistrictname": "เป็นสุข",
            "zipcode": "32180"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320501",
            "subdistrictname": "กังแอน",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320502",
            "subdistrictname": "ทมอ",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320503",
            "subdistrictname": "ไพล",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320504",
            "subdistrictname": "ปรือ",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320505",
            "subdistrictname": "ทุ่งมน",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320506",
            "subdistrictname": "ตาเบา",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320507",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320508",
            "subdistrictname": "โคกยาง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320509",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320510",
            "subdistrictname": "บ้านไทร",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320511",
            "subdistrictname": "โชคนาสาม",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320512",
            "subdistrictname": "เชื้อเพลิง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320513",
            "subdistrictname": "ปราสาททนง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320514",
            "subdistrictname": "ตานี",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320515",
            "subdistrictname": "บ้านพลวง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320516",
            "subdistrictname": "กันตวจระมวล",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320517",
            "subdistrictname": "สมุด",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3205",
            "districtname": "ปราสาท",
            "subdistrictcode": "320518",
            "subdistrictname": "ประทัดบุ",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320601",
            "subdistrictname": "กาบเชิง",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320604",
            "subdistrictname": "คูตัน",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320605",
            "subdistrictname": "ด่าน",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320606",
            "subdistrictname": "แนงมุด",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320607",
            "subdistrictname": "โคกตะเคียน",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3206",
            "districtname": "กาบเชิง",
            "subdistrictcode": "320610",
            "subdistrictname": "ตะเคียน",
            "zipcode": "32210"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320701",
            "subdistrictname": "รัตนบุรี",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320702",
            "subdistrictname": "ธาตุ",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320703",
            "subdistrictname": "แก",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320704",
            "subdistrictname": "ดอนแรด",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320705",
            "subdistrictname": "หนองบัวทอง",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320706",
            "subdistrictname": "หนองบัวบาน",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320709",
            "subdistrictname": "ไผ่",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320711",
            "subdistrictname": "เบิด",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320713",
            "subdistrictname": "น้ำเขียว",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320714",
            "subdistrictname": "กุดขาคีม",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320715",
            "subdistrictname": "ยางสว่าง",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3207",
            "districtname": "รัตนบุรี",
            "subdistrictcode": "320716",
            "subdistrictname": "ทับใหญ่",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320801",
            "subdistrictname": "สนม",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320802",
            "subdistrictname": "โพนโก",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320803",
            "subdistrictname": "หนองระฆัง",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320804",
            "subdistrictname": "นานวน",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320805",
            "subdistrictname": "แคน",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320806",
            "subdistrictname": "หัวงัว",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3208",
            "districtname": "สนม",
            "subdistrictcode": "320807",
            "subdistrictname": "หนองอียอ",
            "zipcode": "32160"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320901",
            "subdistrictname": "ระแงง",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320902",
            "subdistrictname": "ตรึม",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320903",
            "subdistrictname": "จารพัต",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320904",
            "subdistrictname": "ยาง",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320905",
            "subdistrictname": "แตล",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320906",
            "subdistrictname": "หนองบัว",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320907",
            "subdistrictname": "คาละแมะ",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320908",
            "subdistrictname": "หนองเหล็ก",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320909",
            "subdistrictname": "หนองขวาว",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320910",
            "subdistrictname": "ช่างปี่",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320911",
            "subdistrictname": "กุดหวาย",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320912",
            "subdistrictname": "ขวาวใหญ่",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320913",
            "subdistrictname": "นารุ่ง",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320914",
            "subdistrictname": "ตรมไพร",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3209",
            "districtname": "ศีขรภูมิ",
            "subdistrictcode": "320915",
            "subdistrictname": "ผักไหม",
            "zipcode": "32110"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321001",
            "subdistrictname": "สังขะ",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321002",
            "subdistrictname": "ขอนแตก",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321006",
            "subdistrictname": "ดม",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321007",
            "subdistrictname": "พระแก้ว",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321008",
            "subdistrictname": "บ้านจารย์",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321009",
            "subdistrictname": "กระเทียม",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321010",
            "subdistrictname": "สะกาด",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321011",
            "subdistrictname": "ตาตุม",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321012",
            "subdistrictname": "ทับทัน",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321013",
            "subdistrictname": "ตาคง",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321015",
            "subdistrictname": "บ้านชบ",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3210",
            "districtname": "สังขะ",
            "subdistrictcode": "321017",
            "subdistrictname": "เทพรักษา",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3211",
            "districtname": "ลำดวน",
            "subdistrictcode": "321101",
            "subdistrictname": "ลำดวน",
            "zipcode": "32220"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3211",
            "districtname": "ลำดวน",
            "subdistrictcode": "321102",
            "subdistrictname": "โชคเหนือ",
            "zipcode": "32220"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3211",
            "districtname": "ลำดวน",
            "subdistrictcode": "321103",
            "subdistrictname": "อู่โลก",
            "zipcode": "32220"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3211",
            "districtname": "ลำดวน",
            "subdistrictcode": "321104",
            "subdistrictname": "ตรำดม",
            "zipcode": "32220"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3211",
            "districtname": "ลำดวน",
            "subdistrictcode": "321105",
            "subdistrictname": "ตระเปียงเตีย",
            "zipcode": "32220"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321201",
            "subdistrictname": "สำโรงทาบ",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321202",
            "subdistrictname": "หนองไผ่ล้อม",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321203",
            "subdistrictname": "กระออม",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321204",
            "subdistrictname": "หนองฮะ",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321205",
            "subdistrictname": "ศรีสุข",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321206",
            "subdistrictname": "เกาะแก้ว",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321207",
            "subdistrictname": "หมื่นศรี",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321208",
            "subdistrictname": "เสม็จ",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321209",
            "subdistrictname": "สะโน",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3212",
            "districtname": "สำโรงทาบ",
            "subdistrictcode": "321210",
            "subdistrictname": "ประดู่",
            "zipcode": "32170"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321301",
            "subdistrictname": "บัวเชด",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321302",
            "subdistrictname": "สะเดา",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321303",
            "subdistrictname": "จรัส",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321304",
            "subdistrictname": "ตาวัง",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321305",
            "subdistrictname": "อาโพน",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3213",
            "districtname": "บัวเชด",
            "subdistrictcode": "321306",
            "subdistrictname": "สำเภาลูน",
            "zipcode": "32230"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3214",
            "districtname": "พนมดงรัก",
            "subdistrictcode": "321401",
            "subdistrictname": "บักได",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3214",
            "districtname": "พนมดงรัก",
            "subdistrictcode": "321402",
            "subdistrictname": "โคกกลาง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3214",
            "districtname": "พนมดงรัก",
            "subdistrictcode": "321403",
            "subdistrictname": "จีกแดก",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3214",
            "districtname": "พนมดงรัก",
            "subdistrictcode": "321404",
            "subdistrictname": "ตาเมียง",
            "zipcode": "32140"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์",
            "subdistrictcode": "321501",
            "subdistrictname": "ณรงค์",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์",
            "subdistrictcode": "321502",
            "subdistrictname": "แจนแวน",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์",
            "subdistrictcode": "321503",
            "subdistrictname": "ตรวจ",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์",
            "subdistrictcode": "321504",
            "subdistrictname": "หนองแวง",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3215",
            "districtname": "ศรีณรงค์",
            "subdistrictcode": "321505",
            "subdistrictname": "ศรีสุข",
            "zipcode": "32150"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์",
            "subdistrictcode": "321601",
            "subdistrictname": "เขวาสินรินทร์",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์",
            "subdistrictcode": "321602",
            "subdistrictname": "บึง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์",
            "subdistrictcode": "321603",
            "subdistrictname": "ตากูก",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์",
            "subdistrictcode": "321604",
            "subdistrictname": "ปราสาททอง",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3216",
            "districtname": "เขวาสินรินทร์",
            "subdistrictcode": "321605",
            "subdistrictname": "บ้านแร่",
            "zipcode": "32000"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์",
            "subdistrictcode": "321701",
            "subdistrictname": "หนองหลวง",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์",
            "subdistrictcode": "321702",
            "subdistrictname": "คำผง",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์",
            "subdistrictcode": "321703",
            "subdistrictname": "โนน",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์",
            "subdistrictcode": "321704",
            "subdistrictname": "ระเวียง",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "สุรินทร์",
            "districtcode": "3217",
            "districtname": "โนนนารายณ์",
            "subdistrictcode": "321705",
            "subdistrictname": "หนองเทพ",
            "zipcode": "32130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330101",
            "subdistrictname": "เมืองเหนือ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330102",
            "subdistrictname": "เมืองใต้",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330103",
            "subdistrictname": "คูซอด",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330104",
            "subdistrictname": "ซำ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330105",
            "subdistrictname": "จาน",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330106",
            "subdistrictname": "ตะดอบ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330107",
            "subdistrictname": "หนองครก",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330111",
            "subdistrictname": "โพนข่า",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330112",
            "subdistrictname": "โพนค้อ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330115",
            "subdistrictname": "โพนเขวา",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330116",
            "subdistrictname": "หญ้าปล้อง",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330118",
            "subdistrictname": "ทุ่ม",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330119",
            "subdistrictname": "หนองไฮ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330121",
            "subdistrictname": "หนองแก้ว",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330122",
            "subdistrictname": "น้ำคำ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330123",
            "subdistrictname": "โพธิ์",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330124",
            "subdistrictname": "หมากเขียบ",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3301",
            "districtname": "เมืองศรีสะเกษ",
            "subdistrictcode": "330127",
            "subdistrictname": "หนองไผ่",
            "zipcode": "33000"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330201",
            "subdistrictname": "ยางชุมน้อย",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330202",
            "subdistrictname": "ลิ้นฟ้า",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330203",
            "subdistrictname": "คอนกาม",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330204",
            "subdistrictname": "โนนคูณ",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330205",
            "subdistrictname": "กุดเมืองฮาม",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330206",
            "subdistrictname": "บึงบอน",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3302",
            "districtname": "ยางชุมน้อย",
            "subdistrictcode": "330207",
            "subdistrictname": "ยางชุมใหญ่",
            "zipcode": "33190"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330301",
            "subdistrictname": "ดูน",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330302",
            "subdistrictname": "โนนสัง",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330303",
            "subdistrictname": "หนองหัวช้าง",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330304",
            "subdistrictname": "ยาง",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330305",
            "subdistrictname": "หนองแวง",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330306",
            "subdistrictname": "หนองแก้ว",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330307",
            "subdistrictname": "ทาม",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330308",
            "subdistrictname": "ละทาย",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330309",
            "subdistrictname": "เมืองน้อย",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330310",
            "subdistrictname": "อีปาด",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330311",
            "subdistrictname": "บัวน้อย",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330312",
            "subdistrictname": "หนองบัว",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330313",
            "subdistrictname": "ดู่",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330314",
            "subdistrictname": "ผักแพว",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330315",
            "subdistrictname": "จาน",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330320",
            "subdistrictname": "คำเนียม",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3303",
            "districtname": "กันทรารมย์",
            "subdistrictcode": "330320",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330401",
            "subdistrictname": "บึงมะลู",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330402",
            "subdistrictname": "กุดเสลา",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330403",
            "subdistrictname": "เมือง",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330405",
            "subdistrictname": "สังเม็ก",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330406",
            "subdistrictname": "น้ำอ้อม",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330407",
            "subdistrictname": "ละลาย",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330408",
            "subdistrictname": "รุง",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330409",
            "subdistrictname": "ตระกาจ",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330411",
            "subdistrictname": "จานใหญ่",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330412",
            "subdistrictname": "ภูเงิน",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330413",
            "subdistrictname": "ชำ",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330414",
            "subdistrictname": "กระแชง",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330415",
            "subdistrictname": "โนนสำราญ",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330416",
            "subdistrictname": "หนองหญ้าลาด",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330419",
            "subdistrictname": "เสาธงชัย",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330420",
            "subdistrictname": "ขนุน",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330421",
            "subdistrictname": "สวนกล้วย",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330423",
            "subdistrictname": "เวียงเหนือ",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330424",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3304",
            "districtname": "กันทรลักษ์",
            "subdistrictcode": "330425",
            "subdistrictname": "ภูผาหมอก",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330501",
            "subdistrictname": "กันทรารมย์",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330502",
            "subdistrictname": "จะกง",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330503",
            "subdistrictname": "ใจดี",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330504",
            "subdistrictname": "ดองกำเม็ด",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330505",
            "subdistrictname": "โสน",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330506",
            "subdistrictname": "ปรือใหญ่",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330507",
            "subdistrictname": "สะเดาใหญ่",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330508",
            "subdistrictname": "ตาอุด",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330509",
            "subdistrictname": "ห้วยเหนือ",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330510",
            "subdistrictname": "ห้วยใต้",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330511",
            "subdistrictname": "หัวเสือ",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330513",
            "subdistrictname": "ตะเคียน",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330515",
            "subdistrictname": "นิคมพัฒนา",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330517",
            "subdistrictname": "โคกเพชร",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330518",
            "subdistrictname": "ปราสาท",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330521",
            "subdistrictname": "สำโรงตาเจ็น",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330522",
            "subdistrictname": "ห้วยสำราญ",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330524",
            "subdistrictname": "กฤษณา",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330525",
            "subdistrictname": "ลมศักดิ์",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330526",
            "subdistrictname": "หนองฉลอง",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330527",
            "subdistrictname": "ศรีตระกูล",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3305",
            "districtname": "ขุขันธ์",
            "subdistrictcode": "330528",
            "subdistrictname": "ศรีสะอาด",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330601",
            "subdistrictname": "ไพรบึง",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330602",
            "subdistrictname": "ดินแดง",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330603",
            "subdistrictname": "ปราสาทเยอ",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330604",
            "subdistrictname": "สำโรงพลัน",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330605",
            "subdistrictname": "สุขสวัสดิ์",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3306",
            "districtname": "ไพรบึง",
            "subdistrictcode": "330606",
            "subdistrictname": "โนนปูน",
            "zipcode": "33180"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330701",
            "subdistrictname": "พิมาย",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330702",
            "subdistrictname": "กู่",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330703",
            "subdistrictname": "หนองเชียงทูน",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330704",
            "subdistrictname": "ตูม",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330705",
            "subdistrictname": "สมอ",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330706",
            "subdistrictname": "โพธิ์ศรี",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330707",
            "subdistrictname": "สำโรงปราสาท",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330708",
            "subdistrictname": "ดู่",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330709",
            "subdistrictname": "สวาย",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3307",
            "districtname": "ปรางค์กู่",
            "subdistrictcode": "330710",
            "subdistrictname": "พิมายเหนือ",
            "zipcode": "33170"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330801",
            "subdistrictname": "สิ",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330802",
            "subdistrictname": "บักดอง",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330803",
            "subdistrictname": "พราน",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330804",
            "subdistrictname": "โพธิ์วงศ์",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330805",
            "subdistrictname": "ไพร",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330806",
            "subdistrictname": "กระหวัน",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330807",
            "subdistrictname": "ขุนหาญ",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330808",
            "subdistrictname": "โนนสูง",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330809",
            "subdistrictname": "กันทรอม",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330810",
            "subdistrictname": "ภูฝ้าย",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330811",
            "subdistrictname": "โพธิ์กระสังข์",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3308",
            "districtname": "ขุนหาญ",
            "subdistrictcode": "330812",
            "subdistrictname": "ห้วยจันทร์",
            "zipcode": "33150"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330901",
            "subdistrictname": "เมืองคง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330902",
            "subdistrictname": "เมืองแคน",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330903",
            "subdistrictname": "หนองแค",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330906",
            "subdistrictname": "จิกสังข์ทอง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330907",
            "subdistrictname": "ด่าน",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330908",
            "subdistrictname": "ดู่",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330909",
            "subdistrictname": "หนองอึ่ง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330910",
            "subdistrictname": "บัวหุ่ง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330911",
            "subdistrictname": "ไผ่",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330912",
            "subdistrictname": "ส้มป่อย",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330913",
            "subdistrictname": "หนองหมี",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330914",
            "subdistrictname": "หว้านคำ",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3309",
            "districtname": "ราษีไศล",
            "subdistrictcode": "330915",
            "subdistrictname": "สร้างปี่",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331001",
            "subdistrictname": "กำแพง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331002",
            "subdistrictname": "อี่หล่ำ",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331003",
            "subdistrictname": "ก้านเหลือง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331004",
            "subdistrictname": "ทุ่งไชย",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331005",
            "subdistrictname": "สำโรง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331006",
            "subdistrictname": "แขม",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331007",
            "subdistrictname": "หนองไฮ",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิส��ย",
            "subdistrictcode": "331008",
            "subdistrictname": "ขะยูง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331010",
            "subdistrictname": "ตาเกษ",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331011",
            "subdistrictname": "หัวช้าง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331012",
            "subdistrictname": "รังแร้ง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331014",
            "subdistrictname": "แต้",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331015",
            "subdistrictname": "แข้",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331016",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331017",
            "subdistrictname": "ปะอาว",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331018",
            "subdistrictname": "หนองห้าง",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331022",
            "subdistrictname": "สระกำแพงใหญ่",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331024",
            "subdistrictname": "โคกหล่าม",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3310",
            "districtname": "อุทุมพรพิสัย",
            "subdistrictcode": "331025",
            "subdistrictname": "โคกจาน",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3311",
            "districtname": "บึงบูรพ์",
            "subdistrictcode": "331101",
            "subdistrictname": "เป๊าะ",
            "zipcode": "33220"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3311",
            "districtname": "บึงบูรพ์",
            "subdistrictcode": "331102",
            "subdistrictname": "บึงบูรพ์",
            "zipcode": "33220"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331201",
            "subdistrictname": "ห้วยทับทัน",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331202",
            "subdistrictname": "เมืองหลวง",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331203",
            "subdistrictname": "กล้วยกว้าง",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331204",
            "subdistrictname": "ผักไหม",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331205",
            "subdistrictname": "จานแสนไชย",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3312",
            "districtname": "ห้วยทับทัน",
            "subdistrictcode": "331206",
            "subdistrictname": "ปราสาท",
            "zipcode": "33210"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3313",
            "districtname": "โนนคูณ",
            "subdistrictcode": "331301",
            "subdistrictname": "โนนค้อ",
            "zipcode": "33250"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3313",
            "districtname": "โนนคูณ",
            "subdistrictcode": "331302",
            "subdistrictname": "บก",
            "zipcode": "33250"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3313",
            "districtname": "โนนคูณ",
            "subdistrictcode": "331303",
            "subdistrictname": "โพธิ์",
            "zipcode": "33250"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3313",
            "districtname": "โนนคูณ",
            "subdistrictcode": "331304",
            "subdistrictname": "หนองกุง",
            "zipcode": "33250"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3313",
            "districtname": "โนนคูณ",
            "subdistrictcode": "331305",
            "subdistrictname": "เหล่ากวาง",
            "zipcode": "33250"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331401",
            "subdistrictname": "ศรีแก้ว",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331402",
            "subdistrictname": "พิงพวย",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331403",
            "subdistrictname": "สระเยาว์",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331404",
            "subdistrictname": "ตูม",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331405",
            "subdistrictname": "เสื่องข้าว",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331406",
            "subdistrictname": "ศรีโนนงาม",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3314",
            "districtname": "ศรีรัตนะ",
            "subdistrictcode": "331407",
            "subdistrictname": "สะพุง",
            "zipcode": "33240"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331501",
            "subdistrictname": "น้ำเกลี้ยง",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331502",
            "subdistrictname": "ละเอาะ",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331503",
            "subdistrictname": "ตองปิด",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331504",
            "subdistrictname": "เขิน",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331505",
            "subdistrictname": "รุ่งระวี",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3315",
            "districtname": "น้ำเกลี้ยง",
            "subdistrictcode": "331506",
            "subdistrictname": "คูบ",
            "zipcode": "33130"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331601",
            "subdistrictname": "บุสูง",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331602",
            "subdistrictname": "ธาตุ",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331603",
            "subdistrictname": "ดวนใหญ่",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331604",
            "subdistrictname": "บ่อแก้ว",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331605",
            "subdistrictname": "ศรีสำราญ",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331606",
            "subdistrictname": "ทุ่งสว่าง",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331607",
            "subdistrictname": "วังหิน",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3316",
            "districtname": "วังหิน",
            "subdistrictcode": "331608",
            "subdistrictname": "โพนยาง",
            "zipcode": "33270"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331701",
            "subdistrictname": "โคกตาล",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331702",
            "subdistrictname": "ห้วยตามอญ",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331703",
            "subdistrictname": "ห้วยตึ๊กชู",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331704",
            "subdistrictname": "ละลม",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331705",
            "subdistrictname": "ตะเคียนราม",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331706",
            "subdistrictname": "ดงรัก",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3317",
            "districtname": "ภูสิงห์",
            "subdistrictcode": "331707",
            "subdistrictname": "ไพรพัฒนา",
            "zipcode": "33140"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3318",
            "districtname": "เมืองจันทร์",
            "subdistrictcode": "331801",
            "subdistrictname": "เมืองจันทร์",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3318",
            "districtname": "เมืองจันทร์",
            "subdistrictcode": "331802",
            "subdistrictname": "ตาโกน",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3318",
            "districtname": "เมืองจันทร์",
            "subdistrictcode": "331803",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์",
            "subdistrictcode": "331901",
            "subdistrictname": "เสียว",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์",
            "subdistrictcode": "331902",
            "subdistrictname": "หนองหว้า",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์",
            "subdistrictcode": "331903",
            "subdistrictname": "หนองงูเหลือม",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์",
            "subdistrictcode": "331904",
            "subdistrictname": "หนองฮาง",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3319",
            "districtname": "เบญจลักษ์",
            "subdistrictcode": "331905",
            "subdistrictname": "ท่าคล้อ",
            "zipcode": "33110"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3320",
            "districtname": "พยุห์",
            "subdistrictcode": "332001",
            "subdistrictname": "พยุห์",
            "zipcode": "33230"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3320",
            "districtname": "พยุห์",
            "subdistrictcode": "332002",
            "subdistrictname": "พรหมสวัสดิ์",
            "zipcode": "33230"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3320",
            "districtname": "พยุห์",
            "subdistrictcode": "332003",
            "subdistrictname": "ตำแย",
            "zipcode": "33230"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3320",
            "districtname": "พยุห์",
            "subdistrictcode": "332004",
            "subdistrictname": "โนนเพ็ก",
            "zipcode": "33230"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3320",
            "districtname": "พยุห์",
            "subdistrictcode": "332005",
            "subdistrictname": "หนองค้า",
            "zipcode": "33230"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ",
            "subdistrictcode": "332101",
            "subdistrictname": "โดด",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ",
            "subdistrictcode": "332102",
            "subdistrictname": "เสียว",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ",
            "subdistrictcode": "332103",
            "subdistrictname": "หนองม้า",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ",
            "subdistrictcode": "332104",
            "subdistrictname": "ผือใหญ่",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3321",
            "districtname": "โพธิ์ศรีสุวรรณ",
            "subdistrictcode": "332105",
            "subdistrictname": "อีเซ",
            "zipcode": "33120"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3322",
            "districtname": "ศิลาลาด",
            "subdistrictcode": "332201",
            "subdistrictname": "กุง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3322",
            "districtname": "ศิลาลาด",
            "subdistrictcode": "332202",
            "subdistrictname": "คลีกลิ้ง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3322",
            "districtname": "ศิลาลาด",
            "subdistrictcode": "332203",
            "subdistrictname": "หนองบัวดง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "ศรีสะเกษ",
            "districtcode": "3322",
            "districtname": "ศิลาลาด",
            "subdistrictcode": "332204",
            "subdistrictname": "โจดม่วง",
            "zipcode": "33160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340101",
            "subdistrictname": "ในเมือง",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340104",
            "subdistrictname": "หัวเรือ",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340105",
            "subdistrictname": "หนองขอน",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340107",
            "subdistrictname": "ปทุม",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340108",
            "subdistrictname": "ขามใหญ่",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340109",
            "subdistrictname": "แจระแม",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340111",
            "subdistrictname": "หนองบ่อ",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340112",
            "subdistrictname": "ไร่น้อย",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340113",
            "subdistrictname": "กระโสบ",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340116",
            "subdistrictname": "กุดลาด",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340119",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3401",
            "districtname": "เมืองอุบลราชธานี",
            "subdistrictcode": "340120",
            "subdistrictname": "ปะอาว",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340201",
            "subdistrictname": "นาคำ",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340202",
            "subdistrictname": "แก้งกอก",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340203",
            "subdistrictname": "เอือดใหญ่",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340204",
            "subdistrictname": "วาริน",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340205",
            "subdistrictname": "ลาดควาย",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340206",
            "subdistrictname": "สงยาง",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340207",
            "subdistrictname": "ตะบ่าย",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340208",
            "subdistrictname": "คำไหล",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340209",
            "subdistrictname": "หนามแท่ง",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340210",
            "subdistrictname": "นาเลิน",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3402",
            "districtname": "ศรีเมืองใหม่",
            "subdistrictcode": "340211",
            "subdistrictname": "ดอนใหญ่",
            "zipcode": "34250"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340301",
            "subdistrictname": "โขงเจียม",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340302",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340303",
            "subdistrictname": "นาโพธิ์กลาง",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340304",
            "subdistrictname": "หนองแสงใหญ่",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340305",
            "subdistrictname": "ห้วยไผ่",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3403",
            "districtname": "โขงเจียม",
            "subdistrictcode": "340306",
            "subdistrictname": "คำเขื่อนแก้ว",
            "zipcode": "34220"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340401",
            "subdistrictname": "เขื่องใน",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340402",
            "subdistrictname": "สร้างถ่อ",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340403",
            "subdistrictname": "ค้อทอง",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340404",
            "subdistrictname": "ก่อเอ้",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340405",
            "subdistrictname": "หัวดอน",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340406",
            "subdistrictname": "ชีทวน",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340407",
            "subdistrictname": "ท่าไห",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340408",
            "subdistrictname": "นาคำใหญ่",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340409",
            "subdistrictname": "แดงหม้อ",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340410",
            "subdistrictname": "ธาตุน้อย",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340411",
            "subdistrictname": "บ้านไทย",
            "zipcode": "34320"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340412",
            "subdistrictname": "บ้านกอก",
            "zipcode": "34320"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340413",
            "subdistrictname": "กลางใหญ่",
            "zipcode": "34320"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340414",
            "subdistrictname": "โนนรัง",
            "zipcode": "34320"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340415",
            "subdistrictname": "ยางขี้นก",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340416",
            "subdistrictname": "ศรีสุข",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340417",
            "subdistrictname": "สหธาตุ",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3404",
            "districtname": "เขื่องใน",
            "subdistrictcode": "340418",
            "subdistrictname": "หนองเหล่า",
            "zipcode": "34150"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340501",
            "subdistrictname": "เขมราฐ",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340503",
            "subdistrictname": "ขามป้อม",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340504",
            "subdistrictname": "เจียด",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340507",
            "subdistrictname": "หนองผือ",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340508",
            "subdistrictname": "นาแวง",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340510",
            "subdistrictname": "แก้งเหนือ",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340511",
            "subdistrictname": "หนองนกทา",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340512",
            "subdistrictname": "หนองสิม",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3405",
            "districtname": "เขมราฐ",
            "subdistrictcode": "340513",
            "subdistrictname": "หัวนา",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340701",
            "subdistrictname": "เมืองเดช",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340702",
            "subdistrictname": "นาส่วง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340704",
            "subdistrictname": "นาเจริญ",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340706",
            "subdistrictname": "ทุ่งเทิง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340708",
            "subdistrictname": "สมสะอาด",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340709",
            "subdistrictname": "กุดประทาย",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340710",
            "subdistrictname": "ตบหู",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340711",
            "subdistrictname": "กลาง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340712",
            "subdistrictname": "แก้ง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340713",
            "subdistrictname": "ท่าโพธิ์ศรี",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340715",
            "subdistrictname": "บัวงาม",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340716",
            "subdistrictname": "คำครั่ง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340717",
            "subdistrictname": "นากระแซง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340720",
            "subdistrictname": "โพนงาม",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340721",
            "subdistrictname": "ป่าโมง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3407",
            "districtname": "เดชอุดม",
            "subdistrictcode": "340723",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340801",
            "subdistrictname": "นาจะหลวย",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340802",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340803",
            "subdistrictname": "พรสวรรค์",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340804",
            "subdistrictname": "บ้านตูม",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340805",
            "subdistrictname": "โสกแสง",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3408",
            "districtname": "นาจะหลวย",
            "subdistrictcode": "340806",
            "subdistrictname": "โนนสวรรค์",
            "zipcode": "34280"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340901",
            "subdistrictname": "โซง",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340903",
            "subdistrictname": "ยาง",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340904",
            "subdistrictname": "โดมประดิษฐ์",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340906",
            "subdistrictname": "บุเปือย",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340907",
            "subdistrictname": "สีวิเชียร",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340909",
            "subdistrictname": "ยางใหญ่",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3409",
            "districtname": "น้ำยืน",
            "subdistrictcode": "340911",
            "subdistrictname": "เก่าขาม",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341001",
            "subdistrictname": "โพนงาม",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341002",
            "subdistrictname": "ห้วยข่า",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341003",
            "subdistrictname": "คอแลน",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341004",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341005",
            "subdistrictname": "หนองสะโน",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341006",
            "subdistrictname": "โนนค้อ",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341007",
            "subdistrictname": "บัวงาม",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3410",
            "districtname": "บุณฑริก",
            "subdistrictcode": "341008",
            "subdistrictname": "บ้านแมด",
            "zipcode": "34230"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341101",
            "subdistrictname": "ขุหลุ",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341102",
            "subdistrictname": "กระเดียน",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341103",
            "subdistrictname": "เกษม",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341104",
            "subdistrictname": "กุศกร",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341105",
            "subdistrictname": "ขามเปี้ย",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341106",
            "subdistrictname": "คอนสาย",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341107",
            "subdistrictname": "โคกจาน",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341108",
            "subdistrictname": "นาพิน",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341109",
            "subdistrictname": "นาสะไม",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341110",
            "subdistrictname": "โนนกุง",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341111",
            "subdistrictname": "ตระการ",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341112",
            "subdistrictname": "ตากแดด",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341113",
            "subdistrictname": "ไหล่ทุ่ง",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341114",
            "subdistrictname": "เป้า",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341115",
            "subdistrictname": "เซเป็ด",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341116",
            "subdistrictname": "สะพือ",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341117",
            "subdistrictname": "หนองเต่า",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341118",
            "subdistrictname": "ถ้ำแข้",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341119",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341120",
            "subdistrictname": "ห้วยฝ้ายพัฒนา",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341121",
            "subdistrictname": "กุดยาลวน",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341122",
            "subdistrictname": "บ้านแดง",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3411",
            "districtname": "ตระการพืชผล",
            "subdistrictcode": "341123",
            "subdistrictname": "คำเจริญ",
            "zipcode": "34130"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3412",
            "districtname": "กุดข้าวปุ้น",
            "subdistrictcode": "341201",
            "subdistrictname": "ข้าวปุ้น",
            "zipcode": "34270"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3412",
            "districtname": "กุดข้าวปุ้น",
            "subdistrictcode": "341202",
            "subdistrictname": "โนนสวาง",
            "zipcode": "34270"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3412",
            "districtname": "กุดข้าวปุ้น",
            "subdistrictcode": "341203",
            "subdistrictname": "แก่งเค็ง",
            "zipcode": "34270"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3412",
            "districtname": "กุดข้าวปุ้น",
            "subdistrictcode": "341204",
            "subdistrictname": "กาบิน",
            "zipcode": "34270"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3412",
            "districtname": "กุดข้าวป��้น",
            "subdistrictcode": "341205",
            "subdistrictname": "หนองทันน��ำ",
            "zipcode": "34270"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341401",
            "subdistrictname": "ม่วงสามสิบ",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341402",
            "subdistrictname": "เหล่าบก",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341403",
            "subdistrictname": "ดุมใหญ่",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341404",
            "subdistrictname": "หนองช้างใหญ่",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341405",
            "subdistrictname": "หนองเมือง",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341406",
            "subdistrictname": "เตย",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341407",
            "subdistrictname": "ยางสักกระโพหลุ่ม",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341408",
            "subdistrictname": "หนองไข่นก",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341409",
            "subdistrictname": "หนองเหล่า",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341410",
            "subdistrictname": "หนองฮาง",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341411",
            "subdistrictname": "ยางโยภาพ",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341412",
            "subdistrictname": "ไผ่ใหญ่",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341413",
            "subdistrictname": "นาเลิง",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3414",
            "districtname": "ม่วงสามสิบ",
            "subdistrictcode": "341414",
            "subdistrictname": "โพนแพง",
            "zipcode": "34140"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341501",
            "subdistrictname": "วารินชำราบ",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341502",
            "subdistrictname": "ธาตุ",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341504",
            "subdistrictname": "ท่าลาด",
            "zipcode": "34310"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341505",
            "subdistrictname": "โนนโหนน",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341507",
            "subdistrictname": "คูเมือง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341508",
            "subdistrictname": "สระสมิง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341510",
            "subdistrictname": "คำน้ำแซบ",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341511",
            "subdistrictname": "บุ่งหวาย",
            "zipcode": "34310"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341515",
            "subdistrictname": "คำขวาง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341516",
            "subdistrictname": "โพธิ์ใหญ่",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341518",
            "subdistrictname": "แสนสุข",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341520",
            "subdistrictname": "หนองกินเพล",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341521",
            "subdistrictname": "โนนผึ้ง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341522",
            "subdistrictname": "เมืองศรีไค",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341524",
            "subdistrictname": "ห้วยขะยุง",
            "zipcode": "34310"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3415",
            "districtname": "วารินชำราบ",
            "subdistrictcode": "341526",
            "subdistrictname": "บุ่งไหม",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341901",
            "subdistrictname": "พิบูล",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341902",
            "subdistrictname": "กุดชมภู",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341904",
            "subdistrictname": "ดอนจิก",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341905",
            "subdistrictname": "ทรายมูล",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341906",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341907",
            "subdistrictname": "โนนกลาง",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341909",
            "subdistrictname": "โพธิ์ไทร",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341910",
            "subdistrictname": "โพธิ์ศรี",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341911",
            "subdistrictname": "ระเว",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341912",
            "subdistrictname": "ไร่ใต้",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341913",
            "subdistrictname": "หนองบัวฮี",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341914",
            "subdistrictname": "อ่างศิลา",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341918",
            "subdistrictname": "โนนกาหลง",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3419",
            "districtname": "พิบูลมังสาหาร",
            "subdistrictcode": "341919",
            "subdistrictname": "บ้านแขม",
            "zipcode": "34110"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342001",
            "subdistrictname": "ตาลสุม",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342002",
            "subdistrictname": "สำโรง",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342003",
            "subdistrictname": "จิกเทิง",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342004",
            "subdistrictname": "หนองกุง",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342005",
            "subdistrictname": "นาคาย",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3420",
            "districtname": "ตาลสุม",
            "subdistrictcode": "342006",
            "subdistrictname": "คำหว้า",
            "zipcode": "34330"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342101",
            "subdistrictname": "โพธิ์ไทร",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342102",
            "subdistrictname": "ม่วงใหญ่",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342103",
            "subdistrictname": "สำโรง",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342104",
            "subdistrictname": "สองคอน",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342105",
            "subdistrictname": "สารภี",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3421",
            "districtname": "โพธิ์ไทร",
            "subdistrictcode": "342106",
            "subdistrictname": "เหล่างาม",
            "zipcode": "34340"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342201",
            "subdistrictname": "สำโรง",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342202",
            "subdistrictname": "โคกก่อง",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342203",
            "subdistrictname": "หนองไฮ",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342204",
            "subdistrictname": "ค้อน้อย",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342205",
            "subdistrictname": "โนนกาเล็น",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342206",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342207",
            "subdistrictname": "โนนกลาง",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342208",
            "subdistrictname": "บอน",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3422",
            "districtname": "สำโรง",
            "subdistrictcode": "342209",
            "subdistrictname": "ขามป้อม",
            "zipcode": "34360"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3424",
            "districtname": "ดอนมดแดง",
            "subdistrictcode": "342401",
            "subdistrictname": "ดอนมดแดง",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3424",
            "districtname": "ดอนมดแดง",
            "subdistrictcode": "342402",
            "subdistrictname": "เหล่าแดง",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3424",
            "districtname": "ดอนมดแดง",
            "subdistrictcode": "342403",
            "subdistrictname": "ท่าเมือง",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3424",
            "districtname": "ดอนมดแดง",
            "subdistrictcode": "342404",
            "subdistrictname": "คำไฮใหญ่",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342501",
            "subdistrictname": "คันไร่",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342502",
            "subdistrictname": "ช่องเม็ก",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342503",
            "subdistrictname": "โนนก่อ",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342504",
            "subdistrictname": "นิคมสร้างตนเองลำโดมน้อย",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342505",
            "subdistrictname": "ฝางคำ",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3425",
            "districtname": "สิรินธร",
            "subdistrictcode": "342506",
            "subdistrictname": "คำเขื่อนแก้ว",
            "zipcode": "34350"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม",
            "subdistrictcode": "342602",
            "subdistrictname": "หนองอ้ม",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม",
            "subdistrictcode": "342603",
            "subdistrictname": "นาเกษม",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม",
            "subdistrictcode": "342604",
            "subdistrictname": "กุดเรือ",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม",
            "subdistrictcode": "342605",
            "subdistrictname": "โคกชำแระ",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3426",
            "districtname": "ทุ่งศรีอุดม",
            "subdistrictcode": "342606",
            "subdistrictname": "นาห่อม",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3429",
            "districtname": "นาเยีย",
            "subdistrictcode": "342901",
            "subdistrictname": "นาเยีย",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3429",
            "districtname": "นาเยีย",
            "subdistrictcode": "342902",
            "subdistrictname": "นาดี",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3429",
            "districtname": "นาเยีย",
            "subdistrictcode": "342903",
            "subdistrictname": "นาเรือง",
            "zipcode": "34160"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3430",
            "districtname": "นาตาล",
            "subdistrictcode": "343001",
            "subdistrictname": "นาตาล",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3430",
            "districtname": "นาตาล",
            "subdistrictcode": "343002",
            "subdistrictname": "พะลาน",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3430",
            "districtname": "นาตาล",
            "subdistrictcode": "343003",
            "subdistrictname": "กองโพน",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3430",
            "districtname": "นาตาล",
            "subdistrictcode": "343004",
            "subdistrictname": "พังเคน",
            "zipcode": "34170"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3431",
            "districtname": "เหล่าเสือโก้ก",
            "subdistrictcode": "343101",
            "subdistrictname": "เหล่าเสือโก้ก",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3431",
            "districtname": "เหล่าเสือโก้ก",
            "subdistrictcode": "343102",
            "subdistrictname": "โพนเมือง",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3431",
            "districtname": "เหล่าเสือโก้ก",
            "subdistrictcode": "343103",
            "subdistrictname": "แพงใหญ่",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3431",
            "districtname": "เหล่าเสือโก้ก",
            "subdistrictcode": "343104",
            "subdistrictname": "หนองบก",
            "zipcode": "34000"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3432",
            "districtname": "สว่างวีระวงศ์",
            "subdistrictcode": "343201",
            "subdistrictname": "แก่งโดม",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3432",
            "districtname": "สว่างวีระวงศ์",
            "subdistrictcode": "343202",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3432",
            "districtname": "สว่างวีระวงศ์",
            "subdistrictcode": "343203",
            "subdistrictname": "บุ่งมะแลง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3432",
            "districtname": "สว่างวีระวงศ์",
            "subdistrictcode": "343204",
            "subdistrictname": "สว่าง",
            "zipcode": "34190"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3433",
            "districtname": "น้ำขุ่น",
            "subdistrictcode": "343301",
            "subdistrictname": "ตาเกา",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3433",
            "districtname": "น้ำขุ่น",
            "subdistrictcode": "343302",
            "subdistrictname": "ไพบูลย์",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3433",
            "districtname": "น้ำขุ่น",
            "subdistrictcode": "343303",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "อุบลราชธานี",
            "districtcode": "3433",
            "districtname": "น้ำขุ่น",
            "subdistrictcode": "343304",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "34260"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350101",
            "subdistrictname": "ในเมือง",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350102",
            "subdistrictname": "น้ำคำใหญ่",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350103",
            "subdistrictname": "ตาดทอง",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350104",
            "subdistrictname": "สำราญ",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350105",
            "subdistrictname": "ค้อเหนือ",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350106",
            "subdistrictname": "ดู่ทุ่ง",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350107",
            "subdistrictname": "เดิด",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350108",
            "subdistrictname": "ขั้นไดใหญ่",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350109",
            "subdistrictname": "ทุ่งแต้",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350110",
            "subdistrictname": "สิงห์",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350111",
            "subdistrictname": "นาสะไมย์",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350112",
            "subdistrictname": "เขื่องคำ",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350113",
            "subdistrictname": "หนองหิน",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350114",
            "subdistrictname": "หนองคู",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350115",
            "subdistrictname": "ขุมเงิน",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350116",
            "subdistrictname": "ทุ่งนางโอก",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350117",
            "subdistrictname": "หนองเรือ",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3501",
            "districtname": "เมืองยโสธร",
            "subdistrictcode": "350118",
            "subdistrictname": "หนองเป็ด",
            "zipcode": "35000"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3502",
            "districtname": "ทรายมูล",
            "subdistrictcode": "350201",
            "subdistrictname": "ทรายมูล",
            "zipcode": "35170"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3502",
            "districtname": "ทรายมูล",
            "subdistrictcode": "350202",
            "subdistrictname": "ดู่ลาด",
            "zipcode": "35170"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3502",
            "districtname": "ทรายมูล",
            "subdistrictcode": "350203",
            "subdistrictname": "ดงมะไฟ",
            "zipcode": "35170"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3502",
            "districtname": "ทรายมูล",
            "subdistrictcode": "350204",
            "subdistrictname": "นาเวียง",
            "zipcode": "35170"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3502",
            "districtname": "ทรายมูล",
            "subdistrictcode": "350205",
            "subdistrictname": "ไผ่",
            "zipcode": "35170"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350301",
            "subdistrictname": "กุดชุม",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350302",
            "subdistrictname": "โนนเปือย",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350303",
            "subdistrictname": "กำแมด",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350304",
            "subdistrictname": "นาโส่",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350305",
            "subdistrictname": "ห้วยแก้ง",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350306",
            "subdistrictname": "หนองหมี",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350307",
            "subdistrictname": "โพนงาม",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350308",
            "subdistrictname": "คำน้ำสร้าง",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3503",
            "districtname": "กุดชุม",
            "subdistrictcode": "350309",
            "subdistrictname": "หนองแหน",
            "zipcode": "35140"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350401",
            "subdistrictname": "ลุมพุก",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350402",
            "subdistrictname": "ย่อ",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350403",
            "subdistrictname": "สงเปือย",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350404",
            "subdistrictname": "โพนทัน",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350405",
            "subdistrictname": "ทุ่งมน",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350406",
            "subdistrictname": "นาคำ",
            "zipcode": "35180"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350407",
            "subdistrictname": "ดงแคนใหญ่",
            "zipcode": "35180"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350408",
            "subdistrictname": "กู่จาน",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350409",
            "subdistrictname": "นาแก",
            "zipcode": "35180"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350410",
            "subdistrictname": "กุดกุง",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350411",
            "subdistrictname": "เหล่าไฮ",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350412",
            "subdistrictname": "แคนน้อย",
            "zipcode": "35180"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3504",
            "districtname": "คำเขื่อนแก้ว",
            "subdistrictcode": "350413",
            "subdistrictname": "ดงเจริญ",
            "zipcode": "35110"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว",
            "subdistrictcode": "350501",
            "subdistrictname": "โพธิ์ไทร",
            "zipcode": "35150"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว",
            "subdistrictcode": "350502",
            "subdistrictname": "กระจาย",
            "zipcode": "35150"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว",
            "subdistrictcode": "350503",
            "subdistrictname": "โคกนาโก",
            "zipcode": "35150"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว",
            "subdistrictcode": "350504",
            "subdistrictname": "เชียงเพ็ง",
            "zipcode": "35150"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3505",
            "districtname": "ป่าติ้ว",
            "subdistrictcode": "350505",
            "subdistrictname": "ศรีฐาน",
            "zipcode": "35150"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350601",
            "subdistrictname": "ฟ้าหยาด",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350602",
            "subdistrictname": "หัวเมือง",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350603",
            "subdistrictname": "คูเมือง",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350604",
            "subdistrictname": "ผือฮี",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350605",
            "subdistrictname": "บากเรือ",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350606",
            "subdistrictname": "ม่วง",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350607",
            "subdistrictname": "โนนทราย",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350608",
            "subdistrictname": "บึงแก",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350609",
            "subdistrictname": "พระเสาร์",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3506",
            "districtname": "มหาชนะชัย",
            "subdistrictcode": "350610",
            "subdistrictname": "สงยาง",
            "zipcode": "35130"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3507",
            "districtname": "ค้อวัง",
            "subdistrictcode": "350701",
            "subdistrictname": "ฟ้าห่วน",
            "zipcode": "35160"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3507",
            "districtname": "ค้อวัง",
            "subdistrictcode": "350702",
            "subdistrictname": "กุดน้ำใส",
            "zipcode": "35160"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3507",
            "districtname": "ค้อวัง",
            "subdistrictcode": "350703",
            "subdistrictname": "น้ำอ้อม",
            "zipcode": "35160"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3507",
            "districtname": "ค้อวัง",
            "subdistrictcode": "350704",
            "subdistrictname": "ค้อวัง",
            "zipcode": "35160"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350802",
            "subdistrictname": "บุ่งค้า",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350803",
            "subdistrictname": "สวาท",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350805",
            "subdistrictname": "ห้องแซง",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350806",
            "subdistrictname": "สามัคคี",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350807",
            "subdistrictname": "กุดเชียงหมี",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350810",
            "subdistrictname": "สามแยก",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350811",
            "subdistrictname": "กุดแห่",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350812",
            "subdistrictname": "โคกสำราญ",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350813",
            "subdistrictname": "สร้างมิ่ง",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3508",
            "districtname": "เลิงนกทา",
            "subdistrictcode": "350814",
            "subdistrictname": "ศรีแก้ว",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ",
            "subdistrictcode": "350901",
            "subdistrictname": "ไทยเจริญ",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ",
            "subdistrictcode": "350902",
            "subdistrictname": "น้ำคำ",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ",
            "subdistrictcode": "350903",
            "subdistrictname": "ส้มผ่อ",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ",
            "subdistrictcode": "350904",
            "subdistrictname": "คำเตย",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ยโสธร",
            "districtcode": "3509",
            "districtname": "ไทยเจริญ",
            "subdistrictcode": "350905",
            "subdistrictname": "คำไผ่",
            "zipcode": "35120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360101",
            "subdistrictname": "ในเมือง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360102",
            "subdistrictname": "รอบเมือง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360103",
            "subdistrictname": "โพนทอง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360104",
            "subdistrictname": "นาฝาย",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360105",
            "subdistrictname": "บ้านค่าย",
            "zipcode": "36240"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360106",
            "subdistrictname": "กุดตุ้ม",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360107",
            "subdistrictname": "ชีลอง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360108",
            "subdistrictname": "บ้านเล่า",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360109",
            "subdistrictname": "นาเสียว",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360110",
            "subdistrictname": "หนองนาแซง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360111",
            "subdistrictname": "ลาดใหญ่",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360112",
            "subdistrictname": "หนองไผ่",
            "zipcode": "36240"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360113",
            "subdistrictname": "ท่าหินโงม",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360114",
            "subdistrictname": "ห้วยต้อน",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360115",
            "subdistrictname": "ห้วยบง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360116",
            "subdistrictname": "โนนสำราญ",
            "zipcode": "36240"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360117",
            "subdistrictname": "โคกสูง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360118",
            "subdistrictname": "บุ่งคล้า",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3601",
            "districtname": "เมืองชัยภูมิ",
            "subdistrictcode": "360119",
            "subdistrictname": "ซับสีทอง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360201",
            "subdistrictname": "บ้านเขว้า",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360202",
            "subdistrictname": "ตลาดแร้ง",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360203",
            "subdistrictname": "ลุ่มลำชี",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360204",
            "subdistrictname": "ชีบน",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360205",
            "subdistrictname": "ภูแลนคา",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3602",
            "districtname": "บ้านเขว้า",
            "subdistrictcode": "360206",
            "subdistrictname": "โนนแดง",
            "zipcode": "36170"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360301",
            "subdistrictname": "คอนสวรรค์",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360302",
            "subdistrictname": "ยางหวาย",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360303",
            "subdistrictname": "ช่องสามหมอ",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360304",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360305",
            "subdistrictname": "ห้วยไร่",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360306",
            "subdistrictname": "บ้านโสก",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360307",
            "subdistrictname": "โคกมั่งงอย",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360308",
            "subdistrictname": "หนองขาม",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3603",
            "districtname": "คอนสวรรค์",
            "subdistrictcode": "360309",
            "subdistrictname": "ศรีสำราญ",
            "zipcode": "36140"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360401",
            "subdistrictname": "บ้านยาง",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360402",
            "subdistrictname": "บ้านหัน",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360403",
            "subdistrictname": "บ้านเดื่อ",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360404",
            "subdistrictname": "บ้านเป้า",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360405",
            "subdistrictname": "กุดเลาะ",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360406",
            "subdistrictname": "โนนกอก",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360407",
            "subdistrictname": "สระโพนทอง",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360408",
            "subdistrictname": "หนองข่า",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360409",
            "subdistrictname": "หนองโพนงาม",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360410",
            "subdistrictname": "บ้านบัว",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360411",
            "subdistrictname": "ซับสีทอง",
            "zipcode": "36000"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3604",
            "districtname": "เกษตรสมบูรณ์",
            "subdistrictcode": "360412",
            "subdistrictname": "โนนทอง",
            "zipcode": "36120"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360501",
            "subdistrictname": "หนองบัวแดง",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360502",
            "subdistrictname": "กุดชุมแสง",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360503",
            "subdistrictname": "ถ้ำวัวแดง",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360504",
            "subdistrictname": "นางแดด",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360507",
            "subdistrictname": "หนองแวง",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360508",
            "subdistrictname": "คูเมือง",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360509",
            "subdistrictname": "ท่าใหญ่",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3605",
            "districtname": "หนองบัวแดง",
            "subdistrictcode": "360511",
            "subdistrictname": "วังชมภู",
            "zipcode": "36210"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360601",
            "subdistrictname": "บ้านกอก",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360602",
            "subdistrictname": "หนองบัวบาน",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360603",
            "subdistrictname": "บ้านขาม",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360605",
            "subdistrictname": "กุดน้ำใส",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360606",
            "subdistrictname": "หนองโดน",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360607",
            "subdistrictname": "ละหาน",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360610",
            "subdistrictname": "หนองบัวใหญ่",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360611",
            "subdistrictname": "หนองบัวโคก",
            "zipcode": "36220"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3606",
            "districtname": "จัตุรัส",
            "subdistrictcode": "360613",
            "subdistrictname": "ส้มป่อย",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360701",
            "subdistrictname": "บ้านชวน",
            "zipcode": "36160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360702",
            "subdistrictname": "บ้านเพชร",
            "zipcode": "36160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360703",
            "subdistrictname": "บ้านตาล",
            "zipcode": "36220"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360704",
            "subdistrictname": "หัวทะเล",
            "zipcode": "36220"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360705",
            "subdistrictname": "โคกเริงรมย์",
            "zipcode": "36160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360706",
            "subdistrictname": "เกาะมะนาว",
            "zipcode": "36160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3607",
            "districtname": "บำเหน็จณรงค์",
            "subdistrictcode": "360707",
            "subdistrictname": "โคกเพชรพัฒนา",
            "zipcode": "36160"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว",
            "subdistrictcode": "360801",
            "subdistrictname": "หนองบัวระเหว",
            "zipcode": "36250"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว",
            "subdistrictcode": "360802",
            "subdistrictname": "วังตะเฆ่",
            "zipcode": "36250"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว",
            "subdistrictcode": "360803",
            "subdistrictname": "ห้วยแย้",
            "zipcode": "36250"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว",
            "subdistrictcode": "360804",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "36250"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3608",
            "districtname": "หนองบัวระเหว",
            "subdistrictcode": "360805",
            "subdistrictname": "โสกปลาดุก",
            "zipcode": "36250"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3609",
            "districtname": "เทพสถิต",
            "subdistrictcode": "360901",
            "subdistrictname": "วะตะแบก",
            "zipcode": "36230"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3609",
            "districtname": "เทพสถิต",
            "subdistrictcode": "360902",
            "subdistrictname": "ห้วยยายจิ๋ว",
            "zipcode": "36230"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3609",
            "districtname": "เทพสถิต",
            "subdistrictcode": "360903",
            "subdistrictname": "นายางกลัก",
            "zipcode": "36230"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3609",
            "districtname": "เทพสถิต",
            "subdistrictcode": "360904",
            "subdistrictname": "บ้านไร่",
            "zipcode": "36230"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3609",
            "districtname": "เทพสถิต",
            "subdistrictcode": "360905",
            "subdistrictname": "โป่งนก",
            "zipcode": "36230"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361001",
            "subdistrictname": "ผักปัง",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361002",
            "subdistrictname": "กวางโจน",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361003",
            "subdistrictname": "หนองคอนไทย",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361004",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361005",
            "subdistrictname": "กุดยม",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361006",
            "subdistrictname": "บ้านเพชร",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361007",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361008",
            "subdistrictname": "หนองตูม",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361009",
            "subdistrictname": "โอโล",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361010",
            "subdistrictname": "ธาตุทอง",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3610",
            "districtname": "ภูเขียว",
            "subdistrictcode": "361011",
            "subdistrictname": "บ้านดอน",
            "zipcode": "36110"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3611",
            "districtname": "บ้านแท่น",
            "subdistrictcode": "361101",
            "subdistrictname": "บ้านแท่น",
            "zipcode": "36190"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3611",
            "districtname": "บ้านแท่น",
            "subdistrictcode": "361102",
            "subdistrictname": "สามสวน",
            "zipcode": "36190"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3611",
            "districtname": "บ้านแท่น",
            "subdistrictcode": "361103",
            "subdistrictname": "สระพัง",
            "zipcode": "36190"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3611",
            "districtname": "บ้านแท่น",
            "subdistrictcode": "361104",
            "subdistrictname": "บ้านเต่า",
            "zipcode": "36190"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3611",
            "districtname": "บ้านแท่น",
            "subdistrictcode": "361105",
            "subdistrictname": "หนองคู",
            "zipcode": "36190"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361201",
            "subdistrictname": "ช่องสามหมอ",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361202",
            "subdistrictname": "หนองขาม",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361203",
            "subdistrictname": "นาหนองทุ่ม",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361204",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361205",
            "subdistrictname": "หนองสังข์",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361206",
            "subdistrictname": "หลุบคา",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361207",
            "subdistrictname": "โคกกุง",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361208",
            "subdistrictname": "เก่าย่าดี",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361209",
            "subdistrictname": "ท่ามะไฟหวาน",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3612",
            "districtname": "แก้งคร้อ",
            "subdistrictcode": "361210",
            "subdistrictname": "หนองไผ่",
            "zipcode": "36150"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361301",
            "subdistrictname": "คอนสาร",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361302",
            "subdistrictname": "ทุ่งพระ",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361303",
            "subdistrictname": "โนนคูณ",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361304",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361305",
            "subdistrictname": "ทุ่งลุยลาย",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361306",
            "subdistrictname": "ดงบัง",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361307",
            "subdistrictname": "ทุ่งนาเลา",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3613",
            "districtname": "คอนสาร",
            "subdistrictcode": "361308",
            "subdistrictname": "ดงกลาง",
            "zipcode": "36180"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3614",
            "districtname": "ภักดีชุมพล",
            "subdistrictcode": "361401",
            "subdistrictname": "บ้านเจียง",
            "zipcode": "36260"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3614",
            "districtname": "ภักดีชุมพล",
            "subdistrictcode": "361402",
            "subdistrictname": "เจาทอง",
            "zipcode": "36260"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3614",
            "districtname": "ภักดีชุมพล",
            "subdistrictcode": "361403",
            "subdistrictname": "วังทอง",
            "zipcode": "36260"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3614",
            "districtname": "ภักดีชุมพล",
            "subdistrictcode": "361404",
            "subdistrictname": "แหลมทอง",
            "zipcode": "36260"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3615",
            "districtname": "เนินสง่า",
            "subdistrictcode": "361501",
            "subdistrictname": "หนองฉิม",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3615",
            "districtname": "เนินสง่า",
            "subdistrictcode": "361502",
            "subdistrictname": "ตาเนิน",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3615",
            "districtname": "เนินสง่า",
            "subdistrictcode": "361503",
            "subdistrictname": "กะฮาด",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3615",
            "districtname": "เนินสง่า",
            "subdistrictcode": "361504",
            "subdistrictname": "รังงาม",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3616",
            "districtname": "ซับใหญ่",
            "subdistrictcode": "361601",
            "subdistrictname": "ซับใหญ่",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3616",
            "districtname": "ซับใหญ่",
            "subdistrictcode": "361602",
            "subdistrictname": "ท่ากูบ",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "ชัยภูมิ",
            "districtcode": "3616",
            "districtname": "ซับใหญ่",
            "subdistrictcode": "361603",
            "subdistrictname": "ตะโกทอง",
            "zipcode": "36130"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370101",
            "subdistrictname": "บุ่ง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370102",
            "subdistrictname": "ไก่คำ",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370103",
            "subdistrictname": "นาจิก",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370104",
            "subdistrictname": "ปลาค้าว",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370105",
            "subdistrictname": "เหล่าพรวน",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370106",
            "subdistrictname": "สร้างนกทา",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370107",
            "subdistrictname": "คึมใหญ่",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370108",
            "subdistrictname": "นาผือ",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370109",
            "subdistrictname": "น้ำปลีก",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370110",
            "subdistrictname": "นาวัง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370111",
            "subdistrictname": "นาหมอม้า",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370112",
            "subdistrictname": "โนนโพธิ์",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370113",
            "subdistrictname": "โนนหนามแท่ง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370114",
            "subdistrictname": "ห้วยไร่",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370115",
            "subdistrictname": "หนองมะแซว",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370116",
            "subdistrictname": "กุดปลาดุก",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370117",
            "subdistrictname": "ดอนเมย",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370118",
            "subdistrictname": "นายม",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3701",
            "districtname": "เมืองอำนาจเจริญ",
            "subdistrictcode": "370119",
            "subdistrictname": "นาแต้",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3702",
            "districtname": "ชานุมาน",
            "subdistrictcode": "370201",
            "subdistrictname": "ชานุมาน",
            "zipcode": "37210"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3702",
            "districtname": "ชานุมาน",
            "subdistrictcode": "370202",
            "subdistrictname": "โคกสาร",
            "zipcode": "37210"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3702",
            "districtname": "ชานุมาน",
            "subdistrictcode": "370203",
            "subdistrictname": "คำเขื่อนแก้ว",
            "zipcode": "37210"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3702",
            "districtname": "ชานุมาน",
            "subdistrictcode": "370204",
            "subdistrictname": "โคกก่ง",
            "zipcode": "37210"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3702",
            "districtname": "ชานุมาน",
            "subdistrictcode": "370205",
            "subdistrictname": "ป่าก่อ",
            "zipcode": "37210"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370301",
            "subdistrictname": "หนองข่า",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370302",
            "subdistrictname": "คำโพน",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370303",
            "subdistrictname": "นาหว้า",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370304",
            "subdistrictname": "ลือ",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370305",
            "subdistrictname": "ห้วย",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370306",
            "subdistrictname": "โนนงาม",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3703",
            "districtname": "ปทุมราชวงศา",
            "subdistrictcode": "370307",
            "subdistrictname": "นาป่าแซง",
            "zipcode": "37110"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3704",
            "districtname": "พนา",
            "subdistrictcode": "370401",
            "subdistrictname": "พนา",
            "zipcode": "37180"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3704",
            "districtname": "พนา",
            "subdistrictcode": "370402",
            "subdistrictname": "จานลาน",
            "zipcode": "37180"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3704",
            "districtname": "พนา",
            "subdistrictcode": "370403",
            "subdistrictname": "ไม้กลอน",
            "zipcode": "37180"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3704",
            "districtname": "พนา",
            "subdistrictcode": "370404",
            "subdistrictname": "พระเหลา",
            "zipcode": "37180"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370501",
            "subdistrictname": "เสนางคนิคม",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370502",
            "subdistrictname": "โพนทอง",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370503",
            "subdistrictname": "ไร่สีสุก",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370504",
            "subdistrictname": "นาเวียง",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370505",
            "subdistrictname": "หนองไฮ",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3705",
            "districtname": "เสนางคนิคม",
            "subdistrictcode": "370506",
            "subdistrictname": "หนองสามสี",
            "zipcode": "37290"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370601",
            "subdistrictname": "หัวตะพาน",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370602",
            "subdistrictname": "คำพระ",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370603",
            "subdistrictname": "เค็งใหญ่",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370604",
            "subdistrictname": "หนองแก้ว",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370605",
            "subdistrictname": "โพนเมืองน้อย",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370606",
            "subdistrictname": "สร้างถ่อน้อย",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370607",
            "subdistrictname": "จิกดู่",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3706",
            "districtname": "หัวตะพาน",
            "subdistrictcode": "370608",
            "subdistrictname": "รัตนวารี",
            "zipcode": "37240"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370701",
            "subdistrictname": "อำนาจ",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370702",
            "subdistrictname": "ดงมะยาง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370703",
            "subdistrictname": "เปือย",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370704",
            "subdistrictname": "ดงบัง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370705",
            "subdistrictname": "ไร่ขี",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370706",
            "subdistrictname": "แมด",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "อำนาจเจริญ",
            "districtcode": "3707",
            "districtname": "ลืออำนาจ",
            "subdistrictcode": "370707",
            "subdistrictname": "โคกกลาง",
            "zipcode": "37000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390101",
            "subdistrictname": "หนองบัว",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390102",
            "subdistrictname": "หนองภัยศูนย์",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390103",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390104",
            "subdistrictname": "หนองสวรรค์",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390105",
            "subdistrictname": "หัวนา",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390106",
            "subdistrictname": "บ้านขาม",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390107",
            "subdistrictname": "นามะเฟือง",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390108",
            "subdistrictname": "บ้านพร้าว",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390109",
            "subdistrictname": "โนนขมิ้น",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390110",
            "subdistrictname": "ลำภู",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390111",
            "subdistrictname": "กุดจิก",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390112",
            "subdistrictname": "โนนทัน",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390113",
            "subdistrictname": "นาคำไฮ",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390114",
            "subdistrictname": "ป่าไม้งาม",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3901",
            "districtname": "เมืองหนองบัวลำภู",
            "subdistrictcode": "390115",
            "subdistrictname": "หนองหว้า",
            "zipcode": "39000"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390201",
            "subdistrictname": "นากลาง",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390202",
            "subdistrictname": "ด่านช้าง",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390205",
            "subdistrictname": "กุดดินจี่",
            "zipcode": "39350"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390206",
            "subdistrictname": "ฝั่งแดง",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390207",
            "subdistrictname": "เก่ากลอย",
            "zipcode": "39350"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390209",
            "subdistrictname": "โนนเมือง",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390210",
            "subdistrictname": "อุทัยสวรรค์",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390211",
            "subdistrictname": "ดงสวรรค์",
            "zipcode": "39350"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3902",
            "districtname": "นากลาง",
            "subdistrictcode": "390213",
            "subdistrictname": "กุดแห่",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390301",
            "subdistrictname": "โนนสัง",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390302",
            "subdistrictname": "บ้านถิ่น",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390303",
            "subdistrictname": "หนองเรือ",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390304",
            "subdistrictname": "กุดดู่",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390305",
            "subdistrictname": "บ้านค้อ",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390306",
            "subdistrictname": "โนนเมือง",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390307",
            "subdistrictname": "โคกใหญ่",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390308",
            "subdistrictname": "โคกม่วง",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390309",
            "subdistrictname": "นิคมพัฒนา",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3903",
            "districtname": "โนนสัง",
            "subdistrictcode": "390310",
            "subdistrictname": "ปางกู่",
            "zipcode": "39140"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390401",
            "subdistrictname": "เมืองใหม่",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390402",
            "subdistrictname": "ศรีบุญเรือง",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390403",
            "subdistrictname": "หนองบัวใต้",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390404",
            "subdistrictname": "กุดสะเทียน",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390405",
            "subdistrictname": "นากอก",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390406",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390407",
            "subdistrictname": "ยางหล่อ",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390408",
            "subdistrictname": "โนนม่วง",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390409",
            "subdistrictname": "หนองกุงแก้ว",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390410",
            "subdistrictname": "หนองแก",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390411",
            "subdistrictname": "ทรายทอง",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3904",
            "districtname": "ศรีบุญเรือง",
            "subdistrictcode": "390412",
            "subdistrictname": "หันนางาม",
            "zipcode": "39180"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390501",
            "subdistrictname": "นาสี",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390502",
            "subdistrictname": "บ้านโคก",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390503",
            "subdistrictname": "นาดี",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390504",
            "subdistrictname": "นาด่าน",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390505",
            "subdistrictname": "ดงมะไฟ",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390506",
            "subdistrictname": "สุวรรณคูหา",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390507",
            "subdistrictname": "บุญทัน",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3905",
            "districtname": "สุวรรณคูหา",
            "subdistrictcode": "390508",
            "subdistrictname": "กุดผึ้ง",
            "zipcode": "39270"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3906",
            "districtname": "นาวัง",
            "subdistrictcode": "390601",
            "subdistrictname": "นาเหล่า",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3906",
            "districtname": "นาวัง",
            "subdistrictcode": "390602",
            "subdistrictname": "นาแก",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3906",
            "districtname": "นาวัง",
            "subdistrictcode": "390603",
            "subdistrictname": "วังทอง",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3906",
            "districtname": "นาวัง",
            "subdistrictcode": "390604",
            "subdistrictname": "วังปลาป้อม",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "หนองบัวลำภู",
            "districtcode": "3906",
            "districtname": "นาวัง",
            "subdistrictcode": "390605",
            "subdistrictname": "เทพคีรี",
            "zipcode": "39170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400101",
            "subdistrictname": "ในเมือง",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400102",
            "subdistrictname": "สำราญ",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400103",
            "subdistrictname": "โคกสี",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400104",
            "subdistrictname": "ท่าพระ",
            "zipcode": "40260"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400105",
            "subdistrictname": "บ้านทุ่ม",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400106",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400107",
            "subdistrictname": "พระลับ",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400108",
            "subdistrictname": "สาวะถี",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400109",
            "subdistrictname": "บ้านหว้า",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400110",
            "subdistrictname": "บ้านค้อ",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400111",
            "subdistrictname": "แดงใหญ่",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400112",
            "subdistrictname": "ดอนช้าง",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400113",
            "subdistrictname": "ดอนหัน",
            "zipcode": "40260"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400114",
            "subdistrictname": "ศิลา",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400115",
            "subdistrictname": "บ้านเป็ด",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400116",
            "subdistrictname": "หนองตูม",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400117",
            "subdistrictname": "บึงเนียม",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4001",
            "districtname": "เมืองขอนแก่น",
            "subdistrictcode": "400118",
            "subdistrictname": "โนนท่อน",
            "zipcode": "40000"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400201",
            "subdistrictname": "หนองบัว",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400202",
            "subdistrictname": "ป่าหวายนั่ง",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400203",
            "subdistrictname": "โนนฆ้อง",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400204",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400205",
            "subdistrictname": "ป่ามะนาว",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400206",
            "subdistrictname": "บ้านฝาง",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4002",
            "districtname": "บ้านฝาง",
            "subdistrictcode": "400207",
            "subdistrictname": "โคกงาม",
            "zipcode": "40270"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4003",
            "districtname": "พระยืน",
            "subdistrictcode": "400301",
            "subdistrictname": "พระยืน",
            "zipcode": "40320"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4003",
            "districtname": "พระยืน",
            "subdistrictcode": "400302",
            "subdistrictname": "พระบุ",
            "zipcode": "40320"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4003",
            "districtname": "พระยืน",
            "subdistrictcode": "400303",
            "subdistrictname": "บ้านโต้น",
            "zipcode": "40320"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4003",
            "districtname": "พระยืน",
            "subdistrictcode": "400304",
            "subdistrictname": "หนองแวง",
            "zipcode": "40320"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4003",
            "districtname": "พระยืน",
            "subdistrictcode": "400305",
            "subdistrictname": "ขามป้อม",
            "zipcode": "40320"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400401",
            "subdistrictname": "หนองเรือ",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400402",
            "subdistrictname": "บ้านเม็ง",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400403",
            "subdistrictname": "บ้านกง",
            "zipcode": "40240"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400404",
            "subdistrictname": "ยางคำ",
            "zipcode": "40240"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400405",
            "subdistrictname": "จระเข้",
            "zipcode": "40240"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400406",
            "subdistrictname": "โนนทอง",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400407",
            "subdistrictname": "กุดกว้าง",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400408",
            "subdistrictname": "โนนทัน",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400409",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "40210"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4004",
            "districtname": "หนองเรือ",
            "subdistrictcode": "400410",
            "subdistrictname": "บ้านผือ",
            "zipcode": "40240"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400501",
            "subdistrictname": "ชุมแพ",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400502",
            "subdistrictname": "โนนหัน",
            "zipcode": "40290"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400503",
            "subdistrictname": "นาหนองทุ่ม",
            "zipcode": "40290"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400504",
            "subdistrictname": "โนนอุดม",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400505",
            "subdistrictname": "ขัวเรียง",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400506",
            "subdistrictname": "หนองไผ่",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400507",
            "subdistrictname": "ไชยสอ",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400508",
            "subdistrictname": "วังหินลาด",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400509",
            "subdistrictname": "นาเพียง",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400510",
            "subdistrictname": "หนองเขียด",
            "zipcode": "40290"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400511",
            "subdistrictname": "หนองเสาเล้า",
            "zipcode": "40130"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4005",
            "districtname": "ชุมแพ",
            "subdistrictcode": "400512",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "40290"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400601",
            "subdistrictname": "สีชมพู",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400602",
            "subdistrictname": "ศรีสุข",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400603",
            "subdistrictname": "นาจาน",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400604",
            "subdistrictname": "วังเพิ่ม",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400605",
            "subdistrictname": "ซำยาง",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400606",
            "subdistrictname": "หนองแดง",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400607",
            "subdistrictname": "ดงลาน",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400608",
            "subdistrictname": "บริบูรณ์",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400609",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4006",
            "districtname": "สีชมพู",
            "subdistrictcode": "400610",
            "subdistrictname": "ภูห่าน",
            "zipcode": "40220"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400701",
            "subdistrictname": "น้ำพอง",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400702",
            "subdistrictname": "วังชัย",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400703",
            "subdistrictname": "หนองกุง",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400704",
            "subdistrictname": "บัวใหญ่",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400705",
            "subdistrictname": "สะอาด",
            "zipcode": "40310"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400706",
            "subdistrictname": "ม่วงหวาน",
            "zipcode": "40310"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400707",
            "subdistrictname": "บ้านขาม",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400708",
            "subdistrictname": "บัวเงิน",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400709",
            "subdistrictname": "ทรายมูล",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400710",
            "subdistrictname": "ท่ากระเสริม",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400711",
            "subdistrictname": "พังทุย",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4007",
            "districtname": "น้ำพอง",
            "subdistrictcode": "400712",
            "subdistrictname": "กุดน้ำใส",
            "zipcode": "40140"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400801",
            "subdistrictname": "โคกสูง",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400802",
            "subdistrictname": "บ้านดง",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400803",
            "subdistrictname": "เขื่อนอุบลรัตน์",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400804",
            "subdistrictname": "นาคำ",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400805",
            "subdistrictname": "ศรีสุขสำราญ",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4008",
            "districtname": "อุบลรัตน์",
            "subdistrictcode": "400806",
            "subdistrictname": "ทุ่งโป่ง",
            "zipcode": "40250"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400901",
            "subdistrictname": "หนองโก",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400902",
            "subdistrictname": "หนองกุงใหญ่",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400905",
            "subdistrictname": "ห้วยโจด",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400906",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400907",
            "subdistrictname": "บ้านฝาง",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400909",
            "subdistrictname": "ดูนสาด",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400910",
            "subdistrictname": "หนองโน",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400911",
            "subdistrictname": "น้ำอ้อม",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4009",
            "districtname": "กระนวน",
            "subdistrictcode": "400912",
            "subdistrictname": "หัวนาคำ",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401001",
            "subdistrictname": "บ้านไผ่",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401002",
            "subdistrictname": "ในเมือง",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401005",
            "subdistrictname": "เมืองเพีย",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401009",
            "subdistrictname": "บ้านลาน",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401010",
            "subdistrictname": "แคนเหนือ",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401011",
            "subdistrictname": "ภูเหล็ก",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401013",
            "subdistrictname": "ป่าปอ",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401014",
            "subdistrictname": "หินตั้ง",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401016",
            "subdistrictname": "หนองน้ำใส",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4010",
            "districtname": "บ้านไผ่",
            "subdistrictcode": "401017",
            "subdistrictname": "หัวหนอง",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4011",
            "districtname": "เปือยน้อย",
            "subdistrictcode": "401101",
            "subdistrictname": "เปือยน้อย",
            "zipcode": "40340"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4011",
            "districtname": "เปือยน้อย",
            "subdistrictcode": "401102",
            "subdistrictname": "วังม่วง",
            "zipcode": "40340"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4011",
            "districtname": "เปือยน้อย",
            "subdistrictcode": "401103",
            "subdistrictname": "ขามป้อม",
            "zipcode": "40340"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4011",
            "districtname": "เปือยน้อย",
            "subdistrictcode": "401104",
            "subdistrictname": "สระแก้ว",
            "zipcode": "40340"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401201",
            "subdistrictname": "เมืองพล",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401203",
            "subdistrictname": "โจดหนองแก",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401204",
            "subdistrictname": "เก่างิ้ว",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401205",
            "subdistrictname": "หนองมะเขือ",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401206",
            "subdistrictname": "หนองแวงโสกพระ",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401207",
            "subdistrictname": "เพ็กใหญ่",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401208",
            "subdistrictname": "โคกสง่า",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401209",
            "subdistrictname": "หนองแวงนางเบ้า",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401210",
            "subdistrictname": "ลอมคอม",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401211",
            "subdistrictname": "โนนข่า",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401212",
            "subdistrictname": "โสกนกเต็น",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4012",
            "districtname": "พล",
            "subdistrictcode": "401213",
            "subdistrictname": "หัวทุ่ง",
            "zipcode": "40120"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4013",
            "districtname": "แวงใหญ่",
            "subdistrictcode": "401301",
            "subdistrictname": "คอนฉิม",
            "zipcode": "40330"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4013",
            "districtname": "แวงใหญ่",
            "subdistrictcode": "401302",
            "subdistrictname": "ใหม่นาเพียง",
            "zipcode": "40330"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4013",
            "districtname": "แวงใหญ่",
            "subdistrictcode": "401303",
            "subdistrictname": "โนนทอง",
            "zipcode": "40330"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4013",
            "districtname": "แวงใหญ่",
            "subdistrictcode": "401304",
            "subdistrictname": "แวงใหญ่",
            "zipcode": "40330"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4013",
            "districtname": "แวงใหญ่",
            "subdistrictcode": "401305",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "40330"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401401",
            "subdistrictname": "แวงน้อย",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401402",
            "subdistrictname": "ก้านเหลือง",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401403",
            "subdistrictname": "ท่านางแนว",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401404",
            "subdistrictname": "ละหานนา",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401405",
            "subdistrictname": "ท่าวัด",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4014",
            "districtname": "แวงน้อย",
            "subdistrictcode": "401406",
            "subdistrictname": "ทางขวาง",
            "zipcode": "40230"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401501",
            "subdistrictname": "หนองสองห้อง",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401502",
            "subdistrictname": "คึมชาด",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401503",
            "subdistrictname": "โนนธาตุ",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401504",
            "subdistrictname": "ตะกั่วป่า",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401505",
            "subdistrictname": "สำโรง",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401506",
            "subdistrictname": "หนองเม็ก",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401507",
            "subdistrictname": "ดอนดู่",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401508",
            "subdistrictname": "ดงเค็ง",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401509",
            "subdistrictname": "หันโจด",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401510",
            "subdistrictname": "ดอนดั่ง",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401511",
            "subdistrictname": "วังหิน",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4015",
            "districtname": "หนองสองห้อง",
            "subdistrictcode": "401512",
            "subdistrictname": "หนองไผ่ล้อม",
            "zipcode": "40190"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401601",
            "subdistrictname": "บ้านเรือ",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401602",
            "subdistrictname": "ในเมือง",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401604",
            "subdistrictname": "หว้าทอง",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401605",
            "subdistrictname": "กุดขอนแก่น",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401606",
            "subdistrictname": "นาชุมแสง",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401607",
            "subdistrictname": "นาหว้า",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401608",
            "subdistrictname": "เขาน้อย",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401610",
            "subdistrictname": "หนองกุงธนสาร",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401612",
            "subdistrictname": "หนองกุงเซิน",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401613",
            "subdistrictname": "สงเปือย",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401614",
            "subdistrictname": "ทุ่งชมพู",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401615",
            "subdistrictname": "เมืองเก่าพัฒนา",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401616",
            "subdistrictname": "ดินดำ",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4016",
            "districtname": "ภูเวียง",
            "subdistrictcode": "401617",
            "subdistrictname": "ภูเวียง",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401701",
            "subdistrictname": "กุดเค้า",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401702",
            "subdistrictname": "สวนหม่อน",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401703",
            "subdistrictname": "หนองแปน",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401704",
            "subdistrictname": "โพนเพ็ก",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401705",
            "subdistrictname": "คำแคน",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401706",
            "subdistrictname": "นาข่า",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401707",
            "subdistrictname": "นางาม",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4017",
            "districtname": "มัญจาคีรี",
            "subdistrictcode": "401710",
            "subdistrictname": "ท่าศาลา",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401801",
            "subdistrictname": "ชนบท",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401802",
            "subdistrictname": "กุดเพียขอม",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401803",
            "subdistrictname": "วังแสง",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401804",
            "subdistrictname": "ห้วยแก",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401805",
            "subdistrictname": "บ้านแท่น",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401806",
            "subdistrictname": "ศรีบุญเรือง",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401807",
            "subdistrictname": "โนนพะยอม",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4018",
            "districtname": "ชนบท",
            "subdistrictcode": "401808",
            "subdistrictname": "ปอแดง",
            "zipcode": "40180"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง",
            "subdistrictcode": "401901",
            "subdistrictname": "เขาสวนกวาง",
            "zipcode": "40280"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง",
            "subdistrictcode": "401902",
            "subdistrictname": "ดงเมืองแอม",
            "zipcode": "40280"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง",
            "subdistrictcode": "401903",
            "subdistrictname": "นางิ้ว",
            "zipcode": "40280"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง",
            "subdistrictcode": "401904",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "40280"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4019",
            "districtname": "เขาสวนกวาง",
            "subdistrictcode": "401905",
            "subdistrictname": "คำม่วง",
            "zipcode": "40280"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน",
            "subdistrictcode": "402001",
            "subdistrictname": "โนนคอม",
            "zipcode": "40350"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน",
            "subdistrictcode": "402002",
            "subdistrictname": "นาฝาย",
            "zipcode": "40350"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน",
            "subdistrictcode": "402003",
            "subdistrictname": "ภูผาม่าน",
            "zipcode": "40350"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน",
            "subdistrictcode": "402004",
            "subdistrictname": "วังสวาบ",
            "zipcode": "40350"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4020",
            "districtname": "ภูผาม่าน",
            "subdistrictcode": "402005",
            "subdistrictname": "ห้วยม่วง",
            "zipcode": "40350"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4021",
            "districtname": "ซำสูง",
            "subdistrictcode": "402101",
            "subdistrictname": "กระนวน",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4021",
            "districtname": "ซำสูง",
            "subdistrictcode": "402102",
            "subdistrictname": "คำแมด",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4021",
            "districtname": "ซำสูง",
            "subdistrictcode": "402103",
            "subdistrictname": "บ้านโนน",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4021",
            "districtname": "ซำสูง",
            "subdistrictcode": "402104",
            "subdistrictname": "คูคำ",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4021",
            "districtname": "ซำสูง",
            "subdistrictcode": "402105",
            "subdistrictname": "ห้วยเตย",
            "zipcode": "40170"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4022",
            "districtname": "โคกโพธิ์ไชย",
            "subdistrictcode": "402201",
            "subdistrictname": "บ้านโคก",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4022",
            "districtname": "โคกโพธิ์ไชย",
            "subdistrictcode": "402202",
            "subdistrictname": "โพธิ์ไชย",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4022",
            "districtname": "โคกโพธิ์ไชย",
            "subdistrictcode": "402203",
            "subdistrictname": "ซับสมบูรณ์",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4022",
            "districtname": "โคกโพธิ์ไชย",
            "subdistrictcode": "402204",
            "subdistrictname": "นาแพง",
            "zipcode": "40160"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4023",
            "districtname": "หนองนาคำ",
            "subdistrictcode": "402301",
            "subdistrictname": "กุดธาตุ",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4023",
            "districtname": "หนองนาคำ",
            "subdistrictcode": "402302",
            "subdistrictname": "บ้านโคก",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4023",
            "districtname": "หนองนาคำ",
            "subdistrictcode": "402303",
            "subdistrictname": "ขนวน",
            "zipcode": "40150"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4024",
            "districtname": "บ้านแฮด",
            "subdistrictcode": "402401",
            "subdistrictname": "บ้านแฮด",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4024",
            "districtname": "บ้านแฮด",
            "subdistrictcode": "402402",
            "subdistrictname": "โคกสำราญ",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4024",
            "districtname": "บ้านแฮด",
            "subdistrictcode": "402403",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4024",
            "districtname": "บ้านแฮด",
            "subdistrictcode": "402404",
            "subdistrictname": "หนองแซง",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4025",
            "districtname": "โนนศิลา",
            "subdistrictcode": "402501",
            "subdistrictname": "โนนศิลา",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4025",
            "districtname": "โนนศิลา",
            "subdistrictcode": "402502",
            "subdistrictname": "หนองปลาหมอ",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4025",
            "districtname": "โนนศิลา",
            "subdistrictcode": "402503",
            "subdistrictname": "บ้านหัน",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4025",
            "districtname": "โนนศิลา",
            "subdistrictcode": "402504",
            "subdistrictname": "เปือยใหญ่",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "ขอนแก่น",
            "districtcode": "4025",
            "districtname": "โนนศิลา",
            "subdistrictcode": "402505",
            "subdistrictname": "โนนแดง",
            "zipcode": "40110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410101",
            "subdistrictname": "หมากแข้ง",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410102",
            "subdistrictname": "นิคมสงเคราะห์",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410103",
            "subdistrictname": "บ้านขาว",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410104",
            "subdistrictname": "หนองบัว",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410105",
            "subdistrictname": "บ้านตาด",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410106",
            "subdistrictname": "โนนสูง",
            "zipcode": "41330"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410107",
            "subdistrictname": "หมูม่น",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410108",
            "subdistrictname": "เชียงยืน",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410109",
            "subdistrictname": "หนองนาคำ",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410110",
            "subdistrictname": "กุดสระ",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410111",
            "subdistrictname": "นาดี",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410112",
            "subdistrictname": "บ้านเลื่อม",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410113",
            "subdistrictname": "เชียงพิณ",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410114",
            "subdistrictname": "สามพร้าว",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410115",
            "subdistrictname": "หนองไฮ",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410116",
            "subdistrictname": "นาข่า",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410117",
            "subdistrictname": "บ้านจั่น",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410118",
            "subdistrictname": "หนองขอนกว้าง",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410119",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410120",
            "subdistrictname": "นากว้าง",
            "zipcode": "41000"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4101",
            "districtname": "เมืองอุดรธานี",
            "subdistrictcode": "410121",
            "subdistrictname": "หนองไผ่",
            "zipcode": "41330"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410201",
            "subdistrictname": "กุดจับ",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410202",
            "subdistrictname": "ปะโค",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410203",
            "subdistrictname": "ขอนยูง",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410204",
            "subdistrictname": "เชียงเพ็ง",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410205",
            "subdistrictname": "สร้างก่อ",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410206",
            "subdistrictname": "เมืองเพีย",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4102",
            "districtname": "กุดจับ",
            "subdistrictcode": "410207",
            "subdistrictname": "ตาลเลียน",
            "zipcode": "41250"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410301",
            "subdistrictname": "หมากหญ้า",
            "zipcode": "41360"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410302",
            "subdistrictname": "หนองอ้อ",
            "zipcode": "41220"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410303",
            "subdistrictname": "อูบมุง",
            "zipcode": "41220"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410304",
            "subdistrictname": "กุดหมากไฟ",
            "zipcode": "41220"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410305",
            "subdistrictname": "น้ำพ่น",
            "zipcode": "41360"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410306",
            "subdistrictname": "หนองบัวบาน",
            "zipcode": "41360"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410307",
            "subdistrictname": "โนนหวาย",
            "zipcode": "41220"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4103",
            "districtname": "หนองวัวซอ",
            "subdistrictcode": "410308",
            "subdistrictname": "หนองวัวซอ",
            "zipcode": "41360"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410401",
            "subdistrictname": "ตูมใต้",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410402",
            "subdistrictname": "พันดอน",
            "zipcode": "41370"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410403",
            "subdistrictname": "เวียงคำ",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410404",
            "subdistrictname": "แชแล",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410406",
            "subdistrictname": "เชียงแหว",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410407",
            "subdistrictname": "ห้วยเกิ้ง",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410409",
            "subdistrictname": "เสอเพลอ",
            "zipcode": "41370"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410410",
            "subdistrictname": "สีออ",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410411",
            "subdistrictname": "ปะโค",
            "zipcode": "41370"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410413",
            "subdistrictname": "ผาสุก",
            "zipcode": "41370"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410414",
            "subdistrictname": "ท่าลี่",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410415",
            "subdistrictname": "กุมภวาปี",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4104",
            "districtname": "กุมภวาปี",
            "subdistrictcode": "410416",
            "subdistrictname": "หนองหว้า",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410501",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410502",
            "subdistrictname": "บุ่งแก้ว",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410503",
            "subdistrictname": "โพธิ์ศรีสำราญ",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410504",
            "subdistrictname": "ทมนางาม",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410505",
            "subdistrictname": "หนองกุงศรี",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4105",
            "districtname": "โนนสะอาด",
            "subdistrictcode": "410506",
            "subdistrictname": "โคกกลาง",
            "zipcode": "41240"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410601",
            "subdistrictname": "หนองหาน",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410602",
            "subdistrictname": "หนองเม็ก",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410605",
            "subdistrictname": "พังงู",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410606",
            "subdistrictname": "สะแบง",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410607",
            "subdistrictname": "สร้อยพร้าว",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410609",
            "subdistrictname": "บ้านเชียง",
            "zipcode": "41320"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410610",
            "subdistrictname": "บ้านยา",
            "zipcode": "41320"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410611",
            "subdistrictname": "โพนงาม",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410612",
            "subdistrictname": "ผักตบ",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410614",
            "subdistrictname": "หนองไผ่",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410617",
            "subdistrictname": "ดอนหายโศก",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4106",
            "districtname": "หนองหาน",
            "subdistrictcode": "410618",
            "subdistrictname": "หนองสระปลา",
            "zipcode": "41320"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4107",
            "districtname": "ทุ่งฝน",
            "subdistrictcode": "410701",
            "subdistrictname": "ทุ่งฝน",
            "zipcode": "41310"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4107",
            "districtname": "ทุ่งฝน",
            "subdistrictcode": "410702",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "41310"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4107",
            "districtname": "ทุ่งฝน",
            "subdistrictcode": "410703",
            "subdistrictname": "นาชุมแสง",
            "zipcode": "41310"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4107",
            "districtname": "ทุ่งฝน",
            "subdistrictcode": "410704",
            "subdistrictname": "นาทม",
            "zipcode": "41310"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4108",
            "districtname": "ไชยวาน",
            "subdistrictcode": "410801",
            "subdistrictname": "ไชยวาน",
            "zipcode": "41290"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4108",
            "districtname": "ไชยวาน",
            "subdistrictcode": "410802",
            "subdistrictname": "หนองหลัก",
            "zipcode": "41290"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4108",
            "districtname": "ไชยวาน",
            "subdistrictcode": "410803",
            "subdistrictname": "คำเลาะ",
            "zipcode": "41290"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4108",
            "districtname": "ไชยวาน",
            "subdistrictcode": "410804",
            "subdistrictname": "โพนสูง",
            "zipcode": "41290"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410901",
            "subdistrictname": "ศรีธาตุ",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410902",
            "subdistrictname": "จำปี",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410903",
            "subdistrictname": "บ้านโปร่ง",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410904",
            "subdistrictname": "หัวนาคำ",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410905",
            "subdistrictname": "หนองนกเขียน",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410906",
            "subdistrictname": "นายูง",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4109",
            "districtname": "ศรีธาตุ",
            "subdistrictcode": "410907",
            "subdistrictname": "ตาดทอง",
            "zipcode": "41230"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411001",
            "subdistrictname": "หนองกุงทับม้า",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411002",
            "subdistrictname": "หนองหญ้าไซ",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411003",
            "subdistrictname": "บะยาว",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411004",
            "subdistrictname": "ผาสุก",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411005",
            "subdistrictname": "คำโคกสูง",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4110",
            "districtname": "วังสามหมอ",
            "subdistrictcode": "411006",
            "subdistrictname": "วังสามหมอ",
            "zipcode": "41280"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411101",
            "subdistrictname": "ศรีสุทโธ",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411102",
            "subdistrictname": "บ้านดุง",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411103",
            "subdistrictname": "ดงเย็น",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411104",
            "subdistrictname": "โพนสูง",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411105",
            "subdistrictname": "อ้อมกอ",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411106",
            "subdistrictname": "บ้านจันทน์",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411107",
            "subdistrictname": "บ้านชัย",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411108",
            "subdistrictname": "นาไหม",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411109",
            "subdistrictname": "ถ่อนนาลับ",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411110",
            "subdistrictname": "วังทอง",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411111",
            "subdistrictname": "บ้านม่วง",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411112",
            "subdistrictname": "บ้านตาด",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4111",
            "districtname": "บ้านดุง",
            "subdistrictcode": "411113",
            "subdistrictname": "นาคำ",
            "zipcode": "41190"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411701",
            "subdistrictname": "บ้านผือ",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411702",
            "subdistrictname": "หายโศก",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411703",
            "subdistrictname": "เขือน้ำ",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411704",
            "subdistrictname": "คำบง",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411705",
            "subdistrictname": "โนนทอง",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411706",
            "subdistrictname": "ข้าวสาร",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411707",
            "subdistrictname": "จำปาโมง",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411708",
            "subdistrictname": "กลางใหญ่",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411709",
            "subdistrictname": "เมืองพาน",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411710",
            "subdistrictname": "คำด้วง",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411711",
            "subdistrictname": "หนองหัวคู",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411712",
            "subdistrictname": "บ้านค้อ",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4117",
            "districtname": "บ้านผือ",
            "subdistrictcode": "411713",
            "subdistrictname": "หนองแวง",
            "zipcode": "41160"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411801",
            "subdistrictname": "นางัว",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411802",
            "subdistrictname": "น้ำโสม",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411805",
            "subdistrictname": "หนองแวง",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411806",
            "subdistrictname": "บ้านหยวก",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411807",
            "subdistrictname": "โสมเยี่ยม",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411810",
            "subdistrictname": "ศรีสำราญ",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4118",
            "districtname": "น้ำโสม",
            "subdistrictcode": "411812",
            "subdistrictname": "สามัคคี",
            "zipcode": "41210"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411901",
            "subdistrictname": "เพ็ญ",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411902",
            "subdistrictname": "บ้านธาตุ",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411903",
            "subdistrictname": "นาพู่",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411904",
            "subdistrictname": "เชียงหวาง",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411905",
            "subdistrictname": "สุมเส้า",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411906",
            "subdistrictname": "นาบัว",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411907",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411908",
            "subdistrictname": "จอมศรี",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411909",
            "subdistrictname": "เตาไห",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411910",
            "subdistrictname": "โคกกลาง",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4119",
            "districtname": "เพ็ญ",
            "subdistrictcode": "411911",
            "subdistrictname": "สร้างแป้น",
            "zipcode": "41150"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412001",
            "subdistrictname": "สร้างคอม",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412002",
            "subdistrictname": "เชียงดา",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412003",
            "subdistrictname": "บ้านยวด",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412004",
            "subdistrictname": "บ้านโคก",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412005",
            "subdistrictname": "นาสะอาด",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4120",
            "districtname": "สร้างคอม",
            "subdistrictcode": "412006",
            "subdistrictname": "บ้านหินโงม",
            "zipcode": "41260"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4121",
            "districtname": "หนองแสง",
            "subdistrictcode": "412101",
            "subdistrictname": "หนองแสง",
            "zipcode": "41340"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4121",
            "districtname": "หนองแสง",
            "subdistrictcode": "412102",
            "subdistrictname": "แสงสว่าง",
            "zipcode": "41340"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4121",
            "districtname": "หนองแสง",
            "subdistrictcode": "412103",
            "subdistrictname": "นาดี",
            "zipcode": "41340"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4121",
            "districtname": "หนองแสง",
            "subdistrictcode": "412104",
            "subdistrictname": "ทับกุง",
            "zipcode": "41340"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4122",
            "districtname": "นายูง",
            "subdistrictcode": "412201",
            "subdistrictname": "นายูง",
            "zipcode": "41380"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4122",
            "districtname": "นายูง",
            "subdistrictcode": "412202",
            "subdistrictname": "บ้านก้อง",
            "zipcode": "41380"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4122",
            "districtname": "นายูง",
            "subdistrictcode": "412203",
            "subdistrictname": "นาแค",
            "zipcode": "41380"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4122",
            "districtname": "นายูง",
            "subdistrictcode": "412204",
            "subdistrictname": "โนนทอง",
            "zipcode": "41380"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4123",
            "districtname": "พิบูลย์รักษ์",
            "subdistrictcode": "412301",
            "subdistrictname": "บ้านแดง",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4123",
            "districtname": "พิบูลย์รักษ์",
            "subdistrictcode": "412302",
            "subdistrictname": "นาทราย",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4123",
            "districtname": "พิบูลย์รักษ์",
            "subdistrictcode": "412303",
            "subdistrictname": "ดอนกลอย",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4124",
            "districtname": "กู่แก้ว",
            "subdistrictcode": "412401",
            "subdistrictname": "บ้านจีต",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4124",
            "districtname": "กู่แก้ว",
            "subdistrictcode": "412402",
            "subdistrictname": "โนนทองอินทร์",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4124",
            "districtname": "กู่แก้ว",
            "subdistrictcode": "412403",
            "subdistrictname": "ค้อใหญ่",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4124",
            "districtname": "กู่แก้ว",
            "subdistrictcode": "412404",
            "subdistrictname": "คอนสาย",
            "zipcode": "41130"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4125",
            "districtname": "ประจักษ์ศิลปาคม",
            "subdistrictcode": "412501",
            "subdistrictname": "นาม่วง",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4125",
            "districtname": "ประจักษ์ศิลปาคม",
            "subdistrictcode": "412502",
            "subdistrictname": "ห้วยสามพาด",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "อุดรธานี",
            "districtcode": "4125",
            "districtname": "ประจักษ์ศิลปาคม",
            "subdistrictcode": "412503",
            "subdistrictname": "อุ่มจาน",
            "zipcode": "41110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420101",
            "subdistrictname": "กุดป่อง",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420102",
            "subdistrictname": "เมือง",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420103",
            "subdistrictname": "นาอ้อ",
            "zipcode": "42100"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420104",
            "subdistrictname": "กกดู่",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420105",
            "subdistrictname": "น้ำหมาน",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420106",
            "subdistrictname": "เสี้ยว",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420107",
            "subdistrictname": "นาอาน",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420108",
            "subdistrictname": "นาโป่ง",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420109",
            "subdistrictname": "นาดินดำ",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420110",
            "subdistrictname": "น้ำสวย",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420111",
            "subdistrictname": "ชัยพฤกษ์",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420112",
            "subdistrictname": "นาแขม",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420113",
            "subdistrictname": "ศรีสองรัก",
            "zipcode": "42100"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4201",
            "districtname": "เมืองเลย",
            "subdistrictcode": "420114",
            "subdistrictname": "กกทอง",
            "zipcode": "42000"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4202",
            "districtname": "นาด้วง",
            "subdistrictcode": "420201",
            "subdistrictname": "นาด้วง",
            "zipcode": "42210"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4202",
            "districtname": "นาด้วง",
            "subdistrictcode": "420202",
            "subdistrictname": "นาดอกคำ",
            "zipcode": "42210"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4202",
            "districtname": "นาด้วง",
            "subdistrictcode": "420203",
            "subdistrictname": "ท่าสะอาด",
            "zipcode": "42210"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4202",
            "districtname": "นาด้วง",
            "subdistrictcode": "420204",
            "subdistrictname": "ท่าสวรรค์",
            "zipcode": "42210"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420301",
            "subdistrictname": "เชียงคาน",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420302",
            "subdistrictname": "ธาตุ",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420303",
            "subdistrictname": "นาซ่าว",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420304",
            "subdistrictname": "เขาแก้ว",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420305",
            "subdistrictname": "ปากตม",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420306",
            "subdistrictname": "บุฮม",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420307",
            "subdistrictname": "จอมศรี",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4203",
            "districtname": "เชียงคาน",
            "subdistrictcode": "420308",
            "subdistrictname": "หาดทรายขาว",
            "zipcode": "42110"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420401",
            "subdistrictname": "ปากชม",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420402",
            "subdistrictname": "เชียงกลม",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420403",
            "subdistrictname": "หาดคัมภีร์",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420404",
            "subdistrictname": "ห้วยบ่อซืน",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420405",
            "subdistrictname": "ห้วยพิชัย",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4204",
            "districtname": "ปากชม",
            "subdistrictcode": "420406",
            "subdistrictname": "ชมเจริญ",
            "zipcode": "42150"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420501",
            "subdistrictname": "ด่านซ้าย",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420502",
            "subdistrictname": "ปากหมัน",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420503",
            "subdistrictname": "นาดี",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420504",
            "subdistrictname": "โคกงาม",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420505",
            "subdistrictname": "โพนสูง",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420506",
            "subdistrictname": "อิปุ่ม",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420507",
            "subdistrictname": "กกสะทอน",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420508",
            "subdistrictname": "โป่ง",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420509",
            "subdistrictname": "วังยาว",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4205",
            "districtname": "ด่านซ้าย",
            "subdistrictcode": "420510",
            "subdistrictname": "นาหอ",
            "zipcode": "42120"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4206",
            "districtname": "นาแห้ว",
            "subdistrictcode": "420601",
            "subdistrictname": "นาแห้ว",
            "zipcode": "42170"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4206",
            "districtname": "นาแห้ว",
            "subdistrictcode": "420602",
            "subdistrictname": "แสงภา",
            "zipcode": "42170"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4206",
            "districtname": "นาแห้ว",
            "subdistrictcode": "420603",
            "subdistrictname": "นาพึง",
            "zipcode": "42170"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4206",
            "districtname": "นาแห้ว",
            "subdistrictcode": "420604",
            "subdistrictname": "นามาลา",
            "zipcode": "42170"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4206",
            "districtname": "นาแห้ว",
            "subdistrictcode": "420605",
            "subdistrictname": "เหล่ากอหก",
            "zipcode": "42170"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420701",
            "subdistrictname": "หนองบัว",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420702",
            "subdistrictname": "ท่าศาลา",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420703",
            "subdistrictname": "ร่องจิก",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420704",
            "subdistrictname": "ปลาบ่า",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420705",
            "subdistrictname": "ลาดค่าง",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4207",
            "districtname": "ภูเรือ",
            "subdistrictcode": "420706",
            "subdistrictname": "สานตม",
            "zipcode": "42160"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420801",
            "subdistrictname": "ท่าลี่",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420802",
            "subdistrictname": "หนองผือ",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420803",
            "subdistrictname": "อาฮี",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420804",
            "subdistrictname": "น้ำแคม",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420805",
            "subdistrictname": "โคกใหญ่",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4208",
            "districtname": "ท่าลี่",
            "subdistrictcode": "420806",
            "subdistrictname": "น้ำทูน",
            "zipcode": "42140"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420901",
            "subdistrictname": "วังสะพุง",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420902",
            "subdistrictname": "ทรายขาว",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420903",
            "subdistrictname": "หนองหญ้าปล้อง",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420904",
            "subdistrictname": "หนองงิ้ว",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420905",
            "subdistrictname": "ปากปวน",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420906",
            "subdistrictname": "ผาน้อย",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420910",
            "subdistrictname": "ผาบิ้ง",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420911",
            "subdistrictname": "เขาหลวง",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420912",
            "subdistrictname": "โคกขมิ้น",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4209",
            "districtname": "วังสะพุง",
            "subdistrictcode": "420913",
            "subdistrictname": "ศรีสงคราม",
            "zipcode": "42130"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4210",
            "districtname": "ภูกระดึง",
            "subdistrictcode": "421001",
            "subdistrictname": "ศรีฐาน",
            "zipcode": "42180"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4210",
            "districtname": "ภูกระดึง",
            "subdistrictcode": "421005",
            "subdistrictname": "ผานกเค้า",
            "zipcode": "42180"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4210",
            "districtname": "ภูกระดึง",
            "subdistrictcode": "421007",
            "subdistrictname": "ภูกระดึง",
            "zipcode": "42180"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4210",
            "districtname": "ภูกระดึง",
            "subdistrictcode": "421010",
            "subdistrictname": "ห้วยส้ม",
            "zipcode": "42180"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4211",
            "districtname": "ภูหลวง",
            "subdistrictcode": "421101",
            "subdistrictname": "ภูหอ",
            "zipcode": "42230"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4211",
            "districtname": "ภูหลวง",
            "subdistrictcode": "421102",
            "subdistrictname": "หนองคัน",
            "zipcode": "42230"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4211",
            "districtname": "ภูหลวง",
            "subdistrictcode": "421104",
            "subdistrictname": "ห้วยสีเสียด",
            "zipcode": "42230"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4211",
            "districtname": "ภูหลวง",
            "subdistrictcode": "421105",
            "subdistrictname": "เลยวังไสย์",
            "zipcode": "42230"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4211",
            "districtname": "ภูหลวง",
            "subdistrictcode": "421106",
            "subdistrictname": "แก่งศรีภูมิ",
            "zipcode": "42230"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4212",
            "districtname": "ผาขาว",
            "subdistrictcode": "421201",
            "subdistrictname": "ผาขาว",
            "zipcode": "42240"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4212",
            "districtname": "ผาขาว",
            "subdistrictcode": "421202",
            "subdistrictname": "ท่าช้างคล้อง",
            "zipcode": "42240"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4212",
            "districtname": "ผาขาว",
            "subdistrictcode": "421203",
            "subdistrictname": "โนนปอแดง",
            "zipcode": "42240"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4212",
            "districtname": "ผาขาว",
            "subdistrictcode": "421204",
            "subdistrictname": "โนนป่าซาง",
            "zipcode": "42240"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4212",
            "districtname": "ผาขาว",
            "subdistrictcode": "421205",
            "subdistrictname": "บ้านเพิ่ม",
            "zipcode": "42240"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4213",
            "districtname": "เอราวัณ",
            "subdistrictcode": "421301",
            "subdistrictname": "เอราวัณ",
            "zipcode": "42220"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4213",
            "districtname": "เอราวัณ",
            "subdistrictcode": "421302",
            "subdistrictname": "ผาอินทร์แปลง",
            "zipcode": "42220"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4213",
            "districtname": "เอราวัณ",
            "subdistrictcode": "421303",
            "subdistrictname": "ผาสามยอด",
            "zipcode": "42220"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4213",
            "districtname": "เอราวัณ",
            "subdistrictcode": "421304",
            "subdistrictname": "ทรัพย์ไพวัลย์",
            "zipcode": "42220"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4214",
            "districtname": "หนองหิน",
            "subdistrictcode": "421401",
            "subdistrictname": "หนองหิน",
            "zipcode": "42190"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4214",
            "districtname": "หนองหิน",
            "subdistrictcode": "421402",
            "subdistrictname": "ตาดข่า",
            "zipcode": "42190"
        },
        {
            "isActive": true,
            "provincecode": "เลย",
            "districtcode": "4214",
            "districtname": "หนองหิน",
            "subdistrictcode": "421403",
            "subdistrictname": "ปวนพุ",
            "zipcode": "42190"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430101",
            "subdistrictname": "ในเมือง",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430102",
            "subdistrictname": "มีชัย",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430103",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430104",
            "subdistrictname": "กวนวัน",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430105",
            "subdistrictname": "เวียงคุก",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430106",
            "subdistrictname": "วัดธาตุ",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430107",
            "subdistrictname": "หาดคำ",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430108",
            "subdistrictname": "หินโงม",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430109",
            "subdistrictname": "บ้านเดื่อ",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430110",
            "subdistrictname": "ค่ายบกหวาน",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430111",
            "subdistrictname": "สองห้อง",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430113",
            "subdistrictname": "พระธาตุบังพวน",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430116",
            "subdistrictname": "หนองกอมเกาะ",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430117",
            "subdistrictname": "ปะโค",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430118",
            "subdistrictname": "เมืองหมี",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4301",
            "districtname": "เมืองหนองคาย",
            "subdistrictcode": "430119",
            "subdistrictname": "สีกาย",
            "zipcode": "43000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430201",
            "subdistrictname": "ท่าบ่อ",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430202",
            "subdistrictname": "น้ำโมง",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430203",
            "subdistrictname": "กองนาง",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430204",
            "subdistrictname": "โคกคอน",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430205",
            "subdistrictname": "บ้านเดื่อ",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430206",
            "subdistrictname": "บ้านถ่อน",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430207",
            "subdistrictname": "บ้านว่าน",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430208",
            "subdistrictname": "นาข่า",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430209",
            "subdistrictname": "โพนสา",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4302",
            "districtname": "ท่าบ่อ",
            "subdistrictcode": "430210",
            "subdistrictname": "หนองนาง",
            "zipcode": "43110"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380107",
            "subdistrictname": "บึงกาฬ",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380106",
            "subdistrictname": "โนนสมบูรณ์",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380110",
            "subdistrictname": "หนองเข็ง",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380112",
            "subdistrictname": "หอคำ",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380111",
            "subdistrictname": "หนองเลิง",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380102",
            "subdistrictname": "โคกก่อง",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380105",
            "subdistrictname": "นาสวรรค์",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380103",
            "subdistrictname": "ไคสี",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380104",
            "subdistrictname": "ชัยพร",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380109",
            "subdistrictname": "วิศิษฐ์",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380101",
            "subdistrictname": "คำนาดี",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3801",
            "districtname": "เมืองบึงกาฬ",
            "subdistrictcode": "380108",
            "subdistrictname": "โป่งเปือย",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380405",
            "subdistrictname": "ศรีชมภู",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380401",
            "subdistrictname": "ดอนหญ้านาง",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380403",
            "subdistrictname": "พรเจริญ",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380407",
            "subdistrictname": "หนองหัวช้าง",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380404",
            "subdistrictname": "วังชมภู",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380402",
            "subdistrictname": "ป่าแฝก",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3804",
            "districtname": "พรเจริญ",
            "subdistrictcode": "380406",
            "subdistrictname": "ศรีสำราญ",
            "zipcode": "38180"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430501",
            "subdistrictname": "จุมพล",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430502",
            "subdistrictname": "วัดหลวง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430503",
            "subdistrictname": "กุดบง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430504",
            "subdistrictname": "ชุมช้าง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430506",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430507",
            "subdistrictname": "เหล่าต่างคำ",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430508",
            "subdistrictname": "นาหนัง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430509",
            "subdistrictname": "เซิม",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430513",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430521",
            "subdistrictname": "บ้านผือ",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4305",
            "districtname": "โพนพิสัย",
            "subdistrictcode": "430522",
            "subdistrictname": "สร้างนางขาว",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380302",
            "subdistrictname": "โซ่",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380306",
            "subdistrictname": "หนองพันทา",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380305",
            "subdistrictname": "ศรีชมภู",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380301",
            "subdistrictname": "คำแก้ว",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380304",
            "subdistrictname": "บัวตูม",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380303",
            "subdistrictname": "ถ้ำเจริญ",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3803",
            "districtname": "โซ่พิสัย",
            "subdistrictcode": "380307",
            "subdistrictname": "เหล่าทอง",
            "zipcode": "38170"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4307",
            "districtname": "ศรีเชียงใหม่",
            "subdistrictcode": "430701",
            "subdistrictname": "พานพร้าว",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4307",
            "districtname": "ศรีเชียงใหม่",
            "subdistrictcode": "430703",
            "subdistrictname": "บ้านหม้อ",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4307",
            "districtname": "ศรีเชียงใหม่",
            "subdistrictcode": "430704",
            "subdistrictname": "พระพุทธบาท",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4307",
            "districtname": "ศรีเชียงใหม่",
            "subdistrictcode": "430705",
            "subdistrictname": "หนองปลาปาก",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4308",
            "districtname": "สังคม",
            "subdistrictcode": "430801",
            "subdistrictname": "แก้งไก่",
            "zipcode": "43160"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4308",
            "districtname": "สังคม",
            "subdistrictcode": "430802",
            "subdistrictname": "ผาตั้ง",
            "zipcode": "43160"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4308",
            "districtname": "สังคม",
            "subdistrictcode": "430803",
            "subdistrictname": "บ้านม่วง",
            "zipcode": "43160"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4308",
            "districtname": "สังคม",
            "subdistrictcode": "430804",
            "subdistrictname": "นางิ้ว",
            "zipcode": "43160"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4308",
            "districtname": "สังคม",
            "subdistrictcode": "430805",
            "subdistrictname": "สังคม",
            "zipcode": "43160"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380202",
            "subdistrictname": "เซกา",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380201",
            "subdistrictname": "ซาง",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380203",
            "subdistrictname": "ท่ากกแดง",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380206",
            "subdistrictname": "บ้านต้อง",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380207",
            "subdistrictname": "ป่งไฮ",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380205",
            "subdistrictname": "น้ำจั้น",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380204",
            "subdistrictname": "ท่าสะอาด",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380209",
            "subdistrictname": "หนองทุ่ม",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3802",
            "districtname": "เซกา",
            "subdistrictcode": "380208",
            "subdistrictname": "โสกก่าม",
            "zipcode": "38150"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380704",
            "subdistrictname": "ปากคาด",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380706",
            "subdistrictname": "หนองยอง",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380701",
            "subdistrictname": "นากั้ง",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380703",
            "subdistrictname": "โนนศิลา",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380705",
            "subdistrictname": "สมสนุก",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3807",
            "districtname": "ปากคาด",
            "subdistrictcode": "380702",
            "subdistrictname": "นาดง",
            "zipcode": "38190"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3806",
            "districtname": "บึงโขงหลง",
            "subdistrictcode": "380603",
            "subdistrictname": "บึงโขงหลง",
            "zipcode": "38220"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3806",
            "districtname": "บึงโขงหลง",
            "subdistrictcode": "380604",
            "subdistrictname": "โพธิ์หมากแข้ง",
            "zipcode": "38220"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3806",
            "districtname": "บึงโขงหลง",
            "subdistrictcode": "380601",
            "subdistrictname": "ดงบัง",
            "zipcode": "38220"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3806",
            "districtname": "บึงโขงหลง",
            "subdistrictcode": "380602",
            "subdistrictname": "ท่าดอกคำ",
            "zipcode": "38220"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3805",
            "districtname": "ศรีวิไล",
            "subdistrictcode": "380505",
            "subdistrictname": "ศรีวิไล",
            "zipcode": "38210"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3805",
            "districtname": "ศรีวิไล",
            "subdistrictcode": "380501",
            "subdistrictname": "ชุมภูพร",
            "zipcode": "38210"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3805",
            "districtname": "ศรีวิไล",
            "subdistrictcode": "380504",
            "subdistrictname": "นาแสง",
            "zipcode": "38210"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3805",
            "districtname": "ศรีวิไล",
            "subdistrictcode": "380502",
            "subdistrictname": "นาสะแบง",
            "zipcode": "38210"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3805",
            "districtname": "ศรีวิไล",
            "subdistrictcode": "380503",
            "subdistrictname": "นาสิงห์",
            "zipcode": "38210"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3808",
            "districtname": "บุ่งคล้า",
            "subdistrictcode": "380802",
            "subdistrictname": "บุ่งคล้า",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3808",
            "districtname": "บุ่งคล้า",
            "subdistrictcode": "380803",
            "subdistrictname": "หนองเดิ่น",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "บึงกาฬ",
            "districtcode": "3808",
            "districtname": "บุ่งคล้า",
            "subdistrictcode": "380801",
            "subdistrictname": "โคกกว้าง",
            "zipcode": "38000"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4314",
            "districtname": "สระใคร",
            "subdistrictcode": "431401",
            "subdistrictname": "สระใคร",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4314",
            "districtname": "สระใคร",
            "subdistrictcode": "431402",
            "subdistrictname": "คอกช้าง",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4314",
            "districtname": "สระใคร",
            "subdistrictcode": "431403",
            "subdistrictname": "บ้านฝาง",
            "zipcode": "43100"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่",
            "subdistrictcode": "431501",
            "subdistrictname": "เฝ้าไร่",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่",
            "subdistrictcode": "431502",
            "subdistrictname": "นาดี",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่",
            "subdistrictcode": "431503",
            "subdistrictname": "หนองหลวง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่",
            "subdistrictcode": "431504",
            "subdistrictname": "วังหลวง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4315",
            "districtname": "เฝ้าไร่",
            "subdistrictcode": "431505",
            "subdistrictname": "อุดมพร",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4316",
            "districtname": "รัตนวาปี",
            "subdistrictcode": "431601",
            "subdistrictname": "รัตนวาปี",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4316",
            "districtname": "รัตนวาปี",
            "subdistrictcode": "431602",
            "subdistrictname": "นาทับไฮ",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4316",
            "districtname": "รัตนวาปี",
            "subdistrictcode": "431603",
            "subdistrictname": "บ้านต้อน",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4316",
            "districtname": "รัตนวาปี",
            "subdistrictcode": "431604",
            "subdistrictname": "พระบาทนาสิงห์",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4316",
            "districtname": "รัตนวาปี",
            "subdistrictcode": "431605",
            "subdistrictname": "โพนแพง",
            "zipcode": "43120"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4317",
            "districtname": "โพธิ์ตาก",
            "subdistrictcode": "431701",
            "subdistrictname": "โพธิ์ตาก",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4317",
            "districtname": "โพธิ์ตาก",
            "subdistrictcode": "431702",
            "subdistrictname": "โพนทอง",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "หนองคาย",
            "districtcode": "4317",
            "districtname": "โพธิ์ตาก",
            "subdistrictcode": "431703",
            "subdistrictname": "ด่านศรีสุข",
            "zipcode": "43130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440101",
            "subdistrictname": "ตลาด",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440102",
            "subdistrictname": "เขวา",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440103",
            "subdistrictname": "ท่าตูม",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440104",
            "subdistrictname": "แวงน่าง",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440105",
            "subdistrictname": "โคกก่อ",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440106",
            "subdistrictname": "ดอนหว่าน",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440107",
            "subdistrictname": "เกิ้ง",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440108",
            "subdistrictname": "แก่งเลิงจาน",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440109",
            "subdistrictname": "ท่าสองคอน",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440110",
            "subdistrictname": "ลาดพัฒนา",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440111",
            "subdistrictname": "หนองปลิง",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440112",
            "subdistrictname": "ห้วยแอ่ง",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440113",
            "subdistrictname": "หนองโน",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4401",
            "districtname": "เมืองมหาสารคาม",
            "subdistrictcode": "440114",
            "subdistrictname": "บัวค้อ",
            "zipcode": "44000"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4402",
            "districtname": "แกดำ",
            "subdistrictcode": "440201",
            "subdistrictname": "แกดำ",
            "zipcode": "44190"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4402",
            "districtname": "แกดำ",
            "subdistrictcode": "440202",
            "subdistrictname": "วังแสง",
            "zipcode": "44190"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4402",
            "districtname": "แกดำ",
            "subdistrictcode": "440203",
            "subdistrictname": "มิตรภาพ",
            "zipcode": "44190"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4402",
            "districtname": "แกดำ",
            "subdistrictcode": "440204",
            "subdistrictname": "หนองกุง",
            "zipcode": "44190"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4402",
            "districtname": "แกดำ",
            "subdistrictcode": "440205",
            "subdistrictname": "โนนภิบาล",
            "zipcode": "44190"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440301",
            "subdistrictname": "หัวขวาง",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440302",
            "subdistrictname": "ยางน้อย",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440303",
            "subdistrictname": "วังยาว",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440304",
            "subdistrictname": "เขวาไร่",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440305",
            "subdistrictname": "แพง",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440306",
            "subdistrictname": "แก้งแก",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440307",
            "subdistrictname": "หนองเหล็ก",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440308",
            "subdistrictname": "หนองบัว",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440309",
            "subdistrictname": "เหล่า",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440310",
            "subdistrictname": "เขื่อน",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440311",
            "subdistrictname": "หนองบอน",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440312",
            "subdistrictname": "โพนงาม",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440313",
            "subdistrictname": "ยางท่าแจ้ง",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440314",
            "subdistrictname": "แห่ใต้",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440315",
            "subdistrictname": "หนองกุงสวรรค์",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440316",
            "subdistrictname": "เลิงใต้",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4403",
            "districtname": "โกสุมพิสัย",
            "subdistrictcode": "440317",
            "subdistrictname": "ดอนกลาง",
            "zipcode": "44140"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440401",
            "subdistrictname": "โคกพระ",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440402",
            "subdistrictname": "คันธารราษฎร์",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440403",
            "subdistrictname": "มะค่า",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440404",
            "subdistrictname": "ท่าขอนยาง",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440405",
            "subdistrictname": "นาสีนวน",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440406",
            "subdistrictname": "ขามเรียง",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440407",
            "subdistrictname": "เขวาใหญ่",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440408",
            "subdistrictname": "ศรีสุข",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440409",
            "subdistrictname": "กุดใส้จ่อ",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4404",
            "districtname": "กันทรวิชัย",
            "subdistrictcode": "440410",
            "subdistrictname": "ขามเฒ่าพัฒนา",
            "zipcode": "44150"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440501",
            "subdistrictname": "เชียงยืน",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440503",
            "subdistrictname": "หนองซอน",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440505",
            "subdistrictname": "ดอนเงิน",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440506",
            "subdistrictname": "กู่ทอง",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440507",
            "subdistrictname": "นาทอง",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440508",
            "subdistrictname": "เสือเฒ่า",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440511",
            "subdistrictname": "โพนทอง",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4405",
            "districtname": "เชียงยืน",
            "subdistrictcode": "440512",
            "subdistrictname": "เหล่าบัวบาน",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440601",
            "subdistrictname": "บรบือ",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440602",
            "subdistrictname": "บ่อใหญ่",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440604",
            "subdistrictname": "วังไชย",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440605",
            "subdistrictname": "หนองม่วง",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440606",
            "subdistrictname": "กำพี้",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440607",
            "subdistrictname": "โนนราษี",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440608",
            "subdistrictname": "โนนแดง",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440610",
            "subdistrictname": "หนองจิก",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440611",
            "subdistrictname": "บัวมาศ",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440613",
            "subdistrictname": "หนองคูขาด",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440615",
            "subdistrictname": "วังใหม่",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440616",
            "subdistrictname": "ยาง",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440618",
            "subdistrictname": "หนองสิม",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440619",
            "subdistrictname": "หนองโก",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4406",
            "districtname": "บรบือ",
            "subdistrictcode": "440620",
            "subdistrictname": "ดอนงัว",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440701",
            "subdistrictname": "นาเชือก",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440702",
            "subdistrictname": "สำโรง",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440703",
            "subdistrictname": "หนองแดง",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440704",
            "subdistrictname": "เขวาไร่",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440705",
            "subdistrictname": "หนองโพธิ์",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440706",
            "subdistrictname": "ปอพาน",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440707",
            "subdistrictname": "หนองเม็ก",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440708",
            "subdistrictname": "หนองเรือ",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440709",
            "subdistrictname": "หนองกุง",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4407",
            "districtname": "นาเชือก",
            "subdistrictcode": "440710",
            "subdistrictname": "สันป่าตอง",
            "zipcode": "44170"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440801",
            "subdistrictname": "ปะหลาน",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440802",
            "subdistrictname": "ก้ามปู",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440803",
            "subdistrictname": "เวียงสะอาด",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440804",
            "subdistrictname": "เม็กดำ",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440805",
            "subdistrictname": "นาสีนวล",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440809",
            "subdistrictname": "ราษฎร์เจริญ",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440810",
            "subdistrictname": "หนองบัวแก้ว",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440812",
            "subdistrictname": "เมืองเตา",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440815",
            "subdistrictname": "ลานสะแก",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440816",
            "subdistrictname": "เวียงชัย",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440817",
            "subdistrictname": "หนองบัว",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440818",
            "subdistrictname": "ราษฎร์พัฒนา",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440819",
            "subdistrictname": "เมืองเสือ",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4408",
            "districtname": "พยัคฆภูมิพิสัย",
            "subdistrictcode": "440820",
            "subdistrictname": "ภารแอ่น",
            "zipcode": "44110"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440901",
            "subdistrictname": "หนองแสง",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440902",
            "subdistrictname": "ขามป้อม",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440903",
            "subdistrictname": "เสือโก้ก",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440904",
            "subdistrictname": "ดงใหญ่",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440905",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440906",
            "subdistrictname": "หัวเรือ",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440907",
            "subdistrictname": "แคน",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440908",
            "subdistrictname": "งัวบา",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440909",
            "subdistrictname": "นาข่า",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440910",
            "subdistrictname": "บ้านหวาย",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440911",
            "subdistrictname": "หนองไฮ",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440912",
            "subdistrictname": "ประชาพัฒนา",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440913",
            "subdistrictname": "หนองทุ่ม",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440914",
            "subdistrictname": "หนองแสน",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4409",
            "districtname": "วาปีปทุม",
            "subdistrictcode": "440915",
            "subdistrictname": "โคกสีทองหลาง",
            "zipcode": "44120"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441001",
            "subdistrictname": "นาดูน",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441002",
            "subdistrictname": "หนองไผ่",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441003",
            "subdistrictname": "หนองคู",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441004",
            "subdistrictname": "ดงบัง",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441005",
            "subdistrictname": "ดงดวน",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441006",
            "subdistrictname": "หัวดง",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441007",
            "subdistrictname": "ดงยาง",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441008",
            "subdistrictname": "กู่สันตรัตน์",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4410",
            "districtname": "นาดูน",
            "subdistrictcode": "441009",
            "subdistrictname": "พระธาตุ",
            "zipcode": "44180"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441101",
            "subdistrictname": "ยางสีสุราช",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441102",
            "subdistrictname": "นาภู",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441103",
            "subdistrictname": "แวงดง",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441104",
            "subdistrictname": "บ้านกู่",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441105",
            "subdistrictname": "ดงเมือง",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441106",
            "subdistrictname": "ขามเรียน",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4411",
            "districtname": "ยางสีสุราช",
            "subdistrictcode": "441107",
            "subdistrictname": "หนองบัวสันตุ",
            "zipcode": "44210"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4412",
            "districtname": "กุดรัง",
            "subdistrictcode": "441201",
            "subdistrictname": "กุดรัง",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4412",
            "districtname": "กุดรัง",
            "subdistrictcode": "441202",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4412",
            "districtname": "กุดรัง",
            "subdistrictcode": "441203",
            "subdistrictname": "เลิงแฝก",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4412",
            "districtname": "กุดรัง",
            "subdistrictcode": "441204",
            "subdistrictname": "หนองแวง",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4412",
            "districtname": "กุดรัง",
            "subdistrictcode": "441205",
            "subdistrictname": "ห้วยเตย",
            "zipcode": "44130"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4413",
            "districtname": "ชื่นชม",
            "subdistrictcode": "441301",
            "subdistrictname": "ชื่นชม",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4413",
            "districtname": "ชื่นชม",
            "subdistrictcode": "441302",
            "subdistrictname": "กุดปลาดุก",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4413",
            "districtname": "ชื่นชม",
            "subdistrictcode": "441303",
            "subdistrictname": "เหล่าดอกไม้",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "มหาสารคาม",
            "districtcode": "4413",
            "districtname": "ชื่นชม",
            "subdistrictcode": "441304",
            "subdistrictname": "หนองกุง",
            "zipcode": "44160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450101",
            "subdistrictname": "ในเมือง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450102",
            "subdistrictname": "รอบเมือง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450103",
            "subdistrictname": "เหนือเมือง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450104",
            "subdistrictname": "ขอนแก่น",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450105",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450106",
            "subdistrictname": "สะอาดสมบูรณ์",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450108",
            "subdistrictname": "สีแก้ว",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450109",
            "subdistrictname": "ปอภาร  (ปอพาน)",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450110",
            "subdistrictname": "โนนรัง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450117",
            "subdistrictname": "หนองแก้ว",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450118",
            "subdistrictname": "หนองแวง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450120",
            "subdistrictname": "ดงลาน",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450123",
            "subdistrictname": "แคนใหญ่",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450124",
            "subdistrictname": "โนนตาล",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4501",
            "districtname": "เมืองร้อยเอ็ด",
            "subdistrictcode": "450125",
            "subdistrictname": "เมืองทอง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450201",
            "subdistrictname": "เกษตรวิสัย",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450202",
            "subdistrictname": "เมืองบัว",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450203",
            "subdistrictname": "เหล่าหลวง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450204",
            "subdistrictname": "สิงห์โคก",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450205",
            "subdistrictname": "ดงครั่งใหญ่",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450206",
            "subdistrictname": "บ้านฝาง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450207",
            "subdistrictname": "หนองแวง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450208",
            "subdistrictname": "กำแพง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450209",
            "subdistrictname": "กู่กาสิงห์",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450210",
            "subdistrictname": "น้ำอ้อม",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450211",
            "subdistrictname": "โนนสว่าง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450212",
            "subdistrictname": "ทุ่งทอง",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4502",
            "districtname": "เกษตรวิสัย",
            "subdistrictcode": "450213",
            "subdistrictname": "ดงครั่งน้อย",
            "zipcode": "45150"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450301",
            "subdistrictname": "บัวแดง",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450302",
            "subdistrictname": "ดอกล้ำ",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450303",
            "subdistrictname": "หนองแคน",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450304",
            "subdistrictname": "โพนสูง",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450305",
            "subdistrictname": "โนนสวรรค์",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450306",
            "subdistrictname": "สระบัว",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450307",
            "subdistrictname": "โนนสง่า",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4503",
            "districtname": "ปทุมรัตต์",
            "subdistrictcode": "450308",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "45190"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450401",
            "subdistrictname": "หัวช้าง",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450402",
            "subdistrictname": "หนองผือ",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450403",
            "subdistrictname": "เมืองหงส์",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450404",
            "subdistrictname": "โคกล่าม",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450405",
            "subdistrictname": "น้ำใส",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450406",
            "subdistrictname": "ดงแดง",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450407",
            "subdistrictname": "ดงกลาง",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450408",
            "subdistrictname": "ป่าสังข์",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450409",
            "subdistrictname": "อีง่อง",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450410",
            "subdistrictname": "ลิ้นฟ้า",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450411",
            "subdistrictname": "ดู่น้อย",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4504",
            "districtname": "จตุรพักตรพิมาน",
            "subdistrictcode": "450412",
            "subdistrictname": "ศรีโคตร",
            "zipcode": "45180"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450501",
            "subdistrictname": "นิเวศน์",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450502",
            "subdistrictname": "ธงธานี",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450503",
            "subdistrictname": "หนองไผ่",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450504",
            "subdistrictname": "ธวัชบุรี",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450506",
            "subdistrictname": "อุ่มเม้า",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450507",
            "subdistrictname": "มะอึ",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450510",
            "subdistrictname": "เขวาทุ่ง",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450515",
            "subdistrictname": "ไพศาล",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450517",
            "subdistrictname": "เมืองน้อย",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450520",
            "subdistrictname": "บึงนคร",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450522",
            "subdistrictname": "ราชธานี",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4505",
            "districtname": "ธวัชบุรี",
            "subdistrictcode": "450524",
            "subdistrictname": "หนองพอก",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450601",
            "subdistrictname": "พนมไพร",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450602",
            "subdistrictname": "แสนสุข",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450603",
            "subdistrictname": "กุดน้ำใส",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450604",
            "subdistrictname": "หนองทัพไทย",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450605",
            "subdistrictname": "โพธิ์ใหญ่",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450606",
            "subdistrictname": "วารีสวัสดิ์",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450607",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450611",
            "subdistrictname": "โพธิ์ชัย",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450612",
            "subdistrictname": "นานวล",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450613",
            "subdistrictname": "คำไฮ",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450614",
            "subdistrictname": "สระแก้ว",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450615",
            "subdistrictname": "ค้อใหญ่",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4506",
            "districtname": "พนมไพร",
            "subdistrictcode": "450617",
            "subdistrictname": "ชานุวรรณ",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450701",
            "subdistrictname": "แวง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450702",
            "subdistrictname": "โคกกกม่วง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450703",
            "subdistrictname": "นาอุดม",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450704",
            "subdistrictname": "สว่าง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450705",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450706",
            "subdistrictname": "โพธิ์ทอง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450707",
            "subdistrictname": "โนนชัยศรี",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450708",
            "subdistrictname": "โพธิ์ศรีสว่าง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450709",
            "subdistrictname": "อุ่มเม่า",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450710",
            "subdistrictname": "คำนาดี",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450711",
            "subdistrictname": "พรมสวรรค์",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450712",
            "subdistrictname": "สระนกแก้ว",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450713",
            "subdistrictname": "วังสามัคคี",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4507",
            "districtname": "โพนทอง",
            "subdistrictcode": "450714",
            "subdistrictname": "โคกสูง",
            "zipcode": "45110"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450801",
            "subdistrictname": "ขามเปี้ย",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450802",
            "subdistrictname": "เชียงใหม่",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450803",
            "subdistrictname": "บัวคำ",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450804",
            "subdistrictname": "อัคคะคำ",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450805",
            "subdistrictname": "สะอาด",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450806",
            "subdistrictname": "คำพอุง",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450807",
            "subdistrictname": "หนองตาไก้",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450808",
            "subdistrictname": "ดอนโอง",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4508",
            "districtname": "โพธิ์ชัย",
            "subdistrictcode": "450809",
            "subdistrictname": "โพธิ์ศรี",
            "zipcode": "45230"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450901",
            "subdistrictname": "หนองพอก",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450902",
            "subdistrictname": "บึงงาม",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450903",
            "subdistrictname": "ภูเขาทอง",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450904",
            "subdistrictname": "กกโพธิ์",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450905",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450906",
            "subdistrictname": "หนองขุ่นใหญ่",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450907",
            "subdistrictname": "รอบเมือง",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450908",
            "subdistrictname": "ผาน้ำย้อย",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4509",
            "districtname": "หนองพอก",
            "subdistrictcode": "450909",
            "subdistrictname": "ท่าสีดา",
            "zipcode": "45210"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451001",
            "subdistrictname": "กลาง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451002",
            "subdistrictname": "นางาม",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451003",
            "subdistrictname": "เมืองไพร",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451004",
            "subdistrictname": "นาแซง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451005",
            "subdistrictname": "นาเมือง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451006",
            "subdistrictname": "วังหลวง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451007",
            "subdistrictname": "ท่าม่วง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451008",
            "subdistrictname": "ขวาว",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451009",
            "subdistrictname": "โพธิ์ทอง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451010",
            "subdistrictname": "ภูเงิน",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451011",
            "subdistrictname": "เกาะแก้ว",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451012",
            "subdistrictname": "นาเลิง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451013",
            "subdistrictname": "เหล่าน้อย",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451014",
            "subdistrictname": "ศรีวิลัย",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451015",
            "subdistrictname": "หนองหลวง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451016",
            "subdistrictname": "พรสวรรค์",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451017",
            "subdistrictname": "ขวัญเมือง",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4510",
            "districtname": "เสลภูมิ",
            "subdistrictcode": "451018",
            "subdistrictname": "บึงเกลือ",
            "zipcode": "45120"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451101",
            "subdistrictname": "สระคู",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451102",
            "subdistrictname": "ดอกไม้",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451103",
            "subdistrictname": "นาใหญ่",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451104",
            "subdistrictname": "หินกอง",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451105",
            "subdistrictname": "เมืองทุ่ง",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451106",
            "subdistrictname": "หัวโทน",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451107",
            "subdistrictname": "บ่อพันขัน",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451108",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451109",
            "subdistrictname": "หัวช้าง",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451110",
            "subdistrictname": "น้ำคำ",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451111",
            "subdistrictname": "ห้วยหินลาด",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451112",
            "subdistrictname": "ช้างเผือก",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451113",
            "subdistrictname": "ทุ่งกุลา",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451114",
            "subdistrictname": "ทุ่งศรีเมือง",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4511",
            "districtname": "สุวรรณภูมิ",
            "subdistrictcode": "451115",
            "subdistrictname": "จำปาขัน",
            "zipcode": "45130"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4512",
            "districtname": "เมืองสรวง",
            "subdistrictcode": "451201",
            "subdistrictname": "หนองผือ",
            "zipcode": "45220"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4512",
            "districtname": "เมืองสรวง",
            "subdistrictcode": "451202",
            "subdistrictname": "หนองหิน",
            "zipcode": "45220"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4512",
            "districtname": "เมืองสรวง",
            "subdistrictcode": "451203",
            "subdistrictname": "คูเมือง",
            "zipcode": "45220"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4512",
            "districtname": "เมืองสรวง",
            "subdistrictcode": "451204",
            "subdistrictname": "กกกุง",
            "zipcode": "45220"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4512",
            "districtname": "เมืองสรวง",
            "subdistrictcode": "451205",
            "subdistrictname": "เมืองสรวง",
            "zipcode": "45220"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4513",
            "districtname": "โพนทราย",
            "subdistrictcode": "451301",
            "subdistrictname": "โพนทราย",
            "zipcode": "45240"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4513",
            "districtname": "โพนทราย",
            "subdistrictcode": "451302",
            "subdistrictname": "สามขา",
            "zipcode": "45240"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4513",
            "districtname": "โพนทราย",
            "subdistrictcode": "451303",
            "subdistrictname": "ศรีสว่าง",
            "zipcode": "45240"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4513",
            "districtname": "โพนทราย",
            "subdistrictcode": "451304",
            "subdistrictname": "ยางคำ",
            "zipcode": "45240"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4513",
            "districtname": "โพนทราย",
            "subdistrictcode": "451305",
            "subdistrictname": "ท่าหาดยาว",
            "zipcode": "45240"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451401",
            "subdistrictname": "อาจสามารถ",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451402",
            "subdistrictname": "โพนเมือง",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451403",
            "subdistrictname": "บ้านแจ้ง",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451404",
            "subdistrictname": "หน่อม",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451405",
            "subdistrictname": "หนองหมื่นถ่าน",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451406",
            "subdistrictname": "หนองขาม",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451407",
            "subdistrictname": "โหรา",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451408",
            "subdistrictname": "หนองบัว",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451409",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4514",
            "districtname": "อาจสามารถ",
            "subdistrictcode": "451410",
            "subdistrictname": "บ้านดู่",
            "zipcode": "45160"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4515",
            "districtname": "เมยวดี",
            "subdistrictcode": "451501",
            "subdistrictname": "เมยวดี",
            "zipcode": "45250"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4515",
            "districtname": "เมยวดี",
            "subdistrictcode": "451502",
            "subdistrictname": "ชุมพร",
            "zipcode": "45250"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4515",
            "districtname": "เมยวดี",
            "subdistrictcode": "451503",
            "subdistrictname": "บุ่งเลิศ",
            "zipcode": "45250"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4515",
            "districtname": "เมยวดี",
            "subdistrictcode": "451504",
            "subdistrictname": "ชมสะอาด",
            "zipcode": "45250"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451601",
            "subdistrictname": "โพธิ์ทอง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451602",
            "subdistrictname": "ศรีสมเด็จ",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451603",
            "subdistrictname": "เมืองเปลือย",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451604",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451605",
            "subdistrictname": "สวนจิก",
            "zipcode": "45280"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451606",
            "subdistrictname": "โพธิ์สัย",
            "zipcode": "45280"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451607",
            "subdistrictname": "หนองแวงควง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4516",
            "districtname": "ศรีสมเด็จ",
            "subdistrictcode": "451608",
            "subdistrictname": "บ้านบาก",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451701",
            "subdistrictname": "ดินดำ",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451702",
            "subdistrictname": "ปาฝา",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451703",
            "subdistrictname": "ม่วงลาด",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451704",
            "subdistrictname": "จังหาร",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451705",
            "subdistrictname": "ดงสิงห์",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451706",
            "subdistrictname": "ยางใหญ่",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451707",
            "subdistrictname": "ผักแว่น",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4517",
            "districtname": "จังหาร",
            "subdistrictcode": "451708",
            "subdistrictname": "แสนชาติ",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451801",
            "subdistrictname": "เชียงขวัญ",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451802",
            "subdistrictname": "พลับพลา",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451803",
            "subdistrictname": "พระธาตุ",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451804",
            "subdistrictname": "พระเจ้า",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451805",
            "subdistrictname": "หมูม้น",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4518",
            "districtname": "เชียงขวัญ",
            "subdistrictcode": "451806",
            "subdistrictname": "บ้านเขือง",
            "zipcode": "45000"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4519",
            "districtname": "หนองฮี",
            "subdistrictcode": "451901",
            "subdistrictname": "หนองฮี",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4519",
            "districtname": "หนองฮี",
            "subdistrictcode": "451902",
            "subdistrictname": "สาวแห",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4519",
            "districtname": "หนองฮี",
            "subdistrictcode": "451903",
            "subdistrictname": "ดูกอึ่ง",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4519",
            "districtname": "หนองฮี",
            "subdistrictcode": "451904",
            "subdistrictname": "เด่นราษฎร์",
            "zipcode": "45140"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง",
            "subdistrictcode": "452001",
            "subdistrictname": "ทุ่งเขาหลวง",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง",
            "subdistrictcode": "452002",
            "subdistrictname": "เทอดไทย",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง",
            "subdistrictcode": "452003",
            "subdistrictname": "บึงงาม",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง",
            "subdistrictcode": "452004",
            "subdistrictname": "มะบ้า",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "ร้อยเอ็ด",
            "districtcode": "4520",
            "districtname": "ทุ่งเขาหลวง",
            "subdistrictcode": "452005",
            "subdistrictname": "เหล่า",
            "zipcode": "45170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460101",
            "subdistrictname": "กาฬสินธุ์",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460102",
            "subdistrictname": "เหนือ",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460103",
            "subdistrictname": "หลุบ",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460104",
            "subdistrictname": "ไผ่",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460105",
            "subdistrictname": "ลำปาว",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460106",
            "subdistrictname": "ลำพาน",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460107",
            "subdistrictname": "เชียงเครือ",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460108",
            "subdistrictname": "บึงวิชัย",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460109",
            "subdistrictname": "ห้วยโพธิ์",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460111",
            "subdistrictname": "ภูปอ",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460113",
            "subdistrictname": "ภูดิน",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460115",
            "subdistrictname": "หนองกุง",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460116",
            "subdistrictname": "กลางหมื่น",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460117",
            "subdistrictname": "ขมิ้น",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460119",
            "subdistrictname": "โพนทอง",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460120",
            "subdistrictname": "นาจารย์",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4601",
            "districtname": "เมืองกาฬสินธุ์",
            "subdistrictcode": "460121",
            "subdistrictname": "ลำคลอง",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4602",
            "districtname": "นามน",
            "subdistrictcode": "460201",
            "subdistrictname": "นามน",
            "zipcode": "46230"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4602",
            "districtname": "นามน",
            "subdistrictcode": "460202",
            "subdistrictname": "ยอดแกง",
            "zipcode": "46230"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4602",
            "districtname": "นามน",
            "subdistrictcode": "460203",
            "subdistrictname": "สงเปลือย",
            "zipcode": "46230"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4602",
            "districtname": "นามน",
            "subdistrictcode": "460204",
            "subdistrictname": "หลักเหลี่ยม",
            "zipcode": "46230"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4602",
            "districtname": "นามน",
            "subdistrictcode": "460205",
            "subdistrictname": "หนองบัว",
            "zipcode": "46230"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460301",
            "subdistrictname": "กมลาไสย",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460302",
            "subdistrictname": "หลักเมือง",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460303",
            "subdistrictname": "โพนงาม",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460304",
            "subdistrictname": "ดงลิง",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460305",
            "subdistrictname": "ธัญญา",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460308",
            "subdistrictname": "หนองแปน",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460310",
            "subdistrictname": "เจ้าท่า",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4603",
            "districtname": "กมลาไสย",
            "subdistrictcode": "460311",
            "subdistrictname": "โคกสมบูรณ์",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4604",
            "districtname": "ร่องคำ",
            "subdistrictcode": "460401",
            "subdistrictname": "ร่องคำ",
            "zipcode": "46210"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4604",
            "districtname": "ร่องคำ",
            "subdistrictcode": "460402",
            "subdistrictname": "สามัคคี",
            "zipcode": "46210"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4604",
            "districtname": "ร่องคำ",
            "subdistrictcode": "460403",
            "subdistrictname": "เหล่าอ้อย",
            "zipcode": "46210"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460501",
            "subdistrictname": "บัวขาว",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460502",
            "subdistrictname": "แจนแลน",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460503",
            "subdistrictname": "เหล่าใหญ่",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460504",
            "subdistrictname": "จุมจัง",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460505",
            "subdistrictname": "เหล่าไฮงาม",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460506",
            "subdistrictname": "กุดหว้า",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460507",
            "subdistrictname": "สามขา",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460508",
            "subdistrictname": "นาขาม",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460509",
            "subdistrictname": "หนองห้าง",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460510",
            "subdistrictname": "นาโก",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460511",
            "subdistrictname": "สมสะอาด",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4605",
            "districtname": "กุฉินารายณ์",
            "subdistrictcode": "460512",
            "subdistrictname": "กุดค้าว",
            "zipcode": "46110"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460601",
            "subdistrictname": "คุ้มเก่า",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460602",
            "subdistrictname": "สงเปลือย",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460603",
            "subdistrictname": "หนองผือ",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460606",
            "subdistrictname": "กุดสิมคุ้มใหม่",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460608",
            "subdistrictname": "สระพังทอง",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4606",
            "districtname": "เขาวง",
            "subdistrictcode": "460611",
            "subdistrictname": "กุดปลาค้าว",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460701",
            "subdistrictname": "ยางตลาด",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460702",
            "subdistrictname": "หัวงัว",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460703",
            "subdistrictname": "อุ่มเม่า",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460704",
            "subdistrictname": "บัวบาน",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460705",
            "subdistrictname": "เว่อ",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460706",
            "subdistrictname": "อิตื้อ",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460707",
            "subdistrictname": "หัวนาคำ",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460708",
            "subdistrictname": "หนองอิเฒ่า",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460709",
            "subdistrictname": "ดอนสมบูรณ์",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460710",
            "subdistrictname": "นาเชือก",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460711",
            "subdistrictname": "คลองขาม",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460712",
            "subdistrictname": "เขาพระนอน",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460713",
            "subdistrictname": "นาดี",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460714",
            "subdistrictname": "โนนสูง",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4607",
            "districtname": "ยางตลาด",
            "subdistrictcode": "460715",
            "subdistrictname": "หนองตอกแป้น",
            "zipcode": "46120"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460801",
            "subdistrictname": "ห้วยเม็ก",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460802",
            "subdistrictname": "คำใหญ่",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460803",
            "subdistrictname": "กุดโดน",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460804",
            "subdistrictname": "บึงนาเรียง",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460805",
            "subdistrictname": "หัวหิน",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460806",
            "subdistrictname": "พิมูล",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460807",
            "subdistrictname": "คำเหมือดแก้ว",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460808",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4608",
            "districtname": "ห้วยเม็ก",
            "subdistrictcode": "460809",
            "subdistrictname": "ทรายทอง",
            "zipcode": "46170"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460901",
            "subdistrictname": "ภูสิงห์",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460902",
            "subdistrictname": "สหัสขันธ์",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460903",
            "subdistrictname": "นามะเขือ",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460904",
            "subdistrictname": "โนนศิลา",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460905",
            "subdistrictname": "นิคม",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460906",
            "subdistrictname": "โนนแหลมทอง",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460907",
            "subdistrictname": "โนนบุรี",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4609",
            "districtname": "สหัสขันธ์",
            "subdistrictcode": "460908",
            "subdistrictname": "โนนน้ำเกลี้ยง",
            "zipcode": "46140"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461001",
            "subdistrictname": "ทุ่งคลอง",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461002",
            "subdistrictname": "โพน",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461005",
            "subdistrictname": "ดินจี่",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461006",
            "subdistrictname": "นาบอน",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461007",
            "subdistrictname": "นาทัน",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4610",
            "districtname": "คำม่วง",
            "subdistrictcode": "461009",
            "subdistrictname": "เนินยาง",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่าคันโท",
            "subdistrictcode": "461101",
            "subdistrictname": "ท่าคันโท",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่าคันโท",
            "subdistrictcode": "461102",
            "subdistrictname": "กุงเก่า",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่าคันโท",
            "subdistrictcode": "461103",
            "subdistrictname": "ยางอู้ม",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่��คันโท",
            "subdistrictcode": "461104",
            "subdistrictname": "กุดจิก",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่าคันโท",
            "subdistrictcode": "461105",
            "subdistrictname": "นาตาล",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4611",
            "districtname": "ท่าคันโท",
            "subdistrictcode": "461106",
            "subdistrictname": "ดงสมบูรณ์",
            "zipcode": "46190"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461201",
            "subdistrictname": "หนองกุงศรี",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461202",
            "subdistrictname": "หนองบัว",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461203",
            "subdistrictname": "โคกเครือ",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461204",
            "subdistrictname": "หนองสรวง",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461205",
            "subdistrictname": "เสาเล้า",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461206",
            "subdistrictname": "หนองใหญ่",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461207",
            "subdistrictname": "ดงมูล",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461208",
            "subdistrictname": "ลำหนองแสน",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4612",
            "districtname": "หนองกุงศรี",
            "subdistrictcode": "461209",
            "subdistrictname": "หนองหิน",
            "zipcode": "46220"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461301",
            "subdistrictname": "สมเด็จ",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461302",
            "subdistrictname": "หนองแวง",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461303",
            "subdistrictname": "แซงบาดาล",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461304",
            "subdistrictname": "มหาไชย",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461305",
            "subdistrictname": "หมูม่น",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461306",
            "subdistrictname": "ผาเสวย",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461307",
            "subdistrictname": "ศรีสมเด็จ",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4613",
            "districtname": "สมเด็จ",
            "subdistrictcode": "461308",
            "subdistrictname": "ลำห้วยหลัว",
            "zipcode": "46150"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4614",
            "districtname": "ห้วยผึ้ง",
            "subdistrictcode": "461401",
            "subdistrictname": "คำบง",
            "zipcode": "46240"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4614",
            "districtname": "ห้วยผึ้ง",
            "subdistrictcode": "461402",
            "subdistrictname": "ไค้นุ่น",
            "zipcode": "46240"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4614",
            "districtname": "ห้วยผึ้ง",
            "subdistrictcode": "461403",
            "subdistrictname": "นิคมห้วยผึ้ง",
            "zipcode": "46240"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4614",
            "districtname": "ห้วยผึ้ง",
            "subdistrictcode": "461404",
            "subdistrictname": "หนองอีบุตร",
            "zipcode": "46240"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4615",
            "districtname": "สามชัย",
            "subdistrictcode": "461501",
            "subdistrictname": "สำราญ",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4615",
            "districtname": "สามชัย",
            "subdistrictcode": "461502",
            "subdistrictname": "สำราญใต้",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4615",
            "districtname": "สามชัย",
            "subdistrictcode": "461503",
            "subdistrictname": "คำสร้างเที่ยง",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4615",
            "districtname": "สามชัย",
            "subdistrictcode": "461504",
            "subdistrictname": "หนองช้าง",
            "zipcode": "46180"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4616",
            "districtname": "นาคู",
            "subdistrictcode": "461601",
            "subdistrictname": "นาคู",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4616",
            "districtname": "นาคู",
            "subdistrictcode": "461602",
            "subdistrictname": "สายนาวัง",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4616",
            "districtname": "นาคู",
            "subdistrictcode": "461603",
            "subdistrictname": "โนนนาจาน",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4616",
            "districtname": "นาคู",
            "subdistrictcode": "461604",
            "subdistrictname": "บ่อแก้ว",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4616",
            "districtname": "นาคู",
            "subdistrictcode": "461605",
            "subdistrictname": "ภูแล่นช้าง",
            "zipcode": "46160"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4617",
            "districtname": "ดอนจาน",
            "subdistrictcode": "461701",
            "subdistrictname": "ดอนจาน",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4617",
            "districtname": "ดอนจาน",
            "subdistrictcode": "461702",
            "subdistrictname": "สะอาดไชยศรี",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4617",
            "districtname": "ดอนจาน",
            "subdistrictcode": "461703",
            "subdistrictname": "ดงพยุง",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4617",
            "districtname": "ดอนจาน",
            "subdistrictcode": "461704",
            "subdistrictname": "ม่วงนา",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4617",
            "districtname": "ดอนจาน",
            "subdistrictcode": "461705",
            "subdistrictname": "นาจำปา",
            "zipcode": "46000"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย",
            "subdistrictcode": "461801",
            "subdistrictname": "ฆ้องชัยพัฒนา",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย",
            "subdistrictcode": "461802",
            "subdistrictname": "เหล่ากลาง",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย",
            "subdistrictcode": "461803",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย",
            "subdistrictcode": "461804",
            "subdistrictname": "โนนศิลาเลิง",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "กาฬสินธุ์",
            "districtcode": "4618",
            "districtname": "ฆ้องชัย",
            "subdistrictcode": "461805",
            "subdistrictname": "ลำชี",
            "zipcode": "46130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470101",
            "subdistrictname": "ธาตุเชิงชุม",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470102",
            "subdistrictname": "ขมิ้น",
            "zipcode": "47220"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470103",
            "subdistrictname": "งิ้วด่อน",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470104",
            "subdistrictname": "โนนหอม",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470106",
            "subdistrictname": "เชียงเครือ",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470107",
            "subdistrictname": "ท่าแร่",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470109",
            "subdistrictname": "ม่วงลาย",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470111",
            "subdistrictname": "ดงชน",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470112",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470113",
            "subdistrictname": "พังขว้าง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470115",
            "subdistrictname": "ดงมะไฟ",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470116",
            "subdistrictname": "ธาตุนาเวง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470117",
            "subdistrictname": "เหล่าปอแดง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470118",
            "subdistrictname": "หนองลาด",
            "zipcode": "47220"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470120",
            "subdistrictname": "ฮางโฮง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4701",
            "districtname": "เมืองสกลนคร",
            "subdistrictcode": "470121",
            "subdistrictname": "โคกก่อง",
            "zipcode": "47000"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์",
            "subdistrictcode": "470201",
            "subdistrictname": "กุสุมาลย์",
            "zipcode": "47210"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์",
            "subdistrictcode": "470202",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "47210"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์",
            "subdistrictcode": "470203",
            "subdistrictname": "นาเพียง",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์",
            "subdistrictcode": "470204",
            "subdistrictname": "โพธิไพศาล",
            "zipcode": "47210"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4702",
            "districtname": "กุสุมาลย์",
            "subdistrictcode": "470205",
            "subdistrictname": "อุ่มจาน",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4703",
            "districtname": "กุดบาก",
            "subdistrictcode": "470301",
            "subdistrictname": "กุดบาก",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4703",
            "districtname": "กุดบาก",
            "subdistrictcode": "470303",
            "subdistrictname": "นาม่อง",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4703",
            "districtname": "กุดบาก",
            "subdistrictcode": "470305",
            "subdistrictname": "กุดไห",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470401",
            "subdistrictname": "พรรณา",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470402",
            "subdistrictname": "วังยาง",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470403",
            "subdistrictname": "พอกน้อย",
            "zipcode": "47220"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470404",
            "subdistrictname": "นาหัวบ่อ",
            "zipcode": "47220"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470405",
            "subdistrictname": "ไร่",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470406",
            "subdistrictname": "ช้างมิ่ง",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470407",
            "subdistrictname": "นาใน",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470408",
            "subdistrictname": "สว่าง",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470409",
            "subdistrictname": "บะฮี",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4704",
            "districtname": "พรรณานิคม",
            "subdistrictcode": "470410",
            "subdistrictname": "เชิงชุม",
            "zipcode": "47130"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4705",
            "districtname": "พังโคน",
            "subdistrictcode": "470501",
            "subdistrictname": "พังโคน",
            "zipcode": "47160"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4705",
            "districtname": "พังโคน",
            "subdistrictcode": "470502",
            "subdistrictname": "ม่วงไข่",
            "zipcode": "47160"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4705",
            "districtname": "พังโคน",
            "subdistrictcode": "470503",
            "subdistrictname": "แร่",
            "zipcode": "47160"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4705",
            "districtname": "พังโคน",
            "subdistrictcode": "470504",
            "subdistrictname": "ไฮหย่อง",
            "zipcode": "47160"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4705",
            "districtname": "พังโคน",
            "subdistrictcode": "470505",
            "subdistrictname": "ต้นผึ้ง",
            "zipcode": "47160"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ",
            "subdistrictcode": "470601",
            "subdistrictname": "วาริชภูมิ",
            "zipcode": "47150"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ",
            "subdistrictcode": "470602",
            "subdistrictname": "ปลาโหล",
            "zipcode": "47150"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ",
            "subdistrictcode": "470603",
            "subdistrictname": "หนองลาด",
            "zipcode": "47150"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ",
            "subdistrictcode": "470604",
            "subdistrictname": "คำบ่อ",
            "zipcode": "47150"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4706",
            "districtname": "วาริชภูมิ",
            "subdistrictcode": "470605",
            "subdistrictname": "ค้อเขียว",
            "zipcode": "47150"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4707",
            "districtname": "นิคมน้ำอูน",
            "subdistrictcode": "470701",
            "subdistrictname": "นิคมน้ำอูน",
            "zipcode": "47270"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4707",
            "districtname": "นิคมน้ำอูน",
            "subdistrictcode": "470702",
            "subdistrictname": "หนองปลิง",
            "zipcode": "47270"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4707",
            "districtname": "นิคมน้ำอูน",
            "subdistrictcode": "470703",
            "subdistrictname": "หนองบัว",
            "zipcode": "47270"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4707",
            "districtname": "นิคมน้ำอูน",
            "subdistrictcode": "470704",
            "subdistrictname": "สุวรรณคาม",
            "zipcode": "47270"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470801",
            "subdistrictname": "วานรนิวาส",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470802",
            "subdistrictname": "เดื่อศรีคันไชย",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470803",
            "subdistrictname": "ขัวก่าย",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470804",
            "subdistrictname": "หนองสนม",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470805",
            "subdistrictname": "คูสะคาม",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470806",
            "subdistrictname": "ธาตุ",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470807",
            "subdistrictname": "หนองแวง",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470808",
            "subdistrictname": "ศรีวิชัย",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470809",
            "subdistrictname": "นาซอ",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470810",
            "subdistrictname": "อินทร์แปลง",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470811",
            "subdistrictname": "นาคำ",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470812",
            "subdistrictname": "คอนสวรรค์",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470813",
            "subdistrictname": "กุดเรือคำ",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4708",
            "districtname": "วานรนิวาส",
            "subdistrictcode": "470814",
            "subdistrictname": "หนองแวงใต้",
            "zipcode": "47120"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4709",
            "districtname": "คำตากล้า",
            "subdistrictcode": "470901",
            "subdistrictname": "คำตากล้า",
            "zipcode": "47250"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4709",
            "districtname": "คำตากล้า",
            "subdistrictcode": "470902",
            "subdistrictname": "หนองบัวสิม",
            "zipcode": "47250"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4709",
            "districtname": "คำตากล้า",
            "subdistrictcode": "470903",
            "subdistrictname": "นาแต้",
            "zipcode": "47250"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4709",
            "districtname": "คำตากล้า",
            "subdistrictcode": "470904",
            "subdistrictname": "แพด",
            "zipcode": "47250"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471001",
            "subdistrictname": "ม่วง",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471002",
            "subdistrictname": "มาย",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471003",
            "subdistrictname": "ดงหม้อทอง",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471004",
            "subdistrictname": "ดงเหนือ",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471005",
            "subdistrictname": "ดงหม้อทองใต้",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471006",
            "subdistrictname": "ห้วยหลัว",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471007",
            "subdistrictname": "โนนสะอาด",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471008",
            "subdistrictname": "หนองกวั่ง",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4710",
            "districtname": "บ้านม่วง",
            "subdistrictcode": "471009",
            "subdistrictname": "บ่อแก้ว",
            "zipcode": "47140"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471101",
            "subdistrictname": "อากาศ",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471102",
            "subdistrictname": "โพนแพง",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471103",
            "subdistrictname": "วาใหญ่",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471104",
            "subdistrictname": "โพนงาม",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471105",
            "subdistrictname": "ท่าก้อน",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471106",
            "subdistrictname": "นาฮี",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471107",
            "subdistrictname": "บะหว้า",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4711",
            "districtname": "อากาศอำนวย",
            "subdistrictcode": "471108",
            "subdistrictname": "สามัคคีพัฒนา",
            "zipcode": "47170"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471201",
            "subdistrictname": "สว่างแดนดิน",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471203",
            "subdistrictname": "คำสะอาด",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471204",
            "subdistrictname": "บ้านต้าย",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471206",
            "subdistrictname": "บงเหนือ",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471207",
            "subdistrictname": "โพนสูง",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471208",
            "subdistrictname": "โคกสี",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471210",
            "subdistrictname": "หนองหลวง",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471211",
            "subdistrictname": "บงใต้",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471212",
            "subdistrictname": "ค้อใต้",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471213",
            "subdistrictname": "พันนา",
            "zipcode": "47240"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471214",
            "subdistrictname": "แวง",
            "zipcode": "47240"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471215",
            "subdistrictname": "ทรายมูล",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471216",
            "subdistrictname": "ตาลโกน",
            "zipcode": "47240"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471217",
            "subdistrictname": "ตาลเนิ้ง",
            "zipcode": "47240"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471220",
            "subdistrictname": "ธาตุทอง",
            "zipcode": "47240"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4712",
            "districtname": "สว่างแดนดิน",
            "subdistrictcode": "471221",
            "subdistrictname": "บ้านถ่อน",
            "zipcode": "47110"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4713",
            "districtname": "ส่องดาว",
            "subdistrictcode": "471301",
            "subdistrictname": "ส่องดาว",
            "zipcode": "47190"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4713",
            "districtname": "ส่องดาว",
            "subdistrictcode": "471302",
            "subdistrictname": "ท่าศิลา",
            "zipcode": "47190"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4713",
            "districtname": "ส่องดาว",
            "subdistrictcode": "471303",
            "subdistrictname": "วัฒนา",
            "zipcode": "47190"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4713",
            "districtname": "ส่องดาว",
            "subdistrictcode": "471304",
            "subdistrictname": "ปทุมวาปี",
            "zipcode": "47190"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4714",
            "districtname": "เต่างอย",
            "subdistrictcode": "471401",
            "subdistrictname": "เต่างอย",
            "zipcode": "47260"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4714",
            "districtname": "เต่างอย",
            "subdistrictcode": "471402",
            "subdistrictname": "บึงทวาย",
            "zipcode": "47260"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4714",
            "districtname": "เต่างอย",
            "subdistrictcode": "471403",
            "subdistrictname": "นาตาล",
            "zipcode": "47260"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4714",
            "districtname": "เต่างอย",
            "subdistrictcode": "471404",
            "subdistrictname": "จันทร์เพ็ญ",
            "zipcode": "47260"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4715",
            "districtname": "โคกศรีสุพรรณ",
            "subdistrictcode": "471501",
            "subdistrictname": "ตองโขบ",
            "zipcode": "47280"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4715",
            "districtname": "โคกศรีสุพรรณ",
            "subdistrictcode": "471502",
            "subdistrictname": "เหล่าโพนค้อ",
            "zipcode": "47280"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4715",
            "districtname": "โคกศรีสุพรรณ",
            "subdistrictcode": "471503",
            "subdistrictname": "ด่านม่วงคำ",
            "zipcode": "47280"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4715",
            "districtname": "โคกศรีสุพรรณ",
            "subdistrictcode": "471504",
            "subdistrictname": "แมดนาท่ม",
            "zipcode": "47280"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์",
            "subdistrictcode": "471601",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "47290"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์",
            "subdistrictcode": "471602",
            "subdistrictname": "เจริญศิลป์",
            "zipcode": "47290"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์",
            "subdistrictcode": "471603",
            "subdistrictname": "ทุ่งแก",
            "zipcode": "47290"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์",
            "subdistrictcode": "471604",
            "subdistrictname": "โคกศิลา",
            "zipcode": "47290"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4716",
            "districtname": "เจริญศิลป์",
            "subdistrictcode": "471605",
            "subdistrictname": "หนองแปน",
            "zipcode": "47290"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว",
            "subdistrictcode": "471701",
            "subdistrictname": "บ้านโพน",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว",
            "subdistrictcode": "471702",
            "subdistrictname": "นาแก้ว",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว",
            "subdistrictcode": "471703",
            "subdistrictname": "นาตงวัฒนา",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว",
            "subdistrictcode": "471704",
            "subdistrictname": "บ้านแป้น",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4717",
            "districtname": "โพนนาแก้ว",
            "subdistrictcode": "471705",
            "subdistrictname": "เชียงสือ",
            "zipcode": "47230"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4718",
            "districtname": "ภูพาน",
            "subdistrictcode": "471801",
            "subdistrictname": "สร้างค้อ",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4718",
            "districtname": "ภูพาน",
            "subdistrictcode": "471802",
            "subdistrictname": "หลุบเลา",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4718",
            "districtname": "ภูพาน",
            "subdistrictcode": "471803",
            "subdistrictname": "โคกภู",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "สกลนคร",
            "districtcode": "4718",
            "districtname": "ภูพาน",
            "subdistrictcode": "471804",
            "subdistrictname": "กกปลาซิว",
            "zipcode": "47180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480101",
            "subdistrictname": "ในเมือง",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480102",
            "subdistrictname": "หนองแสง",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480103",
            "subdistrictname": "นาทราย",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480104",
            "subdistrictname": "นาราชควาย",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480105",
            "subdistrictname": "กุรุคุ",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480106",
            "subdistrictname": "บ้านผึ้ง",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480107",
            "subdistrictname": "อาจสามารถ",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480108",
            "subdistrictname": "ขามเฒ่า",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480109",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480110",
            "subdistrictname": "ท่าค้อ",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480111",
            "subdistrictname": "คำเตย",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480112",
            "subdistrictname": "หนองญาติ",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480113",
            "subdistrictname": "ดงขวาง",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480114",
            "subdistrictname": "วังตามัว",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4801",
            "districtname": "เมืองนครพนม",
            "subdistrictcode": "480115",
            "subdistrictname": "โพธิ์ตาก",
            "zipcode": "48000"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480201",
            "subdistrictname": "ปลาปาก",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480202",
            "subdistrictname": "หนองฮี",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480203",
            "subdistrictname": "กุตาไก้",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480204",
            "subdistrictname": "โคกสว่าง",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480205",
            "subdistrictname": "โคกสูง",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480206",
            "subdistrictname": "มหาชัย",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480207",
            "subdistrictname": "นามะเขือ",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4802",
            "districtname": "ปลาปาก",
            "subdistrictcode": "480208",
            "subdistrictname": "หนองเทาใหญ่",
            "zipcode": "48160"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480301",
            "subdistrictname": "ท่าอุเทน",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480302",
            "subdistrictname": "โนนตาล",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480303",
            "subdistrictname": "ท่าจำปา",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480304",
            "subdistrictname": "ไชยบุรี",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480305",
            "subdistrictname": "พนอม",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480306",
            "subdistrictname": "พะทาย",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480311",
            "subdistrictname": "เวินพระบาท",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480312",
            "subdistrictname": "รามราช",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4803",
            "districtname": "ท่าอุเทน",
            "subdistrictcode": "480314",
            "subdistrictname": "หนองเทา",
            "zipcode": "48120"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480401",
            "subdistrictname": "บ้านแพง",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480402",
            "subdistrictname": "ไผ่ล��อม",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480403",
            "subdistrictname": "โพนทอง",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480404",
            "subdistrictname": "หนองแวง",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480408",
            "subdistrictname": "นางัว",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4804",
            "districtname": "บ้านแพง",
            "subdistrictcode": "480409",
            "subdistrictname": "นาเข",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480501",
            "subdistrictname": "ธาตุพนม",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480502",
            "subdistrictname": "ฝั่งแดง",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480503",
            "subdistrictname": "โพนแพง",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480504",
            "subdistrictname": "พระกลางทุ่ง",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480505",
            "subdistrictname": "นาถ่อน",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480506",
            "subdistrictname": "แสนพัน",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480507",
            "subdistrictname": "ดอนนางหงส์",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480508",
            "subdistrictname": "น้ำก่ำ",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480509",
            "subdistrictname": "อุ่มเหม้า",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480510",
            "subdistrictname": "นาหนาด",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480511",
            "subdistrictname": "กุดฉิม",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4805",
            "districtname": "ธาตุพนม",
            "subdistrictcode": "480512",
            "subdistrictname": "ธาตุพนมเหนือ",
            "zipcode": "48110"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480601",
            "subdistrictname": "เรณู",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480602",
            "subdistrictname": "โพนทอง",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480603",
            "subdistrictname": "ท่าลาด",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480604",
            "subdistrictname": "นางาม",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480605",
            "subdistrictname": "โคกหินแฮ่",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480607",
            "subdistrictname": "หนองย่างชิ้น",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480608",
            "subdistrictname": "เรณูใต้",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4806",
            "districtname": "เรณูนคร",
            "subdistrictcode": "480609",
            "subdistrictname": "นาขาม",
            "zipcode": "48170"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480701",
            "subdistrictname": "นาแก",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480702",
            "subdistrictname": "พระซอง",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480703",
            "subdistrictname": "หนองสังข์",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480704",
            "subdistrictname": "นาคู่",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480705",
            "subdistrictname": "พิมาน",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480706",
            "subdistrictname": "พุ่มแก",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480707",
            "subdistrictname": "ก้านเหลือง",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480708",
            "subdistrictname": "หนองบ่อ",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480709",
            "subdistrictname": "นาเลียง",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480712",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480713",
            "subdistrictname": "คำพี้",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4807",
            "districtname": "นาแก",
            "subdistrictcode": "480715",
            "subdistrictname": "สีชมพู",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480801",
            "subdistrictname": "ศรีสงคราม",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480802",
            "subdistrictname": "นาเดื่อ",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480803",
            "subdistrictname": "บ้านเอื้อง",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480804",
            "subdistrictname": "สามผง",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480805",
            "subdistrictname": "ท่าบ่อสงคราม",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480806",
            "subdistrictname": "บ้านข่า",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480807",
            "subdistrictname": "นาคำ",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480808",
            "subdistrictname": "โพนสว่าง",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4808",
            "districtname": "ศรีสงคราม",
            "subdistrictcode": "480809",
            "subdistrictname": "หาดแพง",
            "zipcode": "48150"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480901",
            "subdistrictname": "นาหว้า",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480902",
            "subdistrictname": "นางัว",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480903",
            "subdistrictname": "บ้านเสียว",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480904",
            "subdistrictname": "นาคูณใหญ่",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480905",
            "subdistrictname": "เหล่าพัฒนา",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4809",
            "districtname": "นาหว้า",
            "subdistrictcode": "480906",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "48180"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481001",
            "subdistrictname": "โพนสวรรค์",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481002",
            "subdistrictname": "นาหัวบ่อ",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481003",
            "subdistrictname": "นาขมิ้น",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481004",
            "subdistrictname": "โพนบก",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481005",
            "subdistrictname": "บ้านค้อ",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481006",
            "subdistrictname": "โพนจาน",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4810",
            "districtname": "โพนสวรรค์",
            "subdistrictcode": "481007",
            "subdistrictname": "นาใน",
            "zipcode": "48190"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4811",
            "districtname": "นาทม",
            "subdistrictcode": "481101",
            "subdistrictname": "นาทม",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4811",
            "districtname": "นาทม",
            "subdistrictcode": "481102",
            "subdistrictname": "หนองซน",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4811",
            "districtname": "นาทม",
            "subdistrictcode": "481103",
            "subdistrictname": "ดอนเตย",
            "zipcode": "48140"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4812",
            "districtname": "วังยาง",
            "subdistrictcode": "481201",
            "subdistrictname": "วังยาง",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4812",
            "districtname": "วังยาง",
            "subdistrictcode": "481202",
            "subdistrictname": "โคกสี",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4812",
            "districtname": "วังยาง",
            "subdistrictcode": "481203",
            "subdistrictname": "ยอดชาด",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "นครพนม",
            "districtcode": "4812",
            "districtname": "วังยาง",
            "subdistrictcode": "481204",
            "subdistrictname": "หนองโพธิ์",
            "zipcode": "48130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490101",
            "subdistrictname": "มุกดาหาร",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490102",
            "subdistrictname": "ศรีบุญเรือง",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490103",
            "subdistrictname": "บ้านโคก",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490104",
            "subdistrictname": "บางทรายใหญ่",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490105",
            "subdistrictname": "โพนทราย",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490106",
            "subdistrictname": "ผึ่งแดด",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490107",
            "subdistrictname": "นาโสก",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490108",
            "subdistrictname": "นาสีนวน",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490109",
            "subdistrictname": "คำป่าหลาย",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490110",
            "subdistrictname": "คำอาฮวน",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490111",
            "subdistrictname": "ดงเย็น",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490112",
            "subdistrictname": "ดงมอน",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4901",
            "districtname": "เมืองมุกดาหาร",
            "subdistrictcode": "490113",
            "subdistrictname": "กุดแข้",
            "zipcode": "49000"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490201",
            "subdistrictname": "นิคมคำสร้อย",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490202",
            "subdistrictname": "นากอก",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490203",
            "subdistrictname": "หนองแวง",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490204",
            "subdistrictname": "กกแดง",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490205",
            "subdistrictname": "นาอุดม",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490206",
            "subdistrictname": "โชคชัย",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4902",
            "districtname": "นิคมคำสร้อย",
            "subdistrictcode": "490207",
            "subdistrictname": "ร่มเกล้า",
            "zipcode": "49130"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490301",
            "subdistrictname": "ดอนตาล",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490302",
            "subdistrictname": "โพธิ์ไทร",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490303",
            "subdistrictname": "ป่าไร่",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490304",
            "subdistrictname": "เหล่าหมี",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490305",
            "subdistrictname": "บ้านบาก",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490306",
            "subdistrictname": "นาสะเม็ง",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4903",
            "districtname": "ดอนตาล",
            "subdistrictcode": "490307",
            "subdistrictname": "บ้านแก้ง",
            "zipcode": "49120"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490401",
            "subdistrictname": "ดงหลวง",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490402",
            "subdistrictname": "หนองบัว",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490403",
            "subdistrictname": "กกตูม",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490404",
            "subdistrictname": "หนองแคน",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490405",
            "subdistrictname": "ชะโนดน้อย",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4904",
            "districtname": "ดงหลวง",
            "subdistrictcode": "490406",
            "subdistrictname": "พังแดง",
            "zipcode": "49140"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490503",
            "subdistrictname": "บ้านซ่ง",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490504",
            "subdistrictname": "คำชะอี",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490505",
            "subdistrictname": "หนองเอี่ยน",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490506",
            "subdistrictname": "บ้านค้อ",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490507",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490508",
            "subdistrictname": "โพนงาม",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490511",
            "subdistrictname": "เหล่าสร้างถ่อ",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490512",
            "subdistrictname": "คำบก",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4905",
            "districtname": "คำชะอี",
            "subdistrictcode": "490514",
            "subdistrictname": "น้ำเที่ยง",
            "zipcode": "49110"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่",
            "subdistrictcode": "490601",
            "subdistrictname": "หว้านใหญ่",
            "zipcode": "49150"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่",
            "subdistrictcode": "490602",
            "subdistrictname": "ป่งขาม",
            "zipcode": "49150"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่",
            "subdistrictcode": "490603",
            "subdistrictname": "บางทรายน้อย",
            "zipcode": "49150"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่",
            "subdistrictcode": "490604",
            "subdistrictname": "ชะโนด",
            "zipcode": "49150"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4906",
            "districtname": "หว้านใหญ่",
            "subdistrictcode": "490605",
            "subdistrictname": "ดงหมู",
            "zipcode": "49150"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490701",
            "subdistrictname": "หนองสูง",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490702",
            "subdistrictname": "โนนยาง",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490703",
            "subdistrictname": "ภูวง",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490704",
            "subdistrictname": "บ้านเป้า",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490705",
            "subdistrictname": "หนองสูงใต้",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "มุกดาหาร",
            "districtcode": "4907",
            "districtname": "หนองสูง",
            "subdistrictcode": "490706",
            "subdistrictname": "หนองสูงเหนือ",
            "zipcode": "49160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500101",
            "subdistrictname": "ศรีภูมิ",
            "zipcode": "50200"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500102",
            "subdistrictname": "พระสิงห์",
            "zipcode": "50200"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500103",
            "subdistrictname": "หายยา",
            "zipcode": "50100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500104",
            "subdistrictname": "ช้างม่อย",
            "zipcode": "50300"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500105",
            "subdistrictname": "ช้างคลาน",
            "zipcode": "50100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500106",
            "subdistrictname": "วัดเกต",
            "zipcode": "50000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500107",
            "subdistrictname": "ช้างเผือก",
            "zipcode": "50300"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500108",
            "subdistrictname": "สุเทพ",
            "zipcode": "50200"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500109",
            "subdistrictname": "แม่เหียะ",
            "zipcode": "50100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500110",
            "subdistrictname": "ป่าแดด",
            "zipcode": "50100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500111",
            "subdistrictname": "หนองหอย",
            "zipcode": "50000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500112",
            "subdistrictname": "ท่าศาลา",
            "zipcode": "50000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500113",
            "subdistrictname": "หนองป่าครั่ง",
            "zipcode": "50000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500114",
            "subdistrictname": "ฟ้าฮ่าม",
            "zipcode": "50000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500115",
            "subdistrictname": "ป่าตัน",
            "zipcode": "50300"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5001",
            "districtname": "เมืองเชียงใหม่",
            "subdistrictcode": "500116",
            "subdistrictname": "สันผีเสื้อ",
            "zipcode": "50300"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500203",
            "subdistrictname": "บ้านหลวง",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500204",
            "subdistrictname": "ข่วงเปา",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500205",
            "subdistrictname": "สบเตี๊ยะ",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500206",
            "subdistrictname": "บ้านแปะ",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500207",
            "subdistrictname": "ดอยแก้ว",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5002",
            "districtname": "จอมทอง",
            "subdistrictcode": "500209",
            "subdistrictname": "แม่สอย",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500301",
            "subdistrictname": "ช่างเคิ่ง",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500302",
            "subdistrictname": "ท่าผา",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500303",
            "subdistrictname": "บ้านทับ",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500304",
            "subdistrictname": "แม่ศึก",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500305",
            "subdistrictname": "แม่นาจร",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500306",
            "subdistrictname": "บ้านจันทร์",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500307",
            "subdistrictname": "ปางหินฝน",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500308",
            "subdistrictname": "กองแขก",
            "zipcode": "50270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500309",
            "subdistrictname": "แม่แดด",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5003",
            "districtname": "แม่แจ่ม",
            "subdistrictcode": "500310",
            "subdistrictname": "แจ่มหลวง",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500401",
            "subdistrictname": "เชียงดาว",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500402",
            "subdistrictname": "เมืองนะ",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500403",
            "subdistrictname": "เมืองงาย",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500404",
            "subdistrictname": "แม่นะ",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500405",
            "subdistrictname": "เมืองคอง",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500406",
            "subdistrictname": "ปิงโค้ง",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5004",
            "districtname": "เชียงดาว",
            "subdistrictcode": "500407",
            "subdistrictname": "ทุ่งข้าวพวง",
            "zipcode": "50170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500501",
            "subdistrictname": "เชิงดอย",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500502",
            "subdistrictname": "สันปูเลย",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500503",
            "subdistrictname": "ลวงเหนือ",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500504",
            "subdistrictname": "ป่าป้อง",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500505",
            "subdistrictname": "สง่าบ้าน",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500506",
            "subdistrictname": "ป่าลาน",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500507",
            "subdistrictname": "ตลาดขวัญ",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500508",
            "subdistrictname": "สำราญราษฎร์",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500509",
            "subdistrictname": "แม่คือ",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500510",
            "subdistrictname": "ตลาดใหญ่",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500511",
            "subdistrictname": "แม่ฮ้อยเงิน",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500512",
            "subdistrictname": "แม่โป่ง",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500513",
            "subdistrictname": "ป่าเมี่ยง",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5005",
            "districtname": "ดอยสะเก็ด",
            "subdistrictcode": "500514",
            "subdistrictname": "เทพเสด็จ",
            "zipcode": "50220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500601",
            "subdistrictname": "สันมหาพน",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500602",
            "subdistrictname": "แม่แตง",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500603",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500604",
            "subdistrictname": "ช่อแล",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500605",
            "subdistrictname": "แม่หอพระ",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500606",
            "subdistrictname": "สบเปิง",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500607",
            "subdistrictname": "บ้านเป้า",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500608",
            "subdistrictname": "สันป่ายาง",
            "zipcode": "50330"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500609",
            "subdistrictname": "ป่าแป๋",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500610",
            "subdistrictname": "เมืองก๋าย",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500611",
            "subdistrictname": "บ้านช้าง",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500612",
            "subdistrictname": "กื้ดช้าง",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500613",
            "subdistrictname": "อินทขิล",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5006",
            "districtname": "แม่แตง",
            "subdistrictcode": "500614",
            "subdistrictname": "สมก๋าย",
            "zipcode": "50150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500701",
            "subdistrictname": "ริมใต้",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500702",
            "subdistrictname": "ริมเหนือ",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500703",
            "subdistrictname": "สันโป่ง",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500704",
            "subdistrictname": "ขี้เหล็ก",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500705",
            "subdistrictname": "สะลวง",
            "zipcode": "50330"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500706",
            "subdistrictname": "ห้วยทราย",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500707",
            "subdistrictname": "แม่แรม",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500708",
            "subdistrictname": "โป่งแยง",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500709",
            "subdistrictname": "แม่สา",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500710",
            "subdistrictname": "ดอนแก้ว",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5007",
            "districtname": "แม่ริม",
            "subdistrictcode": "500711",
            "subdistrictname": "เหมืองแก้ว",
            "zipcode": "50180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5008",
            "districtname": "สะเมิง",
            "subdistrictcode": "500801",
            "subdistrictname": "สะเมิงใต้",
            "zipcode": "50250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5008",
            "districtname": "สะเมิง",
            "subdistrictcode": "500802",
            "subdistrictname": "สะเมิงเหนือ",
            "zipcode": "50250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5008",
            "districtname": "สะเมิง",
            "subdistrictcode": "500803",
            "subdistrictname": "แม่สาบ",
            "zipcode": "50250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5008",
            "districtname": "สะเมิง",
            "subdistrictcode": "500804",
            "subdistrictname": "บ่อแก้ว",
            "zipcode": "50250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5008",
            "districtname": "สะเมิง",
            "subdistrictcode": "500805",
            "subdistrictname": "ยั้งเมิน",
            "zipcode": "50250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500901",
            "subdistrictname": "เวียง",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500903",
            "subdistrictname": "ม่อนปิ่น",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500904",
            "subdistrictname": "แม่งอน",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500905",
            "subdistrictname": "แม่สูน",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500906",
            "subdistrictname": "สันทราย",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500910",
            "subdistrictname": "แม่คะ",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500911",
            "subdistrictname": "แม่ข่า",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5009",
            "districtname": "ฝาง",
            "subdistrictcode": "500912",
            "subdistrictname": "โป่งน้ำร้อน",
            "zipcode": "50110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501001",
            "subdistrictname": "แม่อาย",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501002",
            "subdistrictname": "แม่สาว",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501003",
            "subdistrictname": "สันต้นหมื้อ",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501004",
            "subdistrictname": "แม่นาวาง",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501005",
            "subdistrictname": "ท่าตอน",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501006",
            "subdistrictname": "บ้านหลวง",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5010",
            "districtname": "แม่อาย",
            "subdistrictcode": "501007",
            "subdistrictname": "มะลิกา",
            "zipcode": "50280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501101",
            "subdistrictname": "เวียง",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501102",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501103",
            "subdistrictname": "ป่าตุ้ม",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501104",
            "subdistrictname": "ป่าไหน่",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501105",
            "subdistrictname": "สันทราย",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501106",
            "subdistrictname": "บ้านโป่ง",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501107",
            "subdistrictname": "น้ำแพร่",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501108",
            "subdistrictname": "เขื่อนผาก",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501109",
            "subdistrictname": "แม่แวน",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501110",
            "subdistrictname": "แม่ปั๋ง",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5011",
            "districtname": "พร้าว",
            "subdistrictcode": "501111",
            "subdistrictname": "โหล่งขอด",
            "zipcode": "50190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501201",
            "subdistrictname": "ยุหว่า",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501202",
            "subdistrictname": "สันกลาง",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501203",
            "subdistrictname": "ท่าวังพร้าว",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501204",
            "subdistrictname": "มะขามหลวง",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501205",
            "subdistrictname": "แม่ก๊า",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501206",
            "subdistrictname": "บ้านแม",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501207",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501208",
            "subdistrictname": "ทุ่งสะโตก",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501210",
            "subdistrictname": "ทุ่งต้อม",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501214",
            "subdistrictname": "น้ำบ่อหลวง",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5012",
            "districtname": "สันป่าตอง",
            "subdistrictcode": "501215",
            "subdistrictname": "มะขุนหวาน",
            "zipcode": "50120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501301",
            "subdistrictname": "สันกำแพง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501302",
            "subdistrictname": "ทรายมูล",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501303",
            "subdistrictname": "ร้องวัวแดง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501304",
            "subdistrictname": "บวกค้าง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501305",
            "subdistrictname": "แช่ช้าง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501306",
            "subdistrictname": "ออนใต้",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501310",
            "subdistrictname": "แม่ปูคา",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501311",
            "subdistrictname": "ห้วยทราย",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501312",
            "subdistrictname": "ต้นเปา",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5013",
            "districtname": "สันกำแพง",
            "subdistrictcode": "501313",
            "subdistrictname": "สันกลาง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501401",
            "subdistrictname": "สันทรายหลวง",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501402",
            "subdistrictname": "สันทรายน้อย",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501403",
            "subdistrictname": "สันพระเนตร",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501404",
            "subdistrictname": "สันนาเม็ง",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501405",
            "subdistrictname": "สันป่าเปา",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501406",
            "subdistrictname": "หนองแหย่ง",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501407",
            "subdistrictname": "หนองจ๊อม",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501408",
            "subdistrictname": "หนองหาร",
            "zipcode": "50290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501409",
            "subdistrictname": "แม่แฝก",
            "zipcode": "50290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501410",
            "subdistrictname": "แม่แฝกใหม่",
            "zipcode": "50290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501411",
            "subdistrictname": "เมืองเล็น",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5014",
            "districtname": "สันทราย",
            "subdistrictcode": "501412",
            "subdistrictname": "ป่าไผ่",
            "zipcode": "50210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501501",
            "subdistrictname": "หางดง",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501502",
            "subdistrictname": "หนองแก๋ว",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501503",
            "subdistrictname": "หารแก้ว",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501504",
            "subdistrictname": "หนองตอง",
            "zipcode": "50340"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501505",
            "subdistrictname": "ขุนคง",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501506",
            "subdistrictname": "สบแม่ข่า",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501507",
            "subdistrictname": "บ้านแหวน",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501508",
            "subdistrictname": "สันผักหวาน",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501509",
            "subdistrictname": "หนองควาย",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501510",
            "subdistrictname": "บ้านปง",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5015",
            "districtname": "หางดง",
            "subdistrictcode": "501511",
            "subdistrictname": "น้ำแพร่",
            "zipcode": "50230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501601",
            "subdistrictname": "หางดง",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501602",
            "subdistrictname": "ฮอด",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501603",
            "subdistrictname": "บ้านตาล",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501604",
            "subdistrictname": "บ่อหลวง",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501605",
            "subdistrictname": "บ่อสลี",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5016",
            "districtname": "ฮอด",
            "subdistrictcode": "501606",
            "subdistrictname": "นาคอเรือ",
            "zipcode": "50240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501701",
            "subdistrictname": "ดอยเต่า",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501702",
            "subdistrictname": "ท่าเดื่อ",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501703",
            "subdistrictname": "มืดกา",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501704",
            "subdistrictname": "บ้านแอ่น",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501705",
            "subdistrictname": "บงตัน",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5017",
            "districtname": "ดอยเต่า",
            "subdistrictcode": "501706",
            "subdistrictname": "โปงทุ่ง",
            "zipcode": "50260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501801",
            "subdistrictname": "อมก๋อย",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501802",
            "subdistrictname": "ยางเปียง",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501803",
            "subdistrictname": "แม่ตื่น",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501804",
            "subdistrictname": "ม่อนจอง",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501805",
            "subdistrictname": "สบโขง",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5018",
            "districtname": "อมก๋อย",
            "subdistrictcode": "501806",
            "subdistrictname": "นาเกียน",
            "zipcode": "50310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501901",
            "subdistrictname": "ยางเนิ้ง",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501902",
            "subdistrictname": "สารภี",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501903",
            "subdistrictname": "ชมภู",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501904",
            "subdistrictname": "ไชยสถาน",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501905",
            "subdistrictname": "ขัวมุง",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501906",
            "subdistrictname": "หนองแฝก",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501907",
            "subdistrictname": "หนองผึ้ง",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501908",
            "subdistrictname": "ท่ากว้าง",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501909",
            "subdistrictname": "ดอนแก้ว",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501910",
            "subdistrictname": "ท่าวังตาล",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501911",
            "subdistrictname": "สันทราย",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5019",
            "districtname": "สารภี",
            "subdistrictcode": "501912",
            "subdistrictname": "ป่าบง",
            "zipcode": "50140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5020",
            "districtname": "เวียงแหง",
            "subdistrictcode": "502001",
            "subdistrictname": "เมืองแหง",
            "zipcode": "50350"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5020",
            "districtname": "เวียงแหง",
            "subdistrictcode": "502002",
            "subdistrictname": "เปียงหลวง",
            "zipcode": "50350"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5020",
            "districtname": "เวียงแหง",
            "subdistrictcode": "502003",
            "subdistrictname": "แสนไห",
            "zipcode": "50350"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5021",
            "districtname": "ไชยปราการ",
            "subdistrictcode": "502101",
            "subdistrictname": "ปงตำ",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5021",
            "districtname": "ไชยปราการ",
            "subdistrictcode": "502102",
            "subdistrictname": "ศรีดงเย็น",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5021",
            "districtname": "ไชยปราการ",
            "subdistrictcode": "502103",
            "subdistrictname": "แม่ทะลบ",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5021",
            "districtname": "ไชยปราการ",
            "subdistrictcode": "502104",
            "subdistrictname": "หนองบัว",
            "zipcode": "50320"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5022",
            "districtname": "แม่วาง",
            "subdistrictcode": "502201",
            "subdistrictname": "บ้านกาด",
            "zipcode": "50360"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5022",
            "districtname": "แม่วาง",
            "subdistrictcode": "502202",
            "subdistrictname": "ทุ่งปี้",
            "zipcode": "50360"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5022",
            "districtname": "แม่วาง",
            "subdistrictcode": "502203",
            "subdistrictname": "ทุ่งรวงทอง",
            "zipcode": "50360"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5022",
            "districtname": "แม่วาง",
            "subdistrictcode": "502204",
            "subdistrictname": "แม่วิน",
            "zipcode": "50360"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5022",
            "districtname": "แม่วาง",
            "subdistrictcode": "502205",
            "subdistrictname": "ดอนเปา",
            "zipcode": "50360"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502301",
            "subdistrictname": "ออนเหนือ",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502302",
            "subdistrictname": "ออนกลาง",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502303",
            "subdistrictname": "บ้านสหกรณ์",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502304",
            "subdistrictname": "ห้วยแก้ว",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502305",
            "subdistrictname": "แม่ทา",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5023",
            "districtname": "แม่ออน",
            "subdistrictcode": "502306",
            "subdistrictname": "ทาเหนือ",
            "zipcode": "50130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5024",
            "districtname": "ดอยหล่อ",
            "subdistrictcode": "502401",
            "subdistrictname": "ดอยหล่อ",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5024",
            "districtname": "ดอยหล่อ",
            "subdistrictcode": "502402",
            "subdistrictname": "สองแคว",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5024",
            "districtname": "ดอยหล่อ",
            "subdistrictcode": "502403",
            "subdistrictname": "ยางคราม",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงใหม่",
            "districtcode": "5024",
            "districtname": "ดอยหล่อ",
            "subdistrictcode": "502404",
            "subdistrictname": "สันติสุข",
            "zipcode": "50160"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510101",
            "subdistrictname": "ในเมือง",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510102",
            "subdistrictname": "เหมืองง่า",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510103",
            "subdistrictname": "อุโมงค์",
            "zipcode": "51150"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510104",
            "subdistrictname": "หนองช้างคืน",
            "zipcode": "51150"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510105",
            "subdistrictname": "ประตูป่า",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510106",
            "subdistrictname": "ริมปิง",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510107",
            "subdistrictname": "ต้นธง",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510108",
            "subdistrictname": "บ้านแป้น",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510109",
            "subdistrictname": "เหมืองจี้",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510110",
            "subdistrictname": "ป่าสัก",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510111",
            "subdistrictname": "เวียงยอง",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510112",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510113",
            "subdistrictname": "มะเขือแจ้",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510116",
            "subdistrictname": "ศรีบัวบาน",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5101",
            "districtname": "เมืองลำพูน",
            "subdistrictcode": "510117",
            "subdistrictname": "หนองหนาม",
            "zipcode": "51000"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510201",
            "subdistrictname": "ทาปลาดุก",
            "zipcode": "51140"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510202",
            "subdistrictname": "ทาสบเส้า",
            "zipcode": "51140"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510203",
            "subdistrictname": "ทากาศ",
            "zipcode": "51170"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510204",
            "subdistrictname": "ทาขุมเงิน",
            "zipcode": "51170"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510205",
            "subdistrictname": "ทาทุ่งหลวง",
            "zipcode": "51170"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5102",
            "districtname": "แม่ทา",
            "subdistrictcode": "510206",
            "subdistrictname": "ทาแม่ลอบ",
            "zipcode": "51170"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง",
            "subdistrictcode": "510301",
            "subdistrictname": "บ้านโฮ่ง",
            "zipcode": "51130"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง",
            "subdistrictcode": "510302",
            "subdistrictname": "ป่าพลู",
            "zipcode": "51130"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง",
            "subdistrictcode": "510303",
            "subdistrictname": "เหล่ายาว",
            "zipcode": "51130"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง",
            "subdistrictcode": "510304",
            "subdistrictname": "ศรีเตี้ย",
            "zipcode": "51130"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5103",
            "districtname": "บ้านโฮ่ง",
            "subdistrictcode": "510305",
            "subdistrictname": "หนองปลาสะวาย",
            "zipcode": "51130"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510401",
            "subdistrictname": "ลี้",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510402",
            "subdistrictname": "แม่ตืน",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510403",
            "subdistrictname": "นาทราย",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510404",
            "subdistrictname": "ดงดำ",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510405",
            "subdistrictname": "ก้อ",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510406",
            "subdistrictname": "แม่ลาน",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510408",
            "subdistrictname": "ป่าไผ่",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5104",
            "districtname": "ลี้",
            "subdistrictcode": "510409",
            "subdistrictname": "ศรีวิชัย",
            "zipcode": "51110"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5105",
            "districtname": "ทุ่งหัวช้าง",
            "subdistrictcode": "510501",
            "subdistrictname": "ทุ่งหัวช้าง",
            "zipcode": "51160"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5105",
            "districtname": "ทุ่งหัวช้าง",
            "subdistrictcode": "510502",
            "subdistrictname": "บ้านปวง",
            "zipcode": "51160"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5105",
            "districtname": "ทุ่งหัวช้าง",
            "subdistrictcode": "510503",
            "subdistrictname": "ตะเคียนปม",
            "zipcode": "51160"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510601",
            "subdistrictname": "ปากบ่อง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510602",
            "subdistrictname": "ป่าซาง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510603",
            "subdistrictname": "แม่แรง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510604",
            "subdistrictname": "ม่วงน้อย",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510605",
            "subdistrictname": "บ้านเรือน",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510606",
            "subdistrictname": "มะกอก",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510607",
            "subdistrictname": "ท่าตุ้ม",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510608",
            "subdistrictname": "น้ำดิบ",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5106",
            "districtname": "ป่าซาง",
            "subdistrictcode": "510611",
            "subdistrictname": "นครเจดีย์",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5107",
            "districtname": "บ้านธิ",
            "subdistrictcode": "510701",
            "subdistrictname": "บ้านธิ",
            "zipcode": "51180"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5107",
            "districtname": "บ้านธิ",
            "subdistrictcode": "510702",
            "subdistrictname": "ห้วยยาบ",
            "zipcode": "51180"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5108",
            "districtname": "เวียงหนองล่อง",
            "subdistrictcode": "510801",
            "subdistrictname": "หนองล่อง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5108",
            "districtname": "เวียงหนองล่อง",
            "subdistrictcode": "510802",
            "subdistrictname": "หนองยวง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำพูน",
            "districtcode": "5108",
            "districtname": "เวียงหนองล่อง",
            "subdistrictcode": "510803",
            "subdistrictname": "วังผาง",
            "zipcode": "51120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520101",
            "subdistrictname": "เวียงเหนือ",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520102",
            "subdistrictname": "หัวเวียง",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520103",
            "subdistrictname": "สวนดอก",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520104",
            "subdistrictname": "สบตุ๋ย",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520105",
            "subdistrictname": "พระบาท",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520106",
            "subdistrictname": "ชมพู",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520107",
            "subdistrictname": "กล้วยแพะ",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520108",
            "subdistrictname": "ปงแสนทอง",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520109",
            "subdistrictname": "บ้านแลง",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520110",
            "subdistrictname": "บ้านเสด็จ",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520111",
            "subdistrictname": "พิชัย",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520112",
            "subdistrictname": "ทุ่งฝาย",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520113",
            "subdistrictname": "บ้านเอื้อม",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520114",
            "subdistrictname": "บ้านเป้า",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520115",
            "subdistrictname": "บ้านค่า",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520116",
            "subdistrictname": "บ่อแฮ้ว",
            "zipcode": "52100"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520117",
            "subdistrictname": "ต้นธงชัย",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520118",
            "subdistrictname": "นิคมพัฒนา",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5201",
            "districtname": "เมืองลำปาง",
            "subdistrictcode": "520119",
            "subdistrictname": "บุญนาคพัฒนา",
            "zipcode": "52000"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5202",
            "districtname": "แม่เมาะ",
            "subdistrictcode": "520201",
            "subdistrictname": "บ้านดง",
            "zipcode": "52220"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5202",
            "districtname": "แม่เมาะ",
            "subdistrictcode": "520202",
            "subdistrictname": "นาสัก",
            "zipcode": "52220"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5202",
            "districtname": "แม่เมาะ",
            "subdistrictcode": "520203",
            "subdistrictname": "จางเหนือ",
            "zipcode": "52220"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5202",
            "districtname": "แม่เมาะ",
            "subdistrictcode": "520204",
            "subdistrictname": "แม่เมาะ",
            "zipcode": "52220"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5202",
            "districtname": "แม่เมาะ",
            "subdistrictcode": "520205",
            "subdistrictname": "สบป้าด",
            "zipcode": "52220"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520301",
            "subdistrictname": "ลำปางหลวง",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520302",
            "subdistrictname": "นาแก้ว",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520303",
            "subdistrictname": "ไหล่หิน",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520304",
            "subdistrictname": "วังพร้าว",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520305",
            "subdistrictname": "ศาลา",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะ��า",
            "subdistrictcode": "520306",
            "subdistrictname": "เกาะคา",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520307",
            "subdistrictname": "นาแส่ง",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520308",
            "subdistrictname": "ท่าผา",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5203",
            "districtname": "เกาะคา",
            "subdistrictcode": "520309",
            "subdistrictname": "ใหม่พัฒนา",
            "zipcode": "52130"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5204",
            "districtname": "เสริมงาม",
            "subdistrictcode": "520401",
            "subdistrictname": "ทุ่งงาม",
            "zipcode": "52210"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5204",
            "districtname": "เสริมงาม",
            "subdistrictcode": "520402",
            "subdistrictname": "เสริมขวา",
            "zipcode": "52210"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5204",
            "districtname": "เสริมงาม",
            "subdistrictcode": "520403",
            "subdistrictname": "เสริมซ้าย",
            "zipcode": "52210"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5204",
            "districtname": "เสริมงาม",
            "subdistrictcode": "520404",
            "subdistrictname": "เสริมกลาง",
            "zipcode": "52210"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520501",
            "subdistrictname": "หลวงเหนือ",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520502",
            "subdistrictname": "หลวงใต้",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520503",
            "subdistrictname": "บ้านโป่ง",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520504",
            "subdistrictname": "บ้านร้อง",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520505",
            "subdistrictname": "ปงเตา",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520506",
            "subdistrictname": "นาแก",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520507",
            "subdistrictname": "บ้านอ้อน",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520508",
            "subdistrictname": "บ้านแหง",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520509",
            "subdistrictname": "บ้านหวด",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5205",
            "districtname": "งาว",
            "subdistrictcode": "520510",
            "subdistrictname": "แม่ตีบ",
            "zipcode": "52110"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520601",
            "subdistrictname": "แจ้ห่ม",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520602",
            "subdistrictname": "บ้านสา",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520603",
            "subdistrictname": "ปงดอน",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520604",
            "subdistrictname": "แม่สุก",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520605",
            "subdistrictname": "เมืองมาย",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520606",
            "subdistrictname": "ทุ่งผึ้ง",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5206",
            "districtname": "แจ้ห่ม",
            "subdistrictcode": "520607",
            "subdistrictname": "วิเชตนคร",
            "zipcode": "52120"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520701",
            "subdistrictname": "ทุ่งฮั้ว",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520702",
            "subdistrictname": "วังเหนือ",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520703",
            "subdistrictname": "วังใต้",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520704",
            "subdistrictname": "ร่องเคาะ",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520705",
            "subdistrictname": "วังทอง",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520706",
            "subdistrictname": "วังซ้าย",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520707",
            "subdistrictname": "วังแก้ว",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5207",
            "districtname": "วังเหนือ",
            "subdistrictcode": "520708",
            "subdistrictname": "วังทรายคำ",
            "zipcode": "52140"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520801",
            "subdistrictname": "ล้อมแรด",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520802",
            "subdistrictname": "แม่วะ",
            "zipcode": "52230"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520803",
            "subdistrictname": "แม่ปะ",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520804",
            "subdistrictname": "แม่มอก",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520805",
            "subdistrictname": "เวียงมอก",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520806",
            "subdistrictname": "นาโป่ง",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520807",
            "subdistrictname": "แม่ถอด",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5208",
            "districtname": "เถิน",
            "subdistrictcode": "520808",
            "subdistrictname": "เถินบุรี",
            "zipcode": "52160"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5209",
            "districtname": "แม่พริก",
            "subdistrictcode": "520901",
            "subdistrictname": "แม่พริก",
            "zipcode": "52180"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5209",
            "districtname": "แม่พริก",
            "subdistrictcode": "520902",
            "subdistrictname": "ผาปัง",
            "zipcode": "52180"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5209",
            "districtname": "แม่พริก",
            "subdistrictcode": "520903",
            "subdistrictname": "แม่ปุ",
            "zipcode": "52180"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5209",
            "districtname": "แม่พริก",
            "subdistrictcode": "520904",
            "subdistrictname": "พระบาทวังตวง",
            "zipcode": "52180"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521001",
            "subdistrictname": "แม่ทะ",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521002",
            "subdistrictname": "นาครัว",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521003",
            "subdistrictname": "ป่าตัน",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521004",
            "subdistrictname": "บ้านกิ่ว",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521005",
            "subdistrictname": "บ้านบอม",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521006",
            "subdistrictname": "น้ำโจ้",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521007",
            "subdistrictname": "ดอนไฟ",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521008",
            "subdistrictname": "หัวเสือ",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521010",
            "subdistrictname": "วังเงิน",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5210",
            "districtname": "แม่ทะ",
            "subdistrictcode": "521011",
            "subdistrictname": "สันดอนแก้ว",
            "zipcode": "52150"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5211",
            "districtname": "สบปราบ",
            "subdistrictcode": "521101",
            "subdistrictname": "สบปราบ",
            "zipcode": "52170"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5211",
            "districtname": "สบปราบ",
            "subdistrictcode": "521102",
            "subdistrictname": "สมัย",
            "zipcode": "52170"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5211",
            "districtname": "สบปราบ",
            "subdistrictcode": "521103",
            "subdistrictname": "แม่กัวะ",
            "zipcode": "52170"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5211",
            "districtname": "สบปราบ",
            "subdistrictcode": "521104",
            "subdistrictname": "นายาง",
            "zipcode": "52170"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521201",
            "subdistrictname": "ห้างฉัตร",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521202",
            "subdistrictname": "หนองหล่ม",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521203",
            "subdistrictname": "เมืองยาว",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521204",
            "subdistrictname": "ปงยางคก",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521205",
            "subdistrictname": "เวียงตาล",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521206",
            "subdistrictname": "แม่สัน",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5212",
            "districtname": "ห้างฉัตร",
            "subdistrictcode": "521207",
            "subdistrictname": "วอแก้ว",
            "zipcode": "52190"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5213",
            "districtname": "เมืองปาน",
            "subdistrictcode": "521301",
            "subdistrictname": "เมืองปาน",
            "zipcode": "52240"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5213",
            "districtname": "เมืองปาน",
            "subdistrictcode": "521302",
            "subdistrictname": "บ้านขอ",
            "zipcode": "52240"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5213",
            "districtname": "เมืองปาน",
            "subdistrictcode": "521303",
            "subdistrictname": "ทุ่งกว๋าว",
            "zipcode": "52240"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5213",
            "districtname": "เมืองปาน",
            "subdistrictcode": "521304",
            "subdistrictname": "แจ้ซ้อน",
            "zipcode": "52240"
        },
        {
            "isActive": true,
            "provincecode": "ลำปาง",
            "districtcode": "5213",
            "districtname": "เมืองปาน",
            "subdistrictcode": "521305",
            "subdistrictname": "หัวเมือง",
            "zipcode": "52240"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530101",
            "subdistrictname": "ท่าอิฐ",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530102",
            "subdistrictname": "ท่าเสา",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530103",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530104",
            "subdistrictname": "ป่าเซ่า",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530105",
            "subdistrictname": "คุ้งตะเภา",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530106",
            "subdistrictname": "วังกะพี้",
            "zipcode": "53170"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530107",
            "subdistrictname": "หาดกรวด",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530108",
            "subdistrictname": "น้ำริด",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530109",
            "subdistrictname": "งิ้วงาม",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530110",
            "subdistrictname": "บ้านด่านนาขาม",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530111",
            "subdistrictname": "บ้านด่าน",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530112",
            "subdistrictname": "ผาจุก",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530113",
            "subdistrictname": "วังดิน",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530114",
            "subdistrictname": "แสนตอ",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530115",
            "subdistrictname": "หาดงิ้ว",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530116",
            "subdistrictname": "ขุนฝาง",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5301",
            "districtname": "เมืองอุตรดิตถ์",
            "subdistrictcode": "530117",
            "subdistrictname": "ถ้ำฉลอง",
            "zipcode": "53000"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5302",
            "districtname": "ตรอน",
            "subdistrictcode": "530201",
            "subdistrictname": "วังแดง",
            "zipcode": "53140"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5302",
            "districtname": "ตรอน",
            "subdistrictcode": "530202",
            "subdistrictname": "บ้านแก่ง",
            "zipcode": "53140"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5302",
            "districtname": "ตรอน",
            "subdistrictcode": "530203",
            "subdistrictname": "หาดสองแคว",
            "zipcode": "53140"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5302",
            "districtname": "ตรอน",
            "subdistrictcode": "530204",
            "subdistrictname": "น้ำอ่าง",
            "zipcode": "53140"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5302",
            "districtname": "ตรอน",
            "subdistrictcode": "530205",
            "subdistrictname": "ข่อยสูง",
            "zipcode": "53140"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530301",
            "subdistrictname": "ท่าปลา",
            "zipcode": "53150"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530302",
            "subdistrictname": "หาดล้า",
            "zipcode": "53150"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530303",
            "subdistrictname": "ผาเลือด",
            "zipcode": "53190"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530304",
            "subdistrictname": "จริม",
            "zipcode": "53150"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530305",
            "subdistrictname": "น้ำหมัน",
            "zipcode": "53150"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530306",
            "subdistrictname": "ท่าแฝก",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530307",
            "subdistrictname": "นางพญา",
            "zipcode": "53150"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5303",
            "districtname": "ท่าปลา",
            "subdistrictcode": "530308",
            "subdistrictname": "ร่วมจิต",
            "zipcode": "53190"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530401",
            "subdistrictname": "แสนตอ",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530402",
            "subdistrictname": "บ้านฝาย",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530403",
            "subdistrictname": "เด่นเหล็ก",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530404",
            "subdistrictname": "น้ำไคร้",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530405",
            "subdistrictname": "น้ำไผ่",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5304",
            "districtname": "น้ำปาด",
            "subdistrictcode": "530406",
            "subdistrictname": "ห้วยมุ่น",
            "zipcode": "53110"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5305",
            "districtname": "ฟากท่า",
            "subdistrictcode": "530501",
            "subdistrictname": "ฟากท่า",
            "zipcode": "53160"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5305",
            "districtname": "ฟากท่า",
            "subdistrictcode": "530502",
            "subdistrictname": "สองคอน",
            "zipcode": "53160"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5305",
            "districtname": "ฟากท่า",
            "subdistrictcode": "530503",
            "subdistrictname": "บ้านเสี้ยว",
            "zipcode": "53160"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5305",
            "districtname": "ฟากท่า",
            "subdistrictcode": "530504",
            "subdistrictname": "สองห้อง",
            "zipcode": "53160"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5306",
            "districtname": "บ้านโคก",
            "subdistrictcode": "530601",
            "subdistrictname": "ม่วงเจ็ดต้น",
            "zipcode": "53180"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5306",
            "districtname": "บ้านโคก",
            "subdistrictcode": "530602",
            "subdistrictname": "บ้านโคก",
            "zipcode": "53180"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5306",
            "districtname": "บ้านโคก",
            "subdistrictcode": "530603",
            "subdistrictname": "นาขุม",
            "zipcode": "53180"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5306",
            "districtname": "บ้านโคก",
            "subdistrictcode": "530604",
            "subdistrictname": "บ่อเบี้ย",
            "zipcode": "53180"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530701",
            "subdistrictname": "ในเมือง",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530702",
            "subdistrictname": "บ้านดารา",
            "zipcode": "53220"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530703",
            "subdistrictname": "ไร่อ้อย",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530704",
            "subdistrictname": "ท่าสัก",
            "zipcode": "53220"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530705",
            "subdistrictname": "คอรุม",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530706",
            "subdistrictname": "บ้านหม้อ",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530707",
            "subdistrictname": "ท่ามะเฟือง",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530708",
            "subdistrictname": "บ้านโคน",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530709",
            "subdistrictname": "พญาแมน",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530710",
            "subdistrictname": "นาอิน",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5307",
            "districtname": "พิชัย",
            "subdistrictcode": "530711",
            "subdistrictname": "นายาง",
            "zipcode": "53120"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530801",
            "subdistrictname": "ศรีพนมมาศ",
            "zipcode": "53130"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530802",
            "subdistrictname": "แม่พูล",
            "zipcode": "53130"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530803",
            "subdistrictname": "นานกกก",
            "zipcode": "53130"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530804",
            "subdistrictname": "ฝายหลวง",
            "zipcode": "53130"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530805",
            "subdistrictname": "ชัยจุมพล",
            "zipcode": "53130"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530806",
            "subdistrictname": "ไผ่ล้อม",
            "zipcode": "53210"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530807",
            "subdistrictname": "ทุ่งยั้ง",
            "zipcode": "53210"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5308",
            "districtname": "ลับแล",
            "subdistrictcode": "530808",
            "subdistrictname": "ด่านแม่คำมัน",
            "zipcode": "53210"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5309",
            "districtname": "ทองแสนขัน",
            "subdistrictcode": "530901",
            "subdistrictname": "ผักขวง",
            "zipcode": "53230"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5309",
            "districtname": "ทองแสนขัน",
            "subdistrictcode": "530902",
            "subdistrictname": "บ่อทอง",
            "zipcode": "53230"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5309",
            "districtname": "ทองแสนขัน",
            "subdistrictcode": "530903",
            "subdistrictname": "ป่าคาย",
            "zipcode": "53230"
        },
        {
            "isActive": true,
            "provincecode": "อุตรดิตถ์",
            "districtcode": "5309",
            "districtname": "ทองแสนขัน",
            "subdistrictcode": "530904",
            "subdistrictname": "น้ำพี้",
            "zipcode": "53230"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540101",
            "subdistrictname": "ในเวียง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540102",
            "subdistrictname": "นาจักร",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540103",
            "subdistrictname": "น้ำชำ",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540104",
            "subdistrictname": "ป่าแดง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540105",
            "subdistrictname": "ทุ่งโฮ้ง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540106",
            "subdistrictname": "เหมืองหม้อ",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540107",
            "subdistrictname": "วังธง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540108",
            "subdistrictname": "แม่หล่าย",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540109",
            "subdistrictname": "ห้วยม้า",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540110",
            "subdistrictname": "ป่าแมต",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540111",
            "subdistrictname": "บ้านถิ่น",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540112",
            "subdistrictname": "สวนเขื่อน",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540113",
            "subdistrictname": "วังหงส์",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540114",
            "subdistrictname": "แม่คำมี",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540115",
            "subdistrictname": "ทุ่งกวาว",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540116",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540117",
            "subdistrictname": "แม่ยม",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540118",
            "subdistrictname": "ช่อแฮ",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540119",
            "subdistrictname": "ร่องฟอง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5401",
            "districtname": "เมืองแพร่",
            "subdistrictcode": "540120",
            "subdistrictname": "กาญจนา",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540201",
            "subdistrictname": "ร้องกวาง",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540204",
            "subdistrictname": "ร้องเข็ม",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540205",
            "subdistrictname": "น้ำเลา",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540206",
            "subdistrictname": "บ้านเวียง",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540207",
            "subdistrictname": "ทุ่งศรี",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540208",
            "subdistrictname": "แม่ยางตาล",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540209",
            "subdistrictname": "แม่ยางฮ่อ",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540210",
            "subdistrictname": "ไผ่โทน",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540213",
            "subdistrictname": "ห้วยโรง",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540214",
            "subdistrictname": "แม่ทราย",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5402",
            "districtname": "ร้องกวาง",
            "subdistrictcode": "540215",
            "subdistrictname": "แม่ยางร้อง",
            "zipcode": "54140"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540301",
            "subdistrictname": "ห้วยอ้อ",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540302",
            "subdistrictname": "บ้านปิน",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540303",
            "subdistrictname": "ต้าผามอก",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540304",
            "subdistrictname": "เวียงต้า",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540305",
            "subdistrictname": "ปากกาง",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540306",
            "subdistrictname": "หัวทุ่ง",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540307",
            "subdistrictname": "ทุ่งแล้ง",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540308",
            "subdistrictname": "บ่อเหล็กลอง",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5403",
            "districtname": "ลอง",
            "subdistrictcode": "540309",
            "subdistrictname": "แม่ปาน",
            "zipcode": "54150"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540401",
            "subdistrictname": "สูงเม่น",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540402",
            "subdistrictname": "น้ำชำ",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540403",
            "subdistrictname": "หัวฝาย",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540404",
            "subdistrictname": "ดอนมูล",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540405",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540406",
            "subdistrictname": "บ้านกวาง",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540407",
            "subdistrictname": "บ้านปง",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540408",
            "subdistrictname": "บ้านกาศ",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540409",
            "subdistrictname": "ร่องกาศ",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540410",
            "subdistrictname": "สบสาย",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540411",
            "subdistrictname": "เวียงทอง",
            "zipcode": "54000"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5404",
            "districtname": "สูงเม่น",
            "subdistrictcode": "540412",
            "subdistrictname": "พระหลวง",
            "zipcode": "54130"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5405",
            "districtname": "เด่นชัย",
            "subdistrictcode": "540501",
            "subdistrictname": "เด่นชัย",
            "zipcode": "54110"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5405",
            "districtname": "เด่นชัย",
            "subdistrictcode": "540502",
            "subdistrictname": "แม่จั๊วะ",
            "zipcode": "54110"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5405",
            "districtname": "เด่นชัย",
            "subdistrictcode": "540503",
            "subdistrictname": "ไทรย้อย",
            "zipcode": "54110"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5405",
            "districtname": "เด่นชัย",
            "subdistrictcode": "540504",
            "subdistrictname": "ห้วยไร่",
            "zipcode": "54110"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5405",
            "districtname": "เด่นชัย",
            "subdistrictcode": "540505",
            "subdistrictname": "ปงป่าหวาย",
            "zipcode": "54110"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540601",
            "subdistrictname": "บ้านหนุน",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540602",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540603",
            "subdistrictname": "ห้วยหม้าย",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540604",
            "subdistrictname": "เตาปูน",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540605",
            "subdistrictname": "หัวเมือง",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540606",
            "subdistrictname": "สะเอียบ",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540607",
            "subdistrictname": "แดนชุมพล",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5406",
            "districtname": "สอง",
            "subdistrictcode": "540608",
            "subdistrictname": "ทุ่งน้าว",
            "zipcode": "54120"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540701",
            "subdistrictname": "วังชิ้น",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540702",
            "subdistrictname": "สรอย",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540703",
            "subdistrictname": "แม่ป้าก",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540704",
            "subdistrictname": "นาพูน",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540705",
            "subdistrictname": "แม่พุง",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540706",
            "subdistrictname": "ป่าสัก",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5407",
            "districtname": "วังชิ้น",
            "subdistrictcode": "540707",
            "subdistrictname": "แม่เกิ๋ง",
            "zipcode": "54160"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540801",
            "subdistrictname": "แม่คำมี",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540802",
            "subdistrictname": "หนองม่วงไข่",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540803",
            "subdistrictname": "น้ำรัด",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540804",
            "subdistrictname": "วังหลวง",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540805",
            "subdistrictname": "ตำหนักธรรม",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "แพร่",
            "districtcode": "5408",
            "districtname": "หนองม่วงไข่",
            "subdistrictcode": "540806",
            "subdistrictname": "ทุ่งแค้ว",
            "zipcode": "54170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550101",
            "subdistrictname": "ในเวียง",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550102",
            "subdistrictname": "บ่อ",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550103",
            "subdistrictname": "ผาสิงห์",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550104",
            "subdistrictname": "ไชยสถาน",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550105",
            "subdistrictname": "ถืมตอง",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550106",
            "subdistrictname": "เรือง",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550107",
            "subdistrictname": "นาซาว",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550108",
            "subdistrictname": "ดู่ใต้",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550109",
            "subdistrictname": "กองควาย",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550116",
            "subdistrictname": "สวก",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5501",
            "districtname": "เมืองน่าน",
            "subdistrictcode": "550117",
            "subdistrictname": "สะเนียน",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5502",
            "districtname": "แม่จริม",
            "subdistrictcode": "550202",
            "subdistrictname": "หนองแดง",
            "zipcode": "55170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5502",
            "districtname": "แม่จริม",
            "subdistrictcode": "550203",
            "subdistrictname": "หมอเมือง",
            "zipcode": "55170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5502",
            "districtname": "แม่จริม",
            "subdistrictcode": "550204",
            "subdistrictname": "น้ำพาง",
            "zipcode": "55170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5502",
            "districtname": "แม่จริม",
            "subdistrictcode": "550205",
            "subdistrictname": "น้ำปาย",
            "zipcode": "55170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5502",
            "districtname": "แม่จริม",
            "subdistrictcode": "550206",
            "subdistrictname": "แม่จริม",
            "zipcode": "55170"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5503",
            "districtname": "บ้านหลวง",
            "subdistrictcode": "550301",
            "subdistrictname": "บ้านฟ้า",
            "zipcode": "55190"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5503",
            "districtname": "บ้านหลวง",
            "subdistrictcode": "550302",
            "subdistrictname": "ป่าคาหลวง",
            "zipcode": "55190"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5503",
            "districtname": "บ้านหลวง",
            "subdistrictcode": "550303",
            "subdistrictname": "สวด",
            "zipcode": "55190"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5503",
            "districtname": "บ้านหลวง",
            "subdistrictcode": "550304",
            "subdistrictname": "บ้านพี้",
            "zipcode": "55190"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550401",
            "subdistrictname": "นาน้อย",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550402",
            "subdistrictname": "เชียงของ",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550403",
            "subdistrictname": "ศรีษะเกษ",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550404",
            "subdistrictname": "สถาน",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550405",
            "subdistrictname": "สันทะ",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550406",
            "subdistrictname": "บัวใหญ่",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5504",
            "districtname": "นาน้อย",
            "subdistrictcode": "550407",
            "subdistrictname": "น้ำตก",
            "zipcode": "55150"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550501",
            "subdistrictname": "ปัว",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550502",
            "subdistrictname": "แงง",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550503",
            "subdistrictname": "สถาน",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550504",
            "subdistrictname": "ศิลาแลง",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550505",
            "subdistrictname": "ศิลาเพชร",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550506",
            "subdistrictname": "อวน",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550509",
            "subdistrictname": "ไชยวัฒนา",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550510",
            "subdistrictname": "เจดีย์ชัย",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550511",
            "subdistrictname": "ภูคา",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550512",
            "subdistrictname": "สกาด",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550513",
            "subdistrictname": "ป่ากลาง",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5505",
            "districtname": "ปัว",
            "subdistrictcode": "550514",
            "subdistrictname": "วรนคร",
            "zipcode": "55120"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550601",
            "subdistrictname": "ริม",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550602",
            "subdistrictname": "ป่าคา",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550603",
            "subdistrictname": "ผาตอ",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550604",
            "subdistrictname": "ยม",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550605",
            "subdistrictname": "ตาลชุม",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550606",
            "subdistrictname": "ศรีภูมิ",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550607",
            "subdistrictname": "จอมพระ",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550608",
            "subdistrictname": "แสนทอง",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550609",
            "subdistrictname": "ท่าวังผา",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5506",
            "districtname": "ท่าวังผา",
            "subdistrictcode": "550610",
            "subdistrictname": "ผาทอง",
            "zipcode": "55140"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550701",
            "subdistrictname": "กลางเวียง",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550702",
            "subdistrictname": "ขึ่ง",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550703",
            "subdistrictname": "ไหล่น่าน",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550704",
            "subdistrictname": "ตาลชุม",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550705",
            "subdistrictname": "นาเหลือง",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550706",
            "subdistrictname": "ส้าน",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550707",
            "subdistrictname": "น้ำมวบ",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550708",
            "subdistrictname": "น้ำปั้ว",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550709",
            "subdistrictname": "ยาบหัวนา",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550710",
            "subdistrictname": "ปงสนุก",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550711",
            "subdistrictname": "อ่ายนาไลย",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550712",
            "subdistrictname": "ส้านนาหนองใหม่",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550713",
            "subdistrictname": "แม่ขะนิง",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550714",
            "subdistrictname": "แม่สาคร",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550715",
            "subdistrictname": "จอมจันทร์",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550716",
            "subdistrictname": "แม่สา",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5507",
            "districtname": "เวียงสา",
            "subdistrictcode": "550717",
            "subdistrictname": "ทุ่งศรีทอง",
            "zipcode": "55110"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5508",
            "districtname": "ทุ่งช้าง",
            "subdistrictcode": "550801",
            "subdistrictname": "ปอน",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5508",
            "districtname": "ทุ่งช้าง",
            "subdistrictcode": "550802",
            "subdistrictname": "งอบ",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5508",
            "districtname": "ทุ่งช้าง",
            "subdistrictcode": "550803",
            "subdistrictname": "และ",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5508",
            "districtname": "ทุ่งช้าง",
            "subdistrictcode": "550804",
            "subdistrictname": "ทุ่งช้าง",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550901",
            "subdistrictname": "เชียงกลาง",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550902",
            "subdistrictname": "เปือ",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550903",
            "subdistrictname": "เชียงคาน",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550904",
            "subdistrictname": "พระธาตุ",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550908",
            "subdistrictname": "พญาแก้ว",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5509",
            "districtname": "เชียงกลาง",
            "subdistrictcode": "550909",
            "subdistrictname": "พระพุทธบาท",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5510",
            "districtname": "นาหมื่น",
            "subdistrictcode": "551001",
            "subdistrictname": "นาทะนุง",
            "zipcode": "55180"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5510",
            "districtname": "นาหมื่น",
            "subdistrictcode": "551002",
            "subdistrictname": "บ่อแก้ว",
            "zipcode": "55180"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5510",
            "districtname": "นาหมื่น",
            "subdistrictcode": "551003",
            "subdistrictname": "เมืองลี",
            "zipcode": "55180"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5510",
            "districtname": "นาหมื่น",
            "subdistrictcode": "551004",
            "subdistrictname": "ปิงหลวง",
            "zipcode": "55180"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5511",
            "districtname": "สันติสุข",
            "subdistrictcode": "551101",
            "subdistrictname": "ดู่พงษ์",
            "zipcode": "55210"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5511",
            "districtname": "สันติสุข",
            "subdistrictcode": "551102",
            "subdistrictname": "ป่าแลวหลวง",
            "zipcode": "55210"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5511",
            "districtname": "สันติสุข",
            "subdistrictcode": "551103",
            "subdistrictname": "พงษ์",
            "zipcode": "55210"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5512",
            "districtname": "บ่อเกลือ",
            "subdistrictcode": "551201",
            "subdistrictname": "บ่อเกลือเหนือ",
            "zipcode": "55220"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5512",
            "districtname": "บ่อเกลือ",
            "subdistrictcode": "551202",
            "subdistrictname": "บ่อเกลือใต้",
            "zipcode": "55220"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5512",
            "districtname": "บ่อเกลือ",
            "subdistrictcode": "551204",
            "subdistrictname": "ภูฟ้า",
            "zipcode": "55220"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5512",
            "districtname": "บ่อเกลือ",
            "subdistrictcode": "551205",
            "subdistrictname": "ดงพญา",
            "zipcode": "55220"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5513",
            "districtname": "สองแคว",
            "subdistrictcode": "551301",
            "subdistrictname": "นาไร่หลวง",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5513",
            "districtname": "สองแคว",
            "subdistrictcode": "551302",
            "subdistrictname": "ชนแดน",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5513",
            "districtname": "สองแคว",
            "subdistrictcode": "551303",
            "subdistrictname": "ยอด",
            "zipcode": "55160"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551401",
            "subdistrictname": "ม่วงตึ๊ด",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551402",
            "subdistrictname": "นาปัง",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551403",
            "subdistrictname": "น้ำแก่น",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551404",
            "subdistrictname": "น้ำเกี๋ยน",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551405",
            "subdistrictname": "เมืองจัง",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551406",
            "subdistrictname": "ท่าน้าว",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5514",
            "districtname": "ภูเพียง",
            "subdistrictcode": "551407",
            "subdistrictname": "ฝายแก้ว",
            "zipcode": "55000"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5515",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "551501",
            "subdistrictname": "ห้วยโก๋น",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "น่าน",
            "districtcode": "5515",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "551502",
            "subdistrictname": "ขุนน่าน",
            "zipcode": "55130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560101",
            "subdistrictname": "เวียง",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560102",
            "subdistrictname": "แม่ต๋ำ",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560104",
            "subdistrictname": "แม่นาเรือ",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560105",
            "subdistrictname": "บ้านตุ่น",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560106",
            "subdistrictname": "บ้านต๊ำ",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560107",
            "subdistrictname": "บ้านต๋อม",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560108",
            "subdistrictname": "แม่ปืม",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560110",
            "subdistrictname": "แม่กา",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560111",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560112",
            "subdistrictname": "จำป่าหวาย",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560113",
            "subdistrictname": "ท่าวังทอง",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560114",
            "subdistrictname": "แม่ใส",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560115",
            "subdistrictname": "บ้านสาง",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560116",
            "subdistrictname": "ท่าจำปี",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5601",
            "districtname": "เมืองพะเยา",
            "subdistrictcode": "560118",
            "subdistrictname": "สันป่าม่วง",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560201",
            "subdistrictname": "ห้วยข้าวก่ำ",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560202",
            "subdistrictname": "จุน",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560203",
            "subdistrictname": "ลอ",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560204",
            "subdistrictname": "หงส์หิน",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560205",
            "subdistrictname": "ทุ่งรวงทอง",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560206",
            "subdistrictname": "ห้วยยางขาม",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5602",
            "districtname": "จุน",
            "subdistrictcode": "560207",
            "subdistrictname": "พระธาตุขิงแกง",
            "zipcode": "56150"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560301",
            "subdistrictname": "หย่วน",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560306",
            "subdistrictname": "น้ำแวน",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560307",
            "subdistrictname": "เวียง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560308",
            "subdistrictname": "ฝายกวาง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560309",
            "subdistrictname": "เจดีย์คำ",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560310",
            "subdistrictname": "ร่มเย็น",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560311",
            "subdistrictname": "เชียงบาน",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560312",
            "subdistrictname": "แม่ลาว",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560313",
            "subdistrictname": "อ่างทอง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5603",
            "districtname": "เชียงคำ",
            "subdistrictcode": "560314",
            "subdistrictname": "ทุ่งผาสุข",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5604",
            "districtname": "เชียงม่วน",
            "subdistrictcode": "560401",
            "subdistrictname": "เชียงม่วน",
            "zipcode": "56160"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5604",
            "districtname": "เชียงม่วน",
            "subdistrictcode": "560402",
            "subdistrictname": "บ้านมาง",
            "zipcode": "56160"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5604",
            "districtname": "เชียงม่วน",
            "subdistrictcode": "560403",
            "subdistrictname": "สระ",
            "zipcode": "56160"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560501",
            "subdistrictname": "ดอกคำใต้",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560502",
            "subdistrictname": "ดอนศรีชุม",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560503",
            "subdistrictname": "บ้านถ้ำ",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560504",
            "subdistrictname": "บ้านปิน",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560505",
            "subdistrictname": "ห้วยลาน",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560506",
            "subdistrictname": "สันโค้ง",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560507",
            "subdistrictname": "ป่าซาง",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560508",
            "subdistrictname": "หนองหล่ม",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560509",
            "subdistrictname": "ดงสุวรรณ",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560510",
            "subdistrictname": "บุญเกิด",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560511",
            "subdistrictname": "สว่างอารมณ์",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5605",
            "districtname": "ดอกคำใต้",
            "subdistrictcode": "560512",
            "subdistrictname": "คือเวียง",
            "zipcode": "56120"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560601",
            "subdistrictname": "ปง",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560602",
            "subdistrictname": "ควร",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560603",
            "subdistrictname": "ออย",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560604",
            "subdistrictname": "งิม",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560605",
            "subdistrictname": "ผาช้างน้อย",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560606",
            "subdistrictname": "นาปรัง",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5606",
            "districtname": "ปง",
            "subdistrictcode": "560607",
            "subdistrictname": "ขุนควร",
            "zipcode": "56140"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560701",
            "subdistrictname": "แม่ใจ",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560702",
            "subdistrictname": "ศรีถ้อย",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560703",
            "subdistrictname": "แม่สุก",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560704",
            "subdistrictname": "ป่าแฝก",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560705",
            "subdistrictname": "บ้านเหล่า",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5607",
            "districtname": "แม่ใจ",
            "subdistrictcode": "560706",
            "subdistrictname": "เจริญราษฎร์",
            "zipcode": "56130"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5608",
            "districtname": "ภูซาง",
            "subdistrictcode": "560801",
            "subdistrictname": "ภูซาง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5608",
            "districtname": "ภูซาง",
            "subdistrictcode": "560802",
            "subdistrictname": "ป่าสัก",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5608",
            "districtname": "ภูซาง",
            "subdistrictcode": "560803",
            "subdistrictname": "ทุ่งกล้วย",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5608",
            "districtname": "ภูซาง",
            "subdistrictcode": "560804",
            "subdistrictname": "เชียงแรง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5608",
            "districtname": "ภูซาง",
            "subdistrictcode": "560805",
            "subdistrictname": "สบบง",
            "zipcode": "56110"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5609",
            "districtname": "ภูกามยาว",
            "subdistrictcode": "560901",
            "subdistrictname": "ห้วยแก้ว",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5609",
            "districtname": "ภูกามยาว",
            "subdistrictcode": "560902",
            "subdistrictname": "ดงเจน",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "พะเยา",
            "districtcode": "5609",
            "districtname": "ภูกามยาว",
            "subdistrictcode": "560903",
            "subdistrictname": "แม่อิง",
            "zipcode": "56000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570101",
            "subdistrictname": "เวียง",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570102",
            "subdistrictname": "รอบเวียง",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570103",
            "subdistrictname": "บ้านดู่",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570104",
            "subdistrictname": "นางแล",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570105",
            "subdistrictname": "แม่ข้าวต้ม",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570106",
            "subdistrictname": "แม่ยาว",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570107",
            "subdistrictname": "สันทราย",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570111",
            "subdistrictname": "แม่กรณ์",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570112",
            "subdistrictname": "ห้วยชมภู",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570113",
            "subdistrictname": "ห้วยสัก",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570114",
            "subdistrictname": "ริมกก",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570115",
            "subdistrictname": "ดอยลาน",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570116",
            "subdistrictname": "ป่าอ้อดอนชัย",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570118",
            "subdistrictname": "ท่าสาย",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570120",
            "subdistrictname": "ดอยฮาง",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5701",
            "districtname": "เมืองเชียงราย",
            "subdistrictcode": "570121",
            "subdistrictname": "ท่าสุด",
            "zipcode": "57100"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5702",
            "districtname": "เวียงชัย",
            "subdistrictcode": "570202",
            "subdistrictname": "เวียงชัย",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5702",
            "districtname": "เวียงชัย",
            "subdistrictcode": "570203",
            "subdistrictname": "ผางาม",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5702",
            "districtname": "เวียงชัย",
            "subdistrictcode": "570204",
            "subdistrictname": "เวียงเหนือ",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5702",
            "districtname": "เวียงชัย",
            "subdistrictcode": "570206",
            "subdistrictname": "ดอนศิลา",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5702",
            "districtname": "เวียงชัย",
            "subdistrictcode": "570208",
            "subdistrictname": "เมืองชุม",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570301",
            "subdistrictname": "เวียง",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570302",
            "subdistrictname": "สถาน",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570303",
            "subdistrictname": "ครึ่ง",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570304",
            "subdistrictname": "บุญเรือง",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570305",
            "subdistrictname": "ห้วยซ้อ",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570308",
            "subdistrictname": "ศรีดอนชัย",
            "zipcode": "57230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5703",
            "districtname": "เชียงของ",
            "subdistrictcode": "570310",
            "subdistrictname": "ริมโขง",
            "zipcode": "57140"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570401",
            "subdistrictname": "เวียง",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570402",
            "subdistrictname": "งิ้ว",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570403",
            "subdistrictname": "ปล้อง",
            "zipcode": "57230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570404",
            "subdistrictname": "แม่ลอย",
            "zipcode": "57230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570405",
            "subdistrictname": "เชียงเคี่ยน",
            "zipcode": "57230"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570409",
            "subdistrictname": "ตับเต่า",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570410",
            "subdistrictname": "หงาว",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570411",
            "subdistrictname": "สันทรายงาม",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570412",
            "subdistrictname": "ศรีดอนไชย",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5704",
            "districtname": "เทิง",
            "subdistrictcode": "570413",
            "subdistrictname": "หนองแรด",
            "zipcode": "57160"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570501",
            "subdistrictname": "สันมะเค็ด",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570502",
            "subdistrictname": "แม่อ้อ",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570503",
            "subdistrictname": "ธารทอง",
            "zipcode": "57250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570504",
            "subdistrictname": "สันติสุข",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570505",
            "subdistrictname": "ดอยงาม",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570506",
            "subdistrictname": "หัวง้ม",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570507",
            "subdistrictname": "เจริญเมือง",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570508",
            "subdistrictname": "ป่าหุ่ง",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570509",
            "subdistrictname": "ม่วงคำ",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570510",
            "subdistrictname": "ทรายขาว",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570511",
            "subdistrictname": "สันกลาง",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570512",
            "subdistrictname": "แม่เย็น",
            "zipcode": "57280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570513",
            "subdistrictname": "เมืองพาน",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570514",
            "subdistrictname": "ทานตะวัน",
            "zipcode": "57280"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5705",
            "districtname": "พาน",
            "subdistrictcode": "570515",
            "subdistrictname": "เวียงห้าว",
            "zipcode": "57120"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5706",
            "districtname": "ป่าแดด",
            "subdistrictcode": "570601",
            "subdistrictname": "ป่าแดด",
            "zipcode": "57190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5706",
            "districtname": "ป่าแดด",
            "subdistrictcode": "570602",
            "subdistrictname": "ป่าแงะ",
            "zipcode": "57190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5706",
            "districtname": "ป่าแดด",
            "subdistrictcode": "570603",
            "subdistrictname": "สันมะค่า",
            "zipcode": "57190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5706",
            "districtname": "ป่าแดด",
            "subdistrictcode": "570605",
            "subdistrictname": "โรงช้าง",
            "zipcode": "57190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5706",
            "districtname": "ป่าแดด",
            "subdistrictcode": "570606",
            "subdistrictname": "ศรีโพธิ์เงิน",
            "zipcode": "57190"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570701",
            "subdistrictname": "แม่จัน",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570702",
            "subdistrictname": "จันจว้า",
            "zipcode": "57270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570703",
            "subdistrictname": "แม่คำ",
            "zipcode": "57240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570704",
            "subdistrictname": "ป่าซาง",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570705",
            "subdistrictname": "สันทราย",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570706",
            "subdistrictname": "ท่าข้าวเปลือก",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570708",
            "subdistrictname": "ป่าตึง",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570710",
            "subdistrictname": "แม่ไร่",
            "zipcode": "57240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570711",
            "subdistrictname": "ศรีค้ำ",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570712",
            "subdistrictname": "จันจว้าใต้",
            "zipcode": "57270"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5707",
            "districtname": "แม่จัน",
            "subdistrictcode": "570713",
            "subdistrictname": "จอมสวรรค์",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570801",
            "subdistrictname": "เวียง",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570802",
            "subdistrictname": "ป่าสัก",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570803",
            "subdistrictname": "บ้านแซว",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570804",
            "subdistrictname": "ศรีดอนมูล",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570805",
            "subdistrictname": "แม่เงิน",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5708",
            "districtname": "เชียงแสน",
            "subdistrictcode": "570806",
            "subdistrictname": "โยนก",
            "zipcode": "57150"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570901",
            "subdistrictname": "แม่สาย",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570902",
            "subdistrictname": "ห้วยไคร้",
            "zipcode": "57220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570903",
            "subdistrictname": "เกาะช้าง",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570904",
            "subdistrictname": "โป่งผา",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570905",
            "subdistrictname": "ศรีเมืองชุม",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570906",
            "subdistrictname": "เวียงพางคำ",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570908",
            "subdistrictname": "บ้านด้าย",
            "zipcode": "57220"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5709",
            "districtname": "แม่สาย",
            "subdistrictcode": "570909",
            "subdistrictname": "โป่งงาม",
            "zipcode": "57130"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571001",
            "subdistrictname": "แม่สรวย",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571002",
            "subdistrictname": "ป่าแดด",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571003",
            "subdistrictname": "แม่พริก",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571004",
            "subdistrictname": "ศรีถ้อย",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571005",
            "subdistrictname": "ท่าก๊อ",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571006",
            "subdistrictname": "วาวี",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5710",
            "districtname": "แม่สรวย",
            "subdistrictcode": "571007",
            "subdistrictname": "เจดีย์หลวง",
            "zipcode": "57180"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571101",
            "subdistrictname": "สันสลี",
            "zipcode": "57170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571102",
            "subdistrictname": "เวียง",
            "zipcode": "57170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571103",
            "subdistrictname": "บ้านโป่ง",
            "zipcode": "57170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571104",
            "subdistrictname": "ป่างิ้ว",
            "zipcode": "57170"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571105",
            "subdistrictname": "เวียงกาหลง",
            "zipcode": "57260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571106",
            "subdistrictname": "แม่เจดีย์",
            "zipcode": "57260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5711",
            "districtname": "เวียงป่าเป้า",
            "subdistrictcode": "571107",
            "subdistrictname": "แม่เจดีย์ใหม่",
            "zipcode": "57260"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย",
            "subdistrictcode": "571201",
            "subdistrictname": "แม่เปา",
            "zipcode": "57290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย",
            "subdistrictcode": "571202",
            "subdistrictname": "แม่ต๋ำ",
            "zipcode": "57290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย",
            "subdistrictcode": "571203",
            "subdistrictname": "ไม้ยา",
            "zipcode": "57290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย",
            "subdistrictcode": "571204",
            "subdistrictname": "เม็งราย",
            "zipcode": "57290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5712",
            "districtname": "พญาเม็งราย",
            "subdistrictcode": "571205",
            "subdistrictname": "ตาดควัน",
            "zipcode": "57290"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5713",
            "districtname": "เวียงแก่น",
            "subdistrictcode": "571301",
            "subdistrictname": "ม่วงยาย",
            "zipcode": "57310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5713",
            "districtname": "เวียงแก่น",
            "subdistrictcode": "571302",
            "subdistrictname": "ปอ",
            "zipcode": "57310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5713",
            "districtname": "เวียงแก่น",
            "subdistrictcode": "571303",
            "subdistrictname": "หล่ายงาว",
            "zipcode": "57310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5713",
            "districtname": "เวียงแก่น",
            "subdistrictcode": "571304",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "57310"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5714",
            "districtname": "ขุนตาล",
            "subdistrictcode": "571401",
            "subdistrictname": "ต้า",
            "zipcode": "57340"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5714",
            "districtname": "ขุนตาล",
            "subdistrictcode": "571402",
            "subdistrictname": "ป่าตาล",
            "zipcode": "57340"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5714",
            "districtname": "ขุนตาล",
            "subdistrictcode": "571403",
            "subdistrictname": "ยางฮอม",
            "zipcode": "57340"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5715",
            "districtname": "แม่ฟ้าหลวง",
            "subdistrictcode": "571501",
            "subdistrictname": "เทอดไทย",
            "zipcode": "57240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5715",
            "districtname": "แม่ฟ้าหลวง",
            "subdistrictcode": "571502",
            "subdistrictname": "แม่สลองใน",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5715",
            "districtname": "แม่ฟ้าหลวง",
            "subdistrictcode": "571503",
            "subdistrictname": "แม่สลองนอก",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5715",
            "districtname": "แม่ฟ้าหลวง",
            "subdistrictcode": "571504",
            "subdistrictname": "แม่ฟ้าหลวง",
            "zipcode": "57240"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5716",
            "districtname": "แม่ลาว",
            "subdistrictcode": "571601",
            "subdistrictname": "ดงมะดะ",
            "zipcode": "57250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5716",
            "districtname": "แม่ลาว",
            "subdistrictcode": "571602",
            "subdistrictname": "จอมหมอกแก้ว",
            "zipcode": "57250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5716",
            "districtname": "แม่ลาว",
            "subdistrictcode": "571603",
            "subdistrictname": "บัวสลี",
            "zipcode": "57250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5716",
            "districtname": "แม่ลาว",
            "subdistrictcode": "571604",
            "subdistrictname": "ป่าก่อดำ",
            "zipcode": "57250"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5716",
            "districtname": "แม่ลาว",
            "subdistrictcode": "571605",
            "subdistrictname": "โป่งแพร่",
            "zipcode": "57000"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5717",
            "districtname": "เวียงเชียงรุ้ง",
            "subdistrictcode": "571701",
            "subdistrictname": "ทุ่งก่อ",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5717",
            "districtname": "เวียงเชียงรุ้ง",
            "subdistrictcode": "571702",
            "subdistrictname": "ดงมหาวัน",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5717",
            "districtname": "เวียงเชียงรุ้ง",
            "subdistrictcode": "571703",
            "subdistrictname": "ป่าซาง",
            "zipcode": "57210"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5718",
            "districtname": "ดอยหลวง",
            "subdistrictcode": "571801",
            "subdistrictname": "ปงน้อย",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5718",
            "districtname": "ดอยหลวง",
            "subdistrictcode": "571802",
            "subdistrictname": "โชคชัย",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "เชียงราย",
            "districtcode": "5718",
            "districtname": "ดอยหลวง",
            "subdistrictcode": "571803",
            "subdistrictname": "หนองป่าก่อ",
            "zipcode": "57110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580101",
            "subdistrictname": "จองคำ",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580102",
            "subdistrictname": "ห้วยโป่ง",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580103",
            "subdistrictname": "ผาบ่อง",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580104",
            "subdistrictname": "ปางหมู",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580105",
            "subdistrictname": "หมอกจำแป่",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580106",
            "subdistrictname": "ห้วยผา",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5801",
            "districtname": "เมืองแม่ฮ่องสอน",
            "subdistrictcode": "580109",
            "subdistrictname": "ห้วยปูลิง",
            "zipcode": "58000"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580201",
            "subdistrictname": "ขุนยวม",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580202",
            "subdistrictname": "แม่เงา",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580203",
            "subdistrictname": "เมืองปอน",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580204",
            "subdistrictname": "แม่ยวมน้อย",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580205",
            "subdistrictname": "แม่กิ๊",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5802",
            "districtname": "ขุนยวม",
            "subdistrictcode": "580206",
            "subdistrictname": "แม่อูคอ",
            "zipcode": "58140"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580301",
            "subdistrictname": "เวียงใต้",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580302",
            "subdistrictname": "เวียงเหนือ",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580303",
            "subdistrictname": "แม่นาเติง",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580304",
            "subdistrictname": "แม่ฮี้",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580305",
            "subdistrictname": "ทุ่งยาว",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580306",
            "subdistrictname": "เมืองแปง",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5803",
            "districtname": "ปาย",
            "subdistrictcode": "580307",
            "subdistrictname": "โป่งสา",
            "zipcode": "58130"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580401",
            "subdistrictname": "บ้านกาศ",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580402",
            "subdistrictname": "แม่สะเรียง",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580403",
            "subdistrictname": "แม่คง",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580404",
            "subdistrictname": "แม่เหาะ",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580405",
            "subdistrictname": "แม่ยวม",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580406",
            "subdistrictname": "เสาหิน",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5804",
            "districtname": "แม่สะเรียง",
            "subdistrictcode": "580408",
            "subdistrictname": "ป่าแป๋",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580501",
            "subdistrictname": "แม่ลาน้อย",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580502",
            "subdistrictname": "แม่ลาหลวง",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580503",
            "subdistrictname": "ท่าผาปุ้ม",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580504",
            "subdistrictname": "แม่โถ",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580505",
            "subdistrictname": "ห้วยห้อม",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580506",
            "subdistrictname": "แม่นาจาง",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580507",
            "subdistrictname": "สันติคีรี",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5805",
            "districtname": "แม่ลาน้อย",
            "subdistrictcode": "580508",
            "subdistrictname": "ขุนแม่ลาน้อย",
            "zipcode": "58120"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580601",
            "subdistrictname": "สบเมย",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580602",
            "subdistrictname": "แม่คะตวน",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580603",
            "subdistrictname": "กองก๋อย",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580604",
            "subdistrictname": "แม่สวด",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580605",
            "subdistrictname": "ป่าโปง",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5806",
            "districtname": "สบเมย",
            "subdistrictcode": "580606",
            "subdistrictname": "แม่สามแลบ",
            "zipcode": "58110"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5807",
            "districtname": "ปางมะผ้า",
            "subdistrictcode": "580701",
            "subdistrictname": "สบป่อง",
            "zipcode": "58150"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5807",
            "districtname": "ปางมะผ้า",
            "subdistrictcode": "580702",
            "subdistrictname": "ปางมะผ้า",
            "zipcode": "58150"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5807",
            "districtname": "ปางมะผ้า",
            "subdistrictcode": "580703",
            "subdistrictname": "ถ้ำลอด",
            "zipcode": "58150"
        },
        {
            "isActive": true,
            "provincecode": "แม่ฮ่องสอน",
            "districtcode": "5807",
            "districtname": "ปางมะผ้า",
            "subdistrictcode": "580704",
            "subdistrictname": "นาปู่ป้อม",
            "zipcode": "58150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600101",
            "subdistrictname": "ปากน้ำโพ",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600102",
            "subdistrictname": "กลางแดด",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600103",
            "subdistrictname": "เกรียงไกร",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600104",
            "subdistrictname": "แควใหญ่",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600105",
            "subdistrictname": "ตะเคียนเลื่อน",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600106",
            "subdistrictname": "นครสวรรค์ตก",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600107",
            "subdistrictname": "นครสวรรค์ออก",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600108",
            "subdistrictname": "บางพระหลวง",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600109",
            "subdistrictname": "บางม่วง",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600110",
            "subdistrictname": "บ้านมะเกลือ",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600111",
            "subdistrictname": "บ้านแก่ง",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600112",
            "subdistrictname": "พระนอน",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600113",
            "subdistrictname": "วัดไทร",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600114",
            "subdistrictname": "หนองกรด",
            "zipcode": "60240"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600115",
            "subdistrictname": "หนองกระโดน",
            "zipcode": "60240"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600116",
            "subdistrictname": "หนองปลิง",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6001",
            "districtname": "เมืองนครสวรรค์",
            "subdistrictcode": "600117",
            "subdistrictname": "บึงเสนาท",
            "zipcode": "60000"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600201",
            "subdistrictname": "โกรกพระ",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600202",
            "subdistrictname": "ยางตาล",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600203",
            "subdistrictname": "บางมะฝ่อ",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600204",
            "subdistrictname": "บางประมุง",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600205",
            "subdistrictname": "นากลาง",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600206",
            "subdistrictname": "ศาลาแดง",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600207",
            "subdistrictname": "เนินกว้าว",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600208",
            "subdistrictname": "เนินศาลา",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6002",
            "districtname": "โกรกพระ",
            "subdistrictcode": "600209",
            "subdistrictname": "หาดสูง",
            "zipcode": "60170"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600301",
            "subdistrictname": "ชุมแสง",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600302",
            "subdistrictname": "ทับกฤช",
            "zipcode": "60250"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600303",
            "subdistrictname": "พิกุล",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600304",
            "subdistrictname": "เกยไชย",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600305",
            "subdistrictname": "ท่าไม้",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600306",
            "subdistrictname": "บางเคียน",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600307",
            "subdistrictname": "หนองกระเจา",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600308",
            "subdistrictname": "พันลาน",
            "zipcode": "60250"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600309",
            "subdistrictname": "โคกหม้อ",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600310",
            "subdistrictname": "ไผ่สิงห์",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600311",
            "subdistrictname": "ฆะมัง",
            "zipcode": "60120"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6003",
            "districtname": "ชุมแสง",
            "subdistrictcode": "600312",
            "subdistrictname": "ทับกฤชใต้",
            "zipcode": "60250"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600401",
            "subdistrictname": "หนองบัว",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600402",
            "subdistrictname": "หนองกลับ",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600403",
            "subdistrictname": "ธารทหาร",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600404",
            "subdistrictname": "ห้วยร่วม",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600405",
            "subdistrictname": "ห้วยถั่วใต้",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600406",
            "subdistrictname": "ห้วยถั่วเหนือ",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600407",
            "subdistrictname": "ห้วยใหญ่",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600408",
            "subdistrictname": "ทุ่งทอง",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6004",
            "districtname": "หนองบัว",
            "subdistrictcode": "600409",
            "subdistrictname": "วังบ่อ",
            "zipcode": "60110"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600501",
            "subdistrictname": "ท่างิ้ว",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600502",
            "subdistrictname": "บางตาหงาย",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600503",
            "subdistrictname": "หูกวาง",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600504",
            "subdistrictname": "อ่างทอง",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600505",
            "subdistrictname": "บ้านแดน",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600506",
            "subdistrictname": "บางแก้ว",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600507",
            "subdistrictname": "ตาขีด",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600508",
            "subdistrictname": "ตาสัง",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600509",
            "subdistrictname": "ด่านช้าง",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600510",
            "subdistrictname": "หนองกรด",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600511",
            "subdistrictname": "หนองตางู",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600512",
            "subdistrictname": "บึงปลาทู",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6005",
            "districtname": "บรรพตพิสัย",
            "subdistrictcode": "600513",
            "subdistrictname": "เจริญผล",
            "zipcode": "60180"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว",
            "subdistrictcode": "600601",
            "subdistrictname": "มหาโพธิ",
            "zipcode": "60230"
        },
        {
            "isActive": true,
            "provincecode": "นครสว��รค์",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว",
            "subdistrictcode": "600602",
            "subdistrictname": "เก้าเลี้ยว",
            "zipcode": "60230"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว",
            "subdistrictcode": "600603",
            "subdistrictname": "หนองเต่า",
            "zipcode": "60230"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว",
            "subdistrictcode": "600604",
            "subdistrictname": "เขาดิน",
            "zipcode": "60230"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6006",
            "districtname": "เก้าเลี้ยว",
            "subdistrictcode": "600605",
            "subdistrictname": "หัวดง",
            "zipcode": "60230"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600701",
            "subdistrictname": "ตาคลี",
            "zipcode": "60140"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600702",
            "subdistrictname": "ช่องแค",
            "zipcode": "60210"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600703",
            "subdistrictname": "จันเสน",
            "zipcode": "60260"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600704",
            "subdistrictname": "ห้วยหอม",
            "zipcode": "60210"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600705",
            "subdistrictname": "หัวหวาย",
            "zipcode": "60140"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600706",
            "subdistrictname": "หนองโพ",
            "zipcode": "60140"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600707",
            "subdistrictname": "หนองหม้อ",
            "zipcode": "60140"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600708",
            "subdistrictname": "สร้อยทอง",
            "zipcode": "60210"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600709",
            "subdistrictname": "ลาดทิพรส",
            "zipcode": "60260"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6007",
            "districtname": "ตาคลี",
            "subdistrictcode": "600710",
            "subdistrictname": "พรหมนิมิต",
            "zipcode": "60210"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600801",
            "subdistrictname": "ท่าตะโก",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600802",
            "subdistrictname": "พนมรอก",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600803",
            "subdistrictname": "หัวถนน",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600804",
            "subdistrictname": "สายลำโพง",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600805",
            "subdistrictname": "วังมหากร",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600806",
            "subdistrictname": "ดอนคา",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600807",
            "subdistrictname": "ทำนบ",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600808",
            "subdistrictname": "วังใหญ่",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600809",
            "subdistrictname": "พนมเศษ",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6008",
            "districtname": "ท่าตะโก",
            "subdistrictcode": "600810",
            "subdistrictname": "หนองหลวง",
            "zipcode": "60160"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600901",
            "subdistrictname": "โคกเดื่อ",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600902",
            "subdistrictname": "สำโรงชัย",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600903",
            "subdistrictname": "วังน้ำลัด",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600904",
            "subdistrictname": "ตะคร้อ",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600905",
            "subdistrictname": "โพธิ์ประสาท",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600906",
            "subdistrictname": "วังข่อย",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600907",
            "subdistrictname": "นาขอม",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6009",
            "districtname": "ไพศาลี",
            "subdistrictcode": "600908",
            "subdistrictname": "ไพศาลี",
            "zipcode": "60220"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601001",
            "subdistrictname": "พยุหะ",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601002",
            "subdistrictname": "เนินมะกอก",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601003",
            "subdistrictname": "นิคมเขาบ่อแก้ว",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601004",
            "subdistrictname": "ม่วงหัก",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601005",
            "subdistrictname": "ยางขาว",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601006",
            "subdistrictname": "ย่านมัทรี",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601007",
            "subdistrictname": "เขาทอง",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601008",
            "subdistrictname": "ท่าน้ำอ้อย",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601009",
            "subdistrictname": "น้ำทรง",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601010",
            "subdistrictname": "เขากะลา",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6010",
            "districtname": "พยุหะคีรี",
            "subdistrictcode": "601011",
            "subdistrictname": "สระทะเล",
            "zipcode": "60130"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601101",
            "subdistrictname": "ลาดยาว",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601102",
            "subdistrictname": "ห้วยน้ำหอม",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601103",
            "subdistrictname": "วังม้า",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601104",
            "subdistrictname": "วังเมือง",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601105",
            "subdistrictname": "สร้อยละคร",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601106",
            "subdistrictname": "มาบแก",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601107",
            "subdistrictname": "หนองยาว",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601108",
            "subdistrictname": "หนองนมวัว",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601109",
            "subdistrictname": "บ้านไร่",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601110",
            "subdistrictname": "เนินขี้เหล็ก",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601116",
            "subdistrictname": "ศาลเจ้าไก่ต่อ",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6011",
            "districtname": "ลาดยาว",
            "subdistrictcode": "601117",
            "subdistrictname": "สระแก้ว",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601201",
            "subdistrictname": "ตากฟ้า",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601202",
            "subdistrictname": "ลำพยนต์",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601203",
            "subdistrictname": "สุขสำราญ",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601204",
            "subdistrictname": "หนองพิกุล",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601205",
            "subdistrictname": "พุนกยูง",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601206",
            "subdistrictname": "อุดมธัญญา",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6012",
            "districtname": "ตากฟ้า",
            "subdistrictcode": "601207",
            "subdistrictname": "เขาชายธง",
            "zipcode": "60190"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6013",
            "districtname": "แม่วงก์",
            "subdistrictcode": "601301",
            "subdistrictname": "แม่วงก์",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6013",
            "districtname": "แม่วงก์",
            "subdistrictcode": "601303",
            "subdistrictname": "แม่เล่ย์",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6013",
            "districtname": "แม่วงก์",
            "subdistrictcode": "601304",
            "subdistrictname": "วังซ่าน",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6013",
            "districtname": "แม่วงก์",
            "subdistrictcode": "601305",
            "subdistrictname": "เขาชนกัน",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6014",
            "districtname": "แม่เปิน",
            "subdistrictcode": "601401",
            "subdistrictname": "แม่เปิน",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6015",
            "districtname": "ชุมตาบง",
            "subdistrictcode": "601501",
            "subdistrictname": "ชุมตาบง",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "นครสวรรค์",
            "districtcode": "6015",
            "districtname": "ชุมตาบง",
            "subdistrictcode": "601502",
            "subdistrictname": "ปางสวรรค์",
            "zipcode": "60150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610101",
            "subdistrictname": "อุทัยใหม่",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610102",
            "subdistrictname": "น้ำซึม",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610103",
            "subdistrictname": "สะแกกรัง",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610104",
            "subdistrictname": "ดอนขวาง",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610105",
            "subdistrictname": "หาดทนง",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610106",
            "subdistrictname": "เกาะเทโพ",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610107",
            "subdistrictname": "ท่าซุง",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610108",
            "subdistrictname": "หนองแก",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610109",
            "subdistrictname": "โนนเหล็ก",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610110",
            "subdistrictname": "หนองเต่า",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610111",
            "subdistrictname": "หนองไผ่แบน",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610112",
            "subdistrictname": "หนองพังค่า",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610113",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6101",
            "districtname": "เมืองอุทัยธานี",
            "subdistrictcode": "610114",
            "subdistrictname": "เนินแจง",
            "zipcode": "61000"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610201",
            "subdistrictname": "ทัพทัน",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610202",
            "subdistrictname": "ทุ่งนาไทย",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610203",
            "subdistrictname": "เขาขี้ฝอย",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610204",
            "subdistrictname": "หนองหญ้าปล้อง",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610205",
            "subdistrictname": "โคกหม้อ",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610206",
            "subdistrictname": "หนองยายดา",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610207",
            "subdistrictname": "หนองกลางดง",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610208",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610209",
            "subdistrictname": "หนองสระ",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6102",
            "districtname": "ทัพทัน",
            "subdistrictcode": "610210",
            "subdistrictname": "ตลุกดู่",
            "zipcode": "61120"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์",
            "subdistrictcode": "610301",
            "subdistrictname": "สว่างอารมณ์",
            "zipcode": "61150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์",
            "subdistrictcode": "610302",
            "subdistrictname": "หนองหลวง",
            "zipcode": "61150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์",
            "subdistrictcode": "610303",
            "subdistrictname": "พลวงสองนาง",
            "zipcode": "61150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์",
            "subdistrictcode": "610304",
            "subdistrictname": "ไผ่เขียว",
            "zipcode": "61150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6103",
            "districtname": "สว่างอารมณ์",
            "subdistrictcode": "610305",
            "subdistrictname": "บ่อยาง",
            "zipcode": "61150"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610401",
            "subdistrictname": "หนองฉาง",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610402",
            "subdistrictname": "หนองยาง",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610403",
            "subdistrictname": "หนองนางนวล",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610404",
            "subdistrictname": "หนองสรวง",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610405",
            "subdistrictname": "บ้านเก่า",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610406",
            "subdistrictname": "อุทัยเก่า",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610407",
            "subdistrictname": "ทุ่งโพ",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610408",
            "subdistrictname": "ทุ่งพง",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610409",
            "subdistrictname": "เขาบางแกรก",
            "zipcode": "61170"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6104",
            "districtname": "หนองฉาง",
            "subdistrictcode": "610410",
            "subdistrictname": "เขากวางทอง",
            "zipcode": "61110"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610501",
            "subdistrictname": "หนองขาหย่าง",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610502",
            "subdistrictname": "หนองไผ่",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610503",
            "subdistrictname": "ดอนกลอย",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610504",
            "subdistrictname": "ห้วยรอบ",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610505",
            "subdistrictname": "ทุ่งพึ่ง",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610506",
            "subdistrictname": "ท่าโพ",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610507",
            "subdistrictname": "หมกแถว",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610508",
            "subdistrictname": "หลุมเข้า",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6105",
            "districtname": "หนองขาหย่าง",
            "subdistrictcode": "610509",
            "subdistrictname": "ดงขวาง",
            "zipcode": "61130"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610601",
            "subdistrictname": "บ้านไร่",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610602",
            "subdistrictname": "ทัพหลวง",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610603",
            "subdistrictname": "ห้วยแห้ง",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610604",
            "subdistrictname": "คอกควาย",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610605",
            "subdistrictname": "วังหิน",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610606",
            "subdistrictname": "เมืองการุ้ง",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610607",
            "subdistrictname": "แก่นมะกรูด",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610609",
            "subdistrictname": "หนองจอก",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610610",
            "subdistrictname": "หูช้าง",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610611",
            "subdistrictname": "บ้านบึง",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610612",
            "subdistrictname": "บ้านใหม่คลองเคียน",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610613",
            "subdistrictname": "หนองบ่มกล้วย",
            "zipcode": "61180"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6106",
            "districtname": "บ้านไร่",
            "subdistrictcode": "610614",
            "subdistrictname": "เจ้าวัด",
            "zipcode": "61140"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610701",
            "subdistrictname": "ลานสัก",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610702",
            "subdistrictname": "ประดู่ยืน",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610703",
            "subdistrictname": "ป่าอ้อ",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610704",
            "subdistrictname": "ระบำ",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610705",
            "subdistrictname": "น้ำรอบ",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6107",
            "districtname": "ลานสัก",
            "subdistrictcode": "610706",
            "subdistrictname": "ทุ่งนางาม",
            "zipcode": "61160"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6108",
            "districtname": "ห้วยคต",
            "subdistrictcode": "610801",
            "subdistrictname": "สุขฤทัย",
            "zipcode": "61170"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6108",
            "districtname": "ห้วยคต",
            "subdistrictcode": "610802",
            "subdistrictname": "ทองหลาง",
            "zipcode": "61170"
        },
        {
            "isActive": true,
            "provincecode": "อุทัยธานี",
            "districtcode": "6108",
            "districtname": "ห้วยคต",
            "subdistrictcode": "610803",
            "subdistrictname": "ห้วยคต",
            "zipcode": "61170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620101",
            "subdistrictname": "ในเมือง",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620102",
            "subdistrictname": "ไตรตรึงษ์",
            "zipcode": "62160"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620103",
            "subdistrictname": "อ่างทอง",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620104",
            "subdistrictname": "นาบ่อคำ",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620105",
            "subdistrictname": "นครชุม",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620106",
            "subdistrictname": "ทรงธรรม",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620107",
            "subdistrictname": "ลานดอกไม้",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620110",
            "subdistrictname": "หนองปลิง",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620111",
            "subdistrictname": "คณฑี",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620112",
            "subdistrictname": "นิคมทุ่งโพธิ์ทะเล",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620113",
            "subdistrictname": "เทพนคร",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620114",
            "subdistrictname": "วังทอง",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620115",
            "subdistrictname": "ท่าขุนราม",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620117",
            "subdistrictname": "คลองแม่ลาย",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620118",
            "subdistrictname": "ธำมรงค์",
            "zipcode": "62160"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6201",
            "districtname": "เมืองกำแพงเพชร",
            "subdistrictcode": "620119",
            "subdistrictname": "สระแก้ว",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620201",
            "subdistrictname": "ไทรงาม",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620202",
            "subdistrictname": "หนองคล้า",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620203",
            "subdistrictname": "หนองทอง",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620204",
            "subdistrictname": "หนองไม้กอง",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620205",
            "subdistrictname": "มหาชัย",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620206",
            "subdistrictname": "พานทอง",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6202",
            "districtname": "ไทรงาม",
            "subdistrictcode": "620207",
            "subdistrictname": "หนองแม่แตง",
            "zipcode": "62150"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6203",
            "districtname": "คลองลาน",
            "subdistrictcode": "620301",
            "subdistrictname": "คลองน้ำไหล",
            "zipcode": "62180"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6203",
            "districtname": "คลองลาน",
            "subdistrictcode": "620302",
            "subdistrictname": "โป่งน้ำร้อน",
            "zipcode": "62180"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6203",
            "districtname": "คลองลาน",
            "subdistrictcode": "620303",
            "subdistrictname": "คลองลานพัฒนา",
            "zipcode": "62180"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6203",
            "districtname": "คลองลาน",
            "subdistrictcode": "620304",
            "subdistrictname": "สักงาม",
            "zipcode": "62180"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620403",
            "subdistrictname": "ยางสูง",
            "zipcode": "62130"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620404",
            "subdistrictname": "ป่าพุทรา",
            "zipcode": "62130"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620405",
            "subdistrictname": "แสนตอ",
            "zipcode": "62130"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620406",
            "subdistrictname": "สลกบาตร",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620407",
            "subdistrictname": "บ่อถ้ำ",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620408",
            "subdistrictname": "ดอนแตง",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620409",
            "subdistrictname": "วังชะพลู",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620410",
            "subdistrictname": "โค้งไผ่",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620411",
            "subdistrictname": "ปางมะค่า",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620412",
            "subdistrictname": "วังหามแห",
            "zipcode": "62140"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6204",
            "districtname": "ขาณุวรลักษบุรี",
            "subdistrictcode": "620413",
            "subdistrictname": "เกาะตาล",
            "zipcode": "62130"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620501",
            "subdistrictname": "คลองขลุง",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620502",
            "subdistrictname": "ท่ามะเขือ",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620504",
            "subdistrictname": "ท่าพุทรา",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620505",
            "subdistrictname": "แม่ลาด",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620506",
            "subdistrictname": "วังยาง",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620507",
            "subdistrictname": "วังแขม",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620508",
            "subdistrictname": "หัวถนน",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620509",
            "subdistrictname": "วังไทร",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620513",
            "subdistrictname": "วังบัว",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6205",
            "districtname": "คลองขลุง",
            "subdistrictcode": "620516",
            "subdistrictname": "คลองสมบูรณ์",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620601",
            "subdistrictname": "พรานกระต่าย",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620602",
            "subdistrictname": "หนองหัววัว",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620603",
            "subdistrictname": "ท่าไม้",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620604",
            "subdistrictname": "วังควง",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620605",
            "subdistrictname": "วังตะแบก",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620606",
            "subdistrictname": "เขาคีริส",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620607",
            "subdistrictname": "คุยบ้านโอง",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620608",
            "subdistrictname": "คลองพิไกร",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620609",
            "subdistrictname": "ถ้��กระต่ายทอง",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6206",
            "districtname": "พรานกระต่าย",
            "subdistrictcode": "620610",
            "subdistrictname": "ห้วยยั้ง",
            "zipcode": "62110"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620701",
            "subdistrictname": "ลานกระบือ",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620702",
            "subdistrictname": "ช่องลม",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620703",
            "subdistrictname": "หนองหลวง",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620704",
            "subdistrictname": "โนนพลวง",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620705",
            "subdistrictname": "ประชาสุขสันต์",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620706",
            "subdistrictname": "บึงทับแรต",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6207",
            "districtname": "ลานกระบือ",
            "subdistrictcode": "620707",
            "subdistrictname": "จันทิมา",
            "zipcode": "62170"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6208",
            "districtname": "ทรายทองวัฒนา",
            "subdistrictcode": "620801",
            "subdistrictname": "ทุ่งทราย",
            "zipcode": "62190"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6208",
            "districtname": "ทรายทองวัฒนา",
            "subdistrictcode": "620802",
            "subdistrictname": "ทุ่งทอง",
            "zipcode": "62190"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6208",
            "districtname": "ทรายทองวัฒนา",
            "subdistrictcode": "620803",
            "subdistrictname": "ถาวรวัฒนา",
            "zipcode": "62190"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6209",
            "districtname": "ปางศิลาทอง",
            "subdistrictcode": "620901",
            "subdistrictname": "โพธิ์ทอง",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6209",
            "districtname": "ปางศิลาทอง",
            "subdistrictcode": "620902",
            "subdistrictname": "หินดาต",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6209",
            "districtname": "ปางศิลาทอง",
            "subdistrictcode": "620903",
            "subdistrictname": "ปางตาไว",
            "zipcode": "62120"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6210",
            "districtname": "บึงสามัคคี",
            "subdistrictcode": "621001",
            "subdistrictname": "บึงสามัคคี",
            "zipcode": "62210"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6210",
            "districtname": "บึงสามัคคี",
            "subdistrictcode": "621002",
            "subdistrictname": "วังชะโอน",
            "zipcode": "62210"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6210",
            "districtname": "บึงสามัคคี",
            "subdistrictcode": "621003",
            "subdistrictname": "ระหาน",
            "zipcode": "62210"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6210",
            "districtname": "บึงสามัคคี",
            "subdistrictcode": "621004",
            "subdistrictname": "เทพนิมิต",
            "zipcode": "62210"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6211",
            "districtname": "โกสัมพีนคร",
            "subdistrictcode": "621101",
            "subdistrictname": "โกสัมพี",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6211",
            "districtname": "โกสัมพีนคร",
            "subdistrictcode": "621102",
            "subdistrictname": "เพชรชมภู",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "กำแพงเพชร",
            "districtcode": "6211",
            "districtname": "โกสัมพีนคร",
            "subdistrictcode": "621103",
            "subdistrictname": "ลานดอกไม้ตก",
            "zipcode": "62000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630101",
            "subdistrictname": "ระแหง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630102",
            "subdistrictname": "หนองหลวง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630103",
            "subdistrictname": "เชียงเงิน",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630104",
            "subdistrictname": "หัวเดียด",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630105",
            "subdistrictname": "หนองบัวเหนือ",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630106",
            "subdistrictname": "ไม้งาม",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630107",
            "subdistrictname": "โป่งแดง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630108",
            "subdistrictname": "น้ำรึม",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630109",
            "subdistrictname": "วังหิน",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630111",
            "subdistrictname": "แม่ท้อ",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630112",
            "subdistrictname": "ป่ามะม่วง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630113",
            "subdistrictname": "หนองบัวใต้",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630114",
            "subdistrictname": "วังประจบ",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6301",
            "districtname": "เมืองตาก",
            "subdistrictcode": "630115",
            "subdistrictname": "ตลุกกลางทุ่ง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630201",
            "subdistrictname": "ตากออก",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630202",
            "subdistrictname": "สมอโคน",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630203",
            "subdistrictname": "แม่สลิด",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630204",
            "subdistrictname": "ตากตก",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630205",
            "subdistrictname": "เกาะตะเภา",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630206",
            "subdistrictname": "ทุ่งกระเชาะ",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6302",
            "districtname": "บ้านตาก",
            "subdistrictcode": "630207",
            "subdistrictname": "ท้องฟ้า",
            "zipcode": "63120"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630301",
            "subdistrictname": "สามเงา",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630302",
            "subdistrictname": "วังหมัน",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630303",
            "subdistrictname": "ยกกระบัตร",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630304",
            "subdistrictname": "ย่านรี",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630305",
            "subdistrictname": "บ้านนา",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6303",
            "districtname": "สามเงา",
            "subdistrictcode": "630306",
            "subdistrictname": "วังจันทร์",
            "zipcode": "63130"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630401",
            "subdistrictname": "แม่ระมาด",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630402",
            "subdistrictname": "แม่จะเรา",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630403",
            "subdistrictname": "ขะเนจื้อ",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630404",
            "subdistrictname": "แม่ตื่น",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630405",
            "subdistrictname": "สามหมื่น",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6304",
            "districtname": "แม่ระมาด",
            "subdistrictcode": "630406",
            "subdistrictname": "พระธาตุ",
            "zipcode": "63140"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630501",
            "subdistrictname": "ท่าสองยาง",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630502",
            "subdistrictname": "แม่ต้าน",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630503",
            "subdistrictname": "แม่สอง",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630504",
            "subdistrictname": "แม่หละ",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630505",
            "subdistrictname": "แม่วะหลวง",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6305",
            "districtname": "ท่าสองยาง",
            "subdistrictcode": "630506",
            "subdistrictname": "แม่อุสุ",
            "zipcode": "63150"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630601",
            "subdistrictname": "แม่สอด",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630602",
            "subdistrictname": "แม่กุ",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630603",
            "subdistrictname": "พะวอ",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630604",
            "subdistrictname": "แม่ตาว",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630605",
            "subdistrictname": "แม่กาษา",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630606",
            "subdistrictname": "ท่าสายลวด",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630607",
            "subdistrictname": "แม่ปะ",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630608",
            "subdistrictname": "มหาวัน",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630609",
            "subdistrictname": "ด่านแม่ละเมา",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6306",
            "districtname": "แม่สอด",
            "subdistrictcode": "630610",
            "subdistrictname": "พระธาตุผาแดง",
            "zipcode": "63110"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6307",
            "districtname": "พบพระ",
            "subdistrictcode": "630701",
            "subdistrictname": "พบพระ",
            "zipcode": "63160"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6307",
            "districtname": "พบพระ",
            "subdistrictcode": "630702",
            "subdistrictname": "ช่องแคบ",
            "zipcode": "63160"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6307",
            "districtname": "พบพระ",
            "subdistrictcode": "630703",
            "subdistrictname": "คีรีราษฎร์",
            "zipcode": "63160"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6307",
            "districtname": "พบพระ",
            "subdistrictcode": "630704",
            "subdistrictname": "วาเล่ย์",
            "zipcode": "63160"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6307",
            "districtname": "พบพระ",
            "subdistrictcode": "630705",
            "subdistrictname": "รวมไทยพัฒนา",
            "zipcode": "63160"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630801",
            "subdistrictname": "อุ้มผาง",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630802",
            "subdistrictname": "หนองหลวง",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630803",
            "subdistrictname": "โมโกร",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630804",
            "subdistrictname": "แม่จัน",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630805",
            "subdistrictname": "แม่ละมุ้ง",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6308",
            "districtname": "อุ้มผาง",
            "subdistrictcode": "630806",
            "subdistrictname": "แม่กลอง",
            "zipcode": "63170"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6309",
            "districtname": "วังเจ้า",
            "subdistrictcode": "630901",
            "subdistrictname": "เชียงทอง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6309",
            "districtname": "วังเจ้า",
            "subdistrictcode": "630902",
            "subdistrictname": "นาโบสถ์",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "ตาก",
            "districtcode": "6309",
            "districtname": "วังเจ้า",
            "subdistrictcode": "630903",
            "subdistrictname": "ประดาง",
            "zipcode": "63000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640101",
            "subdistrictname": "ธานี",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640102",
            "subdistrictname": "บ้านสวน",
            "zipcode": "64220"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640103",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "64210"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640104",
            "subdistrictname": "ปากแคว",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640105",
            "subdistrictname": "ยางซ้าย",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640106",
            "subdistrictname": "บ้านกล้วย",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640107",
            "subdistrictname": "บ้านหลุม",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640108",
            "subdistrictname": "ตาลเตี้ย",
            "zipcode": "64220"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640109",
            "subdistrictname": "ปากพระ",
            "zipcode": "64000"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6401",
            "districtname": "เมืองสุโขทัย",
            "subdistrictcode": "640110",
            "subdistrictname": "วังทองแดง",
            "zipcode": "64210"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640201",
            "subdistrictname": "ลานหอย",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640202",
            "subdistrictname": "บ้านด่าน",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640203",
            "subdistrictname": "วังตะคร้อ",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640204",
            "subdistrictname": "วังน้ำขาว",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640205",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640206",
            "subdistrictname": "หนองหญ้าปล้อง",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6402",
            "districtname": "บ้านด่านลานหอย",
            "subdistrictcode": "640207",
            "subdistrictname": "วังลึก",
            "zipcode": "64140"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640301",
            "subdistrictname": "โตนด",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640302",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640303",
            "subdistrictname": "บ้านป้อม",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640304",
            "subdistrictname": "สามพวง",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640305",
            "subdistrictname": "ศรีคีรีมาศ",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640306",
            "subdistrictname": "หนองจิก",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640307",
            "subdistrictname": "นาเชิงคีรี",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640308",
            "subdistrictname": "หนองกระดิ่ง",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640309",
            "subdistrictname": "บ้านน้ำพุ",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6403",
            "districtname": "คีรีมาศ",
            "subdistrictcode": "640310",
            "subdistrictname": "ทุ่งยางเมือง",
            "zipcode": "64160"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640401",
            "subdistrictname": "กง",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640402",
            "subdistrictname": "บ้านกร่าง",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640403",
            "subdistrictname": "ไกรนอก",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640404",
            "subdistrictname": "ไกรกลาง",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640405",
            "subdistrictname": "ไกรใน",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640406",
            "subdistrictname": "ดงเดือย",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640407",
            "subdistrictname": "ป่าแฝก",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640408",
            "subdistrictname": "กกแรต",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640409",
            "subdistrictname": "ท่าฉนวน",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640410",
            "subdistrictname": "หนองตูม",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6404",
            "districtname": "กงไกรลาศ",
            "subdistrictcode": "640411",
            "subdistrictname": "บ้านใหม่สุขเกษม",
            "zipcode": "64170"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640501",
            "subdistrictname": "หาดเสี้ยว",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640502",
            "subdistrictname": "ป่างิ้ว",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640503",
            "subdistrictname": "แม่สำ",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640504",
            "subdistrictname": "แม่สิน",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640505",
            "subdistrictname": "บ้านตึก",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640506",
            "subdistrictname": "หนองอ้อ",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640507",
            "subdistrictname": "ท่าชัย",
            "zipcode": "64190"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640508",
            "subdistrictname": "ศรีสัชนาลัย",
            "zipcode": "64190"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640509",
            "subdistrictname": "ดงคู่",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640510",
            "subdistrictname": "บ้านแก่ง",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6405",
            "districtname": "ศรีสัชนาลัย",
            "subdistrictcode": "640511",
            "subdistrictname": "สารจิตร",
            "zipcode": "64130"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640601",
            "subdistrictname": "คลองตาล",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640602",
            "subdistrictname": "วังลึก",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640603",
            "subdistrictname": "สามเรือน",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640604",
            "subdistrictname": "บ้านนา",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640605",
            "subdistrictname": "วังทอง",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640606",
            "subdistrictname": "นาขุนไกร",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640607",
            "subdistrictname": "เกาะตาเลี้ยง",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640608",
            "subdistrictname": "วัดเกาะ",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640609",
            "subdistrictname": "บ้านไร่",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640610",
            "subdistrictname": "ทับผึ้ง",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640611",
            "subdistrictname": "บ้านซ่าน",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640612",
            "subdistrictname": "วังใหญ่",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6406",
            "districtname": "ศรีสำโรง",
            "subdistrictcode": "640613",
            "subdistrictname": "ราวต้นจันทร์",
            "zipcode": "64120"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640701",
            "subdistrictname": "เมืองสวรรคโลก",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640702",
            "subdistrictname": "ในเมือง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640703",
            "subdistrictname": "คลองกระจง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640704",
            "subdistrictname": "วังพิณพาทย์",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640705",
            "subdistrictname": "วังไม้ขอน",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640706",
            "subdistrictname": "ย่านยาว",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640707",
            "subdistrictname": "นาทุ่ง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640708",
            "subdistrictname": "คลองยาง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640709",
            "subdistrictname": "เมืองบางยม",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640710",
            "subdistrictname": "ท่าทอง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640711",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640712",
            "subdistrictname": "ป่ากุมเกาะ",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640713",
            "subdistrictname": "เมืองบางขลัง",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6407",
            "districtname": "สวรรคโลก",
            "subdistrictcode": "640714",
            "subdistrictname": "หนองกลับ",
            "zipcode": "64110"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6408",
            "districtname": "ศรีนคร",
            "subdistrictcode": "640801",
            "subdistrictname": "ศรีนคร",
            "zipcode": "64180"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6408",
            "districtname": "ศรีนคร",
            "subdistrictcode": "640802",
            "subdistrictname": "นครเดิฐ",
            "zipcode": "64180"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6408",
            "districtname": "ศรีนคร",
            "subdistrictcode": "640803",
            "subdistrictname": "น้ำขุม",
            "zipcode": "64180"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6408",
            "districtname": "ศรีนคร",
            "subdistrictcode": "640804",
            "subdistrictname": "คลองมะพลับ",
            "zipcode": "64180"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6408",
            "districtname": "ศรีนคร",
            "subdistrictcode": "640805",
            "subdistrictname": "หนองบัว",
            "zipcode": "64180"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม",
            "subdistrictcode": "640901",
            "subdistrictname": "บ้านใหม่ไชยมงคล",
            "zipcode": "64230"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม",
            "subdistrictcode": "640902",
            "subdistrictname": "ไทยชนะศึก",
            "zipcode": "64150"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม",
            "subdistrictcode": "640903",
            "subdistrictname": "ทุ่งเสลี่ยม",
            "zipcode": "64150"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม",
            "subdistrictcode": "640904",
            "subdistrictname": "กลางดง",
            "zipcode": "64150"
        },
        {
            "isActive": true,
            "provincecode": "สุโขทัย",
            "districtcode": "6409",
            "districtname": "ทุ่งเสลี่ยม",
            "subdistrictcode": "640905",
            "subdistrictname": "เขาแก้วศรีสมบูรณ์",
            "zipcode": "64230"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650101",
            "subdistrictname": "ในเมือง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650102",
            "subdistrictname": "วังน้ำคู้",
            "zipcode": "65230"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650103",
            "subdistrictname": "วัดจันทร์",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650104",
            "subdistrictname": "วัดพริก",
            "zipcode": "65230"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650105",
            "subdistrictname": "ท่าทอง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650106",
            "subdistrictname": "ท่าโพธิ์",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650107",
            "subdistrictname": "สมอแข",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650108",
            "subdistrictname": "ดอนทอง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650109",
            "subdistrictname": "บ้านป่า",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650110",
            "subdistrictname": "ปากโทก",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650111",
            "subdistrictname": "หัวรอ",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650112",
            "subdistrictname": "จอมทอง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650113",
            "subdistrictname": "บ้านกร่าง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650114",
            "subdistrictname": "บ้านคลอง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650115",
            "subdistrictname": "พลายชุมพล",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650116",
            "subdistrictname": "มะขามสูง",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650117",
            "subdistrictname": "อรัญญิก",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650118",
            "subdistrictname": "บึงพระ",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650119",
            "subdistrictname": "ไผ่ขอดอน",
            "zipcode": "65000"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6501",
            "districtname": "เมืองพิษณุโลก",
            "subdistrictcode": "650120",
            "subdistrictname": "งิ้วงาม",
            "zipcode": "65230"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650201",
            "subdistrictname": "นครไทย",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650202",
            "subdistrictname": "หนองกะท้าว",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650203",
            "subdistrictname": "บ้านแยง",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650204",
            "subdistrictname": "เนินเพิ่ม",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650205",
            "subdistrictname": "นาบัว",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650206",
            "subdistrictname": "นครชุม",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650207",
            "subdistrictname": "น้ำกุ่ม",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650208",
            "subdistrictname": "ยางโกลน",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650209",
            "subdistrictname": "บ่อโพธิ์",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650210",
            "subdistrictname": "บ้านพร้าว",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6502",
            "districtname": "นครไทย",
            "subdistrictcode": "650211",
            "subdistrictname": "ห้วยเฮี้ย",
            "zipcode": "65120"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650301",
            "subdistrictname": "ป่าแดง",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650302",
            "subdistrictname": "ชาติตระการ",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650303",
            "subdistrictname": "สวนเมี่ยง",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650304",
            "subdistrictname": "บ้านดง",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650305",
            "subdistrictname": "บ่อภาค",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6503",
            "districtname": "ชาติตระการ",
            "subdistrictcode": "650306",
            "subdistrictname": "ท่าสะแก",
            "zipcode": "65170"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650401",
            "subdistrictname": "บางระกำ",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650402",
            "subdistrictname": "ปลักแรด",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650403",
            "subdistrictname": "พันเสา",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650404",
            "subdistrictname": "วังอิทก",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650405",
            "subdistrictname": "บึงกอก",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650406",
            "subdistrictname": "หนองกุลา",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650407",
            "subdistrictname": "ชุมแสงสงคราม",
            "zipcode": "65240"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650408",
            "subdistrictname": "นิคมพัฒนา",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650409",
            "subdistrictname": "บ่อทอง",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650410",
            "subdistrictname": "ท่านางงาม",
            "zipcode": "65140"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6504",
            "districtname": "บางระกำ",
            "subdistrictcode": "650411",
            "subdistrictname": "คุยม่วง",
            "zipcode": "65240"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650501",
            "subdistrictname": "บางกระทุ่ม",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650502",
            "subdistrictname": "บ้านไร่",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650503",
            "subdistrictname": "โคกสลุด",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650504",
            "subdistrictname": "สนามคลี",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650505",
            "subdistrictname": "ท่าตาล",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650506",
            "subdistrictname": "ไผ่ล้อม",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650507",
            "subdistrictname": "นครป่าหมาก",
            "zipcode": "65110"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650508",
            "subdistrictname": "เนินกุ่ม",
            "zipcode": "65210"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6505",
            "districtname": "บางกระทุ่ม",
            "subdistrictcode": "650509",
            "subdistrictname": "วัดตายม",
            "zipcode": "65210"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650601",
            "subdistrictname": "พรหมพิราม",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650602",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650603",
            "subdistrictname": "วงฆ้อง",
            "zipcode": "65180"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650604",
            "subdistrictname": "มะตูม",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650605",
            "subdistrictname": "หอกลอง",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650606",
            "subdistrictname": "ศรีภิรมย์",
            "zipcode": "65180"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650607",
            "subdistrictname": "ตลุกเทียม",
            "zipcode": "65180"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650608",
            "subdistrictname": "วังวน",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650609",
            "subdistrictname": "หนองแขม",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650610",
            "subdistrictname": "มะต้อง",
            "zipcode": "65180"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650611",
            "subdistrictname": "ทับยายเชียง",
            "zipcode": "65150"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6506",
            "districtname": "พรหมพิราม",
            "subdistrictcode": "650612",
            "subdistrictname": "ดงประคำ",
            "zipcode": "65180"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650701",
            "subdistrictname": "วัดโบสถ์",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650702",
            "subdistrictname": "ท่างาม",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650703",
            "subdistrictname": "ท้อแท้",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650704",
            "subdistrictname": "บ้านยาง",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650705",
            "subdistrictname": "หินลาด",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6507",
            "districtname": "วัดโบสถ์",
            "subdistrictcode": "650706",
            "subdistrictname": "คันโช้ง",
            "zipcode": "65160"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650801",
            "subdistrictname": "วังทอง",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650802",
            "subdistrictname": "พันชาลี",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650803",
            "subdistrictname": "แม่ระกา",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650804",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "65220"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650805",
            "subdistrictname": "วังพิกุล",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650806",
            "subdistrictname": "แก่งโสภา",
            "zipcode": "65220"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650807",
            "subdistrictname": "ท่าหมื่นราม",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650808",
            "subdistrictname": "วังนกแอ่น",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650809",
            "subdistrictname": "หนองพระ",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650810",
            "subdistrictname": "ชัยนาม",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6508",
            "districtname": "วังทอง",
            "subdistrictcode": "650811",
            "subdistrictname": "ดินทอง",
            "zipcode": "65130"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650901",
            "subdistrictname": "ชมพู",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650902",
            "subdistrictname": "บ้านมุง",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650903",
            "subdistrictname": "ไทรย้อย",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650904",
            "subdistrictname": "วังโพรง",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650905",
            "subdistrictname": "บ้านน้อยซุ้มขี้เหล็ก",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650906",
            "subdistrictname": "เนินมะปราง",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650907",
            "subdistrictname": "วังยาง",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิษณุโลก",
            "districtcode": "6509",
            "districtname": "เนินมะปราง",
            "subdistrictcode": "650908",
            "subdistrictname": "โคกแหลม",
            "zipcode": "65190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660101",
            "subdistrictname": "ในเมือง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660102",
            "subdistrictname": "ไผ่ขวาง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660103",
            "subdistrictname": "ย่านยาว",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660104",
            "subdistrictname": "ท่าฬ่อ",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660105",
            "subdistrictname": "ปากทาง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660106",
            "subdistrictname": "คลองคะเชนทร์",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660107",
            "subdistrictname": "โรงช้าง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660108",
            "subdistrictname": "เมืองเก่า",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660109",
            "subdistrictname": "ท่าหลวง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660110",
            "subdistrictname": "บ้านบุ่ง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660111",
            "subdistrictname": "ฆะมัง",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660112",
            "subdistrictname": "ดงป่าคำ",
            "zipcode": "66170"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660113",
            "subdistrictname": "หัวดง",
            "zipcode": "66170"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660115",
            "subdistrictname": "ป่ามะคาบ",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660119",
            "subdistrictname": "สายคำโห้",
            "zipcode": "66000"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6601",
            "districtname": "เมืองพิจิตร",
            "subdistrictcode": "660120",
            "subdistrictname": "ดงกลาง",
            "zipcode": "66170"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6602",
            "districtname": "วังทรายพูน",
            "subdistrictcode": "660201",
            "subdistrictname": "วังทรายพูน",
            "zipcode": "66180"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6602",
            "districtname": "วังทรายพูน",
            "subdistrictcode": "660202",
            "subdistrictname": "หนองปลาไหล",
            "zipcode": "66180"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6602",
            "districtname": "วังทรายพูน",
            "subdistrictcode": "660203",
            "subdistrictname": "หนองพระ",
            "zipcode": "66180"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6602",
            "districtname": "วังทรายพูน",
            "subdistrictcode": "660204",
            "subdistrictname": "หนองปล้อง",
            "zipcode": "66180"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660301",
            "subdistrictname": "โพธิ์ประทับช้าง",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660302",
            "subdistrictname": "ไผ่ท่าโพ",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660303",
            "subdistrictname": "วังจิก",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660304",
            "subdistrictname": "ไผ่รอบ",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660305",
            "subdistrictname": "ดงเสือเหลือง",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660306",
            "subdistrictname": "เนินสว่าง",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6603",
            "districtname": "โพธิ์ประทับช้าง",
            "subdistrictcode": "660307",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "66190"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660401",
            "subdistrictname": "ตะพานหิน",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660402",
            "subdistrictname": "งิ้วราย",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660403",
            "subdistrictname": "ห้วยเกตุ",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660404",
            "subdistrictname": "ไทรโรงโขน",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660405",
            "subdistrictname": "หนองพยอม",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660406",
            "subdistrictname": "ทุ่งโพธิ์",
            "zipcode": "66150"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660407",
            "subdistrictname": "ดงตะขบ",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660408",
            "subdistrictname": "คลองคูณ",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660409",
            "subdistrictname": "วังสำโรง",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660410",
            "subdistrictname": "วังหว้า",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660411",
            "subdistrictname": "วังหลุม",
            "zipcode": "66150"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660412",
            "subdistrictname": "ทับหมัน",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6604",
            "districtname": "ตะพานหิน",
            "subdistrictcode": "660413",
            "subdistrictname": "ไผ่หลวง",
            "zipcode": "66110"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660501",
            "subdistrictname": "บางมูลนาก",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660502",
            "subdistrictname": "บางไผ่",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660503",
            "subdistrictname": "หอไกร",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660504",
            "subdistrictname": "เนินมะกอก",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660505",
            "subdistrictname": "วังสำโรง",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660506",
            "subdistrictname": "ภูมิ",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660507",
            "subdistrictname": "วังกรด",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660508",
            "subdistrictname": "ห้วยเขน",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660509",
            "subdistrictname": "วังตะกู",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6605",
            "districtname": "บางมูลนาก",
            "subdistrictcode": "660514",
            "subdistrictname": "ลำประดา",
            "zipcode": "66120"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660601",
            "subdistrictname": "โพทะเล",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660602",
            "subdistrictname": "ท้ายน้ำ",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660603",
            "subdistrictname": "ทะนง",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660604",
            "subdistrictname": "ท่าบัว",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660605",
            "subdistrictname": "ทุ่งน้อย",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660606",
            "subdistrictname": "ท่าขมิ้น",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660607",
            "subdistrictname": "ท่าเสา",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660608",
            "subdistrictname": "บางคลาน",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660611",
            "subdistrictname": "ท่านั่ง",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660612",
            "subdistrictname": "บ้านน้อย",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6606",
            "districtname": "โพทะเล",
            "subdistrictcode": "660613",
            "subdistrictname": "วัดขวาง",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6607",
            "districtname": "สามง่าม",
            "subdistrictcode": "660701",
            "subdistrictname": "สามง่าม",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6607",
            "districtname": "สามง่าม",
            "subdistrictcode": "660702",
            "subdistrictname": "กำแพงดิน",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6607",
            "districtname": "สามง่าม",
            "subdistrictcode": "660703",
            "subdistrictname": "รังนก",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6607",
            "districtname": "สามง่าม",
            "subdistrictcode": "660706",
            "subdistrictname": "เนินปอ",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6607",
            "districtname": "สามง่าม",
            "subdistrictcode": "660707",
            "subdistrictname": "หนองโสน",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6608",
            "districtname": "ทับคล้อ",
            "subdistrictcode": "660801",
            "subdistrictname": "ทับคล้อ",
            "zipcode": "66150"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6608",
            "districtname": "ทับคล้อ",
            "subdistrictcode": "660802",
            "subdistrictname": "เขาทราย",
            "zipcode": "66230"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6608",
            "districtname": "ทับคล้อ",
            "subdistrictcode": "660803",
            "subdistrictname": "เขาเจ็ดลูก",
            "zipcode": "66230"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6608",
            "districtname": "ทับคล้อ",
            "subdistrictcode": "660804",
            "subdistrictname": "ท้ายทุ่ง",
            "zipcode": "66150"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6609",
            "districtname": "สากเหล็ก",
            "subdistrictcode": "660901",
            "subdistrictname": "สากเหล็ก",
            "zipcode": "66160"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6609",
            "districtname": "สากเหล็ก",
            "subdistrictcode": "660902",
            "subdistrictname": "ท่าเยี่ยม",
            "zipcode": "66160"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6609",
            "districtname": "สากเหล็ก",
            "subdistrictcode": "660903",
            "subdistrictname": "คลองทราย",
            "zipcode": "66160"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6609",
            "districtname": "สากเหล็ก",
            "subdistrictcode": "660904",
            "subdistrictname": "หนองหญ้าไทร",
            "zipcode": "66160"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6609",
            "districtname": "สากเหล็ก",
            "subdistrictcode": "660905",
            "subdistrictname": "วังทับไทร",
            "zipcode": "66160"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6610",
            "districtname": "บึงนาราง",
            "subdistrictcode": "661001",
            "subdistrictname": "ห้วยแก้ว",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6610",
            "districtname": "บึงนาราง",
            "subdistrictcode": "661002",
            "subdistrictname": "โพธิ์ไทรงาม",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6610",
            "districtname": "บึงนาราง",
            "subdistrictcode": "661003",
            "subdistrictname": "แหลมรัง",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6610",
            "districtname": "บึงนาราง",
            "subdistrictcode": "661004",
            "subdistrictname": "บางลาย",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6610",
            "districtname": "บึงนาราง",
            "subdistrictcode": "661005",
            "subdistrictname": "บึงนาราง",
            "zipcode": "66130"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6611",
            "districtname": "ดงเจริญ",
            "subdistrictcode": "661101",
            "subdistrictname": "วังงิ้วใต้",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6611",
            "districtname": "ดงเจริญ",
            "subdistrictcode": "661102",
            "subdistrictname": "วังงิ้ว",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6611",
            "districtname": "ดงเจริญ",
            "subdistrictcode": "661103",
            "subdistrictname": "ห้วยร่วม",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6611",
            "districtname": "ดงเจริญ",
            "subdistrictcode": "661104",
            "subdistrictname": "ห้วยพุก",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6611",
            "districtname": "ดงเจริญ",
            "subdistrictcode": "661105",
            "subdistrictname": "สำนักขุนเณร",
            "zipcode": "66210"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6612",
            "districtname": "วชิรบารมี",
            "subdistrictcode": "661201",
            "subdistrictname": "บ้านนา",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6612",
            "districtname": "วชิรบารมี",
            "subdistrictcode": "661202",
            "subdistrictname": "บึงบัว",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6612",
            "districtname": "วชิรบารมี",
            "subdistrictcode": "661203",
            "subdistrictname": "วังโมกข์",
            "zipcode": "66140"
        },
        {
            "isActive": true,
            "provincecode": "พิจิตร",
            "districtcode": "6612",
            "districtname": "วชิรบารมี",
            "subdistrictcode": "661204",
            "subdistrictname": "หนองหลุม",
            "zipcode": "66220"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670101",
            "subdistrictname": "ในเมือง",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670102",
            "subdistrictname": "ตะเบาะ",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670103",
            "subdistrictname": "บ้านโตก",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670104",
            "subdistrictname": "สะเดียง",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670105",
            "subdistrictname": "ป่าเลา",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670106",
            "subdistrictname": "นางั่ว",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670107",
            "subdistrictname": "ท่าพล",
            "zipcode": "67250"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670108",
            "subdistrictname": "ดงมูลเหล็ก",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670109",
            "subdistrictname": "บ้านโคก",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670110",
            "subdistrictname": "ชอนไพร",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670111",
            "subdistrictname": "นาป่า",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670112",
            "subdistrictname": "นายม",
            "zipcode": "67210"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670113",
            "subdistrictname": "วังชมภู",
            "zipcode": "67210"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670114",
            "subdistrictname": "น้ำร้อน",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670115",
            "subdistrictname": "ห้วยสะแก",
            "zipcode": "67210"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670116",
            "subdistrictname": "ห้วยใหญ่",
            "zipcode": "67000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6701",
            "districtname": "เมืองเพชรบูรณ์",
            "subdistrictcode": "670117",
            "subdistrictname": "ระวิง",
            "zipcode": "67210"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670201",
            "subdistrictname": "ชนแดน",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670202",
            "subdistrictname": "ดงขุย",
            "zipcode": "67190"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670203",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670204",
            "subdistrictname": "พุทธบาท",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670205",
            "subdistrictname": "ลาดแค",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670206",
            "subdistrictname": "บ้านกล้วย",
            "zipcode": "67190"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670208",
            "subdistrictname": "ซับพุทรา",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670209",
            "subdistrictname": "ตะกุดไร",
            "zipcode": "67190"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6702",
            "districtname": "ชนแดน",
            "subdistrictcode": "670210",
            "subdistrictname": "ศาลาลาย",
            "zipcode": "67150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670301",
            "subdistrictname": "หล่มสัก",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670302",
            "subdistrictname": "วัดป่า",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670303",
            "subdistrictname": "ตาลเดี่ยว",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670304",
            "subdistrictname": "ฝายนาแซง",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670305",
            "subdistrictname": "หนองสว่าง",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670306",
            "subdistrictname": "น้ำเฮี้ย",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670307",
            "subdistrictname": "สักหลง",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670308",
            "subdistrictname": "ท่าอิบุญ",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670309",
            "subdistrictname": "บ้านโสก",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670310",
            "subdistrictname": "บ้านติ้ว",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670311",
            "subdistrictname": "ห้วยไร่",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670312",
            "subdistrictname": "น้ำก้อ",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670313",
            "subdistrictname": "ปากช่อง",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670314",
            "subdistrictname": "น้ำชุน",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670315",
            "subdistrictname": "หนองไขว่",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670316",
            "subdistrictname": "ลานบ่า",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670317",
            "subdistrictname": "บุ่งคล้า",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670318",
            "subdistrictname": "บุ่งน้ำเต้า",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670319",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670320",
            "subdistrictname": "ช้างตะลูด",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670321",
            "subdistrictname": "บ้านไร่",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670322",
            "subdistrictname": "ปากดุก",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6703",
            "districtname": "หล่มสัก",
            "subdistrictcode": "670323",
            "subdistrictname": "บ้านหวาย",
            "zipcode": "67110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670401",
            "subdistrictname": "หล่มเก่า",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670402",
            "subdistrictname": "นาซำ",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670403",
            "subdistrictname": "หินฮาว",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670404",
            "subdistrictname": "บ้านเนิน",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670405",
            "subdistrictname": "ศิลา",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670406",
            "subdistrictname": "นาแซง",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670407",
            "subdistrictname": "วังบาล",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670408",
            "subdistrictname": "นาเกาะ",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6704",
            "districtname": "หล่มเก่า",
            "subdistrictcode": "670409",
            "subdistrictname": "ตาดกลอย",
            "zipcode": "67120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670501",
            "subdistrictname": "ท่าโรง",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670502",
            "subdistrictname": "สระประดู่",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670503",
            "subdistrictname": "สามแยก",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670504",
            "subdistrictname": "โคกปรง",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670505",
            "subdistrictname": "น้ำร้อน",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670506",
            "subdistrictname": "บ่อรัง",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670507",
            "subdistrictname": "พุเตย",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670508",
            "subdistrictname": "พุขาม",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670509",
            "subdistrictname": "ภูน้ำหยด",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670510",
            "subdistrictname": "ซับสมบูรณ์",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670511",
            "subdistrictname": "บึงกระจับ",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670512",
            "subdistrictname": "วังใหญ่",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670513",
            "subdistrictname": "ยางสาว",
            "zipcode": "67130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6705",
            "districtname": "วิเชียรบุรี",
            "subdistrictcode": "670514",
            "subdistrictname": "ซับน้อย",
            "zipcode": "67180"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670601",
            "subdistrictname": "ศรีเทพ",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670602",
            "subdistrictname": "สระกรวด",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670603",
            "subdistrictname": "คลองกระจัง",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670604",
            "subdistrictname": "นาสนุ่น",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670605",
            "subdistrictname": "โคกสะอาด",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670606",
            "subdistrictname": "หนองย่างทอย",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6706",
            "districtname": "ศรีเทพ",
            "subdistrictcode": "670607",
            "subdistrictname": "ประดู่งาม",
            "zipcode": "67170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670701",
            "subdistrictname": "กองทูล",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670702",
            "subdistrictname": "นาเฉลียง",
            "zipcode": "67220"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670703",
            "subdistrictname": "บ้านโภชน์",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670704",
            "subdistrictname": "ท่าแดง",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670705",
            "subdistrictname": "เพชรละคร",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670706",
            "subdistrictname": "บ่อไทย",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670707",
            "subdistrictname": "ห้วยโป่ง",
            "zipcode": "67220"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670708",
            "subdistrictname": "วังท่าดี",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670709",
            "subdistrictname": "บัววัฒนา",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670710",
            "subdistrictname": "หนองไผ่",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670711",
            "subdistrictname": "วังโบสถ์",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670712",
            "subdistrictname": "ยางงาม",
            "zipcode": "67220"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6707",
            "districtname": "หนองไผ่",
            "subdistrictcode": "670713",
            "subdistrictname": "ท่าด้วง",
            "zipcode": "67140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670801",
            "subdistrictname": "ซับสมอทอด",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670802",
            "subdistrictname": "ซับไม้แดง",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670803",
            "subdistrictname": "หนองแจง",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670804",
            "subdistrictname": "กันจุ",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670805",
            "subdistrictname": "วังพิกุล",
            "zipcode": "67230"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670806",
            "subdistrictname": "พญาวัง",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670807",
            "subdistrictname": "ศรีมงคล",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670808",
            "subdistrictname": "สระแก้ว",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6708",
            "districtname": "บึงสามพัน",
            "subdistrictcode": "670809",
            "subdistrictname": "บึงสามพัน",
            "zipcode": "67160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6709",
            "districtname": "น้ำหนาว",
            "subdistrictcode": "670901",
            "subdistrictname": "น้ำหนาว",
            "zipcode": "67260"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6709",
            "districtname": "น้ำหนาว",
            "subdistrictcode": "670902",
            "subdistrictname": "หลักด่าน",
            "zipcode": "67260"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6709",
            "districtname": "น้ำหนาว",
            "subdistrictcode": "670903",
            "subdistrictname": "วังกวาง",
            "zipcode": "67260"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6709",
            "districtname": "น้ำหนาว",
            "subdistrictcode": "670904",
            "subdistrictname": "โคกมน",
            "zipcode": "67260"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6710",
            "districtname": "วังโป่ง",
            "subdistrictcode": "671001",
            "subdistrictname": "วังโป่ง",
            "zipcode": "67240"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6710",
            "districtname": "วังโป่ง",
            "subdistrictcode": "671002",
            "subdistrictname": "ท้ายดง",
            "zipcode": "67240"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6710",
            "districtname": "วังโป่ง",
            "subdistrictcode": "671003",
            "subdistrictname": "ซับเปิบ",
            "zipcode": "67240"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6710",
            "districtname": "วังโป่ง",
            "subdistrictcode": "671004",
            "subdistrictname": "วังหิน",
            "zipcode": "67240"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6710",
            "districtname": "วังโป่ง",
            "subdistrictcode": "671005",
            "subdistrictname": "วังศาล",
            "zipcode": "67240"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671101",
            "subdistrictname": "ทุ่งสมอ",
            "zipcode": "67270"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671102",
            "subdistrictname": "แคมป์สน",
            "zipcode": "67280"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671103",
            "subdistrictname": "เขาค้อ",
            "zipcode": "67270"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671104",
            "subdistrictname": "ริมสีม่วง",
            "zipcode": "67270"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671105",
            "subdistrictname": "สะเดาะพง",
            "zipcode": "67270"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671106",
            "subdistrictname": "หนองแม่นา",
            "zipcode": "67270"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบูรณ์",
            "districtcode": "6711",
            "districtname": "เขาค้อ",
            "subdistrictcode": "671107",
            "subdistrictname": "เข็กน้อย",
            "zipcode": "67280"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700101",
            "subdistrictname": "หน้าเมือง",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700102",
            "subdistrictname": "เจดีย์หัก",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700103",
            "subdistrictname": "ดอนตะโก",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700104",
            "subdistrictname": "หนองกลางนา",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700105",
            "subdistrictname": "ห้วยไผ่",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700106",
            "subdistrictname": "คุ้งน้ำวน",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700107",
            "subdistrictname": "คุ้งกระถิน",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700108",
            "subdistrictname": "อ่างทอง",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700109",
            "subdistrictname": "โคกหม้อ",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700110",
            "subdistrictname": "สามเรือน",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700111",
            "subdistrictname": "พิกุลทอง",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700112",
            "subdistrictname": "น้ำพุ",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700113",
            "subdistrictname": "ดอนแร่",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700114",
            "subdistrictname": "หินกอง",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700115",
            "subdistrictname": "เขาแร้ง",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700116",
            "subdistrictname": "เกาะพลับพลา",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700117",
            "subdistrictname": "หลุมดิน",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700118",
            "subdistrictname": "บางป่า",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700119",
            "subdistrictname": "พงสวาย",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700120",
            "subdistrictname": "คูบัว",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700121",
            "subdistrictname": "ท่าราบ",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7001",
            "districtname": "เมืองราชบุรี",
            "subdistrictcode": "700122",
            "subdistrictname": "บ้านไร่",
            "zipcode": "70000"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700201",
            "subdistrictname": "จอมบึง",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700202",
            "subdistrictname": "ปากช่อง",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700203",
            "subdistrictname": "เบิกไพร",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700204",
            "subdistrictname": "ด่านทับตะโก",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700205",
            "subdistrictname": "แก้มอ้น",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7002",
            "districtname": "จอมบึง",
            "subdistrictcode": "700206",
            "subdistrictname": "รางบัว",
            "zipcode": "70150"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7003",
            "districtname": "สวนผึ้ง",
            "subdistrictcode": "700301",
            "subdistrictname": "สวนผึ้ง",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7003",
            "districtname": "สวนผึ้ง",
            "subdistrictcode": "700302",
            "subdistrictname": "ป่าหวาย",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7003",
            "districtname": "สวนผึ้ง",
            "subdistrictcode": "700304",
            "subdistrictname": "ท่าเคย",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7003",
            "districtname": "สวนผึ้ง",
            "subdistrictcode": "700307",
            "subdistrictname": "ตะนาวศรี",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700401",
            "subdistrictname": "ดำเนินสะดวก",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700402",
            "subdistrictname": "ประสาทสิทธิ์",
            "zipcode": "70210"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700403",
            "subdistrictname": "ศรีสุราษฎร์",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700404",
            "subdistrictname": "ตาหลวง",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700405",
            "subdistrictname": "ดอนกรวย",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700406",
            "subdistrictname": "ดอนคลัง",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700407",
            "subdistrictname": "บัวงาม",
            "zipcode": "70210"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700408",
            "subdistrictname": "บ้านไร่",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700409",
            "subdistrictname": "แพงพวย",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700410",
            "subdistrictname": "สี่หมื่น",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700411",
            "subdistrictname": "ท่านัด",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700412",
            "subdistrictname": "ขุนพิทักษ์",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7004",
            "districtname": "ดำเนินสะดวก",
            "subdistrictcode": "700413",
            "subdistrictname": "ดอนไผ่",
            "zipcode": "70130"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700501",
            "subdistrictname": "บ้านโป่ง",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700502",
            "subdistrictname": "ท่าผา",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700503",
            "subdistrictname": "กรับใหญ่",
            "zipcode": "70190"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700504",
            "subdistrictname": "ปากแรต",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700505",
            "subdistrictname": "หนองกบ",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700506",
            "subdistrictname": "หนองอ้อ",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700507",
            "subdistrictname": "ดอนกระเบื้อง",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700508",
            "subdistrictname": "สวนกล้วย",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700509",
            "subdistrictname": "นครชุมน์",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700510",
            "subdistrictname": "บ้านม่วง",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700511",
            "subdistrictname": "คุ้งพยอม",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700512",
            "subdistrictname": "หนองปลาหมอ",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700513",
            "subdistrictname": "เขาขลุง",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700514",
            "subdistrictname": "เบิกไพร",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7005",
            "districtname": "บ้านโป่ง",
            "subdistrictcode": "700515",
            "subdistrictname": "ลาดบัวขาว",
            "zipcode": "70110"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700601",
            "subdistrictname": "บางแพ",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700602",
            "subdistrictname": "วังเย็น",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700603",
            "subdistrictname": "หัวโพ",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700604",
            "subdistrictname": "วัดแก้ว",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700605",
            "subdistrictname": "ดอนใหญ่",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700606",
            "subdistrictname": "ดอนคา",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7006",
            "districtname": "บางแพ",
            "subdistrictcode": "700607",
            "subdistrictname": "โพหัก",
            "zipcode": "70160"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700701",
            "subdistrictname": "โพธาราม",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700702",
            "subdistrictname": "ดอนกระเบื้อง",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700703",
            "subdistrictname": "หนองโพ",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700704",
            "subdistrictname": "บ้านเลือก",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700705",
            "subdistrictname": "คลองตาคต",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700706",
            "subdistrictname": "บ้านฆ้อง",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700707",
            "subdistrictname": "บ้านสิงห์",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700708",
            "subdistrictname": "ดอนทราย",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700709",
            "subdistrictname": "เจ็ดเสมียน",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700710",
            "subdistrictname": "คลองข่อย",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700711",
            "subdistrictname": "ชำแระ",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700712",
            "subdistrictname": "สร้อยฟ้า",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700713",
            "subdistrictname": "ท่าชุมพล",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700714",
            "subdistrictname": "บางโตนด",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700715",
            "subdistrictname": "เตาปูน",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700716",
            "subdistrictname": "นางแก้ว",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700717",
            "subdistrictname": "ธรรมเสน",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700718",
            "subdistrictname": "เขาชะงุ้ม",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7007",
            "districtname": "โพธาราม",
            "subdistrictcode": "700719",
            "subdistrictname": "หนองกวาง",
            "zipcode": "70120"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700801",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700802",
            "subdistrictname": "วังมะนาว",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700803",
            "subdistrictname": "ดอนทราย",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700804",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700805",
            "subdistrictname": "ปากท่อ",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700806",
            "subdistrictname": "ป่าไก่",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700807",
            "subdistrictname": "วัดยางงาม",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700808",
            "subdistrictname": "อ่างหิน",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700809",
            "subdistrictname": "บ่อกระดาน",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700810",
            "subdistrictname": "ยางหัก",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700811",
            "subdistrictname": "วันดาว",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7008",
            "districtname": "ปากท่อ",
            "subdistrictcode": "700812",
            "subdistrictname": "ห้วยยางโทน",
            "zipcode": "70140"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7009",
            "districtname": "วัดเพลง",
            "subdistrictcode": "700901",
            "subdistrictname": "เกาะศาลพระ",
            "zipcode": "70170"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7009",
            "districtname": "วัดเพลง",
            "subdistrictcode": "700902",
            "subdistrictname": "จอมประทัด",
            "zipcode": "70170"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7009",
            "districtname": "วัดเพลง",
            "subdistrictcode": "700903",
            "subdistrictname": "วัดเพลง",
            "zipcode": "70170"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7010",
            "districtname": "บ้านคา",
            "subdistrictcode": "701001",
            "subdistrictname": "บ้านคา",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7010",
            "districtname": "บ้านคา",
            "subdistrictcode": "701002",
            "subdistrictname": "บ้านบึง",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "ราชบุรี",
            "districtcode": "7010",
            "districtname": "บ้านคา",
            "subdistrictcode": "701003",
            "subdistrictname": "หนองพันจันทร์",
            "zipcode": "70180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710101",
            "subdistrictname": "บ้านเหนือ",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710102",
            "subdistrictname": "บ้านใต้",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710103",
            "subdistrictname": "ปากแพรก",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710104",
            "subdistrictname": "ท่ามะขาม",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710105",
            "subdistrictname": "แก่งเสี้ยน",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710106",
            "subdistrictname": "หนองบัว",
            "zipcode": "71190"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710107",
            "subdistrictname": "ลาดหญ้า",
            "zipcode": "71190"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710108",
            "subdistrictname": "วังด้ง",
            "zipcode": "71190"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710109",
            "subdistrictname": "ช่องสะเดา",
            "zipcode": "71190"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710110",
            "subdistrictname": "หนองหญ้า",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710111",
            "subdistrictname": "เกาะสำโรง",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710113",
            "subdistrictname": "บ้านเก่า",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7101",
            "districtname": "เมืองกาญจนบุรี",
            "subdistrictcode": "710116",
            "subdistrictname": "วังเย็น",
            "zipcode": "71000"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710201",
            "subdistrictname": "ลุ่มสุ่ม",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710202",
            "subdistrictname": "ท่าเสา",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710203",
            "subdistrictname": "สิงห์",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710204",
            "subdistrictname": "ไทรโยค",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710205",
            "subdistrictname": "วังกระแจะ",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710206",
            "subdistrictname": "ศรีมงคล",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7102",
            "districtname": "ไทรโยค",
            "subdistrictcode": "710207",
            "subdistrictname": "บ้องตี้",
            "zipcode": "71150"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710301",
            "subdistrictname": "บ่อพลอย",
            "zipcode": "71160"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710302",
            "subdistrictname": "หนองกุ่ม",
            "zipcode": "71160"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710303",
            "subdistrictname": "หนองรี",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710305",
            "subdistrictname": "หลุมรัง",
            "zipcode": "71160"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710308",
            "subdistrictname": "ช่องด่าน",
            "zipcode": "71160"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7103",
            "districtname": "บ่อพลอย",
            "subdistrictcode": "710309",
            "subdistrictname": "หนองกร่าง",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710401",
            "subdistrictname": "นาสวน",
            "zipcode": "71250"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710402",
            "subdistrictname": "ด่านแม่แฉลบ",
            "zipcode": "71250"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710403",
            "subdistrictname": "หนองเป็ด",
            "zipcode": "71250"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710404",
            "subdistrictname": "ท่ากระดาน",
            "zipcode": "71250"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710405",
            "subdistrictname": "เขาโจด",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7104",
            "districtname": "ศรีสวัสดิ์",
            "subdistrictcode": "710406",
            "subdistrictname": "แม่กระบุง",
            "zipcode": "71250"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710501",
            "subdistrictname": "พงตึก",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710502",
            "subdistrictname": "ยางม่วง",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710503",
            "subdistrictname": "ดอนชะเอม",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710504",
            "subdistrictname": "ท่าไม้",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710505",
            "subdistrictname": "ตะคร้ำเอน",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710506",
            "subdistrictname": "ท่ามะกา",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710507",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710508",
            "subdistrictname": "โคกตะบอง",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710509",
            "subdistrictname": "ดอนขมิ้น",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710510",
            "subdistrictname": "อุโลกสี่หมื่น",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710511",
            "subdistrictname": "เขาสามสิบหาบ",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710512",
            "subdistrictname": "พระแท่น",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710513",
            "subdistrictname": "หวายเหนียว",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710514",
            "subdistrictname": "แสนตอ",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710515",
            "subdistrictname": "สนามแย้",
            "zipcode": "70190"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710516",
            "subdistrictname": "ท่าเสา",
            "zipcode": "71120"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7105",
            "districtname": "ท่ามะกา",
            "subdistrictcode": "710517",
            "subdistrictname": "หนองลาน",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710601",
            "subdistrictname": "ท่าม่วง",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710602",
            "subdistrictname": "วังขนาย",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710603",
            "subdistrictname": "วังศาลา",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710604",
            "subdistrictname": "ท่าล้อ",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710605",
            "subdistrictname": "หนองขาว",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710606",
            "subdistrictname": "ทุ่งทอง",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710607",
            "subdistrictname": "เขาน้อย",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710608",
            "subdistrictname": "ม่วงชุม",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710609",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710610",
            "subdistrictname": "พังตรุ",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710611",
            "subdistrictname": "ท่าตะคร้อ",
            "zipcode": "71130"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710612",
            "subdistrictname": "รางสาลี่",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7106",
            "districtname": "ท่าม่วง",
            "subdistrictcode": "710613",
            "subdistrictname": "หนองตากยา",
            "zipcode": "71110"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710701",
            "subdistrictname": "ท่าขนุน",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710702",
            "subdistrictname": "ปิล๊อก",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710703",
            "subdistrictname": "หินดาด",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710704",
            "subdistrictname": "ลิ่นถิ่น",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710705",
            "subdistrictname": "ชะแล",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710706",
            "subdistrictname": "ห้วยเขย่ง",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7107",
            "districtname": "ทองผาภูมิ",
            "subdistrictcode": "710707",
            "subdistrictname": "สหกรณ์นิคม",
            "zipcode": "71180"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7108",
            "districtname": "สังขละบุรี",
            "subdistrictcode": "710801",
            "subdistrictname": "หนองลู",
            "zipcode": "71240"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7108",
            "districtname": "สังขละบุรี",
            "subdistrictcode": "710802",
            "subdistrictname": "ปรังเผล",
            "zipcode": "71240"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7108",
            "districtname": "สังขละบุรี",
            "subdistrictcode": "710803",
            "subdistrictname": "ไล่โว่",
            "zipcode": "71240"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710901",
            "subdistrictname": "พนมทวน",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710902",
            "subdistrictname": "หนองโรง",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710903",
            "subdistrictname": "ทุ่งสมอ",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710904",
            "subdistrictname": "ดอนเจดีย์",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710905",
            "subdistrictname": "พังตรุ",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710906",
            "subdistrictname": "รางหวาย",
            "zipcode": "71170"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710911",
            "subdistrictname": "หนองสาหร่าย",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7109",
            "districtname": "พนมทวน",
            "subdistrictcode": "710912",
            "subdistrictname": "ดอนตาเพชร",
            "zipcode": "71140"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711001",
            "subdistrictname": "เลาขวัญ",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711002",
            "subdistrictname": "หนองโสน",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711003",
            "subdistrictname": "หนองประดู่",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711004",
            "subdistrictname": "หนองปลิง",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711005",
            "subdistrictname": "หนองนกแก้ว",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711006",
            "subdistrictname": "ทุ่งกระบ่ำ",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7110",
            "districtname": "เลาขวัญ",
            "subdistrictcode": "711007",
            "subdistrictname": "หนองฝ้าย",
            "zipcode": "71210"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7111",
            "districtname": "ด่านมะขามเตี้ย",
            "subdistrictcode": "711101",
            "subdistrictname": "ด่านมะขามเตี้ย",
            "zipcode": "71260"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7111",
            "districtname": "ด่านมะขามเตี้ย",
            "subdistrictcode": "711102",
            "subdistrictname": "กลอนโด",
            "zipcode": "71260"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7111",
            "districtname": "ด่านมะขามเตี้ย",
            "subdistrictcode": "711103",
            "subdistrictname": "จรเข้เผือก",
            "zipcode": "71260"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7111",
            "districtname": "ด่านมะขามเตี้ย",
            "subdistrictcode": "711104",
            "subdistrictname": "หนองไผ่",
            "zipcode": "71260"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7112",
            "districtname": "หนองปรือ",
            "subdistrictcode": "711201",
            "subdistrictname": "หนองปรือ",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7112",
            "districtname": "หนองปรือ",
            "subdistrictcode": "711202",
            "subdistrictname": "หนองปลาไหล",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7112",
            "districtname": "หนองปรือ",
            "subdistrictcode": "711203",
            "subdistrictname": "สมเด็จเจริญ",
            "zipcode": "71220"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7113",
            "districtname": "ห้วยกระเจา",
            "subdistrictcode": "711301",
            "subdistrictname": "ห้วยกระเจา",
            "zipcode": "71170"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7113",
            "districtname": "ห้วยกระเจา",
            "subdistrictcode": "711302",
            "subdistrictname": "วังไผ่",
            "zipcode": "71170"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7113",
            "districtname": "ห้วยกระเจา",
            "subdistrictcode": "711303",
            "subdistrictname": "ดอนแสลบ",
            "zipcode": "71170"
        },
        {
            "isActive": true,
            "provincecode": "กาญจนบุรี",
            "districtcode": "7113",
            "districtname": "ห้วยกระเจา",
            "subdistrictcode": "711304",
            "subdistrictname": "สระลงเรือ",
            "zipcode": "71170"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720101",
            "subdistrictname": "ท่าพี่เลี้ยง",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720102",
            "subdistrictname": "รั้วใหญ่",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720103",
            "subdistrictname": "ทับตีเหล็ก",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720104",
            "subdistrictname": "ท่าระหัด",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720105",
            "subdistrictname": "ไผ่ขวาง",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720106",
            "subdistrictname": "โคกโคเฒ่า",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720107",
            "subdistrictname": "ดอนตาล",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720108",
            "subdistrictname": "ดอนมะสังข์",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720109",
            "subdistrictname": "พิหารแดง",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720110",
            "subdistrictname": "ดอนกำยาน",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720111",
            "subdistrictname": "ดอนโพธิ์ทอง",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720112",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720113",
            "subdistrictname": "สระแก้ว",
            "zipcode": "72230"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720114",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "72230"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720115",
            "subdistrictname": "บางกุ้ง",
            "zipcode": "72210"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720116",
            "subdistrictname": "ศาลาขาว",
            "zipcode": "72210"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720117",
            "subdistrictname": "สวนแตง",
            "zipcode": "72210"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720118",
            "subdistrictname": "สนามชัย",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720119",
            "subdistrictname": "โพธิ์พระยา",
            "zipcode": "72000"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7201",
            "districtname": "เมืองสุพรรณบุรี",
            "subdistrictcode": "720120",
            "subdistrictname": "สนามคลี",
            "zipcode": "72230"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720201",
            "subdistrictname": "เขาพระ",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720202",
            "subdistrictname": "เดิมบาง",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720203",
            "subdistrictname": "นางบวช",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720204",
            "subdistrictname": "เขาดิน",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720205",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720206",
            "subdistrictname": "ทุ่งคลี",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720207",
            "subdistrictname": "โคกช้าง",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720208",
            "subdistrictname": "หัวเขา",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720209",
            "subdistrictname": "หัวนา",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720210",
            "subdistrictname": "บ่อกรุ",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720211",
            "subdistrictname": "วังศรีราช",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720212",
            "subdistrictname": "ป่าสะแก",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720213",
            "subdistrictname": "ยางนอน",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7202",
            "districtname": "เดิมบางนางบวช",
            "subdistrictcode": "720214",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "72120"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720301",
            "subdistrictname": "หนองมะค่าโมง",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720302",
            "subdistrictname": "ด่านช้าง",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720303",
            "subdistrictname": "ห้วยขมิ้น",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720304",
            "subdistrictname": "องค์พระ",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720305",
            "subdistrictname": "วังคัน",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720306",
            "subdistrictname": "นิคมกระเสียว",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7203",
            "districtname": "ด่านช้าง",
            "subdistrictcode": "720307",
            "subdistrictname": "วังยาว",
            "zipcode": "72180"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720401",
            "subdistrictname": "โคกคราม",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720402",
            "subdistrictname": "บางปลาม้า",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720403",
            "subdistrictname": "ตะค่า",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720404",
            "subdistrictname": "บางใหญ่",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720405",
            "subdistrictname": "กฤษณา",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720406",
            "subdistrictname": "สาลี",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720407",
            "subdistrictname": "ไผ่กองดิน",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720408",
            "subdistrictname": "องครักษ์",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720409",
            "subdistrictname": "จรเข้ใหญ่",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720410",
            "subdistrictname": "บ้านแหลม",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720411",
            "subdistrictname": "มะขามล้ม",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720412",
            "subdistrictname": "วังน้ำเย็น",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720413",
            "subdistrictname": "วัดโบสถ์",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7204",
            "districtname": "บางปลาม้า",
            "subdistrictcode": "720414",
            "subdistrictname": "วัดดาว",
            "zipcode": "72150"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720501",
            "subdistrictname": "ศรีประจันต์",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720502",
            "subdistrictname": "บ้านกร่าง",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720503",
            "subdistrictname": "มดแดง",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720504",
            "subdistrictname": "บางงาม",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720505",
            "subdistrictname": "ดอนปรู",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720506",
            "subdistrictname": "ปลายนา",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720507",
            "subdistrictname": "วังหว้า",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720508",
            "subdistrictname": "วังน้ำซับ",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7205",
            "districtname": "ศรีประจันต์",
            "subdistrictcode": "720509",
            "subdistrictname": "วังยาง",
            "zipcode": "72140"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์",
            "subdistrictcode": "720601",
            "subdistrictname": "ดอนเจดีย์",
            "zipcode": "72170"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์",
            "subdistrictcode": "720602",
            "subdistrictname": "หนองสาหร่าย",
            "zipcode": "72170"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์",
            "subdistrictcode": "720603",
            "subdistrictname": "ไร่รถ",
            "zipcode": "72170"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์",
            "subdistrictcode": "720604",
            "subdistrictname": "สระกระโจม",
            "zipcode": "72250"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7206",
            "districtname": "ดอนเจดีย์",
            "subdistrictcode": "720605",
            "subdistrictname": "ทะเลบก",
            "zipcode": "72250"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720701",
            "subdistrictname": "สองพี่น้อง",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720702",
            "subdistrictname": "บางเลน",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720703",
            "subdistrictname": "บางตาเถร",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720704",
            "subdistrictname": "บางตะเคียน",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720705",
            "subdistrictname": "บ้านกุ่ม",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720706",
            "subdistrictname": "หัวโพธิ์",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720707",
            "subdistrictname": "บางพลับ",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720708",
            "subdistrictname": "เนินพระปรางค์",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720709",
            "subdistrictname": "บ้านช้าง",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720710",
            "subdistrictname": "ต้นตาล",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720711",
            "subdistrictname": "ศรีสำราญ",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720712",
            "subdistrictname": "ทุ่งคอก",
            "zipcode": "72190"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720713",
            "subdistrictname": "หนองบ่อ",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720714",
            "subdistrictname": "บ่อสุพรรณ",
            "zipcode": "72190"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7207",
            "districtname": "สองพี่น้อง",
            "subdistrictcode": "720715",
            "subdistrictname": "ดอนมะนาว",
            "zipcode": "72110"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720801",
            "subdistrictname": "ย่านยาว",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720802",
            "subdistrictname": "วังลึก",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720803",
            "subdistrictname": "สามชุก",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720804",
            "subdistrictname": "หนองผักนาก",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720805",
            "subdistrictname": "บ้านสระ",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720806",
            "subdistrictname": "หนองสะเดา",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7208",
            "districtname": "สามชุก",
            "subdistrictcode": "720807",
            "subdistrictname": "กระเสียว",
            "zipcode": "72130"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720901",
            "subdistrictname": "อู่ทอง",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720902",
            "subdistrictname": "สระยายโสม",
            "zipcode": "72220"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720903",
            "subdistrictname": "จรเข้สามพัน",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720904",
            "subdistrictname": "บ้านดอน",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720905",
            "subdistrictname": "ยุ้งทะลาย",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720906",
            "subdistrictname": "ดอนมะเกลือ",
            "zipcode": "72220"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720907",
            "subdistrictname": "หนองโอ่ง",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720908",
            "subdistrictname": "ดอนคา",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720909",
            "subdistrictname": "พลับพลาไชย",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720910",
            "subdistrictname": "บ้านโข้ง",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720911",
            "subdistrictname": "เจดีย์",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720912",
            "subdistrictname": "สระพังลาน",
            "zipcode": "72220"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7209",
            "districtname": "อู่ทอง",
            "subdistrictcode": "720913",
            "subdistrictname": "กระจัน",
            "zipcode": "72160"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721001",
            "subdistrictname": "หนองหญ้าไซ",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721002",
            "subdistrictname": "หนองราชวัตร",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721003",
            "subdistrictname": "หนองโพธิ์",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721004",
            "subdistrictname": "แจงงาม",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721005",
            "subdistrictname": "หนองขาม",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "สุพรรณบุรี",
            "districtcode": "7210",
            "districtname": "หนองหญ้าไซ",
            "subdistrictcode": "721006",
            "subdistrictname": "ทัพหลวง",
            "zipcode": "72240"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730101",
            "subdistrictname": "พระปฐมเจดีย์",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730102",
            "subdistrictname": "บางแขม",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730103",
            "subdistrictname": "พระประโทน",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730104",
            "subdistrictname": "ธรรมศาลา",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730105",
            "subdistrictname": "ตาก้อง",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730106",
            "subdistrictname": "มาบแค",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730107",
            "subdistrictname": "สนามจันทร์",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730108",
            "subdistrictname": "ดอนยายหอม",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730109",
            "subdistrictname": "ถนนขาด",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730110",
            "subdistrictname": "บ่อพลับ",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730111",
            "subdistrictname": "นครปฐม",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730112",
            "subdistrictname": "วังตะกู",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730113",
            "subdistrictname": "หนองปากโลง",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730114",
            "subdistrictname": "สามควายเผือก",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730115",
            "subdistrictname": "ทุ่งน้อย",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730116",
            "subdistrictname": "หนองดินแดง",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730117",
            "subdistrictname": "วังเย็น",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730118",
            "subdistrictname": "โพรงมะเดื่อ",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730119",
            "subdistrictname": "ลำพยา",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730120",
            "subdistrictname": "สระกะเทียม",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730121",
            "subdistrictname": "สวนป่าน",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730122",
            "subdistrictname": "ห้วยจรเข้",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730123",
            "subdistrictname": "ทัพหลวง",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730124",
            "subdistrictname": "หนองงูเหลือม",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7301",
            "districtname": "เมืองนครปฐม",
            "subdistrictcode": "730125",
            "subdistrictname": "บ้านยาง",
            "zipcode": "73000"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730201",
            "subdistrictname": "ทุ่งกระพังโหม",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730202",
            "subdistrictname": "กระตีบ",
            "zipcode": "73180"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730203",
            "subdistrictname": "ทุ่งลูกนก",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730204",
            "subdistrictname": "ห้วยขวาง",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730205",
            "subdistrictname": "ทุ่งขวาง",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730206",
            "subdistrictname": "สระสี่มุม",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730207",
            "subdistrictname": "ทุ่งบัว",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730208",
            "subdistrictname": "ดอนข่อย",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730209",
            "subdistrictname": "สระพัฒนา",
            "zipcode": "73180"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730210",
            "subdistrictname": "ห้วยหมอนทอง",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730211",
            "subdistrictname": "ห้วยม่วง",
            "zipcode": "73180"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730212",
            "subdistrictname": "กำแพงแสน",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730213",
            "subdistrictname": "รางพิกุล",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730214",
            "subdistrictname": "หนองกระทุ่ม",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7302",
            "districtname": "กำแพงแสน",
            "subdistrictcode": "730215",
            "subdistrictname": "วังน้ำเขียว",
            "zipcode": "73140"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730301",
            "subdistrictname": "นครชัยศรี",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730302",
            "subdistrictname": "บางกระเบา",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730303",
            "subdistrictname": "วัดแค",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730304",
            "subdistrictname": "ท่าตำหนัก",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730305",
            "subdistrictname": "บางแก้ว",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730306",
            "subdistrictname": "ท่ากระชับ",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730307",
            "subdistrictname": "ขุนแก้ว",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730308",
            "subdistrictname": "ท่าพระยา",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730309",
            "subdistrictname": "พะเนียด",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730310",
            "subdistrictname": "บางระกำ",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730311",
            "subdistrictname": "โคกพระเจดีย์",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730312",
            "subdistrictname": "ศรีษะทอง",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730313",
            "subdistrictname": "แหลมบัว",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730314",
            "subdistrictname": "ศรีมหาโพธิ์",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730315",
            "subdistrictname": "สัมปทวน",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730316",
            "subdistrictname": "วัดสำโรง",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730317",
            "subdistrictname": "ดอนแฝก",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730318",
            "subdistrictname": "ห้วยพลู",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730319",
            "subdistrictname": "วัดละมุด",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730320",
            "subdistrictname": "บางพระ",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730321",
            "subdistrictname": "บางแก้วฟ้า",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730322",
            "subdistrictname": "ลานตากฟ้า",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730323",
            "subdistrictname": "งิ้วราย",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7303",
            "districtname": "นครชัยศรี",
            "subdistrictcode": "730324",
            "subdistrictname": "ไทยาวาส",
            "zipcode": "73120"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730401",
            "subdistrictname": "สามง่าม",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730402",
            "subdistrictname": "ห้วยพระ",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730403",
            "subdistrictname": "ลำเหย",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730404",
            "subdistrictname": "ดอนพุทรา",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730405",
            "subdistrictname": "บ้านหลวง",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730406",
            "subdistrictname": "ดอนรวก",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730407",
            "subdistrictname": "ห้วยด้วน",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7304",
            "districtname": "ดอนตูม",
            "subdistrictcode": "730408",
            "subdistrictname": "ลำลูกบัว",
            "zipcode": "73150"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730501",
            "subdistrictname": "บางเลน",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730502",
            "subdistrictname": "บางปลา",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730503",
            "subdistrictname": "บางหลวง",
            "zipcode": "73190"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730504",
            "subdistrictname": "บางภาษี",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730505",
            "subdistrictname": "บางระกำ",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730506",
            "subdistrictname": "บางไทรป่า",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730507",
            "subdistrictname": "หินมูล",
            "zipcode": "73190"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730508",
            "subdistrictname": "ไทรงาม",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730509",
            "subdistrictname": "ดอนตูม",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730510",
            "subdistrictname": "นิลเพชร",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730511",
            "subdistrictname": "บัวปากท่า",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730512",
            "subdistrictname": "คลองนกกระทุง",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730513",
            "subdistrictname": "นราภิรมย์",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730514",
            "subdistrictname": "ลำพญา",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7305",
            "districtname": "บางเลน",
            "subdistrictcode": "730515",
            "subdistrictname": "ไผ่หูช้าง",
            "zipcode": "73130"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730601",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730602",
            "subdistrictname": "ทรงคนอง",
            "zipcode": "73210"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730603",
            "subdistrictname": "หอมเกร็ด",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730604",
            "subdistrictname": "บางกระทึก",
            "zipcode": "73210"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730605",
            "subdistrictname": "บางเตย",
            "zipcode": "73210"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730606",
            "subdistrictname": "สามพราน",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730607",
            "subdistrictname": "บางช้าง",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730608",
            "subdistrictname": "ไร่ขิง",
            "zipcode": "73210"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730609",
            "subdistrictname": "ท่าตลาด",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730610",
            "subdistrictname": "กระทุ่มล้ม",
            "zipcode": "73220"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730611",
            "subdistrictname": "คลองใหม่",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730612",
            "subdistrictname": "ตลาดจินดา",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730613",
            "subdistrictname": "คลองจินดา",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730614",
            "subdistrictname": "ยายชา",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730615",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "73110"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7306",
            "districtname": "สามพราน",
            "subdistrictcode": "730616",
            "subdistrictname": "อ้อมใหญ่",
            "zipcode": "73160"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7307",
            "districtname": "พุทธมณฑล",
            "subdistrictcode": "730701",
            "subdistrictname": "ศาลายา",
            "zipcode": "73170"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7307",
            "districtname": "พุทธมณฑล",
            "subdistrictcode": "730702",
            "subdistrictname": "คลองโยง",
            "zipcode": "73170"
        },
        {
            "isActive": true,
            "provincecode": "นครปฐม",
            "districtcode": "7307",
            "districtname": "พุทธมณฑล",
            "subdistrictcode": "730703",
            "subdistrictname": "มหาสวัสดิ์",
            "zipcode": "73170"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740101",
            "subdistrictname": "มหาชัย",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740102",
            "subdistrictname": "ท่าฉลอม",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740103",
            "subdistrictname": "โกรกกราก",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740104",
            "subdistrictname": "บ้านบ่อ",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740105",
            "subdistrictname": "บางโทรัด",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740106",
            "subdistrictname": "กาหลง",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740107",
            "subdistrictname": "นาโคก",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740108",
            "subdistrictname": "ท่าจีน",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740109",
            "subdistrictname": "นาดี",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740110",
            "subdistrictname": "ท่าทราย",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740111",
            "subdistrictname": "คอกกระบือ",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740112",
            "subdistrictname": "บางน้ำจืด",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740113",
            "subdistrictname": "พันท้ายนรสิงห์",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740114",
            "subdistrictname": "โคกขาม",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740115",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740116",
            "subdistrictname": "บางกระเจ้า",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740117",
            "subdistrictname": "บางหญ้าแพรก",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7401",
            "districtname": "เมืองสมุทรสาคร",
            "subdistrictcode": "740118",
            "subdistrictname": "ชัยมงคล",
            "zipcode": "74000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740201",
            "subdistrictname": "ตลาดกระทุ่มแบน",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740202",
            "subdistrictname": "อ้อมน้อย",
            "zipcode": "74130"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740203",
            "subdistrictname": "ท่าไม้",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740204",
            "subdistrictname": "สวนหลวง",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740205",
            "subdistrictname": "บางยาง",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740206",
            "subdistrictname": "คลองมะเดื่อ",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740207",
            "subdistrictname": "หนองนกไข่",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740208",
            "subdistrictname": "ดอนไก่ดี",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740209",
            "subdistrictname": "แคราย",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7402",
            "districtname": "กระทุ่มแบน",
            "subdistrictcode": "740210",
            "subdistrictname": "ท่าเสา",
            "zipcode": "74110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740301",
            "subdistrictname": "บ้านแพ้ว",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740302",
            "subdistrictname": "หลักสาม",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740303",
            "subdistrictname": "ยกกระบัตร",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740304",
            "subdistrictname": "โรงเข้",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740305",
            "subdistrictname": "หนองสองห้อง",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740306",
            "subdistrictname": "หนองบัว",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740307",
            "subdistrictname": "หลักสอง",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740308",
            "subdistrictname": "เจ็ดริ้ว",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740309",
            "subdistrictname": "คลองตัน",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740310",
            "subdistrictname": "อำแพง",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740311",
            "subdistrictname": "สวนส้ม",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสาคร",
            "districtcode": "7403",
            "districtname": "บ้านแพ้ว",
            "subdistrictcode": "740312",
            "subdistrictname": "เกษตรพัฒนา",
            "zipcode": "74120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750101",
            "subdistrictname": "แม่กลอง",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750102",
            "subdistrictname": "บางขันแตก",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750103",
            "subdistrictname": "ลาดใหญ่",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750104",
            "subdistrictname": "บ้านปรก",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750105",
            "subdistrictname": "บางแก้ว",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750106",
            "subdistrictname": "ท้ายหาด",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750107",
            "subdistrictname": "แหลมใหญ่",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750108",
            "subdistrictname": "คลองเขิน",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750109",
            "subdistrictname": "คลองโคน",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750110",
            "subdistrictname": "นางตะเคียน",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7501",
            "districtname": "เมืองสมุทรสงคราม",
            "subdistrictcode": "750111",
            "subdistrictname": "บางจะเกร็ง",
            "zipcode": "75000"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750201",
            "subdistrictname": "กระดังงา",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750202",
            "subdistrictname": "บางสะแก",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750203",
            "subdistrictname": "บางยี่รงค์",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750204",
            "subdistrictname": "โรงหีบ",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750205",
            "subdistrictname": "บางคนที",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750206",
            "subdistrictname": "ดอนมะโนรา",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750207",
            "subdistrictname": "บางพรม",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750208",
            "subdistrictname": "บางกุ้ง",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750209",
            "subdistrictname": "จอมปลวก",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750210",
            "subdistrictname": "บางนกแขวก",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750211",
            "subdistrictname": "ยายแพง",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750212",
            "subdistrictname": "บางกระบือ",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7502",
            "districtname": "บางคนที",
            "subdistrictcode": "750213",
            "subdistrictname": "บ้านปราโมทย์",
            "zipcode": "75120"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750301",
            "subdistrictname": "อัมพวา",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750302",
            "subdistrictname": "สวนหลวง",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750303",
            "subdistrictname": "ท่าคา",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750304",
            "subdistrictname": "วัดประดู่",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750305",
            "subdistrictname": "เหมืองใหม่",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750306",
            "subdistrictname": "บางช้าง",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750307",
            "subdistrictname": "แควอ้อม",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750308",
            "subdistrictname": "ปลายโพงพาง",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750309",
            "subdistrictname": "บางแค",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750310",
            "subdistrictname": "แพรกหนามแดง",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750311",
            "subdistrictname": "ยี่สาร",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "สมุทรสงคราม",
            "districtcode": "7503",
            "districtname": "อัมพวา",
            "subdistrictcode": "750312",
            "subdistrictname": "บางนางลี่",
            "zipcode": "75110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760101",
            "subdistrictname": "ท่าราบ",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760102",
            "subdistrictname": "คลองกระแชง",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760103",
            "subdistrictname": "บางจาน",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760104",
            "subdistrictname": "นาพันสาม",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760105",
            "subdistrictname": "ธงชัย",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760106",
            "subdistrictname": "บ้านกุ่ม",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760107",
            "subdistrictname": "หนองโสน",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760108",
            "subdistrictname": "ไร่ส้ม",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760109",
            "subdistrictname": "เวียงคอย",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760110",
            "subdistrictname": "บางจาก",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760111",
            "subdistrictname": "บ้านหม้อ",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760112",
            "subdistrictname": "ต้นมะม่วง",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760113",
            "subdistrictname": "ช่องสะแก",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760114",
            "subdistrictname": "นาวุ้ง",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760115",
            "subdistrictname": "สำมะโรง",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760116",
            "subdistrictname": "โพพระ",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760117",
            "subdistrictname": "หาดเจ้าสำราญ",
            "zipcode": "76100"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760118",
            "subdistrictname": "หัวสะพาน",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760119",
            "subdistrictname": "ต้นมะพร้าว",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760120",
            "subdistrictname": "วังตะโก",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760121",
            "subdistrictname": "โพไร่หวาน",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760122",
            "subdistrictname": "ดอนยาง",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760123",
            "subdistrictname": "หนองขนาน",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7601",
            "districtname": "เมืองเพชรบุรี",
            "subdistrictcode": "760124",
            "subdistrictname": "หนองพลับ",
            "zipcode": "76000"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760201",
            "subdistrictname": "เขาย้อย",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุร���",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760202",
            "subdistrictname": "สระพัง",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760203",
            "subdistrictname": "บางเค็ม",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760204",
            "subdistrictname": "ทับคาง",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760205",
            "subdistrictname": "หนองปลาไหล",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760206",
            "subdistrictname": "หนองปรง",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760207",
            "subdistrictname": "หนองชุมพล",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760208",
            "subdistrictname": "ห้วยโรง",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760209",
            "subdistrictname": "ห้วยท่าช้าง",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7602",
            "districtname": "เขาย้อย",
            "subdistrictcode": "760210",
            "subdistrictname": "หนองชุมพลเหนือ",
            "zipcode": "76140"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7603",
            "districtname": "หนองหญ้าปล้อง",
            "subdistrictcode": "760301",
            "subdistrictname": "หนองหญ้าปล้อง",
            "zipcode": "76160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7603",
            "districtname": "หนองหญ้าปล้อง",
            "subdistrictcode": "760302",
            "subdistrictname": "ยางน้ำกลัดเหนือ",
            "zipcode": "76160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7603",
            "districtname": "หนองหญ้าปล้อง",
            "subdistrictcode": "760303",
            "subdistrictname": "ยางน้ำกลัดใต้",
            "zipcode": "76160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7603",
            "districtname": "หนองหญ้าปล้อง",
            "subdistrictcode": "760304",
            "subdistrictname": "ท่าตะคร้อ",
            "zipcode": "76160"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760401",
            "subdistrictname": "ชะอำ",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760402",
            "subdistrictname": "บางเก่า",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760403",
            "subdistrictname": "นายาง",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760404",
            "subdistrictname": "เขาใหญ่",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760405",
            "subdistrictname": "หนองศาลา",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760406",
            "subdistrictname": "ห้วยทรายเหนือ",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760407",
            "subdistrictname": "ไร่ใหม่พัฒนา",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760408",
            "subdistrictname": "สามพระยา",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7604",
            "districtname": "ชะอำ",
            "subdistrictcode": "760409",
            "subdistrictname": "ดอนขุนห้วย",
            "zipcode": "76120"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760501",
            "subdistrictname": "ท่ายาง",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760502",
            "subdistrictname": "ท่าคอย",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760503",
            "subdistrictname": "ยางหย่อง",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760504",
            "subdistrictname": "หนองจอก",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760505",
            "subdistrictname": "มาบปลาเค้า",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760506",
            "subdistrictname": "ท่าไม้รวก",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760507",
            "subdistrictname": "วังไคร้",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760511",
            "subdistrictname": "กลัดหลวง",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760512",
            "subdistrictname": "ปึกเตียน",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760513",
            "subdistrictname": "เขากระปุก",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760514",
            "subdistrictname": "ท่าแลง",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7605",
            "districtname": "ท่ายาง",
            "subdistrictcode": "760515",
            "subdistrictname": "บ้านในดง",
            "zipcode": "76130"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760601",
            "subdistrictname": "บ้านลาด",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760602",
            "subdistrictname": "บ้านหาด",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760603",
            "subdistrictname": "บ้านทาน",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760604",
            "subdistrictname": "ตำหรุ",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760605",
            "subdistrictname": "สมอพลือ",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760606",
            "subdistrictname": "ไร่มะขาม",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760607",
            "subdistrictname": "ท่าเสน",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760608",
            "subdistrictname": "หนองกระเจ็ด",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760609",
            "subdistrictname": "หนองกะปุ",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760610",
            "subdistrictname": "ลาดโพธิ์",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760611",
            "subdistrictname": "สะพานไกร",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760612",
            "subdistrictname": "ไร่โคก",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760613",
            "subdistrictname": "โรงเข้",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760614",
            "subdistrictname": "ไร่สะท้อน",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760615",
            "subdistrictname": "ห้วยข้อง",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760616",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760617",
            "subdistrictname": "ถ้ำรงค์",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7606",
            "districtname": "บ้านลาด",
            "subdistrictcode": "760618",
            "subdistrictname": "ห้วยลึก",
            "zipcode": "76150"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760701",
            "subdistrictname": "บ้านแหลม",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760702",
            "subdistrictname": "บางขุนไทร",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760703",
            "subdistrictname": "ปากทะเล",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760704",
            "subdistrictname": "บางแก้ว",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760705",
            "subdistrictname": "แหลมผักเบี้ย",
            "zipcode": "76100"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760706",
            "subdistrictname": "บางตะบูน",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760707",
            "subdistrictname": "บางตะบูนออก",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760708",
            "subdistrictname": "บางครก",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760709",
            "subdistrictname": "ท่าแร้ง",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7607",
            "districtname": "บ้านแหลม",
            "subdistrictcode": "760710",
            "subdistrictname": "ท่าแร้งออก",
            "zipcode": "76110"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760801",
            "subdistrictname": "แก่งกระจาน",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760802",
            "subdistrictname": "สองพี่น้อง",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760803",
            "subdistrictname": "วังจันทร์",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760804",
            "subdistrictname": "ป่าเด็ง",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760805",
            "subdistrictname": "พุสวรรค์",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "เพชรบุรี",
            "districtcode": "7608",
            "districtname": "แก่งกระจาน",
            "subdistrictcode": "760806",
            "subdistrictname": "ห้วยแม่เพรียง",
            "zipcode": "76170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770101",
            "subdistrictname": "ประจวบคีรีขันธ์",
            "zipcode": "77000"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770102",
            "subdistrictname": "เกาะหลัก",
            "zipcode": "77000"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770103",
            "subdistrictname": "คลองวาฬ",
            "zipcode": "77000"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770104",
            "subdistrictname": "ห้วยทราย",
            "zipcode": "77000"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770105",
            "subdistrictname": "อ่าวน้อย",
            "zipcode": "77000"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7701",
            "districtname": "เมืองประจวบคีรีขันธ์",
            "subdistrictcode": "770106",
            "subdistrictname": "บ่อนอก",
            "zipcode": "77210"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770201",
            "subdistrictname": "กุยบุรี",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770202",
            "subdistrictname": "กุยเหนือ",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770203",
            "subdistrictname": "เขาแดง",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770204",
            "subdistrictname": "ดอนยายหนู",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770206",
            "subdistrictname": "สามกระทาย",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7702",
            "districtname": "กุยบุรี",
            "subdistrictcode": "770207",
            "subdistrictname": "หาดขาม",
            "zipcode": "77150"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770301",
            "subdistrictname": "ทับสะแก",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770302",
            "subdistrictname": "อ่างทอง",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770303",
            "subdistrictname": "นาหูกวาง",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770304",
            "subdistrictname": "เขาล้าน",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770305",
            "subdistrictname": "ห้วยยาง",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7703",
            "districtname": "ทับสะแก",
            "subdistrictcode": "770306",
            "subdistrictname": "แสงอรุณ",
            "zipcode": "77130"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770401",
            "subdistrictname": "กำเนิดนพคุณ",
            "zipcode": "77140"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770402",
            "subdistrictname": "พงศ์ประศาสน์",
            "zipcode": "77140"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770403",
            "subdistrictname": "ร่อนทอง",
            "zipcode": "77230"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770404",
            "subdistrictname": "ธงชัย",
            "zipcode": "77190"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770405",
            "subdistrictname": "ชัยเกษม",
            "zipcode": "77190"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770406",
            "subdistrictname": "ทองมงคล",
            "zipcode": "77230"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7704",
            "districtname": "บางสะพาน",
            "subdistrictcode": "770407",
            "subdistrictname": "แม่รำพึง",
            "zipcode": "77140"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย",
            "subdistrictcode": "770501",
            "subdistrictname": "ปากแพรก",
            "zipcode": "77170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย",
            "subdistrictcode": "770502",
            "subdistrictname": "บางสะพาน",
            "zipcode": "77170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย",
            "subdistrictcode": "770503",
            "subdistrictname": "ทรายทอง",
            "zipcode": "77170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย",
            "subdistrictcode": "770504",
            "subdistrictname": "ช้างแรก",
            "zipcode": "77170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7705",
            "districtname": "บางสะพานน้อย",
            "subdistrictcode": "770505",
            "subdistrictname": "ไชยราช",
            "zipcode": "77170"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770601",
            "subdistrictname": "ปราณบุรี",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770602",
            "subdistrictname": "เขาน้อย",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770604",
            "subdistrictname": "ปากน้ำปราณ",
            "zipcode": "77220"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770607",
            "subdistrictname": "หนองตาแต้ม",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770608",
            "subdistrictname": "วังก์พง",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7706",
            "districtname": "ปราณบุรี",
            "subdistrictcode": "770609",
            "subdistrictname": "เขาจ้าว",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770701",
            "subdistrictname": "หัวหิน",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770702",
            "subdistrictname": "หนองแก",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770703",
            "subdistrictname": "หินเหล็กไฟ",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770704",
            "subdistrictname": "หนองพลับ",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770705",
            "subdistrictname": "ทับใต้",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770706",
            "subdistrictname": "ห้วยสัตว์ใหญ่",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7707",
            "districtname": "หัวหิน",
            "subdistrictcode": "770707",
            "subdistrictname": "บึงนคร",
            "zipcode": "77110"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด",
            "subdistrictcode": "770801",
            "subdistrictname": "สามร้อยยอด",
            "zipcode": "77120"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด",
            "subdistrictcode": "770802",
            "subdistrictname": "ศิลาลอย",
            "zipcode": "77180"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด",
            "subdistrictcode": "770803",
            "subdistrictname": "ไร่เก่า",
            "zipcode": "77180"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด",
            "subdistrictcode": "770804",
            "subdistrictname": "ศาลาลัย",
            "zipcode": "77180"
        },
        {
            "isActive": true,
            "provincecode": "ประจวบคีรีขันธ์",
            "districtcode": "7708",
            "districtname": "สามร้อยยอด",
            "subdistrictcode": "770805",
            "subdistrictname": "ไร่ใหม่",
            "zipcode": "77180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800101",
            "subdistrictname": "ในเมือง",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800102",
            "subdistrictname": "ท่าวัง",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800103",
            "subdistrictname": "คลัง",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800106",
            "subdistrictname": "ท่าไร่",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800107",
            "subdistrictname": "ปากนคร",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800108",
            "subdistrictname": "นาทราย",
            "zipcode": "80280"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800112",
            "subdistrictname": "กำแพงเซา",
            "zipcode": "80280"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800113",
            "subdistrictname": "ไชยมนตรี",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800114",
            "subdistrictname": "มะม่วงสองต้น",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800115",
            "subdistrictname": "นาเคียน",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800116",
            "subdistrictname": "ท่างิ้ว",
            "zipcode": "80280"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800118",
            "subdistrictname": "โพธิ์เสด็จ",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800119",
            "subdistrictname": "บางจาก",
            "zipcode": "80330"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800120",
            "subdistrictname": "ปากพูน",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800121",
            "subdistrictname": "ท่าซัก",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8001",
            "districtname": "เมืองนครศรีธรรมราช",
            "subdistrictcode": "800122",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "80290"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8002",
            "districtname": "พรหมคีรี",
            "subdistrictcode": "800201",
            "subdistrictname": "พรหมโลก",
            "zipcode": "80320"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8002",
            "districtname": "พรหมคีรี",
            "subdistrictcode": "800202",
            "subdistrictname": "บ้านเกาะ",
            "zipcode": "80320"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8002",
            "districtname": "พรหมคีรี",
            "subdistrictcode": "800203",
            "subdistrictname": "อินคีรี",
            "zipcode": "80320"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8002",
            "districtname": "พรหมคีรี",
            "subdistrictcode": "800204",
            "subdistrictname": "ทอนหงส์",
            "zipcode": "80320"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8002",
            "districtname": "พรหมคีรี",
            "subdistrictcode": "800205",
            "subdistrictname": "นาเรียง",
            "zipcode": "80320"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8003",
            "districtname": "ลานสกา",
            "subdistrictcode": "800301",
            "subdistrictname": "เขาแก้ว",
            "zipcode": "80230"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8003",
            "districtname": "ลานสกา",
            "subdistrictcode": "800302",
            "subdistrictname": "ลานสกา",
            "zipcode": "80230"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8003",
            "districtname": "ลานสกา",
            "subdistrictcode": "800303",
            "subdistrictname": "ท่าดี",
            "zipcode": "80230"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8003",
            "districtname": "ลานสกา",
            "subdistrictcode": "800304",
            "subdistrictname": "กำโลน",
            "zipcode": "80230"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8003",
            "districtname": "ลานสกา",
            "subdistrictcode": "800305",
            "subdistrictname": "ขุนทะเล",
            "zipcode": "80230"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800401",
            "subdistrictname": "ฉวาง",
            "zipcode": "80150"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800403",
            "subdistrictname": "ละอาย",
            "zipcode": "80250"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800404",
            "subdistrictname": "นาแว",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800405",
            "subdistrictname": "ไม้เรียง",
            "zipcode": "80150"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800406",
            "subdistrictname": "กะเปียด",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800407",
            "subdistrictname": "นากะชะ",
            "zipcode": "80150"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800409",
            "subdistrictname": "ห้วยปริก",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800410",
            "subdistrictname": "ไสหร้า",
            "zipcode": "80150"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800415",
            "subdistrictname": "นาเขลียง",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8004",
            "districtname": "ฉวาง",
            "subdistrictcode": "800416",
            "subdistrictname": "จันดี",
            "zipcode": "80250"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8005",
            "districtname": "พิปูน",
            "subdistrictcode": "800501",
            "subdistrictname": "พิปูน",
            "zipcode": "80270"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8005",
            "districtname": "พิปูน",
            "subdistrictcode": "800502",
            "subdistrictname": "กะทูน",
            "zipcode": "80270"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8005",
            "districtname": "พิปูน",
            "subdistrictcode": "800503",
            "subdistrictname": "เขาพระ",
            "zipcode": "80270"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8005",
            "districtname": "พิปูน",
            "subdistrictcode": "800504",
            "subdistrictname": "ยางค้อม",
            "zipcode": "80270"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8005",
            "districtname": "พิปูน",
            "subdistrictcode": "800505",
            "subdistrictname": "ควนกลาง",
            "zipcode": "80270"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800601",
            "subdistrictname": "เชียรใหญ่",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800603",
            "subdistrictname": "ท่าขนาน",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800604",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800605",
            "subdistrictname": "บ้านเนิน",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800606",
            "subdistrictname": "ไสหมาก",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800607",
            "subdistrictname": "ท้องลำเจียก",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800610",
            "subdistrictname": "เสือหึง",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800611",
            "subdistrictname": "การะเกด",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800612",
            "subdistrictname": "เขาพระบาท",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8006",
            "districtname": "เชียรใหญ่",
            "subdistrictcode": "800613",
            "subdistrictname": "แม่เจ้าอยู่หัว",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800701",
            "subdistrictname": "ชะอวด",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800702",
            "subdistrictname": "ท่าเสม็ด",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800703",
            "subdistrictname": "ท่าประจะ",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800704",
            "subdistrictname": "เคร็ง",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800705",
            "subdistrictname": "วังอ่าง",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800706",
            "subdistrictname": "บ้านตูล",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800707",
            "subdistrictname": "ขอนหาด",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800708",
            "subdistrictname": "เกาะขันธ์",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800709",
            "subdistrictname": "ควนหนองหงษ์",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800710",
            "subdistrictname": "เขาพระทอง",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8007",
            "districtname": "ชะอวด",
            "subdistrictcode": "800711",
            "subdistrictname": "นางหลง",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800801",
            "subdistrictname": "ท่าศาลา",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800802",
            "subdistrictname": "กลาย",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800803",
            "subdistrictname": "ท่าขึ้น",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800804",
            "subdistrictname": "หัวตะพาน",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800806",
            "subdistrictname": "สระแก้ว",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800807",
            "subdistrictname": "โมคลาน",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800809",
            "subdistrictname": "ไทยบุรี",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800810",
            "subdistrictname": "ดอนตะโก",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800811",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8008",
            "districtname": "ท่าศาลา",
            "subdistrictcode": "800813",
            "subdistrictname": "โพธิ์ทอง",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800901",
            "subdistrictname": "ปากแพรก",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800902",
            "subdistrictname": "ชะมาย",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800903",
            "subdistrictname": "หนองหงส์",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800904",
            "subdistrictname": "ควนกรด",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศร��ธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800905",
            "subdistrictname": "นาไม้ไผ่",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800906",
            "subdistrictname": "นาหลวงเสน",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800907",
            "subdistrictname": "เขาโร",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800908",
            "subdistrictname": "กะปาง",
            "zipcode": "80310"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800909",
            "subdistrictname": "ที่วัง",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800910",
            "subdistrictname": "น้ำตก",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800911",
            "subdistrictname": "ถ้ำใหญ่",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800912",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8009",
            "districtname": "ทุ่งสง",
            "subdistrictcode": "800913",
            "subdistrictname": "เขาขาว",
            "zipcode": "80110"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8010",
            "districtname": "นาบอน",
            "subdistrictcode": "801001",
            "subdistrictname": "นาบอน",
            "zipcode": "80220"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8010",
            "districtname": "นาบอน",
            "subdistrictcode": "801002",
            "subdistrictname": "ทุ่งสง",
            "zipcode": "80220"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8010",
            "districtname": "นาบอน",
            "subdistrictcode": "801003",
            "subdistrictname": "แก้วแสน",
            "zipcode": "80220"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801101",
            "subdistrictname": "ท่ายาง",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801102",
            "subdistrictname": "ทุ่งสัง",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801103",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801104",
            "subdistrictname": "กุแหระ",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801105",
            "subdistrictname": "ปริก",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801106",
            "subdistrictname": "บางรูป",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8011",
            "districtname": "ทุ่งใหญ่",
            "subdistrictcode": "801107",
            "subdistrictname": "กรุงหยัน",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801201",
            "subdistrictname": "ปากพนัง",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801202",
            "subdistrictname": "คลองน้อย",
            "zipcode": "80330"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801203",
            "subdistrictname": "ป่าระกำ",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801204",
            "subdistrictname": "ชะเมา",
            "zipcode": "80330"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801205",
            "subdistrictname": "คลองกระบือ",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801206",
            "subdistrictname": "เกาะทวด",
            "zipcode": "80330"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801207",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801208",
            "subdistrictname": "หูล่อง",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801209",
            "subdistrictname": "แหลมตะลุมพุก",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801210",
            "subdistrictname": "ปากพนังฝั่งตะวันตก",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801211",
            "subdistrictname": "บางศาลา",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801212",
            "subdistrictname": "บางพระ",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801213",
            "subdistrictname": "บางตะพง",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801214",
            "subdistrictname": "ปากพนังฝั่งตะวันออก",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801215",
            "subdistrictname": "บ้านเพิง",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801216",
            "subdistrictname": "ท่าพยา",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801217",
            "subdistrictname": "ปากแพรก",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8012",
            "districtname": "ปากพนัง",
            "subdistrictcode": "801218",
            "subdistrictname": "ขนาบนาก",
            "zipcode": "80140"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801301",
            "subdistrictname": "ร่อนพิบูลย์",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801302",
            "subdistrictname": "หินตก",
            "zipcode": "80350"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801303",
            "subdistrictname": "เสาธง",
            "zipcode": "80350"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801304",
            "subdistrictname": "ควนเกย",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801305",
            "subdistrictname": "ควนพัง",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8013",
            "districtname": "ร่อนพิบูลย์",
            "subdistrictcode": "801306",
            "subdistrictname": "ควนชุม",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801401",
            "subdistrictname": "สิชล",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801402",
            "subdistrictname": "ทุ่งปรัง",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801403",
            "subdistrictname": "ฉลอง",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801404",
            "subdistrictname": "เสาเภา",
            "zipcode": "80340"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801405",
            "subdistrictname": "เปลี่ยน",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801406",
            "subdistrictname": "สี่ขีด",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801407",
            "subdistrictname": "เทพราช",
            "zipcode": "80340"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801408",
            "subdistrictname": "เขาน้อย",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8014",
            "districtname": "สิชล",
            "subdistrictcode": "801409",
            "subdistrictname": "ทุ่งใส",
            "zipcode": "80120"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8015",
            "districtname": "ขนอม",
            "subdistrictcode": "801501",
            "subdistrictname": "ขนอม",
            "zipcode": "80210"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8015",
            "districtname": "ขนอม",
            "subdistrictcode": "801502",
            "subdistrictname": "ควนทอง",
            "zipcode": "80210"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8015",
            "districtname": "ขนอม",
            "subdistrictcode": "801503",
            "subdistrictname": "ท้องเนียน",
            "zipcode": "80210"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801601",
            "subdistrictname": "หัวไทร",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801602",
            "subdistrictname": "หน้าสตน",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801603",
            "subdistrictname": "ทรายขาว",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801604",
            "subdistrictname": "แหลม",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801605",
            "subdistrictname": "เขาพังไกร",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801606",
            "subdistrictname": "บ้านราม",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801607",
            "subdistrictname": "บางนบ",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801608",
            "subdistrictname": "ท่าซอม",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801609",
            "subdistrictname": "ควนชะลิก",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801610",
            "subdistrictname": "รามแก้ว",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8016",
            "districtname": "หัวไทร",
            "subdistrictcode": "801611",
            "subdistrictname": "เกาะเพชร",
            "zipcode": "80170"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8017",
            "districtname": "บางขัน",
            "subdistrictcode": "801701",
            "subdistrictname": "บางขัน",
            "zipcode": "80360"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8017",
            "districtname": "บางขัน",
            "subdistrictcode": "801702",
            "subdistrictname": "บ้านลำนาว",
            "zipcode": "80360"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8017",
            "districtname": "บางขัน",
            "subdistrictcode": "801703",
            "subdistrictname": "วังหิน",
            "zipcode": "80360"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8017",
            "districtname": "บางขัน",
            "subdistrictcode": "801704",
            "subdistrictname": "บ้านนิคม",
            "zipcode": "80360"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8018",
            "districtname": "ถ้ำพรรณรา",
            "subdistrictcode": "801801",
            "subdistrictname": "ถ้ำพรรณรา",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8018",
            "districtname": "ถ้ำพรรณรา",
            "subdistrictcode": "801802",
            "subdistrictname": "คลองเส",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8018",
            "districtname": "ถ้ำพรรณรา",
            "subdistrictcode": "801803",
            "subdistrictname": "ดุสิต",
            "zipcode": "80260"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801901",
            "subdistrictname": "บ้านควนมุด",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801902",
            "subdistrictname": "บ้านชะอวด",
            "zipcode": "80180"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801903",
            "subdistrictname": "ควนหนองคว้า",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801904",
            "subdistrictname": "ทุ่งโพธิ์",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801905",
            "subdistrictname": "นาหมอบุญ",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8019",
            "districtname": "จุฬาภรณ์",
            "subdistrictcode": "801906",
            "subdistrictname": "สามตำบล",
            "zipcode": "80130"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8020",
            "districtname": "พระพรหม",
            "subdistrictcode": "802001",
            "subdistrictname": "นาพรุ",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8020",
            "districtname": "พระพรหม",
            "subdistrictcode": "802002",
            "subdistrictname": "นาสาร",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8020",
            "districtname": "พระพรหม",
            "subdistrictcode": "802003",
            "subdistrictname": "ท้ายสำเภา",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8020",
            "districtname": "พระพรหม",
            "subdistrictcode": "802004",
            "subdistrictname": "ช้างซ้าย",
            "zipcode": "80000"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8021",
            "districtname": "นบพิตำ",
            "subdistrictcode": "802101",
            "subdistrictname": "นบพิตำ",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8021",
            "districtname": "นบพิตำ",
            "subdistrictcode": "802102",
            "subdistrictname": "กรุงชิง",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8021",
            "districtname": "นบพิตำ",
            "subdistrictcode": "802103",
            "subdistrictname": "กะหรอ",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8021",
            "districtname": "นบพิตำ",
            "subdistrictcode": "802104",
            "subdistrictname": "นาเหรง",
            "zipcode": "80160"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8022",
            "districtname": "ช้างกลาง",
            "subdistrictcode": "802201",
            "subdistrictname": "ช้างกลาง",
            "zipcode": "80250"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8022",
            "districtname": "ช้างกลาง",
            "subdistrictcode": "802202",
            "subdistrictname": "หลักช้าง",
            "zipcode": "80250"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8022",
            "districtname": "ช้างกลาง",
            "subdistrictcode": "802203",
            "subdistrictname": "สวนขัน",
            "zipcode": "80250"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8023",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "802301",
            "subdistrictname": "เชียรเขา",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8023",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "802302",
            "subdistrictname": "ดอนตรอ",
            "zipcode": "80290"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8023",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "802303",
            "subdistrictname": "สวนหลวง",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "นครศรีธรรมราช",
            "districtcode": "8023",
            "districtname": "เฉลิมพระเกียรติ",
            "subdistrictcode": "802304",
            "subdistrictname": "ทางพูน",
            "zipcode": "80190"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810101",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810102",
            "subdistrictname": "กระบี่ใหญ่",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810103",
            "subdistrictname": "กระบี่น้อย",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810105",
            "subdistrictname": "เขาคราม",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810106",
            "subdistrictname": "เขาทอง",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810111",
            "subdistrictname": "ทับปริก",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810115",
            "subdistrictname": "ไสไทย",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810116",
            "subdistrictname": "อ่าวนาง",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810117",
            "subdistrictname": "หนองทะเล",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8101",
            "districtname": "เมืองกระบี่",
            "subdistrictcode": "810118",
            "subdistrictname": "คลองประสงค์",
            "zipcode": "81000"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810201",
            "subdistrictname": "เขาพนม",
            "zipcode": "81140"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810202",
            "subdistrictname": "เขาดิน",
            "zipcode": "81140"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810203",
            "subdistrictname": "สินปุน",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810204",
            "subdistrictname": "พรุเตียว",
            "zipcode": "81140"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810205",
            "subdistrictname": "หน้าเขา",
            "zipcode": "81140"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8102",
            "districtname": "เขาพนม",
            "subdistrictcode": "810206",
            "subdistrictname": "โคกหาร",
            "zipcode": "80240"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8103",
            "districtname": "เกาะลันตา",
            "subdistrictcode": "810301",
            "subdistrictname": "เกาะลันตาใหญ่",
            "zipcode": "81150"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8103",
            "districtname": "เกาะลันตา",
            "subdistrictcode": "810302",
            "subdistrictname": "เกาะลันตาน้อย",
            "zipcode": "81150"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8103",
            "districtname": "เกาะลันตา",
            "subdistrictcode": "810303",
            "subdistrictname": "เกาะกลาง",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8103",
            "districtname": "เกาะลันตา",
            "subdistrictcode": "810304",
            "subdistrictname": "คลองยาง",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8103",
            "districtname": "เกาะลันตา",
            "subdistrictcode": "810305",
            "subdistrictname": "ศาลาด่าน",
            "zipcode": "81150"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810401",
            "subdistrictname": "คลองท่อมใต้",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810402",
            "subdistrictname": "คลองท่อมเหนือ",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810403",
            "subdistrictname": "คลองพน",
            "zipcode": "81170"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810404",
            "subdistrictname": "ทรายขาว",
            "zipcode": "81170"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810405",
            "subdistrictname": "ห้วยน้ำขาว",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810406",
            "subdistrictname": "พรุดินนา",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8104",
            "districtname": "คลองท่อม",
            "subdistrictcode": "810407",
            "subdistrictname": "เพหลา",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810501",
            "subdistrictname": "อ่าวลึกใต้",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810502",
            "subdistrictname": "แหลมสัก",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810503",
            "subdistrictname": "นาเหนือ",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810504",
            "subdistrictname": "คลองหิน",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810505",
            "subdistrictname": "อ่าวลึกน้อย",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810506",
            "subdistrictname": "อ่าวลึกเหนือ",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810507",
            "subdistrictname": "เขาใหญ่",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810508",
            "subdistrictname": "คลองยา",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8105",
            "districtname": "อ่าวลึก",
            "subdistrictcode": "810509",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "81110"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8106",
            "districtname": "ปลายพระยา",
            "subdistrictcode": "810601",
            "subdistrictname": "ปลายพระยา",
            "zipcode": "81160"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8106",
            "districtname": "ปลายพระยา",
            "subdistrictcode": "810602",
            "subdistrictname": "เขาเขน",
            "zipcode": "81160"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8106",
            "districtname": "ปลายพระยา",
            "subdistrictcode": "810603",
            "subdistrictname": "เขาต่อ",
            "zipcode": "81160"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8106",
            "districtname": "ปลายพระยา",
            "subdistrictcode": "810604",
            "subdistrictname": "คีรีวง",
            "zipcode": "81160"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8107",
            "districtname": "ลำทับ",
            "subdistrictcode": "810701",
            "subdistrictname": "ลำทับ",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8107",
            "districtname": "ลำทับ",
            "subdistrictcode": "810702",
            "subdistrictname": "ดินอุดม",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8107",
            "districtname": "ลำทับ",
            "subdistrictcode": "810703",
            "subdistrictname": "ทุ่งไทรทอง",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8107",
            "districtname": "ลำทับ",
            "subdistrictcode": "810704",
            "subdistrictname": "ดินแดง",
            "zipcode": "81120"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810801",
            "subdistrictname": "เหนือคลอง",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810802",
            "subdistrictname": "เกาะศรีบอยา",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810803",
            "subdistrictname": "คลองขนาน",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810804",
            "subdistrictname": "คลองเขม้า",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810805",
            "subdistrictname": "โคกยาง",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810806",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810807",
            "subdistrictname": "ปกาสัย",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "กระบี่",
            "districtcode": "8108",
            "districtname": "เหนือคลอง",
            "subdistrictcode": "810808",
            "subdistrictname": "ห้วยยูง",
            "zipcode": "81130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820101",
            "subdistrictname": "ท้ายช้าง",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820102",
            "subdistrictname": "นบปริง",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820103",
            "subdistrictname": "ถ้ำน้ำผุด",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820104",
            "subdistrictname": "บางเตย",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820105",
            "subdistrictname": "ตากแดด",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820106",
            "subdistrictname": "สองแพรก",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820107",
            "subdistrictname": "ทุ่งคาโงก",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820108",
            "subdistrictname": "เกาะปันหยี",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8201",
            "districtname": "เมืองพังงา",
            "subdistrictcode": "820109",
            "subdistrictname": "ป่ากอ",
            "zipcode": "82000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8202",
            "districtname": "เกาะยาว",
            "subdistrictcode": "820201",
            "subdistrictname": "เกาะยาวน้อย",
            "zipcode": "82160"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8202",
            "districtname": "เกาะยาว",
            "subdistrictcode": "820202",
            "subdistrictname": "เกาะยาวใหญ่",
            "zipcode": "82160"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8202",
            "districtname": "เกาะยาว",
            "subdistrictcode": "820203",
            "subdistrictname": "พรุใน",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8203",
            "districtname": "กะปง",
            "subdistrictcode": "820301",
            "subdistrictname": "กะปง",
            "zipcode": "82170"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8203",
            "districtname": "กะปง",
            "subdistrictcode": "820302",
            "subdistrictname": "ท่านา",
            "zipcode": "82170"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8203",
            "districtname": "กะปง",
            "subdistrictcode": "820303",
            "subdistrictname": "เหมาะ",
            "zipcode": "82170"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8203",
            "districtname": "กะปง",
            "subdistrictcode": "820304",
            "subdistrictname": "เหล",
            "zipcode": "82170"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8203",
            "districtname": "กะปง",
            "subdistrictcode": "820305",
            "subdistrictname": "รมณีย์",
            "zipcode": "82170"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820401",
            "subdistrictname": "ถ้ำ",
            "zipcode": "82130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820402",
            "subdistrictname": "กระโสม",
            "zipcode": "82130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820403",
            "subdistrictname": "กะไหล",
            "zipcode": "82130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820404",
            "subdistrictname": "ท่าอยู่",
            "zipcode": "82130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820405",
            "subdistrictname": "หล่อยูง",
            "zipcode": "82140"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820406",
            "subdistrictname": "โคกกลอย",
            "zipcode": "82140"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8204",
            "districtname": "ตะกั่วทุ่ง",
            "subdistrictcode": "820407",
            "subdistrictname": "คลองเคียน",
            "zipcode": "82130"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820501",
            "subdistrictname": "ตะกั่วป่า",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820502",
            "subdistrictname": "บางนายสี",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820503",
            "subdistrictname": "บางไทร",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820504",
            "subdistrictname": "บางม่วง",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820505",
            "subdistrictname": "ตำตัว",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820506",
            "subdistrictname": "โคกเคียน",
            "zipcode": "82110"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820507",
            "subdistrictname": "คึกคัก",
            "zipcode": "82190"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8205",
            "districtname": "ตะกั่วป่า",
            "subdistrictcode": "820508",
            "subdistrictname": "เกาะคอเขา",
            "zipcode": "82190"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8206",
            "districtname": "คุระบุรี",
            "subdistrictcode": "820601",
            "subdistrictname": "คุระ",
            "zipcode": "82150"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8206",
            "districtname": "คุระบุรี",
            "subdistrictcode": "820602",
            "subdistrictname": "บางวัน",
            "zipcode": "82150"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8206",
            "districtname": "คุระบุรี",
            "subdistrictcode": "820603",
            "subdistrictname": "เกาะพระทอง",
            "zipcode": "82150"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8206",
            "districtname": "คุระบุรี",
            "subdistrictcode": "820605",
            "subdistrictname": "แม่นางขาว",
            "zipcode": "82150"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820701",
            "subdistrictname": "ทับปุด",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820702",
            "subdistrictname": "มะรุ่ย",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820703",
            "subdistrictname": "บ่อแสน",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820704",
            "subdistrictname": "ถ้ำทองหลาง",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820705",
            "subdistrictname": "โคกเจริญ",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8207",
            "districtname": "ทับปุด",
            "subdistrictcode": "820706",
            "subdistrictname": "บางเหรียง",
            "zipcode": "82180"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820801",
            "subdistrictname": "ท้ายเหมือง",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820802",
            "subdistrictname": "นาเตย",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820803",
            "subdistrictname": "บางทอง",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820804",
            "subdistrictname": "ทุ่งมะพร้าว",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820805",
            "subdistrictname": "ลำภี",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "พังงา",
            "districtcode": "8208",
            "districtname": "ท้ายเหมือง",
            "subdistrictcode": "820806",
            "subdistrictname": "ลำแก่น",
            "zipcode": "82120"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830101",
            "subdistrictname": "ตลาดใหญ่",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830102",
            "subdistrictname": "ตลาดเหนือ",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830103",
            "subdistrictname": "เกาะแก้ว",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830104",
            "subdistrictname": "รัษฎา",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830105",
            "subdistrictname": "วิชิต",
            "zipcode": "83000"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830106",
            "subdistrictname": "ฉลอง",
            "zipcode": "83130"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830107",
            "subdistrictname": "ราไวย์",
            "zipcode": "83130"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8301",
            "districtname": "เมืองภูเก็ต",
            "subdistrictcode": "830108",
            "subdistrictname": "กะรน",
            "zipcode": "83100"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8302",
            "districtname": "กะทู้",
            "subdistrictcode": "830201",
            "subdistrictname": "กะทู้",
            "zipcode": "83120"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8302",
            "districtname": "กะทู้",
            "subdistrictcode": "830202",
            "subdistrictname": "ป่าตอง",
            "zipcode": "83150"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8302",
            "districtname": "กะทู้",
            "subdistrictcode": "830203",
            "subdistrictname": "กมลา",
            "zipcode": "83150"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830301",
            "subdistrictname": "เทพกระษัตรี",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830302",
            "subdistrictname": "ศรีสุนทร",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830303",
            "subdistrictname": "เชิงทะเล",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830304",
            "subdistrictname": "ป่าคลอก",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830305",
            "subdistrictname": "ไม้ขาว",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "ภูเก็ต",
            "districtcode": "8303",
            "districtname": "ถลาง",
            "subdistrictcode": "830306",
            "subdistrictname": "สาคู",
            "zipcode": "83110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840101",
            "subdistrictname": "ตลาด",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840102",
            "subdistrictname": "มะขามเตี้ย",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840103",
            "subdistrictname": "วัดประดู่",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840104",
            "subdistrictname": "ขุนทะเล",
            "zipcode": "84100"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840105",
            "subdistrictname": "บางใบไม้",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840106",
            "subdistrictname": "บางชนะ",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840107",
            "subdistrictname": "คลองน้อย",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840108",
            "subdistrictname": "บางไทร",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840109",
            "subdistrictname": "บางโพธิ์",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840110",
            "subdistrictname": "บางกุ้ง",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8401",
            "districtname": "เมืองสุราษฎร์ธานี",
            "subdistrictcode": "840111",
            "subdistrictname": "คลองฉนาก",
            "zipcode": "84000"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840201",
            "subdistrictname": "ท่าทองใหม่",
            "zipcode": "84290"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840202",
            "subdistrictname": "ท่าทอง",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840203",
            "subdistrictname": "กะแดะ",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840204",
            "subdistrictname": "ทุ่งกง",
            "zipcode": "84290"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840205",
            "subdistrictname": "กรูด",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840206",
            "subdistrictname": "ช้างซ้าย",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840207",
            "subdistrictname": "พลายวาส",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840208",
            "subdistrictname": "ป่าร่อน",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840209",
            "subdistrictname": "ตะเคียนทอง",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840210",
            "subdistrictname": "ช้างขวา",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840211",
            "subdistrictname": "ท่าอุแท",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840212",
            "subdistrictname": "ทุ่งรัง",
            "zipcode": "84290"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8402",
            "districtname": "กาญจนดิษฐ์",
            "subdistrictcode": "840213",
            "subdistrictname": "คลองสระ",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8403",
            "districtname": "ดอนสัก",
            "subdistrictcode": "840301",
            "subdistrictname": "ดอนสัก",
            "zipcode": "84220"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8403",
            "districtname": "ดอนสัก",
            "subdistrictcode": "840302",
            "subdistrictname": "ชลคราม",
            "zipcode": "84160"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8403",
            "districtname": "ดอนสัก",
            "subdistrictcode": "840303",
            "subdistrictname": "ไชยคราม",
            "zipcode": "84220"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8403",
            "districtname": "ดอนสัก",
            "subdistrictcode": "840304",
            "subdistrictname": "ปากแพรก",
            "zipcode": "84340"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840401",
            "subdistrictname": "อ่างทอง",
            "zipcode": "84140"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840402",
            "subdistrictname": "ลิปะน้อย",
            "zipcode": "84140"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840403",
            "subdistrictname": "ตลิ่งงาม",
            "zipcode": "84140"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840404",
            "subdistrictname": "หน้าเมือง",
            "zipcode": "84140"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840405",
            "subdistrictname": "มะเร็ต",
            "zipcode": "84310"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840406",
            "subdistrictname": "บ่อผุด",
            "zipcode": "84320"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8404",
            "districtname": "เกาะสมุย",
            "subdistrictcode": "840407",
            "subdistrictname": "แม่น้ำ",
            "zipcode": "84330"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8405",
            "districtname": "เกาะพะงัน",
            "subdistrictcode": "840501",
            "subdistrictname": "เกาะพะงัน",
            "zipcode": "84280"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8405",
            "districtname": "เกาะพะงัน",
            "subdistrictcode": "840502",
            "subdistrictname": "บ้านใต้",
            "zipcode": "84280"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8405",
            "districtname": "เกาะพะงัน",
            "subdistrictcode": "840503",
            "subdistrictname": "เกาะเต่า",
            "zipcode": "84280"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840601",
            "subdistrictname": "ตลาดไชยา",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840602",
            "subdistrictname": "พุมเรียง",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840603",
            "subdistrictname": "เลม็ด",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840604",
            "subdistrictname": "เวียง",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840605",
            "subdistrictname": "ทุ่ง",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840606",
            "subdistrictname": "ป่าเว",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840607",
            "subdistrictname": "ตะกรบ",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840608",
            "subdistrictname": "โมถ่าย",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8406",
            "districtname": "ไชยา",
            "subdistrictcode": "840609",
            "subdistrictname": "ปากหมาก",
            "zipcode": "84110"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840701",
            "subdistrictname": "ท่าชนะ",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840702",
            "subdistrictname": "สมอทอง",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840703",
            "subdistrictname": "ประสงค์",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840704",
            "subdistrictname": "คันธุลี",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840705",
            "subdistrictname": "วัง",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8407",
            "districtname": "ท่าชนะ",
            "subdistrictcode": "840706",
            "subdistrictname": "คลองพา",
            "zipcode": "84170"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840801",
            "subdistrictname": "ท่าขนอน",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840802",
            "subdistrictname": "บ้านยาง",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840803",
            "subdistrictname": "น้ำหัก",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840806",
            "subdistrictname": "กะเปา",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840807",
            "subdistrictname": "ท่ากระดาน",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840808",
            "subdistrictname": "ย่านยาว",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840809",
            "subdistrictname": "ถ้ำสิงขร",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8408",
            "districtname": "คีรีรัฐนิคม",
            "subdistrictcode": "840810",
            "subdistrictname": "บ้านทำเนียบ",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8409",
            "districtname": "บ้านตาขุน",
            "subdistrictcode": "840901",
            "subdistrictname": "เขาวง",
            "zipcode": "84230"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8409",
            "districtname": "บ้านตาขุน",
            "subdistrictcode": "840902",
            "subdistrictname": "พะแสง",
            "zipcode": "84230"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8409",
            "districtname": "บ้านตาขุน",
            "subdistrictcode": "840903",
            "subdistrictname": "พรุไทย",
            "zipcode": "84230"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8409",
            "districtname": "บ้านตาขุน",
            "subdistrictcode": "840904",
            "subdistrictname": "เขาพัง",
            "zipcode": "84230"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841001",
            "subdistrictname": "พนม",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841002",
            "subdistrictname": "ต้นยวน",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841003",
            "subdistrictname": "คลองศก",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841004",
            "subdistrictname": "พลูเถื่อน",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841005",
            "subdistrictname": "พังกาญจน์",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8410",
            "districtname": "พนม",
            "subdistrictcode": "841006",
            "subdistrictname": "คลองชะอุ่น",
            "zipcode": "84250"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841101",
            "subdistrictname": "ท่าฉาง",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841102",
            "subdistrictname": "ท่าเคย",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841103",
            "subdistrictname": "คลองไทร",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841104",
            "subdistrictname": "เขาถ่าน",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841105",
            "subdistrictname": "เสวียด",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8411",
            "districtname": "ท่าฉาง",
            "subdistrictcode": "841106",
            "subdistrictname": "ปากฉลุย",
            "zipcode": "84150"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841201",
            "subdistrictname": "นาสาร",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841202",
            "subdistrictname": "พรุพี",
            "zipcode": "84270"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841203",
            "subdistrictname": "ทุ่งเตา",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841204",
            "subdistrictname": "ลำพูน",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841205",
            "subdistrictname": "ท่าชี",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841206",
            "subdistrictname": "ควนศรี",
            "zipcode": "84270"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841207",
            "subdistrictname": "ควนสุบรรณ",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841208",
            "subdistrictname": "คลองปราบ",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841209",
            "subdistrictname": "น้ำพุ",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841210",
            "subdistrictname": "ทุ่งเตาใหม่",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8412",
            "districtname": "บ้านนาสาร",
            "subdistrictcode": "841211",
            "subdistrictname": "เพิ่มพูนทรัพย์",
            "zipcode": "84120"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8413",
            "districtname": "บ้านนาเดิม",
            "subdistrictcode": "841301",
            "subdistrictname": "บ้านนา",
            "zipcode": "84240"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8413",
            "districtname": "บ้านนาเดิม",
            "subdistrictcode": "841302",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "84240"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8413",
            "districtname": "บ้านนาเดิม",
            "subdistrictcode": "841303",
            "subdistrictname": "ทรัพย์ทวี",
            "zipcode": "84240"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8413",
            "districtname": "บ้านนาเดิม",
            "subdistrictcode": "841304",
            "subdistrictname": "นาใต้",
            "zipcode": "84240"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8414",
            "districtname": "เคียนซา",
            "subdistrictcode": "841401",
            "subdistrictname": "เคียนซา",
            "zipcode": "84260"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8414",
            "districtname": "เคียนซา",
            "subdistrictcode": "841402",
            "subdistrictname": "พ่วงพรมคร",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8414",
            "districtname": "เคียนซา",
            "subdistrictcode": "841403",
            "subdistrictname": "เขาตอก",
            "zipcode": "84260"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8414",
            "districtname": "เคียนซา",
            "subdistrictcode": "841404",
            "subdistrictname": "อรัญคามวารี",
            "zipcode": "84260"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8414",
            "districtname": "เคียนซา",
            "subdistrictcode": "841405",
            "subdistrictname": "บ้านเสด็จ",
            "zipcode": "84260"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8415",
            "districtname": "เวียงสระ",
            "subdistrictcode": "841501",
            "subdistrictname": "เวียงสระ",
            "zipcode": "84190"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8415",
            "districtname": "เวียงสระ",
            "subdistrictcode": "841502",
            "subdistrictname": "บ้านส้อง",
            "zipcode": "84190"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8415",
            "districtname": "เวียงสระ",
            "subdistrictcode": "841503",
            "subdistrictname": "คลองฉนวน",
            "zipcode": "84190"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8415",
            "districtname": "เวียงสระ",
            "subdistrictcode": "841504",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "84190"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8415",
            "districtname": "เวียงสระ",
            "subdistrictcode": "841505",
            "subdistrictname": "เขานิพันธ์",
            "zipcode": "84190"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841601",
            "subdistrictname": "อิปัน",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841602",
            "subdistrictname": "สินปุน",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841603",
            "subdistrictname": "บางสวรรค์",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841604",
            "subdistrictname": "ไทรขึง",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841605",
            "subdistrictname": "สินเจริญ",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841606",
            "subdistrictname": "ไทรโสภา",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8416",
            "districtname": "พระแสง",
            "subdistrictcode": "841607",
            "subdistrictname": "สาคู",
            "zipcode": "84210"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841701",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841702",
            "subdistrictname": "ท่าสะท้อน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841703",
            "subdistrictname": "ลีเล็ด",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841704",
            "subdistrictname": "บางมะเดื่อ",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841705",
            "subdistrictname": "บางเดือน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841706",
            "subdistrictname": "ท่าโรงช้าง",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841707",
            "subdistrictname": "กรูด",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841708",
            "subdistrictname": "พุนพิน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841709",
            "subdistrictname": "บางงอน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841710",
            "subdistrictname": "ศรีวิชัย",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841711",
            "subdistrictname": "น้ำรอบ",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841712",
            "subdistrictname": "มะลวน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841713",
            "subdistrictname": "หัวเตย",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841714",
            "subdistrictname": "หนองไทร",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841715",
            "subdistrictname": "เขาหัวควาย",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8417",
            "districtname": "พุนพิน",
            "subdistrictcode": "841716",
            "subdistrictname": "ตะปาน",
            "zipcode": "84130"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8418",
            "districtname": "ชัยบุรี",
            "subdistrictcode": "841801",
            "subdistrictname": "สองแพรก",
            "zipcode": "84350"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8418",
            "districtname": "ชัยบุรี",
            "subdistrictcode": "841802",
            "subdistrictname": "ชัยบุรี",
            "zipcode": "84350"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8418",
            "districtname": "ชัยบุรี",
            "subdistrictcode": "841803",
            "subdistrictname": "คลองน้อย",
            "zipcode": "84350"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8418",
            "districtname": "ชัยบุรี",
            "subdistrictcode": "841804",
            "subdistrictname": "ไทรทอง",
            "zipcode": "84350"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8419",
            "districtname": "วิภาวดี",
            "subdistrictcode": "841901",
            "subdistrictname": "ตะกุกใต้",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "สุราษฎร์ธานี",
            "districtcode": "8419",
            "districtname": "วิภาวดี",
            "subdistrictcode": "841902",
            "subdistrictname": "ตะกุกเหนือ",
            "zipcode": "84180"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850101",
            "subdistrictname": "เขานิเวศน์",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850102",
            "subdistrictname": "ราชกรูด",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850103",
            "subdistrictname": "หงาว",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850104",
            "subdistrictname": "บางริ้น",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850105",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850106",
            "subdistrictname": "บางนอน",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850107",
            "subdistrictname": "หาดส้มแป้น",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850108",
            "subdistrictname": "ทรายแดง",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8501",
            "districtname": "เมืองระนอง",
            "subdistrictcode": "850109",
            "subdistrictname": "เกาะพยาม",
            "zipcode": "85000"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850201",
            "subdistrictname": "ละอุ่นใต้",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850202",
            "subdistrictname": "ละอุ่นเหนือ",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850203",
            "subdistrictname": "บางพระใต้",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850204",
            "subdistrictname": "บางพระเหนือ",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850205",
            "subdistrictname": "บางแก้ว",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850206",
            "subdistrictname": "ในวงเหนือ",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8502",
            "districtname": "ละอุ่น",
            "subdistrictcode": "850207",
            "subdistrictname": "ในวงใต้",
            "zipcode": "85130"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8503",
            "districtname": "กะเปอร์",
            "subdistrictcode": "850301",
            "subdistrictname": "ม่วงกลวง",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8503",
            "districtname": "กะเปอร์",
            "subdistrictcode": "850302",
            "subdistrictname": "กะเปอร์",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8503",
            "districtname": "กะเปอร์",
            "subdistrictcode": "850303",
            "subdistrictname": "เชี่ยวเหลียง",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8503",
            "districtname": "กะเปอร์",
            "subdistrictcode": "850304",
            "subdistrictname": "บ้านนา",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8503",
            "districtname": "กะเปอร์",
            "subdistrictcode": "850305",
            "subdistrictname": "บางหิน",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850401",
            "subdistrictname": "น้ำจืด",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850402",
            "subdistrictname": "น้ำจืดน้อย",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850403",
            "subdistrictname": "มะมุ",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850404",
            "subdistrictname": "ปากจั่น",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850405",
            "subdistrictname": "ลำเลียง",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850406",
            "subdistrictname": "จ.ป.ร.",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8504",
            "districtname": "กระบุรี",
            "subdistrictcode": "850407",
            "subdistrictname": "บางใหญ่",
            "zipcode": "85110"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8505",
            "districtname": "สุขสำราญ",
            "subdistrictcode": "850501",
            "subdistrictname": "นาคา",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ระนอง",
            "districtcode": "8505",
            "districtname": "สุขสำราญ",
            "subdistrictcode": "850502",
            "subdistrictname": "กำพวน",
            "zipcode": "85120"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860101",
            "subdistrictname": "ท่าตะเภา",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860102",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "86120"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860103",
            "subdistrictname": "ท่ายาง",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860104",
            "subdistrictname": "บางหมาก",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860105",
            "subdistrictname": "นาทุ่ง",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860106",
            "subdistrictname": "นาชะอัง",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860107",
            "subdistrictname": "ตากแดด",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860108",
            "subdistrictname": "บางลึก",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860109",
            "subdistrictname": "หาดพันไกร",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860110",
            "subdistrictname": "วังไผ่",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860111",
            "subdistrictname": "วังใหม่",
            "zipcode": "86190"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860112",
            "subdistrictname": "บ้านนา",
            "zipcode": "86190"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860113",
            "subdistrictname": "ขุนกระทิง",
            "zipcode": "86000"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860114",
            "subdistrictname": "ทุ่งคา",
            "zipcode": "86100"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860115",
            "subdistrictname": "วิสัยเหนือ",
            "zipcode": "86100"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860116",
            "subdistrictname": "หาดทรายรี",
            "zipcode": "86120"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8601",
            "districtname": "เมืองชุมพร",
            "subdistrictcode": "860117",
            "subdistrictname": "ถ้ำสิงห์",
            "zipcode": "86100"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860201",
            "subdistrictname": "ท่าแซะ",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860202",
            "subdistrictname": "คุริง",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860203",
            "subdistrictname": "สลุย",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860204",
            "subdistrictname": "นากระตาม",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860205",
            "subdistrictname": "รับร่อ",
            "zipcode": "86190"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860206",
            "subdistrictname": "ท่าข���าม",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860207",
            "subdistrictname": "หงษ์เจริญ",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860208",
            "subdistrictname": "หินแก้ว",
            "zipcode": "86190"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860209",
            "subdistrictname": "ทรัพย์อนันต์",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8602",
            "districtname": "ท่าแซะ",
            "subdistrictcode": "860210",
            "subdistrictname": "สองพี่น้อง",
            "zipcode": "86140"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860301",
            "subdistrictname": "บางสน",
            "zipcode": "86160"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860302",
            "subdistrictname": "ทะเลทรัพย์",
            "zipcode": "86160"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860303",
            "subdistrictname": "สะพลี",
            "zipcode": "86230"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860304",
            "subdistrictname": "ชุมโค",
            "zipcode": "86160"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860305",
            "subdistrictname": "ดอนยาง",
            "zipcode": "86210"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860306",
            "subdistrictname": "ปากคลอง",
            "zipcode": "86210"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8603",
            "districtname": "ปะทิว",
            "subdistrictcode": "860307",
            "subdistrictname": "เขาไชยราช",
            "zipcode": "86210"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860401",
            "subdistrictname": "หลังสวน",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860402",
            "subdistrictname": "ขันเงิน",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860403",
            "subdistrictname": "ท่ามะพลา",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860404",
            "subdistrictname": "นาขา",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860405",
            "subdistrictname": "นาพญา",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860406",
            "subdistrictname": "บ้านควน",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860407",
            "subdistrictname": "บางมะพร้าว",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860408",
            "subdistrictname": "บางน้ำจืด",
            "zipcode": "86150"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860409",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "86150"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860410",
            "subdistrictname": "พ้อแดง",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860411",
            "subdistrictname": "แหลมทราย",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860412",
            "subdistrictname": "วังตะกอ",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8604",
            "districtname": "หลังสวน",
            "subdistrictcode": "860413",
            "subdistrictname": "หาดยาย",
            "zipcode": "86110"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8605",
            "districtname": "ละแม",
            "subdistrictcode": "860501",
            "subdistrictname": "ละแม",
            "zipcode": "86170"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8605",
            "districtname": "ละแม",
            "subdistrictcode": "860502",
            "subdistrictname": "ทุ่งหลวง",
            "zipcode": "86170"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8605",
            "districtname": "ละแม",
            "subdistrictcode": "860503",
            "subdistrictname": "สวนแตง",
            "zipcode": "86170"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8605",
            "districtname": "ละแม",
            "subdistrictcode": "860504",
            "subdistrictname": "ทุ่งคาวัด",
            "zipcode": "86170"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8606",
            "districtname": "พะโต๊ะ",
            "subdistrictcode": "860601",
            "subdistrictname": "พะโต๊ะ",
            "zipcode": "86180"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8606",
            "districtname": "พะโต๊ะ",
            "subdistrictcode": "860602",
            "subdistrictname": "ปากทรง",
            "zipcode": "86180"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8606",
            "districtname": "พะโต๊ะ",
            "subdistrictcode": "860603",
            "subdistrictname": "ปังหวาน",
            "zipcode": "86180"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8606",
            "districtname": "พะโต๊ะ",
            "subdistrictcode": "860604",
            "subdistrictname": "พระรักษ์",
            "zipcode": "86180"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860701",
            "subdistrictname": "นาโพธิ์",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860702",
            "subdistrictname": "สวี",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860703",
            "subdistrictname": "ทุ่งระยะ",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860704",
            "subdistrictname": "ท่าหิน",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860705",
            "subdistrictname": "ปากแพรก",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860706",
            "subdistrictname": "ด่านสวี",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860707",
            "subdistrictname": "ครน",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860708",
            "subdistrictname": "วิสัยใต้",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860709",
            "subdistrictname": "นาสัก",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860710",
            "subdistrictname": "เขาทะลุ",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8607",
            "districtname": "สวี",
            "subdistrictcode": "860711",
            "subdistrictname": "เขาค่าย",
            "zipcode": "86130"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8608",
            "districtname": "ทุ่งตะโก",
            "subdistrictcode": "860801",
            "subdistrictname": "ปากตะโก",
            "zipcode": "86220"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8608",
            "districtname": "ทุ่งตะโก",
            "subdistrictcode": "860802",
            "subdistrictname": "ทุ่งตะไคร",
            "zipcode": "86220"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8608",
            "districtname": "ทุ่งตะโก",
            "subdistrictcode": "860803",
            "subdistrictname": "ตะโก",
            "zipcode": "86220"
        },
        {
            "isActive": true,
            "provincecode": "ชุมพร",
            "districtcode": "8608",
            "districtname": "ทุ่งตะโก",
            "subdistrictcode": "860804",
            "subdistrictname": "ช่องไม้แก้ว",
            "zipcode": "86220"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900101",
            "subdistrictname": "บ่อยาง",
            "zipcode": "90000"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900102",
            "subdistrictname": "เขารูปช้าง",
            "zipcode": "90000"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900103",
            "subdistrictname": "เกาะแต้ว",
            "zipcode": "90000"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900104",
            "subdistrictname": "พะวง",
            "zipcode": "90100"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900105",
            "subdistrictname": "ทุ่งหวัง",
            "zipcode": "90000"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9001",
            "districtname": "เมืองสงขลา",
            "subdistrictcode": "900106",
            "subdistrictname": "เกาะยอ",
            "zipcode": "90100"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900201",
            "subdistrictname": "จะทิ้งพระ",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900202",
            "subdistrictname": "กระดังงา",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900203",
            "subdistrictname": "สนามชัย",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900204",
            "subdistrictname": "ดีหลวง",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900205",
            "subdistrictname": "ชุมพล",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900206",
            "subdistrictname": "คลองรี",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900207",
            "subdistrictname": "คูขุด",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900208",
            "subdistrictname": "ท่าหิน",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900209",
            "subdistrictname": "วัดจันทร์",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900210",
            "subdistrictname": "บ่อแดง",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9002",
            "districtname": "สทิงพระ",
            "subdistrictcode": "900211",
            "subdistrictname": "บ่อดาน",
            "zipcode": "90190"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900301",
            "subdistrictname": "บ้านนา",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900302",
            "subdistrictname": "ป่าชิง",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900303",
            "subdistrictname": "สะพานไม้แก่น",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900304",
            "subdistrictname": "สะกอม",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900305",
            "subdistrictname": "นาหว้า",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900306",
            "subdistrictname": "นาทับ",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900307",
            "subdistrictname": "น้ำขาว",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900308",
            "subdistrictname": "ขุนตัดหวาย",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900309",
            "subdistrictname": "ท่าหมอไทร",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900310",
            "subdistrictname": "จะโหนง",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900311",
            "subdistrictname": "คู",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900312",
            "subdistrictname": "แค",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900313",
            "subdistrictname": "คลองเปียะ",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9003",
            "districtname": "จะนะ",
            "subdistrictcode": "900314",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "90130"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900401",
            "subdistrictname": "นาทวี",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900402",
            "subdistrictname": "ฉาง",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900403",
            "subdistrictname": "นาหมอศรี",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900404",
            "subdistrictname": "คลองทราย",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900405",
            "subdistrictname": "ปลักหนู",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900406",
            "subdistrictname": "ท่าประดู่",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900407",
            "subdistrictname": "สะท้อน",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900408",
            "subdistrictname": "ทับช้าง",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900409",
            "subdistrictname": "ประกอบ",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9004",
            "districtname": "นาทวี",
            "subdistrictcode": "900410",
            "subdistrictname": "คลองกวาง",
            "zipcode": "90160"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900501",
            "subdistrictname": "เทพา",
            "zipcode": "90150"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900502",
            "subdistrictname": "ปากบาง",
            "zipcode": "90150"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900503",
            "subdistrictname": "เกาะสะบ้า",
            "zipcode": "90150"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900504",
            "subdistrictname": "ลำไพล",
            "zipcode": "90260"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900505",
            "subdistrictname": "ท่าม่วง",
            "zipcode": "90260"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900506",
            "subdistrictname": "วังใหญ่",
            "zipcode": "90260"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9005",
            "districtname": "เทพา",
            "subdistrictcode": "900507",
            "subdistrictname": "สะกอม",
            "zipcode": "90150"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900601",
            "subdistrictname": "สะบ้าย้อย",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900602",
            "subdistrictname": "ทุ่งพอ",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900603",
            "subdistrictname": "เปียน",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900604",
            "subdistrictname": "บ้านโหนด",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900605",
            "subdistrictname": "จะแหน",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900606",
            "subdistrictname": "คูหา",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900607",
            "subdistrictname": "เขาแดง",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900608",
            "subdistrictname": "บาโหย",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9006",
            "districtname": "สะบ้าย้อย",
            "subdistrictcode": "900609",
            "subdistrictname": "ธารคีรี",
            "zipcode": "90210"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900701",
            "subdistrictname": "ระโนด",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900702",
            "subdistrictname": "คลองแดน",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900703",
            "subdistrictname": "ตะเครียะ",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900704",
            "subdistrictname": "ท่าบอน",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900705",
            "subdistrictname": "บ้านใหม่",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900706",
            "subdistrictname": "บ่อตรุ",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900707",
            "subdistrictname": "ปากแตระ",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900708",
            "subdistrictname": "พังยาง",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900709",
            "subdistrictname": "ระวะ",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900710",
            "subdistrictname": "วัดสน",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900711",
            "subdistrictname": "บ้านขาว",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9007",
            "districtname": "ระโนด",
            "subdistrictcode": "900712",
            "subdistrictname": "แดนสงวน",
            "zipcode": "90140"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9008",
            "districtname": "กระแสสินธุ์",
            "subdistrictcode": "900801",
            "subdistrictname": "เกาะใหญ่",
            "zipcode": "90270"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9008",
            "districtname": "กระแสสินธุ์",
            "subdistrictcode": "900802",
            "subdistrictname": "โรง",
            "zipcode": "90270"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9008",
            "districtname": "กระแสสินธุ์",
            "subdistrictcode": "900803",
            "subdistrictname": "เชิงแส",
            "zipcode": "90270"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9008",
            "districtname": "กระแสสินธุ์",
            "subdistrictcode": "900804",
            "subdistrictname": "กระแสสินธุ์",
            "zipcode": "90270"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9009",
            "districtname": "รัตภูมิ",
            "subdistrictcode": "900901",
            "subdistrictname": "กำแพงเพชร",
            "zipcode": "90180"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9009",
            "districtname": "รัตภูมิ",
            "subdistrictcode": "900902",
            "subdistrictname": "ท่าชะมวง",
            "zipcode": "90180"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9009",
            "districtname": "รัตภูมิ",
            "subdistrictcode": "900903",
            "subdistrictname": "คูหาใต้",
            "zipcode": "90180"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9009",
            "districtname": "รัตภูมิ",
            "subdistrictcode": "900904",
            "subdistrictname": "ควนรู",
            "zipcode": "90180"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9009",
            "districtname": "รัตภูมิ",
            "subdistrictcode": "900909",
            "subdistrictname": "เขาพระ",
            "zipcode": "90180"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901001",
            "subdistrictname": "สะเดา",
            "zipcode": "90120"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901002",
            "subdistrictname": "ปริก",
            "zipcode": "90120"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901003",
            "subdistrictname": "พังลา",
            "zipcode": "90170"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901004",
            "subdistrictname": "สำนักแต้ว",
            "zipcode": "90120"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901005",
            "subdistrictname": "ทุ่งหมอ",
            "zipcode": "90240"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901006",
            "subdistrictname": "ท่าโพธิ์",
            "zipcode": "90170"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901007",
            "subdistrictname": "ปาดังเบซาร์",
            "zipcode": "90240"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901008",
            "subdistrictname": "สำนักขาม",
            "zipcode": "90320"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9010",
            "districtname": "สะเดา",
            "subdistrictcode": "901009",
            "subdistrictname": "เขามีเกียรติ",
            "zipcode": "90170"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901101",
            "subdistrictname": "หาดใหญ่",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901102",
            "subdistrictname": "ควนลัง",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901103",
            "subdistrictname": "คูเต่า",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901104",
            "subdistrictname": "คอหงส์",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901105",
            "subdistrictname": "คลองแห",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901107",
            "subdistrictname": "คลองอู่ตะเภา",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901108",
            "subdistrictname": "ฉลุง",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901111",
            "subdistrictname": "ทุ่งใหญ่",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901112",
            "subdistrictname": "ทุ่งตำเสา",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901113",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901114",
            "subdistrictname": "น้ำน้อย",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901116",
            "subdistrictname": "บ้านพรุ",
            "zipcode": "90250"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9011",
            "districtname": "หาดใหญ่",
            "subdistrictcode": "901118",
            "subdistrictname": "พะตง",
            "zipcode": "90230"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9012",
            "districtname": "นาหม่อม",
            "subdistrictcode": "901201",
            "subdistrictname": "นาหม่อม",
            "zipcode": "90310"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9012",
            "districtname": "นาหม่อม",
            "subdistrictcode": "901202",
            "subdistrictname": "พิจิตร",
            "zipcode": "90310"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9012",
            "districtname": "นาหม่อม",
            "subdistrictcode": "901203",
            "subdistrictname": "ทุ่งขมิ้น",
            "zipcode": "90310"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9012",
            "districtname": "นาหม่อม",
            "subdistrictcode": "901204",
            "subdistrictname": "คลองหรัง",
            "zipcode": "90310"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9013",
            "districtname": "ควนเนียง",
            "subdistrictcode": "901301",
            "subdistrictname": "รัตภูมิ",
            "zipcode": "90220"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9013",
            "districtname": "ควนเนียง",
            "subdistrictcode": "901302",
            "subdistrictname": "ควนโส",
            "zipcode": "90220"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9013",
            "districtname": "ควนเนียง",
            "subdistrictcode": "901303",
            "subdistrictname": "ห้วยลึก",
            "zipcode": "90220"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9013",
            "districtname": "ควนเนียง",
            "subdistrictcode": "901304",
            "subdistrictname": "บางเหรียง",
            "zipcode": "90220"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9014",
            "districtname": "บางกล่ำ",
            "subdistrictcode": "901401",
            "subdistrictname": "บางกล่ำ",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9014",
            "districtname": "บางกล่ำ",
            "subdistrictcode": "901402",
            "subdistrictname": "ท่าช้าง",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9014",
            "districtname": "บางกล่ำ",
            "subdistrictcode": "901403",
            "subdistrictname": "แม่ทอม",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9014",
            "districtname": "บางกล่ำ",
            "subdistrictcode": "901404",
            "subdistrictname": "บ้านหาร",
            "zipcode": "90110"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901501",
            "subdistrictname": "ชิงโค",
            "zipcode": "90280"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901502",
            "subdistrictname": "สทิงหม้อ",
            "zipcode": "90280"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901503",
            "subdistrictname": "ทำนบ",
            "zipcode": "90280"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901504",
            "subdistrictname": "รำแดง",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901505",
            "subdistrictname": "วัดขนุน",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901506",
            "subdistrictname": "ชะแล้",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901507",
            "subdistrictname": "ปากรอ",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901508",
            "subdistrictname": "ป่าขาด",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901509",
            "subdistrictname": "หัวเขา",
            "zipcode": "90280"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901510",
            "subdistrictname": "บางเขียด",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9015",
            "districtname": "สิงหนคร",
            "subdistrictcode": "901511",
            "subdistrictname": "ม่วงงาม",
            "zipcode": "90330"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9016",
            "districtname": "คลองหอยโข่ง",
            "subdistrictcode": "901601",
            "subdistrictname": "คลองหอยโข่ง",
            "zipcode": "90230"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9016",
            "districtname": "คลองหอยโข่ง",
            "subdistrictcode": "901602",
            "subdistrictname": "ทุ่งลาน",
            "zipcode": "90230"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9016",
            "districtname": "คลองหอยโข่ง",
            "subdistrictcode": "901603",
            "subdistrictname": "โคกม่วง",
            "zipcode": "90230"
        },
        {
            "isActive": true,
            "provincecode": "สงขลา",
            "districtcode": "9016",
            "districtname": "คลองหอยโข่ง",
            "subdistrictcode": "901604",
            "subdistrictname": "คลองหลา",
            "zipcode": "90115"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910101",
            "subdistrictname": "พิมาน",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910102",
            "subdistrictname": "คลองขุด",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910103",
            "subdistrictname": "ควนขัน",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910104",
            "subdistrictname": "บ้านควน",
            "zipcode": "91140"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910105",
            "subdistrictname": "ฉลุง",
            "zipcode": "91140"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910106",
            "subdistrictname": "เกาะสาหร่าย",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910107",
            "subdistrictname": "ตันหยงโป",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910108",
            "subdistrictname": "เจ๊ะบิลัง",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910109",
            "subdistrictname": "ตำมะลัง",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910110",
            "subdistrictname": "ปูยู",
            "zipcode": "91000"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910111",
            "subdistrictname": "ควนโพธิ์",
            "zipcode": "91140"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9101",
            "districtname": "เมืองสตูล",
            "subdistrictcode": "910112",
            "subdistrictname": "เกตรี",
            "zipcode": "91140"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9102",
            "districtname": "ควนโดน",
            "subdistrictcode": "910201",
            "subdistrictname": "ควนโดน",
            "zipcode": "91160"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9102",
            "districtname": "ควนโดน",
            "subdistrictcode": "910202",
            "subdistrictname": "ควนสตอ",
            "zipcode": "91160"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9102",
            "districtname": "ควนโดน",
            "subdistrictcode": "910203",
            "subdistrictname": "ย่านซื่อ",
            "zipcode": "91160"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9102",
            "districtname": "ควนโดน",
            "subdistrictcode": "910204",
            "subdistrictname": "วังประจัน",
            "zipcode": "91160"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9103",
            "districtname": "ควนกาหลง",
            "subdistrictcode": "910301",
            "subdistrictname": "ทุ่งนุ้ย",
            "zipcode": "91130"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9103",
            "districtname": "ควนกาหลง",
            "subdistrictcode": "910302",
            "subdistrictname": "ควนกาหลง",
            "zipcode": "91130"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9103",
            "districtname": "ควนกาหลง",
            "subdistrictcode": "910303",
            "subdistrictname": "อุใดเจริญ",
            "zipcode": "91130"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9104",
            "districtname": "ท่าแพ",
            "subdistrictcode": "910401",
            "subdistrictname": "ท่าแพ",
            "zipcode": "91150"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9104",
            "districtname": "ท่าแพ",
            "subdistrictcode": "910402",
            "subdistrictname": "แป-ระ",
            "zipcode": "91150"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9104",
            "districtname": "ท่าแพ",
            "subdistrictcode": "910403",
            "subdistrictname": "สาคร",
            "zipcode": "91150"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9104",
            "districtname": "ท่าแพ",
            "subdistrictcode": "910404",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "91150"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910501",
            "subdistrictname": "กำแพง",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910502",
            "subdistrictname": "ละงู",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910503",
            "subdistrictname": "เขาขาว",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910504",
            "subdistrictname": "ปากน้ำ",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910505",
            "subdistrictname": "น้ำผุด",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9105",
            "districtname": "ละงู",
            "subdistrictcode": "910506",
            "subdistrictname": "แหลมสน",
            "zipcode": "91110"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า",
            "subdistrictcode": "910601",
            "subdistrictname": "ทุ่งหว้า",
            "zipcode": "91120"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า",
            "subdistrictcode": "910602",
            "subdistrictname": "นาทอน",
            "zipcode": "91120"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า",
            "subdistrictcode": "910603",
            "subdistrictname": "ขอนคลาน",
            "zipcode": "91120"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า",
            "subdistrictcode": "910604",
            "subdistrictname": "ทุ่งบุหลัง",
            "zipcode": "91120"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9106",
            "districtname": "ทุ่งหว้า",
            "subdistrictcode": "910605",
            "subdistrictname": "ป่าแก่บ่อหิน",
            "zipcode": "91120"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9107",
            "districtname": "มะนัง",
            "subdistrictcode": "910701",
            "subdistrictname": "ปาล์มพัฒนา",
            "zipcode": "91130"
        },
        {
            "isActive": true,
            "provincecode": "สตูล",
            "districtcode": "9107",
            "districtname": "มะนัง",
            "subdistrictcode": "910702",
            "subdistrictname": "นิคมพัฒ���า",
            "zipcode": "91130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920101",
            "subdistrictname": "ทับเที่ยง",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920104",
            "subdistrictname": "นาพละ",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920105",
            "subdistrictname": "บ้านควน",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920106",
            "subdistrictname": "นาบินหลา",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920107",
            "subdistrictname": "ควนปริง",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920108",
            "subdistrictname": "นาโยงใต้",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920109",
            "subdistrictname": "บางรัก",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920110",
            "subdistrictname": "โคกหล่อ",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920113",
            "subdistrictname": "นาโต๊ะหมิง",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920114",
            "subdistrictname": "หนองตรุด",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920115",
            "subdistrictname": "น้ำผุด",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920117",
            "subdistrictname": "นาตาล่วง",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920118",
            "subdistrictname": "บ้านโพธิ์",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920119",
            "subdistrictname": "นาท่ามเหนือ",
            "zipcode": "92190"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9201",
            "districtname": "เมืองตรัง",
            "subdistrictcode": "920120",
            "subdistrictname": "นาท่ามใต้",
            "zipcode": "92190"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920201",
            "subdistrictname": "กันตัง",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920202",
            "subdistrictname": "ควนธานี",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920203",
            "subdistrictname": "บางหมาก",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920204",
            "subdistrictname": "บางเป้า",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920205",
            "subdistrictname": "วังวน",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920206",
            "subdistrictname": "กันตังใต้",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920207",
            "subdistrictname": "โคกยาง",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920208",
            "subdistrictname": "คลองลุ",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920209",
            "subdistrictname": "ย่านซื่อ",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920210",
            "subdistrictname": "บ่อน้ำร้อน",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920211",
            "subdistrictname": "บางสัก",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920212",
            "subdistrictname": "นาเกลือ",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920213",
            "subdistrictname": "เกาะลิบง",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9202",
            "districtname": "กันตัง",
            "subdistrictcode": "920214",
            "subdistrictname": "คลองชีล้อม",
            "zipcode": "92110"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920301",
            "subdistrictname": "ย่านตาขาว",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920302",
            "subdistrictname": "หนองบ่อ",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920303",
            "subdistrictname": "นาชุมเห็ด",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920304",
            "subdistrictname": "ในควน",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920305",
            "subdistrictname": "โพรงจระเข้",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920306",
            "subdistrictname": "ทุ่งกระบือ",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920307",
            "subdistrictname": "ทุ่งค่าย",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9203",
            "districtname": "ย่านตาขาว",
            "subdistrictcode": "920308",
            "subdistrictname": "เกาะเปียะ",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920401",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920402",
            "subdistrictname": "ทุ่งยาว",
            "zipcode": "92180"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920403",
            "subdistrictname": "ปะเหลียน",
            "zipcode": "92180"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920404",
            "subdistrictname": "บางด้วน",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920407",
            "subdistrictname": "บ้านนา",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920409",
            "subdistrictname": "สุโสะ",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920410",
            "subdistrictname": "ลิพัง",
            "zipcode": "92180"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920411",
            "subdistrictname": "เกาะสุกร",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920412",
            "subdistrictname": "ท่าพญา",
            "zipcode": "92140"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9204",
            "districtname": "ปะเหลียน",
            "subdistrictcode": "920413",
            "subdistrictname": "แหลมสอม",
            "zipcode": "92180"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9205",
            "districtname": "สิเกา",
            "subdistrictcode": "920501",
            "subdistrictname": "บ่อหิน",
            "zipcode": "92150"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9205",
            "districtname": "สิเกา",
            "subdistrictcode": "920502",
            "subdistrictname": "เขาไม้แก้ว",
            "zipcode": "92150"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9205",
            "districtname": "สิเกา",
            "subdistrictcode": "920503",
            "subdistrictname": "กะลาเส",
            "zipcode": "92150"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9205",
            "districtname": "สิเกา",
            "subdistrictcode": "920504",
            "subdistrictname": "ไม้ฝาด",
            "zipcode": "92150"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9205",
            "districtname": "สิเกา",
            "subdistrictcode": "920505",
            "subdistrictname": "นาเมืองเพชร",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920601",
            "subdistrictname": "ห้วยยอด",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920602",
            "subdistrictname": "หนองช้างแล่น",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920605",
            "subdistrictname": "บางดี",
            "zipcode": "92210"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920606",
            "subdistrictname": "บางกุ้ง",
            "zipcode": "92210"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920607",
            "subdistrictname": "เขากอบ",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920608",
            "subdistrictname": "เขาขาว",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920609",
            "subdistrictname": "เขาปูน",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920610",
            "subdistrictname": "ปากแจ่ม",
            "zipcode": "92190"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920611",
            "subdistrictname": "ปากคม",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920614",
            "subdistrictname": "ท่างิ้ว",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920615",
            "subdistrictname": "ลำภูรา",
            "zipcode": "92190"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920616",
            "subdistrictname": "นาวง",
            "zipcode": "92210"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920617",
            "subdistrictname": "ห้วยนาง",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920619",
            "subdistrictname": "ในเตา",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920620",
            "subdistrictname": "ทุ่งต่อ",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9206",
            "districtname": "ห้วยยอด",
            "subdistrictcode": "920621",
            "subdistrictname": "วังคีรี",
            "zipcode": "92210"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9207",
            "districtname": "วังวิเศษ",
            "subdistrictcode": "920701",
            "subdistrictname": "เขาวิเศษ",
            "zipcode": "92220"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9207",
            "districtname": "วังวิเศษ",
            "subdistrictcode": "920702",
            "subdistrictname": "วังมะปราง",
            "zipcode": "92220"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9207",
            "districtname": "วังวิเศษ",
            "subdistrictcode": "920703",
            "subdistrictname": "อ่าวตง",
            "zipcode": "92220"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9207",
            "districtname": "วังวิเศษ",
            "subdistrictcode": "920704",
            "subdistrictname": "ท่าสะบ้า",
            "zipcode": "92000"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9207",
            "districtname": "วังวิเศษ",
            "subdistrictcode": "920705",
            "subdistrictname": "วังมะปรางเหนือ",
            "zipcode": "92220"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920801",
            "subdistrictname": "นาโยงเหนือ",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920802",
            "subdistrictname": "ช่อง",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920803",
            "subdistrictname": "ละมอ",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920804",
            "subdistrictname": "โคกสะบ้า",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920805",
            "subdistrictname": "นาหมื่นศรี",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9208",
            "districtname": "นาโยง",
            "subdistrictcode": "920806",
            "subdistrictname": "นาข้าวเสีย",
            "zipcode": "92170"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9209",
            "districtname": "รัษฎา",
            "subdistrictcode": "920901",
            "subdistrictname": "ควนเมา",
            "zipcode": "92160"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9209",
            "districtname": "รัษฎา",
            "subdistrictcode": "920902",
            "subdistrictname": "คลองปาง",
            "zipcode": "92160"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9209",
            "districtname": "รัษฎา",
            "subdistrictcode": "920903",
            "subdistrictname": "หนองบัว",
            "zipcode": "92160"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9209",
            "districtname": "รัษฎา",
            "subdistrictcode": "920904",
            "subdistrictname": "หนองปรือ",
            "zipcode": "92130"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9209",
            "districtname": "รัษฎา",
            "subdistrictcode": "920905",
            "subdistrictname": "เขาไพร",
            "zipcode": "92160"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9210",
            "districtname": "หาดสำราญ",
            "subdistrictcode": "921001",
            "subdistrictname": "หาดสำราญ",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9210",
            "districtname": "หาดสำราญ",
            "subdistrictcode": "921002",
            "subdistrictname": "บ้าหวี",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "ตรัง",
            "districtcode": "9210",
            "districtname": "หาดสำราญ",
            "subdistrictcode": "921003",
            "subdistrictname": "ตะเสะ",
            "zipcode": "92120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930101",
            "subdistrictname": "คูหาสวรรค์",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930103",
            "subdistrictname": "เขาเจียก",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930104",
            "subdistrictname": "ท่ามิหรำ",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930105",
            "subdistrictname": "โคกชะงาย",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930106",
            "subdistrictname": "นาท่อม",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930107",
            "subdistrictname": "ปรางหมู่",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930108",
            "subdistrictname": "ท่าแค",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930109",
            "subdistrictname": "ลำปำ",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930110",
            "subdistrictname": "ตำนาน",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930111",
            "subdistrictname": "ควนมะพร้าว",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930112",
            "subdistrictname": "ร่มเมือง",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930113",
            "subdistrictname": "ชัยบุรี",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930114",
            "subdistrictname": "นาโหนด",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9301",
            "districtname": "เมืองพัทลุง",
            "subdistrictcode": "930115",
            "subdistrictname": "พญาขัน",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9302",
            "districtname": "กงหรา",
            "subdistrictcode": "930201",
            "subdistrictname": "กงหรา",
            "zipcode": "93180"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9302",
            "districtname": "กงหรา",
            "subdistrictcode": "930202",
            "subdistrictname": "ชะรัด",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9302",
            "districtname": "กงหรา",
            "subdistrictcode": "930203",
            "subdistrictname": "คลองเฉลิม",
            "zipcode": "93180"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9302",
            "districtname": "กงหรา",
            "subdistrictcode": "930204",
            "subdistrictname": "คลองทรายขาว",
            "zipcode": "93180"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9302",
            "districtname": "กงหรา",
            "subdistrictcode": "930205",
            "subdistrictname": "สมหวัง",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9303",
            "districtname": "เขาชัยสน",
            "subdistrictcode": "930301",
            "subdistrictname": "เขาชัยสน",
            "zipcode": "93130"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9303",
            "districtname": "เขาชัยสน",
            "subdistrictcode": "930302",
            "subdistrictname": "ควนขนุน",
            "zipcode": "93130"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9303",
            "districtname": "เขาชัยสน",
            "subdistrictcode": "930305",
            "subdistrictname": "จองถนน",
            "zipcode": "93130"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9303",
            "districtname": "เขาชัยสน",
            "subdistrictcode": "930306",
            "subdistrictname": "หานโพธิ์",
            "zipcode": "93130"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9303",
            "districtname": "เขาชัยสน",
            "subdistrictcode": "930307",
            "subdistrictname": "โคกม่วง",
            "zipcode": "93130"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9304",
            "districtname": "ตะโหมด",
            "subdistrictcode": "930401",
            "subdistrictname": "แม่ขรี",
            "zipcode": "93160"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9304",
            "districtname": "ตะโหมด",
            "subdistrictcode": "930402",
            "subdistrictname": "ตะโหมด",
            "zipcode": "93160"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9304",
            "districtname": "ตะโหมด",
            "subdistrictcode": "930403",
            "subdistrictname": "คลองใหญ่",
            "zipcode": "93160"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930501",
            "subdistrictname": "ควนขนุน",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930502",
            "subdistrictname": "ทะเลน้อย",
            "zipcode": "93150"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930504",
            "subdistrictname": "นาขยาด",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930505",
            "subdistrictname": "พนมวังก์",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930506",
            "subdistrictname": "แหลมโตนด",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930508",
            "subdistrictname": "ปันแต",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930509",
            "subdistrictname": "โตนดด้วน",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930510",
            "subdistrictname": "ดอนทราย",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930511",
            "subdistrictname": "มะกอกเหนือ",
            "zipcode": "93150"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930512",
            "subdistrictname": "พนางตุง",
            "zipcode": "93150"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930513",
            "subdistrictname": "ชะมวง",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9305",
            "districtname": "ควนขนุน",
            "subdistrictcode": "930516",
            "subdistrictname": "แพรกหา",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930601",
            "subdistrictname": "ปากพะยูน",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930602",
            "subdistrictname": "ดอนประดู่",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930603",
            "subdistrictname": "เกาะนางคำ",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930604",
            "subdistrictname": "เกาะหมาก",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930605",
            "subdistrictname": "ฝาละมี",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930606",
            "subdistrictname": "หารเทา",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9306",
            "districtname": "ปากพะยูน",
            "subdistrictcode": "930607",
            "subdistrictname": "ดอนทราย",
            "zipcode": "93120"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9307",
            "districtname": "ศรีบรรพต",
            "subdistrictcode": "930701",
            "subdistrictname": "เขาย่า",
            "zipcode": "93190"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9307",
            "districtname": "ศรีบรรพต",
            "subdistrictcode": "930702",
            "subdistrictname": "เขาปู่",
            "zipcode": "93190"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9307",
            "districtname": "ศรีบรรพต",
            "subdistrictcode": "930703",
            "subdistrictname": "ตะแพน",
            "zipcode": "93190"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9308",
            "districtname": "ป่าบอน",
            "subdistrictcode": "930801",
            "subdistrictname": "ป่าบอน",
            "zipcode": "93170"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9308",
            "districtname": "ป่าบอน",
            "subdistrictcode": "930802",
            "subdistrictname": "โคกทราย",
            "zipcode": "93170"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9308",
            "districtname": "ป่าบอน",
            "subdistrictcode": "930803",
            "subdistrictname": "หนองธง",
            "zipcode": "93170"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9308",
            "districtname": "ป่าบอน",
            "subdistrictcode": "930804",
            "subdistrictname": "ทุ่งนารี",
            "zipcode": "93170"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9308",
            "districtname": "ป่าบอน",
            "subdistrictcode": "930806",
            "subdistrictname": "วังใหม่",
            "zipcode": "93170"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9309",
            "districtname": "บางแก้ว",
            "subdistrictcode": "930901",
            "subdistrictname": "ท่ามะเดื่อ",
            "zipcode": "93140"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9309",
            "districtname": "บางแก้ว",
            "subdistrictcode": "930902",
            "subdistrictname": "นาปะขอ",
            "zipcode": "93140"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9309",
            "districtname": "บางแก้ว",
            "subdistrictcode": "930903",
            "subdistrictname": "โคกสัก",
            "zipcode": "93140"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9310",
            "districtname": "ป่าพะยอม",
            "subdistrictcode": "931001",
            "subdistrictname": "ป่าพะยอม",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9310",
            "districtname": "ป่าพะยอม",
            "subdistrictcode": "931002",
            "subdistrictname": "ลานข่อย",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9310",
            "districtname": "ป่าพะยอม",
            "subdistrictcode": "931003",
            "subdistrictname": "เกาะเต่า",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9310",
            "districtname": "ป่าพะยอม",
            "subdistrictcode": "931004",
            "subdistrictname": "บ้านพร้าว",
            "zipcode": "93110"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9311",
            "districtname": "ศรีนครินทร์",
            "subdistrictcode": "931101",
            "subdistrictname": "ชุมพล",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9311",
            "districtname": "ศรีนครินทร์",
            "subdistrictcode": "931102",
            "subdistrictname": "บ้านนา",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9311",
            "districtname": "ศรีนครินทร์",
            "subdistrictcode": "931103",
            "subdistrictname": "อ่างทอง",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "พัทลุง",
            "districtcode": "9311",
            "districtname": "ศรีนครินทร์",
            "subdistrictcode": "931104",
            "subdistrictname": "ลำสินธุ์",
            "zipcode": "93000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940101",
            "subdistrictname": "สะบารัง",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940102",
            "subdistrictname": "อาเนาะรู",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940103",
            "subdistrictname": "จะบังติกอ",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940104",
            "subdistrictname": "บานา",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940105",
            "subdistrictname": "ตันหยงลุโละ",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940106",
            "subdistrictname": "คลองมานิง",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940107",
            "subdistrictname": "กะมิยอ",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940108",
            "subdistrictname": "บาราโหม",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940109",
            "subdistrictname": "ปะกาฮะรัง",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940110",
            "subdistrictname": "รูสะมิแล",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940111",
            "subdistrictname": "ตะลุโบะ",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940112",
            "subdistrictname": "บาราเฮาะ",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9401",
            "districtname": "เมืองปัตตานี",
            "subdistrictcode": "940113",
            "subdistrictname": "ปุยุด",
            "zipcode": "94000"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940201",
            "subdistrictname": "โคกโพธิ์",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940202",
            "subdistrictname": "มะกรูด",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940203",
            "subdistrictname": "บางโกระ",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940204",
            "subdistrictname": "ป่าบอน",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940205",
            "subdistrictname": "ทรายขาว",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940206",
            "subdistrictname": "นาประดู่",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940207",
            "subdistrictname": "ปากล่อ",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940208",
            "subdistrictname": "ทุ่งพลา",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940211",
            "subdistrictname": "ท่าเรือ",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940213",
            "subdistrictname": "นาเกตุ",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940214",
            "subdistrictname": "ควนโนรี",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9402",
            "districtname": "โคกโพธิ์",
            "subdistrictcode": "940215",
            "subdistrictname": "ช้างให้ตก",
            "zipcode": "94120"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940301",
            "subdistrictname": "เกาะเปาะ",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940302",
            "subdistrictname": "คอลอตันหยง",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940303",
            "subdistrictname": "ดอนรัก",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940304",
            "subdistrictname": "ดาโต๊ะ",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940305",
            "subdistrictname": "ตุยง",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940306",
            "subdistrictname": "ท่ากำชำ",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940307",
            "subdistrictname": "บ่อทอง",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940308",
            "subdistrictname": "บางเขา",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940309",
            "subdistrictname": "บางตาวา",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940310",
            "subdistrictname": "ปุโละปุโย",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940311",
            "subdistrictname": "ยาบี",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9403",
            "districtname": "หนองจิก",
            "subdistrictcode": "940312",
            "subdistrictname": "ลิปะสะโง",
            "zipcode": "94170"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940401",
            "subdistrictname": "ปะนาเระ",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940402",
            "subdistrictname": "ท่าข้าม",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940403",
            "subdistrictname": "บ้านนอก",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940404",
            "subdistrictname": "ดอน",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940405",
            "subdistrictname": "ควน",
            "zipcode": "94190"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940406",
            "subdistrictname": "ท่าน้ำ",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940407",
            "subdistrictname": "คอกกระบือ",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940408",
            "subdistrictname": "พ่อมิ่ง",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940409",
            "subdistrictname": "บ้านกลาง",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9404",
            "districtname": "ปะนาเระ",
            "subdistrictcode": "940410",
            "subdistrictname": "บ้านน้ำบ่อ",
            "zipcode": "94130"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940501",
            "subdistrictname": "มายอ",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940502",
            "subdistrictname": "ถนน",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940503",
            "subdistrictname": "ตรัง",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940504",
            "subdistrictname": "กระหวะ",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940505",
            "subdistrictname": "ลุโบะยิไร",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940506",
            "subdistrictname": "ลางา",
            "zipcode": "94190"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940507",
            "subdistrictname": "กระเสาะ",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940508",
            "subdistrictname": "เกาะจัน",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940509",
            "subdistrictname": "ปะโด",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940510",
            "subdistrictname": "สาคอบน",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940511",
            "subdistrictname": "สาคอใต้",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940512",
            "subdistrictname": "สะกำ",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9405",
            "districtname": "มายอ",
            "subdistrictcode": "940513",
            "subdistrictname": "ปานัน",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9406",
            "districtname": "ทุ่งยางแดง",
            "subdistrictcode": "940601",
            "subdistrictname": "ตะโละแมะนา",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9406",
            "districtname": "ทุ่งยางแดง",
            "subdistrictcode": "940602",
            "subdistrictname": "พิเทน",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9406",
            "districtname": "ทุ่งยางแดง",
            "subdistrictcode": "940603",
            "subdistrictname": "น้ำดำ",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9406",
            "districtname": "ทุ่งยางแดง",
            "subdistrictcode": "940604",
            "subdistrictname": "ปากู",
            "zipcode": "94140"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940701",
            "subdistrictname": "ตะลุบัน",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940702",
            "subdistrictname": "ตะบิ้ง",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940703",
            "subdistrictname": "ปะเสยะวอ",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940704",
            "subdistrictname": "บางเก่า",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940705",
            "subdistrictname": "บือเระ",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940706",
            "subdistrictname": "เตราะบอน",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940707",
            "subdistrictname": "กะดุนง",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940708",
            "subdistrictname": "ละหาร",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940709",
            "subdistrictname": "มะนังดาลำ",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940710",
            "subdistrictname": "แป้น",
            "zipcode": "94110"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9407",
            "districtname": "สายบุรี",
            "subdistrictcode": "940711",
            "subdistrictname": "ทุ่งคล้า",
            "zipcode": "94190"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9408",
            "districtname": "ไม้แก่น",
            "subdistrictcode": "940801",
            "subdistrictname": "ไทรทอง",
            "zipcode": "94220"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9408",
            "districtname": "ไม้แก่น",
            "subdistrictcode": "940802",
            "subdistrictname": "ไม้แก่น",
            "zipcode": "94220"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9408",
            "districtname": "ไม้แก่น",
            "subdistrictcode": "940803",
            "subdistrictname": "ตะโละไกรทอง",
            "zipcode": "94220"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9408",
            "districtname": "ไม้แก่น",
            "subdistrictcode": "940804",
            "subdistrictname": "ดอนทราย",
            "zipcode": "94220"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940901",
            "subdistrictname": "ตะโละ",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940902",
            "subdistrictname": "ตะโละกาโปร์",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940903",
            "subdistrictname": "ตันหยงดาลอ",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940904",
            "subdistrictname": "ตันหยงจึงงา",
            "zipcode": "94190"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940905",
            "subdistrictname": "ตอหลัง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940906",
            "subdistrictname": "ตาแกะ",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940907",
            "subdistrictname": "ตาลีอายร์",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940908",
            "subdistrictname": "ยามู",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940909",
            "subdistrictname": "บางปู",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940910",
            "subdistrictname": "หนองแรต",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940911",
            "subdistrictname": "ปิยามุมัง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940912",
            "subdistrictname": "ปุลากง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940913",
            "subdistrictname": "บาโลย",
            "zipcode": "94190"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940914",
            "subdistrictname": "สาบัน",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940915",
            "subdistrictname": "มะนังยง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940916",
            "subdistrictname": "ราตาปันยัง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940917",
            "subdistrictname": "จะรัง",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9409",
            "districtname": "ยะหริ่ง",
            "subdistrictcode": "940918",
            "subdistrictname": "แหลมโพธิ์",
            "zipcode": "94150"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941001",
            "subdistrictname": "ยะรัง",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941002",
            "subdistrictname": "สะดาวา",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941003",
            "subdistrictname": "ประจัน",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941004",
            "subdistrictname": "สะนอ",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941005",
            "subdistrictname": "ระแว้ง",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941006",
            "subdistrictname": "ปิตูมุดี",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941007",
            "subdistrictname": "วัด",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941008",
            "subdistrictname": "กระโด",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941009",
            "subdistrictname": "คลองใหม่",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941010",
            "subdistrictname": "เมาะมาวี",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941011",
            "subdistrictname": "กอลำ",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9410",
            "districtname": "ยะรัง",
            "subdistrictcode": "941012",
            "subdistrictname": "เขาตูม",
            "zipcode": "94160"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9411",
            "districtname": "กะพ้อ",
            "subdistrictcode": "941101",
            "subdistrictname": "กะรุบี",
            "zipcode": "94230"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9411",
            "districtname": "กะพ้อ",
            "subdistrictcode": "941102",
            "subdistrictname": "ตะโละดือรามัน",
            "zipcode": "94230"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9411",
            "districtname": "กะพ้อ",
            "subdistrictcode": "941103",
            "subdistrictname": "ปล่องหอย",
            "zipcode": "94230"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9412",
            "districtname": "แม่ลาน",
            "subdistrictcode": "941201",
            "subdistrictname": "แม่ลาน",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9412",
            "districtname": "แม่ลาน",
            "subdistrictcode": "941202",
            "subdistrictname": "ม่วงเตี้ย",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ปัตตานี",
            "districtcode": "9412",
            "districtname": "แม่ลาน",
            "subdistrictcode": "941203",
            "subdistrictname": "ป่าไร่",
            "zipcode": "94180"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950101",
            "subdistrictname": "สะเตง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950102",
            "subdistrictname": "บุดี",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950103",
            "subdistrictname": "ยุโป",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950104",
            "subdistrictname": "ลิดล",
            "zipcode": "95160"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950106",
            "subdistrictname": "ยะลา",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950108",
            "subdistrictname": "ท่าสาป",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950109",
            "subdistrictname": "ลำใหม่",
            "zipcode": "95160"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950110",
            "subdistrictname": "หน้าถ้ำ",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950111",
            "subdistrictname": "ลำพะยา",
            "zipcode": "95160"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950112",
            "subdistrictname": "เปาะเส้ง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950114",
            "subdistrictname": "พร่อน",
            "zipcode": "95160"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950115",
            "subdistrictname": "บันนังสาเรง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950116",
            "subdistrictname": "สะเตงนอก",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9501",
            "districtname": "เมืองยะลา",
            "subdistrictcode": "950118",
            "subdistrictname": "ตาเซะ",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9502",
            "districtname": "เบตง",
            "subdistrictcode": "950201",
            "subdistrictname": "เบตง",
            "zipcode": "95110"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9502",
            "districtname": "เบตง",
            "subdistrictcode": "950202",
            "subdistrictname": "ยะรม",
            "zipcode": "95110"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9502",
            "districtname": "เบตง",
            "subdistrictcode": "950203",
            "subdistrictname": "ตาเนาะแมเราะ",
            "zipcode": "95110"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9502",
            "districtname": "เบตง",
            "subdistrictcode": "950204",
            "subdistrictname": "อัยเยอร์เวง",
            "zipcode": "95110"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9502",
            "districtname": "เบตง",
            "subdistrictcode": "950205",
            "subdistrictname": "ธารน้ำทิพย์",
            "zipcode": "95110"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950301",
            "subdistrictname": "บันนังสตา",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950302",
            "subdistrictname": "บาเจาะ",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950303",
            "subdistrictname": "ตาเนาะปูเต๊ะ",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950304",
            "subdistrictname": "ถ้ำทะลุ",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950305",
            "subdistrictname": "ตลิ่งชัน",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9503",
            "districtname": "บันนังสตา",
            "subdistrictcode": "950306",
            "subdistrictname": "เขื่อนบางลาง",
            "zipcode": "95130"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9504",
            "districtname": "ธารโต",
            "subdistrictcode": "950401",
            "subdistrictname": "ธารโต",
            "zipcode": "95150"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9504",
            "districtname": "ธารโต",
            "subdistrictcode": "950402",
            "subdistrictname": "บ้านแหร",
            "zipcode": "95150"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9504",
            "districtname": "ธารโต",
            "subdistrictcode": "950403",
            "subdistrictname": "แม่หวาด",
            "zipcode": "95170"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9504",
            "districtname": "ธารโต",
            "subdistrictcode": "950404",
            "subdistrictname": "คีรีเขต",
            "zipcode": "95150"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950501",
            "subdistrictname": "ยะหา",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950502",
            "subdistrictname": "ละแอ",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950503",
            "subdistrictname": "ปะแต",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950504",
            "subdistrictname": "บาโร๊ะ",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950506",
            "subdistrictname": "ตาชี",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950507",
            "subdistrictname": "บาโงยซิแน",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9505",
            "districtname": "ยะหา",
            "subdistrictcode": "950508",
            "subdistrictname": "กาตอง",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950601",
            "subdistrictname": "กายูบอเกาะ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950602",
            "subdistrictname": "กาลูปัง",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950603",
            "subdistrictname": "กาลอ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950604",
            "subdistrictname": "กอตอตือร๊ะ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950605",
            "subdistrictname": "โกตาบารู",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950606",
            "subdistrictname": "เกะรอ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950607",
            "subdistrictname": "จะกว๊ะ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950608",
            "subdistrictname": "ท่าธง",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950609",
            "subdistrictname": "เนินงาม",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950610",
            "subdistrictname": "บาลอ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950611",
            "subdistrictname": "บาโงย",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950612",
            "subdistrictname": "บือมัง",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950613",
            "subdistrictname": "ยะต๊ะ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950614",
            "subdistrictname": "วังพญา",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950615",
            "subdistrictname": "อาซ่อง",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9506",
            "districtname": "รามัน",
            "subdistrictcode": "950616",
            "subdistrictname": "ตะโล๊ะหะลอ",
            "zipcode": "95140"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9507",
            "districtname": "กาบัง",
            "subdistrictcode": "950701",
            "subdistrictname": "กาบัง",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9507",
            "districtname": "กาบัง",
            "subdistrictcode": "950702",
            "subdistrictname": "บาละ",
            "zipcode": "95120"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9508",
            "districtname": "กรงปินัง",
            "subdistrictcode": "950801",
            "subdistrictname": "กรงปินัง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9508",
            "districtname": "กรงปินัง",
            "subdistrictcode": "950802",
            "subdistrictname": "สะเอะ",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9508",
            "districtname": "กรงปินัง",
            "subdistrictcode": "950803",
            "subdistrictname": "ห้วยกระทิง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "ยะลา",
            "districtcode": "9508",
            "districtname": "กรงปินัง",
            "subdistrictcode": "950804",
            "subdistrictname": "ปุโรง",
            "zipcode": "95000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960101",
            "subdistrictname": "บางนาค",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960102",
            "subdistrictname": "ลำภู",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960103",
            "subdistrictname": "มะนังตายอ",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960104",
            "subdistrictname": "บางปอ",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960105",
            "subdistrictname": "กะลุวอ",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960106",
            "subdistrictname": "กะลุวอเหนือ",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9601",
            "districtname": "เมืองนราธิวาส",
            "subdistrictcode": "960107",
            "subdistrictname": "โคกเคียน",
            "zipcode": "96000"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960201",
            "subdistrictname": "เจ๊ะเห",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960202",
            "subdistrictname": "ไพรวัน",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960203",
            "subdistrictname": "พร่อน",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960204",
            "subdistrictname": "ศาลาใหม่",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960205",
            "subdistrictname": "บางขุนทอง",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960206",
            "subdistrictname": "เกาะสะท้อน",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960207",
            "subdistrictname": "นานาค",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9602",
            "districtname": "ตากใบ",
            "subdistrictcode": "960208",
            "subdistrictname": "โฆษิต",
            "zipcode": "96110"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960301",
            "subdistrictname": "บาเจาะ",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960302",
            "subdistrictname": "ลุโบะสาวอ",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960303",
            "subdistrictname": "กาเยาะมาตี",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960304",
            "subdistrictname": "ปะลุกาสาเมาะ",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960305",
            "subdistrictname": "บาเระเหนือ",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9603",
            "districtname": "บาเจาะ",
            "subdistrictcode": "960306",
            "subdistrictname": "บาเระใต้",
            "zipcode": "96170"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960401",
            "subdistrictname": "ยี่งอ",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960402",
            "subdistrictname": "ละหาร",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960403",
            "subdistrictname": "จอเบาะ",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960404",
            "subdistrictname": "ลุโบะบายะ",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960405",
            "subdistrictname": "ลุโบะบือซา",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9604",
            "districtname": "ยี่งอ",
            "subdistrictcode": "960406",
            "subdistrictname": "ตะปอเยาะ",
            "zipcode": "96180"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960501",
            "subdistrictname": "ตันหยงมัส",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960502",
            "subdistrictname": "ตันหยงลิมอ",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960506",
            "subdistrictname": "บองอ",
            "zipcode": "96220"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960507",
            "subdistrictname": "กาลิซา",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960508",
            "subdistrictname": "บาโงสะโต",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960509",
            "subdistrictname": "เฉลิม",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9605",
            "districtname": "ระแงะ",
            "subdistrictcode": "960510",
            "subdistrictname": "มะรือโบตก",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960601",
            "subdistrictname": "รือเสาะ",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960602",
            "subdistrictname": "สาวอ",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960603",
            "subdistrictname": "เรียง",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960604",
            "subdistrictname": "สามัคคี",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960605",
            "subdistrictname": "บาตง",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960606",
            "subdistrictname": "ลาโละ",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960607",
            "subdistrictname": "รือเสาะออก",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960608",
            "subdistrictname": "โคกสะตอ",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9606",
            "districtname": "รือเสาะ",
            "subdistrictcode": "960609",
            "subdistrictname": "สุวารี",
            "zipcode": "96150"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960701",
            "subdistrictname": "ซากอ",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960702",
            "subdistrictname": "ตะมะยูง",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960703",
            "subdistrictname": "ศรีสาคร",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960704",
            "subdistrictname": "เชิงคีรี",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960705",
            "subdistrictname": "กาหลง",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9607",
            "districtname": "ศรีสาคร",
            "subdistrictcode": "960706",
            "subdistrictname": "ศรีบรรพต",
            "zipcode": "96210"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960801",
            "subdistrictname": "แว้ง",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960802",
            "subdistrictname": "กายูคละ",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960803",
            "subdistrictname": "ฆอเลาะ",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960804",
            "subdistrictname": "โละจูด",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960805",
            "subdistrictname": "แม่ดง",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9608",
            "districtname": "แว้ง",
            "subdistrictcode": "960806",
            "subdistrictname": "เอราวัณ",
            "zipcode": "96160"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9609",
            "districtname": "สุคิริน",
            "subdistrictcode": "960901",
            "subdistrictname": "มาโมง",
            "zipcode": "96190"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9609",
            "districtname": "สุคิริน",
            "subdistrictcode": "960902",
            "subdistrictname": "สุคิริน",
            "zipcode": "96190"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9609",
            "districtname": "สุคิริน",
            "subdistrictcode": "960903",
            "subdistrictname": "เกียร์",
            "zipcode": "96190"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9609",
            "districtname": "สุคิริน",
            "subdistrictcode": "960904",
            "subdistrictname": "ภูเขาทอง",
            "zipcode": "96190"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9609",
            "districtname": "สุคิริน",
            "subdistrictcode": "960905",
            "subdistrictname": "ร่มไทร",
            "zipcode": "96190"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9610",
            "districtname": "สุไหงโก-ลก",
            "subdistrictcode": "961001",
            "subdistrictname": "สุไหงโก-ลก",
            "zipcode": "96120"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9610",
            "districtname": "สุไหงโก-ลก",
            "subdistrictcode": "961002",
            "subdistrictname": "ปาเสมัส",
            "zipcode": "96120"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9610",
            "districtname": "สุไหงโก-ลก",
            "subdistrictcode": "961003",
            "subdistrictname": "มูโนะ",
            "zipcode": "96120"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9610",
            "districtname": "สุไหงโก-ลก",
            "subdistrictcode": "961004",
            "subdistrictname": "ปูโยะ",
            "zipcode": "96120"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961101",
            "subdistrictname": "ปะลุรู",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961102",
            "subdistrictname": "สุไหงปาดี",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961103",
            "subdistrictname": "โต๊ะเด็ง",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961104",
            "subdistrictname": "สากอ",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961105",
            "subdistrictname": "ริโก๋",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9611",
            "districtname": "สุไหงปาดี",
            "subdistrictcode": "961106",
            "subdistrictname": "กาวะ",
            "zipcode": "96140"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9612",
            "districtname": "จะแนะ",
            "subdistrictcode": "961201",
            "subdistrictname": "จะแนะ",
            "zipcode": "96220"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9612",
            "districtname": "จะแนะ",
            "subdistrictcode": "961202",
            "subdistrictname": "ดุซงญอ",
            "zipcode": "96220"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9612",
            "districtname": "จะแนะ",
            "subdistrictcode": "961203",
            "subdistrictname": "ผดุงมาตร",
            "zipcode": "96220"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9612",
            "districtname": "จะแนะ",
            "subdistrictcode": "961204",
            "subdistrictname": "ช้างเผือก",
            "zipcode": "96220"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9613",
            "districtname": "เจาะไอร้อง",
            "subdistrictcode": "961301",
            "subdistrictname": "จวบ",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9613",
            "districtname": "เจาะไอร้อง",
            "subdistrictcode": "961302",
            "subdistrictname": "บูกิต",
            "zipcode": "96130"
        },
        {
            "isActive": true,
            "provincecode": "นราธิวาส",
            "districtcode": "9613",
            "districtname": "เจาะไอร้อง",
            "subdistrictcode": "961303",
            "subdistrictname": "มะรือโบออก",
            "zipcode": "96130"
        }
    ];

    @ViewChild("save", { static: true }) pRef: QRCodeComponent;
    constructor(public modalRef: MDBModalRef, private numberService: NumberService, private formBuilder: FormBuilder, private config: NgSelectConfig) {
        this.config.notFoundText = 'ไม่พบข้อมูล';
        this.selectNumber = this.numberService.selectNumber;
        // this.simprice = this.numberService.price;
        if (parseFloat(this.numberService.price) === parseInt(this.numberService.price)) {
            this.simprice = parseInt(this.numberService.price).toString();
        } else {
            this.simprice = this.numberService.price;
        }
        this.submitForm = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            contactnumber: ['', Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)],
            email: ['', Validators.email],
            idradio: ['idcard', Validators.required],
            idcard: ['', Validators.required],
            passport: ['', Validators.required],
            dob: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
            housenumber: ['', Validators.required],
            housename: [''],
            province: [null, Validators.required],
            district: [{ value: null, disabled: true }, Validators.required],
            subdistrict: [{ value: null, disabled: true }, Validators.required],
            zipcode: [null, Validators.required],
        });
    }

    ngOnInit(): void {
    }

    get f() { return this.submitForm.controls; }

    bookNumber() {
        this.loading = true;
        let phonenumber = '66' + this.numberService.selectNumber.slice(1);
        this.numberService.bookNumber(phonenumber).subscribe((res: any) => {
            this.loading = false;
            let resp = JSON.parse(res);
            if (resp.responsecode === 101) {
                this.codeRes = 101;
                this.errorBook = true;
            } else if (resp.responsecode === 102) {
                this.errorBook = true;
            } else {
                this.errorBook = false;
                this.btntext = 'ถัดไป';
                this.page = 'personal';
            }
        })
    }

    keyPressNumber(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    updateDistrict($event) {
        this.Selecteddistrict = [];
        this.submitForm.patchValue({ "district": null, "subdistrict": null, "zipcode": null });
        this.thisindex = 0;
        for (const prop in this.District) {
            if (this.District[prop]['provincecode'] === $event['provincecode']) {
                this.Selecteddistrict[this.thisindex] = this.District[prop];
                this.thisindex += 1;
            }
        }
        if ($event['provincename'] === 'กรุงเทพมหานคร') {
            this.districttitle = 'เขต';
            this.subdistricttitle = 'แขวง';
        } else {
            this.districttitle = 'อำเภอ';
            this.subdistricttitle = 'ตำบล';
        }
        if (this.submitForm.controls.district.disabled) {
            this.submitForm.controls.district.enable();
        } else {
            this.submitForm.controls.district.disable();
        }
    }

    updateSubDistrict($event) {
        this.Selectedsubdistrict = [];
        this.submitForm.patchValue({ "subdistrict": null, "zipcode": null });
        this.thisindex = 0;
        for (const prop in this.Subdistrict) {
            if (this.Subdistrict[prop]['districtcode'] === $event['districtcode']) {
                this.Selectedsubdistrict[this.thisindex] = this.Subdistrict[prop];
                this.thisindex += 1;
            }
        }
        if (this.submitForm.controls.subdistrict.disabled) {
            this.submitForm.controls.subdistrict.enable();
        } else {
            this.submitForm.controls.subdistrict.disable();
        }
    }

    updateZipcode($event) {
        this.submitForm.patchValue({ "zipcode": $event['zipcode'] });
    }

    onClose() {
        this.modalRef.hide();
    }

    editPage() {
        this.addressdetail = '';
        this.btntext = 'ถัดไป';
        this.page = 'personal';
    }

    onSent() {
        if (this.page === 'summary') {
            this.bookNumber();
        } else if (this.page === 'personal') {
            if (this.submitForm.get('firstname').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่ชื่อให้ถูกต้อง';
            } else if (this.submitForm.get('lastname').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่นามสกุลให้ถูกต้อง';
            } else if (this.submitForm.get('contactnumber').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่เบอร์ติดต่อให้ถูกต้อง';
            } else if (this.submitForm.get('dob').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่วันเดือนปีเกิดให้ถูกต้อง';
            } else {
                if (this.submitForm.value.idradio === 'idcard') {
                    if (this.submitForm.get('idcard').invalid) {
                        this.errorTextCheck = true;
                        this.errorText = 'กรุณาใส่เลขบัตรประชาชนให้ถูกต้อง';
                    } else {
                        this.numberService.firstname = this.submitForm.value.firstname;
                        this.numberService.lastname = this.submitForm.value.lastname;
                        this.numberService.contactnumber = this.submitForm.value.contactnumber;
                        this.numberService.email = this.submitForm.value.email;
                        this.numberService.personaltype = 'idcard';
                        this.numberService.personalid = this.submitForm.value.idcard;
                        this.numberService.birthday = this.submitForm.value.dob;
                        this.errorTextCheck = false;
                        this.btntext = 'ถัดไป';
                        this.page = 'address';
                    }
                } else {
                    if (this.submitForm.get('passport').invalid) {
                        this.errorTextCheck = true;
                        this.errorText = 'กรุณาใส่เลขหนังสือเดินทางให้ถูกต้อง';
                    } else {
                        this.numberService.firstname = this.submitForm.value.firstname;
                        this.numberService.lastname = this.submitForm.value.lastname;
                        this.numberService.contactnumber = this.submitForm.value.contactnumber;
                        this.numberService.email = this.submitForm.value.email;
                        this.numberService.personaltype = 'passport';
                        this.numberService.personalid = this.submitForm.value.passport;
                        this.numberService.birthday = this.submitForm.value.dob;
                        this.errorTextCheck = false;
                        this.btntext = 'ถัดไป';
                        this.page = 'address';
                    }
                }
            }
        } else if (this.page === 'address') {
            if (this.submitForm.get('housenumber').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่บ้านเลขที่หรือถนนให้ถูกต้อง';
            } else if (this.submitForm.get('province').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาเลือกจังหวัดให้ถูกต้อง';
            } else if (this.submitForm.get('district').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาเลือกเขตหรืออำเภอให้ถูกต้อง';
            } else if (this.submitForm.get('subdistrict').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาเลือกแขวงหรือตำบลให้ถูกต้อง';
            } else if (this.submitForm.get('zipcode').invalid) {
                this.errorTextCheck = true;
                this.errorText = 'กรุณาใส่รหัสไปรษณีย์ให้ถูกต้อง';
            } else {
                this.numberService.housenumber = this.submitForm.value.housenumber;
                this.numberService.housename = this.submitForm.value.housename;
                this.numberService.province = this.submitForm.value.province.provincename;
                this.numberService.district = this.submitForm.value.district.districtname;
                this.numberService.subdistrict = this.submitForm.value.subdistrict.subdistrictname;
                this.numberService.zipcode = this.submitForm.value.zipcode;
                this.errorTextCheck = false;
                this.btntext = 'ถัดไป';
                this.page = 'payment';
            }
        } else if (this.page === 'payment') {
            console.log(this.submitForm.value);
            this.firstnamedetail = this.numberService.firstname;
            this.lastnamedetail = this.numberService.lastname;
            this.addressdetail += this.numberService.housenumber + ' ';
            if (this.numberService.housename !== null) {
                this.addressdetail += this.numberService.housename;
            }
            this.addressdetail += ', ' + this.numberService.subdistrict;
            this.addressdetail += ', ' + this.numberService.district;
            this.addressdetail += ', ' + this.numberService.province;
            this.addressdetail += ', ' + this.numberService.zipcode;
            this.contactnumberdetail = this.numberService.contactnumber;
            if (parseFloat(this.numberService.price) === parseInt(this.numberService.price)) {
                this.pricedetail = parseInt(this.numberService.price).toString();
            } else {
                this.pricedetail = this.numberService.price;
            }
            if (parseFloat(this.numberService.originprice) === parseInt(this.numberService.originprice)) {
                this.originpricedetail = parseInt(this.numberService.originprice).toString();
            } else {
                this.originpricedetail = this.numberService.originprice;
            }
            if (parseFloat(this.numberService.taxprice) === parseInt(this.numberService.taxprice)) {
                this.taxpricedetail = parseInt(this.numberService.taxprice).toString();
            } else {
                this.taxpricedetail = this.numberService.taxprice;
            }
            // this.pricedetail = this.numberService.price;
            // this.originpricedetail = this.numberService.originprice;
            // this.taxpricedetail = this.numberService.taxprice;
            this.btntext = 'สั่งซื้อ';
            this.page = 'orderdetail';
        } else if (this.page === 'orderdetail') {
            let jsonparam = {
                'productid': this.numberService.productid,
                'paymentchannel': this.myRadio,
                'firstname': this.numberService.firstname,
                'lastname': this.numberService.lastname,
                'contactnumber': this.numberService.contactnumber,
                'birthday': this.numberService.birthday,
                'personaltype': this.numberService.personaltype === 'idcard' ? 'C' : 'P',
                'personalid': this.numberService.personalid,
                'housenumber': this.numberService.housenumber,
                'province': this.numberService.province,
                'district': this.numberService.district,
                'subdistrict': this.numberService.subdistrict,
                'zipcode': this.numberService.zipcode
            };
            if (this.numberService.email !== '') {
                jsonparam['email'] = this.numberService.email;
            }
            if (this.numberService.housename !== '') {
                jsonparam['housename'] = this.numberService.housename;
            }
            this.numberService.orderNumber(jsonparam).subscribe((res: any) => {
                console.log(res);
                let jsonorderres = JSON.parse(res);
                this.orderres = jsonorderres.responsecode;
                console.log(this.orderres)
                if (this.orderres === 0) {
                    this.qrClass = ''
                    this.spinner = ' d-none'
                    if (this.myRadio === 'simcard') {
                        this.successPage = true
                    } else {
                        this.qrcode = jsonorderres.qrcode
                    }
                } else {
                    this.spinner = ' d-none'
                    this.errorQR = true
                    this.errorQrText = jsonorderres.messages
                    // if (res['responsecode'] === 102) {
                    //     this.lowBalance = res['maximum'].split(".")[0]
                    // }
                }
            });
            this.btntext = 'ตกลง';
            this.page = 'success';
        } else if (this.page === 'editpage') {
            this.btntext = 'ตกลง';
            this.page = 'editpage2';
        } else if (this.page === 'editpage2') {
            this.btntext = 'สั่งซื้อ';
            this.page = 'orderdetail';
        } else {
            this.btntext = 'ตกลง';
            this.modalRef.hide();
            window.location.reload();
        }
    }

    radio(event) {
        const inputValue = event.target.value;
        this.myRadio = inputValue;
        // console.log(inputValue);
    }

    onClick() {
        this.link = this.pRef.qrcElement.nativeElement.firstChild.src;
    }

    onCommingSoon() {
        window.open('https://line.me/R/ti/p/%40847vbleb');
    }

}
