import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterService } from '../../services/register.service';

@Component({
    selector: 'app-portin',
    templateUrl: './portin.component.html',
    styleUrls: ['./portin.component.scss']
})
export class PortinComponent implements OnInit {
    page: string = 'intro';
    loading: boolean = false;

    constructor(private register: RegisterService) {
        this.register.pageMove.subscribe((value) => {
            if (value !== undefined) {
                this.page = value
            }
        });
        this.register.loading.subscribe((value) => {
            if (value !== undefined) {
                this.loading = value;
            }
        });
    }

    ngOnInit(): void {
    }

    next() {
        this.page = 'agreement';
    }
}
