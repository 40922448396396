import { Component, OnInit } from '@angular/core';
import { OnlinesimService } from '../../../services/onlinesim.service';

@Component({
    selector: 'app-selectpackage',
    templateUrl: './selectpackage.component.html',
    styleUrls: ['./selectpackage.component.scss']
})
export class SelectpackageComponent implements OnInit {
    packagelist: Array<object>;
    packageloading: boolean = true;
    selectedpackage: string = 'null';

    constructor(private onlinesimService: OnlinesimService) {
        // console.log(this.onlinesimService.summarydetail);
        // console.log(this.onlinesimService.numberproductid);
        // console.log(this.onlinesimService.numberoriginprice);
        // console.log(this.onlinesimService.numbertaxprice);
        this.onlinesimService.getPackage().subscribe(
            (res: any) => {
                console.log(res);
                this.packageloading = false;
                this.packagelist = Object.values(JSON.parse(res + '')['packagelist']);
            },
            (err: any) => { }
        );
    }

    ngOnInit(): void {
    }

    onSelect(selected: Object) {
        // this.onlinesimService.summarydetail = this.onlinesimService.summarydetail.filter(items => items.item !== 'แพ็กเกจ');
        this.selectedpackage = selected['packagecode'];
        this.onlinesimService.selectedPackageObject = selected;
        this.onlinesimService.selectedPackagePrice = selected['totalprice'];
        this.onlinesimService.selectedPackageCode = selected['packagecode'];
        this.onlinesimService.selectedPackageProductid = selected['productid'];
        this.onlinesimService.selectedPackageOriginPrice = selected['price'];
        // this.onlinesimService.price += +selected['price'];
        // if (selected['packagecode']) {
        //     let detail = {
        //         'item': 'แพ็กเกจ',
        //         'amount': '1',
        //         'price': selected['price']
        //     }
        //     this.onlinesimService.summarydetail.push(detail);
        // }
        this.onlinesimService.package.next(selected['productname']);
    }

    onSelectNull() {
        // this.onlinesimService.summarydetail = this.onlinesimService.summarydetail.filter(items => items.item !== 'แพ็กเกจ');
        this.onlinesimService.selectedPackageObject = null;
        this.onlinesimService.selectedPackagePrice = null;
        this.onlinesimService.selectedPackageCode = null;
        this.onlinesimService.selectedPackageProductid = null;
        this.onlinesimService.selectedPackageOriginPrice = null;
        this.selectedpackage = 'null';
        this.onlinesimService.package.next('-');
    }

}
