import { Component, OnInit } from '@angular/core';
import { PackageService } from 'src/app/services/package.service';
import { TopupService } from 'src/app/services/topup.service';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalpaytopupComponent } from './modalpaytopup/modalpaytopup.component';

@Component({
    selector: 'app-topup',
    templateUrl: './topup.component.html',
    styleUrls: [ './topup.component.scss' ]
})
export class TopupComponent implements OnInit {
    public money: number[] = [ 10, 30, 50, 100, 200, 500, 800, 1000, 2000 ];
    public circleProcess: number = 0;
    topupMethod: string = 'topupamount';
    public totalPrice: number = 0;
    public textCircle: string = '0 บาท';
    public colorCircle: string = '#e3f20f';
    public myRadio: string = 'alipay';
    public packagecode: string = '1140';
    priceError: boolean = false;
    pricelessError: boolean = false;
    modalRef: MDBModalRef;

    constructor(
        private topupService: TopupService,
        private modalService: MDBModalService,
        private pack: PackageService,
        private _router: Router
    ) {}

    ngOnInit(): void {}

    public greater(num: number, num2: number) {
        return num > num2;
    }

    public lower(num: number, num2: number) {
        return num < num2;
    }

    public slides: Array<Object> = [
        { img: '../../../assets/img/bg/topup.png' },
        { img: '../../../assets/img/bg/topup2.png' }
    ];

    onKey(event, id: number) {
        const inputValue = event.target.value;
        this.circleProcess = inputValue * 100 / 2000;
        this.textCircle = inputValue + 'บาท';
        this.totalPrice = inputValue;
        if (id === 1) {
            this.topupMethod = 'topup';
        } else {
            this.topupMethod = 'topupamount';
        }
        if (inputValue < 10) {
            this.pricelessError = true;
        } else {
            this.pricelessError = false;
            this.priceError = false;
        }
    }

    topUp() {
        var payment: string = this.myRadio;
        var phonenumber: string = document.getElementById('phonenumber')['value'];
        this.pack
            .payment(phonenumber, this.totalPrice + '', payment, this.packagecode)
            .subscribe((res: any) => [
                (this.pack.paymentchannel = payment),
                (this.pack.urlpayment = JSON.parse(res)['qrcode']),
                this._router.navigate([ '/', 'qrpayment' ])
            ]);
    }

    onSent() {
        if (this.totalPrice >= 10) {
            this.topupService.selectPrice = this.totalPrice;
            this.topupService.topupMethod = this.topupMethod;
            this.priceError = false;
            this.pricelessError = false;
            this.modalRef = this.modalService.show(ModalpaytopupComponent, { class: 'modal-dialog-centered' });
        } else {
            if (this.totalPrice < 10) {
                this.pricelessError = true;
            } else {
                this.priceError = true;
            }
        }
    }

    radio(event) {
        const inputValue = event.target.value;
        this.myRadio = inputValue;
    }
}
