<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/main-banner-package.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/main-banner-package.png">
    </div>
    <div class="row m-0 justify-content-center">
        <div class="mt-3 col-12 col-lg-10 p-0">
            <div class="pt-3 d-none" *ngIf="btnroute">
                <a class="promotion-container row m-0 p-3 rounded-lg cursor-on shadow" [routerLink]="['/onlinesim']">
                    <img class="w-100 hm-100" src="../../../assets/img/banner/selected-package-20-11-18.jpg">
                </a>
            </div>
            <div *ngFor="let item of packagesres; let i = index" class="pt-3">
                <div (click)="onSelect(item)"
                    class="promotion-container row m-0 p-3 p-md-5 rounded-lg cursor-on shadow">
                    <div class="ribbon-wrapper-red" *ngIf="item.is_promotion === '1'">
                        <div class="ribbon-red">โปรโมชั่น</div>
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <h1 class="text-light-green-main fs-30 mb-1">
                            {{item.duration}}
                            <span class="elegant-color-text">วัน</span>
                        </h1>
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <!-- <div
                            *ngIf="item.packagetype === 'VOICE' && item.packagespeedamount === '0' && item.packagedataamount === '0'">
                            <h3 class="elegant-color-text mb-1 fs-30">-</h3>
                        </div> -->
                        <div *ngIf="item.packagetype !== 'VOICE'">
                            <h3 class="text-light-green-main mb-1 fs-30" *ngIf="item.dataspeedtype === 'FullSpeed'">
                                เต็มสปีด
                            </h3>
                            <h3 class="text-light-green-main mb-1 fs-30" *ngIf="item.dataspeedtype !== 'FullSpeed'">
                                {{item.dataspeedamount | number}}
                                <span class="elegant-color-text"> {{item.dataspeedunit}}</span>
                            </h3>
                            <small class="respo-text-phone mt-2">ใช้เน็ตได้
                                <span class="text-light-green-main fs-20"
                                    *ngIf="item.datatype === 'Unlimited'">ไม่จำกัด</span>
                                <span class="text-light-green-main fs-20" *ngIf="item.datatype !== 'Unlimited'">
                                    {{item.datavolumn | number}}
                                    <span class="elegant-color-text"> {{item.dataunit}}</span>
                                </span>
                            </small>
                        </div>
                        <small class="respo-text-phone mt-2" *ngIf="item.voiceamount !== '0.00'">โทรได้
                            <span class="text-light-green-main fs-20">
                                {{item.voiceamount | number}}
                                <span class="elegant-color-text"> นาที</span>
                            </span>
                            <!-- <span class="text-light-green-main fs-20" *ngIf="item.datatype !== 'Unlimited'">
                                {{item.datavolumn | number}}
                                <span class="elegant-color-text"> {{item.dataunit}}</span>
                            </span> -->
                        </small>
                        <!-- <small class="text-light-green-main mb-1 fs-20" *ngIf="item.voiceamount !== '0'">
                            {{item.voiceamount | number}}
                            <span class="elegant-color-text"> นาที</span>
                        </small>
                        <small class="text-light-green-main mb-1 fs-20"
                            *ngIf="item.voiceamount === '0' && (item.packageonnetfreecall !== '0' || item.packageoffnetfreecall !== '0')">
                            โทรฟรีไม่จำกัด
                        </small>
                        <small class="respo-text-phone mt-2 fs-20"
                            *ngIf="item.voiceamount === '0' && item.packageonnetfreecall !== '0'">
                            โทรฟรีในเครือข่าย ครั้งละ 60 นาที
                        </small>
                        <small class="respo-text-phone mt-2 fs-20"
                            *ngIf="item.voiceamount === '0' && item.packageoffnetfreecall !== '0'">
                            โทรฟรีทุกเครือข่าย ครั้งละ 60 นาที
                        </small> -->
                        <!-- <h1 class="text-light-green-main mb-1 fs-30" *ngIf="item.dataspeedtype !== 'FullSpeed'">
                            {{item.dataspeedamount.split('.')[0]}}
                            <span class="elegant-color-text"> {{item.dataspeedunit}}</span>
                        </h1>
                        <h1 class="text-light-green-main mb-1 fs-30" *ngIf="item.dataspeedtype === 'FullSpeed'">
                            เต็มสปีด
                        </h1>
                        <small class="respo-text-phone mt-2">ใช้เน็ตได้
                            <span class="text-light-green-main fs-20"
                                *ngIf="item.datatype === 'Unlimited'">ไม่จำกัด</span>
                            <span class="text-light-green-main fs-20" *ngIf="item.datatype !== 'Unlimited'">
                                {{item.datavolumn.split('.')[0]}}
                                <span class="elegant-color-text"> {{item.dataunit}}</span>
                            </span>
                        </small> -->
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <h1 class="text-light-green-main mb-0 fs-30">
                            <span class="text-light-green-main" *ngIf="item.display_price === 'SRP'">
                                {{item.price.split('.')[0]}}
                            </span>
                            <span class="text-light-green-main" *ngIf="item.display_price === 'SRP+VAT'">
                                {{item.total_net.split('.')[0]}}
                            </span>
                            <span class="elegant-color-text">บาท</span>
                        </h1>
                        <p class="fs-12 m-0" *ngIf="item.display_price === 'SRP'">
                            ราคายังไม่รวมภาษีมูลค่าเพิ่ม</p>
                        <p class="fs-12 m-0" *ngIf="item.display_price === 'SRP+VAT'">
                            ราคารวมภาษีมูลค่าเพิ่ม</p>
                    </div>
                    <div class="col-3 d-none text-center d-lg-block my-auto">
                        <a class="text-light-green-main fs-20" *ngIf="item.ussdcode">
                            <span class="elegant-color-text">
                                สมัครกด
                            </span>
                            {{item.ussdcode}}
                        </a><br *ngIf="item.ussdcode">
                        <a class="fs-20" *ngIf="item.ussdcode">
                            หรือ <u>คลิกที่นี่</u>
                        </a>
                        <a class="text-light-green-main fs-20" *ngIf="!item.ussdcode">
                            <u>คลิกที่นี่</u>
                        </a>
                        <div class="col-12 d-none text-center d-lg-block mt-2" *ngIf="item.is_promotion === '1'">
                            สมัครได้ถึงวันที่ {{item.end_display}}
                        </div>
                    </div>
                    <div class="col-12 d-block d-lg-none">
                        <div class="row w-100 m-0">
                            <div class="col-6">
                                <h1 class="m-0" *ngIf="item.dataspeedtype !== 'FullSpeed'">ความเร็ว<br>
                                    <span class="text-light-green-main">{{item.dataspeedamount.split('.')[0]}}</span>
                                    {{item.dataspeedunit}}
                                </h1>
                                <h1 class="m-0" *ngIf="item.dataspeedtype === 'FullSpeed'">ความเร็ว<br>
                                    <span class="text-light-green-main">เต็มสปีด</span>
                                </h1>
                                <small class="respo-text-phone mt-1">
                                    ใช้เน็ตได้
                                    <span class="text-light-green-main fs-20"
                                        *ngIf="item.datatype === 'Unlimited'">ไม่จำกัด</span>
                                    <span class="text-light-green-main fs-20" *ngIf="item.datatype !== 'Unlimited'">
                                        {{item.datavolumn.split('.')[0]}}
                                        <span class="elegant-color-text"> {{item.dataunit}}</span>
                                    </span>
                                </small>
                                <p class="mt-1 text-light-green-main fs-20 mb-1" *ngIf="item.ussdcode">
                                    <small class="elegant-color-text">สมัครกด</small>
                                    {{item.ussdcode}}
                                </p>
                                <h3 class="mb-1" *ngIf="item.ussdcode">
                                    <small class="text-light-green-main">หรือ</small>
                                    <u>คลิกที่นี่</u>
                                </h3>
                                <h3 class="text-light-green-main mb-1" *ngIf="!item.ussdcode">
                                    <u>คลิกที่นี่</u>
                                </h3>
                            </div>
                            <div class="col-6 row m-0 align-items-center justify-content-end text-light-green-main">
                                <div class="col-12 p-0 text-right">
                                    <h1 class="fs-30 m-0">{{item.duration}}<small
                                            class="elegant-color-text fs-20 ml-1">วัน</small>
                                    </h1>
                                </div>
                                <div class="col-12 p-0 text-right">
                                    <h1 class="fs-50 m-0">
                                        <span *ngIf="item.display_price === 'SRP'">{{item.price.split('.')[0]}}</span>
                                        <span
                                            *ngIf="item.display_price === 'SRP+VAT'">{{item.total_net.split('.')[0]}}</span>
                                        <small class="elegant-color-text fs-20 ml-1">บาท</small>
                                    </h1>
                                    <small class="elegant-color-text"
                                        *ngIf="item.display_price === 'SRP'">ราคายังไม่รวมภาษีมูลค่าเพิ่ม</small>
                                    <small class="elegant-color-text"
                                        *ngIf="item.display_price === 'SRP+VAT'">ราคารวมภาษีมูลค่าเพิ่ม</small>
                                </div>
                            </div>
                            <div class="col-12 d-block text-right d-lg-none my-auto" *ngIf="item.is_promotion === '1'">
                                สมัครได้ถึงวันที่ {{item.end_display}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ad-ondemand col-12 col-lg-10 mt-3 rounded-lg bg-soft-green">
            ไม่เห็นแพ็กเกจที่ตรงใจคุณใช่ไหม
            <div class="respo-ad"><br></div>
            <button mdbBtn type="button" class="btn-outline-white text-white" [routerLink]="['/ondemandpackage']"
                mdbWavesEffect>เลือกแพ็กเกจตามใจคุณ</button>
        </div>
    </div>
</main>