<main>
    <div class="w-100 d-none d-lg-block">
        <img class="w-100" src="../../../assets/img/banner/store-2-16.png">
    </div>
    <div class="w-100 d-block d-lg-none">
        <img class="w-100" src="../../../assets/img/banner/mobile/Artboard 30 copy 2.png">
    </div>

    <div class="row justify-content-center w-100 m-0" *ngIf="page==='intro'">
        <div class="col-12 col-sm-10 col-md-8 white rounded-top-3 mt-3 mb-3 p-5">
            <div class="mb-3">
                <h1 class="fs-50 line-h-100 text-center text-light-green-main">ย้ายค่ายออนไลน์</h1>
            </div>
            <div class="text-center">
                <p class="font-size-18 text-teal" style="font-size: 18px; color: teal;">
                    การลงทะเบียนซิมไม่ใช่เรื่องที่ยุ่งยากอีกต่อไป<br>
                    ด้วยบริการลงทะเบียนซิมออนไลน์<br>
                </p>
            </div>
            <hr>
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-01.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">1. กรอกเบอร์ที่ต้องการย้ายค่าย</h2>
                        <p>กรอกเบอร์ที่ต้องการย้ายค่ายและรหัสย้ายค่าย</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-02.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">2. กรอกข้อมูลส่วนตัว</h2>
                        <p>กรอกข้อมูลส่วนตัวของท่าน เช่น ที่อยู่ เลขบัตรประชาชน รูปหน้าตรง</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100 m-0">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-03.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">3. ที่อยู่จัดส่งซิมใหม่</h2>
                        <p>กรอกข้อมูลที่อยู่ที่ท่านต้องการให้จัดส่งซิมการ์ดไป</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 p-3">
                    <div class="row justify-content-center w-100">
                        <img class="wh-500px" src="../../../assets/img/info/movetofeels/infowebfeels-04.svg">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                        <h2 class="text-light-green-main">4. ย้ายค่ายเสร็จแล้ว</h2>
                        <p>ย้ายค่ายสำเร็จแล้ว เริ่มใช้งานซิม Feels ได้ทันที</p>
                    </div>
                </div>
                <div class="col-12 text-center p-3">
                    <button mdbBtn type="button" (click)="next()" class="rounded-pill bg-soft-green text-white"
                        mdbWavesEffect>เริ่มเลย!</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row w-100 m-0" *ngIf="page!=='intro'">
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="page-body white rounded-top-3 col-12 col-lg-8 mt-3 mb-3 p-3">
                    <div class="row justify-content-center p-3 mb-3">
                        <img class="col-md-3 col-5" src="assets\img\logo\logo-1.svg">
                    </div>
                    <div class="mb-3">
                        <h1 class="fs-50 line-h-100 text-center text-light-green-main">ย้ายค่ายออนไลน์</h1>
                    </div>
                    <div class="text-center">
                        <p class="font-size-18 text-teal" style="font-size: 18px; color: teal;">
                            การลงทะเบียนซิมไม่ใช่เรื่องที่ยุ่งยากอีกต่อไป<br>
                            ด้วยบริการลงทะเบียนซิมออนไลน์<br>
                        </p>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <div class="progressbar-wrapper">
                                <ul class="progressbar-min" *ngIf="page==='agreement'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">เงื่อนไข</div>
                                            การใช้งานระบบย้ายค่ายออนไลน์
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='blanksim'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ตรวจสอบ</div>
                                            ซิมการ์ด
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='personalimg'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ตรวจสอบ</div>
                                            บัตรประจำตัวประชาชน
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='personalinfo'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ตรวจสอบ</div>
                                            ข้อมูลส่วนตัว
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-step" *ngIf="page==='customerimg'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ตรวจสอบ</div>
                                            อัตลักษณ์
                                        </span>
                                    </li>
                                </ul>
                                <ul class="progressbar-max"
                                    *ngIf="page==='done' || page==='error' || page==='doneterm'">
                                    <li class="mb-3">
                                        <span>
                                            <div class="step-title">ผลลัพธ์</div>
                                            การย้ายค่าย
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <app-portinagreement class="col-12" *ngIf="page==='agreement'"></app-portinagreement>
                        <app-portinblanksim class="col-12" *ngIf="page==='blanksim'"></app-portinblanksim>
                        <app-portinpersonalimg class="col-12" *ngIf="page==='personalimg'"></app-portinpersonalimg>
                        <app-portinpersonalinfo class="col-12" *ngIf="page==='personalinfo'"></app-portinpersonalinfo>
                        <app-portincusimg class="col-12" *ngIf="page==='customerimg'"></app-portincusimg>
                        <!-- <app-agreement class="col-12" *ngIf="page==='agreement'"></app-agreement>
                        <app-simcard class="col-12" *ngIf="page==='simcard'"></app-simcard>
                        <app-detail class="col-12" *ngIf="page==='detail'"></app-detail>
                        <app-personalinfo class="col-12" *ngIf="page==='personalinfo'"></app-personalinfo>
                        <app-personalimage class="col-12" *ngIf="page==='personalimage'"></app-personalimage>
                        <app-termpage class="col-12" *ngIf="page==='term'"></app-termpage> -->
                        <main class="bg-white col-12 p-0" *ngIf="page==='done'">
                            <div class="row justify-content-center">
                                <div class="col-8 p-0">
                                    <div class="col-12 p-0">
                                        <div class="text-center">
                                            <img src="../../../assets/img/info/register/success.svg">
                                            <h2 class="mt-3">ลงทะเบียนสำเร็จ</h2>
                                            <p>ขอบคุณที่ไว้วางใจ Feels</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-center p-3">
                                    <!-- <button mdbBtn type="button" (click)="onDone()" -->
                                    <button mdbBtn type="button" class="rounded-pill bg-soft-green text-white"
                                        mdbWavesEffect>แพ็กเกจ</button>
                                </div>
                            </div>
                        </main>
                        <!-- <main class="bg-white col-12 p-0" *ngIf="page==='doneterm'">
                            <div class="row justify-content-center">
                                <div class="col-8 p-0">
                                    <div class="col-12 p-0">
                                        <div class="text-center">
                                            <img src="../../../assets/img/info/register/pending.svg">
                                            <h2 class="mt-3">เจ้าหน้าที่กำลังตรวจสอบข้อมูลของคุณ</h2>
                                            <p>ขอบคุณที่ไว้วางใจ Feels</p>
                                            <p>เจ้าหน้าที่ของเราได้รับข้อมูลของท่านแล้ว</p>
                                            <p>เราจะดำเนินการเปิดซิมของคุณภายใน 1 วันทำการ</p>
                                            <p>หากดำเนินการเรียบร้อยแล้วจะมีการติดต่อไป หรือหากสงสัยสามารถสอบถามได้ที่
                                                <a href="https://lin.ee/mG3Fm30" target="_blank">
                                                    <i class="fab fa-line"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-center p-3">
                                    <button mdbBtn type="button" (click)="onDone()"
                                        class="rounded-pill bg-soft-green text-white" mdbWavesEffect>แพ็กเกจ</button>
                                </div>
                            </div>
                        </main> -->
                        <!-- <main class="bg-white col-12 p-0" *ngIf="page==='error'">
                            <div class="row justify-content-center">
                                <div class="col-8 p-0">
                                    <div class="col-12 p-0">
                                        <div class="text-center">
                                            <img src="../../../assets/img/info/register/error.svg">
                                            <h2 class="mt-3">ลงทะเบียนไม่สำเร็จ</h2>
                                            <p>{{ errormessage }}</p>
                                            <p>
                                                กรุณาติดต่อเจ้าหน้าที่
                                                <a href="https://lin.ee/mG3Fm30" target="_blank">
                                                    <i class="fab fa-line"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<div class="container-fluid" *ngIf="loading">
    <div class="row justify-content-center align-items-center overlay" id="overlay">
        <div class="col col-sm-6 col-lg-12 h-100 p-0">
            <div class="bounce col-12">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <h1 class="col mt-5 loading-txt">กรุณารอสักครู่</h1>
        </div>
    </div>
</div>