<main class="bg-white">
    <div class="row justify-content-center">
        <div class="col-10 col-lg-8 pt-4 mb-3">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="text-center rounded bg-soft-green p-1">
                        <p class="text-white m-0">ภาพคิวอาร์โค้ต</p>
                        <div>
                            <qrcode [qrdata]="qrcode" [width]="qrsize" [errorCorrectionLevel]="'M'">
                            </qrcode>
                        </div>
                        <!-- <div [className]="'justify-content-center wh-200 align-items-center'+spinner">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> -->
                        <a [href]="link" download class="text-white" (click)="download()">บันทึกภาพ</a>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3 text-center">
                <h2>คำสั่งซื้อ {{ordernumber}}</h2>
                <p>กรุณาบันทึกภาพคิวอาร์โค้ตและทำการชำระเงินที่แอพ
                    <span *ngIf="paymentchannel === 'thaiqr' || paymentchannel === 'creditcard'">ธนาคาร</span>
                    <span *ngIf="paymentchannel === 'linepay'">LINE Pay</span>
                    <span *ngIf="paymentchannel === 'wechatpay'">WeChat Pay</span>
                    <span *ngIf="paymentchannel === 'alipay'">Alipay</span>
                    ของท่าน <b class="text-danger">ภายใน {{expireorder}}</b><br>
                    เมื่อชำระเงินเรียบร้อยแล้วรอรับซิม Feels ที่บ้านได้เลย
                </p>
            </div>
        </div>
        <!-- <div class="col-12 text-center p-3">
            <button mdbBtn type="button" class="rounded-pill bg-soft-green text-white"
                mdbWavesEffect>ยอมรับเงื่อนไข</button>
        </div> -->
    </div>
</main>

<qrcode #save id="qr" [qrdata]="qrcode" [width]="1000" elementType="img" [errorCorrectionLevel]="'M'" class="d-none">
</qrcode>