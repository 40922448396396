import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service'

@Component({
  selector: 'app-paymentsimcard',
  templateUrl: './paymentsimcard.component.html',
  styleUrls: ['./paymentsimcard.component.scss']
})
export class PaymentsimcardComponent implements OnInit {
  public myRadio: string = 'alipay'

  constructor(private register: RegisterService) { }

  ngOnInit(): void {
  }
  radio(event) {
    const inputValue = event.target.value;
    this.myRadio = inputValue
    console.log(inputValue)
  }
  onSent() {
    this.register.pageMove.next('done')
  }

}
