import { Component, OnInit } from '@angular/core';
import { OnlinesimService } from '../../../services/onlinesim.service';

@Component({
    selector: 'app-onlinesimagreement',
    templateUrl: './onlinesimagreement.component.html',
    styleUrls: ['./onlinesimagreement.component.scss']
})
export class OnlinesimagreementComponent implements OnInit {

    constructor(private onlinesimService: OnlinesimService) {
        // console.log(this.onlinesimService.summarydetail);
        // console.log(this.onlinesimService.numberproductid);
        // console.log(this.onlinesimService.numberoriginprice);
        // console.log(this.onlinesimService.numbertaxprice);
        // console.log(this.onlinesimService.selectedPackageObject);
        // console.log(this.onlinesimService.selectedPackageOriginPrice);
        // console.log(this.onlinesimService.selectedPackagePrice);
        // console.log(this.onlinesimService.selectedPackageCode);
        // console.log(this.onlinesimService.selectedPackageProductid);
    }

    ngOnInit(): void {
    }

    next() {
        this.onlinesimService.page.next('personalinfo');
    }

}
